<form class="o-form u-padding--inline" [formGroup]="alertForm">
  <div class="o-form__legend">
    <h2 class="is-legend">Configure real-time camera alert</h2>
  </div>
  <div class="is-input">
    <h3 class="brevier u-flush--bottom">
      Delay before sending an alert after a camera issue is detected.<br />
      <span class="kirk"> 15m recommended.</span>
    </h3>
    <mat-form-field appearance="outline" class="u-white-bg u-margin--bottom">
      <input autocomplete="off" matInput formControlName="duration" />
    </mat-form-field>
    <div class="c-send-rules__help">
      <div>Use the format: 15m, 2h, etc...</div>
      <ul class="c-send-rules__help-list">
        <li>m = minutes</li>
        <li>h = hours</li>
      </ul>
    </div>
  </div>
  <div class="option-label">
    <mat-checkbox formControlName="notifyAlertRecovery">
      <span class="brevier">Send update when camera is back online.</span>
    </mat-checkbox>
  </div>
  <div>
    <app-select-recipients
      [users]="users"
      [notificationGroups]="notificationGroups"
      [selectedRecipients]="selectedRecipients"
    ></app-select-recipients>
  </div>
</form>
