import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-occupancy-monitor-confirmation',
  templateUrl: './occupancy-monitor-confirmation.component.html',
  styleUrls: ['./occupancy-monitor-confirmation.component.scss'],
})
export class OccupancyMonitorConfirmationComponent {
  @Input() monitorDetails = {};
  @Input() cameras = [];
  @Input() cameraFrame = {};
  @Input() direction1: string;
  @Input() direction2: string;
  constructor() {}
}
