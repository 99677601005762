<div class="o-modal-full-page has-secondary-header c-monitor-general-info-dialog">
  <div class="o-modal-full-page__header">
    <button (click)="close()" class="is-close">
      <mat-icon>clear</mat-icon>
    </button>
    <h2 class="is-title">People count</h2>
  </div>

  <div class="c-header">
    <div class="c-header__name">{{ peopleCountMonitor.name }}</div>
    <div class="c-header__options">
      <div class="o-button-flush-group">
        <button
          (click)="dailyTimePicker.open()"
          class="o-button is-small"
          [ngClass]="reportsService.isPeopleCountDaily ? 'is-primary' : 'is-muted is-contained'"
        >
          <span class="material-icons is-icon-left" *ngIf="reportsService.isPeopleCountDaily"> event </span>
          <div class="o-button__date-label">
            Daily
            <span class="c-text-date" *ngIf="reportsService.isPeopleCountDaily">
              {{ reportsService.peopleCountMonitorDataDailyTime | date: 'dd/MM/yy' }}</span
            >
          </div>
        </button>
        <input
          class="c-hide"
          matInput
          [(ngModel)]="reportsService.peopleCountMonitorDataDailyTime"
          (dateChange)="reportsService.isPeopleCountDaily = true"
          [matDatepicker]="dailyTimePicker"
        />
        <mat-datepicker #dailyTimePicker></mat-datepicker>
        <button
          (click)="weeklyTimePicker.open()"
          class="o-button is-small"
          [ngClass]="!reportsService.isPeopleCountDaily ? 'is-primary' : 'is-muted is-contained'"
        >
          <div class="o-button__date-label">
            <span class="material-icons is-icon-left" *ngIf="!reportsService.isPeopleCountDaily"> event </span>
            Weekly
            <span class="c-text-date" *ngIf="!reportsService.isPeopleCountDaily">{{
              reportsService.peopleCountMonitorDataWeeklyTime | date: 'dd/MM/yy'
            }}</span>
          </div>
        </button>
        <mat-date-range-input class="c-hide" [rangePicker]="weeklyTimePicker">
          <input
            matStartDate
            [(ngModel)]="reportsService.peopleCountMonitorDataWeeklyTime"
            (dateChange)="updatePeopleCountMonitorDataWeeklyTime(); weeklyTimePicker.close()"
          />
          <input
            matEndDate
            [(ngModel)]="peopleCountMonitorDataWeeklyTimeEndDate"
            (dateChange)="updatePeopleCountMonitorDataWeeklyTime(); weeklyTimePicker.close()"
          />
        </mat-date-range-input>
        <mat-date-range-picker #weeklyTimePicker [restoreFocus]="true"></mat-date-range-picker>
      </div>
    </div>
  </div>

  <app-people-count-monitor-card
    [site]="reportsService.sitesMap[peopleCountMonitor.siteId]"
    [dataStartDate]="
      reportsService.isPeopleCountDaily
        ? reportsService.peopleCountMonitorDataDailyTime
        : reportsService.peopleCountMonitorDataWeeklyTime
    "
    [useOpeningHours]="reportsService.useOpeningHoursPeopleCountMonitors"
    [peopleCountMonitor]="peopleCountMonitor"
    [disableShowingPopup]="true"
    [showChartForMobile]="true"
  ></app-people-count-monitor-card>
</div>
