<form class="o-form" [formGroup]="monitorForm">
  <div class="o-form__legend-icon">
    <mat-icon class="is-icon">view_compact</mat-icon>
    <h2 class="is-legend">Give your monitor a name.</h2>
    <h3 class="is-support-text">
      This can be anything you like, but should describe the location it is monitoring. Such as: Entrance, or High St.
    </h3>
    <mat-form-field class="is-input" appearance="outline">
      <mat-label>Monitor name</mat-label>
      <input autocomplete="off" matInput formControlName="name" />
    </mat-form-field>
  </div>
  <ng-container *ngIf="accountService.isSupport">
    <div class="o-form__legend-icon">
      <mat-icon class="is-icon">event</mat-icon>
      <h2 class="is-legend">Reporting start date.</h2>
      <mat-form-field class="is-input">
        <mat-label>Start date</mat-label>
        <input matInput [matDatepicker]="pickerFrom" formControlName="reportingStartDate" readonly />
        <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="o-form__legend-icon">
      <mat-icon class="is-icon">event</mat-icon>
      <h2 class="is-legend">Reporting end date.</h2>
      <mat-form-field class="is-input">
        <mat-label>End date</mat-label>
        <input matInput [matDatepicker]="pickerTo" formControlName="reportingEndDate" readonly />
        <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
        <mat-datepicker #pickerTo></mat-datepicker>
      </mat-form-field>
    </div>
  </ng-container>
</form>
