import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { CameraService } from 'src/app/api';
import { CameraStatus } from 'src/app/model/cameraStatus';
import { CamerasService } from 'src/app/services/cameras.service';
import { NotifyService } from 'src/app/services/notify.service';
import { UserConfirmationComponent } from 'src/app/components/general/user-confirmation/user-confirmation.component';

@Component({
  selector: 'app-select-camera-direction-step',
  templateUrl: './select-camera-direction-step.component.html',
  styleUrls: ['./select-camera-direction-step.component.scss'],
})
export class SelectCameraDirectionStepComponent implements OnInit, OnDestroy {
  monitorForm: FormGroup;
  cameraFrame = {};
  isLoadingCamera = {};
  isLoadingCameraFrame = {};
  camerasMap = {};

  ngUnsubscribe = new Subject();
  constructor(
    private cameraService: CameraService,
    private camerasService: CamerasService,
    private notifyService: NotifyService,
    private ref: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private matDialog: MatDialog,
  ) {
    this.monitorForm = this.formBuilder.group({
      camera: this.formBuilder.array([]),
    });
  }

  @Output() removeCameraAction: EventEmitter<string> = new EventEmitter<string>();
  @Output() addCameras: EventEmitter<void> = new EventEmitter();
  @Output() deleteMonitor: EventEmitter<void> = new EventEmitter();
  @Input() direction1: string;
  @Input() direction2: string;
  _cameras: any[];
  get cameras(): any[] {
    return this._cameras;
  }
  @Input() set cameras(cameras: CameraStatus[]) {
    this.clearFormValues();
    this._cameras = [...cameras];
    cameras.forEach((camera) => {
      this.camerasMap[camera.id] = camera;
    });
    this.patchFormValues();
  }

  _isEditMode: boolean;
  get isEditMode(): boolean {
    return this._isEditMode;
  }
  @Input() set isEditMode(value: boolean) {
    this.monitorControls.controls.forEach((camera) => camera.get('confirmed').patchValue(true));
    this._isEditMode = value;
  }

  get monitorControls(): FormArray {
    return this.monitorForm.get('camera') as FormArray;
  }

  ngOnInit(): void {
    this.cameras.forEach((camera) => {
      this.getCameraFrame(camera.id);
    });
  }

  patchFormValues(): void {
    const control = <FormArray>this.monitorForm.get('camera');
    this.cameras.map((camera: CameraStatus) => {
      control.push(
        this.formBuilder.group({
          id: camera.id,
          cameraPositionId: camera.cameraPositionId,
          name: camera.name,
          type: camera.type,
          direction1: camera.direction1Alias,
          direction2: camera.direction2Alias,
          confirmed: [false, Validators.requiredTrue],
          reversed: camera.hasOwnProperty('reversed') ? camera['reversed'] : false,
          isOnline: camera.isOnline,
          isPassingTraffic: camera.type === 'Passing traffic',
        }),
      );
    });
  }

  getCamera(cameraId: string): void {
    this.isLoadingCamera[cameraId] = true;
    this.ref.detectChanges();

    this.cameraService
      .getCamera(cameraId)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.isLoadingCamera[cameraId] = false;
          this.ref.detectChanges();
        }),
      )
      .subscribe({
        next: (camera) => {
          const c = this.camerasService.getCameraStatus(camera);
          const newControl = this.formBuilder.group({
            id: c.id,
            cameraPositionId: c.cameraPositionId,
            name: c.name,
            type: c.type,
            direction1: c.direction1Alias,
            direction2: c.direction2Alias,
            confirmed: [false, Validators.requiredTrue],
            reversed: c.hasOwnProperty('reversed') ? c['reversed'] : false,
            isOnline: c.isOnline,
            isPassingTraffic: c.type === 'Passing traffic',
          });
          this.monitorControls.controls = this.monitorControls.controls.map((control) =>
            control.get('id').value !== c.id ? control : newControl,
          );
          this.getCameraFrame(c.id);
        },
        error: (error) => {
          this.notifyService.error(error);
        },
      });
  }

  clearFormValues(): void {
    const control = <FormArray>this.monitorForm.get('camera');
    control.clear();
  }

  switchMonitorDirection(camera: AbstractControl): void {
    camera.get('reversed').setValue(!camera.get('reversed').value);
  }

  isCameraOnline(camera: CameraStatus): boolean {
    return (
      (camera.state === 'running' && this.cameraFrame.hasOwnProperty(camera.id)) ||
      this.isLoadingCameraFrame.hasOwnProperty(camera.id)
    );
  }

  removeCamera(camera: AbstractControl): void {
    const message = `Are you sure you want to delete "${camera.get('name').value}" camera from this monitor?`;

    const dialogRef = this.matDialog.open(UserConfirmationComponent, {
      data: { message, buttonText: 'delete', isDelete: true },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'confirm') {
          const cameraId = camera.get('id').value;
          const cameraIndex = this.monitorControls.value.findIndex((camera) => camera.id === cameraId);
          this.monitorControls.removeAt(cameraIndex);
          delete this.cameraFrame[cameraId];
          delete this.isLoadingCameraFrame[cameraId];
          this.removeCameraAction.emit(camera.get('cameraPositionId').value);
          this.ref.detectChanges();
        }
      },
    });
  }

  getCameraFrame(cameraId: string): void {
    const camera = this.camerasMap[cameraId];
    if (camera.state === 'running' && camera.isOnline) {
      this.isLoadingCameraFrame[camera.id] = true;
      this.ref.detectChanges();

      this.cameraService
        .createFrameRequest({ cameraId: camera.id })
        .pipe(
          takeUntil(this.ngUnsubscribe),
          finalize(() => {
            this.isLoadingCameraFrame[camera.id] = false;
            this.ref.detectChanges();
          }),
        )
        .subscribe({
          next: (frame) => {
            this.cameraFrame[camera.id] = 'data:image/jpeg;base64,' + frame.image;
          },
          error: (error) => {
            delete this.isLoadingCameraFrame[camera.id];
            this.notifyService.error(error);
          },
        });
    }
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
