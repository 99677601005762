<div class="c-options">
  <div class="c-options__download">
    <div class="loading" *ngIf="downloading">
      <mat-progress-spinner color="primary" mode="indeterminate" diameter="36"></mat-progress-spinner>
    </div>
    <div
      #tooltip="matTooltip"
      matTooltip="You must select at least one organisation"
      [matTooltipDisabled]="!noOrganisationSelectedError"
    >
      <button class="o-button is-primary is-small" (click)="downloadCSV(tooltip)">
        <mat-icon class="is-icon-left">file_download</mat-icon>
        {{ downloadButtonText }}
      </button>
    </div>
  </div>
  <div class="c-options__search">
    <div class="c-search__field">
      <app-filter placeholder="Filter organisations" (search)="runSearch($event)" class="o-search__input"></app-filter>
    </div>
  </div>
  <div class="c-options__pagination">
    <mat-paginator #tablePaginator [pageSizeOptions]="[20, 50, 100]" class="c-pagination"></mat-paginator>
  </div>
  <button class="o-button is-primary is-small is-create" (click)="openNewDwellTimeReportDialog()">CREATE REPORT</button>
</div>

<div class="c-camera-list">
  <table
    mat-table
    [dataSource]="tableDataSource"
    class="c-table"
    [ngClass]="{ 'has-error': noOrganisationSelectedError }"
  >
    <tr mat-header-row *matHeaderRowDef="tableHeaderNames" class="c-table__header"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: tableHeaderNames"
      class="c-table__row"
      [ngStyle]="row.status !== 'completed' && { background: '#f1f1f1' }"
    ></tr>

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row" class="check-column">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
          [disabled]="row.status !== 'completed'"
        >
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef class="c-table__title">Created At</th>
      <td mat-cell *matCellDef="let row">
        {{ row.createdAt }}
      </td>
    </ng-container>
    <ng-container matColumnDef="preview">
      <th mat-header-cell *matHeaderCellDef class="c-table__title"></th>
      <td mat-cell *matCellDef="let row">
        <a
          *ngIf="accountService.isSupport || row.status === 'completed'"
          [routerLink]="['/reporting', 'dwell-time-reports', row.dwellTimeReportId]"
          class="is-compact"
          ><mat-icon class="is-icon">preview</mat-icon></a
        >
      </td>
    </ng-container>
    <ng-container matColumnDef="startDate">
      <th mat-header-cell *matHeaderCellDef class="c-table__title">Start date</th>

      <td mat-cell *matCellDef="let row">
        {{ row.startDate }}
      </td>
    </ng-container>
    <ng-container matColumnDef="endDate">
      <th mat-header-cell *matHeaderCellDef class="c-table__title">End Date</th>

      <td mat-cell *matCellDef="let row">
        {{ row.endDate }}
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef class="c-table__title">Status</th>

      <td mat-cell *matCellDef="let row">
        {{ row.status }}
      </td>
    </ng-container>
    <ng-container matColumnDef="monitors">
      <th mat-header-cell *matHeaderCellDef class="c-table__title">Monitors</th>

      <td mat-cell *matCellDef="let row" class="monitors-cell">{{ row.monitors }}</td>
    </ng-container>
    <ng-container matColumnDef="organisationName" *ngIf="accountService.isSupport">
      <th mat-header-cell *matHeaderCellDef class="c-table__title">Organisation Name</th>

      <td mat-cell *matCellDef="let row">
        {{ row.organisationName }}
      </td>
    </ng-container>
  </table>
</div>
