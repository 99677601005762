import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NotificationGroup, NotificationGroupNotificationGroup, User } from 'src/app/api';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-edit-notification-group-dialog',
  templateUrl: './edit-notification-group-dialog.component.html',
  styleUrls: ['./edit-notification-group-dialog.component.scss'],
})
export class EditNotificationGroupDialogComponent implements OnInit {
  notificationGroup: NotificationGroup;
  notificationGroupsMap: { [_: string]: NotificationGroup } = {};
  childNotificationGroups: NotificationGroup[];
  userEmailToUserMap: { [userEmail: string]: User };
  notificationGroupNotificationGroupMap: { [_: string]: NotificationGroupNotificationGroup[] };

  constructor(
    private notificationsService: NotificationsService,
    private dialogRef: MatDialogRef<EditNotificationGroupDialogComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.userEmailToUserMap = this.data.userEmailToUserMap;
    this.notificationGroup = this.notificationsService.notificationGroupsMap[this.data.notificationGroupDetails.id];

    this.notificationGroupsMap = this.notificationsService.notificationGroupsMap;
    this.notificationGroupNotificationGroupMap = this.notificationsService.notificationGroupNotificationGroupMap;

    const childNotificationGroupsIds = this.notificationGroupNotificationGroupMap[this.notificationGroup.id];

    this.childNotificationGroups = childNotificationGroupsIds.map(
      (childNotificationGroupsId) => this.notificationGroupsMap[childNotificationGroupsId.childId],
    );

    this.childNotificationGroups.map((group) => ({
      name: group.name,
      id: group.id,
      members: this.notificationsService.notificationGroupMembers[group.id],
    }));
  }

  close(reload?: boolean): void {
    if (reload) {
      this.dialogRef.close('reload');
    } else {
      this.dialogRef.close();
    }
  }

  editGroup(): void {
    const path = `alerts/notification-groups/${this.notificationGroup.id}`;
    this.router.navigate([path]).then(() => this.close());
  }
}
