import { CdkStep } from '@angular/cdk/stepper';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import * as moment from 'moment';
import { Observable, Subject, finalize, forkJoin, switchMap, takeUntil } from 'rxjs';
import {
  Alert,
  AlertOccupancyMonitorMappingService,
  OccupancyMonitor,
  OccupancyMonitorCameraPosition,
  OccupancyMonitorCameraPositionService,
  OccupancyMonitorService,
} from 'src/app/api';
import { SelectCameraDirectionStepComponent } from 'src/app/components/steps-for-stepper/select-camera-direction-step/select-camera-direction-step.component';
import { SelectCamerasStepComponent } from 'src/app/components/steps-for-stepper/select-cameras-step/select-cameras-step.component';
import { UpdateOccupancyMonitorDetailsComponent } from 'src/app/components/steps-for-stepper/update-occupancy-monitor-details/update-occupancy-monitor-details.component';
import { GlobalMethods } from 'src/app/global-methods';
import { CameraStatus } from 'src/app/model/cameraStatus';
import { NotifyService } from 'src/app/services/notify.service';
import { UserConfirmationComponent } from '../../general/user-confirmation/user-confirmation.component';

@Component({
  selector: 'app-edit-occupancy-monitor',
  templateUrl: './edit-occupancy-monitor.component.html',
  styleUrls: ['./edit-occupancy-monitor.component.scss'],
})
export class EditOccupancyMonitorComponent implements OnInit, OnDestroy {
  monitorCameras: CameraStatus[];
  unusedCameras: CameraStatus[];
  cameraPositions = {};
  cameraPositionsToRemove = [];
  monitor: OccupancyMonitor;
  occupancyAlerts: Alert[] = [];
  cameras: CameraStatus[] = [];
  saving = false;
  isRedirect = false;

  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('generalStep') generalStep: CdkStep;
  @ViewChild('addAlertsStep') addAlertsStep: CdkStep;
  @ViewChild('selectNewCameraDirectionStep') selectNewCameraDirectionStep: CdkStep;

  @ViewChild(SelectCamerasStepComponent) selectCamerasStepComponent: SelectCamerasStepComponent;
  @ViewChild('selectCameraDirectionStep')
  selectCameraDirectionStep: SelectCameraDirectionStepComponent;
  @ViewChild('selectNewCameraDirectionStepComponent')
  selectNewCameraDirectionStepComponent: SelectCameraDirectionStepComponent;
  @ViewChild(UpdateOccupancyMonitorDetailsComponent)
  updateOccupancyMonitorDetailsComponent: UpdateOccupancyMonitorDetailsComponent;

  private ngUnsubscribe = new Subject();

  constructor(
    public dialogRef: MatDialogRef<EditOccupancyMonitorComponent>,
    private occupancyMonitorService: OccupancyMonitorService,
    private occupancyMonitorCameraPositionService: OccupancyMonitorCameraPositionService,
    private alertOccupancyMonitorMappingService: AlertOccupancyMonitorMappingService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notifyService: NotifyService,
    private matDialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.monitor = this.data.monitor;
    this.cameras = this.data.cameras.filter((c) => c.siteId === this.monitor.siteId);
    this.monitorCameras = this.data.monitorCameras;
    this.cameraPositions = this.data.cameraPositions;
    this.monitorCameras.forEach((camera) => {
      camera['reversed'] =
        this.data.cameraPositions[camera.cameraPositionId].inDirection === 'direction_1' ? false : true;
    });
    this.unusedCameras = this.excludeCameras(this.cameras, this.monitorCameras);
    this.occupancyAlerts = this.data.occupancyAlerts;
    this.isRedirect = this.data.isRedirect;
  }

  goToSelectNewCameraDirectionStep(): void {
    this.selectCamerasStepComponent.monitorForm.markAllAsTouched();
    if (this.selectCamerasStepComponent.monitorForm.valid) {
      this.stepper.selected = this.selectNewCameraDirectionStep;
    }
  }

  addNewCameras(): void {
    this.selectNewCameraDirectionStepComponent.monitorForm.markAsTouched();
    if (this.selectNewCameraDirectionStepComponent.monitorForm.valid) {
      var newCameras = this.unusedCameras.filter((existedCamera) =>
        this.selectNewCameraDirectionStepComponent.monitorForm
          .get('camera')
          .value.map((newCamera) => newCamera.id)
          .includes(existedCamera.id),
      );

      this.cameras = [...this.cameras, ...newCameras];
      this.unusedCameras = this.excludeCameras(this.unusedCameras, newCameras);

      this.stepper.selected = this.generalStep;
    }

    const newCamerasSettings = this.selectNewCameraDirectionStepComponent.monitorForm.get('camera').value;

    forkJoin(
      newCamerasSettings.map((camera) =>
        this.occupancyMonitorCameraPositionService.putOccupancyMonitorCameraPosition({
          occupancyMonitorId: this.monitor.id,
          cameraPositionId: camera.cameraPositionId,
          inDirection: camera.reversed ? 'direction_2' : 'direction_1',
        }),
      ),
    )
      .pipe(
        finalize(() => {
          this.close(true);
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (_) => {
          this.notifyService.success('Monitor accessibility successfully updated!');
          this.stepper.next();
        },
        error: (error) => {
          this.notifyService.error(error);
        },
      });
  }

  save(): void {
    const requests: Observable<any>[] = [];
    const dailyResetMin = GlobalMethods.timeStringToMinutes(
      this.updateOccupancyMonitorDetailsComponent.monitorForm.value['dailyResetMin'],
    );
    const camerasSettings = this.selectCameraDirectionStep.monitorForm.get('camera').value;

    // update monitor info
    if (
      this.updateOccupancyMonitorDetailsComponent.monitorForm.value['name'] !== this.monitor.name ||
      this.updateOccupancyMonitorDetailsComponent.monitorForm.value['capacity'] !== this.monitor.capacity ||
      this.updateOccupancyMonitorDetailsComponent.monitorForm.value['reportingStartDate'] !==
        this.monitor.reportingStartDate ||
      this.updateOccupancyMonitorDetailsComponent.monitorForm.value['reportingEndDate'] !==
        this.monitor.reportingEndDate ||
      dailyResetMin !== this.monitor.dailyResetMin
    ) {
      requests.push(
        this.occupancyMonitorService.putOccupancyMonitor({
          ...this.updateOccupancyMonitorDetailsComponent.monitorForm.value,
          reportingEndDate: this.updateOccupancyMonitorDetailsComponent.monitorForm.value['reportingEndDate']
            ? moment(this.updateOccupancyMonitorDetailsComponent.monitorForm.value['reportingEndDate']).format(
                'YYYY-MM-DD',
              )
            : undefined,
          reportingStartDate: this.updateOccupancyMonitorDetailsComponent.monitorForm.value['reportingStartDate']
            ? moment(this.updateOccupancyMonitorDetailsComponent.monitorForm.value['reportingStartDate']).format(
                'YYYY-MM-DD',
              )
            : undefined,
          id: this.monitor.id,
          siteId: this.monitor.siteId,
          dailyResetMin: GlobalMethods.timeStringToMinutes(
            this.updateOccupancyMonitorDetailsComponent.monitorForm.value['dailyResetMin'],
          ),
        }),
      );
    }

    // delete camera positions
    this.cameraPositionsToRemove.forEach((cameraPositionId) => {
      requests.push(
        this.occupancyMonitorCameraPositionService.deleteOccupancyMonitorCameraPosition(
          this.cameraPositions[cameraPositionId].id,
        ),
      );
    });

    // update cameraPosition  InDirection
    camerasSettings.forEach((camerasSetting) => {
      if (
        (camerasSetting.reversed &&
          this.cameraPositions[camerasSetting.cameraPositionId].inDirection ===
            OccupancyMonitorCameraPosition.InDirectionEnum._1) ||
        (!camerasSetting.reversed &&
          this.cameraPositions[camerasSetting.cameraPositionId].inDirection ===
            OccupancyMonitorCameraPosition.InDirectionEnum._2)
      ) {
        requests.push(
          this.occupancyMonitorCameraPositionService.putOccupancyMonitorCameraPosition({
            id: this.cameraPositions[camerasSetting.cameraPositionId].id,
            occupancyMonitorId: this.cameraPositions[camerasSetting.cameraPositionId].occupancyMonitorId,
            cameraPositionId: camerasSetting.cameraPositionId,
            inDirection: camerasSetting.reversed
              ? OccupancyMonitorCameraPosition.InDirectionEnum._2
              : OccupancyMonitorCameraPosition.InDirectionEnum._1,
          }),
        );
      }
    });

    forkJoin(requests)
      .pipe(
        finalize(() => {
          this.saving = false;
          this.close(true);
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (_) => {
          this.notifyService.success('Monitor accessibility successfully updated!');
        },
        error: (error) => {
          this.notifyService.error(error);
        },
      });
  }

  excludeCameras(cameras: CameraStatus[], camerasToExclude: CameraStatus[]): CameraStatus[] {
    return cameras.filter(
      (existedCamera) => !camerasToExclude.map((cameraToExclude) => cameraToExclude.id).includes(existedCamera.id),
    );
  }

  backToMainStep(): void {
    this.stepper.selected = this.generalStep;
  }

  goToAddCamerasStep(): void {
    this.stepper.next();
  }

  goToAddAlertsStep(): void {
    this.stepper.linear = false;
    this.stepper.selected = this.addAlertsStep;
    this.stepper.linear = true;
  }

  onRemoveCamera(cameraPositionId: string): void {
    this.cameraPositionsToRemove.push(cameraPositionId);
  }

  deleteOccupancyMonitor(occupancyMonitor: OccupancyMonitor): void {
    const message = `You are about to delete ${occupancyMonitor.name}.

    Are you sure you want to continue?`;

    const dialogRef = this.matDialog.open(UserConfirmationComponent, {
      data: { message, buttonText: 'Delete' },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'confirm') {
          forkJoin([
            this.occupancyMonitorCameraPositionService.listOccupancyMonitorCameraPositions(
              undefined,
              undefined,
              undefined,
              occupancyMonitor.id,
              'active',
            ),
            this.alertOccupancyMonitorMappingService.listAlertOccupancyMonitorMappings(
              occupancyMonitor.id,
              undefined,
              undefined,
              undefined,
              'active',
            ),
          ])
            .pipe(
              switchMap(([occupancyMonitorCameraPositions, alertOccupancyMonitorMappings]) => {
                const requests: Observable<any>[] = [];
                occupancyMonitorCameraPositions.forEach((occupancyMonitorCameraPosition) => {
                  requests.push(
                    this.occupancyMonitorCameraPositionService.deleteOccupancyMonitorCameraPosition(
                      occupancyMonitorCameraPosition.id,
                    ),
                  );
                });
                alertOccupancyMonitorMappings.forEach((alertOccupancyMonitorMapping) => {
                  requests.push(
                    this.alertOccupancyMonitorMappingService.deleteAlertOccupancyMonitorMapping(
                      alertOccupancyMonitorMapping.id,
                    ),
                  );
                });
                if (!requests.length) {
                  return this.occupancyMonitorService.deleteOccupancyMonitor(occupancyMonitor.id);
                }
                return forkJoin(requests).pipe(
                  switchMap((_) => this.occupancyMonitorService.deleteOccupancyMonitor(occupancyMonitor.id)),
                );
              }),
              takeUntil(this.ngUnsubscribe),
            )
            .subscribe({
              next: (_) => {
                window.location.reload();
              },
              error: (error) => {
                this.notifyService.error(error);
              },
            });
        }
      },
    });
  }

  close(reload?: boolean): void {
    if (this.isRedirect) {
      history.back();
    } else if (reload) {
      this.dialogRef.close('reload');
    } else {
      this.dialogRef.close();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
