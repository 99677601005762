import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CameraStatus } from 'src/app/model/cameraStatus';

@Component({
  selector: 'app-cameras-information',
  templateUrl: './cameras-information.component.html',
  styleUrls: ['./cameras-information.component.scss'],
})
export class CamerasInformationComponent {
  @Input() cameras: CameraStatus[] = [];
  @Input() onlineCamerasWithoutAlertsQuantity: number;
  @Input() issueType: 'alert' | 'summary';

  @Output() closeDialog: EventEmitter<void> = new EventEmitter();
  constructor(private router: Router) {}

  addCameraAlert(): void {
    if (this.issueType === 'alert') {
      this.router.navigate(['alerts/no-camera-alerts']).then(() => this.closeDialog.emit());
    } else {
      this.router.navigate(['alerts/no-camera-summaries']).then(() => this.closeDialog.emit());
    }
  }
}
