import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { OAuthClient } from 'src/app/api';

@Component({
  selector: 'app-oauth-client-card',
  templateUrl: './oauth-client-card.component.html',
  styleUrl: './oauth-client-card.component.scss',
})
export class OAuthClientCardComponent {
  @Input() oAuthClient: OAuthClient;

  @Output() addEditOAuthClient: EventEmitter<void> = new EventEmitter();
  tooltipMessage = 'Copy to clipboard';
  isClientSecretAsPassword = true;
  constructor(private ref: ChangeDetectorRef) {}

  copyLink(tooltip: MatTooltip): void {
    this.tooltipMessage = 'Link copied!';
    tooltip.show();
    this.ref.detectChanges();
    setTimeout(() => {
      this.tooltipMessage = 'Copy to clipboard';
      this.ref.detectChanges();
    }, 1000);
  }

  togglePassword(): void {
    this.isClientSecretAsPassword = !this.isClientSecretAsPassword;
  }
}
