<app-step [stepTitle]="'Opeining hours'" [isBackable]="false" [hasNextStep]="false" (closeStep)="dialogRef.close()">
  <img class="is-image" src="/assets/images/client-auth-thumb.png" alt="Building" />
  <div class="c-sites-container">
    <div class="c-site-card" *ngFor="let site of sites">
      <div class="c-site-card__title long-primer kirk">
        {{ site.name }}
        <button class="o-button is-small" *ngIf="site.openingHour" (click)="editSite(site)">edit</button>
      </div>
      <ng-container *ngIf="!site.openingHour; else openingHours">
        Opening hours not set.
        <button class="o-button is-small" (click)="editSite(site)">
          <mat-icon class="is-icon-left">more_time</mat-icon>
          Add times
        </button>
      </ng-container>
      <ng-template #openingHours>
        <div class="c-opening-hours-day" *ngFor="let day of daysOfWeek; index as i">
          <div class="is-day brevier u-flush--bottom">{{ day }}</div>
          <div class="is-hours minion--caps">
            {{ site.openingHour['weekly'][i] | formatOpeningHours }}
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</app-step>
