import { Injectable } from '@angular/core';
import { Site } from 'src/app/api';
import { CameraStatus } from 'src/app/model/cameraStatus';
import { SiteStatus } from 'src/app/model/siteStatus';

@Injectable({
  providedIn: 'root',
})
export class SitesService {
  constructor() {}

  public getSiteStatus(site: Site, cameras?: CameraStatus[]): SiteStatus {
    const s: SiteStatus = site;

    s.status = 'online';

    cameras?.forEach((c) => {
      if (c.status === 'lagging' || c.status === 'missing' || c.status === 'offline') {
        s.status = 'issue';
      }
    });

    return s;
  }
}
