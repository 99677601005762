import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-metrics-card',
  templateUrl: './metrics-card.component.html',
  styleUrls: ['./metrics-card.component.scss'],
})
export class MetricsCardComponent {
  @Input() isLoadingMetrics = false;
  @Input() titel;
  @Input() titelDate;
  @Input() videoUploaded;
  @Input() peopleCounted;
  @Input() backgroundColor = 'var(--color-mikado--light-1)';

  constructor() {}
}
