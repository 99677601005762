<div class="o-modal-full-page" xmlns="http://www.w3.org/1999/html">
  <div class="menu-control">
    <div (click)="close()" class="menu-control__button">
      <mat-icon class="cursor-pointer is-icon">clear</mat-icon>
    </div>
  </div>
  <ul class="o-list right-nav">
    <li class="o-list__item" *ngIf="isSupport">
      <a routerLink="/organisations" (click)="close()" class="o-list--is-link">
        <mat-icon class="o-list__item-icon is-icon"> shield </mat-icon>
        <div class="o-list__item-text">Organisations</div>
        <mat-icon class="o-list__item-secondary-icon is-secondary-icon">chevron_right </mat-icon>
      </a>
    </li>
    <li class="o-list__item">
      <a routerLink="/sites" (click)="close()" class="o-list--is-link">
        <mat-icon class="o-list__item-icon is-icon"> apartment </mat-icon>
        <div class="o-list__item-text">Sites</div>
        <mat-icon class="o-list__item-secondary-icon is-secondary-icon">chevron_right </mat-icon>
      </a>
    </li>
    <li class="o-list__item">
      <a routerLink="/cameras" (click)="close()" class="o-list--is-link">
        <mat-icon class="o-list__item-icon is-icon"> photo_camera </mat-icon>
        <div class="o-list__item-text">Cameras</div>
        <mat-icon class="o-list__item-secondary-icon is-secondary-icon"> chevron_right </mat-icon>
      </a>
    </li>
    <li class="o-list__item">
      <a routerLink="/alerts" (click)="close()" class="o-list--is-link">
        <mat-icon class="o-list__item-icon is-icon"> notifications </mat-icon>
        <div class="o-list__item-text">Alerts</div>
        <mat-icon class="o-list__item-secondary-icon is-secondary-icon"> chevron_right </mat-icon>
      </a>
    </li>
    <li class="o-list__item">
      <a routerLink="/monitors" (click)="close()" class="o-list--is-link">
        <mat-icon class="o-list__item-icon is-icon"> view_quilt </mat-icon>
        <div class="o-list__item-text">Monitors</div>
        <mat-icon class="o-list__item-secondary-icon is-secondary-icon"> chevron_right </mat-icon>
      </a>
    </li>
    <li class="o-list__item">
      <a routerLink="/reporting" (click)="close()" class="o-list--is-link">
        <mat-icon class="o-list__item-icon is-icon"> assessment </mat-icon>
        <div class="o-list__item-text">Reporting</div>
        <mat-icon class="o-list__item-secondary-icon is-secondary-icon"> chevron_right </mat-icon>
      </a>
    </li>
    <li class="o-list__item" *ngIf="isSupport">
      <a routerLink="/real-time-occupancy" (click)="close()" class="o-list--is-link">
        <mat-icon class="o-list__item-icon is-icon"> show_chart </mat-icon>
        <div class="o-list__item-text">Real time occupancy</div>
        <mat-icon class="o-list__item-secondary-icon is-secondary-icon"> chevron_right </mat-icon>
      </a>
    </li>
    <li class="o-list__item" *ngIf="isSupport">
      <a routerLink="/users" (click)="close()" class="o-list--is-link">
        <mat-icon class="o-list__item-icon is-icon"> person </mat-icon>
        <div class="o-list__item-text">Users</div>
        <mat-icon class="o-list__item-secondary-icon is-secondary-icon"> chevron_right </mat-icon>
      </a>
    </li>
    <li class="o-list__item" *ngIf="isAdmin">
      <a routerLink="/settings" (click)="close()" target="_blank" class="o-list--is-link">
        <mat-icon class="o-list__item-icon is-icon"> settings </mat-icon>
        <div class="o-list__item-text">Settings</div>
        <mat-icon class="o-list__item-secondary-icon is-secondary-icon"> chevron_right </mat-icon>
      </a>
    </li>
    <li class="o-list__item">
      <a href="https://docs.hoxton.ai" (click)="close()" target="_blank" class="o-list--is-link">
        <mat-icon class="o-list__item-icon is-icon"> help </mat-icon>
        <div class="o-list__item-text">Support site</div>
        <mat-icon class="o-list__item-secondary-icon is-secondary-icon"> chevron_right </mat-icon>
      </a>
    </li>
    <li class="o-list__item is-app-info">
      <div class="o-list__item-text">
        <span class="brevier--caps">App info</span> <br />
        <span class="brevier--caps">id </span><span class="brevier--bold">{{ buildHash }}</span> <br />
        <span class="brevier--caps">date </span><span class="brevier--bold">{{ buildDate }}</span>
      </div>
    </li>
  </ul>
</div>
