<div class="c-onboard-stepper">
  <div class="o-modal-full-page is-not-full-height">
    <div class="o-modal-full-page__header">
      <h2 class="is-title c-onboard-stepper__title">
        <span class="is-new-label">New feature</span>
        <span class="is-feature-name">Dwell-time report</span>
      </h2>
    </div>
    <div class="c-onboard-stepper__body">
      <div class="is-media">
        <img src="assets/images/new-monitor.png" />
      </div>
      <div class="is-description">
        <h3 class="long-primer kirk">This report provides insights into how long visitors stay inside your space.</h3>
        <p>
          It helps you analyze visitor engagement by measuring the average time they spend in your space, offering a
          better understanding of their behavior. Use this data to optimize layout, staffing, and improve visitor
          experience.
        </p>
        <!--        <p>-->
        <!--          Find out more about how you can use peel off monitors on our-->
        <!--          <a href="https://docs.hoxton.ai/user-guide/p/peel-off-monitors" target="_blank"> support page </a>.-->
        <!--        </p>-->
      </div>
    </div>
    <div class="o-modal-full-page__footer c-onboard-stepper__button">
      <div class="o-button" (click)="dontShowAgain()">Don't show this again</div>
    </div>
  </div>
</div>
