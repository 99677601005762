<div class="c-comparison-dialog">
  <div class="c-comparisoner-content">
    <div class="c-comparison-dialog-title">Comparison level.</div>
    <div class="c-comparison-dialog-description">Set an occupancy level to compare time spent above/below.</div>
    <div class="c-comparisoner-wrapper">
      <div class="o-slider-legend c-slider-legend">
        <div class="is-value">{{ comparisonLevel }}</div>
        <h1 class="is-label">
          %
          <span class="c-text-max-capacity"> of max capacity </span>
        </h1>
      </div>
      <mat-slider class="c-range-slider" [min]="0" [max]="100">
        <input matSliderThumb [(ngModel)]="comparisonLevel" />
      </mat-slider>
      <mat-checkbox class="c-checkbox-default-option" [(ngModel)]="saveAsDefault">Set as default.</mat-checkbox>
    </div>
  </div>

  <div class="c-comparisoner-buttons">
    <button class="o-button is-muted c-button-cancel" (click)="close()">CANCEL</button>
    <button class="o-button c-button-update" (click)="update()">UPDATE</button>
  </div>
</div>
