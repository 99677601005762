import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
type stepTypeEnum = 'ready' | 'current' | 'unready';

@Component({
  selector: 'app-dwell-time-report-setup-side-panel',
  templateUrl: './dwell-time-report-setup-side-panel.component.html',
  styleUrl: './dwell-time-report-setup-side-panel.component.scss',
})
export class DwellTimeReportSetupSidePanelComponent implements OnInit {
  @Input() activeStep = 0;

  setupProgress = 0;
  StepTypeEnum = {
    ready: 'ready' as stepTypeEnum,
    current: 'current' as stepTypeEnum,
    unready: 'unready' as stepTypeEnum,
  };
  steps = [
    {
      name: 'Select Time',
      icon: 'calendar_today',
      type: this.StepTypeEnum.current,
    },
    {
      name: 'Select monitors',
      icon: 'tag',
      type: this.StepTypeEnum.current,
    },
    {
      name: 'Review report',
      icon: 'task_alt',
      type: this.StepTypeEnum.current,
    },
  ];

  @Output() openPreviousStep: EventEmitter<void> = new EventEmitter();

  ngOnInit(): void {
    this.steps = this.steps.map((step, index) => {
      let type;
      if (index < this.activeStep) {
        type = this.StepTypeEnum.ready;
      }
      if (index === this.activeStep) {
        type = this.StepTypeEnum.current;
      }
      if (index > this.activeStep) {
        type = this.StepTypeEnum.unready;
      }
      return { ...step, type };
    });
    this.setupProgress = Math.round((this.activeStep * 100) / (this.steps.length - 1));
  }
}
