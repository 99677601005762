<div class="o-form">
  <div class="o-form__legend-icon" *ngIf="showOrganisationSelect">
    <mat-icon class="is-icon">shield</mat-icon>
    <div class="is-legend">
      <div class="o-heading--primer">
        <h2>Select an organisation to filter sites</h2>
      </div>
    </div>

    <mat-form-field class="is-input">
      <mat-label>Select an organisation</mat-label>
      <mat-select
        ngDefaultControl
        [disabled]="accountService.organisations.length === 1"
        (selectionChange)="filterByOrganisation()"
        [(ngModel)]="organisationId"
        name="organisationId"
      >
        <mat-option *ngFor="let organisation of accountService.organisations" [value]="organisation.id">
          {{ organisation.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="o-form__legend-icon">
    <mat-icon class="is-icon">business</mat-icon>
    <div class="is-legend">
      <div class="o-heading--primer">
        <h2>Which site is the monitor being created at?</h2>
      </div>
    </div>
    <ng-container *ngIf="filteredSites.length; else noSites">
      <mat-form-field class="is-input">
        <mat-label>Select a site</mat-label>
        <mat-select [(ngModel)]="choosenSite">
          <mat-option *ngFor="let site of filteredSites" [value]="site">
            {{ site.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <ng-template #noSites>
      <div class="is-input c-missing-resource">
        <div class="c-missing-resource__text">
          <mat-icon class="c-missing-resource__text-icon">warning</mat-icon> There are no sites available.
        </div>

        <div class="c-missing-resource__text">
          <mat-icon class="c-missing-resource__text-icon invisible">warning</mat-icon>
          <a class="o-button" (click)="navigateToSites()"
            >CREATE A SITE <mat-icon class="is-icon-right"> arrow_forward </mat-icon></a
          >
        </div>
      </div>
    </ng-template>
  </div>
</div>
