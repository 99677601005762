<ng-container *ngFor="let camera of cameras">
  <div class="c-paused-camera">
    <div class="c-paused-camera__main">
      <mat-icon class="is-icon">camera_alt</mat-icon>
      <div class="c-paused-camera__description">
        <div>{{ camera.name }}</div>
        <div>{{ camera.type }}</div>
      </div>
      <button (click)="putCameraState(camera)" class="is-pause-button o-button is-contained">
        <ng-container *ngIf="camera.state === 'running'; else pausedCamera">
          <mat-icon class="is-icon-left">pause</mat-icon>
          <div>PAUSE CAMERA</div>
        </ng-container>
        <ng-template #pausedCamera>
          <mat-icon class="is-icon-left">play_arrow</mat-icon>
          <div>ACTIVATE CAMERA</div>
        </ng-template>
      </button>
    </div>
    <div class="c-camera-status" [class.c-camera-status--active]="camera.state === 'running'">
      <div>{{ camera.state === 'running' ? 'ACTIVE' : 'PAUSED' }}</div>
    </div>
  </div>
</ng-container>
