import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-quickstart',
  templateUrl: './quickstart.component.html',
  styleUrls: ['./quickstart.component.scss'],
})
export class QuickstartComponent {
  @Input() addedSite = false;
  @Input() addedUser = false;
  @Input() addedCamera = false;

  @Output() closeWizard: EventEmitter<void> = new EventEmitter();

  constructor() {}
}
