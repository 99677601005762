import { Component, Input } from '@angular/core';
import { Alert, Camera, NotificationGroup, Site, User } from 'src/app/api';
import { GlobalMethods } from 'src/app/global-methods';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-camera-alert-and-summary-details-review',
  templateUrl: './camera-alert-and-summary-details-review.component.html',
  styleUrls: ['./camera-alert-and-summary-details-review.component.scss'],
})
export class CameraAlertAndSummaryDetailsReviewComponent {
  @Input() resourceType = '';
  @Input() site: Site;
  @Input() allSites: boolean;
  @Input() selectedCameras: Camera[] = [];
  @Input() cameras: Camera[] = [];
  @Input() allCameras: boolean;
  @Input() alertOrSummary: Alert;
  @Input() notificationGroupName: string;
  @Input() selectedRecipients: any[];

  notificationGroupMembers: { [_: string]: number } = {};
  constructor(private notificationsService: NotificationsService) {
    this.notificationGroupMembers = this.notificationsService.notificationGroupMembers;
  }

  humanizeSchedule(schedule: string): string {
    return GlobalMethods.humanizeSchedule(schedule);
  }

  getRecipientName(recipient: NotificationGroup | User | string): string {
    if (typeof recipient === 'string') {
      return recipient;
    } else if (recipient.hasOwnProperty('firstName')) {
      return recipient.name;
    } else return `${recipient.name} (${this.notificationGroupMembers[recipient.id]})`;
  }
}
