<div class="o-modal-full-page">
  <div class="o-modal-full-page__header">
    <button (click)="close()" class="is-close">
      <mat-icon>clear</mat-icon>
    </button>
    <h2 class="is-title">Edit camera</h2>
    <button class="is-action" [class.spinner]="saving" [disabled]="cameraForm.invalid || saving" (click)="save()">
      Save
    </button>
  </div>
  <div class="auth-overlay white-overlay" *ngIf="saving; else notSaving">
    <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
      <p style="font-size: 2rem; font-weight: 500">Saving changes...</p>
      <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>
  </div>
  <ng-template #notSaving>
    <div class="o-modal-full-page__body">
      <form class="main-content" [formGroup]="cameraForm">
        <div class="c-camera__view-directions" [ngClass]="{ 'is-pass-traff': cameraType === 'Passing traffic' }">
          <div class="c-camera__camera-view" *ngIf="cameraState === 'running'; else notRunningCameraFrame">
            <ng-container *ngIf="cameraFrame !== ''; else noImage">
              <img class="is-image" [src]="cameraFrame" alt="camera view" />
            </ng-container>
            <ng-template #noImage>
              <mat-icon>no_photography</mat-icon>
              Could not get camera view.
            </ng-template>
          </div>
          <ng-template #notRunningCameraFrame>
            <mat-icon>no_photography</mat-icon>
            Camera is not running.
          </ng-template>
          <ng-container *ngIf="cameraType !== 'Passing traffic'; else passTraffDirections">
            <div class="c-camera__set-directions">
              <div class="is-direction">
                <div class="c-direction-icon">
                  <mat-icon class="is-icon">arrow_upward</mat-icon>
                </div>
                <mat-form-field>
                  <mat-label>Direction 1</mat-label>
                  <input autocomplete="off" matInput placeholder="Direction 1" formControlName="direction1Alias" />
                </mat-form-field>
              </div>
              <div class="is-direction">
                <div class="c-direction-icon">
                  <mat-icon class="is-icon">arrow_downward</mat-icon>
                </div>
                <mat-form-field>
                  <mat-label>Direction 2</mat-label>
                  <input autocomplete="off" matInput placeholder="Direction 2" formControlName="direction2Alias" />
                </mat-form-field>
              </div>
            </div>
          </ng-container>
          <ng-template #passTraffDirections>
            <div class="c-camera__set-directions is-pass-traff">
              <div class="is-direction">
                <div class="c-direction-icon">
                  <mat-icon class="is-icon">arrow_back</mat-icon>
                </div>
                <mat-form-field>
                  <mat-label>Direction 2</mat-label>
                  <input autocomplete="off" matInput placeholder="Direction 2" formControlName="direction2Alias" />
                </mat-form-field>
              </div>
              <div class="is-direction">
                <div class="c-direction-icon">
                  <mat-icon class="is-icon">arrow_forward</mat-icon>
                </div>
                <mat-form-field>
                  <mat-label>Direction 1</mat-label>
                  <input autocomplete="off" matInput placeholder="Direction 1" formControlName="direction1Alias" />
                </mat-form-field>
              </div>
            </div>
          </ng-template>
        </div>

        <div>
          <mat-form-field>
            <mat-label>Camera name</mat-label>
            <input autocomplete="off" matInput placeholder="Camera name" formControlName="name" />
          </mat-form-field>
        </div>
        <div class="c-camera__actions">
          <ng-container [ngSwitch]="cameraState">
            <ng-container *ngSwitchCase="'running'">
              <button class="o-button is-contained is-muted is-small" (click)="putCameraState('paused', 'pause')">
                <mat-icon class="is-icon-left">pause</mat-icon>
                PAUSE
              </button>
            </ng-container>
            <ng-container *ngSwitchCase="'paused'">
              <button class="o-button is-contained is-muted is-small" (click)="putCameraState('running', 'restore')">
                <mat-icon class="is-icon-left">play_arrow</mat-icon>
                RESTORE
              </button>
            </ng-container>
          </ng-container>
          <button
            class="o-button is-contained is-muted is-small decommission"
            [class.spinner]="gettingDecommissionInfo"
            [disabled]="gettingDecommissionInfo"
            (click)="deleteCamera()"
            *ngIf="accountService.isSupport"
          >
            <mat-icon class="is-icon-left">delete</mat-icon>
            DECOMMISSION
          </button>
        </div>
      </form>
    </div>
  </ng-template>
</div>
