<div class="o-feature-panel is-contained is-muted">
  <div class="o-feature-panel__head">
    <span class="material-icons is-icon">business</span>
    <h3 class="is-label">No sites added</h3>
  </div>
  <div class="o-feature-panel__body">
    <div class="o-feature-panel__message">
      <h3 class="pica">You haven't added a site yet.</h3>
      <p>Please add a site before registering a camera.</p>
      <p>
        <button class="o-button is-primary" (click)="openAddSiteDialog()">
          <mat-icon class="is-icon-left">business</mat-icon>
          Add site
        </button>
      </p>
      <p class="brevier">
        <a href="https://docs.hoxton.ai/user-guide/sites" target="_blank"
          >Learn more about setting up a site for your organisation</a
        >.
      </p>
    </div>
    <div class="o-feature-panel__media">
      <img src="/assets/images/site-museum.png" alt="Add a site" class="is-media is-greyscale" />
    </div>
  </div>
</div>
