import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PeopleCountMonitor } from 'src/app/api';

@Component({
  selector: 'app-select-monitor-direction-step',
  templateUrl: './select-monitor-direction-step.component.html',
  styleUrls: ['./select-monitor-direction-step.component.scss'],
})
export class SelectMonitorDirectionStepComponent {
  monitorForm: FormGroup;

  @Input() set monitor(monitor: PeopleCountMonitor) {
    this.monitorForm.controls.direction1Alias.patchValue(monitor.direction1Alias);
    this.monitorForm.controls.direction2Alias.patchValue(monitor.direction2Alias);
  }

  constructor(private formBuilder: FormBuilder) {
    this.monitorForm = this.formBuilder.group({
      direction1Alias: ['', [Validators.required, Validators.pattern('^((?!Direction (1|2)).)*$')]],
      direction2Alias: ['', [Validators.required, Validators.pattern('^((?!Direction (1|2)).)*$')]],
    });
  }
}
