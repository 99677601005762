<div class="container">
  <div class="o-banner-message not-found">
    <div class="o-banner-message__text">
      <h1 class="is-title is-paragon">Sorry, you do not have access to this product.</h1>
      <p class="is-text">
        To enable access, contact our
        <a href="mailto:support@hoxton.ai">customer service team</a>.
      </p>
    </div>

    <div class="o-banner-message__media not-found__media">
      <img src="/assets/images/404-graphic.png" alt="" class="is-image" />
    </div>
  </div>
</div>
