<form class="o-form" [formGroup]="monitorForm">
  <div class="o-form__legend-icon">
    <mat-icon class="is-icon"> place </mat-icon>
    <h3 class="is-legend">Which cameras will this monitor use?</h3>
    <p class="is-support-text" *ngIf="cameras.length">
      Choose from {{ cameras.length }} available {{ cameras.length | pluraliseWord: 'camera' }}
    </p>
    <div class="is-input c-camera-list">
      <div
        class="c-camera-list__overflow"
        [class.is-invalid]="monitorForm?.touched && monitorForm?.get('cameras')?.invalid"
      >
        <div
          *ngIf="monitorForm?.touched && monitorForm?.get('cameras')?.invalid"
          class="o-banner-alert is-error u-flush"
        >
          <span class="is-message">Please select a camera</span>
        </div>
        <ul class="o-list">
          <li class="o-list__item" *ngFor="let camera of cameras">
            <mat-checkbox
              class="o-list--is-link has-no-padding"
              (click)="$event.stopPropagation()"
              (change)="onChange(camera)"
            >
              <div class="o-heading--normal u-flush--bottom">
                <span>{{ camera.name }}</span>
              </div>
            </mat-checkbox>
          </li>
        </ul>
        <div class="c-no-content" *ngIf="!cameras.length">
          <h4 class="is-title">No cameras available.</h4>
          <div class="is-media">
            <img src="/assets/images/no-camera-placeholder.png" alt="No cameras available" />
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
