import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-user-confirmation',
  templateUrl: './user-confirmation.component.html',
  styleUrls: ['./user-confirmation.component.scss'],
})
export class UserConfirmationComponent {
  title = '';
  message = '';
  buttonText = '';
  closeText = 'close';
  isDelete = false;
  constructor(
    public dialogRef: MatDialogRef<UserConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.title = this.data.title;
    this.message = this.data.message;
    this.buttonText = this.data.buttonText;
    if (this.data.hasOwnProperty('closeText')) {
      this.closeText = this.data.closeText;
    }
    if (this.data.hasOwnProperty('isDelete')) {
      this.isDelete = this.data.isDelete;
    }
  }

  confirm(): void {
    this.dialogRef.close('confirm');
  }

  close(): void {
    this.dialogRef.close();
  }
}
