import { CdkStep } from '@angular/cdk/stepper';
import { Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { finalize, forkJoin, of, Subject, switchMap, takeUntil } from 'rxjs';
import { OccupancyMonitor, OccupancyMonitorCameraPositionService, OccupancyMonitorService, Site } from 'src/app/api';
import { GlobalMethods } from 'src/app/global-methods';
import { CameraStatus } from 'src/app/model/cameraStatus';
import { NotifyService } from 'src/app/services/notify.service';
import { AddOccupancyMonitorDetailsComponent } from 'src/app/components/steps-for-stepper/add-occupancy-monitor-details/add-occupancy-monitor-details.component';
import { SelectASiteComponent } from 'src/app/components/steps-for-stepper/select-a-site/select-a-site.component';
import { SelectCameraDirectionStepComponent } from 'src/app/components/steps-for-stepper/select-camera-direction-step/select-camera-direction-step.component';
import { SelectCamerasStepComponent } from 'src/app/components/steps-for-stepper/select-cameras-step/select-cameras-step.component';
import * as moment from 'moment';

@Component({
  selector: 'app-add-occupancy-monitor',
  templateUrl: './add-occupancy-monitor.component.html',
  styleUrls: ['./add-occupancy-monitor.component.scss'],
})
export class AddOccupancyMonitorComponent implements OnDestroy {
  stepsTitle = 'Create an occupancy monitor';
  cameras: CameraStatus[];
  filteredCameras: CameraStatus[] = [];
  sites: Site[];
  showSelectSite = false;
  selectedCamerasSetting = [];
  cameraFrame = {};
  successMessage = '';
  siteId = '';
  isLoading = false;

  private ngUnsubscribe = new Subject();

  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('selectCamerasStepComponentStep') selectCamerasStepComponentStep: CdkStep;
  @ViewChild('occupancyMonitorConfirmationStep') occupancyMonitorConfirmationStep: CdkStep;
  @ViewChild(SelectASiteComponent) selectASiteComponent: SelectASiteComponent;
  @ViewChild(AddOccupancyMonitorDetailsComponent)
  addOccupancyMonitorDetailsComponent: AddOccupancyMonitorDetailsComponent;
  @ViewChild(SelectCamerasStepComponent) selectCamerasStepComponent: SelectCamerasStepComponent;
  @ViewChild(SelectCameraDirectionStepComponent) selectCameraDirectionStepComponent: SelectCameraDirectionStepComponent;

  constructor(
    public dialogRef: MatDialogRef<AddOccupancyMonitorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private occupancyMonitorService: OccupancyMonitorService,
    private occupancyMonitorCameraPositionService: OccupancyMonitorCameraPositionService,
    private notifyService: NotifyService,
  ) {
    this.cameras = data.cameras;
    this.sites = data.sites;

    if (this.sites?.length === 1 && !data.siteId) {
      this.siteId = this.sites[0].id;
    } else {
      this.siteId = data.siteId;
    }

    if (!this.siteId) {
      this.showSelectSite = true;
    }
    this.filterCameras();
  }

  filterCameras(): void {
    this.filteredCameras = this.siteId ? this.cameras.filter((c: CameraStatus) => c.siteId === this.siteId) : [];
  }

  selectASite(): void {
    this.siteId = this.selectASiteComponent.choosenSite.id;
    this.filterCameras();
    this.stepper.next();
  }

  checkSelectedCameras(): void {
    if (this.selectCamerasStepComponent.monitorForm.get('cameras').value.length) {
      this.stepper.next();
    } else {
      this.selectedCamerasSetting = [];
      this.cameraFrame = {};
      this.stepper.selected = this.occupancyMonitorConfirmationStep;
    }
  }

  goToConfirmationStep(): void {
    if (this.selectCameraDirectionStepComponent.monitorForm.valid) {
      this.stepper.next();
      this.selectedCamerasSetting = this.selectCameraDirectionStepComponent.monitorForm.get('camera').value;
      this.cameraFrame = this.selectCameraDirectionStepComponent?.cameraFrame;
    }
  }

  createMonitor(): void {
    this.isLoading = true;
    this.occupancyMonitorService
      .putOccupancyMonitor({
        ...this.addOccupancyMonitorDetailsComponent.monitorForm.value,
        reportingEndDate: this.addOccupancyMonitorDetailsComponent.monitorForm.value['reportingEndDate']
          ? moment(this.addOccupancyMonitorDetailsComponent.monitorForm.value['reportingEndDate']).format('YYYY-MM-DD')
          : undefined,
        reportingStartDate: this.addOccupancyMonitorDetailsComponent.monitorForm.value['reportingStartDate']
          ? moment(this.addOccupancyMonitorDetailsComponent.monitorForm.value['reportingStartDate']).format(
              'YYYY-MM-DD',
            )
          : undefined,
        siteId: this.siteId,
        dailyResetMin: GlobalMethods.timeStringToMinutes(
          this.addOccupancyMonitorDetailsComponent.monitorForm.value['dailyResetMin'],
        ),
      })
      .pipe(
        switchMap((monitor: OccupancyMonitor) => {
          if (this.selectedCamerasSetting.length) {
            return forkJoin(
              this.selectedCamerasSetting.map((camera) =>
                this.occupancyMonitorCameraPositionService.putOccupancyMonitorCameraPosition({
                  occupancyMonitorId: monitor.id,
                  cameraPositionId: camera.cameraPositionId,
                  inDirection: camera.reversed ? 'direction_2' : 'direction_1',
                }),
              ),
            );
          } else {
            return of([]);
          }
        }),
        finalize(() => {
          this.isLoading = false;
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (_) => {
          this.successMessage = `Your new occupancy monitor: ${this.addOccupancyMonitorDetailsComponent?.monitorForm.value['name']} - is created.`;
          this.close(true);
        },
        error: (error) => {
          this.notifyService.error(error);
        },
      });
  }

  createMonitorPreviousStep() {
    if (this.selectCamerasStepComponent.monitorForm.get('cameras').value.length) {
      this.stepper.previous();
    } else {
      this.stepper.selected = this.selectCamerasStepComponentStep;
    }
  }

  close(reload?: boolean): void {
    if (reload) {
      this.dialogRef.close('reload');
    } else {
      this.dialogRef.close();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
