import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { User as Auth0User } from '@auth0/auth0-spa-js';
import { AuthService } from '@auth0/auth0-angular';
import { Organisation, OrganisationService, Site, SiteService, User, UserService } from 'src/app/api';
import { AddEditUserComponent } from 'src/app/components/users/add-edit-user/add-edit-user.component';
import { environment } from 'src/environments/environment';
import { NotifyService } from 'src/app/services/notify.service';
import { ImpersonationService } from 'src/app/services/impersonation.service';
import { AccountService } from 'src/app/services/account.service';
import { LimitReachedInformationComponent } from 'src/app/components/general/limit-reached-information/limit-reached-information.component';
import { GlobalMethods } from 'src/app/global-methods';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, OnDestroy {
  usersVisibility: {} = {};
  filteredUsers: User[] = [];
  users: User[] = [];
  sites: Site[] = [];
  organisationsMap: { [_: string]: Organisation } = {};
  selectedSites: Site[] = [];
  selectedOrganisations: Organisation[] = [];
  organisation: Organisation;
  showAdmin = true;
  showMember = true;
  mustContainString = '';

  canImpersonate = false;
  isAdmin = false;
  isSupport = false;
  user: Auth0User;

  isLoading = false;

  adminUsers: number;
  adminUsersSeatsAvailable: number;
  memberUsers: number;
  memberUsersSeatsAvailable: number;

  private ngUnsubscribe = new Subject();

  constructor(
    private activatedRoute: ActivatedRoute,
    private auth: AuthService,
    private matDialog: MatDialog,
    private organisationService: OrganisationService,
    private siteService: SiteService,
    private userService: UserService,
    private router: Router,
    private ref: ChangeDetectorRef,
    private notifyService: NotifyService,
    private impersonation: ImpersonationService,
    public accountService: AccountService,
  ) {}

  ngOnInit(): void {
    this.isSupport = this.accountService.isSupport;
    this.isAdmin = this.accountService.isAdmin;
    this.canImpersonate = this.accountService.canImpersonate;

    this.organisation = this.accountService.organisation;
    this.organisationsMap = this.accountService.organisationsMap;

    this.auth.user$.subscribe({
      next: (user) => {
        this.user = user;
      },
    });

    this.getData();
  }

  getData(): void {
    this.isLoading = true;
    this.usersVisibility = {};
    this.ref.detectChanges();

    forkJoin([this.userService.listUsers(), this.siteService.listSites()])
      .pipe(
        finalize(() => {
          if (this.isAdmin && this.organisation) {
            this.setupUserLimits(this.organisation);
          }
          this.ref.detectChanges();
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: ([users, sites]) => {
          this.sites = sites;
          this.users = users;

          const usersVisibleSitesRequests = [];

          if (!this.accountService.isSupport) {
            users.forEach((user) => {
              usersVisibleSitesRequests.push(this.siteService.listSites(undefined, user.id));
            });
          }

          forkJoin(usersVisibleSitesRequests)
            .pipe(
              finalize(() => {
                this.isLoading = false;
                this.applyFilter();
                this.activatedRoute.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
                  next: (params) => {
                    if (params.orgId) {
                      this.filteredUsers = this.filteredUsers.filter((u) => u.organisationId === params.orgId);
                    }
                  },
                });
                this.ref.detectChanges();
              }),
              takeUntil(this.ngUnsubscribe),
            )
            .subscribe({
              next: (usersVisibleSites) => {
                usersVisibleSites.forEach((userSites: any[], index) => {
                  this.usersVisibility[users[index].id] = {
                    user: users[index],
                    sites: userSites.map((site) => site.id),
                  };
                });
              },
              error: (error) => {
                console.log(error);
              },
            });
        },
        error: (_) => {
          this.router.navigate(['internal-error']);
        },
      });
  }

  setupUserLimits(organisation: Organisation) {
    this.adminUsers = this.users.filter((u) => u.roles.includes('admin')).length;
    this.memberUsers = this.users.filter((u) => u.roles.includes('member')).length;

    if (organisation['maxUserSeats']) this.memberUsersSeatsAvailable = organisation['maxUserSeats'];
    if (organisation['maxAdminSeats']) this.adminUsersSeatsAvailable = organisation['maxAdminSeats'];
  }

  applyFilter(sites?: Site[], organisations?: Organisation[]): void {
    this.selectedSites = sites ? sites : [];
    this.selectedOrganisations = organisations ? organisations : [];
    this.filterUsers();
  }

  runSearch(ss: string) {
    this.mustContainString = ss.toLowerCase();
    this.filterUsers();
  }

  filterUsers(): void {
    let selectedSiteIds = this.selectedSites.map((site) => site.id);
    let selectedOrganisationIds = this.selectedOrganisations.map((org) => org.id);
    this.filteredUsers =
      selectedSiteIds.length !== 0
        ? Object.values(this.usersVisibility)
            .filter(
              (uv) =>
                (uv['user']['roles'].includes('admin') &&
                  this.selectedSites.filter((site) => site.organisationId === uv['user'].organisationId).length !==
                    0) ||
                uv['sites'].filter((siteId) => selectedSiteIds.includes(siteId)).length !== 0,
            )
            .map((uv) => uv['user'])
        : this.users;
    if (selectedOrganisationIds.length) {
      this.filteredUsers = this.filteredUsers.filter((u) => selectedOrganisationIds.includes(u.organisationId));
    }

    let rolesFilter = [];
    if (this.showAdmin) {
      rolesFilter.push('admin');
    }
    if (this.showMember) {
      rolesFilter.push('member');
    }

    if ((this.showMember || this.showAdmin) && !(this.showMember && this.showAdmin)) {
      this.filteredUsers = this.filteredUsers.filter(
        (user) => user.roles.filter((role) => rolesFilter.includes(role)).length !== 0,
      );
    }

    if (this.mustContainString) {
      this.filteredUsers = this.filteredUsers.filter(
        (user) =>
          user.name.toLowerCase().includes(this.mustContainString) ||
          user.id.toLowerCase().includes(this.mustContainString) ||
          user.email.toLowerCase().includes(this.mustContainString) ||
          this.organisationsMap[user.organisationId].name.toLowerCase().includes(this.mustContainString) ||
          this.organisationsMap[user.organisationId].id.toLowerCase().includes(this.mustContainString),
      );
    }

    this.filteredUsers = this.filteredUsers.sort((u1: User, u2: User) => {
      if (u1.name.toLowerCase() > u2.name.toLowerCase()) {
        return 1;
      } else {
        return -1;
      }
    });
  }

  formatUserRoles(user): string {
    var userRoles = '-';

    if (user.roles.includes('admin')) {
      userRoles = 'ADMIN';

      if (user.roles.includes('member')) {
        userRoles += ', MEMBER';
      }
    } else {
      if (user.roles.includes('member')) {
        userRoles = 'MEMBER';
      }
    }

    return userRoles;
  }

  openAddEditUserDialog(user?: User): void {
    const data = {
      sites: this.sites,
      users: this.users,
      organisationsMap: this.organisationsMap,
      adminUsers: this.adminUsers,
      adminUsersSeatsAvailable: this.adminUsersSeatsAvailable,
      memberUsers: this.memberUsers,
      memberUsersSeatsAvailable: this.memberUsersSeatsAvailable,
    };
    if (user) {
      data['user'] = user;

      if (this.isSupport) {
        data['adminUsers'] = this.users.filter(
          (u) => user.organisationId === u.organisationId && u.roles.includes('admin'),
        ).length;
        data['memberUsers'] = this.users.filter(
          (u) => user.organisationId === u.organisationId && u.roles.includes('member'),
        ).length;
        data['adminUsersSeatsAvailable'] = this.organisationsMap[user.organisationId]['maxAdminSeats'];
        data['memberUsersSeatsAvailable'] = this.organisationsMap[user.organisationId]['maxUserSeats'];
      }
    }

    const dialogRef = this.matDialog.open(AddEditUserComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data,
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          this.getData();
        }
      },
    });
  }

  impersonate(user: User): void {
    this.userService
      .createImpersonationRequest({ userId: user.id, clientId: environment.auth0ClientId })
      .pipe(
        finalize(() => {
          this.ref.detectChanges();
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (res) => {
          this.impersonation.setImpersonation(this.user);
          window.open(res.url.replace('hoxtonanalytics.eu.auth0.com', 'auth.hoxton.ai'), '_blank');
        },
        error: (error) => {
          this.notifyService.error(error);
        },
      });
  }

  clearMustContainString(): void {
    this.mustContainString = '';
    this.filterUsers();
  }

  openIncreaseUserLimitDialog(limit: number, admin?: boolean): void {
    const title =
      `You have reached your limit of ${limit} ` +
      (admin ? 'Admin ' : ' ') +
      GlobalMethods.pluraliseWord(limit, 'user') +
      '.';
    this.matDialog.open(LimitReachedInformationComponent, {
      data: {
        title: title,
        contactText:
          'Please contact Hoxton support to increase your allowance. We will contact you to confirm the details of your new user limits.',
        deleteText: 'Or delete an existing user to add another and stay within your existing allowance.',
      },
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
