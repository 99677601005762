import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import { NotificationGroup, NotificationGroupMappingService, NotificationGroupService, User } from 'src/app/api';
import { AccountService } from 'src/app/services/account.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { NotifyService } from 'src/app/services/notify.service';
import { SelectRecipientsComponent } from 'src/app/components/notifications/select-recipients/select-recipients.component';

@Component({
  selector: 'app-add-edit-notification-group',
  templateUrl: './add-edit-notification-group.component.html',
  styleUrls: ['./add-edit-notification-group.component.scss'],
})
export class AddEditNotificationGroupComponent implements OnInit, OnDestroy {
  notificaionGroupForm: FormGroup;
  usersMapByEmail: { [_: string]: User } = {};
  isLoading = false;
  saving = false;
  editing = false;
  editOnlyUsers = false;
  showOrganisationSelect = false;

  notificationGroups: NotificationGroup[] = [];
  filteredNotificationGroups: NotificationGroup[] = [];
  users: User[] = [];
  filteredUsers: User[] = [];
  selectedRecipients = [];

  @ViewChild(SelectRecipientsComponent)
  selectRecipientsComponent: SelectRecipientsComponent;
  ngUnsubscribe = new Subject();

  constructor(
    public accountService: AccountService,
    public dialogRef: MatDialogRef<AddEditNotificationGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notificationsService: NotificationsService,
    private notificationGroupService: NotificationGroupService,
    private notificationGroupMappingService: NotificationGroupMappingService,
    private formBuilder: FormBuilder,
    private notifyService: NotifyService,
  ) {}

  ngOnInit(): void {
    this.users = this.notificationsService.users;
    this.notificationGroups = this.notificationsService.notificationGroups;
    let organisationId = this.accountService.isSupport ? '' : this.accountService.organisation.id;
    this.showOrganisationSelect = this.accountService.isSupport;

    let id = undefined;
    let name = '';

    if (this.data?.notificationGroup) {
      this.editing = true;
      this.editOnlyUsers = this.data.editOnlyUsers;

      organisationId = this.data.notificationGroup.organisationId;
      id = this.data.notificationGroup.id;
      name = this.data.notificationGroup.name;

      this.notificationGroups = this.notificationGroups.filter((ng) => ng.id !== id);
      this.data.notificationGroup.emails?.forEach((email) => {
        const hoxtonUser = this.users.find((u) => u.email === email);
        this.selectedRecipients.push(hoxtonUser ? hoxtonUser : email);
      });

      this.notificationsService.notificationGroupNotificationGroupMap[id].forEach(
        (notificationGroupNotificationGroup) => {
          this.selectedRecipients.push(
            this.notificationsService.notificationGroupsMap[notificationGroupNotificationGroup.childId],
          );
        },
      );
    }

    this.notificaionGroupForm = this.formBuilder.group({
      id: [id],
      name: [name, [Validators.required]],
      organisationId: [organisationId, [Validators.required]],
    });
    if (organisationId) {
      this.filterNotificationGroupsByOrganisation();
    } else {
      this.filteredUsers = this.users;
      this.filteredNotificationGroups = this.notificationGroups;
    }
  }

  filterNotificationGroupsByOrganisation(): void {
    this.filteredNotificationGroups = this.notificationGroups.filter(
      (notificationGroup) => notificationGroup.organisationId === this.notificaionGroupForm.get('organisationId').value,
    );
    this.filteredUsers = this.users.filter(
      (notificationGroup) => notificationGroup.organisationId === this.notificaionGroupForm.get('organisationId').value,
    );
  }

  private getRecipientsEmails(recipients: any[]): string[] {
    const emails = [];
    recipients.forEach((recipient) => {
      if (typeof recipient === 'string') {
        emails.push(recipient);
      } else if (recipient.hasOwnProperty('firstName')) {
        emails.push(recipient.email);
      }
    });
    return emails;
  }

  private getRecipientsNotificationGroupsIDs(recipients: any[]): string[] {
    const notificationGroupsIDs = [];
    recipients.forEach((recipient) => {
      if (recipient.hasOwnProperty('emails')) {
        notificationGroupsIDs.push(recipient.id);
      }
    });
    return notificationGroupsIDs;
  }

  save(): void {
    if (this.selectRecipientsComponent.selectedRecipients.length < 2) {
      this.notifyService.warning('You have to add at least 2 members to a notification group!', null);
      return;
    }

    this.saving = true;
    this.notificationGroupService
      .putNotificationGroup({
        ...this.notificaionGroupForm.value,
        emails: this.getRecipientsEmails(this.selectRecipientsComponent.selectedRecipients),
      })
      .pipe(
        switchMap((notificationGroup: NotificationGroup) => {
          const requests: Observable<any>[] = [];

          if (this.editing) {
            this.notificationsService.notificationGroupNotificationGroupMap[this.notificaionGroupForm.value.id].forEach(
              (notificationGroupNotificationGroup) => {
                requests.push(
                  this.notificationGroupMappingService.deleteNotificationGroupNotificationGroup(
                    notificationGroupNotificationGroup.id,
                  ),
                );
              },
            );
          }
          this.getRecipientsNotificationGroupsIDs(this.selectRecipientsComponent.selectedRecipients).forEach(
            (notificationGroupId) => {
              requests.push(
                this.notificationGroupMappingService.putNotificationGroupNotificationGroup({
                  organisationId: this.notificaionGroupForm.value.organisationId,
                  parentId: notificationGroup?.id ? notificationGroup.id : this.notificaionGroupForm.value.id,
                  childId: notificationGroupId,
                }),
              );
            },
          );

          if (!requests.length) {
            return of([]);
          }

          return forkJoin(requests);
        }),
        finalize(() => {
          this.saving = false;
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (_) => {
          this.close(true);
        },
        error: (error) => {
          this.notifyService.error(error);
        },
      });
  }

  close(reload?: boolean): void {
    if (reload) {
      this.dialogRef.close('reload');
    } else {
      this.dialogRef.close();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
