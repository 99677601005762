<div class="o-step-body">
  <form class="main-content" [formGroup]="alertForm">
    <div class="o-heading--pica u-flush--bottom is-wrapping">
      <h1>Configure occupancy alert.</h1>
    </div>
    <ng-container *ngIf="edit">
      <div *ngIf="accountService.isSupport">
        <div class="c-inline-icon-text u-margin--bottom">
          <mat-icon class="u-grey-color"> shield </mat-icon>
          <h3 class="primer kirk u-flush--bottom">Organisation</h3>
        </div>
        <mat-form-field>
          <mat-label>Organisation</mat-label>
          <input autocomplete="off" matInput formControlName="organisationId" />
        </mat-form-field>
      </div>

      <div>
        <div class="c-inline-icon-text u-margin--bottom">
          <mat-icon class="u-grey-color">label</mat-icon>
          <h3 class="primer kirk u-flush--bottom">Alert name</h3>
        </div>
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input autocomplete="off" matInput placeholder="Name" formControlName="name" />
        </mat-form-field>
      </div>
    </ng-container>

    <div class="c-alert-box">
      <div class="o-slider-legend">
        <h1 class="is-label">Alert threshold</h1>
        <div class="is-value">{{ alertForm.get('alertThreshold').value | number: '1.0-0' }}%</div>
      </div>
      <mat-slider class="u-padding--bottom" [min]="0" [max]="100" #ngSlider
        ><input
          matSliderThumb
          (input)="
            alertForm.controls['alertThreshold'].setValue(
              { source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value }.value
            )
          "
          formControlName="alertThreshold"
          #ngSliderThumb="matSliderThumb"
        />
      </mat-slider>
      <div class="o-heading--normal is-all-wrapping">
        <h4>What % of capacity should this alert be sent at?</h4>
        <p>eg: If capacity is 100, select 90% to be alerted when occupancy reaches 90.</p>
      </div>
      <div class="c-recovery">
        <h3 class="is-title">Recovery threshold</h3>
        <mat-form-field class="c-recovery__input is-input">
          <input autocomplete="off" matInput formControlName="alertRecoveryThreshold" />
          <span matSuffix class="is-suffix">%</span>
        </mat-form-field>
        <a class="o-button is-small c-flush-button is-help" (click)="openRecoveryThresholdDetails()">
          <mat-icon class="is-icon-left">help</mat-icon>
          What is this?
        </a>
        <mat-error *ngIf="errors.alertRecoveryThreshold">{{ errors.alertRecoveryThreshold }}</mat-error>
        <div class="c-recovery__notify">
          <mat-checkbox formControlName="notifyAlertRecovery" class="c-check-label">
            <span class="brevier">Receive alerts when occupancy drops below this level.</span>
          </mat-checkbox>
        </div>
      </div>
    </div>

    <div class="c-warning-box">
      <div [ngClass]="alertForm.get('issueWarning').value ? 'c-warning-box--primary' : 'c-warning-box--secondary'">
        <div class="is-header">
          RECEIVE WARNING ALERTS
          <mat-slide-toggle formControlName="issueWarning" (change)="issueWarning()"> </mat-slide-toggle>
        </div>
      </div>
      <div *ngIf="alertForm.get('issueWarning').value" class="c-warning-box--secondary">
        <div class="o-slider-legend">
          <h1 class="is-label">Warning threshold</h1>
          <div class="is-value">{{ alertForm.get('warningThreshold').value | number: '1.0-0' }}%</div>
        </div>
        <mat-slider class="u-padding--bottom" [min]="0" [max]="100" #ngSlider
          ><input
            matSliderThumb
            (input)="
              alertForm.controls['warningThreshold'].setValue(
                { source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value }.value
              )
            "
            formControlName="warningThreshold"
            #ngSliderThumb="matSliderThumb"
          />
        </mat-slider>
        <div class="o-heading--normal is-all-wrapping">
          <p>What % of capacity should this alert be sent at?</p>
          <p>eg: If capacity is 100, select 80% to be alerted when occupancy reaches 80.</p>
        </div>

        <div class="c-recovery">
          <h3 class="is-title">Recovery threshold</h3>
          <mat-form-field class="c-recovery__input is-input">
            <input autocomplete="off" matInput formControlName="warningRecoveryThreshold" />
            <span matSuffix class="is-suffix">%</span>
          </mat-form-field>
          <a class="o-button is-small c-flush-button is-help" (click)="openRecoveryThresholdDetails()">
            <mat-icon class="is-icon-left">help</mat-icon>
            What is this?
          </a>
          <mat-error *ngIf="errors.warningRecoveryThreshold">{{ errors.warningRecoveryThreshold }}</mat-error>
          <div class="c-recovery__notify">
            <mat-checkbox formControlName="notifyWarningRecovery" class="c-check-label">
              <span class="brevier">Receive alerts when occupancy drops below this level.</span>
            </mat-checkbox>
          </div>
        </div>
        <mat-error *ngIf="errors.warningThreshold">{{ errors.warningThreshold }}</mat-error>
      </div>
    </div>

    <div>
      <div class="o-flex u-padding--bottom">
        <mat-icon class="u-grey-color"> email </mat-icon>
        <div class="o-heading--primer u-flush--bottom is-wrapping">
          <h3>Select recipients.</h3>
          <p>Choose a notification group or single email address.</p>
        </div>
      </div>
      <app-select-recipients
        [users]="filteredUsers"
        [notificationGroups]="filteredNotificationGroups"
        [selectedRecipients]="selectedRecipients"
      ></app-select-recipients>
    </div>
  </form>
</div>
