import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { User } from '@auth0/auth0-spa-js';
import * as DarkReader from 'darkreader';
import { filter, finalize, switchMap, take, takeUntil } from 'rxjs/operators';
import { NavigationRightSideComponent } from './components/general/navigation-right-side/navigation-right-side.component';
import * as Sentry from '@sentry/angular';
import { HttpClient } from '@angular/common/http';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';
import { forkJoin, Observable, Subject } from 'rxjs';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { GlobalMethods } from 'src/app/global-methods';
import { AccountService } from 'src/app/services/account.service';
import { ImpersonationService } from 'src/app/services/impersonation.service';
import { UpdateService } from 'src/app/services/update.service';
import { environment } from 'src/environments/environment';
import {
  Alert,
  AlertCameraPositionMappingService,
  AlertService,
  CameraService,
  OAuthClientService,
  Organisation,
  OrganisationService,
  Site,
  SiteService,
} from './api';
import { FindOutMoreModalDialogComponent } from './components/notifications/find-out-more-modal-dialog/find-out-more-modal-dialog.component';
import { NewCameraSummariesComponent } from './components/notifications/new-camera-summaries/new-camera-summaries.component';
import { CameraStatus } from './model/cameraStatus';
import { CamerasService } from './services/cameras.service';
import { NotifyService } from './services/notify.service';
import { NewFeatureComponent } from 'src/app/components/general/new-feature/new-feature.component';

declare var gtag: Function;
declare global {
  interface Window {
    hsConversationsSettings: any;
    hsConversationsOnReady: any;
    HubSpotConversations: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  user: User;
  isLoading = false;
  loggedInAs: User;
  impersonated: boolean = false;
  darkReader: any = DarkReader;
  selectedTheme: string;

  cameras: CameraStatus[] = [];
  summaryAlerts: Alert[] = [];
  camerasWithNoOfflineAlerts: CameraStatus[] = [];
  camerasWithNoSummaryAlert: CameraStatus[] = [];
  offlineCamerasWithNoAlerts: CameraStatus[] = [];
  issueCamerasWithNoSummaries: CameraStatus[] = [];
  showOfflineCamerasWithNoAlertsBanner;
  reminderOfflineCamerasWithNoAlertsBanner;
  showOfflineCamerasWithNoSummaryBanner;
  reminderOfflineCamerasWithNoSummaryBanner;

  ngUnsubscribe = new Subject();

  constructor(
    public auth: AuthService,
    private cookieService: CookieService,
    private localStorageService: LocalStorageService,
    public accountService: AccountService,
    private organisationService: OrganisationService,
    private http: HttpClient,
    private router: Router,
    private matDialog: MatDialog,
    private updates: UpdateService,
    private impersonation: ImpersonationService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private ref: ChangeDetectorRef,
    private notifyService: NotifyService,
    private alertService: AlertService,
    private alertCameraPositionMappingService: AlertCameraPositionMappingService,
    private cameraService: CameraService,
    private camerasService: CamerasService,
    private siteService: SiteService,
    private oAuthClientService: OAuthClientService,
  ) {
    this.darkReader.setFetchMethod(window.fetch);
    this.setTheme(this.localStorageService.get('_hai_theme_mode'));
    this.matIconRegistry.addSvgIcon(
      'balena',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/balena.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'gcp_cloud_logging',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/gcp_cloud_logging.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'grafana',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/grafana.svg'),
    );

    this.updates.checkForUpdates();
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.ref.detectChanges();
    this.auth.isAuthenticated$
      .pipe(
        filter((isAuthenticated) => isAuthenticated),
        take(1),
        switchMap((_) => {
          return forkJoin([
            this.auth.getAccessTokenSilently(),
            this.organisationService.listOrganisations(),
            this.oAuthClientService.listOAuthClientLinks(),
            this.auth.user$.pipe(take(1)),
          ]);
        }),
      )
      .pipe(
        takeUntil(this.ngUnsubscribe),
        take(1),
        finalize(() => {
          this.setOAuthClients();
        }),
      )
      .subscribe({
        next: ([token, organisations, oAuthClientLinks, user]) => {
          const jwtHelper = new JwtHelperService();
          const decoded_token = jwtHelper.decodeToken(token);

          this.accountService.user = user;
          this.accountService.role = decoded_token.app_metadata.rbac_role;
          this.accountService.isSupport = decoded_token.app_metadata.rbac_role === 'support';
          this.accountService.isAdmin = decoded_token.app_metadata.rbac_role === 'admin';
          this.accountService.isMember = decoded_token.app_metadata.rbac_role === 'member';
          this.accountService.canImpersonate = this.accountService.isSupport;

          if (environment.auth0Audience.includes('stage') || this.accountService.isSupport) {
            this.accountService.showNotReleased = true;
          }

          this.accountService.canCreateOAuth = this.accountService.isSupport || this.accountService.isAdmin;
          this.accountService.canReadOAuth = this.accountService.isSupport || this.accountService.isAdmin;
          this.accountService.canUpdateOAuth = this.accountService.isSupport || this.accountService.isAdmin;

          this.accountService.oAuthClientLinks = oAuthClientLinks ? oAuthClientLinks : [];
          ({ effectiveUser: this.user, impersonated: this.impersonated } = this.impersonation.getEffictiveUser(user));
          if (this.impersonated) {
            this.loggedInAs = user;
          }

          if (!this.accountService.isSupport && !this.accountService.isAdmin && !this.accountService.isMember) {
            this.accountService.hasAppAccess = false;
            this.router.navigate(['no-access']);
            return;
          }

          if (!organisations || organisations.length === 0) {
            this.accountService.hasAppAccess = false;
            this.router.navigate(['no-access']);
            return;
          }

          this.accountService.peopleCountLicence =
            organisations.length === 1 ? organisations[0].licence.includes('people_count') : true;
          this.accountService.occupancyLicence =
            organisations.length === 1 ? organisations[0].licence.includes('occupancy_monitor') : true;
          this.accountService.dataExplorerLicence =
            organisations.length === 1 ? organisations[0].licence.includes('data_explorer') : true;

          organisations.forEach((organisation: Organisation) => {
            this.accountService.organisationsMap[organisation.id] = organisation;
          });
          if (organisations.length === 1) {
            this.accountService.organisation = organisations[0];
          } else {
            this.accountService.organisations = organisations.sort((e1: Organisation, e2: Organisation) => {
              if (e1.name?.toLowerCase() > e2.name?.toLowerCase()) {
                return 1;
              } else {
                return -1;
              }
            });
          }

          this.loadHubSpotLiveChatWidget();
          if (environment.gtagId) {
            // register google tag manager
            const gTagManagerScript = document.createElement('script');
            gTagManagerScript.async = true;
            gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.gtagId}`;
            document.head.appendChild(gTagManagerScript);

            // register google analytics
            const gaScript = document.createElement('script');
            gaScript.innerHTML = `
              window.dataLayer = window.dataLayer || [];
              function gtag() { dataLayer.push(arguments); }
              gtag('js', new Date());
              gtag('config', '${environment.gtagId}',{send_page_view: false});
            `;
            document.head.appendChild(gaScript);

            const navigationEvents = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));
            gtag('set', 'user_properties', {
              user_id: this.user.email,
              userID: this.user.email,
            });
            if (organisations.length === 1 && !this.impersonated) {
              gtag('set', 'user_properties', {
                OrganisationName: organisations[0].name,
                OrganisationID: organisations[0].id,
              });
            } else {
              gtag('set', 'user_properties', {
                OrganisationName: 'Hoxton support',
                OrganisationID: 'Hoxton support',
              });
            }

            navigationEvents.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
              next: (event: NavigationEnd) => {
                gtag('event', 'page_view', {
                  page_title: event.urlAfterRedirects,
                  page_location: event.urlAfterRedirects,
                  page_path: event.urlAfterRedirects,
                  user_id: this.user.email,
                });
              },
            });
          }

          Sentry.setUser({
            id: this.accountService.user?.sub,
            role: this.accountService.role,
            org_id: this.accountService.organisation?.id,
          });
        },
        error: (error) => {
          console.log(error);
          this.router.navigate(['internal-error']);
        },
      });
  }

  setOAuthClients() {
    if (this.accountService.isAdmin) {
      const request: Observable<any>[] = [];
      this.accountService.oAuthClientLinks.forEach((oAuthClientLink) => {
        request.push(this.oAuthClientService.getOAuthClient(oAuthClientLink.clientId));
      });
      forkJoin(request)
        .pipe(
          finalize(() => {
            this.isLoading = false;
            this.accountService.isLoading$.next(false);
            this.ref.detectChanges();
          }),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe({
          next: (oAuthClients) => {
            this.accountService.oAuthClients = oAuthClients;
          },
          error: (error) => {
            this.notifyService.error(error);
          },
        });
    } else {
      this.isLoading = false;
      this.accountService.isLoading$.next(false);
      this.ref.detectChanges();
    }
  }

  showCameraAlertsBanner(): void {
    forkJoin([
      this.cameraService.listCameras(false, undefined, undefined, ['running', 'paused']),
      this.siteService.listSites(undefined, undefined, 'active'),
      this.alertService.listAlerts('offline_alert', undefined, undefined, undefined, undefined, undefined, 'active'),
      this.alertService.listAlerts(
        'camera_health_summary_alert',
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
      ),
      this.alertCameraPositionMappingService.listAlertCameraPositionMappings(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
        'offline_alert',
      ),
      this.alertCameraPositionMappingService.listAlertCameraPositionMappings(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
        'camera_health_summary_alert',
      ),
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: ([
          cameras,
          sites,
          offlineAlerts,
          summaryAlerts,
          offlineAlertCameraPositions,
          summaryAlertCameraPositions,
        ]) => {
          const sitesMap: { [_: string]: Site } = {};
          sites.forEach((site: Site) => (sitesMap[site.id] = site));

          this.cameras = cameras.map((c) => this.camerasService.getCameraStatus(c, sitesMap[c.siteId]));

          this.summaryAlerts = summaryAlerts;

          this.camerasWithNoOfflineAlerts = cameras.filter((c) => c.state === 'running');
          this.camerasWithNoSummaryAlert = cameras.filter((c) => c.state === 'running');

          const offlineAlertMapOfflineAlertCameraPositions: { [_: string]: boolean } = {};
          const summaryAlertMapSummaryAlertCameraPositions: { [_: string]: boolean } = {};

          offlineAlertCameraPositions.forEach((mapping) => {
            offlineAlertMapOfflineAlertCameraPositions[mapping.alertId] = true;
            this.camerasWithNoOfflineAlerts = this.camerasWithNoOfflineAlerts.filter(
              (c) => c.cameraPositionId !== mapping.cameraPositionId,
            );
          });
          summaryAlertCameraPositions.forEach((mapping) => {
            summaryAlertMapSummaryAlertCameraPositions[mapping.alertId] = true;
            this.camerasWithNoSummaryAlert = this.camerasWithNoSummaryAlert.filter(
              (c) => c.cameraPositionId !== mapping.cameraPositionId,
            );
          });

          offlineAlerts.forEach((alert) => {
            if (!alert.siteId) {
              this.camerasWithNoOfflineAlerts = this.camerasWithNoOfflineAlerts.filter(
                (c) => c.organisationId !== alert.organisationId,
              );
            } else if (!offlineAlertMapOfflineAlertCameraPositions[alert.id]) {
              this.camerasWithNoOfflineAlerts = this.camerasWithNoOfflineAlerts.filter(
                (c) => c.siteId !== alert.siteId,
              );
            }
          });
          this.offlineCamerasWithNoAlerts = this.camerasWithNoOfflineAlerts.filter(
            (camera: CameraStatus) => !camera.isOnline,
          );

          summaryAlerts.forEach((alert) => {
            if (!alert.siteId) {
              this.camerasWithNoSummaryAlert = this.camerasWithNoSummaryAlert.filter(
                (c) => c.organisationId !== alert.organisationId,
              );
            } else if (!summaryAlertMapSummaryAlertCameraPositions[alert.id]) {
              this.camerasWithNoSummaryAlert = this.camerasWithNoSummaryAlert.filter((c) => c.siteId !== alert.siteId);
            }
          });
          this.issueCamerasWithNoSummaries = this.camerasWithNoSummaryAlert.filter((camera: CameraStatus) =>
            this.camerasService.hasIssue(camera),
          );

          const firstShownCameraAlerts = this.cookieService.get('_first_cameras_alerts');
          if (firstShownCameraAlerts) {
            const firstShownCameraAlertsDate = new Date(JSON.parse(firstShownCameraAlerts));
            if (
              Math.ceil(Math.abs(Number(new Date()) - Number(firstShownCameraAlertsDate)) / (1000 * 60 * 60 * 24)) < 14
            ) {
              this.showOfflineCamerasWithNoAlertsBanner = this.offlineCamerasWithNoAlerts.length;
            } else {
              this.closeOfflineCamerasWithNoAlertsBanner();
            }
          } else {
            this.showOfflineCamerasWithNoAlertsBanner =
              this.offlineCamerasWithNoAlerts.length && this.cookieService.get('_dont_show_cameras_alerts') !== 'true';

            if (this.showOfflineCamerasWithNoAlertsBanner) {
              this.cookieService.set('_first_cameras_alerts', JSON.stringify(new Date()));

              const sawOfflineCamerasWithNoAlertsBanner = this.cookieService.get('_saw_cameras_alerts');
              if (sawOfflineCamerasWithNoAlertsBanner === 'true') {
                this.reminderOfflineCamerasWithNoAlertsBanner = true;
              } else {
                this.cookieService.set('_saw_cameras_alerts', 'true');
              }
            }
          }

          const cameraSummariesFirstShown = this.cookieService.get('_cameras_summaries_first_showen');
          if (cameraSummariesFirstShown) {
            const cameraSummariesFirstShownDate = new Date(JSON.parse(cameraSummariesFirstShown));
            if (
              Math.ceil(Math.abs(Number(new Date()) - Number(cameraSummariesFirstShownDate)) / (1000 * 60 * 60 * 24)) <
              14
            ) {
              this.showOfflineCamerasWithNoSummaryBanner = this.issueCamerasWithNoSummaries.length;
            } else {
              this.closeOfflineCamerasWithNoSummaryBanner();
            }
          } else {
            this.showOfflineCamerasWithNoSummaryBanner =
              this.issueCamerasWithNoSummaries.length &&
              this.cookieService.get('_dont_show_cameras_summaries') !== 'true';

            if (this.showOfflineCamerasWithNoSummaryBanner) {
              this.cookieService.set('_cameras_summaries_first_showen', JSON.stringify(new Date()));

              const sawOfflineCamerasWithNoSummaryBanner = this.cookieService.get('_saw_cameras_summaries');
              if (sawOfflineCamerasWithNoSummaryBanner === 'true') {
                this.reminderOfflineCamerasWithNoSummaryBanner = true;
              } else {
                this.cookieService.set('_saw_cameras_summaries', 'true');
              }
            }
          }

          this.ref.detectChanges();
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  showNewFeatureDialog(): void {
    // Dwell-time reports feature is only available for occupancy customers. Don't so the new feature banner for others.
    if (
      !this.accountService.organisation?.licence.includes(Organisation.LicenceEnum.OccupancyMonitor) &&
      !this.accountService.isSupport
    ) {
      return;
    }
    if (GlobalMethods.showNewFeatureDialog(this.cookieService)) {
      this.matDialog.open(NewFeatureComponent);
    }
  }

  closeOfflineCamerasWithNoAlertsBanner(): void {
    this.showOfflineCamerasWithNoAlertsBanner = false;
    let expiredDate = new Date();
    expiredDate.setDate(expiredDate.getDate() + 28);
    this.cookieService.delete('_first_cameras_alerts');
    this.cookieService.set('_dont_show_cameras_alerts', 'true', expiredDate);
  }

  closeOfflineCamerasWithNoSummaryBanner(): void {
    this.showOfflineCamerasWithNoSummaryBanner = false;
    let expiredDate = new Date();
    expiredDate.setDate(expiredDate.getDate() + 28);
    this.cookieService.delete('_cameras_summaries_first_showen');
    this.cookieService.set('_dont_show_cameras_summaries', 'true', expiredDate);
  }

  openFindOutMoreCameraAlerts(): void {
    this.matDialog.open(FindOutMoreModalDialogComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data: {
        cameras: this.cameras,
        camerasPronedToIssues: this.camerasWithNoOfflineAlerts,
        offlineCamerasWithIssues: this.offlineCamerasWithNoAlerts,
        issueType: 'alert',
      },
    });
  }

  openFindOutMoreCameraSummaries(): void {
    this.matDialog.open(FindOutMoreModalDialogComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data: {
        cameras: this.cameras,
        camerasPronedToIssues: this.camerasWithNoSummaryAlert,
        offlineCamerasWithIssues: this.issueCamerasWithNoSummaries,
        issueType: 'summary',
      },
    });
  }

  openSetupInstructionsForCameraSummaries(): void {
    this.matDialog.open(NewCameraSummariesComponent, {
      data: {
        hasLink: true,
      },
    });
  }

  loadHubSpotLiveChatWidget(): void {
    this.http
      .post(`${environment.hubspot.visitorIdentificationUrl}`, {
        email: this.user.email,
        firstName: this.user.given_name,
        lastName: this.user.family_name,
      })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (resp: any) => {
          let token = resp.token;
          window.hsConversationsSettings = {
            identificationEmail: this.user.email,
            identificationToken: token,
          };
          if (window.HubSpotConversations) {
            window.HubSpotConversations.widget.load();
          } else {
            window.hsConversationsOnReady = [
              () => {
                window.HubSpotConversations.widget.load();
              },
            ];
          }
        },
        error: (error) => {
          if (this.accountService.isSupport) {
            this.notifyService.error(error);
          }
        },
      });
  }

  openNavigationRightSide(): void {
    this.matDialog.open(NavigationRightSideComponent, {
      height: '100vh',
      maxWidth: '90vw',
      position: { right: '0' },
      panelClass: ['mat-dialog-side-menu', 'drawer-nav__right'],
    });
  }

  changeTheme(choice: string): void {
    this.localStorageService.set('_hai_theme_mode', choice);
    this.setTheme(choice);
  }

  setTheme(choice = 'light'): void {
    this.selectedTheme = choice;
    switch (this.selectedTheme) {
      case 'dark': {
        this.darkReader.enable({ brightness: 180, contrast: 90, sepia: 30 }, { invert: ['img[src$=".svg"]'] });
        break;
      }
      case 'light': {
        this.darkReader.disable();
        break;
      }
      case 'system': {
        this.darkReader.auto({ brightness: 180, contrast: 90, sepia: 30 }, { invert: ['img[src$=".svg"]'] });
        break;
      }
    }
  }

  logout(): void {
    if (this.impersonated) {
      this.impersonation.clearImpersonation();
    }
    this.auth.logout();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
