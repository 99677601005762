import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { forkJoin, Subject, takeUntil, finalize, Observable } from 'rxjs';
import { Alert, AlertCameraPositionMappingService, AlertService, Camera, Site } from 'src/app/api';
import { NotificationsService } from 'src/app/services/notifications.service';
import { NotifyService } from 'src/app/services/notify.service';
import { AddCameraToResourceDialogComponent } from 'src/app/views/notifications/components/add-camera-to-resource-dialog/add-camera-to-resource-dialog.component';

@Component({
  selector: 'app-no-camera-summaries',
  templateUrl: './no-camera-summaries.component.html',
  styleUrls: ['./no-camera-summaries.component.scss'],
})
export class NoCameraSummariesComponent implements OnInit, OnDestroy {
  isLoading = false;

  cameras: Camera[] = [];
  filteredCameras: Camera[] = [];
  sites: Site[] = [];
  sitesMap = {};
  selectedSites: Site[] = [];
  selection = new SelectionModel<string>(true, []);
  summaryAlerts: Alert[] = [];

  private ngUnsubscribe = new Subject();
  constructor(
    private notificationsService: NotificationsService,
    private alertCameraPositionMappingService: AlertCameraPositionMappingService,
    private ref: ChangeDetectorRef,
    private matDialog: MatDialog,
    private router: Router,
    private alertService: AlertService,
    private notifyService: NotifyService,
  ) {
    this.sites = this.notificationsService.sites;
    this.sitesMap = this.notificationsService.sitesMap;
  }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.isLoading = true;
    this.ref.detectChanges();

    forkJoin([
      this.alertCameraPositionMappingService.listAlertCameraPositionMappings(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
        'camera_health_summary_alert',
      ),
      this.alertService.listAlerts(
        'camera_health_summary_alert',
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
      ),
    ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.isLoading = false;
          this.applyFilter();
          this.ref.detectChanges();
        }),
      )
      .subscribe({
        next: ([summaryAlertCameraPositions, summaryAlerts]) => {
          this.summaryAlerts = summaryAlerts;

          var camerasWithNoSummaryAlert = this.notificationsService.cameras.filter((c) => c.state === 'running');

          const summaryAlertMapSummaryAlertCameraPositions: { [_: string]: boolean } = {};

          summaryAlertCameraPositions.forEach((mapping) => {
            summaryAlertMapSummaryAlertCameraPositions[mapping.alertId] = true;
            camerasWithNoSummaryAlert = camerasWithNoSummaryAlert.filter(
              (c) => c.cameraPositionId !== mapping.cameraPositionId,
            );
          });

          summaryAlerts.forEach((alert) => {
            if (!alert.siteId) {
              camerasWithNoSummaryAlert = camerasWithNoSummaryAlert.filter(
                (c) => c.organisationId !== alert.organisationId,
              );
            } else if (!summaryAlertMapSummaryAlertCameraPositions[alert.id]) {
              camerasWithNoSummaryAlert = camerasWithNoSummaryAlert.filter((c) => c.siteId !== alert.siteId);
            }
          });
          this.cameras = camerasWithNoSummaryAlert;
          if (this.cameras.length === 0) {
            this.router.navigate(['alerts/camera-summaries']);
          }
        },
        error: (_) => {
          this.router.navigate(['internal-error']);
        },
      });
  }

  applyFilter(sites?: Site[]): void {
    this.selectedSites = sites && sites.length !== 0 ? sites : this.sites;
    this.filter();
  }

  filter() {
    let selectedSiteIds = this.selectedSites.map((site) => site.id);
    this.filteredCameras = this.cameras.filter((camera) => selectedSiteIds.includes(camera.siteId));
  }

  addToSummary(): void {
    const cameraIds = this.selection.selected;
    const dialogRef = this.matDialog.open(AddCameraToResourceDialogComponent, {
      width: '330px',
      data: {
        resourceType: 'summary',
        resources: this.summaryAlerts.filter((summary) => {
          if (!summary.siteId) {
            return this.cameras
              .filter((c) => cameraIds.includes(c.id))
              .find((c) => c.organisationId === summary.organisationId);
          } else {
            return this.cameras.filter((c) => cameraIds.includes(c.id)).find((c) => c.siteId === summary.siteId);
          }
        }),
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (result: string[]) => {
        if (result) {
          const requests: Observable<any>[] = [];

          result.forEach((cameraHealthSummaryConfigurationId: string) => {
            cameraIds.forEach((cameraId: string) => {
              requests.push(
                this.alertCameraPositionMappingService.putAlertCameraPositionMapping({
                  alertId: cameraHealthSummaryConfigurationId,
                  cameraPositionId: cameraId,
                }),
              );
            });
          });

          forkJoin(requests).subscribe({
            next: (_) => {
              this.notifyService.success('Summaries succesfully added');
              this.getData();
            },
            error: (error) => {
              this.notifyService.error(error);
            },
          });
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
