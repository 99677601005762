<div class="o-step-body">
  <div class="o-form u-padding--inline">
    <div>
      <div class="c-inline-icon-text u-margin--bottom">
        <mat-icon class="u-grey-color"> groups </mat-icon>
        <h3 class="primer kirk u-flush--bottom">
          {{ edit ? 'The alert is used for the following monitors' : 'Which monitors will use this alert?' }}
        </h3>
      </div>
      <div class="is-input c-monitor-list">
        <ul class="o-list" *ngIf="monitors.length; else noMonitors">
          <li class="o-list__item c-list-item is-head" *ngIf="!edit">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="masterToggle()"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
            >
              <div class="is-primary-text">All occupancy monitors ({{ monitors.length }})</div>
              <div class="is-secondary-text">Monitors you add in the future will be automaticaly added.</div>
            </mat-checkbox>
          </li>
          <div class="c-monitor-list__overflow">
            <li class="o-list__item" *ngFor="let monitor of monitors">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="selection.toggle(monitor)"
                [checked]="selection.isSelected(monitor)"
                >{{ monitor.name }}
              </mat-checkbox>
            </li>
          </div>
        </ul>
        <ng-template #noMonitors>
          <div class="c-no-content">
            <h4 class="is-title u-flush--bottom">No monitors available.</h4>
            <div class="is-media">
              <img src="/assets/images/no-monitor-placeholder.png" alt="No monitors available" />
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
