import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

type stepTypeEnum = 'ready' | 'current' | 'unready';

@Component({
  selector: 'app-site-setup-side-panel',
  templateUrl: './site-setup-side-panel.component.html',
  styleUrls: ['./site-setup-side-panel.component.scss'],
})
export class SiteSetupSidePanelComponent implements OnInit {
  @Input() setupProgress = 0;
  @Input() activeStep = 0;
  @Input() site;
  @Input() isBackable = false;

  StepTypeEnum = {
    ready: 'ready' as stepTypeEnum,
    current: 'current' as stepTypeEnum,
    unready: 'unready' as stepTypeEnum,
  };

  steps = [
    {
      name: 'Site details',
      icon: 'tag',
      type: this.StepTypeEnum.current,
    },
    {
      name: 'Site opening hours',
      icon: 'more_time',
      type: this.StepTypeEnum.unready,
    },
    {
      name: 'Review',
      icon: 'checklist',
      type: this.StepTypeEnum.unready,
    },
  ];

  @Output() openPreviousStep: EventEmitter<void> = new EventEmitter();

  ngOnInit(): void {
    this.steps = this.steps.map((step, index) => {
      let type;

      if (index < this.activeStep) {
        type = this.StepTypeEnum.ready;
      }
      if (index === this.activeStep) {
        type = this.StepTypeEnum.current;
      }
      if (index > this.activeStep) {
        type = this.StepTypeEnum.unready;
      }

      return { ...step, type };
    });
  }

  goToPreviousStep(): void {
    this.openPreviousStep.emit();
  }
}
