import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Alert, NotificationGroup, User } from 'src/app/api';
import { AccountService } from 'src/app/services/account.service';
import { UserConfirmationComponent } from 'src/app/components/general/user-confirmation/user-confirmation.component';
import { SelectRecipientsComponent } from 'src/app/components/notifications/select-recipients/select-recipients.component';

@Component({
  selector: 'app-occupancy-alert-details',
  templateUrl: './occupancy-alert-details.component.html',
  styleUrls: ['./occupancy-alert-details.component.scss'],
})
export class OccupancyAlertDetailsComponent implements OnInit {
  @Input() organisationId;
  @Input() notificationGroups: NotificationGroup[] = [];
  filteredNotificationGroups: NotificationGroup[] = [];
  @Input() users: User[] = [];
  filteredUsers: User[] = [];
  alertForm: FormGroup;
  errors = { warningThreshold: '', warningRecoveryThreshold: '', alertRecoveryThreshold: '' };
  edit = false;

  selectedRecipients = [];

  @ViewChild(SelectRecipientsComponent)
  selectRecipientsComponent: SelectRecipientsComponent;

  @Input() set occupancyAlertRuleNotificationGroups(occupancyAlertRuleNotificationGroups: NotificationGroup[]) {
    occupancyAlertRuleNotificationGroups.forEach((occupancyAlertRuleNotificationGroup) => {
      this.selectedRecipients.push(occupancyAlertRuleNotificationGroup);
    });
  }

  @Input() set occupancyAlert(occupancyAlert: Alert) {
    this.alertForm.controls.id.patchValue(occupancyAlert.id);
    this.alertForm.controls.name.patchValue(occupancyAlert.name);
    this.alertForm.controls.alertThreshold.patchValue(occupancyAlert.configurationDetails['alertThreshold'] * 100);
    this.alertForm.controls.notifyAlertRecovery.patchValue(occupancyAlert.configurationDetails['notifyAlertRecovery']);
    this.alertForm.controls.issueWarning.patchValue(
      occupancyAlert.configurationDetails['warningThreshold'] ? true : false,
    );
    this.alertForm.controls.warningThreshold.patchValue(occupancyAlert.configurationDetails['warningThreshold'] * 100);
    this.alertForm.controls.notifyWarningRecovery.patchValue(
      occupancyAlert.configurationDetails['notifyWarningRecovery'],
    );
    this.alertForm.controls.organisationId.patchValue(occupancyAlert.organisationId);
    this.organisationId = occupancyAlert.organisationId;

    occupancyAlert.emails?.forEach((email) => {
      const hoxtonUser = this.users.find((u) => u.email === email);
      this.selectedRecipients.push(hoxtonUser ? hoxtonUser : email);
    });

    this.issueWarning();
    this.edit = true;
    this.alertForm.controls.alertRecoveryThreshold.patchValue(
      occupancyAlert.configurationDetails['alertRecoveryThreshold'] * 100,
    );
    this.alertForm.controls.warningRecoveryThreshold.patchValue(
      occupancyAlert.configurationDetails['warningRecoveryThreshold'] * 100,
    );
  }

  constructor(
    private formBuilder: FormBuilder,
    private matDialog: MatDialog,
    public accountService: AccountService,
  ) {
    this.alertForm = this.formBuilder.group({
      id: [undefined],
      name: [''],
      alertThreshold: [0, [Validators.required, Validators.min(1), Validators.max(100)]],
      alertRecoveryThreshold: [0, [Validators.min(1), Validators.max(100)]],
      notifyAlertRecovery: [false],
      issueWarning: [false],
      warningThreshold: [0],
      warningRecoveryThreshold: [0],
      notifyWarningRecovery: [false],
      organisationId: [{ value: '', disabled: true }],
    });

    this.alertForm.get('alertThreshold').valueChanges.subscribe((alertThreshold: number) => {
      this.alertForm.get('alertRecoveryThreshold').setValue(alertThreshold > 5 ? alertThreshold - 5 : 0);
    });
    this.alertForm.get('warningThreshold').valueChanges.subscribe((warningThreshold: number) => {
      this.alertForm.get('warningRecoveryThreshold').setValue(warningThreshold > 5 ? warningThreshold - 5 : 0);
    });
  }

  ngOnInit() {
    this.filteredNotificationGroups = this.notificationGroups.filter(
      (notificationGroup) => notificationGroup.organisationId === this.organisationId,
    );
    this.filteredUsers = this.users.filter(
      (notificationGroup) => notificationGroup.organisationId === this.organisationId,
    );
  }

  issueWarning(): void {
    if (this.alertForm.controls.issueWarning.value) {
      this.alertForm.get('warningThreshold').setValidators([Validators.min(1), Validators.max(100)]);
      this.alertForm.get('warningRecoveryThreshold').setValidators([Validators.min(1), Validators.max(100)]);
      this.alertForm.controls['alertThreshold'].value;
    } else {
      this.alertForm.get('warningThreshold').setValidators([]);
      this.alertForm.get('warningRecoveryThreshold').setValidators([]);
      this.alertForm.get('warningThreshold').setValue(0);
      this.alertForm.get('warningRecoveryThreshold').setValue(0);
    }

    this.alertForm.get('warningThreshold').updateValueAndValidity();
    this.alertForm.get('warningRecoveryThreshold').updateValueAndValidity();
  }

  openRecoveryThresholdDetails(): void {
    const message = `A recovery threshold helps avoid too many email alerts being sent.

    In some situations an occupancy alert or recovery notification can be triggered every time a person enters or leaves a space.
    
    We recomment a recovery threshold 5% below the main alert value. For example: if an alert is to be sent when the occupancy exceeds 100, a recovery email would only be sent when the occcupancy goes below 95 again and second alert cannot be sent until after the recovery email.`;

    this.matDialog.open(UserConfirmationComponent, {
      data: { message },
    });
  }
}
