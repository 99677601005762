<div class="o-dialog">
  <div class="o-dialog__title">
    <div class="c-title u-margin--bottom">
      <mat-icon> access_time </mat-icon>
      {{ dialogTitle }}
    </div>
    <div *ngIf="dialogTopDescription">
      {{ dialogTopDescription }}
    </div>
  </div>
  <div class="o-dialog__body">
    <div class="c-time-picker-wrapper">
      <mat-form-field appearance="outline">
        <mat-label>Start</mat-label>
        <input matInput [ngxTimepicker]="toggleTimepickerStart" readonly placeholder="HH" [(ngModel)]="start" />
        <ngx-material-timepicker
          #toggleTimepickerStart
          [disableAnimation]="true"
          [hoursOnly]="true"
        ></ngx-material-timepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>End</mat-label>
        <input matInput [ngxTimepicker]="toggleTimepickerEnd" readonly placeholder="HH" [(ngModel)]="end" />
        <ngx-material-timepicker
          #toggleTimepickerEnd
          [disableAnimation]="true"
          [hoursOnly]="true"
        ></ngx-material-timepicker>
      </mat-form-field>
    </div>
    <mat-checkbox class="c-checkbox-default-option" [(ngModel)]="saveAsDefault">Set as default.</mat-checkbox>
  </div>
  <div class="o-dialog__actions">
    <button mat-button class="is-destructive is-uppercase" (click)="close()">CANCEL</button>
    <button mat-button class="is-constructive is-uppercase" (click)="update()">UPDATE</button>
  </div>
</div>
