<mat-horizontal-stepper linear class="dialog-stepper" #stepper>
  <mat-step [stepControl]="peelOffMonitorForm">
    <app-step
      [stepTitle]="stepsTitle"
      [isBackable]="false"
      [processingStep]="isLoading"
      [disabledNextStep]="peelOffMonitorForm.invalid"
      (openNextStep)="goToNextStep()"
      (closeStep)="close()"
      class="c-first-step"
    >
      <div class="c-modal-content">
        <app-monitor-setup-side-panel
          [monitorType]="'peel-off'"
          [monitor]="peelOffMonitor"
          [activeStep]="0"
        ></app-monitor-setup-side-panel>
        <div class="c-peel-off-container">
          <div class="c-whats-peel-off-container">
            <div class="c-whats-peel-off-content">
              <mat-icon class="c-icon-fork">fork_left</mat-icon>
              <div class="c-whats-peel-off-wrapper">
                <div class="c-title-whats-peel-off">What is a peel off monitor?</div>
                <div class="c-subtitle-whats-peel-off">
                  Peel-off monitors calculate the percentage of passing traffic that enters your store or exhibit.
                </div>

                <div style="padding: 56.25% 0 0 0; position: relative">
                  <iframe
                    src="https://player.vimeo.com/video/850492752?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
                    title="Peel off monitors explained | HoxtonAi"
                  ></iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
              </div>
            </div>
          </div>
          <form [formGroup]="peelOffMonitorForm" class="c-name-peel-off-container">
            <div class="c-name-peel-off-content">
              <mat-icon class="c-icon-fork">label</mat-icon>
              <div class="c-name-peel-off-wrapper">
                <div class="c-title-name-peel-off">Name the peel off monitor</div>
                <div>
                  <mat-form-field>
                    <mat-label>Monitor name</mat-label>
                    <input formControlName="monitorName" autocomplete="off" matInput placeholder="Monitor name" />
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="c-name-peel-off-content" *ngIf="accountService.isSupport">
              <mat-icon class="c-icon-fork">shield</mat-icon>
              <div class="c-name-peel-off-wrapper">
                <div class="c-title-name-peel-off">Select an organisation</div>
                <div>
                  <mat-form-field>
                    <mat-label>Select an organisation</mat-label>
                    <mat-select
                      ngDefaultControl
                      [disabled]="accountService.organisations.length === 1"
                      (selectionChange)="filterByOrganisation($event)"
                      name="organisationId"
                    >
                      <mat-option *ngFor="let organisation of accountService.organisations" [value]="organisation.id">
                        {{ organisation.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="c-name-peel-off-content" *ngIf="!editing">
              <mat-icon class="c-icon-fork">apartment</mat-icon>
              <div class="c-name-peel-off-wrapper">
                <div class="c-title-name-peel-off">Which site is the monitor being created at</div>
                <div>
                  <mat-form-field class="c-dropdown-site">
                    <mat-label>Site name</mat-label>
                    <mat-select formControlName="site">
                      <mat-option *ngFor="let site of filteredSites" [value]="site">{{ site.name }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <ng-container *ngIf="accountService.isSupport">
              <div class="c-name-peel-off-content">
                <mat-icon class="c-icon-fork">event</mat-icon>
                <div class="c-name-peel-off-wrapper">
                  <div class="c-title-name-peel-off">Reporting start date</div>
                  <div>
                    <mat-form-field class="is-date-input">
                      <mat-label>Start date</mat-label>
                      <input matInput [matDatepicker]="pickerFrom" formControlName="reportingStartDate" readonly />
                      <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                      <mat-datepicker #pickerFrom></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="c-name-peel-off-content">
                <mat-icon class="c-icon-fork">event</mat-icon>
                <div class="c-name-peel-off-wrapper">
                  <div class="c-title-name-peel-off">Reporting end date</div>
                  <div>
                    <mat-form-field class="is-date-input">
                      <mat-label>End date</mat-label>
                      <input matInput [matDatepicker]="pickerTo" formControlName="reportingEndDate" readonly />
                      <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                      <mat-datepicker #pickerTo></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </ng-container>
          </form>
        </div>
      </div>
    </app-step>
  </mat-step>

  <mat-step>
    <app-step
      [stepTitle]="stepsTitle"
      [isBackable]="true"
      [processingStep]="isLoading"
      [disabledNextStep]="this.selectedPassingCountCameras.selected.length === 0"
      (openNextStep)="goToNextStep()"
      (closeStep)="close()"
    >
      <div class="c-modal-content">
        <app-monitor-setup-side-panel
          [monitorType]="'peel-off'"
          [monitor]="peelOffMonitor"
          [activeStep]="1"
        ></app-monitor-setup-side-panel>
        <div class="c-select-cameras-container">
          <div class="c-img-select-camera-wrapper" width="500">
            <img src="/assets/images/peel-off-placeholder.png" alt="No data" class="c-img-select-camera" />
          </div>

          <div class="c-select-camera-for-passing-count">
            <div class="c-select-cameras-wrapper">
              <mat-icon class="c-icon-pin-drop-select-cameras">pin_drop</mat-icon>

              <div>
                <div class="c-title-select-camera">Select camera(s) to be used for the passing count</div>
                <div>{{ passingCountCamerasForActiveSite.length }} cameras available</div>
                <form
                  *ngIf="passingCountCamerasForActiveSite.length"
                  class="c-available-cameras-checkboxes-wrapper"
                  [class.c-available-cameras-checkboxes-wrapper-warn]="passingCountCamerasFormErrors"
                >
                  <div class="c-available-cameras-checkboxes">
                    <mat-checkbox
                      *ngFor="let passingCountCamera of passingCountCamerasForActiveSite; let i = index"
                      [checked]="selectedPassingCountCameras.isSelected(passingCountCamera)"
                      (change)="togglePassingCountCamera(passingCountCamera)"
                      class="c-checkbox-camera-available"
                    >
                      <div class="c-title-camera-available">{{ passingCountCamera.name }}</div>
                      <div class="c-subtitle-camera-available">#{{ passingCountCamera.serialNumber }}</div>
                    </mat-checkbox>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-step>
  </mat-step>

  <mat-step>
    <app-step
      [stepTitle]="stepsTitle"
      [isBackable]="true"
      [processingStep]="isLoading"
      [disabledNextStep]="isValidDirectionsStep()"
      (openNextStep)="goToNextStep()"
      (closeStep)="close()"
    >
      <div class="c-modal-content">
        <app-monitor-setup-side-panel
          [monitorType]="'peel-off'"
          [monitor]="peelOffMonitor"
          [activeStep]="2"
        ></app-monitor-setup-side-panel>
        <div class="c-select-direction-container">
          <mat-icon class="c-icon-person">camera_front</mat-icon>
          <div class="c-select-direction-content">
            <div class="c-title-select-direction">Select direction(s) to be included in the passing count</div>
            <div *ngFor="let countCamera of selectedPassingCountCameras.selected" class="c-camera-container">
              <div class="c-text-camera">{{ countCamera.name }}</div>

              <div
                class="c-img-camera-wrapper"
                [style.background-image]="'url(' + cameraFrame[countCamera.id] + ')'"
                [ngClass]="{ 'is-pass-traff': countCamera.type === 'Passing traffic' }"
              >
                <div class="c-red-middle-line"></div>
                <div *ngIf="this.selectedCamerasDirections[countCamera.id]" class="c-buttons-directions">
                  <button
                    (click)="onPassingDirectionButtonClick(countCamera.id, 'direction1')"
                    [class.c-button-direction-active]="this.selectedCamerasDirections[countCamera.id]['direction1']"
                    class="c-button-direction"
                  >
                    <mat-icon class="c-icon-direction-leaving" *ngIf="countCamera.type === 'Passing traffic'"
                      >arrow_forward</mat-icon
                    >
                    <div>{{ countCamera.direction1Alias }}</div>
                    <mat-icon class="c-icon-direction-leaving" *ngIf="countCamera.type !== 'Passing traffic'"
                      >arrow_upward</mat-icon
                    >
                  </button>
                  <button
                    (click)="onPassingDirectionButtonClick(countCamera.id, 'direction2')"
                    [class.c-button-direction-active]="this.selectedCamerasDirections[countCamera.id]['direction2']"
                    class="c-button-direction"
                  >
                    <mat-icon class="c-icon-direction-leaving" *ngIf="countCamera.type === 'Passing traffic'"
                      >arrow_back</mat-icon
                    >
                    {{ countCamera.direction2Alias }}
                    <mat-icon class="c-icon-direction-leaving" *ngIf="countCamera.type !== 'Passing traffic'"
                      >arrow_downward</mat-icon
                    >
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-step>
  </mat-step>

  <mat-step>
    <app-step
      [stepTitle]="stepsTitle"
      [isBackable]="true"
      [processingStep]="isLoading"
      [disabledNextStep]="this.selectedEntranceCountCameras.selected.length === 0"
      (openNextStep)="goToNextStep()"
      (closeStep)="close()"
    >
      <div class="c-modal-content">
        <app-monitor-setup-side-panel
          [monitorType]="'peel-off'"
          [monitor]="peelOffMonitor"
          [activeStep]="3"
        ></app-monitor-setup-side-panel>
        <div class="c-select-cameras-container">
          <div class="c-img-passing-count-wrapper">
            <img src="/assets/images/passingcount-placeholder.png" alt="No data" class="c-img-select-camera" />
          </div>

          <div class="c-select-camera-for-passing-count">
            <div class="c-select-cameras-wrapper">
              <mat-icon class="c-icon-pin-drop-select-cameras">pin_drop</mat-icon>
              <div>
                <div class="c-title-select-camera">Select camera(s) to be used for the entrance count</div>
                <div>{{ entranceCountCamerasForActiveSite.length }} cameras available</div>
                <form
                  *ngIf="entranceCountCamerasForActiveSite.length"
                  class="c-available-cameras-checkboxes-wrapper"
                  [class.c-available-cameras-checkboxes-wrapper-warn]="entranceCountCamerasFormErrors"
                >
                  <div class="c-available-cameras-checkboxes">
                    <mat-checkbox
                      *ngFor="let entranceCountCamera of entranceCountCamerasForActiveSite; let i = index"
                      [checked]="selectedEntranceCountCameras.isSelected(entranceCountCamera)"
                      (change)="toggleEntranceCountCamera(entranceCountCamera)"
                      class="c-checkbox-camera-available"
                    >
                      <div class="c-title-camera-available">{{ entranceCountCamera.name }}</div>
                      <div class="c-subtitle-camera-available">#{{ entranceCountCamera.serialNumber }}</div>
                    </mat-checkbox>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-step>
  </mat-step>

  <mat-step>
    <app-step
      [stepTitle]="stepsTitle"
      [isBackable]="true"
      [processingStep]="isLoading"
      [disabledNextStep]="isValidDirectionsStep()"
      (openNextStep)="goToNextStep()"
      (closeStep)="close()"
    >
      <div class="c-modal-content">
        <app-monitor-setup-side-panel
          [monitorType]="'peel-off'"
          [monitor]="peelOffMonitor"
          [activeStep]="4"
        ></app-monitor-setup-side-panel>
        <div class="c-select-direction-container">
          <mat-icon class="c-icon-person">camera_front</mat-icon>
          <div class="c-select-direction-content">
            <div class="c-title-select-direction">Select direction to be used as the entrance count</div>
            <div *ngFor="let countCamera of selectedEntranceCountCameras.selected" class="c-camera-container">
              <div class="c-text-camera">{{ countCamera.name }}</div>

              <div
                class="c-img-camera-wrapper"
                [style.background-image]="'url(' + cameraFrame[countCamera.id] + ')'"
                [ngClass]="{ 'is-pass-traff': countCamera.type === 'Passing traffic' }"
              >
                <div class="c-red-middle-line"></div>
                <div *ngIf="this.selectedCamerasDirections[countCamera.id]" class="c-buttons-directions">
                  <button
                    (click)="onEntranceDirectionButtonClick(countCamera.id, 'direction1')"
                    [class.c-button-direction-active]="this.selectedCamerasDirections[countCamera.id]['direction1']"
                    class="c-button-direction"
                  >
                    <mat-icon class="c-icon-direction-leaving" *ngIf="countCamera.type === 'Passing traffic'"
                      >arrow_forward</mat-icon
                    >
                    <div>{{ countCamera.direction1Alias }}</div>
                    <mat-icon class="c-icon-direction-leaving" *ngIf="countCamera.type !== 'Passing traffic'"
                      >arrow_upward</mat-icon
                    >
                  </button>
                  <button
                    (click)="onEntranceDirectionButtonClick(countCamera.id, 'direction2')"
                    [class.c-button-direction-active]="this.selectedCamerasDirections[countCamera.id]['direction2']"
                    class="c-button-direction"
                  >
                    <mat-icon class="c-icon-direction-leaving" *ngIf="countCamera.type === 'Passing traffic'"
                      >arrow_back</mat-icon
                    >
                    {{ countCamera.direction2Alias }}
                    <mat-icon class="c-icon-direction-leaving" *ngIf="countCamera.type !== 'Passing traffic'"
                      >arrow_downward</mat-icon
                    >
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-step>
  </mat-step>

  <mat-step>
    <ng-template matStepContent>
      <app-step
        [stepTitle]="stepsTitle"
        [isBackable]="true"
        [nextStepText]="'FINISH'"
        [processingStep]="isLoading"
        (closeStep)="close()"
        (openNextStep)="onFinishDialog()"
      >
        <div class="c-modal-content">
          <app-monitor-setup-side-panel
            [monitorType]="'peel-off'"
            [monitor]="peelOffMonitor"
            [activeStep]="5"
          ></app-monitor-setup-side-panel>
          <div class="c-monitor-setup-complete-container" *ngIf="this.stepper.selectedIndex === 5">
            <img src="/assets/images/peeloff-setup-complete.png" alt="No data" class="c-img-setup-complete" />
            <div class="c-title-setup-complete-wrapper">
              <div class="c-title-setup-complete">Review details for your peel off monitor</div>
            </div>

            <div class="c-name-peel-off-content is-review">
              <mat-icon class="c-icon-fork">label</mat-icon>
              <div class="c-name-peel-off-wrapper">
                <div class="c-title-name-peel-off">Peel off monitor name</div>
                <div class="c-subtitle-whats-peel-off">
                  {{ peelOffMonitor.name }}
                </div>
              </div>
            </div>

            <div class="c-name-peel-off-content is-review">
              <mat-icon class="c-icon-fork">apartment</mat-icon>
              <div class="c-name-peel-off-wrapper">
                <div class="c-title-name-peel-off">Site where the monitor is created</div>

                <div class="c-subtitle-whats-peel-off">{{ peelOffMonitorForm.value['site'].name }}</div>
              </div>
            </div>

            <ng-container *ngIf="accountService.isSupport">
              <div class="c-name-peel-off-content is-review">
                <mat-icon class="c-icon-fork">event</mat-icon>
                <div class="c-name-peel-off-wrapper">
                  <div class="c-title-name-peel-off">Reporting start date</div>
                  <div class="c-subtitle-whats-peel-off">
                    {{ peelOffMonitor.reportingStartDate }}
                  </div>
                </div>
              </div>
              <div class="c-name-peel-off-content is-review">
                <mat-icon class="c-icon-fork">event</mat-icon>
                <div class="c-name-peel-off-wrapper">
                  <div class="c-title-name-peel-off">Reporting end date</div>
                  <div class="c-subtitle-whats-peel-off">
                    {{ peelOffMonitor.reportingEndDate }}
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="c-direction-count-wrapper">
              <div class="c-direction-count-header">
                <div class="c-title-direciton-count">Passing count.</div>
                <button (click)="goToStep(1)" class="o-button is-muted">
                  <span class="material-icons is-icon-left"> edit </span>
                  Edit
                </button>
              </div>
              <div class="c-direction-count-content">
                <div class="c-text-cameras-selected">
                  {{ selectedPassingCountCameras.selected.length }} cameras selected
                </div>
                <div
                  *ngFor="let selectedPassingCountCamera of selectedPassingCountCameras.selected"
                  class="c-camera-selected"
                >
                  <mat-icon class="c-icon-camera-alt">camera_alt</mat-icon>
                  <div class="c-text-camera-selected-name">{{ selectedPassingCountCamera.name }}</div>
                  <div class="c-text-camera-selected-direction">
                    {{
                      selectedCamerasDirections[selectedPassingCountCamera.id].direction1 &&
                      selectedCamerasDirections[selectedPassingCountCamera.id].direction2
                        ? 'Both directions'
                        : selectedCamerasDirections[selectedPassingCountCamera.id].direction1
                          ? 'Direction 1'
                          : 'Direction 2'
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div class="c-direction-count-wrapper">
              <div class="c-direction-count-header">
                <div class="c-title-direciton-count">Entrance count.</div>
                <button (click)="goToStep(3)" class="o-button is-muted">
                  <span class="material-icons is-icon-left"> edit </span>
                  Edit
                </button>
              </div>
              <div class="c-direction-count-content">
                <div class="c-text-cameras-selected">
                  {{ selectedEntranceCountCameras.selected.length }} cameras selected
                </div>
                <div
                  *ngFor="let selectedEntranceCountCamera of selectedEntranceCountCameras.selected"
                  class="c-camera-selected"
                >
                  <mat-icon class="c-icon-camera-alt">camera_alt</mat-icon>
                  <div class="c-text-camera-selected-name">{{ selectedEntranceCountCamera.name }}</div>
                  <div class="c-text-camera-selected-direction">
                    {{
                      selectedCamerasDirections[selectedEntranceCountCamera.id].direction1 &&
                      selectedCamerasDirections[selectedEntranceCountCamera.id].direction2
                        ? 'Both directions'
                        : selectedCamerasDirections[selectedEntranceCountCamera.id].direction1
                          ? 'Direction 1'
                          : 'Direction 2'
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-step>
    </ng-template>
  </mat-step>
</mat-horizontal-stepper>
