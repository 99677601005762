import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CameraStatus } from 'src/app/model/cameraStatus';
import { MonitorStatus } from 'src/app/model/monitorStatus';

@Component({
  selector: 'app-monitors-list-display',
  templateUrl: './monitors-list-display.component.html',
  styleUrls: ['./monitors-list-display.component.scss'],
})
export class MonitorsListDisplayComponent implements OnInit {
  @Input() monitorIcon: string;
  @Input() monitorTitle: string;
  @Input() imgSrc: string;
  @Input() monitors = [];
  @Input() monitorCameras: { [_: string]: CameraStatus[] } = {};

  @Output() viewMonitors: EventEmitter<void> = new EventEmitter();
  @Output() createMonitor: EventEmitter<void> = new EventEmitter();

  monitorStatus: { [_: string]: MonitorStatus.StatusEnum } = {};
  allMonitorsHaveIssue: boolean;
  monitorsWithIssue = 0;
  constructor() {}

  ngOnInit(): void {
    this.monitors.forEach((m) => {
      this.monitorStatus[m.id] = 'online';
      if (
        this.monitorCameras[m.id].some(
          (c) => c.status === 'lagging' || c.status === 'missing' || c.status === 'offline',
        )
      ) {
        this.monitorStatus[m.id] = 'issue';
        this.monitorsWithIssue += 1;
      }
    });
    this.allMonitorsHaveIssue = this.monitors.length && this.monitors.length === this.monitorsWithIssue;
  }
}
