import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserConfirmationComponent } from 'src/app/components/general/user-confirmation/user-confirmation.component';
import { CameraStatus } from 'src/app/model/cameraStatus';

@Component({
  selector: 'app-camera-performance-summary',
  templateUrl: './camera-performance-summary.component.html',
  styleUrls: ['./camera-performance-summary.component.scss'],
})
export class CameraPerformanceSummaryComponent {
  @Input() sevenDaysSummary;
  @Input() oneMonthSummary;

  @Input() camera: CameraStatus;

  constructor(private matDialog: MatDialog) {}

  openUserConfirmation(): void {
    const messages = `Video received on time means we have received the video in under 5 minutes.
      
      Video received late means the video was received but after more than 5 minutes. Late video might be an issue for you if you're using the count data for real time occupancy management.

      Video not received means no video data has been sent. Usually because the camera has been offline and not recording, or the internet has been poor and the backlog is exceeded.`;

    this.matDialog.open(UserConfirmationComponent, {
      data: { message: messages, isDelete: true },
    });
  }
}
