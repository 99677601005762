import { Pipe, PipeTransform } from '@angular/core';

const SIZE_UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];

@Pipe({
  name: 'formatTransferSpeed',
})
export class FormatTransferSpeedPipe implements PipeTransform {
  transform(sizeInBytes: number): string {
    const units = SIZE_UNITS;

    let power = Math.round(Math.log(sizeInBytes) / Math.log(1000));
    power = Math.min(power - 1, units.length - 1);

    const size = sizeInBytes / Math.pow(1000, power); // size in new units
    const formattedSize = Math.round(size * 100) / 100; // keep up to 2 decimals
    const unit = units[power];

    return `${formattedSize} ${unit}/s`;
  }
}
