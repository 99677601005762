<form class="u-padding--inline">
  <ng-container *ngIf="resourceType === 'alert'; else summaryTitle">
    <div class="long-primer kirk u-flush--bottom">Confirm your camera alert rules.</div>
  </ng-container>
  <ng-template #summaryTitle>
    <div class="long-primer kirk u-flush--bottom">Confirm health summary details.</div>
  </ng-template>
  <div class="o-list">
    <div class="o-list__item">
      <div class="o-list__item-icon">
        <mat-icon class="is-icon">label</mat-icon>
      </div>
      <div class="o-list__item-text">
        <span class="is-secondary-text">{{ resourceType === 'alert' ? 'Alert' : 'Summary' }} name</span>
        <span class="is-primary-text">{{ alertOrSummary.name }}</span>
      </div>
    </div>
    <div class="o-list__item">
      <div class="o-list__item-icon">
        <mat-icon class="is-icon">contacts</mat-icon>
      </div>
      <div class="o-list__item-text">
        <ng-container *ngIf="notificationGroupName; else recipientsList">
          <span class="is-secondary-text">Notification group</span>
          <span class="is-primary-text">{{ notificationGroupName }}</span>
        </ng-container>
        <ng-template #recipientsList>
          <span class="is-secondary-text">Recipients ({{ selectedRecipients.length }})</span>
          <div class="is-primary-text c-recipients">
            <span class="c-recipients__item" *ngFor="let recipient of selectedRecipients">{{
              getRecipientName(recipient)
            }}</span>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="c-alert-config">
    <div class="is-body">
      <div class="is-title">{{ resourceType === 'alert' ? 'Real time alerts' : 'Health summary' }}</div>
      <div class="c-alert-config__details">
        <div class="is-label">Sent</div>
        <ng-container *ngIf="resourceType === 'alert'; else summaryDetails">
          <span class="is-value">
            After <b>{{ alertOrSummary.configurationDetails['duration'] }}</b>
          </span>
        </ng-container>
        <ng-template #summaryDetails>
          <span class="is-value">{{ humanizeSchedule(alertOrSummary.configurationDetails['schedule']) }}</span>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="c-alert-details u-padding--top" *ngIf="resourceType === 'alert'">
    <ng-container *ngIf="alertOrSummary.configurationDetails['notifyAlertRecovery']; else noRecovery">
      <mat-icon class="u-primary-color">check_circle</mat-icon>
      <span>Send update when camera is back online</span>
    </ng-container>
    <ng-template #noRecovery>
      <mat-icon class="u-grey-color">cancel</mat-icon>
      <span>Don't send update when camera is back online</span>
    </ng-template>
  </div>

  <hr class="o-rule" />

  <ng-container *ngIf="!allSites; else allSitesIncluded">
    <div class="c-alert-summary__item">
      <div class="is-icon">
        <mat-icon class="is-icon">apartment</mat-icon>
      </div>
      <div class="is-label">Site</div>

      <div class="c-alert-summary-item-decorated is-detail">
        {{ site.name }}
      </div>
    </div>
  </ng-container>
  <ng-template #allSitesIncluded>
    <div class="c-alert-summary__item">
      <div class="is-icon">
        <mat-icon class="is-icon">apartment</mat-icon>
      </div>
      <div class="is-label">Sites included</div>

      <div class="is-all is-detail">All sites</div>
    </div>
  </ng-template>

  <div class="c-alert-summary__item">
    <div class="is-icon">
      <mat-icon class="is-icon">photo_camera</mat-icon>
    </div>
    <div class="is-label">Cameras selected</div>
    <ng-container *ngIf="!allSites && !allCameras; else allCamerasIncluded">
      <div class="is-detail">
        <div class="c-alert-summary-item-decorated c-alert-summary-item-decorated-long is-detail">
          <div>
            <div class="c-active-site-wrapper">
              <div>
                <b> {{ site.name }} </b>
              </div>
              <div class="is-all">{{ selectedCameras.length }} of {{ cameras.length }} cameras</div>
            </div>
            <div *ngFor="let camera of selectedCameras; let cameraIndex = index" class="c-camera-name">
              {{ camera.name }} <span *ngIf="cameraIndex + 1 < selectedCameras.length" class="c-camera-rule">|</span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #allCamerasIncluded>
      <div class="is-all is-detail">All cameras</div>
    </ng-template>
  </div>
</form>
