import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { CameraStatus } from 'src/app/model/cameraStatus';
import { AccountService } from 'src/app/services/account.service';
import { filter, finalize, Subject, take, takeLast } from 'rxjs';
import { AggregatedVideoMetrics } from 'src/app/api';
import { CamerasService } from 'src/app/services/cameras.service';
import * as moment from 'moment';

@Component({
  selector: 'app-camera-card',
  templateUrl: './camera-card.component.html',
  styleUrls: ['./camera-card.component.scss'],
})
export class CameraCardComponent implements OnInit, OnChanges, OnDestroy {
  @Input() camera: CameraStatus;
  @Input() aggregatedVideoMetricsReport: any;
  @Input() keyToSort: keyof CameraStatus;
  @Input() videoLoss: number;

  @Output() cardClick: EventEmitter<void> = new EventEmitter();

  isVideoLossReady = true;
  organisationsMap = {};
  isSupport: boolean = false;

  private ngUnsubscribe = new Subject();

  constructor(
    private accountService: AccountService,
    private camerasService: CamerasService,
    private ref: ChangeDetectorRef,
  ) {
    this.organisationsMap = this.accountService.organisationsMap;
    this.isSupport = this.accountService.isSupport;
  }

  ngOnInit() {
    if (moment(this.camera.claimedAt).isBefore(moment().subtract(1, 'week'))) {
      this.isVideoLossReady = false;
      this.camerasService.createAggregatedVideoMetricsReport(this.camera.id).then((observable) => {
        observable
          .pipe(
            filter((summary: AggregatedVideoMetrics) => summary.cameraId === this.camera.id),
            take(4),
            takeLast(1),
            finalize(() => {
              this.isVideoLossReady = true;
              this.ref.detectChanges();
            }),
          )
          .subscribe({
            next: (summary: AggregatedVideoMetrics) => {
              this.videoLoss = Math.round(
                (summary.missingCount * 100) / (summary.lateCount + summary.missingCount + summary.onTimeCount),
              );
            },
            error: (error) => {
              console.log(error);
            },
          });
      });
    }
  }

  ngOnChanges(): void {
    if (this.videoLoss !== undefined) {
      this.isVideoLossReady = true;
    }
  }

  computeTooltipPosition(cameraId: string): number {
    var containerRightPosition = document.getElementById(cameraId).getBoundingClientRect().right;
    var urgencyRightPosition = document
      .getElementById(cameraId)
      .getElementsByClassName('c-panel__progress-inner')[0]
      .getBoundingClientRect().right;

    return containerRightPosition - 74 > urgencyRightPosition ? -74 : urgencyRightPosition - containerRightPosition;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
