import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent {
  @Input() placeholder: string = 'Filter';
  @Output() search: EventEmitter<string> = new EventEmitter<string>();

  @Input() searchString: string = '';
  isInputFocused = false;

  @ViewChild('search') searchElement: ElementRef;

  @HostListener('document:keydown.meta.f', ['$event'])
  focusSearchBox(event: KeyboardEvent): void {
    event.preventDefault();
    this.searchElement.nativeElement.focus();
  }

  @HostListener('document:keydown.escape', ['$event'])
  clearSearchBox(event: KeyboardEvent): void {
    if (!this.isInputFocused) {
      return;
    }
    event.preventDefault();
    this.clearSearch();
  }

  clearSearch(): void {
    this.searchString = '';
    this.search.emit(this.searchString);
  }

  emitSearch(): void {
    this.search.emit(this.searchString);
  }
}
