import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Color, ScaleType } from '@swimlane/ngx-charts';
import * as moment from 'moment';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  filter,
  finalize,
  forkJoin,
  of,
  Subject,
  takeUntil,
  throwError,
} from 'rxjs';
import {
  AggregatedVideoMetrics,
  Alert,
  AlertCameraPositionMappingService,
  AlertService,
  Camera,
  CameraService,
  OccupancyMonitor,
  OccupancyMonitorService,
  PeelOffMonitor,
  PeelOffMonitorService,
  PeopleCountMonitor,
  PeopleCountMonitorService,
  ReportService,
  Site,
  SiteService,
} from 'src/app/api';
import { CameraStatus } from 'src/app/model/cameraStatus';
import { AccountService } from 'src/app/services/account.service';
import { NotifyService } from 'src/app/services/notify.service';
import { UserConfirmationComponent } from 'src/app/components/general/user-confirmation/user-confirmation.component';
import { EditCameraComponent } from 'src/app/components/cameras/edit-camera/edit-camera.component';
import { CamerasService } from 'src/app/services/cameras.service';

@Component({
  selector: 'app-camera-details',
  templateUrl: './camera-details.component.html',
  styleUrls: ['./camera-details.component.scss'],
})
export class CameraDetailsComponent implements OnInit, OnDestroy {
  cameraFrame = '';
  offlineAlerts: Alert[] = [];
  summaryAlerts: Alert[] = [];
  peopleCountMonitors: PeopleCountMonitor[] = [];
  peelOffMonitors: PeelOffMonitor[] = [];
  occupancyMonitors: OccupancyMonitor[] = [];
  isLoadingCamera$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLoadingFrame = false;
  isLoadingCameraAlerts$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLoadingCameraMonitors$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLoadingCameraHistoricalData = false;
  camera: CameraStatus;
  isSupport: boolean = false;
  occupancyLicence: boolean = false;
  organisationsMap = {};

  sevenDaysSummary = { lateCount: 0, missingCount: 0, onTimeCount: 0 };
  oneMonthSummary = { lateCount: 0, missingCount: 0, onTimeCount: 0 };

  cameraPerformanceChartColorScheme: Color = {
    name: 'PerformanceChartColorScheme',
    selectable: false,
    group: ScaleType.Linear,
    domain: ['var(--color-apple--light-2)', ' var(--color-mikado--light-1)', ' var(--error-color)', `url(#pattern)`],
  };
  cameraPerformanceLastWeekData = [];
  cameraPerformanceLastMonthData = [];

  daysView: [number, number] = [360, 220];
  weeksView: [number, number] = [360, 220];

  private ngUnsubscribe = new Subject();

  constructor(
    public accountService: AccountService,
    private cameraService: CameraService,
    private camerasService: CamerasService,
    private notifyService: NotifyService,
    private reportService: ReportService,
    private alertService: AlertService,
    private alertCameraPositionMappingService: AlertCameraPositionMappingService,
    private occupancyMonitorService: OccupancyMonitorService,
    private peopleCountMonitorService: PeopleCountMonitorService,
    private peelOffMonitorService: PeelOffMonitorService,
    private siteService: SiteService,
    private matDialog: MatDialog,
    private ref: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.organisationsMap = this.accountService.organisationsMap;
    this.isSupport = this.accountService.isSupport;
    this.occupancyLicence = this.accountService.occupancyLicence;
  }

  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (params) => {
        this.camera = { id: params.id };
        this.getCamera();
      },
    });
  }

  getData(): void {
    this.getCameraFrame();
    this.getCameraAlerts();
    this.getCameraMonitors();
    this.getCameraHistoricalData();
    this.addSiteName();
  }

  addSiteName(): void {
    this.siteService
      .getSite(this.camera.siteId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (site: Site) => {
          this.camera.siteName = site.name;
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  getCamera(): void {
    this.isLoadingCamera$.next(true);
    this.ref.detectChanges();

    this.cameraService
      .getCamera(this.camera.id)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.isLoadingCamera$.next(false);
          this.ref.detectChanges();
        }),
      )
      .subscribe({
        next: (camera: Camera) => {
          this.camera = this.camerasService.getCameraStatus(camera);
          this.getData();
        },
        error: (error) => {
          this.notifyService.error(error);
        },
      });
  }

  getCameraFrame(): void {
    if (this.camera.state === 'running' && this.camera.isOnline) {
      this.isLoadingFrame = true;
      this.ref.detectChanges();

      this.cameraService
        .createFrameRequest({ cameraId: this.camera.id })
        .pipe(
          takeUntil(this.ngUnsubscribe),
          finalize(() => {
            this.isLoadingFrame = false;
            this.ref.detectChanges();
          }),
        )
        .subscribe({
          next: (frame) => {
            this.cameraFrame = 'data:image/jpeg;base64,' + frame.image;
          },
          error: (error) => {
            this.notifyService.error(error);
          },
        });
    }
  }

  refreshCamera(): void {
    if (this.camera.state === 'running' && this.camera.isOnline) {
      this.getCameraFrame();
    } else {
      this.getCamera();
      this.notifyService.warning(
        'It might take several minutes to get a camera view when the device first comes back online.',
      );
    }
  }

  getCameraAlerts(): void {
    this.isLoadingCameraAlerts$.next(true);
    this.ref.detectChanges();

    forkJoin([
      this.alertService.listAlerts(
        'offline_alert',
        undefined,
        undefined,
        this.camera.organisationId,
        undefined,
        undefined,
        'active',
      ),
      this.alertService.listAlerts(
        'camera_health_summary_alert',
        undefined,
        undefined,
        this.camera.organisationId,
        undefined,
        undefined,
        'active',
      ),
      this.alertCameraPositionMappingService.listAlertCameraPositionMappings(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
        'offline_alert',
      ),
      this.alertCameraPositionMappingService.listAlertCameraPositionMappings(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
        'camera_health_summary_alert',
      ),
    ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.isLoadingCameraAlerts$.next(false);
          this.ref.detectChanges();
        }),
      )
      .subscribe({
        next: ([offlineAlerts, summaryAlerts, offlineAlertCameraPositions, summaryAlertCameraPositions]) => {
          this.offlineAlerts = [];
          this.summaryAlerts = [];

          offlineAlerts.forEach((alert) => {
            if (!alert.siteId) {
              this.offlineAlerts.push(alert);
            } else if (
              alert.siteId === this.camera.siteId &&
              (!offlineAlertCameraPositions.find((mapping) => mapping.alertId === alert.id) ||
                offlineAlertCameraPositions.find(
                  (mapping) =>
                    mapping.alertId === alert.id && mapping.cameraPositionId === this.camera.cameraPositionId,
                ))
            ) {
              this.offlineAlerts.push(alert);
            }
          });

          summaryAlerts.forEach((alert) => {
            if (!alert.siteId) {
              this.summaryAlerts.push(alert);
            } else if (
              alert.siteId === this.camera.siteId &&
              (!summaryAlertCameraPositions.find(
                (mapping) => mapping.cameraPositionId !== this.camera.cameraPositionId,
              ) ||
                summaryAlertCameraPositions.find(
                  (mapping) => mapping.cameraPositionId === this.camera.cameraPositionId,
                ))
            ) {
              this.summaryAlerts.push(alert);
            }
          });
        },
        error: (error) => {
          this.notifyService.error(error);
        },
      });
  }

  getCameraMonitors(): void {
    this.isLoadingCameraMonitors$.next(true);
    this.ref.detectChanges();

    forkJoin([
      this.occupancyMonitorService.listOccupancyMonitors(undefined, undefined, this.camera.cameraPositionId, 'active'),
      this.peopleCountMonitorService.listPeopleCountMonitors(
        undefined,
        undefined,
        this.camera.cameraPositionId,
        'active',
      ),
      this.peelOffMonitorService.listPeelOffMonitors(undefined, undefined, this.camera.cameraPositionId, 'active'),
    ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.isLoadingCameraMonitors$.next(false);
          this.ref.detectChanges();
        }),
      )
      .subscribe({
        next: ([occupancyMonitors, peopleCountMonitors, peelOffMonitors]) => {
          this.occupancyMonitors = occupancyMonitors ? occupancyMonitors : [];
          this.peopleCountMonitors = peopleCountMonitors ? peopleCountMonitors : [];
          this.peelOffMonitors = peelOffMonitors ? peelOffMonitors : [];
        },
        error: (error) => {
          this.notifyService.error(error);
        },
      });
  }

  getCameraHistoricalData(): void {
    if (this.camera.status === 'paused') {
      return;
    }
    combineLatest([this.isLoadingCamera$, this.isLoadingCameraAlerts$, this.isLoadingCameraMonitors$])
      .pipe(
        // check all the loading is done so the charts elemnts have been rendered
        filter((isLoading: boolean[]) => isLoading.every((l) => !l)),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (_) => {
          this.isLoadingCameraHistoricalData = true;
          forkJoin([
            this.reportService.createAggregatedVideoMetricsReport({
              cameraIds: [this.camera.id],
              startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
              endDate: moment().format('YYYY-MM-DD'),
              timeGrain: '24h',
            }),
            this.reportService.createAggregatedVideoMetricsReport({
              cameraIds: [this.camera.id],
              startDate: moment().subtract(4, 'weeks').format('YYYY-MM-DD'),
              endDate: moment().format('YYYY-MM-DD'),
              timeGrain: '168h',
            }),
          ])
            .pipe(
              takeUntil(this.ngUnsubscribe),
              finalize(() => {
                var daysChartWidth = document.getElementById('camera-performance-chart-days').offsetWidth;
                var weeksChartWidth = document.getElementById('camera-performance-chart-days').offsetWidth;

                this.daysView = [daysChartWidth, document.documentElement.clientHeight * 0.4];
                this.weeksView = [weeksChartWidth, document.documentElement.clientHeight * 0.4];
                this.isLoadingCameraHistoricalData = false;
                this.ref.detectChanges();
                for (let element of Array.from(document.getElementsByClassName('ngx-charts'))) {
                  element.insertAdjacentHTML(
                    'afterbegin',
                    `<defs>
                  <pattern id="pattern"
                           width="8" height="10"
                           patternUnits="userSpaceOnUse"
                           patternTransform="rotate(45 50 50)">
                    <line stroke="#a6a6a6" stroke-width="7px" y2="10"/>
                  </pattern>
                </defs>`,
                  );
                }
              }),
            )
            .subscribe({
              next: ([sevenDaysSummary, oneMonthSummary]: [AggregatedVideoMetrics[], AggregatedVideoMetrics[]]) => {
                this.cameraPerformanceLastWeekData = [];
                this.cameraPerformanceLastMonthData = [];
                this.sevenDaysSummary = { lateCount: 0, missingCount: 0, onTimeCount: 0 };
                this.oneMonthSummary = { lateCount: 0, missingCount: 0, onTimeCount: 0 };
                let sevenDaysSummaryTotal = 0;
                sevenDaysSummary
                  .sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime())
                  .forEach((summary: AggregatedVideoMetrics) => {
                    // sometimes the onTimeCount value is negative.
                    const dayTotal = summary.lateCount + summary.missingCount + Math.abs(summary.onTimeCount);
                    sevenDaysSummaryTotal += dayTotal;
                    // chart data
                    if (new Date(summary.time).getTime() > new Date(this.camera.claimedAt).getTime()) {
                      this.sevenDaysSummary.lateCount += summary.lateCount;
                      this.sevenDaysSummary.missingCount += summary.missingCount;
                      this.sevenDaysSummary.onTimeCount += Math.abs(summary.onTimeCount);
                      this.cameraPerformanceLastWeekData.push({
                        name: summary.time.split('T')[0],
                        series: [
                          {
                            name: 'onTimeCount',
                            value: Math.trunc((Math.abs(summary.onTimeCount) * 1000) / dayTotal) / 10,
                          },
                          {
                            name: 'lateCount',
                            value: Math.trunc((summary.lateCount * 1000) / dayTotal) / 10,
                          },
                          {
                            name: 'missingCount',
                            value: Math.trunc((summary.missingCount * 1000) / dayTotal) / 10,
                          },
                        ],
                      });
                    } else {
                      this.cameraPerformanceLastWeekData.push({
                        name: summary.time.split('T')[0],
                        series: [
                          {
                            name: 'onTimeCount',
                            value: 0,
                          },
                          {
                            name: 'lateCount',
                            value: 0,
                          },
                          {
                            name: 'missingCount',
                            value: 0,
                          },
                          {
                            name: 'noData',
                            value: 100,
                          },
                        ],
                      });
                    }
                  });
                this.sevenDaysSummary.lateCount =
                  Math.trunc((this.sevenDaysSummary.lateCount * 1000) / sevenDaysSummaryTotal) / 10;
                this.sevenDaysSummary.missingCount =
                  Math.trunc((this.sevenDaysSummary.missingCount * 1000) / sevenDaysSummaryTotal) / 10;
                this.sevenDaysSummary.onTimeCount =
                  Math.trunc((this.sevenDaysSummary.onTimeCount * 1000) / sevenDaysSummaryTotal) / 10;

                if (
                  this.sevenDaysSummary.lateCount +
                    this.sevenDaysSummary.missingCount +
                    this.sevenDaysSummary.onTimeCount <
                  100
                ) {
                  let incrementKey = Object.keys(this.sevenDaysSummary).find(
                    (key) => this.sevenDaysSummary[key] === Math.max(...Object.values(this.sevenDaysSummary)),
                  );
                  this.sevenDaysSummary[incrementKey] +=
                    100 -
                    (this.sevenDaysSummary.lateCount +
                      this.sevenDaysSummary.missingCount +
                      this.sevenDaysSummary.onTimeCount);
                  this.sevenDaysSummary[incrementKey] = Math.trunc(this.sevenDaysSummary[incrementKey] * 10) / 10;
                }

                let oneMonthSummaryTotal = 0;
                oneMonthSummary
                  .sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime())
                  .forEach((summary) => {
                    // chart data
                    if (new Date(summary.time).getTime() > new Date(this.camera.claimedAt).getTime()) {
                      // sometimes the onTimeCount value is negative.
                      const weekTotal = summary.lateCount + summary.missingCount + Math.abs(summary.onTimeCount);
                      oneMonthSummaryTotal += weekTotal;

                      this.oneMonthSummary.lateCount += summary.lateCount;
                      this.oneMonthSummary.missingCount += summary.missingCount;
                      this.oneMonthSummary.onTimeCount += Math.abs(summary.onTimeCount);
                      this.cameraPerformanceLastMonthData.push({
                        name: summary.time.split('T')[0],
                        series: [
                          {
                            name: 'onTimeCount',
                            value: Math.trunc((Math.abs(summary.onTimeCount) * 1000) / weekTotal) / 10,
                          },
                          {
                            name: 'lateCount',
                            value: Math.trunc((summary.lateCount * 1000) / weekTotal) / 10,
                          },
                          {
                            name: 'missingCount',
                            value: Math.trunc((summary.missingCount * 1000) / weekTotal) / 10,
                          },
                        ],
                      });
                    } else {
                      this.cameraPerformanceLastMonthData.push({
                        name: summary.time.split('T')[0],
                        series: [
                          {
                            name: 'onTimeCount',
                            value: 0,
                          },
                          {
                            name: 'lateCount',
                            value: 0,
                          },
                          {
                            name: 'missingCount',
                            value: 0,
                          },
                          {
                            name: 'noData',
                            value: 100,
                          },
                        ],
                      });
                    }
                  });

                this.oneMonthSummary.lateCount =
                  Math.trunc((this.oneMonthSummary.lateCount * 1000) / oneMonthSummaryTotal) / 10;
                this.oneMonthSummary.missingCount =
                  Math.trunc((this.oneMonthSummary.missingCount * 1000) / oneMonthSummaryTotal) / 10;
                this.oneMonthSummary.onTimeCount =
                  Math.trunc((this.oneMonthSummary.onTimeCount * 1000) / oneMonthSummaryTotal) / 10;

                if (
                  this.oneMonthSummary.lateCount +
                    this.oneMonthSummary.missingCount +
                    this.oneMonthSummary.onTimeCount <
                  100
                ) {
                  let incrementKey = Object.keys(this.oneMonthSummary).find(
                    (key) => this.oneMonthSummary[key] === Math.max(...Object.values(this.oneMonthSummary)),
                  );

                  this.oneMonthSummary[incrementKey] +=
                    100 -
                    (this.oneMonthSummary.lateCount +
                      this.oneMonthSummary.missingCount +
                      this.oneMonthSummary.onTimeCount);
                  this.oneMonthSummary[incrementKey] = Math.trunc(this.oneMonthSummary[incrementKey] * 10) / 10;
                }
              },
              error: (error) => {
                this.notifyService.error(error);
              },
            });
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  editCamera(): void {
    const dialogRef = this.matDialog.open(EditCameraComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data: {
        camera: this.camera,
        cameraFrame: this.cameraFrame,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          this.getCamera();
        }
      },
    });
  }

  close(): void {
    history.back();
  }

  viewAlerts(alertId?: string): void {
    this.router.navigate(['alerts/camera-alerts-offline'], {
      queryParams: alertId ? { alertId: alertId } : undefined,
    });
  }

  viewSummaries(summaryId?: string): void {
    this.router.navigate(['alerts/camera-summaries'], {
      queryParams: summaryId ? { summaryId: summaryId } : undefined,
    });
  }

  viewMonitors(type?): void {
    this.router.navigate(['monitors'], {
      queryParams: { type },
    });
  }

  viewPeopleCountMonitor(monitor: PeopleCountMonitor): void {
    let path;
    path = `monitors/people-count-monitor/${monitor.id}`;
    this.router.navigate([path]);
  }

  viewOccupancyMonitor(monitor: OccupancyMonitor): void {
    let path;
    path = `monitors/occupancy-monitor/${monitor.id}`;
    this.router.navigate([path]);
  }

  viewPeelOffMonitor(monitor: PeelOffMonitor): void {
    let path;
    path = `monitors/peel-off-monitor/${monitor.id}`;
    this.router.navigate([path]);
  }

  getTooltipText(chartData: any[], dataPointSeries: string): string {
    const dataRow = chartData.find((data) => data['name'] === dataPointSeries)?.series;
    return dataRow && dataRow.length !== 4
      ? `<div>Received on time: ${dataRow[0].value}%</div>
    <div>Received late: ${dataRow[1].value}%</div>
    <div>Not received: ${dataRow[2].value}%</div>`
      : 'No data';
  }

  openUserConfirmation(type: string): void {
    const messages = {
      recommended: `A minimum upload speed of 85KB/s is recommended to ensure reliable video transfer.
    
    Speeds below this can result in slow video uploads and lagging data. If the problem persists it might also result in lost video.`,
      video_backlog: `This is the amount of video stored on your camera that
    has not been uploaded to HoxtonAi.
    
    The maximum backlog size will vary but is unlikely to be
    greater than 1 hr for any camera. Video will be lost once
    your backlog size has filled the camera memory.`,
      legend: `Video received on time means we have received the video in under 5 minutes.
      
      Video received late means the video was received but after more than 5 minutes. Late video might be an issue for you if you're using the count data for real time occupancy management.

      Video not received means no video data has been sent. Usually because the camera has been offline and not recording, or the internet has been poor and the backlog is exceeded.`,
    };
    if (type in messages) {
      this.matDialog.open(UserConfirmationComponent, {
        data: { message: messages[type], isDelete: true },
      });
    }
  }

  dayFormatting(val: string | number) {
    const daysMap = {
      0: 'Sun',
      1: 'Mon',
      2: 'Tue',
      3: 'Wed',
      4: 'Thu',
      5: 'Fri',
      6: 'Sat',
    };
    const day = moment(val).day();
    return daysMap[day].toUpperCase();
  }

  weekFormatting(val: string | number) {
    return `${moment(val).format('DD MMM')} - ${moment(val).add(6, 'd').format('DD MMM')}`.toLocaleUpperCase();
  }

  percantageormatting(val: string | number): string {
    return `${val} %`;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
