import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-uuid',
  templateUrl: './uuid.component.html',
  styleUrls: ['./uuid.component.scss'],
})
export class UuidComponent implements OnInit {
  shortId = '';

  @Input() fullId = '';
  constructor() {}

  ngOnInit(): void {
    this.shortId = this.fullId.slice(0, 8);
  }
}
