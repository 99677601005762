<div class="c-occupancy-distribution">
  <div class="is-header">
    <div>
      <div class="brevier u-flush--bottom">{{ reportsService.sitesMap[occupancyMonitor.siteId].name }}</div>
      <div class="long-primer kirk">{{ occupancyMonitor.name }}</div>
    </div>
    <mat-checkbox
      [checked]="reportsService.useOpeningHoursCapacityManagementMonitors"
      (change)="filterByOpeningHours.emit()"
      >Filter by opening hours</mat-checkbox
    >
  </div>
  <div class="c-occupancy-distribution__content">
    <div>
      <div class="primer kirk">Maximum occupancy</div>
      <div class="c-occupancy-distribution__legend" *ngIf="!isLoading">
        <div class="c-occupancy-distribution__legend-item is-last-7">
          <span class="is-label">Last 7 days</span>
          <span class="is-date"
            >{{ lastUpdate.getTime() - 518400000 | date: 'EE dd MMM' }} - {{ lastUpdate | date: 'EE dd MMM' }}</span
          >
        </div>
        <div class="c-occupancy-distribution__legend-item is-previous-7">
          <span class="is-label">Previous 7 days</span>
          <span class="is-date"
            >{{ lastUpdate.getTime() - 1123200000 | date: 'EE dd MMM' }} -
            {{ lastUpdate.getTime() - 604800000 | date: 'EE dd MMM' }}</span
          >
        </div>
      </div>
    </div>
    <div class="c-dot-flashing-container" *ngIf="isLoading; else notLoadingData">
      <div class="dot-flashing"></div>
    </div>
    <ng-template #notLoadingData>
      <div class="c-occupancy-distribution__chart">
        <div #chart (window:resize)="setViewSize()"></div>
        <button class="o-button c-button-view-details" (click)="openCapacityManagementDetailsDialog()">
          VIEW DETAILS <span class="material-icons is-icon-left c-icon-view-details">arrow_forward</span>
        </button>
      </div>
    </ng-template>
  </div>
</div>
