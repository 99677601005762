<div class="c-side-menu">
  <div class="o-button--icon is-grey u-margin--left" *ngIf="dialogRef">
    <mat-icon (click)="close()">close</mat-icon>
  </div>
  <div class="menu-content">
    <button class="o-button is-primary is-small" (click)="setupCameraEvent()">
      <mat-icon class="is-icon-left"> add_a_photo </mat-icon>
      setup camera
    </button>
    <div class="unregistered-cameras" *ngIf="incompletCameras.length && accountService.isSupport">
      <div class="brevier--caps">being setup</div>
      <ul class="unregistered-cameras__list o-list">
        <li class="c-setup-camera-item" *ngFor="let camera of incompletCameras" (click)="continueSetupEvent(camera)">
          <div class="c-setup-camera-item__title">
            <div class="is-primary-text">{{ camera.name }}</div>
            <div class="is-secondary-text">{{ sitesMap[camera.siteId].name }}</div>
          </div>
          <div class="c-setup-camera-item__progress">
            <span class="minion--caps u-flush--bottom type--secondary">SETUP:</span> {{ completPercentage[camera.id] }}%
            complete
            <div class="is-progress-bar">
              <div class="is-completed" [ngStyle]="{ 'width.%': completPercentage[camera.id] }"></div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="camera-issues" *ngIf="issuesCameras.length">
      <div class="primer kirk u-padding--top">Camera issues</div>
      <div class="camera-issues__sites-banner">
        <div class="cameras-number">
          <span>{{ issuesCameras.length }}</span> {{ issuesCameras.length | pluraliseWord: 'Camera' }} with issues
        </div>
        <div class="sites-number">Across {{ siteIds.length }} {{ siteIds.length | pluraliseWord: 'site' }}</div>
      </div>
      <div class="camera-issues__monitors-banner" *ngIf="impactedMonitors">
        <div class="monitors-number">
          {{ impactedMonitors }} {{ impactedMonitors | pluraliseWord: 'monitor' }} impacted.
        </div>

        <div class="monitors-text">
          Counts will be unavailable or innacurate for these monitors until issues are resolved.
        </div>
      </div>

      <div class="camera-issues__monitors-list" *ngFor="let siteId of siteIds">
        <div class="site-name">{{ sitesMap[siteId].name }}</div>
        <ul class="o-list" *ngFor="let monitor of issuesCamerasMonitors[siteId]">
          <li (click)="close()">
            <a
              *ngIf="monitor.hasOwnProperty('capacity')"
              class="o-list__item is-link"
              [routerLink]="['/monitors', 'occupancy-monitor', monitor.id]"
            >
              <div class="o-list__item-text">
                <span class="is-primary-text"> {{ monitor.name }} </span>
                <span class="is-secondary-text">Occupancy monitor</span>
              </div>
              <div class="o-list__item-secondary-icon">
                <mat-icon> chevron_right </mat-icon>
              </div>
            </a>
            <a
              *ngIf="monitor.hasOwnProperty('direction1Alias')"
              class="o-list__item is-link"
              [routerLink]="['/monitors', 'people-count-monitor', monitor.id]"
            >
              <div class="o-list__item-text">
                <span class="is-primary-text"> {{ monitor.name }} </span>
                <span class="is-secondary-text">People count monitor</span>
              </div>
              <div class="o-list__item-secondary-icon">
                <mat-icon> chevron_right </mat-icon>
              </div>
            </a>
            <a
              *ngIf="!monitor.hasOwnProperty('capacity') && !monitor.hasOwnProperty('direction1Alias')"
              class="o-list__item is-link"
              [routerLink]="['/monitors', 'peel-off-monitor', monitor.id]"
            >
              <div class="o-list__item-text">
                <span class="is-primary-text"> {{ monitor.name }} </span>
                <span class="is-secondary-text">Peel off monitor</span>
              </div>
              <div class="o-list__item-secondary-icon">
                <mat-icon> chevron_right </mat-icon>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
