<mat-horizontal-stepper linear class="dialog-stepper" #stepper>
  <mat-step #generalStep>
    <app-step
      [stepTitle]="'Edit occupancy alert'"
      [isBackable]="false"
      [nextStepText]="'Save'"
      [processingStep]="saving"
      [disabledNextStep]="
        !occupancyAlertDetailsComponent.alertForm.valid ||
        !occupancyAlertDetailsComponent.selectRecipientsComponent.selectedRecipients.length ||
        (occupancyAlertDetailsComponent.selectRecipientsComponent.createNotificationCheck &&
          !occupancyAlertDetailsComponent.selectRecipientsComponent.createNotificationName.valid)
      "
      (openNextStep)="save()"
      (closeStep)="close(false)"
      class="c-page-split"
    >
      <div class="c-edit-group">
        <div class="is-group-1">
          <app-occupancy-alert-details
            [users]="users"
            [notificationGroups]="notificationGroups"
            [occupancyAlert]="occupancyAlert"
            [occupancyAlertRuleNotificationGroups]="occupancyAlertRuleNotificationGroups"
            #occupancyAlertDetailsComponent
          ></app-occupancy-alert-details>
        </div>
        <div class="is-group-2">
          <div *ngIf="!occupancyAlert.siteId" class="c-links-already-selected">
            <div class="c-links-already-selected__content">
              <img class="c-add-site__image" src="/assets/images/alert-all-sites.png" alt="All sites" />
              <div class="is-text">
                This alert is used in all sites({{ getSitesNumber() }}) for all occupancy monitors({{
                  occupancyAlertRuleMonitors.length
                }})
              </div>
            </div>
          </div>
          <div *ngIf="occupancyAlert.siteId && !alertOccupancyMonitorMappings.length" class="c-links-already-selected">
            <div class="c-links-already-selected__content">
              <img class="c-add-site__image" src="/assets/images/alert-all-sites.png" alt="All sites" />
              <div class="is-text">
                This alert is used for all occupancy monitors({{ occupancyAlertRuleMonitors.length }}) in site -
                {{ site.name }}
              </div>
            </div>
          </div>
          <ng-container *ngIf="occupancyAlert.siteId && alertOccupancyMonitorMappings.length">
            <app-occupancy-alert-select-monitor
              [monitors]="occupancyMonitors"
              [edit]="true"
            ></app-occupancy-alert-select-monitor>
          </ng-container>
        </div>
      </div>
    </app-step>
  </mat-step>
</mat-horizontal-stepper>
