<app-step [stepTitle]="'Order cameras'" [isBackable]="false" [hasNextStep]="false" (closeStep)="close()">
  <div class="o-modal-full-page">
    <h2 class="primer kirk u-margin--left">Select how you want to order the camera list.</h2>
    <ul class="o-list">
      <li class="o-list__item">
        <a mat-menu-item (click)="close('status')" class="o-list--is-link">
          <div class="o-list__item-text">Status</div>
          <mat-icon class="o-list__item-secondary-icon">chevron_right </mat-icon>
        </a>
      </li>
      <li class="o-list__item">
        <a mat-menu-item (click)="close('siteName')" class="o-list--is-link">
          <div class="o-list__item-text">Site</div>
          <div class="o-list__item-secondary-icon">
            <mat-icon class="o-list__item-secondary-icon">chevron_right </mat-icon>
          </div>
        </a>
      </li>
      <li class="o-list__item">
        <a mat-menu-item (click)="close('lastSeenAt')" class="o-list--is-link">
          <div class="o-list__item-text">Last seen</div>
          <div class="o-list__item-secondary-icon">
            <mat-icon class="o-list__item-secondary-icon">chevron_right </mat-icon>
          </div>
        </a>
      </li>
      <li class="o-list__item">
        <a mat-menu-item (click)="close('serialNumber')" class="o-list--is-link">
          <div class="o-list__item-text">Serial number</div>
          <div class="o-list__item-secondary-icon">
            <mat-icon class="o-list__item-secondary-icon">chevron_right </mat-icon>
          </div>
        </a>
      </li>
      <li class="o-list__item">
        <a mat-menu-item (click)="close('videoBacklog')" class="o-list--is-link">
          <div class="o-list__item-text">Video backlog</div>
          <div class="o-list__item-secondary-icon">
            <mat-icon class="o-list__item-secondary-icon">chevron_right </mat-icon>
          </div>
        </a>
      </li>
      <li class="o-list__item" *ngIf="isSupport">
        <a mat-menu-item (click)="close('organisationId')" class="o-list--is-link">
          <div class="o-list__item-text">Organisation</div>
          <div class="o-list__item-secondary-icon">
            <mat-icon class="o-list__item-secondary-icon">chevron_right </mat-icon>
          </div>
        </a>
      </li>
    </ul>
  </div>
</app-step>
