import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-time-pick-dialog',
  templateUrl: './time-pick-dialog.component.html',
  styleUrls: ['./time-pick-dialog.component.scss'],
})
export class TimePickDialogComponent implements OnInit {
  start: any;
  end: any;
  saveAsDefault = false;
  dialogTopDescription: string = '';
  dialogTitle = 'Open & closing times';

  constructor(
    private dialogRef: MatDialogRef<TimePickDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.start = this.data.start;
      this.end = this.data.end;
      this.dialogTitle = this.data.dialogTitle;
      this.dialogTopDescription = this.data.dialogTopDescription;
      this.saveAsDefault = this.data.saveAsDefault;
    }
  }

  getValueInRange(value: number, range: number): number {
    return value > range ? range : value;
  }

  update(): void {
    const response = { start: this.start, end: this.end, saveAsDefault: this.saveAsDefault };
    this.dialogRef.close(response);
  }

  close(): void {
    this.dialogRef.close();
  }

  stripText(event) {
    const seperator = '^([0-9])';
    const maskSeperator = new RegExp(seperator, 'g');
    const result = maskSeperator.test(event.key);

    return result;
  }
}
