import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject, forkJoin } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { CameraService, Organisation, OrganisationService, SiteService, UserService } from 'src/app/api';
import { AddEditOrganistaionComponent } from 'src/app/components/organisations/add-edit-organistaion/add-edit-organistaion.component';
import { AccountService } from 'src/app/services/account.service';
import { CamerasService } from 'src/app/services/cameras.service';

@Component({
  selector: 'app-organisations',
  templateUrl: './organisations.component.html',
  styleUrls: ['./organisations.component.scss'],
})
export class OrganisationsComponent implements OnInit, OnDestroy {
  isLoading = false;
  organisations = [];
  filteredOrganisations = [];
  organisationsSites = {};
  organisationsCameras = {};
  organisationsUsers = {};

  mustContainString = '';
  isSupport: boolean;

  private ngUnsubscribe = new Subject();
  constructor(
    private accountService: AccountService,
    private cameraService: CameraService,
    private camerasService: CamerasService,
    private siteService: SiteService,
    private userService: UserService,
    private organisationService: OrganisationService,
    private router: Router,
    private matDialog: MatDialog,
    private ref: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.isSupport = this.accountService.isSupport;
    this.getData();
  }

  getData(): void {
    this.isLoading = true;
    this.ref.detectChanges();
    forkJoin([
      this.siteService.listSites(undefined, undefined, 'active'),
      this.cameraService.listCameras(true, undefined, undefined, ['running', 'paused']),
      this.userService.listUsers(),
      this.organisationService.listOrganisations(),
    ])
      .pipe(
        finalize(() => {
          this.applyFilter();
          this.isLoading = false;
          this.ref.detectChanges();
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: ([sites, cameras, users, organisations]) => {
          this.organisations = organisations.sort((e1: Organisation, e2: Organisation) => {
            if (e1.name.toLowerCase() > e2.name.toLowerCase()) {
              return 1;
            } else {
              return -1;
            }
          });
          this.organisations.forEach((organisation) => {
            this.organisationsSites[organisation.id] = [];
            this.organisationsCameras[organisation.id] = [];
            this.organisationsUsers[organisation.id] = [];
          });

          sites.forEach((site) => {
            this.organisationsSites[site.organisationId].push(site);
          });
          cameras = cameras.map((c) => this.camerasService.getCameraStatus(c));

          cameras.forEach((camera) => {
            this.organisationsCameras[camera.organisationId].push(camera);
          });
          users.forEach((user) => {
            this.organisationsUsers[user.organisationId].push(user);
          });
        },
        error: (_) => {
          this.router.navigate(['internal-error']);
        },
      });
  }

  openAddEditOrganistaion(organisation?): void {
    const data = {};
    if (organisation) {
      data['organisation'] = organisation;
      data['oAuthClientIds'] = this.accountService.oAuthClientLinks.filter(
        (oAuthClientLink) => oAuthClientLink.organisationId === organisation.id,
      );
    }

    const dialogRef = this.matDialog.open(AddEditOrganistaionComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data,
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          this.getData();
        }
      },
    });
  }

  applyFilter(): void {
    this.filteredOrganisations = this.organisations.filter(
      (organisation) =>
        organisation.name.toLowerCase().includes(this.mustContainString) ||
        organisation.id.toLowerCase().includes(this.mustContainString),
    );
  }

  runSearch(ss: string): void {
    this.mustContainString = ss.toLowerCase();
    this.applyFilter();
  }

  clearMustContainString(): void {
    this.mustContainString = '';
    this.applyFilter();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
