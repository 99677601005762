<div class="auth-overlay" *ngIf="isLoading; else notLoading">
  <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
    <p style="font-size: 2rem; font-weight: 500">Loading monitor details...</p>
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>
<ng-template #notLoading>
  <div>
    <div class="c-grid-section">
      <div class="o-feature-card">
        <div class="o-feature-card__head">
          <span class="material-icons">groups</span>
          <div class="xis-title xis-reverse">
            <div class="minion--caps">Occupancy monitor</div>
            <h2 class="primer--bold type--ghost">{{ monitor.name }}</h2>
          </div>
        </div>
        <ul class="o-list is-compact is-light u-margin--bottom">
          <li class="o-list__item">
            <div class="o-list__item-icon">
              <span class="material-icons">apartment</span>
            </div>
            <div class="o-list__item-text">{{ site.name }}</div>
          </li>
          <li class="o-list__item">
            <div class="o-list__item-icon">
              <span class="material-icons">photo_camera</span>
            </div>
            <div class="o-list__item-text">{{ monitorCameras.length | pluraliseWord: 'Camera' }}</div>
            <div class="o-list__item-secondary">
              {{ monitorCameras.length }}
            </div>
          </li>
          <li class="o-list__item">
            <div class="o-list__item-icon">
              <span class="material-icons">groups</span>
            </div>
            <div class="o-list__item-text">Capacity</div>
            <div class="o-list__item-secondary">
              {{ monitor.capacity }}
            </div>
          </li>
          <li class="o-list__item">
            <div class="o-list__item-icon">
              <span class="material-icons">notifications</span>
            </div>
            <div class="o-list__item-text">Occupancy alerts</div>
            <div class="o-list__item-secondary">
              {{ occupancyAlerts.length }}
            </div>
          </li>
          <li class="o-list__item">
            <div class="o-list__item-icon">
              <span class="material-icons">schedule</span>
            </div>
            <div class="o-list__item-text">Daily reset time</div>
            <div class="o-list__item-secondary">
              {{ monitor.dailyResetMin | minutesToTimeString }}
            </div>
          </li>
          <ng-container *ngIf="accountService.isSupport">
            <li class="o-list__item" *ngIf="monitor.reportingStartDate">
              <div class="o-list__item-icon">
                <span class="material-icons">event</span>
              </div>
              <div class="o-list__item-text">Reporting start date</div>
              <div class="o-list__item-secondary">
                {{ monitor.reportingStartDate | date: 'dd/MM/yyyy' }}
              </div>
            </li>
            <li class="o-list__item" *ngIf="monitor.reportingEndDate">
              <div class="o-list__item-icon">
                <span class="material-icons">event</span>
              </div>
              <div class="o-list__item-text">Reporting end date</div>
              <div class="o-list__item-secondary">{{ monitor.reportingEndDate | date: 'dd/MM/yyyy' }}</div>
            </li>
          </ng-container>
        </ul>
        <div class="o-feature-card__action" *ngIf="accountService.isSupport">
          <button class="o-button is-ghost" (click)="openEditOccupancyMonitorDialog()">
            Edit monitor
            <span class="material-icons is-icon-right">arrow_forward</span>
          </button>
        </div>
      </div>

      <div class="c-monitor-summary">
        <div class="c-monitor-summary__counts">
          <h3 class="long-primer kirk">Live occupancy</h3>
          <div class="c-week-count">
            <div class="dot-flashing" *ngIf="isLoadingChart"></div>
            <div #todayChart></div>
          </div>

          <div class="is-action">
            <a class="o-button is-contained" [routerLink]="['/reporting', 'capacity-management', monitor.id]">
              More reporting
              <mat-icon class="is-icon-right">arrow_forward</mat-icon>
            </a>
          </div>
        </div>

        <div class="c-monitor-summary__chart">
          <div class="is-header">
            <div class="o-heading--primer">
              <h3>Last 7 days</h3>
              <div class="is-legend">
                <span class="is-avg-occupancy">Avg occupancy</span>
                <span class="is-max-occupancy">Max occupancy</span>
              </div>
            </div>
            <span
              [matTooltip]="'There are no opening hours set for this site.'"
              [matTooltipDisabled]="!!site.openingHour"
            >
              <mat-checkbox [(ngModel)]="useOpeningHours" (change)="setChartData()" [disabled]="!site.openingHour"
                >Filter by opening hours</mat-checkbox
              >
            </span>
          </div>
          <div class="is-chart" #chart></div>
        </div>
      </div>
    </div>

    <div class="content-container">
      <div class="c-section-title">
        <h4 class="is-title">Camera views</h4>
      </div>

      <div class="o-card-grid">
        <ng-container *ngFor="let camera of monitorCameras">
          <div class="o-monitor-view">
            <div class="o-monitor-view__image">
              <ng-container *ngIf="camera.state === 'running'; else noImage">
                <ng-container *ngIf="isLoadingCameraFrame[camera.id]; else notLoadingImg">
                  <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
                    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
                  </div>
                </ng-container>
              </ng-container>

              <ng-template #notLoadingImg>
                <ng-container *ngIf="cameraFrame.hasOwnProperty(camera.id); else noImage">
                  <div class="o-monitor-view__image-wrap">
                    <img [src]="cameraFrame[camera.id]" alt="camera view" />
                    <ng-container *ngIf="!camera.isPassingTraffic; else passTraffCrossingLine">
                      <svg height="100%" width="100%">
                        <line x1="0%" y1="50%" x2="100%" y2="50%" stroke="rgb(255,0,0)" stroke-width="2" />
                      </svg>
                    </ng-container>
                    <ng-template #passTraffCrossingLine>
                      <svg height="100%" width="100%">
                        <line x1="50%" y1="0%" x2="50%" y2="100%" stroke="rgb(255,0,0)" stroke-width="2" />
                      </svg>
                    </ng-template>
                  </div>
                  <div
                    class="o-monitor-view__focus-direction"
                    [ngClass]="{
                      'is-left':
                        camera.isPassingTraffic &&
                        camerasPositions[camera.cameraPositionId].inDirection === 'direction_1',
                      'is-right':
                        camera.isPassingTraffic &&
                        camerasPositions[camera.cameraPositionId].inDirection === 'direction_2',
                      'is-top':
                        !camera.isPassingTraffic &&
                        camerasPositions[camera.cameraPositionId].inDirection === 'direction_1',
                      'is-bottom':
                        !camera.isPassingTraffic &&
                        camerasPositions[camera.cameraPositionId].inDirection === 'direction_2',
                    }"
                  >
                    <div class="is-arrow">
                      <mat-icon class="is-icon">{{
                        camera.isPassingTraffic
                          ? camerasPositions[camera.cameraPositionId].inDirection === 'direction_1'
                            ? 'arrow_back'
                            : 'arrow_forward'
                          : camerasPositions[camera.cameraPositionId].inDirection === 'direction_1'
                            ? 'north'
                            : 'south'
                      }}</mat-icon>
                    </div>
                    <span class="is-label"> IN </span>
                  </div>
                </ng-container>
              </ng-template>

              <ng-template #noImage>
                <div class="o-monitor-view__no-image">
                  <div *ngIf="camera.state !== 'running'" class="is-message">
                    <mat-icon class="material-icons">broken_image</mat-icon>
                    <p>Camera is not running.</p>
                  </div>
                  <div *ngIf="camera.state === 'running'" class="is-message">
                    <mat-icon class="material-icons">broken_image</mat-icon>
                    <p>Could not get camera view.</p>
                  </div>
                </div>
              </ng-template>
            </div>

            <div class="o-monitor-view__title">
              <div
                class="o-list-card__title-icon is-online"
                *ngIf="cameraFrame.hasOwnProperty(camera.id); else cameraOfflineIcon"
              >
                <mat-icon>photo_camera</mat-icon>
              </div>
              <ng-template #cameraOfflineIcon>
                <div class="o-list-card__title-icon is-offline">
                  <mat-icon>no_photography</mat-icon>
                </div>
              </ng-template>

              <div class="o-heading--primer u-flush--bottom">
                <h2>{{ camera.name }}</h2>
                <h3>{{ camera.type }}</h3>
              </div>
            </div>
            <div class="o-monitor-view__directions">
              <div class="o-monitor-view__direction">
                <div class="is-label">{{ camera.direction1Alias }}</div>
                <div
                  [ngClass]="{
                    'is-in-direction': camerasPositions[camera.cameraPositionId].inDirection === 'direction_1',
                  }"
                >
                  <span>
                    {{ camerasPositions[camera.cameraPositionId].inDirection === 'direction_1' ? 'IN' : 'OUT' }}</span
                  >
                </div>
              </div>
              <div class="o-monitor-view__direction">
                <div class="is-label">{{ camera.direction2Alias }}</div>
                <div
                  [ngClass]="{
                    'is-in-direction': camerasPositions[camera.cameraPositionId].inDirection === 'direction_2',
                  }"
                >
                  <span>
                    {{ camerasPositions[camera.cameraPositionId].inDirection === 'direction_2' ? 'IN' : 'OUT' }}</span
                  >
                </div>
              </div>
            </div>
            <div class="o-monitor-view__action">
              <a class="o-button is-small is-action" [routerLink]="['/cameras/details/' + camera.id]">
                View camera
                <span class="material-icons is-icon-right"> arrow_forward </span>
              </a>
            </div>
          </div>
        </ng-container>
        <div class="o-card-grid" *ngIf="!monitorCameras.length">
          <div class="c-no-content">
            <h4 class="is-title">No cameras added.</h4>
            <div class="is-media">
              <img src="/assets/images/no-camera-placeholder.png" alt="No cameras" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
