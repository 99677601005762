import { Injectable } from '@angular/core';
import { Camera, NotificationGroup, NotificationGroupNotificationGroup, Site, User } from 'src/app/api';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private _notificationGroups: NotificationGroup[] = [];
  private _notificationGroupsMap: { [_: string]: NotificationGroup } = {};
  private _notificationGroupMembers: { [_: string]: number } = {};
  private _notificationGroupNotificationGroups: NotificationGroupNotificationGroup[] = [];
  private _notificationGroupNotificationGroupMap: { [_: string]: NotificationGroupNotificationGroup[] } = {};
  private _cameras: Camera[] = [];
  private _camerasMap: { [_: string]: Camera } = {};
  private _sites: Site[] = [];
  private _sitesMap: { [_: string]: Site } = {};
  private _users: User[] = [];

  get notificationGroups(): NotificationGroup[] {
    return this._notificationGroups;
  }

  set notificationGroups(notificationGroups: NotificationGroup[]) {
    this._notificationGroups = notificationGroups;
  }

  get notificationGroupsMap(): { [_: string]: NotificationGroup } {
    return this._notificationGroupsMap;
  }

  set notificationGroupsMap(notificationGroupsMap: { [_: string]: NotificationGroup }) {
    this._notificationGroupsMap = notificationGroupsMap;
  }

  get notificationGroupMembers(): { [_: string]: number } {
    return this._notificationGroupMembers;
  }

  set notificationGroupMembers(notificationGroupMembers: { [_: string]: number }) {
    this._notificationGroupMembers = notificationGroupMembers;
  }

  get notificationGroupNotificationGroups(): NotificationGroupNotificationGroup[] {
    return this._notificationGroupNotificationGroups;
  }

  set notificationGroupNotificationGroups(notificationGroupNotificationGroups: NotificationGroupNotificationGroup[]) {
    this._notificationGroupNotificationGroups = notificationGroupNotificationGroups;
  }

  get notificationGroupNotificationGroupMap(): { [_: string]: NotificationGroupNotificationGroup[] } {
    return this._notificationGroupNotificationGroupMap;
  }

  set notificationGroupNotificationGroupMap(notificationGroupNotificationGroupMap: {
    [_: string]: NotificationGroupNotificationGroup[];
  }) {
    this._notificationGroupNotificationGroupMap = notificationGroupNotificationGroupMap;
  }

  get cameras(): Camera[] {
    return this._cameras;
  }

  set cameras(cameras: Camera[]) {
    this._cameras = cameras;
  }

  get camerasMap(): { [_: string]: Camera } {
    return this._camerasMap;
  }

  set camerasMap(camerasMap: { [_: string]: Camera }) {
    this._camerasMap = camerasMap;
  }

  get sites(): Site[] {
    return this._sites;
  }

  set sites(sites: Site[]) {
    this._sites = sites;
  }

  get sitesMap(): { [_: string]: Site } {
    return this._sitesMap;
  }

  set sitesMap(sitesMap: { [_: string]: Site }) {
    this._sitesMap = sitesMap;
  }

  get users(): User[] {
    return this._users;
  }

  set users(users: User[]) {
    this._users = users;
  }
}
