import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { filter, switchMap, of } from 'rxjs';
import { AccountService } from 'src/app/services/account.service';

export const hasAccessGuard: CanActivateFn = (route, state) => {
  const accountService = inject(AccountService);

  return accountService.isLoading$.pipe(
    filter((isLoading) => !isLoading),
    switchMap(() => {
      return of(accountService.hasAppAccess);
    }),
  );
};
