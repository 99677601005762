<section class="o-expansion-section">
  <div class="o-expansion-section__header" (click)="collapseAddSiteSection = !collapseAddSiteSection">
    <mat-icon class="is-icon">business</mat-icon>
    <div class="is-title">Add a site</div>
    <mat-icon class="is-expansion-icon">
      {{ collapseAddSiteSection ? 'expand_more' : 'expand_less' }}
    </mat-icon>
  </div>

  <div class="o-expansion-section__body c-add-site" [ngClass]="{ 'is-hidden': collapseAddSiteSection }">
    <div class="c-add-site__text">
      <div class="is-primary-text">Add your first site.</div>
      <div class="is-secondary-text">
        A site is the whole building or address that you want to use HoxtonAi cameras in.
      </div>
      <div class="is-secondary-text">
        You can add as many sites as you need for your organisation.
        <a href="https://docs.hoxton.ai/user-guide/sites"> Learn more. </a>
      </div>

      <button class="o-button is-primary" (click)="addSite.emit()">
        <span class="material-icons is-icon-left"> business </span>
        add a site
      </button>
    </div>

    <img class="c-add-site__image" src="/assets/images/new-homepage/no-sites.png" alt="No sites" />
  </div>
</section>
