<form class="c-form">
  <div class="c-alert-camera-container">
    <mat-icon class="is-icon">photo_camera</mat-icon>
    <div class="c-camera-list-container">
      <h2 class="c-text-which-camera">Which cameras will use this alert?</h2>

      <div class="c-camera-list-wrapper">
        <div class="c-camera-list-title-wrapper">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="masterToggle()"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
            <div class="o-heading--normal u-flush--bottom">
              <div class="c-camera-list-title">All cameras ({{ cameras.length }})</div>
              <div class="c-camera-list-subtitle">Cameras you add in future will be automatically added.</div>
            </div>
          </mat-checkbox>
        </div>

        <ul class="c-camera-list">
          <li *ngFor="let camera of cameras" class="c-camera-list-item">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="selection.toggle(camera)"
              [checked]="selection.isSelected(camera)"
            >
              {{ camera.name }}
            </mat-checkbox>
          </li>
        </ul>
      </div>
    </div>
  </div>
</form>
