import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { finalize, Subject, take, takeUntil } from 'rxjs';
import { Camera, CameraService, PeopleCountMonitor, ReportService, Site } from 'src/app/api';
import { EditPeopleCountMonitorComponent } from 'src/app/components/monitors/edit-people-count-monitor/edit-people-count-monitor.component';
import { AccountService } from 'src/app/services/account.service';
import { NotifyService } from 'src/app/services/notify.service';
import * as ApexCharts from 'apexcharts';
import { formatDate } from '@angular/common';
import { MonitorsService } from 'src/app/services/monitors.service';
export type ExtendedCamera = Camera & {
  type: string;
  isPassingTraffic: boolean;
  isOnline: boolean;
};

@Component({
  selector: 'app-people-count-monitor-details',
  templateUrl: './people-count-monitor-details.component.html',
  styleUrls: ['./people-count-monitor-details.component.scss'],
})
export class PeopleCountMonitorDetailsComponent implements OnInit, OnDestroy {
  monitorId = '';
  graphData = [];

  site: Site;
  monitor: PeopleCountMonitor;
  monitorCameras = [];
  camerasPositions = {};
  cameraFrame = {};

  isLoadingCameraFrame = {};
  isLoading = true;
  isLoadingChart = false;
  useOpeningHours = false;

  dateFrom;
  dateTo;
  xaxis;

  direction1Today: { [key in 'true' | 'false']: number } = { true: 0, false: 0 };
  direction2Today: { [key in 'true' | 'false']: number } = { true: 0, false: 0 };
  chartData: { [key in 'true' | 'false']: { dir1: []; dir2: []; both: [] } } = { true: undefined, false: undefined };
  selectedDirection: 'dir1' | 'dir2' | 'both' = 'both';

  viewHeight: number;
  private chart: ElementRef;

  @ViewChild('chart') set content(content: ElementRef) {
    if (content && !this.chart) {
      this.chart = content;
      this.viewHeight = this.chart.nativeElement.offsetHeight;
      this.setChartData();
    }
  }

  private ngUnsubscribe = new Subject();

  constructor(
    public accountService: AccountService,
    private cameraService: CameraService,
    private route: ActivatedRoute,
    private notifyService: NotifyService,
    private matDialog: MatDialog,
    private router: Router,
    private ref: ChangeDetectorRef,
    public monitorsService: MonitorsService,
    private reportService: ReportService,
  ) {}

  ngOnInit(): void {
    this.route.params.pipe(take(1), takeUntil(this.ngUnsubscribe)).subscribe({
      next: (params) => {
        this.monitorId = params?.id;
        this.monitor = this.monitorsService.getPeopleCountMonitor(this.monitorId);
        if (this.monitor === undefined) {
          this.router.navigate(['/not-found']);
        }
        this.site = this.monitorsService.sites.find((s) => s.id === this.monitor.siteId);
        this.monitorCameras = this.monitorsService.peopleCountMonitorCameras[this.monitorId];
        this.monitorCameras.forEach((camera) => {
          camera.isPassingTraffic = camera.type === 'Passing traffic';

          this.getCameraFrame(camera);
        });
        this.camerasPositions = this.monitorsService.peopleCountMonitorCamerasPositions[this.monitorId];

        if (params?.edit) {
          this.openEditPeopleCountMonitorDialog(true);
        }
        this.isLoading = false;
        this.ref.detectChanges();
      },
      error: (error) => {
        this.notifyService.error(error);
        this.router.navigate(['internal-error']);
      },
    });
  }

  setChartData() {
    if (!this.chart) {
      return;
    }

    this.removeAllChildNodes(this.chart.nativeElement);
    this.isLoadingChart = true;
    this.ref.detectChanges();
    if (!this.chartData[String(this.useOpeningHours)]) {
      this.getReportData();
    } else {
      this.drawChart();
      this.isLoadingChart = false;
      this.ref.detectChanges();
    }
  }

  getReportData() {
    this.dateFrom = moment().subtract(7, 'days').format('DD/MM/YYYY');
    this.dateTo = moment().subtract(1, 'day').format('DD/MM/YYYY');
    this.xaxis = [];
    this.reportService
      .createPeopleCountReport({
        peopleCountMonitorIds: [this.monitorId],
        startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        endDate: moment().add(1, 'day').format('YYYY-MM-DD'),
        timeGrain: '24h',
        isOpen: this.useOpeningHours,
      })
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.drawChart();
          this.isLoadingChart = false;
          this.ref.detectChanges();
        }),
      )
      .subscribe({
        next: (reports) => {
          const dir1 = [];
          const dir2 = [];
          const both = [];
          reports.forEach((report) => {
            this.direction1Today[String(this.useOpeningHours)] = report['direction1Count'];
            this.direction2Today[String(this.useOpeningHours)] = report['direction2Count'];
            dir1.push(report['direction1Count']);
            dir2.push(report['direction2Count']);
            both.push(report['direction1Count'] + report['direction2Count']);

            this.xaxis.push(moment(report['time'].split('.')[0]).format());
          });
          this.chartData[String(this.useOpeningHours)] = { dir1, dir2, both };
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  private drawChart(): void {
    const chartOptions = this.getChartOptions();
    const chart = new ApexCharts(this.chart.nativeElement, chartOptions);
    chart.render();
  }

  private removeAllChildNodes(element: HTMLElement) {
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  }

  private getChartOptions(): ApexCharts.ApexOptions {
    return {
      chart: {
        toolbar: { show: false },
        width: '98%',
        height: this.viewHeight,
        zoom: { enabled: false },
      },
      series: [
        {
          name: 'Both',
          type: 'line',
          data: this.chartData[String(this.useOpeningHours)]['both'],
        },
        {
          name: this.monitor.direction1Alias,
          type: 'column',
          data: this.chartData[String(this.useOpeningHours)]['dir1'],
        },
        {
          name: this.monitor.direction2Alias,
          type: 'column',
          data: this.chartData[String(this.useOpeningHours)]['dir2'],
        },
      ],
      colors: [
        function ({ value, seriesIndex, dataPointIndex, w }) {
          switch (seriesIndex) {
            case 0: {
              return 'var(--color-turquoise)';
            }
            case 1: {
              return dataPointIndex == 7 ? 'var(--color-violet--light)' : 'var(--color-violet)';
            }
            case 2: {
              return dataPointIndex == 7 ? 'var(--color-mikado--light-2)' : 'var(--color-mikado)';
            }
          }
          return '';
        },
      ],

      xaxis: {
        categories: this.xaxis,
        labels: {
          formatter: function (value, timestamp, opts) {
            if (!value) {
              return '';
            }
            return moment(value).isSame(new Date(), 'day') ? 'Today' : formatDate(new Date(value), 'EEE', 'en_US');
          },
        },
      },
      yaxis: [
        {
          seriesName: 'Both',
          labels: {
            formatter: (value) => String(value),
          },
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          title: {
            text: 'Both',
          },
        },
        {
          seriesName: this.monitor.direction1Alias,
          labels: {
            formatter: (value) => String(value),
          },
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          title: {
            text: this.monitor.direction1Alias + ' / ' + this.monitor.direction2Alias,
          },
        },
        {
          seriesName: this.monitor.direction1Alias,
          labels: {
            formatter: (value) => String(value),
          },
          show: false,
        },
      ],
      grid: {
        show: true,
      },
      stroke: { width: 2, curve: 'monotoneCubic' },
      markers: { size: 4 },
      legend: { show: false },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 1,
          borderRadiusApplication: 'end',
          columnWidth: '90%',
        },
      },
    };
  }

  getCameraFrame(camera: Camera): void {
    if (camera.state === 'running' && camera.isOnline) {
      this.isLoadingCameraFrame[camera.id] = true;
      this.ref.detectChanges();

      this.cameraService.createFrameRequest({ cameraId: camera.id }).subscribe({
        next: (frame) => {
          this.cameraFrame[camera.id] = 'data:image/jpeg;base64,' + frame.image;
          this.isLoadingCameraFrame[camera.id] = false;
          this.ref.detectChanges();
        },
        error: (error) => {
          delete this.isLoadingCameraFrame[camera.id];
          this.ref.detectChanges();
          this.notifyService.error(error);
        },
      });
    }
  }

  openEditPeopleCountMonitorDialog(isRedirect?: boolean): void {
    const dialogRef = this.matDialog.open(EditPeopleCountMonitorComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data: {
        cameras: this.monitorsService.cameras,
        monitor: this.monitor,
        monitorCameras: this.monitorCameras,
        cameraPositions: this.camerasPositions,
        isRedirect: isRedirect,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          window.location.reload();
        }
      },
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }

  protected readonly String = String;
}
