import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Site } from 'src/app/api';
import { CameraStatus } from 'src/app/model/cameraStatus';
import { CamerasService } from 'src/app/services/cameras.service';

@Component({
  selector: 'app-find-out-more-modal-dialog',
  templateUrl: './find-out-more-modal-dialog.component.html',
  styleUrls: ['./find-out-more-modal-dialog.component.scss'],
})
export class FindOutMoreModalDialogComponent {
  cameras: CameraStatus[] = [];
  camerasPronedToIssues: CameraStatus[] = [];
  offlineCamerasWithIssues: CameraStatus[] = [];
  sitesMap: { [_: string]: Site } = {};
  issueType;

  stepsTitle = 'Offline cameras';

  @ViewChild('stepper') stepper: MatStepper;
  constructor(
    public dialogRef: MatDialogRef<FindOutMoreModalDialogComponent>,
    private camerasService: CamerasService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.cameras = data.cameras;
    this.camerasPronedToIssues = data.camerasPronedToIssues;
    this.offlineCamerasWithIssues = data.offlineCamerasWithIssues;
    this.issueType = data.issueType;
  }

  goToSecondStep(): void {
    this.stepsTitle = 'Pause cameras';
    this.stepper.next();
  }

  getCamerasSites(): number {
    const siteIds = new Set<string>();
    this.cameras.forEach((camera: CameraStatus) => {
      siteIds.add(camera.siteId);
    });
    return siteIds.size;
  }

  getCamerasWithIssues(): number {
    if (this.issueType === 'alert') {
      return this.cameras.filter((camera: CameraStatus) => !camera.isOnline).length;
    } else {
      return this.cameras.filter((camera: CameraStatus) => this.camerasService.hasIssue(camera)).length;
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
