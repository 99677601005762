import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';
import { NotificationGroup, User } from 'src/app/api';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-select-recipients',
  templateUrl: './select-recipients.component.html',
  styleUrls: ['./select-recipients.component.scss'],
})
export class SelectRecipientsComponent implements OnInit {
  createNotificationCheck = false;
  createNotificationName = new FormControl('', [Validators.required]);

  filteredNotificationGroups: NotificationGroup[] = [];
  filteredUsers: User[] = [];
  recipientsOptions = [];
  $filteredUsers: Observable<any[]>;
  $filteredNotificationGroups: Observable<any[]>;
  searchRecipientsControl = new FormControl('', [
    Validators.email,
    Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
  ]);
  notificationGroupMembers: { [_: string]: number } = {};

  @Input() hasCreateOption = true;
  @Input() selectedRecipients = [];
  @Input() set users(users: User[]) {
    this.filteredUsers = users;
    this.searchRecipientsControl.setValue('');
  }
  @Input() set notificationGroups(notificationGroups: NotificationGroup[]) {
    this.filteredNotificationGroups = notificationGroups;
    this.searchRecipientsControl.setValue('');
  }

  constructor(private notificationsService: NotificationsService) {
    this.notificationGroupMembers = this.notificationsService.notificationGroupMembers;
  }

  ngOnInit(): void {
    this.$filteredUsers = this.searchRecipientsControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterUsers(value || '')),
    );

    this.$filteredNotificationGroups = this.searchRecipientsControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterNotificationGroups(value || '')),
    );
  }

  private filterUsers(value: string): User[] {
    const filterValue = value.toLowerCase();

    return this.filteredUsers.filter(
      (user) => user.name.toLowerCase().includes(filterValue) || user.email.toLowerCase().includes(filterValue),
    );
  }

  private filterNotificationGroups(value: string): NotificationGroup[] {
    const filterValue = value.toLowerCase();

    return this.filteredNotificationGroups.filter((notificationGroups) =>
      notificationGroups.name.toLowerCase().includes(filterValue),
    );
  }

  addRecipients(recipientsOption) {
    if (this.searchRecipientsControl.invalid) {
      return;
    }

    this.selectedRecipients.push(recipientsOption);
    this.selectedRecipients = [...new Set(this.selectedRecipients)];

    this.searchRecipientsControl.setValue('');
  }

  getRecipientType(recipient: NotificationGroup | User | string): string {
    if (typeof recipient === 'string') {
      return 'email';
    } else if (recipient.hasOwnProperty('firstName')) {
      return 'user';
    }
    return 'group';
  }

  deleteSelectedRecipient(recipient) {
    this.selectedRecipients = this.selectedRecipients.filter((selectedRecipient) => recipient !== selectedRecipient);
  }
}
