import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input } from '@angular/core';
import { OccupancyMonitor } from 'src/app/api';

@Component({
  selector: 'app-occupancy-alert-select-monitor',
  templateUrl: './occupancy-alert-select-monitor.component.html',
  styleUrls: ['./occupancy-alert-select-monitor.component.scss'],
})
export class OccupancyAlertSelectMonitorComponent {
  @Input() monitors: OccupancyMonitor[] = [];
  @Input() edit = false;
  selection = new SelectionModel<OccupancyMonitor>(true, []);

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.monitors.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.monitors.forEach((monitor) => this.selection.select(monitor));
  }
}
