import { Injectable } from '@angular/core';
import { User } from '@auth0/auth0-angular';
import { BehaviorSubject } from 'rxjs';
import { OAuthClient, OAuthClientLink, Organisation } from 'src/app/api';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  public isLoading$ = new BehaviorSubject<boolean>(true);
  showNotReleased = false;
  hasAppAccess = true;
  isSupport = false;
  isAdmin = false;
  isMember = false;
  role = undefined;
  canImpersonate = false;
  canCreateOAuth = false;
  canReadOAuth = false;
  canUpdateOAuth = false;
  occupancyLicence = false;
  peopleCountLicence = false;
  dataExplorerLicence = false;
  organisation: Organisation = undefined;
  organisations: Organisation[] = [];
  organisationsMap: { [_: string]: Organisation } = {};
  oAuthClientLinks: OAuthClientLink[] = [];
  oAuthClients: OAuthClient[] = [];
  user: User;
  licenseIcon = {
    data_explorer: 'query_stats',
    occupancy_monitor: 'groups',
    people_count: 'transfer_within_a_station',
    real_time: 'pending_actions',
  };
}
