import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Alert, NotificationGroup, OccupancyMonitor, PeelOffMonitor, PeopleCountMonitor, User } from 'src/app/api';
import { SitesOpeningHoursComponent } from 'src/app/components/sites/sites-opening-hours/sites-opening-hours.component';
import { CameraStatus } from 'src/app/model/cameraStatus';
import { SiteStatus } from 'src/app/model/siteStatus';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-infrastructure-aside',
  templateUrl: './infrastructure-aside.component.html',
  styleUrls: ['./infrastructure-aside.component.scss'],
  providers: [MatDialogModule],
})
export class InfrastructureAsideComponent implements OnInit {
  @Input() sites: SiteStatus[] = [];
  @Input() sitesMap: { [_: string]: SiteStatus } = {};
  @Input() cameras: CameraStatus[] = [];
  @Input() issueCameras: CameraStatus[] = [];
  @Input() incompletCameras: CameraStatus[] = [];
  @Input() summaryAlerts: Alert[] = [];
  @Input() camerasWithNoSummaryAlert: CameraStatus[] = [];
  @Input() offlineAlerts: Alert[] = [];
  @Input() camerasWithNoOfflineAlerts: CameraStatus[] = [];
  @Input() occupancyMonitors: OccupancyMonitor[] = [];
  @Input() peelOffMonitors: PeelOffMonitor[] = [];
  @Input() peopleCountMonitors: PeopleCountMonitor[] = [];
  @Input() occupancyAlerts: Alert[] = [];
  @Input() noOccupancyAlertMonitors: OccupancyMonitor[] = [];

  pausedCameras: number;
  completPercentage: { [_: string]: number } = {};

  @Output() addSite: EventEmitter<void> = new EventEmitter();
  @Output() addCamera: EventEmitter<void> = new EventEmitter();
  @Output() addOccupancyMonitor: EventEmitter<void> = new EventEmitter();
  @Output() addPeopleCountMonitor: EventEmitter<void> = new EventEmitter();
  @Output() addPeelOffMonitor: EventEmitter<void> = new EventEmitter();
  @Output() continueSetup: EventEmitter<CameraStatus> = new EventEmitter<CameraStatus>();

  constructor(
    private matDialog: MatDialog,
    public accountService: AccountService,
  ) {}

  ngOnInit(): void {
    this.pausedCameras = this.getPausedCameras();
    this.incompletCameras.forEach((c: CameraStatus) => {
      this.completPercentage[c.id] = 20;

      if (new Date(c.claimedAt).getTime() < new Date(c.lastSeenAt).getTime()) {
        this.completPercentage[c.id] = 45;
      }
      if (c.direction1Alias !== 'Direction 1' && c.direction2Alias !== 'Direction 2') {
        this.completPercentage[c.id] = 80;
      }
    });
  }

  openDialog(templateRef): void {
    this.matDialog.open(templateRef, {
      width: '100vw',
      maxWidth: '100vw',
      height: '100%',
    });
  }

  getPausedCameras(): number {
    return this.cameras.filter((c) => c.state === 'paused').length;
  }

  getCamerasSites(cameras: CameraStatus[]): number {
    return new Set(cameras.map((c) => c.siteId)).size;
  }

  openOpeningHours(): void {
    this.matDialog.open(SitesOpeningHoursComponent, {
      data: { sites: this.sites },
      width: '100vw',
      maxWidth: '100vw',
      height: '100%',
    });
  }

  closeDialog(): void {
    this.matDialog.closeAll();
  }
}
