import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AccountService } from 'src/app/services/account.service';
import { buildHash, buildDate } from 'src/environments/version';

@Component({
  selector: 'app-navigation-right-side',
  templateUrl: './navigation-right-side.component.html',
  styleUrls: ['./navigation-right-side.component.scss'],
})
export class NavigationRightSideComponent implements OnInit {
  isSupport: boolean;
  isAdmin: boolean;
  hasOccupancyLicence: boolean;

  constructor(
    public dialogRef: MatDialogRef<NavigationRightSideComponent>,
    private accountService: AccountService,
  ) {}

  ngOnInit(): void {
    this.isSupport = this.accountService.isSupport;
    this.isAdmin = this.accountService.isAdmin;
    this.hasOccupancyLicence = this.accountService.occupancyLicence;
  }

  close() {
    this.dialogRef.close();
  }

  protected readonly buildHash = buildHash;
  protected readonly buildDate = buildDate;
}
