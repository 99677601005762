<div class="c-container">
  <div class="c-container-cards">
    <div class="c-license-card">
      <div class="long-primer kirk">Organisation Licenses</div>
      <div class="c-opening-hours-day" *ngFor="let license of accountService.organisation.licence">
        <mat-icon>{{ accountService.licenseIcon[license] }}</mat-icon>
        <div class="brevier u-flush--bottom">{{ license | formatLicense }}</div>
      </div>
    </div>
  </div>
</div>
