<div class="c-api-card">
  <div class="c-api-card__title long-primer kirk">
    {{ oAuthClient.name }}
    <button class="o-button is-small" (click)="addEditOAuthClient.emit()">
      <mat-icon class="is-icon-left">edit</mat-icon>
      Edit
    </button>
  </div>
  <div class="c-api-card__actions">
    <div>
      <h2 class="is-action-name">Client ID</h2>
      <h3 class="is-action-value">
        {{ oAuthClient.clientId }}

        <mat-icon
          (click)="copyLink(tooltipClient)"
          [cdkCopyToClipboard]="oAuthClient.clientId"
          class="c-input-icon c-icon-content-copy"
          #tooltipClient="matTooltip"
          [matTooltip]="tooltipMessage"
        >
          content_copy
        </mat-icon>
      </h3>
    </div>
    <div>
      <h2 class="is-action-name">Client Secret</h2>
      <h3 class="is-action-value">
        {{
          isClientSecretAsPassword
            ? '•'.repeat(oAuthClient.clientSecret.length > 40 ? 40 : oAuthClient.clientSecret.length)
            : oAuthClient.clientSecret.length > 30
              ? (oAuthClient.clientSecret | slice: 0 : 30) + '...'
              : oAuthClient.clientSecret
        }}
        <mat-icon (click)="togglePassword()"> remove_red_eyes</mat-icon>
        <mat-icon
          (click)="copyLink(tooltipClient)"
          [cdkCopyToClipboard]="oAuthClient.clientSecret"
          #tooltipClient="matTooltip"
          [matTooltip]="tooltipMessage"
        >
          content_copy
        </mat-icon>
      </h3>
    </div>
  </div>
</div>
