<div
  [id]="camera.id"
  (click)="cardClick.emit()"
  [ngClass]="{
    'is-online': camera.status === 'online',
    'is-offline': camera.status === 'lagging' || camera.status === 'missing' || camera.status === 'offline',
    'is-paused': camera.status === 'paused' || camera.status === 'decommissioned',
  }"
  class="c-panel"
>
  <div class="c-panel__progress-outer">
    <div class="c-panel__progress-inner" [ngStyle]="{ 'width.%': camera.urgency ? camera.urgency : 100 }">
      <div
        class="c-tooltip-progress"
        *ngIf="camera.urgency"
        [ngStyle]="{ 'right.px': computeTooltipPosition(camera.id) }"
        [ngClass]="{ top: cardClick.observed, bottom: !cardClick.observed }"
      >
        Urgency level: {{ camera.urgency }}%
      </div>
    </div>
  </div>
  <div class="c-camera-card" [ngClass]="{ 'has-hover': cardClick.observed }">
    <div class="c-camera-card__main">
      <mat-icon class="is-icon" style="margin-top: 4px">
        <ng-container [ngSwitch]="camera.status">
          <ng-container *ngSwitchCase="'online'"> photo_camera </ng-container>
          <ng-container *ngSwitchCase="'decommissioned'"> delete_forever </ng-container>
          <ng-container *ngSwitchCase="['lagging', 'missing', 'offline'] | switchMultiCase: camera.status">
            no_photography
          </ng-container>
          <ng-container *ngSwitchCase="'paused'"> pause_circle_filled </ng-container>
        </ng-container>
      </mat-icon>
      <div class="is-main">
        <div class="c-camera-card__primary-text">
          <span class="is-camera-name">
            <app-copy-link
              [text]="camera.name"
              [fullRoute]="'/cameras/details/' + camera.id"
              [tooltipMessage]="'Copy link to camera'"
              [disabled]="!isSupport"
              class="is-compact"
            ></app-copy-link>
          </span>
          <div *ngIf="camera.status !== 'online'" class="is-status o-text-badge">
            {{ camera.status }}
          </div>
          <ng-container *ngIf="camera.status !== 'decommissioned'">
            <div class="is-last-seen">LAST SEEN: {{ camera.lastSeenAt ? (camera.lastSeenAt | dateAgo) : '-' }}</div>
            <div class="is-health" *ngIf="camera.status !== 'paused'">
              <ng-container *ngTemplateOutlet="videoLossTemplate"></ng-container>
            </div>
          </ng-container>
        </div>
        <div class="c-camera-card__secondary-text">
          <div class="c-secondary-text__item" [ngClass]="{ 'is-selected': keyToSort === 'serialNumber' }">
            <span class="is-label">
              <app-copy-text
                [displayText]="camera.serialNumber ? 'S/N: ' + camera.serialNumber : 'S/N: -'"
                [copyText]="camera.serialNumber"
                [tooltipMessage]="'Copy serial number'"
                [disabled]="!isSupport && !!camera.serialNumber"
                class="is-compact"
              ></app-copy-text>
            </span>
          </div>
          <div class="c-secondary-text__item" [ngClass]="{ 'is-selected': keyToSort === 'siteName' }">
            <span class="is-label">
              {{ camera.siteName ? camera.siteName : '-' }}
            </span>
          </div>
          <ng-container *ngIf="isSupport">
            <div class="c-secondary-text__item" [ngClass]="{ 'is-selected': keyToSort === 'organisationId' }">
              <span class="is-label">
                {{ organisationsMap[camera.organisationId] ? organisationsMap[camera.organisationId].name : '-' }}
              </span>
            </div>
          </ng-container>
          <div
            class="c-secondary-text__item"
            [ngClass]="{ 'is-selected': keyToSort === 'videoBacklog' }"
            *ngIf="camera.status !== 'decommissioned'"
          >
            <span class="o-text-badge">
              Video backlog:
              <span class="is-time type--monospace">{{
                camera.videoBacklog !== undefined ? (camera.videoBacklog | timeToString) : 'N/A'
              }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="c-camera-card__uuid type--monospace" *ngIf="isSupport">
      <app-copy-text
        [displayText]="'UUID: ' + camera.id"
        [copyText]="camera.id"
        [tooltipMessage]="'Copy UUID'"
        [disabled]="!isSupport"
        class="is-compact"
      ></app-copy-text>
    </div>
    <div class="c-camera-card__health" *ngIf="camera.status !== 'paused'">
      <ng-container *ngTemplateOutlet="videoLossTemplate"></ng-container>
    </div>
  </div>
</div>

<ng-template #videoLossLoadingTemplate>
  <div class="c-dots-wrapper">
    <div class="dot-flashing"></div>
  </div>
</ng-template>

<ng-template #videoLossTemplate>
  <ng-container *ngIf="isVideoLossReady; else videoLossLoadingTemplate">
    <mat-icon class="is-icon">date_range</mat-icon>
    <div class="is-health-label">
      7 day health:&nbsp;<a class="is-link">{{ videoLoss === undefined ? '-' : videoLoss > 5 ? 'POOR' : 'GOOD' }}</a>
    </div>
    <div class="is-video-loss">
      Video loss&nbsp;<a
        >{{ videoLoss === undefined ? '-' : videoLoss !== 0 && videoLoss !== 100 ? '>' : '' }}{{ videoLoss }}
        {{ videoLoss === undefined ? ' ' : '%' }}</a
      >
    </div>
  </ng-container>
</ng-template>
