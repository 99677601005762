import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { NotificationGroup, Organisation } from 'src/app/api';
import { AddEditNotificationGroupComponent } from 'src/app/components/notifications/add-edit-notification-group/add-edit-notification-group.component';
import { AccountService } from 'src/app/services/account.service';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-notification-groups',
  templateUrl: './notification-groups.component.html',
  styleUrls: ['./notification-groups.component.scss'],
})
export class NotificationGroupsComponent implements OnInit, OnDestroy {
  notificationGroups: NotificationGroup[] = [];
  filteredNotificationGroups: NotificationGroup[] = [];
  organisations: Organisation[] = [];
  selectedOrganisations: Organisation[] = [];
  organisationsMap: { [_: string]: Organisation } = {};
  isLoading = false;

  private ngUnsubscribe = new Subject();

  constructor(
    public accountService: AccountService,
    public notificationsService: NotificationsService,
    private matDialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.organisationsMap = this.accountService.organisationsMap;
    this.notificationGroups = this.notificationsService.notificationGroups;
    this.applyFilter();
  }

  applyFilter(organisations?: Organisation[]): void {
    this.selectedOrganisations =
      organisations && organisations.length !== 0
        ? organisations
        : this.accountService.organisation
          ? [this.accountService.organisation]
          : this.accountService.organisations;
    this.filterByOrganisations();
  }

  filterByOrganisations(): void {
    let selectedOrganisationIds = this.selectedOrganisations.map((o) => o.id);
    this.filteredNotificationGroups = this.notificationGroups.filter((notificationGroup: NotificationGroup) =>
      selectedOrganisationIds.includes(notificationGroup.organisationId),
    );
  }

  createNotificationGroup(): void {
    const dialogRef = this.matDialog.open(AddEditNotificationGroupComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          window.location.reload();
        }
      },
    });
  }

  showNotificationGroup(notificationGroup: NotificationGroup): void {
    this.router.navigate([`${notificationGroup.id}`], { relativeTo: this.route });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
