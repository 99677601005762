import { Component, Inject, OnDestroy, OnInit, ViewChild, computed, signal } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Site, SiteService } from 'src/app/api';
import { NotifyService } from 'src/app/services/notify.service';
// @ts-ignore
import moment from 'moment-timezone';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { AccountService } from 'src/app/services/account.service';
import { MatStepper } from '@angular/material/stepper';
import { SelectionModel } from '@angular/cdk/collections';
import { GlobalMethods } from 'src/app/global-methods';

@Component({
  selector: 'app-add-edit-site',
  templateUrl: './add-edit-site.component.html',
  styleUrls: ['./add-edit-site.component.scss'],
})
export class AddEditSiteComponent implements OnDestroy, OnInit {
  setupProgress = 0;
  site: Site;
  siteForm: FormGroup;
  openingHours: FormGroup;
  saving = false;
  timezones = moment.tz.names();

  update = signal(false);
  daysOfWeek: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  validOpeningHours = false;
  skipOpeningHours = false;
  canSkip = false;

  stepsTitle = computed(() => (this.update() ? 'Edit site' : 'Add site'));

  isClosedAllDay = new SelectionModel<string>(true, []);
  isOpenAllDay = new SelectionModel<string>(true, []);
  private ngUnsubscribe = new Subject();

  @ViewChild('stepper') stepper: MatStepper;

  constructor(
    public dialogRef: MatDialogRef<AddEditSiteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { orgId: string; site: Site },
    public accountService: AccountService,
    private formBuilder: FormBuilder,
    private notifyService: NotifyService,
    private siteService: SiteService,
  ) {}

  ngOnInit(): void {
    const organisationId = this.accountService.isSupport
      ? this.data.orgId
        ? this.data.orgId
        : ''
      : this.accountService.organisation.id;

    if (this.data && this.data.hasOwnProperty('site')) {
      this.update.set(true);
      this.site = this.data.site;
      this.siteForm = this.formBuilder.group({
        id: [this.data.site.id],
        name: [this.data.site.name, [Validators.required]],
        address: [this.data.site.address, [Validators.required]],
        tz: [this.data.site.tz, [Validators.required]],
        organisationId: [this.data.site.organisationId, [Validators.required]],
      });
      this.siteForm.get('organisationId')?.valueChanges.subscribe((orgId) => {
        this.canSkip = this.canSkipOpeningHour(orgId);
      });
    } else {
      this.siteForm = this.formBuilder.group({
        name: ['', [Validators.required]],
        address: ['', [Validators.required]],
        tz: [moment.tz.guess(), [Validators.required]],
        organisationId: [organisationId, [Validators.required]],
      });
      this.siteForm.get('organisationId')?.valueChanges.subscribe((orgId) => {
        this.canSkip = this.canSkipOpeningHour(orgId);
      });
    }
    if (this.data && this.data.hasOwnProperty('site') && this.data.site.openingHour) {
      const openingHoursControls: { [key: string]: FormGroup } = {};
      this.daysOfWeek.forEach((day, index) => {
        if (this.data.site.openingHour['weekly'][index][0] === -1) {
          this.isOpenAllDay.select(day);
          openingHoursControls[day] = this.formBuilder.group({
            open: '',
            close: '',
          });
        } else if (this.data.site.openingHour['weekly'][index][0] === this.data.site.openingHour['weekly'][index][1]) {
          this.isClosedAllDay.select(day);
          openingHoursControls[day] = this.formBuilder.group({
            open: '',
            close: '',
          });
        } else {
          openingHoursControls[day] = this.formBuilder.group({
            open: GlobalMethods.minutesToTimeString(this.data.site.openingHour['weekly'][index][0]),
            close: GlobalMethods.minutesToTimeString(this.data.site.openingHour['weekly'][index][1]),
          });
        }
      });
      this.openingHours = this.formBuilder.group(openingHoursControls);
      this.validateOpeningHours();
    } else {
      const openingHoursControls: { [key: string]: FormGroup } = {};
      this.daysOfWeek.forEach((day) => {
        openingHoursControls[day] = this.formBuilder.group({
          open: '',
          close: '',
        });
      });
      this.openingHours = this.formBuilder.group(openingHoursControls);
    }

    this.openingHours.valueChanges.subscribe((_) => {
      this.validateOpeningHours();
    });

    this.isOpenAllDay.changed.subscribe((_) => {
      this.validateOpeningHours();
    });

    this.isClosedAllDay.changed.subscribe((_) => {
      this.validateOpeningHours();
    });
  }

  canSkipOpeningHour(orgID: string): boolean {
    const processingGrp = this.accountService.organisationsMap[orgID].processingGroup;
    return processingGrp !== 2;
  }

  clearOpeningHours(): void {
    this.daysOfWeek.forEach((day: string): void => {
      this.isOpenAllDay.deselect(day);
      this.isClosedAllDay.deselect(day);
      this.openingHours.controls[day].reset();
    });
  }

  copyToAllDays(): void {
    if (this.isClosedAllDay.isSelected('Monday')) {
      this.daysOfWeek.forEach((day) => {
        this.isClosedAllDay.select(day);
        this.isOpenAllDay.deselect(day);
        this.openingHours.controls[day].reset();
      });
    } else if (this.isOpenAllDay.isSelected('Monday')) {
      this.daysOfWeek.forEach((day) => {
        this.isOpenAllDay.select(day);
        this.isClosedAllDay.deselect(day);
        this.openingHours.controls[day].reset();
      });
    } else {
      const mondayControls = this.openingHours.controls['Monday'];
      this.daysOfWeek.forEach((day) => {
        this.isOpenAllDay.deselect(day);
        this.isClosedAllDay.deselect(day);
        this.openingHours.controls[day].setValue(mondayControls.value);
      });
    }
  }

  getOpeningHours(): {} {
    if (
      Object.values(this.openingHours.value).every(
        (x: any) => x.open === '' || x.open === null || x.close === '' || x.close === null,
      ) &&
      this.isClosedAllDay.selected.length === 0 &&
      this.isOpenAllDay.selected.length === 0
    ) {
      return {};
    }
    var openingHour = { weekly: {} };

    this.daysOfWeek.forEach((day, index) => {
      if (this.isClosedAllDay.isSelected(day)) {
        openingHour.weekly[index] = [0, 0];
      } else if (this.isOpenAllDay.isSelected(day)) {
        openingHour.weekly[index] = [-1, -1];
      } else {
        openingHour.weekly[index] = [
          GlobalMethods.timeStringToMinutes(this.openingHours.controls[day].value.open),
          GlobalMethods.timeStringToMinutes(this.openingHours.controls[day].value.close),
        ];
      }
    });

    return { openingHour };
  }

  save(): void {
    this.saving = true;

    this.siteService
      .putSite({
        ...this.site,
        ...this.getOpeningHours(),
      })
      .pipe(
        finalize(() => {
          this.saving = false;
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (_) => {
          this.close(true);
        },
        error: (error) => {
          this.notifyService.error(error);
        },
      });
  }

  getUserTimeZone(): void {
    this.siteForm.patchValue({ tz: moment.tz.guess() });
  }

  close(reload?: boolean): void {
    if (reload) {
      this.dialogRef.close('reload');
    } else {
      this.dialogRef.close();
    }
  }

  validateOpeningHours(): void {
    let isValid = true;
    this.daysOfWeek.forEach((day) => {
      if (
        !this.isOpenAllDay.isSelected(day) &&
        !this.isClosedAllDay.isSelected(day) &&
        (!this.openingHours.controls[day].value.open || !this.openingHours.controls[day].value.close)
      ) {
        isValid = false;
      }
    });
    this.validOpeningHours = isValid;
  }

  validateFirstStep(setupProgress: number): void {
    this.setupProgress = setupProgress;
    this.site = this.siteForm.value;
    this.stepper.next();
  }

  validateSecondStep(setupProgress: number): void {
    this.setupProgress = setupProgress;
    this.skipOpeningHours = false;
    this.stepper.next();
  }

  goToPreviousStep(setupProgress: number): void {
    this.setupProgress = setupProgress;
    this.stepper.previous();
  }

  goToNextStep(setupProgress: number): void {
    this.setupProgress = setupProgress;
    this.stepper.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
