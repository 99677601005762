import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-add-first-site',
  templateUrl: './add-first-site.component.html',
  styleUrls: ['./add-first-site.component.scss'],
})
export class AddFirstSiteComponent {
  collapseAddSiteSection = false;

  @Output() addSite: EventEmitter<void> = new EventEmitter();
  constructor() {}
}
