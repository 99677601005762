import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minMax',
})
export class MinMaxPipe implements PipeTransform {
  transform(value: any[]) {
    if (!Array.isArray(value) || value.length === 0) {
      return value;
    }

    value.sort((a, b) => b - a);

    const min = value[value.length - 1];
    const max = value[0];

    return [min, max];
  }
}
