import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-add-first-monitor',
  templateUrl: './add-first-monitor.component.html',
  styleUrls: ['./add-first-monitor.component.scss'],
})
export class AddFirstMonitorComponent {
  collapseMonitorsSection = false;

  @Input() canAddMonitor = true;
  @Output() addPeopleCountMonitor: EventEmitter<void> = new EventEmitter();
  @Output() addOccupancyMonitor: EventEmitter<void> = new EventEmitter();
  @Output() addPeelOffMonitor: EventEmitter<void> = new EventEmitter();
  constructor(public accountService: AccountService) {}
}
