import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Camera } from 'src/app/api';

@Component({
  selector: 'app-select-cameras-step',
  templateUrl: './select-cameras-step.component.html',
  styleUrls: ['./select-cameras-step.component.scss'],
})
export class SelectCamerasStepComponent {
  @Input() cameras: Camera[] = [];
  monitorForm: FormGroup;
  selectedCameras = [];

  constructor(private formBuilder: FormBuilder) {
    this.monitorForm = this.formBuilder.group({
      cameras: [[], [Validators.required]],
    });
  }

  onChange(camera: Camera) {
    const alreadyChecked = this.selectedCameras.find((cam) => cam.id === camera.id);
    if (alreadyChecked) {
      this.selectedCameras = this.selectedCameras.filter((cam) => cam.id !== camera.id);
    } else {
      this.selectedCameras.push(camera);
    }
    this.monitorForm.controls.cameras.patchValue(this.selectedCameras);
  }
}
