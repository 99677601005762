<div
  class="o-monitor-card"
  [ngClass]="{
    'is-online': monitor.status === 'online',
    'is-offline': monitor.status === 'issue',
    'is-disabled': activeCameras === 0 || isIncomplete,
    'c-is-disabled': isDisabled,
  }"
>
  <div class="c-disabled-overlay" *ngIf="isDisabled"></div>

  <div class="o-monitor-card__title">
    <mat-icon *ngIf="monitorType === monitorNames.occupancy" class="is-icon c-monitor-card__monitor-icon">
      groups
    </mat-icon>
    <mat-icon *ngIf="monitorType === monitorNames.peopleCount" class="is-icon c-monitor-card__monitor-icon"
      >transfer_within_a_station</mat-icon
    >
    <mat-icon *ngIf="monitorType === monitorNames.peelOff" class="is-icon c-monitor-card__monitor-icon"
      >fork_left</mat-icon
    >

    <div class="o-monitor-card__title-text has-3-lines">
      <span>{{ monitor.siteName | uppercase }}</span>
      <h2>{{ monitor?.name }}</h2>
      <h3 *ngIf="cameras">{{ cameras.length }} {{ cameras.length | pluraliseWord: 'camera' }}</h3>
    </div>
    <a class="o-monitor-card__title-actions">
      <mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="editMonitor.emit()">
          <mat-icon>edit</mat-icon>
          Edit monitor
        </button>
        <button mat-menu-item (click)="deleteMonitor.emit()" *ngIf="accountService.isSupport">
          <mat-icon>delete</mat-icon>
          Delete monitor
        </button>
      </mat-menu>
    </a>
  </div>
  <div class="o-monitor-card__stats" *ngIf="hasCapacity()">
    <div class="o-monitor-card__stats-list">
      <div class="o-monitor-card__stats-item">
        <span class="minion--caps u-flush--bottom">Capacity</span>
        <span class="is-number">{{ monitor.capacity ? monitor.capacity : 'N/A' }}</span>
      </div>
    </div>
  </div>

  <div class="o-monitor-card__body">
    <div class="o-monitor-card__banner-small has-error" *ngIf="issues">
      <span class="is-primary-text">{{ issues }} camera {{ issues | pluraliseWord: 'issue' }}!</span>
      <span class="is-secondary-text">
        {{ getSecondaryText() }}
      </span>
    </div>
    <div class="o-monitor-card__banner-small has-warning" *ngIf="activeCameras === 0 && !isIncomplete">
      <span class="is-primary-text">Inactive monitor!</span>
      <span class="is-secondary-text">
        No
        <b *ngIf="cameras.length !== 0">running</b>
        cameras are linked to this monitor.
      </span>
    </div>

    <div class="o-monitor-card__banner-small has-warning" *ngIf="monitorType === 'peel-off' && isIncomplete && !issues">
      <span class="is-primary-text">Inactive monitor!</span>
      <span class="is-secondary-text">
        You need to add a camera for both <b>passing</b> count and <b>entrance</b> count.
      </span>
    </div>

    <ul class="o-list" *ngIf="!issues && activeCameras !== 0 && !isIncomplete">
      <li class="o-list__item" [ngClass]="{ 'has-error': urgentIssues }">
        <div class="o-list__item-icon">
          <mat-icon [ngClass]="{ 'is-online': monitor.status === 'online', 'is-offline': monitor.status === 'issue' }"
            >photo_camera</mat-icon
          >
        </div>
        <div class="o-list__item-text">
          <ng-container *ngIf="monitor.status === 'issue'; else noIssue">
            <ng-container *ngIf="urgentIssues"
              >{{ urgentIssues }} urgent camera {{ urgentIssues | pluraliseWord: 'issue' }}</ng-container
            >
            <ng-container *ngIf="!urgentIssues">
              {{ issues }} camera {{ issues | pluraliseWord: 'issue' }}</ng-container
            >
          </ng-container>
          <ng-template #noIssue> No camera issues </ng-template>
        </div>
      </li>
    </ul>
  </div>

  <div class="o-monitor-card__action" (click)="showMonitorDetails.emit()">
    <a class="o-button">
      View More
      <mat-icon class="is-icon-right">arrow_forward</mat-icon>
    </a>
  </div>
</div>
