<mat-horizontal-stepper class="dialog-stepper" #stepper>
  <mat-step *ngIf="showSelectSite">
    <app-step
      [stepTitle]="stepsTitle"
      [isBackable]="false"
      [processingStep]="isLoading"
      [disabledNextStep]="!selectASiteComponent.choosenSite"
      (openNextStep)="selectASite()"
      (closeStep)="close()"
    >
      <div class="c-modal-content">
        <app-monitor-setup-side-panel [monitorType]="'occupancy'" [activeStep]="0"></app-monitor-setup-side-panel>
        <app-select-a-site #selectASiteComponent class="o-step-body" [sites]="sites"> </app-select-a-site>
      </div>
    </app-step>
  </mat-step>

  <mat-step>
    <app-step
      [stepTitle]="stepsTitle"
      [isBackable]="true"
      [disabledNextStep]="addOccupancyMonitorDetailsComponent.monitorForm?.invalid"
      (closeStep)="close()"
    >
      <div class="c-modal-content">
        <app-monitor-setup-side-panel [monitorType]="'occupancy'" [activeStep]="1"></app-monitor-setup-side-panel>
        <app-add-occupancy-monitor-details #addOccupancyMonitorDetailsComponent class="o-step-body">
        </app-add-occupancy-monitor-details>
      </div>
    </app-step>
  </mat-step>

  <mat-step #selectCamerasStepComponentStep>
    <app-step [stepTitle]="stepsTitle" (closeStep)="close()" (openNextStep)="checkSelectedCameras()">
      <div class="c-modal-content">
        <app-monitor-setup-side-panel [monitorType]="'occupancy'" [activeStep]="2"></app-monitor-setup-side-panel>
        <app-select-cameras-step #selectCamerasStepComponent class="o-step-body" [cameras]="filteredCameras">
        </app-select-cameras-step>
      </div>
    </app-step>
  </mat-step>

  <mat-step #selectCameraDirectionStep>
    <app-step
      [stepTitle]="stepsTitle"
      (closeStep)="close()"
      (openNextStep)="goToConfirmationStep()"
      *ngIf="stepper.selected === selectCameraDirectionStep"
    >
      <div class="c-modal-content">
        <app-monitor-setup-side-panel [monitorType]="'occupancy'" [activeStep]="3"></app-monitor-setup-side-panel>
        <app-select-camera-direction-step
          #selectCameraDirectionStepComponent
          [direction1]="'in'"
          [direction2]="'out'"
          [cameras]="selectCamerasStepComponent?.monitorForm.get('cameras').value"
          class="o-step-body"
        >
        </app-select-camera-direction-step>
      </div>
    </app-step>
  </mat-step>

  <mat-step #occupancyMonitorConfirmationStep>
    <ng-template matStepContent>
      <app-step
        [nextStepText]="'FINISH'"
        [stepTitle]="stepsTitle"
        [processingStep]="isLoading"
        (closeStep)="close()"
        (openNextStep)="createMonitor()"
        (openPreviousStep)="createMonitorPreviousStep()"
      >
        <div class="c-modal-content">
          <app-monitor-setup-side-panel [monitorType]="'occupancy'" [activeStep]="4"></app-monitor-setup-side-panel>
          <app-occupancy-monitor-confirmation
            [monitorDetails]="addOccupancyMonitorDetailsComponent?.monitorForm.value"
            [cameras]="selectedCamerasSetting"
            [cameraFrame]="cameraFrame"
            [direction1]="'in'"
            [direction2]="'out'"
            class="o-step-body"
          >
          </app-occupancy-monitor-confirmation>
        </div>
      </app-step>
    </ng-template>
  </mat-step>
</mat-horizontal-stepper>
