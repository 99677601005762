<div class="auth-overlay" *ngIf="isLoading; else notLoading">
  <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
    <p style="font-size: 2rem; font-weight: 500">Loading cameras...</p>
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>

<ng-template #notLoading>
  <nav class="navigation is-breadcrumb">
    <ul class="o-breadcrumb">
      <li class="o-breadcrumb__item">
        <a routerLink="/infrastructure" class="is-link"> <mat-icon class="is-icon">dashboard</mat-icon></a>
      </li>
      <li class="o-breadcrumb__item">Support</li>
      <li class="o-breadcrumb__item">Engagement</li>
    </ul>
  </nav>
  <div class="c-customer-engagement">
    <div class="c-customer-engagement-title">Customer engagement.</div>
    <div class="c-customer-engagement-content">
      <div class="c-filters">
        <app-filter placeholder="Search organisations" (search)="runSearch($event)" class="c-search-input"></app-filter>

        <mat-form-field class="c-date-input-wrapper">
          <mat-date-range-input [formGroup]="organisationDate" [rangePicker]="organisationDatePicker">
            <input matStartDate placeholder="Start date" formControlName="start" />
            <input matEndDate placeholder="End date" formControlName="end" />
          </mat-date-range-input>
          <mat-datepicker-toggle matIconSuffix [for]="organisationDatePicker"></mat-datepicker-toggle>
          <mat-date-range-picker #organisationDatePicker></mat-date-range-picker>
        </mat-form-field>
      </div>
      <div class="c-button-hide-selected-wrapper">
        <button
          (click)="onHideSelectedButtonClick()"
          class="o-button is-contained is-small c-button-hide-selected"
          [class.is-muted]="hideSelectedListItems"
        >
          <span class="material-icons is-icon-left"> visibility_off </span>
          Hide selected
        </button>

        <button
          *ngIf="hideSelectedListItems"
          (click)="revealHiddenOrganisation()"
          class="o-button c-button-reveal-organisations"
        >
          Reveal {{ hiddenListItemsQuantity }} hidden organisations
        </button>
      </div>

      <div class="c-table">
        <div class="c-table-header">
          <div class="c-table-cell c-table-cell-xs"><!-- empty cell --></div>
          <div class="c-table-cell-lg">
            <span
              (click)="onTableHeaderItemClick('organisation')"
              [class.c-selected-table-header-item]="orderByParam === 'organisation'"
              >Organisation</span
            >
          </div>
          <div class="c-table-cell-sm">
            <span
              (click)="onTableHeaderItemClick('sites')"
              [class.c-selected-table-header-item]="orderByParam === 'sites'"
              >Sites</span
            >
          </div>
          <div class="c-table-cell-sm">
            <span
              (click)="onTableHeaderItemClick('type')"
              [class.c-selected-table-header-item]="orderByParam === 'type'"
              >Type</span
            >
          </div>
          <div class="c-table-cell-sm">
            <span
              (click)="onTableHeaderItemClick('users')"
              [class.c-selected-table-header-item]="orderByParam === 'users'"
              >Users</span
            >
          </div>
          <div class="c-table-cell-sm">
            <span
              (click)="onTableHeaderItemClick('lastLogin')"
              [class.c-selected-table-header-item]="orderByParam === 'lastLogin'"
            >
              Last login
            </span>
          </div>
          <div class="c-table-cell-sm">
            <span
              (click)="onTableHeaderItemClick('totalLogin')"
              [class.c-selected-table-header-item]="orderByParam === 'totalLogin'"
            >
              Total logins
            </span>
          </div>
          <div class="c-table-cell-sm">
            <span
              (click)="onTableHeaderItemClick('apiHealth')"
              [class.c-selected-table-header-item]="orderByParam === 'apiHealth'"
            >
              API health
            </span>
          </div>
          <div class="c-table-cell-sm">
            <span
              (click)="onTableHeaderItemClick('shopify')"
              [class.c-selected-table-header-item]="orderByParam === 'shopify'"
              >Shopify</span
            >
          </div>
          <div class="c-table-cell-md">
            <span
              (click)="onTableHeaderItemClick('cameraHealth')"
              [class.c-selected-table-header-item]="orderByParam === 'cameraHealth'"
            >
              Camera health
            </span>
          </div>
        </div>
        <ul class="c-table-items">
          <ng-container
            *ngFor="let customerEngagement of filteredCustomerEngagements | orderBy: 'data.' + orderByParam"
          >
            <li *ngIf="!customerEngagement.hidden" class="c-table-item" [class.c-table-item-higlighted-red]="true">
              <div class="c-table-cell c-table-cell-xs">
                <mat-checkbox
                  [checked]="customerEngagement.checked"
                  (click)="onCustomerEngagementCheckboxClick(customerEngagement)"
                ></mat-checkbox>
              </div>
              <div class="c-table-cell c-table-cell-lg">
                {{ customerEngagement.data.organisation }}
              </div>
              <div class="c-table-cell c-table-cell-sm">{{ customerEngagement.data.sites }}</div>
              <div class="c-table-cell c-table-cell-sm">{{ customerEngagement.data.type }}</div>
              <div class="c-table-cell c-table-cell-sm">{{ customerEngagement.data.users }}</div>
              <div class="c-table-cell c-table-cell-sm">{{ customerEngagement.data.lastLogin }}</div>
              <div class="c-table-cell c-table-cell-sm">{{ customerEngagement.data.totalLogins }}</div>
              <div class="c-table-cell c-table-cell-sm">{{ customerEngagement.data.apiHealth }}</div>
              <div class="c-table-cell c-table-cell-sm">{{ customerEngagement.data.shopify }}</div>
              <div
                [innerHTML]="customerEngagement.data.cameraHealth"
                [class.c-camera-health-bad]="customerEngagement.data.cameraHealth !== 'Good'"
                class="c-table-cell c-table-cell-md"
              ></div>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</ng-template>
