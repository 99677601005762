<mat-form-field class="no-label c-filter" appearance="outline" fxFlex="0 100">
  <mat-icon matPrefix>filter_list</mat-icon>
  <input
    #search
    matInput
    type="text"
    placeholder="{{ placeholder }}"
    autocomplete="off"
    [(ngModel)]="searchString"
    (keyup)="emitSearch()"
    (focus)="isInputFocused = true"
    (focusout)="isInputFocused = false"
    name="searchString"
  />
  <button mat-button matSuffix (click)="clearSearch()" *ngIf="searchString">
    <mat-icon *ngIf="searchString">close</mat-icon>
  </button>
</mat-form-field>
