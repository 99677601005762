import { Component, Input, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { User } from '@auth0/auth0-angular';
import { Site } from 'src/app/api';
import { AddEditUserComponent } from 'src/app/components/users/add-edit-user/add-edit-user.component';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-users-tab',
  templateUrl: './users-tab.component.html',
  styleUrl: './users-tab.component.scss',
})
export class UsersTabComponent implements OnChanges {
  @Input() users: User[] = [];
  @Input() adminUsers: User[] = [];
  @Input() memberUsers: User[] = [];
  @Input() sites: Site[] = [];

  filteredUsers: User[] = [];
  mustContainString = '';
  showAdmin = true;
  showMember = true;

  constructor(
    public accountService: AccountService,
    private matDialog: MatDialog,
  ) {}

  ngOnChanges(): void {
    if (this.users) {
      this.filterUsers();
    }
  }

  formatUserRoles(user): string {
    var userRoles = '-';

    if (user.roles.includes('admin')) {
      userRoles = 'ADMIN';

      if (user.roles.includes('member')) {
        userRoles += ', MEMBER';
      }
    } else {
      if (user.roles.includes('member')) {
        userRoles = 'MEMBER';
      }
    }

    return userRoles;
  }

  runSearch(ss: string) {
    this.mustContainString = ss.toLowerCase();
    this.filterUsers();
  }

  filterUsers(): void {
    this.filteredUsers = this.users;

    let rolesFilter = [];
    if (this.showAdmin) {
      rolesFilter.push('admin');
    }
    if (this.showMember) {
      rolesFilter.push('member');
    }
    if ((this.showMember || this.showAdmin) && !(this.showMember && this.showAdmin)) {
      this.filteredUsers = this.filteredUsers.filter(
        (user) => user.roles.filter((role) => rolesFilter.includes(role)).length !== 0,
      );
    }

    if (this.mustContainString) {
      this.filteredUsers = this.filteredUsers.filter(
        (user) =>
          user.name.toLowerCase().includes(this.mustContainString) ||
          user.id.toLowerCase().includes(this.mustContainString) ||
          user.email.toLowerCase().includes(this.mustContainString),
      );
    }

    this.filteredUsers = this.filteredUsers.sort((u1: User, u2: User) => {
      if (u1.name.toLowerCase() > u2.name.toLowerCase()) {
        return 1;
      } else {
        return -1;
      }
    });
  }

  openAddEditUserDialog(user?: User): void {
    const data = {
      sites: this.sites,
      users: this.users,
      organisationsMap: this.accountService.organisationsMap,
      adminUsers: this.adminUsers.length,
      adminUsersSeatsAvailable: this.accountService.organisation.maxAdminSeats,
      memberUsers: this.memberUsers.length,
      memberUsersSeatsAvailable: this.accountService.organisation.maxUserSeats,
    };
    if (user) {
      data['user'] = user;
    }

    const dialogRef = this.matDialog.open(AddEditUserComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data,
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          window.location.reload();
        }
      },
    });
  }
}
