import { Camera } from 'src/app/api';

export interface CameraStatus extends Camera {
  urgency?: number;
  status?: CameraStatus.StatusEnum;
  siteName?: string;
  type?: 'Unknown' | 'Passing traffic' | 'Overhead';
}

export namespace CameraStatus {
  export type StatusEnum = 'offline' | 'missing' | 'lagging' | 'online' | 'paused' | 'decommissioned';
  export const StatusEnum = {
    Offline: 'offline' as StatusEnum,
    Missing: 'missing' as StatusEnum,
    Lagging: 'lagging' as StatusEnum,
    Online: 'online' as StatusEnum,
    Paused: 'paused' as StatusEnum,
    Decommissioned: 'decommissioned' as StatusEnum,
  };
}
