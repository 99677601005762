import { OccupancyMonitor, PeelOffMonitor } from 'src/app/api';
import { PeopleCountMonitor } from 'src/app/api';

export interface OccupancyMonitorStatus extends OccupancyMonitor {
  status?: MonitorStatus.StatusEnum;
  siteName?: string;
  organisationId?: string;
}

export interface PeopleCountMonitorStatus extends PeopleCountMonitor {
  status?: MonitorStatus.StatusEnum;
  siteName?: string;
  organisationId?: string;
}

export interface PeelOffMonitorStatus extends PeelOffMonitor {
  status?: MonitorStatus.StatusEnum;
  siteName?: string;
  organisationId?: string;
}

export namespace MonitorStatus {
  export type StatusEnum = 'online' | 'issue';
  export const StatusEnum = {
    Online: 'online' as StatusEnum,
    Issue: 'issue' as StatusEnum,
  };
}
