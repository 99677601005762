<div class="c-monitor-info">
  <div class="o-feature-card c-feature-card">
    <div class="o-feature-card__head">
      <mat-icon>domain</mat-icon>
      <div class="is-title is-reverse">
        <h2 *ngIf="organisationName" class="u-caps">{{ organisationName }}</h2>
        <h3>
          {{
            monitorsService.occupancyMonitors?.length +
              monitorsService.peopleCountMonitors.length +
              monitorsService.peelOffMonitors.length
          }}
          {{
            monitorsService.occupancyMonitors?.length +
              monitorsService.peopleCountMonitors.length +
              monitorsService.peelOffMonitors.length | pluraliseWord: 'monitor'
          }}
          {{
            monitorsService.site?.id
              ? 'at ' + monitorsService.site.name + ' site'
              : 'across ' + monitorsService.sites?.length + ' ' + (monitorsService.sites.length | pluraliseWord: 'site')
          }}
        </h3>
      </div>
    </div>
    <ul class="o-list is-compact is-light u-margin--bottom">
      <li class="o-list__item c-list__item">
        <div class="o-list__item-icon">
          <mat-icon>transfer_within_a_station</mat-icon>
        </div>
        <div class="o-list__item-text c-item-text">
          <div>
            People count monitors: <b>{{ monitorsService.peopleCountMonitors?.length }}</b>
          </div>

          <div class="c-badge c-badge--warning" *ngIf="peopleCountMonitorsWithIssues">
            {{ peopleCountMonitorsWithIssues }} {{ peopleCountMonitorsWithIssues | pluraliseWord: 'monitor' }} with
            camera issues
          </div>
        </div>
      </li>
      <li class="o-list__item c-list__item">
        <div class="o-list__item-icon">
          <mat-icon>fork_left</mat-icon>
        </div>
        <div class="o-list__item-text c-item-text">
          <div>
            Peel off monitors: <b>{{ monitorsService.peelOffMonitors?.length }}</b>
          </div>

          <div class="c-badge c-badge--warning" *ngIf="peelOffMonitorsWithIssues">
            {{ peelOffMonitorsWithIssues }} {{ peelOffMonitorsWithIssues | pluraliseWord: 'monitor' }} with camera
            issues
          </div>
        </div>
      </li>
      <li class="o-list__item c-list__item">
        <div class="o-list__item-icon">
          <mat-icon>groups</mat-icon>
        </div>
        <div class="o-list__item-text c-item-text">
          <div>
            Occupancy monitors: <b>{{ monitorsService.occupancyMonitors?.length }}</b>
          </div>

          <div class="c-badge c-badge--warning" *ngIf="occupancyMonitorsWithIssues">
            {{ occupancyMonitorsWithIssues }} {{ occupancyMonitorsWithIssues | pluraliseWord: 'monitor' }} with camera
            issues
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="c-monitors-with-issues">
    <div class="c-monitors-with-issues__title">Monitors affected by camera issues</div>

    <img *ngIf="!groupIssueMonitorsBySite().length" src="/assets/images/no-monitors-affected.png" alt="" />
    <ng-container *ngFor="let monitors of groupIssueMonitorsBySite()">
      <div class="c-monitors-with-issues__monitor-name minion--caps">{{ monitors[0].siteName }}</div>
      <ng-container *ngFor="let monitor of monitors">
        <a
          *ngIf="monitor.status === 'issue'"
          class="c-monitors-with-issues__item"
          (click)="goToMonitorPage(monitor)"
          (onauxclick)="goToMonitorPage(monitor)"
        >
          <mat-icon class="c-camera-icon">{{
            monitor.hasOwnProperty('capacity')
              ? 'groups'
              : monitor.hasOwnProperty('direction1Alias')
                ? 'transfer_within_a_station'
                : 'fork_left'
          }}</mat-icon>
          <div class="c-camera-name">
            <span class="c-camera-place-name">
              {{ monitor.name }}
            </span>
            <span class="minion--caps type--secondary u-flush--bottom">{{
              monitor.hasOwnProperty('capacity')
                ? 'Occupancy'
                : monitor.hasOwnProperty('direction1Alias')
                  ? 'People count'
                  : 'Peel off'
            }}</span>
          </div>
          <div>
            <div class="c-camera-badge" *ngIf="monitor.hasOwnProperty('capacity')">
              {{ getCamerasWithIssue(monitorsService.occupancyMonitorCameras[monitor.id]) }}
              camera
              {{ getCamerasWithIssue(monitorsService.occupancyMonitorCameras[monitor.id]) | pluraliseWord: 'issue' }}
            </div>
            <div
              class="c-camera-badge"
              *ngIf="!monitor.hasOwnProperty('capacity') && monitor.hasOwnProperty('direction1Alias')"
            >
              {{ getCamerasWithIssue(monitorsService.peopleCountMonitorCameras[monitor.id]) }}
              camera
              {{ getCamerasWithIssue(monitorsService.peopleCountMonitorCameras[monitor.id]) | pluraliseWord: 'issue' }}
            </div>
            <div
              class="c-camera-badge"
              *ngIf="!monitor.hasOwnProperty('capacity') && !monitor.hasOwnProperty('direction1Alias')"
            >
              {{ getCamerasWithIssue(monitorsService.peelOffMonitorCameras[monitor.id]) }}
              camera
              {{ getCamerasWithIssue(monitorsService.peelOffMonitorCameras[monitor.id]) | pluraliseWord: 'issue' }}
            </div>
          </div>
        </a>
      </ng-container>
    </ng-container>
  </div>
</div>

<mat-tab-group
  class="c-tab-groups"
  [selectedIndex]="monitorsService.selectedTabIndex"
  (selectedTabChange)="onTabChanged(monitorsService.monitorsTypeValue[$event.index])"
>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon style="margin-right: 4px">transfer_within_a_station</mat-icon>
      People count monitors ({{ monitorsService.peopleCountMonitors?.length }})
    </ng-template>
    <ng-container
      *ngIf="
        !accountService.isSupport || monitorsService.selectedTabIndex === monitorsService.monitorsType['people_count']
      "
    >
      <div class="c-controls" *ngIf="monitorsService.peopleCountMonitors.length">
        <div class="c-controls__subgroup">
          <div class="c-filter-wrapper">
            <app-filter
              [searchString]="monitorsService.mustContainPeopleCountString"
              (search)="filterPeopleCountMonitors($event)"
              class="c-search o-search__input"
              placeholder="Filter monitors"
            ></app-filter>
          </div>
          <div class="c-sort">
            <mat-form-field class="c-dropdown">
              <mat-select [(value)]="keyToSort" (selectionChange)="sortPeopleCountMonitors()">
                <mat-option [value]="'name'"> Sort by name </mat-option>
                <mat-option [value]="'siteName'"> Sort by site </mat-option>
                <mat-option [value]="'status'"> Sort by status </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <button (click)="openNewPeopleCountMonitorDialog()" class="o-button is-small is-primary">
          <mat-icon>add</mat-icon>
          ADD PEOPLE COUNT MONITOR
        </button>
      </div>
      <div class="c-monitors">
        <div class="c-monitors__header">
          <div class="o-heading--primer">
            <h2>People count monitors</h2>
            <h3>Count the number of people passing a point in either direction</h3>
          </div>
        </div>
        <div class="o-card-grid" *ngIf="monitorsService.peopleCountMonitors.length">
          <app-monitor-card
            *ngFor="let peopleCountMonitor of monitorsService.filteredPeopleCountMonitors"
            [monitor]="peopleCountMonitor"
            [cameras]="monitorsService.peopleCountMonitorCameras[peopleCountMonitor.id]"
            [monitorType]="'people-count'"
            [isDisabled]="disabledPeopleCountMonitorCard[peopleCountMonitor.id]"
            (showMonitorDetails)="goToMonitorPage(peopleCountMonitor)"
            (editMonitor)="goToMonitorPage(peopleCountMonitor, true)"
            (deleteMonitor)="deletePeopleCountMonitor(peopleCountMonitor)"
          ></app-monitor-card>
        </div>
        <app-add-first-monitors
          *ngIf="!monitorsService.peopleCountMonitors.length"
          [title]="'Create your first people count monitor.'"
          [buttonText]="'Create people count monitor'"
          [text]="noPeopleCountMonitorText"
          [imageSrc]="'/assets/images/people-count-no-monitors.png'"
          (buttonClick)="openNewPeopleCountMonitorDialog()"
        ></app-add-first-monitors>
      </div>
    </ng-container>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon>fork_left</mat-icon>
      Peel off monitors ({{ monitorsService.peelOffMonitors?.length }})
    </ng-template>
    <ng-container
      *ngIf="!accountService.isSupport || monitorsService.selectedTabIndex === monitorsService.monitorsType['peel_off']"
    >
      <div class="c-controls" *ngIf="monitorsService.peelOffMonitors.length">
        <div class="c-controls__subgroup">
          <div class="c-filter-wrapper">
            <app-filter
              [searchString]="monitorsService.mustContainPeelOffString"
              (search)="filterPeelOffMonitors($event)"
              class="c-search o-search__input"
              placeholder="Filter monitors"
            ></app-filter>
          </div>
          <div class="c-sort">
            <mat-form-field class="c-dropdown">
              <mat-select [(value)]="keyToSort" (selectionChange)="sortPeelOffMonitors()">
                <mat-option [value]="'name'"> Sort by name </mat-option>
                <mat-option [value]="'siteName'"> Sort by site </mat-option>
                <mat-option [value]="'status'"> Sort by status </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <button (click)="openNewPeelOffMonitorDialog()" class="o-button is-small is-primary">
          <mat-icon>add</mat-icon>
          ADD PEEL OFF MONITOR
        </button>
      </div>
      <div class="c-monitors">
        <div class="c-monitors__header">
          <div class="o-heading--primer">
            <h2>Peel off monitors</h2>
            <h3>Track the passing to entrance conversion rates for your sites.</h3>
          </div>
        </div>
        <div class="o-card-grid" *ngIf="monitorsService.peelOffMonitors.length">
          <app-monitor-card
            *ngFor="let peelOffMonitor of monitorsService.filteredPeelOffMonitors"
            [monitor]="peelOffMonitor"
            [cameras]="monitorsService.peelOffMonitorCameras[peelOffMonitor.id]"
            [monitorType]="'peel-off'"
            [isDisabled]="disabledPeelOffMonitorCard[peelOffMonitor.id]"
            [isIncomplete]="
              !(
                monitorsService.peelOffMonitorsWithBase.has(peelOffMonitor.id) &&
                monitorsService.peelOffMonitorsWithPeelOff.has(peelOffMonitor.id)
              )
            "
            (showMonitorDetails)="goToMonitorPage(peelOffMonitor)"
            (editMonitor)="goToMonitorPage(peelOffMonitor, true)"
            (deleteMonitor)="deletePeelOfftMonitor(peelOffMonitor)"
          ></app-monitor-card>
        </div>
        <app-add-first-monitors
          *ngIf="!monitorsService.peelOffMonitors.length"
          [title]="'Create your first peel off monitor.'"
          [buttonText]="'Create peel off monitor'"
          [text]="noPeelOffMonitorText"
          [imageSrc]="'/assets/images/peel-off-no-monitors.png'"
          (buttonClick)="openNewPeelOffMonitorDialog()"
        ></app-add-first-monitors>
      </div>
    </ng-container>
  </mat-tab>

  <mat-tab *ngIf="accountService.occupancyLicence">
    <ng-template mat-tab-label>
      <mat-icon style="margin-right: 4px">groups</mat-icon>
      Occupancy monitors ({{ monitorsService.occupancyMonitors?.length }})
    </ng-template>
    <ng-container
      *ngIf="
        !accountService.isSupport || monitorsService.selectedTabIndex === monitorsService.monitorsType['occupancy']
      "
    >
      <div class="c-controls" *ngIf="monitorsService.occupancyMonitors.length">
        <div class="c-controls__subgroup">
          <div class="c-filter-wrapper">
            <app-filter
              [searchString]="monitorsService.mustContainOccupancyString"
              (search)="filterOccupancyMonitors($event)"
              class="c-search o-search__input"
              placeholder="Filter monitors"
            ></app-filter>
          </div>
          <div class="c-sort">
            <mat-form-field class="c-dropdown">
              <mat-select [(value)]="keyToSort" (selectionChange)="sortOccupancyMonitors()">
                <mat-option [value]="'name'"> Sort by name </mat-option>
                <mat-option [value]="'siteName'"> Sort by site </mat-option>
                <mat-option [value]="'status'"> Sort by status </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <button (click)="openNewOccupancyMonitorDialog()" class="o-button is-small is-primary">
          <mat-icon>add</mat-icon>
          ADD OCCUPANCY MONITOR
        </button>
      </div>
      <div class="c-monitors">
        <div class="c-monitors__header">
          <div class="o-heading--primer">
            <h2>Occupancy monitors</h2>
            <h3>Count the number of people in a space and entering/exiting that space</h3>
          </div>
        </div>
        <div class="o-card-grid" *ngIf="monitorsService.occupancyMonitors.length">
          <app-monitor-card
            *ngFor="let occupancyMonitor of monitorsService.filteredOccupancyMonitors"
            [monitor]="occupancyMonitor"
            [cameras]="monitorsService.occupancyMonitorCameras[occupancyMonitor.id]"
            [monitorType]="'occupancy'"
            [isDisabled]="disabledOccupancyMonitorCard[occupancyMonitor.id]"
            (showMonitorDetails)="goToMonitorPage(occupancyMonitor)"
            (editMonitor)="goToMonitorPage(occupancyMonitor, true)"
            (deleteMonitor)="deleteOccupancyMonitor(occupancyMonitor)"
          ></app-monitor-card>
        </div>
        <app-add-first-monitors
          *ngIf="!monitorsService.occupancyMonitors.length"
          [title]="'Create your first occupancy monitor.'"
          [buttonText]="'Create occupancy monitor'"
          [text]="noOccupancyMonitorText"
          [imageSrc]="'/assets/images/occupancy-no-monitors.png'"
          (buttonClick)="openNewOccupancyMonitorDialog()"
        ></app-add-first-monitors>
      </div>
    </ng-container>
  </mat-tab>
</mat-tab-group>
