import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Organisation } from 'src/app/api';
import { GlobalMethods } from 'src/app/global-methods';
import { CameraStatus } from 'src/app/model/cameraStatus';
import { CamerasService } from 'src/app/services/cameras.service';

@Component({
  selector: 'app-organisation-card',
  templateUrl: './organisation-card.component.html',
  styleUrls: ['./organisation-card.component.scss'],
})
export class OrganisationCardComponent implements OnInit {
  adminUsers = 0;
  memberUsers = 0;

  @Input() organisation: Organisation = null;
  @Input() sites = [];
  @Input() cameras = [];
  @Input() users = [];
  @Input() canEdit: boolean;
  @Input() adminUsersSeatsAvailable: number;
  @Input() memberUsersSeatsAvailable: number;

  @Output() edit: EventEmitter<boolean> = new EventEmitter();

  issues = 0;
  urgentIssues = 0;
  disabled = false;
  processingGroup = {
    0: '24x7',
    1: '6 AM - 11 PM',
    2: 'Site open hours ± 1 hour',
  };

  constructor(private camerasService: CamerasService) {}

  ngOnInit(): void {
    this.disabled = this.organisation.state === 'disabled';
    if (!this.disabled) {
      this.cameras.forEach((camera: CameraStatus) => {
        if (this.camerasService.hasIssue(camera)) {
          this.issues += 1;
          if (camera.urgency === 100) {
            this.urgentIssues += 1;
          }
        }
      });
    }

    this.getOrganisationUsers();
  }

  getOrganisationUsers(): void {
    this.users.forEach((user) => {
      if (user.roles.includes('admin')) {
        this.adminUsers += 1;
      } else if (user.roles.includes('member')) {
        this.memberUsers += 1;
      }
    });
  }

  formatLicenses(licenses: string[]): string {
    return GlobalMethods.formatLicenses(licenses);
  }
}
