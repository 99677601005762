import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { User } from '@auth0/auth0-spa-js';

@Injectable({
  providedIn: 'root',
})
export class ImpersonationService {
  constructor(private cookieService: CookieService) {}

  setImpersonation(user: User) {
    this.cookieService.set('_hai_impersonation', 'true');
    this.cookieService.set('_hai_impersonator', btoa(encodeURIComponent(JSON.stringify(user))));
  }

  getEffictiveUser(user: User): { effectiveUser: User; impersonated: boolean } {
    let impersonated = this.cookieService.get('_hai_impersonation') === 'true';
    let effectiveUser = impersonated
      ? JSON.parse(decodeURIComponent(atob(this.cookieService.get('_hai_impersonator'))))
      : user;
    if (user.sub === effectiveUser.sub) {
      impersonated = false;
      this.clearImpersonation();
    }
    return { effectiveUser, impersonated };
  }

  clearImpersonation() {
    this.cookieService.delete('_hai_impersonation');
    this.cookieService.delete('_hai_impersonator');
  }
}
