import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddEditSiteComponent } from 'src/app/components/sites/add-edit-site/add-edit-site.component';

@Component({
  selector: 'app-no-site-to-select',
  templateUrl: './no-site-to-select.component.html',
  styleUrls: ['./no-site-to-select.component.scss'],
})
export class NoSiteToSelectComponent {
  @Output() reloadEvent: EventEmitter<void> = new EventEmitter();

  constructor(private matDialog: MatDialog) {}
  openAddSiteDialog(): void {
    const dialogRef = this.matDialog.open(AddEditSiteComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          this.reloadEvent.emit();
        }
      },
    });
  }
}
