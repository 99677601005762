import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OccupancyMonitor, PeelOffMonitor, PeopleCountMonitor } from 'src/app/api';
import { CameraStatus } from 'src/app/model/cameraStatus';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-cameras-side-menu',
  templateUrl: './cameras-side-menu.component.html',
  styleUrls: ['./cameras-side-menu.component.scss'],
})
export class CamerasSideMenuComponent implements OnInit {
  public dialogRef = null;

  @Input() incompletCameras = [];
  @Input() issuesCameras = [];
  @Input() issuesCamerasMonitors: { [siteId: string]: Set<OccupancyMonitor | PeopleCountMonitor | PeelOffMonitor> } =
    {};
  @Input() sitesMap = {};

  completPercentage: { [_: string]: number } = {};
  siteIds = [];
  impactedMonitors = 0;

  @Output() setupCamera: EventEmitter<void>;
  @Output() continueSetup: EventEmitter<CameraStatus>;
  constructor(
    public accountService: AccountService,
    private injector: Injector,
  ) {
    this.dialogRef = this.injector.get(MatDialogRef, null);
    const data = this.injector.get(MAT_DIALOG_DATA, null);
    if (!this.dialogRef) {
      this.setupCamera = new EventEmitter<void>();
      this.continueSetup = new EventEmitter<CameraStatus>();
    } else if (data) {
      this.incompletCameras = data.incompletCameras;
      this.issuesCameras = data.issuesCameras;
      this.issuesCamerasMonitors = data.issuesCamerasMonitors;
      this.sitesMap = data.sitesMap;
    }
  }

  ngOnInit(): void {
    this.siteIds = Object.keys(this.issuesCamerasMonitors);
    Object.values(this.issuesCamerasMonitors).forEach((monitors) => (this.impactedMonitors += monitors.size));
    this.incompletCameras.forEach((c: CameraStatus) => {
      this.completPercentage[c.id] = 20;

      if (new Date(c.claimedAt).getTime() < new Date(c.lastSeenAt).getTime()) {
        this.completPercentage[c.id] = 45;
      }
      if (c.direction1Alias !== 'Direction 1' && c.direction2Alias !== 'Direction 2') {
        this.completPercentage[c.id] = 80;
      }
    });
  }

  setupCameraEvent(): void {
    if (this.dialogRef) {
      this.dialogRef.close({ 'setup-camera': undefined });
    } else {
      this.setupCamera.emit();
    }
  }

  continueSetupEvent(camera): void {
    if (this.dialogRef) {
      this.dialogRef.close({ camera });
    } else {
      this.continueSetup.emit(camera);
    }
  }

  close() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
