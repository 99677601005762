import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PeopleCountMonitor } from 'src/app/api';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-give-monitor-name-step',
  templateUrl: './give-monitor-name-step.component.html',
  styleUrls: ['./give-monitor-name-step.component.scss'],
})
export class GiveMonitorNameStepComponent {
  monitorForm: FormGroup;

  @Input() set monitor(monitor: PeopleCountMonitor) {
    this.monitorForm.controls.name.patchValue(monitor.name);
    this.monitorForm.controls.reportingStartDate.patchValue(monitor.reportingStartDate);
    this.monitorForm.controls.reportingEndDate.patchValue(monitor.reportingEndDate);
  }

  constructor(
    private formBuilder: FormBuilder,
    public accountService: AccountService,
  ) {
    this.monitorForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      reportingStartDate: [undefined],
      reportingEndDate: [undefined],
    });
  }
}
