import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Subject, filter, takeUntil } from 'rxjs';
import { OccupancyMonitor } from 'src/app/api';
import { ReportsService } from 'src/app/services/reports.service';
import { AccountService } from 'src/app/services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as ApexCharts from 'apexcharts';

declare var gtag: Function;

interface CapacityManagementDataType {
  isLoading$: BehaviorSubject<boolean>;
  data: any[];
  lastUpdate: Date;
}

@Component({
  selector: 'app-capacity-management-brief',
  templateUrl: './capacity-management-brief.component.html',
  styleUrls: ['./capacity-management-brief.component.scss'],
})
export class CapacityManagementBriefComponent implements OnDestroy {
  @Input() occupancyMonitor: OccupancyMonitor;

  @Output() filterByOpeningHours: EventEmitter<void> = new EventEmitter();

  @ViewChild('chart') set content(content: ElementRef) {
    if (content) {
      this.chart = content;
      Promise.resolve().then(() => this.drawChart());
    }
  }

  @Input() set capacityManagementData(data: CapacityManagementDataType) {
    this.processCapacityManagementData(data);
  }

  viewHeight: number;
  isLoading = true;
  referenceLine = {};
  lastUpdate: Date;
  lastWeekData: number[] = [];
  thisWeekData: number[] = [];

  private chart: ElementRef;
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router,
    public reportsService: ReportsService,
    private ref: ChangeDetectorRef,
  ) {}

  private processCapacityManagementData(data: CapacityManagementDataType): void {
    this.isLoading = true;
    this.ref.detectChanges();
    this.referenceLine = {};
    this.lastWeekData = [];
    this.thisWeekData = [];

    data.isLoading$
      .pipe(
        filter((isLoading) => !isLoading),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        this.lastUpdate = data.lastUpdate;
        this.prepareChartData(data);
        this.isLoading = false;
        this.ref.detectChanges();
      });
  }

  private prepareChartData(data: CapacityManagementDataType): void {
    if (this.occupancyMonitor.capacity) {
      this.referenceLine = this.createCapacityReferenceLine(this.occupancyMonitor.capacity);
    }

    data.data.forEach((dataPoint, index) => {
      const occupancyMax = dataPoint.occupancyMax === undefined ? 0 : dataPoint.occupancyMax;
      if (index < 7) {
        this.lastWeekData.push(occupancyMax);
      } else {
        this.thisWeekData.push(occupancyMax);
      }
    });
  }

  private createCapacityReferenceLine(capacity: number): any {
    return {
      yaxis: [
        {
          y: capacity,
          borderColor: 'var(--color-red)',
          strokeDashArray: 4,
          label: {
            color: 'var(--color-red)',
            borderWidth: 0,
            text: capacity.toString(),
            position: 'right',
            style: {
              color: 'var(--color-red)',
            },
          },
        },
      ],
    };
  }

  private createBackgroundReferenceLine(day: string): any {
    return {
      x: day,
      strokeDashArray: 0,
      borderColor: 'var(--color-grey--medium)',
    };
  }

  private drawChart(): void {
    this.viewHeight = this.chart.nativeElement.offsetHeight;
    const chartOptions = this.getChartOptions();
    const chart = new ApexCharts(this.chart.nativeElement, chartOptions);
    chart.render();
  }

  private getChartOptions(): ApexCharts.ApexOptions {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const last7Days = this.getLast7DaysLabels(daysOfWeek);

    return {
      chart: {
        toolbar: { show: false },
        height: this.viewHeight,
        type: 'line',
        zoom: { enabled: false },
      },
      series: [
        { name: 'Last 7 days', data: this.thisWeekData, color: 'var(--color-rose)' },
        { name: 'Previous 7 days', data: this.lastWeekData, color: 'var(--color-mikado)' },
      ],
      stroke: { width: 2 },
      xaxis: { categories: last7Days },
      annotations: this.referenceLine,
      markers: { size: 5 },
      legend: { show: false },
    };
  }

  private getLast7DaysLabels(daysOfWeek: string[]): string[] {
    const today = new Date();
    const labels = [];

    this.referenceLine['xaxis'] = [];

    for (let i = 6; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      labels.push(i === 0 ? 'Today' : daysOfWeek[date.getDay()]);
      this.referenceLine['xaxis'].push(
        this.createBackgroundReferenceLine(i === 0 ? 'Today' : daysOfWeek[date.getDay()]),
      );
    }

    return labels;
  }

  openCapacityManagementDetailsDialog(): void {
    gtag('event', 'page_view', {
      page_title: '/reporting/capacity-management-charts',
      page_location: '/reporting/capacity-management-charts',
      page_path: '/reporting/capacity-management-charts',
      user_id: this.accountService.user.email,
    });

    this.router.navigate([`capacity-management/${this.occupancyMonitor.id}`], { relativeTo: this.route });
  }
  setViewSize() {
    this.viewHeight = this.chart.nativeElement.offsetHeight;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
