import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

type stepTypeEnum = 'ready' | 'current' | 'unready';

@Component({
  selector: 'app-camera-setup-side-panel',
  templateUrl: './camera-setup-side-panel.component.html',
  styleUrls: ['./camera-setup-side-panel.component.scss'],
})
export class CameraSetupSidePanelComponent implements OnInit {
  @Input() setupProgress = 0;
  @Input() activeStep = 0;
  @Input() camera;
  @Input() isBackable = true;
  @Input() hideAlerts = false;

  StepTypeEnum = {
    ready: 'ready' as stepTypeEnum,
    current: 'current' as stepTypeEnum,
    unready: 'unready' as stepTypeEnum,
  };

  steps = [
    {
      name: 'Claim camera',
      icon: 'tag',
      type: this.StepTypeEnum.current,
    },

    {
      name: 'Power camera',
      icon: 'power_settings_new',
      type: this.StepTypeEnum.unready,
    },

    {
      name: 'Connect to internet',
      icon: 'wifi',
      type: this.StepTypeEnum.unready,
    },
    {
      name: 'Get camera view',
      icon: 'photo_camera_back',
      type: this.StepTypeEnum.unready,
    },
    {
      name: 'Mount camera',
      icon: 'handyman',
      type: this.StepTypeEnum.unready,
    },
    {
      name: 'Name camera directions',
      icon: 'signpost',
      type: this.StepTypeEnum.unready,
    },
    {
      name: 'Schedule audit',
      icon: 'safety_check',
      type: this.StepTypeEnum.unready,
    },
    {
      name: 'Add a camera alert',
      icon: 'notifications',
      type: this.StepTypeEnum.unready,
    },
    {
      name: 'Add a health summary ',
      icon: 'emergency',
      type: this.StepTypeEnum.unready,
    },
  ];

  @Output() openPreviousStep: EventEmitter<void> = new EventEmitter();

  ngOnInit(): void {
    if (this.hideAlerts) {
      this.steps.pop();
      this.steps.pop();
    }
    this.steps = this.steps.map((step, index) => {
      let type;

      if (index < this.activeStep) {
        type = this.StepTypeEnum.ready;
      }
      if (index === this.activeStep) {
        type = this.StepTypeEnum.current;
      }
      if (index > this.activeStep) {
        type = this.StepTypeEnum.unready;
      }

      return { ...step, type };
    });
  }

  goToPreviousStep(): void {
    this.openPreviousStep.emit();
  }
}
