<mat-horizontal-stepper linear class="dialog-stepper" #stepper>
  <mat-step *ngIf="showSelectSite">
    <app-step
      [stepTitle]="stepsTitle"
      [isBackable]="false"
      [processingStep]="isLoading"
      [disabledNextStep]="!selectASiteComponent.choosenSite"
      (openNextStep)="selectASite()"
      (closeStep)="close()"
    >
      <div class="c-modal-content">
        <app-monitor-setup-side-panel [monitorType]="'people-count'" [activeStep]="0"></app-monitor-setup-side-panel>
        <app-select-a-site #selectASiteComponent class="o-step-body" [sites]="sites"> </app-select-a-site>
      </div>
    </app-step>
  </mat-step>

  <mat-step #chooseMonitorModeStep>
    <app-step
      [stepTitle]="stepsTitle"
      [isBackable]="true"
      [processingStep]="isLoading"
      (openNextStep)="goToSecondStep()"
      (closeStep)="close()"
    >
      <div class="c-modal-content">
        <app-monitor-setup-side-panel [monitorType]="'people-count'" [activeStep]="1"></app-monitor-setup-side-panel>
        <app-choose-monitor-mode-step class="o-step-body" [cameras]="filteredCameras"> </app-choose-monitor-mode-step>
      </div>
    </app-step>
  </mat-step>

  <mat-step #giveMonitorNameStep>
    <app-step
      [stepTitle]="stepsTitle"
      [disabledNextStep]="giveMonitorNameStepComponent?.monitorForm.invalid"
      (closeStep)="close()"
    >
      <div class="c-modal-content">
        <app-monitor-setup-side-panel [monitorType]="'people-count'" [activeStep]="2"></app-monitor-setup-side-panel>
        <app-give-monitor-name-step #giveMonitorNameStepComponent class="o-step-body"> </app-give-monitor-name-step>
      </div>
    </app-step>
  </mat-step>

  <mat-step #selectCamerasStep>
    <app-step
      [stepTitle]="stepsTitle"
      [disabledNextStep]="selectCamerasStepComponent.monitorForm.invalid"
      (closeStep)="close()"
      (openNextStep)="goToSetMonitorDirectionStep()"
    >
      <div class="c-modal-content">
        <app-monitor-setup-side-panel [monitorType]="'people-count'" [activeStep]="3"></app-monitor-setup-side-panel>
        <app-select-cameras-step #selectCamerasStepComponent class="o-step-body" [cameras]="filteredCameras">
        </app-select-cameras-step>
      </div>
    </app-step>
  </mat-step>

  <mat-step #selectMonitorDirectionStep>
    <app-step
      [stepTitle]="stepsTitle"
      [disabledNextStep]="selectMonitorDirectionStepComponent?.monitorForm.invalid"
      (closeStep)="close()"
    >
      <div class="c-modal-content">
        <app-monitor-setup-side-panel [monitorType]="'people-count'" [activeStep]="4"></app-monitor-setup-side-panel>
        <app-select-monitor-direction-step #selectMonitorDirectionStepComponent class="o-step-body">
        </app-select-monitor-direction-step>
      </div>
    </app-step>
  </mat-step>

  <mat-step #selectCameraDirectionStep>
    <app-step
      *ngIf="stepper.selected === selectCameraDirectionStep"
      [nextStepText]="'FINISH'"
      [stepTitle]="stepsTitle"
      [processingStep]="isLoading"
      [disabledNextStep]="this.selectCameraDirectionStepComponent.monitorForm.invalid"
      (closeStep)="close()"
      (openNextStep)="completeMultiCamerasJourney()"
    >
      <div class="c-modal-content">
        <app-monitor-setup-side-panel [monitorType]="'people-count'" [activeStep]="5"></app-monitor-setup-side-panel>
        <app-select-camera-direction-step
          #selectCameraDirectionStepComponent
          [direction1]="selectMonitorDirectionStepComponent?.monitorForm.get('direction1Alias').value"
          [direction2]="selectMonitorDirectionStepComponent?.monitorForm.get('direction2Alias').value"
          [cameras]="selectCamerasStepComponent?.monitorForm.get('cameras').value"
          class="o-step-body"
        >
        </app-select-camera-direction-step>
      </div>
    </app-step>
  </mat-step>
</mat-horizontal-stepper>
