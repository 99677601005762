import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { Observable, Subject, finalize, forkJoin, switchMap, take, takeUntil } from 'rxjs';
import {
  Alert,
  AlertCameraPositionMapping,
  AlertCameraPositionMappingService,
  AlertNotificationGroupMappingService,
  AlertService,
  CameraService,
  NotificationGroupService,
  PeopleCountMonitor,
  PeopleCountMonitorCameraPosition,
  PeopleCountMonitorCameraPositionService,
  PeopleCountMonitorService,
  Site,
  User,
  UserService,
} from 'src/app/api';
import { GlobalMethods } from 'src/app/global-methods';
import { CameraStatus } from 'src/app/model/cameraStatus';
import { AccountService } from 'src/app/services/account.service';
import { CamerasService } from 'src/app/services/cameras.service';
import { NotifyService } from 'src/app/services/notify.service';
import { SelectAlertsAndSumarriesComponent } from 'src/app/views/cameras/components/select-alerts-and-sumarries/select-alerts-and-sumarries.component';

@Component({
  selector: 'app-camera-setup-dialog',
  templateUrl: './camera-setup-dialog.component.html',
  styleUrls: ['./camera-setup-dialog.component.scss'],
})
export class CameraSetupDialogComponent implements OnDestroy, AfterViewInit {
  setupProgress = 0;
  showWifiTabInsteadEthernetTab = true;
  showOverheadInsteadHorizontalTab = true;
  isPasstraffCamera;
  readyForAudit = false;
  processingStep = false;
  offlineAlerts: Alert[] = [];
  summaryAlerts: Alert[] = [];
  offlineAlertsToAdd: Alert[] = [];
  summaryAlertsToAdd: Alert[] = [];
  offlineAlertsExisting: Alert[] = [];
  summaryAlertsExisting: Alert[] = [];
  offlineAlertCameraPositions: AlertCameraPositionMapping[] = [];
  summaryAlertCameraPositions: AlertCameraPositionMapping[] = [];

  offlineAlertsToAddSelection = new SelectionModel<string>(true, []);
  summaryAlertsToAddSelection = new SelectionModel<string>(true, []);

  filteredSites = [];
  sites = [];
  readonly GlobalMethods = GlobalMethods;
  cameraSN: string;
  camera: CameraStatus = {};
  isGettingView = false;
  cameraFrame = '';

  cameras;
  createMonitorForCamera = true;

  @ViewChild('stepper') private stepper: MatStepper;
  @ViewChild('powerTheCameraStep') powerTheCameraStep;
  @ViewChild('checkCameraViewStep') checkCameraViewStep;
  @ViewChild('scheduleAuditStep') scheduleAuditStep;
  @ViewChild('setupConfirmationStep') setupConfirmationStep;

  private ngUnsubscribe = new Subject();
  formBuilder: any;
  constructor(
    public dialogRef: MatDialogRef<CameraSetupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cameraService: CameraService,
    private camerasService: CamerasService,
    private peopleCountMonitorService: PeopleCountMonitorService,
    private peopleCountMonitorCameraPositionService: PeopleCountMonitorCameraPositionService,
    private alertCameraPositionMappingService: AlertCameraPositionMappingService,
    private alertNotificationGroupMappingService: AlertNotificationGroupMappingService,
    private userService: UserService,
    private notificationGroupService: NotificationGroupService,
    private alertService: AlertService,
    private notifyService: NotifyService,
    private ref: ChangeDetectorRef,
    private matDialog: MatDialog,
    private router: Router,
    public accountService: AccountService,
  ) {
    if (this.data.hasOwnProperty('siteId')) {
      this.camera.siteId = this.data.siteId;
    }
    if (this.data.hasOwnProperty('sites')) {
      this.sites = this.data.sites;
      if (!this.accountService.isSupport) {
        this.filteredSites = this.data.sites;
      }
    }
    if (this.data.hasOwnProperty('offlineAlerts')) {
      this.offlineAlerts = this.data.offlineAlerts;
    }
    if (this.data.hasOwnProperty('summaryAlerts')) {
      this.summaryAlerts = this.data.summaryAlerts;
    }
    if (this.data.hasOwnProperty('offlineAlertCameraPositions')) {
      this.offlineAlertCameraPositions = this.data.offlineAlertCameraPositions;
    }
    if (this.data.hasOwnProperty('summaryAlsummaryAlertCameraPositionserts')) {
      this.summaryAlertCameraPositions = this.data.summaryAlertCameraPositions;
    }
    if (this.data.hasOwnProperty('camera') && this.data.camera) {
      this.camera = this.data.camera;
    }
    if (this.data.hasOwnProperty('cameras') && this.data.cameras) {
      this.cameras = this.data.cameras;
    }
  }

  ngAfterViewInit(): void {
    if (this.camera.id) {
      this.cameras = this.cameras.filter(
        (c) => c.organisationId === this.camera.organisationId && this.camera.cameraPositionId !== c.cameraPositionId,
      );
      this.offlineAlertsExisting = this.offlineAlerts.filter(
        (a) =>
          (a.organisationId === this.camera.organisationId && !a.siteId) ||
          (a.siteId === this.camera.siteId && !this.offlineAlertCameraPositions.find((cp) => cp.alertId === a.id)),
      );
      this.summaryAlertsExisting = this.summaryAlerts.filter(
        (a) =>
          (a.organisationId === this.camera.organisationId && !a.siteId) ||
          (a.siteId === this.camera.siteId && !this.summaryAlertCameraPositions.find((cp) => cp.alertId === a.id)),
      );
      this.offlineAlerts = this.offlineAlerts.filter((a) =>
        this.offlineAlertCameraPositions.find((cp) => cp.alertId === a.id),
      );
      this.summaryAlerts = this.summaryAlerts.filter((a) =>
        this.summaryAlertCameraPositions.find((cp) => cp.alertId === a.id),
      );
      if (this.camera.direction1Alias !== 'Direction 1' && this.camera.direction2Alias !== 'Direction 2') {
        this.jumpToStep(this.scheduleAuditStep, 80);
      } else if (new Date(this.camera.claimedAt).getTime() < new Date(this.camera.lastSeenAt).getTime()) {
        this.jumpToStep(this.checkCameraViewStep, 45);
      } else {
        this.jumpToStep(this.powerTheCameraStep, 20);
      }
    }
  }

  filterSitesByOrganisation(matSelectChange: MatSelectChange): void {
    this.filteredSites = this.sites.filter((s) => s.organisationId === matSelectChange.value);
  }

  jumpToStep(step, progress: number): void {
    this.setupProgress = progress;
    this.stepper.selected = step;
    const stepIndex = this.stepper._steps.toArray().indexOf(step);
    this.stepper.selectedIndex = stepIndex;
    this.ref.detectChanges();
  }

  createCamera(): void {
    this.processingStep = true;

    this.cameraService
      .createCamera({ name: this.camera.name, serialNumber: this.cameraSN, siteId: this.camera.siteId })
      .pipe(
        finalize(() => {
          this.processingStep = false;
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (camera) => {
          this.camera = this.camerasService.getCameraStatus(camera);
          this.cameras = this.cameras.filter((c) => c.organisationId === this.camera.organisationId);
          this.offlineAlertsExisting = this.offlineAlerts.filter(
            (a) =>
              (a.organisationId === this.camera.organisationId && !a.siteId) ||
              (a.siteId === this.camera.siteId && !this.offlineAlertCameraPositions.find((cp) => cp.alertId === a.id)),
          );
          this.summaryAlertsExisting = this.summaryAlerts.filter(
            (a) =>
              (a.organisationId === this.camera.organisationId && !a.siteId) ||
              (a.siteId === this.camera.siteId && !this.summaryAlertCameraPositions.find((cp) => cp.alertId === a.id)),
          );
          this.offlineAlerts = this.offlineAlerts.filter((a) =>
            this.offlineAlertCameraPositions.find((cp) => cp.alertId === a.id),
          );
          this.summaryAlerts = this.summaryAlerts.filter((a) =>
            this.summaryAlertCameraPositions.find((cp) => cp.alertId === a.id),
          );
          this.isPasstraffCamera = this.GlobalMethods.isPasstraffCamera(this.cameraSN);
          this.showOverheadInsteadHorizontalTab = !this.isPasstraffCamera;
          this.goToNextStep(20);
        },
        error: (error) => {
          this.notifyService.error(error);
        },
      });
  }

  updateCamera(): void {
    this.processingStep = true;

    this.cameraService
      .patchCamera(this.camera.id, this.camera)
      .pipe(
        finalize(() => {
          this.processingStep = false;
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (_) => {
          this.goToNextStep(80);
        },
        error: (error) => {
          this.notifyService.error(error);
        },
      });
  }

  cameraReadyForAudit(): void {
    this.processingStep = true;

    let orgName = '';
    let ogId = '';
    let site: Site = this.sites.find((s) => s.id === this.camera.siteId);
    if (this.accountService.isSupport) {
      orgName = this.accountService.organisationsMap[site.organisationId].name;
      ogId = this.accountService.organisationsMap[site.organisationId].id;
    } else {
      orgName = this.accountService.organisation.name;
      ogId = this.accountService.organisation.id;
    }
    const message = `${orgName}, ${this.camera.name}, ${this.camera.serialNumber} ready for audit:
    camera_name: ${this.camera.name}
    camera_id: ${this.camera.id}
    camera_sn: ${this.camera.serialNumber}
    site_name: ${site.name}
    site_id: ${this.camera.siteId}
    org_name: ${orgName}
    org_id: ${ogId}
    `;
    this.cameraService
      .notifySupportRequest({
        title: `${orgName}, ${this.camera.name}, ${this.camera.serialNumber} ready for audit`,
        message,
      })
      .pipe(
        switchMap((_) => this.cameraService.listCameras(false)),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (cameras) => {
          this.cameras = cameras.filter(
            (c) =>
              c.organisationId === this.camera.organisationId && this.camera.cameraPositionId !== c.cameraPositionId,
          );
          if (!this.cameras.length) {
            // this.processingStep = false;
            this.addResourcesForNewCustomer();
          } else {
            this.goToNextStep(85);
            this.processingStep = false;
          }
        },
        error: (error) => {
          this.processingStep = false;
          this.notifyService.error(error);
        },
      });
  }

  addResourcesForNewCustomer(): void {
    this.processingStep = true;

    let organisationName;
    if (this.accountService.organisation) {
      organisationName = this.accountService.organisation.name;
    } else {
      organisationName = this.accountService.organisationsMap[this.camera.organisationId].name;
    }

    this.userService
      .listUsers(this.camera.organisationId)
      .pipe(
        take(1),
        switchMap((users: User[]) =>
          forkJoin([
            this.notificationGroupService.putNotificationGroup({
              name: organisationName + ' admins',
              emails: users.filter((u: User) => u.roles.includes('admin')).map((user) => user.email),
              organisationId: this.camera.organisationId,
            }),
            this.alertService.putAlert({
              alertType: 'offline_alert',
              configurationDetails: { duration: '4h', notifyAlertRecovery: true },
              name: organisationName + ' default camera alert',
              organisationId: this.camera.organisationId,
            }),
            this.alertService.putAlert({
              alertType: 'camera_health_summary_alert',
              configurationDetails: {
                schedule: '0 9 Mon',
              },
              name: organisationName + ' default weekly health summary',
              organisationId: this.camera.organisationId,
            }),
            this.peopleCountMonitorService.putPeopleCountMonitor({
              name: this.camera.name,
              direction1Alias: this.camera.direction1Alias,
              direction2Alias: this.camera.direction2Alias,
              siteId: this.camera.siteId,
            }),
          ]),
        ),
        switchMap(([notificationGroup, offlineAlert, summaryAlert, monitor]) =>
          forkJoin([
            this.alertNotificationGroupMappingService.putAlertNotificationGroupMapping({
              alertId: offlineAlert.id,
              notificationGroupId: notificationGroup.id,
            }),
            this.alertNotificationGroupMappingService.putAlertNotificationGroupMapping({
              alertId: summaryAlert.id,
              notificationGroupId: notificationGroup.id,
            }),
            this.peopleCountMonitorCameraPositionService.putPeopleCountMonitorCameraPosition({
              peopleCountMonitorId: monitor.id,
              cameraPositionId: this.camera.cameraPositionId,
              direction1: PeopleCountMonitorCameraPosition.Direction1Enum._1,
            }),
          ]),
        ),
        finalize(() => {
          this.processingStep = false;
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (_) => {
          this.jumpToStep(this.setupConfirmationStep, 1);
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  checkCameraIsOnline() {
    this.processingStep = true;

    this.cameraService
      .getCamera(this.camera.id)
      .pipe(
        finalize(() => {
          this.processingStep = false;
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (camera) => {
          this.camera = this.camerasService.getCameraStatus(camera);
          if (this.camera.isOnline) {
            this.getCameraFrame();
            this.notifyService.online();
            this.goToNextStep(45);
          } else {
            this.cameraOfflineNotify();
            this.goToNextStep(45);
          }
        },
        error: (_) => {
          this.cameraOfflineNotify();
        },
      });
  }

  cameraOfflineNotify(): void {
    const notifiyRef = this.notifyService.offline();
    notifiyRef.afterDismissed().subscribe({
      next: (reference) => {
        if (reference.dismissedByAction) {
          this.checkCameraIsOnline();
        }
      },
    });
  }

  getCameraFrame(): void {
    if (this.isGettingView) {
      return;
    }

    this.isGettingView = true;
    this.ref.detectChanges();

    this.cameraService
      .createFrameRequest({ cameraId: this.camera.id })
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.isGettingView = false;
          this.ref.detectChanges();
        }),
      )
      .subscribe({
        next: (frame) => {
          this.cameraFrame = 'data:image/jpeg;base64,' + frame.image;
        },
        error: (error) => {
          this.notifyService.error(error);
        },
      });
  }

  selectAlerts(): void {
    const dialogRef = this.matDialog.open(SelectAlertsAndSumarriesComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data: {
        offlineOrSummary: this.offlineAlerts,
        title: 'Add camera alert',
        resourceType: 'alert',
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result) {
          this.offlineAlertsToAdd = this.offlineAlerts.filter((o) => result.includes(o.id));
          this.offlineAlertsToAdd.forEach((o) => this.offlineAlertsToAddSelection.select(o.id));
        }
      },
    });
  }

  selectSummaries(): void {
    const dialogRef = this.matDialog.open(SelectAlertsAndSumarriesComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data: {
        offlineOrSummary: this.summaryAlerts,
        title: 'Add camera health summary',
        resourceType: 'summary',
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result) {
          this.summaryAlertsToAdd = this.summaryAlerts.filter((c) => result.includes(c.id));
          this.summaryAlertsToAdd.forEach((o) => this.summaryAlertsToAddSelection.select(o.id));
        }
      },
    });
  }

  addAlerts(): void {
    this.processingStep = true;
    const requests: Observable<any>[] = [];
    this.offlineAlertsToAddSelection.selected.forEach((offlineAlertRuleId) => {
      requests.push(
        this.alertCameraPositionMappingService.putAlertCameraPositionMapping({
          cameraPositionId: this.camera.cameraPositionId,
          alertId: offlineAlertRuleId,
        }),
      );
    });

    if (requests.length) {
      forkJoin(requests)
        .pipe(
          finalize(() => {
            this.processingStep = false;
          }),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe({
          next: (_) => {
            this.goToNextStep(95);
          },
          error: (error) => {
            this.notifyService.error(error);
          },
        });
    } else {
      this.processingStep = false;
      this.goToNextStep(95);
    }
  }

  addSummaries(): void {
    this.processingStep = true;
    const requests: Observable<any>[] = [];
    this.summaryAlertsToAddSelection.selected.forEach((cameraHealthSummaryConfigurationId) => {
      requests.push(
        this.alertCameraPositionMappingService.putAlertCameraPositionMapping({
          alertId: cameraHealthSummaryConfigurationId,
          cameraPositionId: this.camera.cameraPositionId,
        }),
      );
    });

    if (requests.length) {
      forkJoin(requests)
        .pipe(
          finalize(() => {
            this.processingStep = false;
          }),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe({
          next: (_) => {
            this.goToNextStep(1);
          },
          error: (error) => {
            this.notifyService.error(error);
          },
        });
    } else {
      this.processingStep = false;
      this.goToNextStep(1);
    }
  }

  finalizeJourney(): void {
    if (this.createMonitorForCamera) {
      this.processingStep = true;

      this.peopleCountMonitorService
        .putPeopleCountMonitor({
          name: this.camera.name,
          direction1Alias: this.camera.direction1Alias,
          direction2Alias: this.camera.direction2Alias,
          siteId: this.camera.siteId,
        })
        .pipe(
          switchMap((monitor: PeopleCountMonitor) =>
            this.peopleCountMonitorCameraPositionService.putPeopleCountMonitorCameraPosition({
              peopleCountMonitorId: monitor.id,
              cameraPositionId: this.camera.cameraPositionId,
              direction1: PeopleCountMonitorCameraPosition.Direction1Enum._1,
            }),
          ),
          finalize(() => {
            this.processingStep = true;
          }),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe({
          next: (_) => {
            this.close(true);
          },
          error: (error) => {
            this.notifyService.error(error);
          },
        });
    } else {
      this.close(true);
    }
  }

  getCameraAlerts(): Alert[] {
    return this.offlineAlerts.filter((o) => this.offlineAlertsToAddSelection.selected.includes(o.id));
  }

  getCameraSummaries(): Alert[] {
    return this.summaryAlerts.filter((o) => this.summaryAlertsToAddSelection.selected.includes(o.id));
  }

  goToNextStep(setupProgress: number): void {
    this.setupProgress = setupProgress;
    this.stepper.next();
    this.ref.detectChanges();
  }

  goToPreviousStep(setupProgress: number): void {
    this.setupProgress = setupProgress;
    this.stepper.previous();
    this.ref.detectChanges();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }

  navigateToMonitorsPage(): void {
    this.router.navigate(['monitors']);
    this.close();
  }

  close(reload?: boolean): void {
    if (reload) {
      this.dialogRef.close('reload');
    } else {
      this.dialogRef.close();
    }
  }
}
