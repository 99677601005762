import { Component, Input, OnInit } from '@angular/core';
import { Alert, NotificationGroup, OccupancyMonitor, Site, User } from 'src/app/api';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-occupancy-alert-summray',
  templateUrl: './occupancy-alert-summray.component.html',
  styleUrls: ['./occupancy-alert-summray.component.scss'],
})
export class OccupancyAlertSummrayComponent implements OnInit {
  @Input() site: Site;
  @Input() allSites: boolean;
  @Input() selectedMonitors: OccupancyMonitor[] = [];
  @Input() monitors: OccupancyMonitor[] = [];
  @Input() allMonitors: boolean;
  @Input() occupancyAlert: Alert;
  @Input() notificationGroupName: string;
  @Input() selectedRecipients: string[];

  notificationGroupMembers: { [_: string]: number } = {};
  constructor(private notificationsService: NotificationsService) {
    this.notificationGroupMembers = this.notificationsService.notificationGroupMembers;
  }

  ngOnInit(): void {
    this.notificationGroupName =
      this.selectedRecipients.length === 1 && this.selectedRecipients[0].hasOwnProperty('id')
        ? `${this.notificationGroupName} (${this.notificationGroupMembers[this.selectedRecipients[0]['id']]})`
        : this.notificationGroupName;
  }

  getRecipientName(recipient: NotificationGroup | User | string): string {
    if (typeof recipient === 'string') {
      return recipient;
    } else if (recipient.hasOwnProperty('firstName')) {
      return recipient.name;
    } else return `${recipient.name} (${this.notificationGroupMembers[recipient.id]})`;
  }
}
