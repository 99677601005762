import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input } from '@angular/core';
import { AlertCameraPositionMapping, Camera } from 'src/app/api';

@Component({
  selector: 'app-camera-alert-and-summary-cameras',
  templateUrl: './camera-alert-and-summary-cameras.component.html',
  styleUrls: ['./camera-alert-and-summary-cameras.component.scss'],
})
export class CameraAlertAndSummaryCamerasComponent {
  @Input() resourceType = '';
  @Input() cameras: Camera[] = [];

  @Input() set selectedCameras(selectedCameras: AlertCameraPositionMapping[]) {
    selectedCameras.forEach((selectedCamera) => {
      this.selection.select(this.cameras.find((camera) => camera.cameraPositionId === selectedCamera.cameraPositionId));
    });
  }

  selection = new SelectionModel<Camera>(true, []);
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.cameras.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.cameras.forEach((camera) => this.selection.select(camera));
  }
}
