import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'alertDuration',
})
export class AlertDurationPipe implements PipeTransform {
  transform(value: string): string {
    let i;
    for (i = value.length - 1; i > 1; i--) {
      if ((this.isCharNumber(value[i - 1]) && value[i] === '0') || (this.isCharNumber(value[i]) && value[i] !== '0')) {
        break;
      }
    }
    return value.slice(0, i + 1);
  }

  isCharNumber(c) {
    return c >= '0' && c <= '9';
  }
}
