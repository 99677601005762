import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-oauth-client-flow-help',
  standalone: true,
  imports: [NgOptimizedImage],
  templateUrl: './oauth-client-flow-help.component.html',
  styleUrl: './oauth-client-flow-help.component.scss',
})
export class OauthClientFlowHelpComponent {
  data = inject(MAT_DIALOG_DATA);
}
