import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Site } from 'src/app/api';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-select-a-site',
  templateUrl: './select-a-site.component.html',
  styleUrls: ['./select-a-site.component.scss'],
})
export class SelectASiteComponent implements OnInit {
  @Input() sites: Site[] = [];
  filteredSites: Site[];
  choosenSite: Site;
  organisationId: string;
  showOrganisationSelect = false;

  constructor(
    public accountService: AccountService,
    private router: Router,
  ) {
    this.showOrganisationSelect = accountService.isSupport;
  }

  ngOnInit(): void {
    this.filterByOrganisation();
  }
  filterByOrganisation() {
    this.filteredSites = this.organisationId
      ? this.sites.filter((s) => s.organisationId === this.organisationId)
      : this.sites;
  }

  navigateToSites(): void {
    this.router.navigate(['/sites']).then(() => {
      window.location.reload();
    });
  }
}
