import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-processing-summary',
  templateUrl: './processing-summary.component.html',
  styleUrls: ['./processing-summary.component.scss'],
})
export class ProcessingSummaryComponent implements OnInit {
  @Input() fullId = '';
  private summaryURL: string;
  private baseURL: string = 'https://mlops.hoxton.ai/grafana/d/SgDx2xtVk/video-stream-details?orgId=1&from=now-3h';
  constructor() {}

  ngOnInit(): void {
    this.summaryURL = `${this.baseURL}&var-streamId=${this.fullId.replace(/-/g, '')}`;
  }

  openSummaryView(): void {
    window.open(this.summaryURL, '_blank');
  }
}
