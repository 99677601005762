import { Component } from '@angular/core';
import { ReportService } from 'src/app/api';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-overview-tab',
  templateUrl: './overview-tab.component.html',
  styleUrl: './overview-tab.component.scss',
})
export class OverviewTabComponent {
  constructor(
    private reportService: ReportService,
    public accountService: AccountService,
  ) {}

  // ngOnInit(): void {
  //   // this.reportService.createAggregatedAPIRequestMetricsReport({}).subscribe({
  //   //   next: (response) => {
  //   //     console.log(response);
  //   //   },
  //   // });
  // }
}
