<div class="o-dialog">
  <div class="o-dialog__title">
    <div class="u-flush--bottom">Notification group</div>
    <div class="">{{ notificationGroup.name }}.</div>
  </div>
  <div class="o-dialog__body u-hard--left u-hard--right">
    <ul class="o-avatar__list">
      <li *ngFor="let email of notificationGroup.emails" class="o-avatar__list-item o-avatar__row">
        <ng-container *ngIf="userEmailToUserMap[email]">
          <div class="o-avatar__icon">
            <span>{{ userEmailToUserMap[email].name.split(' ')[0][0] }}</span>
            <span *ngIf="userEmailToUserMap[email].name.split(' ')[1]">
              {{ userEmailToUserMap[email].name.split(' ')[1][0] }}
            </span>
          </div>

          <div class="o-avatar__description">
            <div>{{ userEmailToUserMap[email].name }}</div>
            <div>{{ email }}</div>
          </div>
        </ng-container>

        <ng-container *ngIf="!userEmailToUserMap[email]">
          <div class="o-avatar__icon">
            <span>{{ email[0] | uppercase }}</span>
          </div>

          <div class="o-avatar__description">
            <div>{{ email }}</div>
            <div>Does not have control room access</div>
          </div>
        </ng-container>
      </li>
      <li *ngFor="let childNotificationGroup of childNotificationGroups" class="o-avatar__list-item">
        <div class="o-avatar__icon">
          <span>{{ childNotificationGroup.name.split(' ')[0][0] }}</span>
          <span *ngIf="childNotificationGroup.name.split(' ')[1]">
            {{ childNotificationGroup.name.split(' ')[1][0] }}
          </span>
        </div>

        <div class="o-avatar__description">
          <div>{{ childNotificationGroup.name }}</div>
          <div>
            {{ childNotificationGroup.emails.length }}
            {{ childNotificationGroup.emails.length > 1 ? 'members' : 'member' }}
          </div>
        </div>
      </li>
    </ul>
  </div>

  <div class="o-dialog__actions">
    <div (click)="editGroup()" class="is-destructive">EDIT GROUP</div>
    <div (click)="close()" class="is-constructive">CLOSE</div>
  </div>
</div>
