<section class="o-expansion-section">
  <div class="o-expansion-section__header" (click)="collapseCamerasSection = !collapseCamerasSection">
    <mat-icon class="is-icon">camera_alt</mat-icon>
    <div class="is-title">Cameras</div>
    <mat-icon class="is-expansion-icon">
      {{ collapseCamerasSection ? 'expand_more' : 'expand_less' }}
    </mat-icon>
  </div>

  <div class="o-expansion-section__body" [ngClass]="{ 'is-hidden': collapseCamerasSection }">
    <div class="o-banner-alert is-error">
      <span class="is-message"> You must add at least one site before you can set up a camera. </span>
    </div>
    <div class="c-add-camera">
      <div class="c-add-camera__overhead c-add-camera__item">
        <div class="primer kirk">Overhead cameras</div>

        <div>
          <img src="/assets/images/new-homepage/overhead-camera.png" alt="Overhead camera" loading="lazy" />
        </div>

        <button mat-button class="o-button is-primary is-button" (click)="addCamera.emit()" [disabled]="!canAddCamera">
          <span class="material-icons is-icon-left"> camera_alt </span>
          setup camera
        </button>
      </div>
      <div class="c-add-camera__horizontal c-add-camera__item">
        <div class="primer kirk">Horizontal cameras</div>

        <div>
          <img src="/assets/images/new-homepage/horizontal-camera.png" alt="Horizontal camera" loading="lazy" />
        </div>

        <!-- <button class="o-button is-contained">
          <span class="material-icons is-icon-left"> add_a_photo </span>
          ORDER NEW CAMERA
        </button> -->
      </div>
      <ul class="o-list c-add-camera__list">
        <li class="o-list__item">
          <div class="o-list__item-icon">
            <mat-icon class="is-icon is-selected">check</mat-icon>
          </div>
          Cameras are used to create monitors.
        </li>
        <li class="o-list__item">
          <div class="o-list__item-icon">
            <mat-icon class="is-icon is-selected">check</mat-icon>
          </div>
          A single camera can be used in more than one monitor.
        </li>
        <li class="o-list__item">
          <div class="o-list__item-icon">
            <mat-icon class="is-icon is-selected">check</mat-icon>
          </div>
          A single camera can be used in a people count and an occupancy monitor.
        </li>
        <li class="o-list__item">
          <div class="o-list__item-icon">
            <mat-icon class="is-icon is-selected">check</mat-icon>
          </div>
          You can add more cameras to your site at any time.
        </li>
      </ul>
    </div>
  </div>
</section>
