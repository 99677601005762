import * as moment from 'moment';
import { Camera } from './api';
import { CookieService } from 'ngx-cookie-service';

export class GlobalMethods {
  private static newFeatureCookieKey: string = '_hai_seen_nf_dwell_time_report';

  static showNewFeatureDialog(cookieService: CookieService): boolean {
    let c = cookieService.get(this.newFeatureCookieKey);
    if (c) {
      return !(c === 'true');
    }
    return true;
  }

  static dontShowNewFeatureDialog(cookieService: CookieService): void {
    cookieService.set(this.newFeatureCookieKey, 'true');
  }

  static dateFor(value: any): string {
    if (value) {
      return 'for ' + GlobalMethods.dateDelta(value);
    }
    return null;
  }

  static dateAgo(value: any): string {
    if (value) {
      var date = GlobalMethods.dateDelta(value);
      return date === 'Just now' ? date : date + ' ago';
    }
    return null;
  }

  // Based on https://gist.github.com/shifatul-i/cfacd00f6d36a7d6d03aa52f33ca23fd
  static dateDelta(value: any): string {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29) {
        // less than 30 seconds ago will show as 'Just now'
        return 'Just now';
      }
      const intervals = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1,
      };
      let counter;
      for (const i in intervals) {
        if ({}.hasOwnProperty.call(intervals, i)) {
          counter = Math.floor(seconds / intervals[i]);
          if (counter > 0) {
            return `${counter}  ${GlobalMethods.pluraliseWord(counter, i)}`;
          }
        }
      }
    }
    return value;
  }

  static isValidCameraID(id: string): boolean {
    const regexp = new RegExp('^[dp][wbc]?v[0-9]{1,3}b[0-9]{4,6}');
    return id ? regexp.test(id.toLowerCase()) : false;
  }

  static isPasstraffCamera(id: string): boolean {
    const regexp = new RegExp('^p[wbc]?v[0-9]{1,3}b[0-9]{4,6}');
    return regexp.test(id.toLowerCase());
  }

  static isOverheadCamera(id: string): boolean {
    const regexp = new RegExp('^d[wbc]?v[0-9]{1,3}b[0-9]{4,6}|[a-z]{3}[0-9]{5}-[dgl]$');
    return regexp.test(id.toLowerCase());
  }

  static getCameraType(camera: Camera): 'Unknown' | 'Passing traffic' | 'Overhead' {
    let type: 'Unknown' | 'Passing traffic' | 'Overhead' = 'Unknown';
    if (camera.serialNumber) {
      if (GlobalMethods.isPasstraffCamera(camera.serialNumber)) {
        type = 'Passing traffic';
      } else if (GlobalMethods.isOverheadCamera(camera.serialNumber)) {
        type = 'Overhead';
      }
    }
    return type;
  }

  static thresholdMinutesToPercentage(minutes: number): number {
    return Math.round((minutes * 100) / 1440);
  }

  static thresholdPercentageToMinutes(percentage: number): number {
    return Math.round((percentage * 1440) / 100);
  }

  static formatLicenses(licenses: string[]): string {
    let formatedLicenses = '';
    if (!licenses) {
      return formatedLicenses;
    }
    formatedLicenses = licenses
      .map((license) => license.replace('_', ' ').replace(/./, (c) => c.toUpperCase()))
      .join(', ');

    return formatedLicenses;
  }

  static pluraliseWord(count: number, word: string) {
    return count === 1 ? word : `${word}s`;
  }

  static cameraHasPoorConnection(camera: Camera): boolean {
    // if (camera.last24HourUploadedVideoProportion) {
    //   const [videoProportion, _] = formatPercent(camera.last24HourUploadedVideoProportion, 'en-GB').match(/[0-9]{1,3}/);
    //   if (parseInt(videoProportion) < 100 && camera.state !== 'paused') {
    //     return true;
    //   }
    // }
    return false;
  }

  static timeGrainToMinutes(timeGrain: string): number {
    const allowedTimeGrains = { s: 1 / 60, m: 1, h: 60, d: 1440 };
    let [v, u] = timeGrain.match(/[0-9]+|[smhd]/g);
    if (!allowedTimeGrains.hasOwnProperty(u)) return 0;
    let vInt = parseInt(v);
    return vInt * allowedTimeGrains[u];
  }

  static timeStringToMinutes(time: string): number {
    const t = time.split(' ')[0];
    const period = time.split(' ')[1];
    let hod = Number(t.split(':')[0]);
    const mod = Number(t.split(':')[1]);
    hod = hod % 12; // since we want 12 hours to render as 0 minutes
    let dayMinutes = 0;
    if (period === 'PM') {
      dayMinutes = 720;
    }
    dayMinutes += 60 * hod + mod;
    return dayMinutes;
  }

  static minutesToTimeString(dayMinutes: number): string {
    let period = 'AM';
    if (dayMinutes >= 720) {
      period = 'PM';
      dayMinutes -= 720;
    }
    const mod = dayMinutes % 60;
    const hod = (dayMinutes - mod) / 60;
    const modStr = this.getTwoDigitTime(mod);
    const hodStr = this.getTwoDigitTime(hod);
    let timeStr;
    if (hodStr === '00' && period === 'AM') {
      timeStr = '12' + ':' + modStr + ' ' + period;
    } else {
      timeStr = hodStr + ':' + modStr + ' ' + period;
    }
    return timeStr;
  }

  static getTwoDigitTime(num: number): string {
    let numStr: string;
    if (num < 10) {
      numStr = '0' + String(num);
    } else {
      numStr = String(num);
    }
    return numStr;
  }

  static changeTimezone(currenteTime: string, newTimezone: string): string {
    return moment.tz(new Date(currenteTime), newTimezone).format();
  }

  static getNextDate(dayTime: string, day?: string, isTomorrow?: boolean): string {
    const daysMap = { Sunday: 0, Monday: 1, Tuesday: 2, Wednesday: 3, Thursday: 4, Friday: 5, Saturday: 6 };
    let date = new Date();

    const dayTimeMinutes = GlobalMethods.timeStringToMinutes(dayTime);
    // if the desired time of the day has not passed today, set the reference day to yasterday to take into account today
    if (dayTimeMinutes > date.getHours() * 60 + date.getMinutes()) {
      date = new Date(date.setDate(date.getDate() - 1));
    }
    date.setHours(0, 0, 0, 0);
    date.setMinutes(dayTimeMinutes);

    const nextDay = isTomorrow ? 1 : (7 - date.getDay() + daysMap[day]) % 7 || 7;
    const nextDate = new Date(date.setDate(date.getDate() + nextDay));

    return nextDate.toISOString();
  }

  static humanizeSchedule(schedule: string): string {
    const dayStrings = {
      '*': 'Daily',
      Mon: 'Weekly on Monday',
      Tue: 'Weekly on Tuesday',
      Wed: 'Weekly on Wednesday',
      Thu: 'Weekly on Thursday',
      Fri: 'Weekly on Friday',
      Sat: 'Weekly on Saturday',
      Sun: 'Weekly on Sunday',
    };
    let [minute, hour, dow] = schedule.split(' ');
    let hours = parseInt(hour, 10);
    let suffix = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    return `${dayStrings[dow]} at ${hours}:${minute.padStart(2, '0')} ${suffix}`;
  }

  static getFullWeekdayName(short: string): string {
    const dayStrings = {
      Mon: 'Monday',
      Tue: 'Tuesday',
      Wed: 'Wednesday',
      Thu: 'Thursday',
      Fri: 'Friday',
      Sat: 'Saturday',
      Sun: 'Sunday',
    };
    return dayStrings[short];
  }

  static getDayFromDate(date: string): string {
    const daysMap = {
      0: 'Sunday',
      1: 'Monday',
      2: 'Tuesday',
      3: 'Wednesday',
      4: 'Thursday',
      5: 'Friday',
      6: 'Saturday',
    };
    return daysMap[new Date(date).getDay()];
  }

  static formatTime24h(date: string): string {
    return new Date(date).toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit', hour12: false });
  }

  static formatAMPM(date: string) {
    const timeDate = new Date(date);
    var hours = timeDate.getHours();
    var minutes = timeDate.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    var minutesString = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutesString + ' ' + ampm;
    return strTime;
  }

  static delay(ms: number): Promise<any> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  static flatten(arrays) {
    return arrays.reduce((a, b) => a.concat(b), []);
  }

  static unique(array) {
    return [...new Set(array)];
  }
}
