import { Injectable, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { concatMap, from, Observable, Subject, takeUntil, takeWhile, timer } from 'rxjs';
import { AggregatedVideoMetrics, AggregatedVideoMetricsReport, Camera, ReportService, Site } from 'src/app/api';
import { GlobalMethods } from 'src/app/global-methods';
import { CameraStatus } from 'src/app/model/cameraStatus';

@Injectable({
  providedIn: 'root',
})
export class CamerasService implements OnDestroy {
  private ids: string[] = [];
  private mutex: boolean = false;
  private $aggregatedVideoMetric: Subject<AggregatedVideoMetrics> = new Subject<AggregatedVideoMetrics>();

  private ngUnsubscribe = new Subject();
  constructor(private reportService: ReportService) {}

  public getCameraStatus(camera: Camera, site?: Site): CameraStatus {
    const c: CameraStatus = camera;
    if (site) {
      c.siteName = site.name;
    }

    c.type = GlobalMethods.getCameraType(camera);

    c.status = 'online';
    if (c.state === 'decommissioned' || c.state === 'paused') {
      c.status = c.state;
    } else if (!c.isOnline) {
      c.status = 'offline';
      c.urgency = 50;

      if (c.lastSeenAt) {
        const offlineTime = new Date().getTime() - new Date(c.lastSeenAt).getTime();
        if (offlineTime > 120000) {
          c.urgency += Math.min(Math.trunc(((offlineTime - 120000) * 50) / 480000), 50);
        }
      } else {
        c.urgency = 100;
      }
    } else if (c.encodedFramesProportion < 0.75) {
      // c.status = 'missing';
      // c.urgency = 100;
    } else if (c.videoBacklog > 300000) {
      c.status = 'lagging';
      c.urgency = 25;
      c.urgency += Math.min(Math.trunc(((c.videoBacklog - 300000) * 75) / (60000 * 45)), 75);
    }

    return c;
  }

  hasIssue(camera: CameraStatus): boolean {
    return camera.status === 'lagging' || camera.status === 'missing' || camera.status === 'offline';
  }

  async createAggregatedVideoMetricsReport(cameraId: string): Promise<Observable<AggregatedVideoMetrics>> {
    await new Promise<void>((resolve) => {
      const interval = setInterval(() => {
        if (!this.mutex) {
          clearInterval(interval);
          resolve();
        }
      }, 10);
    });
    this.mutex = true;

    this.ids.push(cameraId);
    if (this.ids.length === 1) {
      timer(1000)
        .pipe(takeWhile(() => this.ids.length < 20))
        .subscribe(async () => {
          await new Promise<void>((resolve) => {
            const interval = setInterval(() => {
              if (!this.mutex) {
                clearInterval(interval);
                resolve();
              }
            }, 10);
          });
          this.mutex = true;
          if (this.ids.length > 0) {
            const report: AggregatedVideoMetricsReport = {
              cameraIds: [...this.ids],
              startDate: moment().subtract(4, 'weeks').format('YYYY-MM-DD'),
              endDate: moment().format('YYYY-MM-DD'),
              timeGrain: '168h',
            };
            this.ids = [];

            this.reportService
              .createAggregatedVideoMetricsReport(report)
              .pipe(
                concatMap((aggregatedVideoMetrics: AggregatedVideoMetrics[]) => from(aggregatedVideoMetrics)),
                takeUntil(this.ngUnsubscribe),
              )
              .subscribe((aggregatedVideoMetric) => {
                this.$aggregatedVideoMetric.next(aggregatedVideoMetric);
              });
          }

          this.mutex = false;
        });
    } else if (this.ids.length >= 20) {
      const report: AggregatedVideoMetricsReport = {
        cameraIds: [...this.ids],
        startDate: moment().subtract(4, 'weeks').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        timeGrain: '168h',
      };
      this.ids = [];

      this.reportService
        .createAggregatedVideoMetricsReport(report)
        .pipe(
          concatMap((aggregatedVideoMetrics: AggregatedVideoMetrics[]) => from(aggregatedVideoMetrics)),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe((aggregatedVideoMetric) => {
          this.$aggregatedVideoMetric.next(aggregatedVideoMetric);
        });
    }

    this.mutex = false;

    return this.$aggregatedVideoMetric;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
