import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Alert, Organisation, Site } from 'src/app/api';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-camera-alert-and-summary-name-and-sites',
  templateUrl: './camera-alert-and-summary-name-and-sites.component.html',
  styleUrls: ['./camera-alert-and-summary-name-and-sites.component.scss'],
})
export class CameraAlertAndSummaryNameAndSitesComponent implements OnInit {
  @Input() sites: Site[] = [];
  @Input() resourceType = '';
  @Input() alertOrSummary: Alert;

  alertName = '';
  filteredSites: Site[];
  selectedOrganisation: Organisation;
  selectedSite: Site;
  showOrganisationSelect: boolean;
  alertMode: 'site' | 'organisaiton' = 'organisaiton';

  @Output() closeDialog: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public accountService: AccountService,
    private ref: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (this.accountService.isSupport) {
      this.showOrganisationSelect = this.accountService.isSupport;
    } else {
      this.selectedOrganisation = this.accountService.organisation;
    }

    this.filteredSites = this.sites;

    if (this.alertOrSummary) {
      this.alertName = this.alertOrSummary.name;
      this.showOrganisationSelect = false;
      this.selectedOrganisation = this.accountService.isSupport
        ? this.accountService.organisations.find((org) => org.id === this.alertOrSummary.organisationId)
        : this.accountService.organisation;
      this.filterSitesByOrganisation();

      if (this.alertOrSummary.siteId) {
        this.alertMode = 'site';
        this.selectedSite = this.sites.find((site) => site.id === this.alertOrSummary.siteId);
      } else {
        this.alertMode = 'organisaiton';
      }
      this.ref.detectChanges();
    }
  }

  filterSitesByOrganisation() {
    this.filteredSites = this.sites.filter((site) => site.organisationId === this.selectedOrganisation.id);
  }

  // onAlertUseRadioChange(value): void {
  //   if (this.alertMode === value) {
  //     return;
  //   }

  //   this.alertMode = value;
  //   this.alertOrSummaryForm.controls['alertUse'].patchValue(this.alertMode);

  //   if (this.alertMode) {
  //     this.alertOrSummaryForm.controls['siteId'].setValue(null);
  //     this.alertOrSummaryForm.controls['siteId'].clearValidators();
  //     this.alertOrSummaryForm.controls['alertUse'].setValidators(Validators.required);
  //   } else {
  //     this.alertOrSummaryForm.controls['siteId'].setValidators(Validators.required);
  //     this.alertOrSummaryForm.controls['alertUse'].clearValidators();
  //   }

  //   this.alertOrSummaryForm.controls['siteId'].updateValueAndValidity();
  //   this.alertOrSummaryForm.controls['alertUse'].updateValueAndValidity();
  // }

  // onSitesRadioChange(event: any): void {
  //   this.activeSiteToUseAlert = event.value;
  //   this.alertOrSummaryForm.controls['siteId'].patchValue(event.value.id);
  // }

  // onOrganisationChange(): void {
  //   const selectedOrganisationId = this.alertOrSummaryForm.controls['organisationId'].value;
  //   this.sitesToUseAlert = this.sites.filter((site) => site.organisationId === selectedOrganisationId);
  // }
}
