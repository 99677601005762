import { CdkStep } from '@angular/cdk/stepper';
import { Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Subject, forkJoin, Observable } from 'rxjs';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import {
  Alert,
  AlertCameraPositionMapping,
  AlertCameraPositionMappingService,
  AlertNotificationGroupMapping,
  AlertNotificationGroupMappingService,
  AlertService,
  Camera,
  NotificationGroup,
  NotificationGroupMappingService,
  NotificationGroupService,
  Site,
  User,
} from 'src/app/api';
import { CameraAlertAndSummaryCamerasComponent } from 'src/app/components/notifications/steps-for-stepper/camera-alert-and-summary-cameras/camera-alert-and-summary-cameras.component';
import { CameraAlertAndSummaryNameAndSitesComponent } from 'src/app/components/notifications/steps-for-stepper/camera-alert-and-summary-name-and-sites/camera-alert-and-summary-name-and-sites.component';
import { CameraAlertSettingsComponent } from 'src/app/components/notifications/steps-for-stepper/camera-alert-settings/camera-alert-settings.component';
import { NotifyService } from 'src/app/services/notify.service';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-add-edit-camera-alerts-offline',
  templateUrl: './add-edit-camera-alerts-offline.component.html',
  styleUrls: ['./add-edit-camera-alerts-offline.component.scss'],
})
export class AddEditCameraAlertsOfflineComponent implements OnDestroy {
  stepsTitle = 'Real time camera alerts';
  sites: Site[];
  filteredCameras: Camera[] = [];
  cameras: Camera[];
  camerasMap: { [_: string]: Camera } = {};
  offlineAlert: Alert;
  offlineAlertCameraPositions: AlertCameraPositionMapping[] = [];
  saving = false;
  editing = false;
  notificationGroupName: string;
  notificationGroups: NotificationGroup[] = [];
  users: User[] = [];
  filteredNotificationGroups: NotificationGroup[] = [];
  filteredUsers: User[] = [];
  organisationId;
  offlineAlertNotificationGroups: NotificationGroup[] = [];
  offlineAlertNotificationGroupLinks: AlertNotificationGroupMapping[];

  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('cameraAlertSettings') cameraAlertSettings: CdkStep;
  @ViewChild(CameraAlertAndSummaryNameAndSitesComponent)
  cameraAlertAndSummaryNameAndSitesComponent: CameraAlertAndSummaryNameAndSitesComponent;
  @ViewChild(CameraAlertAndSummaryCamerasComponent)
  cameraAlertAndSummaryCamerasComponent: CameraAlertAndSummaryCamerasComponent;
  @ViewChild(CameraAlertSettingsComponent)
  cameraAlertSettingsComponent: CameraAlertSettingsComponent;

  private ngUnsubscribe = new Subject();
  constructor(
    private notifyService: NotifyService,
    private notificationsService: NotificationsService,
    private notificationGroupService: NotificationGroupService,
    private notificationGroupMappingService: NotificationGroupMappingService,
    private alertService: AlertService,
    private alertCameraPositionMappingService: AlertCameraPositionMappingService,
    private alertNotificationGroupMappingService: AlertNotificationGroupMappingService,
    public dialogRef: MatDialogRef<AddEditCameraAlertsOfflineComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.sites = this.data.sites;
    this.cameras = this.data.cameras;
    this.camerasMap = this.data.camerasMap;
    this.notificationGroups = this.data.notificationGroups;
    this.users = this.data.users;

    if (this.data.offlineAlert) {
      this.offlineAlertCameraPositions = this.data.offlineAlertCameraPositions;
      this.offlineAlert = this.data.offlineAlert;
      this.offlineAlertNotificationGroupLinks = this.data.offlineAlertNotificationGroupLinks;
      this.offlineAlertNotificationGroupLinks.forEach((offlineAlertNotificationGroupLink) =>
        this.offlineAlertNotificationGroups.push(
          this.notificationsService.notificationGroupsMap[offlineAlertNotificationGroupLink.notificationGroupId],
        ),
      );
      this.editing = true;
    }
  }

  goTosecondStep(): void {
    this.organisationId = this.cameraAlertAndSummaryNameAndSitesComponent.selectedOrganisation.id;
    this.filteredUsers = this.users.filter((user) => user.organisationId === this.organisationId);
    this.filteredNotificationGroups = this.notificationGroups.filter(
      (notificationGroup) => notificationGroup.organisationId === this.organisationId,
    );

    if (this.cameraAlertAndSummaryNameAndSitesComponent.alertMode === 'site') {
      this.filteredCameras = this.cameras.filter(
        (camera: Camera) => this.cameraAlertAndSummaryNameAndSitesComponent.selectedSite.id === camera.siteId,
      );

      this.stepper.next();
    } else {
      this.stepper.selectedIndex = 2;
    }
  }

  setofflineAlert(): void {
    this.offlineAlert = {
      ...this.offlineAlert,
      alertType: 'offline_alert',
      organisationId: this.cameraAlertAndSummaryNameAndSitesComponent.selectedOrganisation.id,
      name: this.cameraAlertAndSummaryNameAndSitesComponent.alertName,
      configurationDetails: { ...this.cameraAlertSettingsComponent.alertForm.value },
    };

    if (this.cameraAlertAndSummaryNameAndSitesComponent.alertMode === 'site') {
      this.offlineAlert.siteId = this.cameraAlertAndSummaryNameAndSitesComponent.selectedSite.id;
    }

    this.notificationGroupName = undefined;
    if (
      this.cameraAlertSettingsComponent.selectRecipientsComponent.selectedRecipients.length === 1 &&
      this.cameraAlertSettingsComponent.selectRecipientsComponent.selectedRecipients[0].hasOwnProperty('emails')
    ) {
      this.notificationGroupName =
        this.cameraAlertSettingsComponent.selectRecipientsComponent.selectedRecipients[0].name;
    } else if (
      this.cameraAlertSettingsComponent.selectRecipientsComponent.createNotificationCheck &&
      this.cameraAlertSettingsComponent.selectRecipientsComponent.selectedRecipients.length !== 1
    ) {
      this.notificationGroupName =
        this.cameraAlertSettingsComponent.selectRecipientsComponent.createNotificationName.value;
    }

    this.stepper.next();
  }

  private getRecipientsEmails(recipients: any[]): string[] {
    const emails = [];
    recipients.forEach((recipient) => {
      if (typeof recipient === 'string') {
        emails.push(recipient);
      } else if (recipient.hasOwnProperty('firstName')) {
        emails.push(recipient.email);
      }
    });
    return emails;
  }

  private getRecipientsNotificationGroupsIDs(recipients: any[]): string[] {
    const notificationGroupsIDs = [];
    recipients.forEach((recipient) => {
      if (recipient.hasOwnProperty('emails')) {
        notificationGroupsIDs.push(recipient.id);
      }
    });
    return notificationGroupsIDs;
  }

  addCameraAlert(): void {
    this.saving = true;
    const requests: Observable<any>[] = [];
    const offlineAlert = {
      ...this.offlineAlert,
    };
    delete offlineAlert['createdAt'];
    let emails;

    if (
      this.cameraAlertSettingsComponent.selectRecipientsComponent.selectedRecipients.length === 1 &&
      this.cameraAlertSettingsComponent.selectRecipientsComponent.selectedRecipients[0].hasOwnProperty('emails')
    ) {
      requests.push(this.alertService.putAlert({ ...offlineAlert, emails: undefined }));
      // notificationGroup = this.cameraAlertSettingsComponent.selectRecipientsComponent.selectedRecipients[0];
    } else if (
      this.cameraAlertSettingsComponent.selectRecipientsComponent.createNotificationCheck &&
      this.cameraAlertSettingsComponent.selectRecipientsComponent.selectedRecipients.length !== 1
    ) {
      requests.push(this.alertService.putAlert(offlineAlert));
      requests.push(
        this.notificationGroupService.putNotificationGroup({
          emails: this.getRecipientsEmails(
            this.cameraAlertSettingsComponent.selectRecipientsComponent.selectedRecipients,
          ),
          name: this.cameraAlertSettingsComponent.selectRecipientsComponent.createNotificationName.value,
          organisationId: this.organisationId,
        }),
      );
    } else {
      emails = this.getRecipientsEmails(this.cameraAlertSettingsComponent.selectRecipientsComponent.selectedRecipients);
      if (emails?.length !== 0) {
        offlineAlert.emails = emails;
      }
      requests.push(this.alertService.putAlert(offlineAlert));
    }
    if (this.editing) {
      this.offlineAlertNotificationGroupLinks.forEach((offlineAlertNotificationGroupLink) => {
        requests.push(
          this.alertNotificationGroupMappingService.deleteAlertNotificationGroupMapping(
            offlineAlertNotificationGroupLink.id,
          ),
        );
      });
    }
    forkJoin(requests)
      .pipe(
        switchMap((response) => {
          const requests: Observable<any>[] = [];
          if (
            this.cameraAlertSettingsComponent.selectRecipientsComponent.createNotificationCheck &&
            this.cameraAlertSettingsComponent.selectRecipientsComponent.selectedRecipients.length !== 1
          ) {
            requests.push(
              this.alertNotificationGroupMappingService.putAlertNotificationGroupMapping({
                alertId: offlineAlert.id ? offlineAlert.id : response[0].id,
                notificationGroupId: response[1].id,
              }),
            );
            this.getRecipientsNotificationGroupsIDs(
              this.cameraAlertSettingsComponent.selectRecipientsComponent.selectedRecipients,
            ).forEach((notificationGroupId) => {
              requests.push(
                this.notificationGroupMappingService.putNotificationGroupNotificationGroup({
                  organisationId: this.organisationId,
                  parentId: response[1].id,
                  childId: notificationGroupId,
                }),
              );
            });
          } else {
            this.getRecipientsNotificationGroupsIDs(
              this.cameraAlertSettingsComponent.selectRecipientsComponent.selectedRecipients,
            ).forEach((notificationGroupId) => {
              requests.push(
                this.alertNotificationGroupMappingService.putAlertNotificationGroupMapping({
                  alertId: offlineAlert.id ? offlineAlert.id : response[0].id,
                  notificationGroupId: notificationGroupId,
                }),
              );
            });
          }
          if (this.editing && this.cameraAlertAndSummaryNameAndSitesComponent.alertMode === 'site') {
            // type offlineAlertCameraPosition
            const linksToDelete = [...this.offlineAlertCameraPositions];
            // type Camera
            const linksToAdd = [...this.cameraAlertAndSummaryCamerasComponent.selection.selected];
            linksToDelete.forEach((offlineAlertCameraPosition: AlertCameraPositionMapping, indexDelete) => {
              const indexAdd = linksToAdd.findIndex(
                (camera: Camera) => camera?.cameraPositionId === offlineAlertCameraPosition.cameraPositionId,
              );
              if (indexAdd != -1) {
                delete linksToDelete[indexDelete];
                delete linksToAdd[indexAdd];
              }
            });
            // delete monitor links
            linksToDelete.forEach((offlineAlertCameraPosition: AlertCameraPositionMapping) => {
              requests.push(
                this.alertCameraPositionMappingService.deleteAlertCameraPositionMapping(offlineAlertCameraPosition.id),
              );
            });
            // add monitor links
            linksToAdd.forEach((camera: Camera) => {
              requests.push(
                this.alertCameraPositionMappingService.putAlertCameraPositionMapping({
                  cameraPositionId: camera.cameraPositionId,
                  alertId: offlineAlert.id,
                }),
              );
            });
          } else {
            if (
              this.cameraAlertAndSummaryNameAndSitesComponent.alertMode === 'site' &&
              !this.cameraAlertAndSummaryCamerasComponent.isAllSelected()
            ) {
              this.cameraAlertAndSummaryCamerasComponent.selection.selected.forEach((camera) =>
                requests.push(
                  this.alertCameraPositionMappingService.putAlertCameraPositionMapping({
                    cameraPositionId: camera.cameraPositionId,
                    alertId: response[0].id,
                  }),
                ),
              );
            }
          }
          return forkJoin(requests);
        }),
        finalize(() => {
          // this.saving = false;
          // if (this.editing) {
          this.close(true);
          // }
          // this.stepper.next();
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (_) => {
          // if (this.editing) {
          this.close(true);
          // }
          // this.stepper.next();
        },
        error: (error) => {
          this.notifyService.error(error);
        },
      });
  }

  close(reload?: boolean): void {
    if (reload) {
      this.dialogRef.close('reload');
    } else {
      this.dialogRef.close();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
