<div class="c-occupancy-row" (click)="openDialog()">
  <div class="c-occupancy-row__title">
    <div class="minion--caps is-site u-flush--bottom">{{ site.name }}</div>
    <div class="is-monitor-name primer kirk u-flush--bottom">{{ occupancyMonitor.name }}</div>

    <div class="is-safe-limit minion u-hard--bottom">
      Safe occupancy: {{ occupancyMonitor.capacity ? occupancyMonitor.capacity : '-' }}
    </div>
  </div>
  <div class="c-occupancy-row__occupancy">
    <ng-container *ngIf="!occupancyMonitor.capacity; else occupancyPie">
      <div class="c-pie-chart" *ngIf="!isLoading">
        <div class="c-pie-chart__background" [style.backgroundColor]="'var(--error-color--light)'">
          {{ this.realTimeData.data.length ? this.realTimeData.currentOccupancy : '-' }}
        </div>
      </div>
      <div class="has-no-capacity-set">
        <mat-icon class="is-icon"> warning </mat-icon>
        Capacity not set
      </div>
    </ng-container>
    <ng-template #occupancyPie>
      <div class="c-pie-chart" *ngIf="!isLoading">
        <div
          class="c-pie-chart__background"
          [style.backgroundColor]="
            occupancyPercentage < 75
              ? 'var(--success-color--light)'
              : occupancyPercentage < 90
                ? 'var(--warning-color--light)'
                : 'var(--error-color--light)'
          "
        >
          {{ this.realTimeData.data.length ? this.realTimeData.currentOccupancy : '-' }}
        </div>
        <ngx-charts-pie-chart
          [view]="[127, 127]"
          [scheme]="colorSchemePieChart"
          [results]="pieChartResults"
          [tooltipDisabled]="true"
          [legend]="false"
          [animations]="false"
          [gradient]="true"
          [doughnut]="true"
        ></ngx-charts-pie-chart>
      </div>
    </ng-template>

    <div class="is-last-update minion u-flush--bottom">
      {{ isLoading || !realTimeData.data.length ? '' : 'As of ' + lastUpdate }}
    </div>
  </div>
  <div
    class="c-occupancy-row__chart"
    [class.c-show-chart-mobile]="showChartForMobile"
    [ngClass]="{ 'is-loading': isLoading || !realTimeData.data.length }"
    #chartArea
    (window:resize)="setViewSize()"
  >
    <div class="dot-flashing" *ngIf="isLoading; else notLoadingData"></div>
    <ng-template #notLoadingData>
      <ngx-charts-line-chart
        *ngIf="realTimeData.data.length; else noRealTimeData"
        [view]="view"
        [animations]="false"
        [scheme]="colorSchemeLineChart"
        [results]="lineChartResults"
        [xAxis]="true"
        [yAxis]="true"
        [xAxisTicks]="xAxisTicks"
        [yScaleMin]="0"
        [yScaleMax]="yScaleMax"
        [xScaleMin]="xScaleMin"
        [xScaleMax]="xScaleMax"
        [xAxisTickFormatting]="dayFormatting"
        [showRefLines]="true"
        [referenceLines]="referenceLines"
      >
        <ng-template #tooltipTemplate let-model="model">
          occupancy at {{ model.name | date: 'short' }} - {{ model.value }}
        </ng-template>
        <ng-template #seriesTooltipTemplate let-model="model">
          occupancy at {{ model[0].name | date: 'short' }} - {{ model[0].value }}
        </ng-template>
      </ngx-charts-line-chart>
      <ng-template #noRealTimeData>
        <div class="c-capacity-not-set-container">
          <mat-icon class="c-icon-warning"> warning </mat-icon>
          No data available
        </div>
      </ng-template>
    </ng-template>
  </div>
</div>
