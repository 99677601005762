import { SelectionModel } from '@angular/cdk/collections';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { filter, take } from 'rxjs';
import { Site } from 'src/app/api';
import { PeopleCountMonitorStatus } from 'src/app/model/monitorStatus';
import { ReportsService } from 'src/app/services/reports.service';

const loadNextMonitorsQuantity = 10;
const loadNextMonitorsQuantityMobile = 10;
@Component({
  selector: 'app-people-count-all-monitors-dialog',
  templateUrl: './people-count-all-monitors-dialog.component.html',
  styleUrls: ['./people-count-all-monitors-dialog.component.scss'],
})
export class PeopleCountAllMonitorsDialogComponent implements OnInit, AfterViewInit {
  peopleCountMonitors: PeopleCountMonitorStatus[] = [];
  filteredPeopleCountMonitorCards: PeopleCountMonitorStatus[] = [];
  filteredPeopleCountMonitorCharts: PeopleCountMonitorStatus[] = [];
  sitesMap: { [_: string]: Site } = {};
  isLoaded = false;
  searchString = '';
  peopleCountMonitorDataWeeklyTimeEndDate;

  selection = new SelectionModel<string>(true, []);
  mobileBreakPoint = '(max-width: 768px)';
  isMobile: boolean;

  activePeopleCountMonitorsLimit = loadNextMonitorsQuantity;
  activePeopleCountMonitorsLimitMobile = loadNextMonitorsQuantityMobile;
  monitorDetailsCardsList: ElementRef;
  @ViewChild('monitorDetailsCardsList') set setMonitorDetailsCardsList(content: ElementRef) {
    if (content) {
      this.monitorDetailsCardsList = content;
    }
  }
  monitorCardsList: ElementRef;
  @ViewChild('monitorCardsList') set setMonitorCardsList(content: ElementRef) {
    if (content) {
      this.monitorCardsList = content;
    }
  }
  showLeftScroll;
  showRightScroll;

  constructor(
    public reportsService: ReportsService,
    private ref: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
  ) {}

  ngOnInit(): void {
    this.sitesMap = this.reportsService.sitesMap;
    this.peopleCountMonitors = this.reportsService.peopleCountMonitors;

    if (this.reportsService.peopleCountMonitorDataWeeklyTime)
      this.peopleCountMonitorDataWeeklyTimeEndDate ==
        new Date(this.reportsService.peopleCountMonitorDataWeeklyTime.getTime() + 6 * 24 * 60 * 60 * 1000);
    this.peopleCountMonitors.slice(0, 3).forEach((monitor) => {
      this.selection.select(monitor.id);
    });

    this.selection.changed.subscribe((_) => {
      this.filterMonitors();
    });

    this.breakpointObserver.observe([this.mobileBreakPoint]).subscribe(() => this.breakpointChanged());
  }

  ngAfterViewInit(): void {
    this.reportsService.isLoadingPeopleCountOrderData
      .pipe(
        filter((loading) => !loading),
        take(1),
      )
      .subscribe({
        next: (_) => {
          this.onMonitorDetailsCardsListScroll();
        },
      });
  }

  private breakpointChanged(): void {
    this.isMobile = this.breakpointObserver.isMatched(this.mobileBreakPoint);
    this.filterMonitors();
  }

  filterMonitors(searchString?: string): void {
    if (searchString !== undefined) {
      this.searchString = searchString.toLowerCase();
    }
    if (!this.isMobile) {
      this.filteredPeopleCountMonitorCards = this.peopleCountMonitors
        .filter(
          (m) =>
            m.id.includes(this.searchString) ||
            m.name?.toLowerCase().includes(this.searchString) ||
            this.sitesMap[m.siteId]?.name.toLowerCase().includes(this.searchString),
        )
        .slice(0, this.activePeopleCountMonitorsLimit);

      this.filteredPeopleCountMonitorCharts = this.filteredPeopleCountMonitorCards.filter(
        (m) =>
          this.selection.isSelected(m.id) &&
          (m.id.includes(this.searchString) ||
            m.name?.toLowerCase().includes(this.searchString) ||
            this.sitesMap[m.siteId]?.name.toLowerCase().includes(this.searchString)),
      );
    } else {
      this.filteredPeopleCountMonitorCharts = this.peopleCountMonitors
        .filter(
          (m) =>
            m.id.includes(this.searchString) ||
            m.name?.toLowerCase().includes(this.searchString) ||
            this.sitesMap[m.siteId]?.name.toLowerCase().includes(this.searchString),
        )
        .slice(0, this.activePeopleCountMonitorsLimitMobile);
    }
  }

  onMonitorDetailsCardsListScroll(): void {
    const element = this.monitorDetailsCardsList.nativeElement;
    const isScrolledToBottom = Math.abs(element.scrollWidth - element.scrollLeft - element.clientWidth) <= 2;
    const isAllCamerasLoaded = this.activePeopleCountMonitorsLimit >= this.peopleCountMonitors.length;
    this.showLeftScroll = element.scrollLeft > 0;
    this.showRightScroll = !isScrolledToBottom || !isAllCamerasLoaded;
    if (isScrolledToBottom && !isAllCamerasLoaded) {
      this.activePeopleCountMonitorsLimit += loadNextMonitorsQuantity;
      this.filterMonitors();
    }
  }

  scrollRight(): void {
    this.monitorDetailsCardsList.nativeElement.scrollTo({
      left:
        this.monitorDetailsCardsList.nativeElement.scrollLeft +
        this.monitorDetailsCardsList.nativeElement.clientWidth * 0.85,
      behavior: 'smooth',
    });
  }

  scrollLeft(): void {
    this.monitorDetailsCardsList.nativeElement.scrollTo({
      left:
        this.monitorDetailsCardsList.nativeElement.scrollLeft -
        this.monitorDetailsCardsList.nativeElement.clientWidth * 0.85,
      behavior: 'smooth',
    });
  }

  onMonitorCardsListScroll(): void {
    if (!this.isMobile) {
      return;
    }

    const element = this.monitorCardsList.nativeElement;
    const isScrolledToBottom = Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) <= 2;
    const isAllCamerasLoaded = this.activePeopleCountMonitorsLimitMobile >= this.peopleCountMonitors.length;
    if (isScrolledToBottom && !isAllCamerasLoaded) {
      this.activePeopleCountMonitorsLimitMobile += loadNextMonitorsQuantityMobile;
      this.filterMonitors();
    }
  }

  updatePeopleCountMonitorDataWeeklyTime() {
    this.peopleCountMonitorDataWeeklyTimeEndDate = new Date(
      this.reportsService.peopleCountMonitorDataWeeklyTime.getTime() + 6 * 24 * 60 * 60 * 1000,
    );

    this.toggleTimingPeopleCount(false);
    this.ref.detectChanges();
  }

  toggleTimingPeopleCount(value: boolean): void {
    this.reportsService.isPeopleCountDaily = value;
    this.reportsService.getPeopleCountMonitorOrderData();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.peopleCountMonitors.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.peopleCountMonitors.forEach((m) => this.selection.select(m.id));
  }

  getSelectedIds(): string {
    return encodeURIComponent(this.selection.selected.join(','));
  }

  close(): void {
    history.back();
  }
}
