<div class="o-step-body">
  <div class="o-form u-padding--inline">
    <div *ngIf="showOrganisationSelect">
      <div class="c-inline-icon-text u-margin--bottom">
        <mat-icon class="u-grey-color"> shield </mat-icon>
        <h3 class="primer kirk u-flush--bottom">Organisation</h3>
      </div>
      <mat-form-field>
        <mat-label>Select an organisation</mat-label>
        <mat-select ngDefaultControl (selectionChange)="filterSitesByOrganisation()" [(ngModel)]="selectedOrganisation">
          <mat-option *ngFor="let organisation of accountService.organisations" [value]="organisation">
            {{ organisation.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div>
      <div class="c-inline-icon-text u-margin--bottom">
        <mat-icon class="u-grey-color">label</mat-icon>
        <div>
          <h3 class="primer kirk u-flush--bottom">Occupancy alert name</h3>
          <h2 class="brevier u-flush--bottom type--secondary">
            It's helpful for this to be descriptive if you have lots of alerts
          </h2>
        </div>
      </div>
      <mat-form-field>
        <mat-label>Alert name</mat-label>
        <input autocomplete="off" matInput placeholder="Occupancy alert name" [(ngModel)]="alertName" />
      </mat-form-field>
    </div>

    <div>
      <div class="c-inline-icon-text u-margin--bottom">
        <mat-icon class="u-grey-color"> location_on </mat-icon>
        <h3 class="primer kirk u-flush--bottom">Where will this alert be used?</h3>
      </div>

      <mat-radio-group class="o-primary-radio-group is-input" [(ngModel)]="alertMode">
        <mat-radio-button [value]="'organisaiton'">
          <div class="is-primary-text">All occupancy monitors at all sites.</div>
          <div class="is-secondary-text">This will include sites and monitors added in future.</div>
        </mat-radio-button>
        <mat-radio-button [value]="'site'">
          <div class="is-primary-text">Occupancy monitors at one site.</div>
          <div class="is-secondary-text">Select any combination of occupancy monitors at this site.</div>
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div *ngIf="alertMode === 'site'">
      <div class="c-inline-icon-text u-margin--bottom">
        <mat-icon class="u-grey-color">business</mat-icon>
        <h3 class="primer kirk u-flush--bottom">Select the site to use this alert at</h3>
      </div>
      <ul class="o-list c-sites-list" *ngIf="filteredSites.length; else noSites">
        <mat-radio-group [(ngModel)]="selectedSite">
          <li class="o-list__item" *ngFor="let site of filteredSites">
            <mat-radio-button [value]="site">
              {{ site.name }}
            </mat-radio-button>
          </li>
        </mat-radio-group>
      </ul>
      <ng-template #noSites>
        <app-no-site-to-select (reloadEvent)="reloadEvent.emit()"></app-no-site-to-select>
      </ng-template>
    </div>
  </div>
</div>
