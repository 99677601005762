import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, DoCheck, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import * as moment from 'moment';
import { Subject, Observable, forkJoin, finalize, takeUntil } from 'rxjs';
import { OccupancyMonitorCameraPosition, Site, OccupancyReport, ReportService } from 'src/app/api';
import { UserConfirmationComponent } from 'src/app/components/general/user-confirmation/user-confirmation.component';
import { GlobalMethods } from 'src/app/global-methods';
import { OccupancyMonitorStatus } from 'src/app/model/monitorStatus';
import { AccountService } from 'src/app/services/account.service';
import { DownloadFileService } from 'src/app/services/download-file.service';
import { NotifyService } from 'src/app/services/notify.service';
import { ReportsService } from 'src/app/services/reports.service';

declare var gtag: Function;

@Component({
  selector: 'app-download-occupancy-data',
  templateUrl: './download-occupancy-data.component.html',
  styleUrls: ['./download-occupancy-data.component.scss'],
})
export class DownloadOccupancyDataComponent implements OnInit, OnDestroy, DoCheck {
  occupancyMonitors: OccupancyMonitorStatus[] = [];
  occupancyMonitorCameraPositionsMap: { [_: string]: OccupancyMonitorCameraPosition[] } = {};
  occupancyMonitorsMap: { [_: string]: OccupancyMonitorStatus } = {};

  sitesMap: { [_: string]: Site } = {};

  downloading: boolean;
  downloadButtonText: string;
  downloadBannerMsg = '';
  ngUnsubscribe = new Subject();

  selectedTimeGrain: OccupancyReport.TimeGrainEnum = '1h';
  timeGrains = Object.values(OccupancyReport.TimeGrainEnum);

  metrics = Object.values(OccupancyReport.MetricsEnum);
  metricsSelection = new SelectionModel<OccupancyReport.MetricsEnum>(true, []);

  currentDateTimeMidnight = new Date(new Date().setHours(0, 0, 0, 0));
  range = new FormGroup({
    from: new FormControl(this.currentDateTimeMidnight),
    to: new FormControl(this.currentDateTimeMidnight),
  });

  convertDataToSiteTimezone = true;
  payloadToBig = true;
  useOpeningHours = false;

  selection = new SelectionModel<string>(true, []);
  tableDataSource = new MatTableDataSource<any>();
  tableHeaderNames = ['select', 'monitorName', 'siteName', 'cameras', 'connectivity'];
  noMonitorSelectedError = false;

  @ViewChild('tablePaginator') set paginator(pager: MatPaginator) {
    if (pager) {
      this.tableDataSource.paginator = pager;
    }
  }

  constructor(
    private df: DownloadFileService,
    public accountService: AccountService,
    private reportsService: ReportsService,
    private notifyService: NotifyService,
    private reportService: ReportService,
    private matDialog: MatDialog,
    private ref: ChangeDetectorRef,
  ) {}

  ngDoCheck(): void {
    const timeRangeInMinutes = (Number(this.range.value.to) - Number(this.range.value.from)) / 60000 + 1440; // round date up so that the upper date is included in returned data
    // (time_range_in_mins/granularity_in_mins)*no_of_monitors > 30*24*60
    if (
      (timeRangeInMinutes / GlobalMethods.timeGrainToMinutes(this.selectedTimeGrain)) * this.selection.selected.length >
      30 * 24 * 60
    ) {
      this.payloadToBig = true;
      this.downloadBannerMsg = `The requested data is too big. Try reducing the number of days or monitors selected, or increasing the granularity`;
    } else {
      this.payloadToBig = false;
      if (this.downloadBannerMsg) {
        this.downloadBannerMsg = `Report ready to be downloaded`;
      }
    }
  }

  ngOnInit(): void {
    this.occupancyMonitors = this.reportsService.occupancyMonitors;
    this.occupancyMonitorCameraPositionsMap = this.reportsService.occupancyMonitorCameraPositionsMap;
    this.occupancyMonitorsMap = this.reportsService.occupancyMonitorsMap;
    this.sitesMap = this.reportsService.sitesMap;

    const dataSource: { [_: string]: string }[] = [];
    this.occupancyMonitors.forEach((occupancyMonitor) => {
      dataSource.push({
        id: occupancyMonitor.id,
        monitorName: occupancyMonitor.name,
        siteName: this.sitesMap[occupancyMonitor.siteId].name,
        cameras: `${this.occupancyMonitorCameraPositionsMap[occupancyMonitor.id].length} ${GlobalMethods.pluraliseWord(
          this.occupancyMonitorCameraPositionsMap[occupancyMonitor.id].length,
          'camera',
        )}`,
        connectivity:
          this.occupancyMonitorCameraPositionsMap[occupancyMonitor.id].length === 0
            ? 'No cameras linked'
            : occupancyMonitor.status === 'online'
              ? 'Online'
              : 'Camera issues',
        disabled: this.occupancyMonitorCameraPositionsMap[occupancyMonitor.id].length === 0 ? 'true' : 'false',
      });
    });
    this.tableDataSource.data = dataSource;
    this.setDownloading(false);
  }

  runSearch(ss: string): void {
    this.tableDataSource.filter = ss.trim().toLowerCase();
  }

  downloadCSV(toolTip?: MatTooltip) {
    if (this.selection.selected.length === 0) {
      this.noMonitorSelectedError = true;
      toolTip.disabled = false;
      toolTip.show();
      return;
    }
    if (this.downloading) {
      return;
    }

    if (this.metricsSelection.selected.length === 0) {
      this.notifyService.warning('Select a metric for the report.');
      return;
    }

    this.noMonitorSelectedError = false;

    const roundedEndDate = new Date(this.range.value.to);
    roundedEndDate.setDate(roundedEndDate.getDate() + 1); // round date up so that the upper date is included in returned data
    const isoStartTime = this.range.value.from.toISOString();
    const isoEndTime = roundedEndDate.toISOString();

    gtag('event', 'file_download', {
      file_name: 'occupancy_report',
      csv_start_time: isoStartTime,
      csv_end_time: isoEndTime,
      monitors: this.selection.selected,
    });

    this.setDownloading(true);

    const requests: Observable<any>[] = [];
    const tzRequests = {};
    const tzList = [];
    this.selection.selected.forEach((occupancyMonitorId) => {
      const siteTz = this.sitesMap[this.occupancyMonitorsMap[occupancyMonitorId].siteId].tz;
      if (siteTz in tzRequests) {
        tzRequests[siteTz].push(occupancyMonitorId);
      } else {
        tzRequests[siteTz] = [occupancyMonitorId];
        tzList.push(siteTz);
      }
    });

    tzList.forEach((tz) => {
      requests.push(
        this.reportService.createOccupancyReport({
          occupancyMonitorIds: tzRequests[tz],
          startDate: moment(this.range.value.from).format('YYYY-MM-DD'),
          endDate: moment(roundedEndDate).format('YYYY-MM-DD'),
          metrics: this.metricsSelection.selected,
          timeGrain: this.selectedTimeGrain,
          isOpen: this.useOpeningHours,
        }),
      );
    });

    forkJoin(requests)
      .pipe(
        finalize(() => {
          this.setDownloading(false);
          this.ref.detectChanges();
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (reports) => {
          var reportForDownload = [];

          if (this.convertDataToSiteTimezone) {
            reports.forEach((report, index) => {
              report.forEach((row) => {
                reportForDownload.push({
                  ...row,
                  occupancyMonitorName: this.occupancyMonitorsMap[row.occupancyMonitorId].name,
                  time: GlobalMethods.changeTimezone(row['time'], tzList[index]),
                });
              });
            });
          } else {
            reports.forEach((report) => {
              report.forEach((row) => {
                row['occupancyMonitorName'] = this.occupancyMonitorsMap[row.occupancyMonitorId].name;
              });
              reportForDownload = [...reportForDownload, ...report];
            });
          }

          if (reportForDownload.length === 0) {
            this.notifyService.warning('No data available for the selected monitors.');
            return;
          }

          let header = new Set<string>(['time', 'occupancyMonitorId', 'occupancyMonitorName']);
          reportForDownload.forEach((r) => Object.keys(r).forEach((k) => header.add(k)));

          this.df.downloadDataAsCSVFile(reportForDownload, `MonitorsData.csv`, [...header]);
        },
        error: (error) => {
          this.notifyService.error(error);
        },
      });
  }

  openUserConfirmation(): void {
    const messages = `You can use the site opening hours to filter the monitors data.

    This allows you to refine the data, offering a deeper understanding
    of occupancy based on the operational hours of your sites.`;

    this.matDialog.open(UserConfirmationComponent, {
      data: { message: messages, isDelete: true },
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableDataSource.data.filter((row) => row.disabled !== 'true').length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.tableDataSource.data.forEach((row) => {
          if (row.disabled !== 'true') {
            this.selection.select(row.id);
          }
        });
  }

  setDownloading(state: boolean) {
    this.downloading = state;
    this.setDownloadButtonText();
  }

  setDownloadButtonText() {
    this.downloadButtonText = this.downloading ? 'Downloading...' : 'Download csv';
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
