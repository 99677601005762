import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar-delete.component.html',
  styleUrls: ['./snackbar-delete.component.scss'],
})
export class SnackbarDeleteComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<SnackbarDeleteComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
  ) {}
}
