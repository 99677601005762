<div class="c-add-camera-alerts-dialog">
  <app-filter
    (search)="filterAlertRules($event)"
    class="c-search o-search__input"
    placeholder="Search {{ resourceType === 'summary' ? 'health summaries' : 'alerts' }}"
  ></app-filter>

  <ul class="o-notification-list c-notification-list has-divider">
    <ng-container *ngFor="let resource of filteredResources">
      <li class="o-notification-list__item c-notification-list__item">
        <mat-checkbox
          (change)="selection.toggle(resource.id)"
          [checked]="selection.isSelected(resource.id)"
          class="is-checkbox"
        ></mat-checkbox>
        <div class="c-text-name is-text">{{ resource.name }}</div>
      </li>
    </ng-container>
  </ul>
  <div class="c-save-button-wrapper">
    <button
      (click)="onSaveButtonClick()"
      class="o-button c-button-save"
      [class.is-muted]="selection.isEmpty()"
      [disabled]="selection.isEmpty()"
    >
      SAVE
    </button>
  </div>
</div>
