import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class DownloadFileService {
  downloadCSVFile(data, filename): void {
    const blob = new Blob([data], { type: 'text/csv' });
    saveAs(blob, filename);
  }

  downloadDataAsCSVFile(data: any[], filename: string, header?: string[]) {
    const replacer = (_, value) => (value === null ? '' : value);

    if (data.length !== 0) {
      if (!header) {
        header = Object.keys(data[0]);
      }
    }
    let csv = data.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob([csvArray], { type: 'text/csv' });
    saveAs(blob, filename);
  }
}
