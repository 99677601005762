import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { filter, map } from 'rxjs';
import { AccountService } from 'src/app/services/account.service';

export const SupportGuard: CanActivateFn = (route, state) => {
  const accountService = inject(AccountService);

  return accountService.isLoading$.pipe(
    filter((isLoading) => !isLoading),
    map(() => accountService.isSupport),
  );
};
