<div class="auth-overlay" *ngIf="isLoading; else notLoading">
  <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
    <p style="font-size: 2rem; font-weight: 500">Loading monitor details...</p>
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>
<ng-template #notLoading>
  <div>
    <div class="c-grid-section">
      <div class="o-feature-card">
        <div class="o-feature-card__head">
          <span class="material-icons">transfer_within_a_station</span>
          <div class="is-title is-reverse">
            <div>People count monitor</div>
            <div>{{ monitor.name }}</div>
          </div>
        </div>

        <ul class="o-list is-compact is-light" style="border-bottom: 1px solid white">
          <li class="o-list__item">
            <div class="o-list__item-icon">
              <span class="material-icons">apartment</span>
            </div>
            <div class="o-list__item-text">{{ site.name }}</div>
          </li>
          <li class="o-list__item">
            <div class="o-list__item-icon">
              <span class="material-icons">photo_camera</span>
            </div>
            <div class="o-list__item-text">{{ monitorCameras.length | pluraliseWord: 'Camera' }}</div>
            <div class="o-list__item-secondary">
              {{ monitorCameras.length }}
            </div>
          </li>
        </ul>
        <ul class="o-list is-compact is-light">
          <li class="o-list__item">
            <div class="o-list__item-icon">
              <span class="material-icons">directions</span>
            </div>
            <div class="o-list__item-text" style="line-height: 2">
              <div class="primer kirk u-flush--bottom">Monitor directions</div>
              <div>Direction 1: {{ monitor.direction1Alias }}</div>
              <div>Direction 2: {{ monitor.direction2Alias }}</div>
            </div>
          </li>
          <li
            class="o-list__item"
            *ngIf="accountService.isSupport && (monitor.reportingStartDate || monitor.reportingEndDate)"
          >
            <div class="o-list__item-icon">
              <span class="material-icons">event</span>
            </div>
            <div class="o-list__item-text" style="line-height: 2">
              <ng-container *ngIf="monitor.reportingStartDate">
                <div class="primer kirk u-flush--bottom">Reporting start date</div>
                <div>{{ monitor.reportingStartDate | date: 'dd/MM/yyyy' }}</div>
              </ng-container>
              <ng-container *ngIf="monitor.reportingEndDate">
                <div class="primer kirk u-flush--bottom">Reporting end date</div>
                <div>{{ monitor.reportingEndDate | date: 'dd/MM/yyyy' }}</div>
              </ng-container>
            </div>
          </li>
        </ul>

        <div class="o-feature-card__action">
          <button class="o-button is-ghost" (click)="openEditPeopleCountMonitorDialog()">
            Edit monitor
            <span class="material-icons is-icon-right">arrow_forward</span>
          </button>
        </div>
      </div>

      <div class="c-monitor-summary">
        <div class="c-monitor-summary__counts">
          <h3 class="long-primer kirk">Today</h3>

          <div class="c-today-count">
            <div class="u-caps minion u-flush--bottom">{{ monitor.direction1Alias }}</div>
            <div>{{ isLoadingChart ? '-' : direction1Today[String(this.useOpeningHours)] }}</div>
          </div>

          <div class="c-today-count">
            <div class="u-caps minion u-flush--bottom">{{ monitor.direction2Alias }}</div>
            <div>{{ isLoadingChart ? '-' : direction2Today[String(this.useOpeningHours)] }}</div>
          </div>

          <div class="c-today-count">
            <div class="u-caps minion u-flush--bottom">Both directions</div>
            <div>
              {{
                isLoadingChart
                  ? '-'
                  : direction1Today[String(this.useOpeningHours)] + direction2Today[String(this.useOpeningHours)]
              }}
            </div>
          </div>

          <div class="dot-flashing" *ngIf="isLoadingChart"></div>
          <div class="is-action">
            <a class="o-button is-contained is-small" [routerLink]="['/reporting', 'people-count']">
              More reporting
              <mat-icon class="is-icon-right">arrow_forward</mat-icon>
            </a>
          </div>
        </div>
        <div class="c-monitor-summary__chart">
          <div class="is-header">
            <div class="o-heading--primer">
              <h3>Last 7 days</h3>
              <div class="is-legend">
                <span class="is-direction-1">{{ monitor.direction1Alias }}</span>
                <span class="is-direction-2">{{ monitor.direction2Alias }}</span>
                <span class="is-direction-both">Both</span>
              </div>
            </div>

            <span
              [matTooltip]="'There are no opening hours set for this site.'"
              [matTooltipDisabled]="!!site.openingHour"
            >
              <mat-checkbox [(ngModel)]="useOpeningHours" (change)="setChartData()" [disabled]="!site.openingHour"
                >Filter by opening hours</mat-checkbox
              >
            </span>
          </div>

          <div class="is-chart" #chart></div>
        </div>
      </div>
    </div>

    <div class="content-container">
      <div class="c-section-title">
        <h4 class="is-title">Monitor views</h4>
      </div>

      <div class="o-card-grid">
        <ng-container *ngFor="let camera of monitorCameras">
          <div class="o-monitor-view">
            <div class="o-monitor-view__image">
              <ng-container *ngIf="camera.state === 'running'; else noImage">
                <ng-container *ngIf="isLoadingCameraFrame[camera.id]; else notLoadingImg">
                  <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
                    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
                  </div>
                </ng-container>
              </ng-container>

              <ng-template #notLoadingImg>
                <ng-container *ngIf="cameraFrame.hasOwnProperty(camera.id); else noImage">
                  <div class="o-monitor-view__image-wrap">
                    <img [src]="cameraFrame[camera.id]" alt="camera view" />
                    <ng-container *ngIf="!camera.isPassingTraffic; else passTraffCrossingLine">
                      <svg height="100%" width="100%">
                        <line x1="0%" y1="50%" x2="100%" y2="50%" stroke="rgb(255,0,0)" stroke-width="2" />
                      </svg>
                    </ng-container>
                    <ng-template #passTraffCrossingLine>
                      <svg height="100%" width="100%">
                        <line x1="50%" y1="0%" x2="50%" y2="100%" stroke="rgb(255,0,0)" stroke-width="2" />
                      </svg>
                    </ng-template>
                  </div>
                  <div
                    class="o-monitor-view__focus-direction"
                    [ngClass]="{
                      'is-left':
                        camera.isPassingTraffic &&
                        camerasPositions[camera.cameraPositionId].direction1 === 'direction_1',
                      'is-right':
                        camera.isPassingTraffic &&
                        camerasPositions[camera.cameraPositionId].direction1 === 'direction_2',
                      'is-top':
                        !camera.isPassingTraffic &&
                        camerasPositions[camera.cameraPositionId].direction1 === 'direction_1',
                      'is-bottom':
                        !camera.isPassingTraffic &&
                        camerasPositions[camera.cameraPositionId].direction1 === 'direction_2',
                    }"
                  >
                    <div class="is-arrow">
                      <mat-icon class="is-icon">{{
                        camera.isPassingTraffic
                          ? camerasPositions[camera.cameraPositionId].direction1 === 'direction_1'
                            ? 'arrow_back'
                            : 'arrow_forward'
                          : camerasPositions[camera.cameraPositionId].direction1 === 'direction_1'
                            ? 'north'
                            : 'south'
                      }}</mat-icon>
                    </div>
                    <span class="is-label">{{ monitor.direction1Alias }}</span>
                  </div>
                </ng-container>
              </ng-template>

              <ng-template #noImage>
                <div class="o-monitor-view__no-image">
                  <div *ngIf="camera.state !== 'running'" class="is-message">
                    <mat-icon class="material-icons">broken_image</mat-icon>
                    <p>Camera is not running.</p>
                  </div>
                  <div *ngIf="camera.state === 'running'" class="is-message">
                    <mat-icon class="material-icons">broken_image</mat-icon>
                    <p>Could not get camera view.</p>
                  </div>
                </div>
              </ng-template>
            </div>

            <div class="o-monitor-view__title">
              <div
                class="o-list-card__title-icon is-online"
                *ngIf="cameraFrame.hasOwnProperty(camera.id); else cameraOfflineIcon"
              >
                <mat-icon>photo_camera</mat-icon>
              </div>
              <ng-template #cameraOfflineIcon>
                <div class="o-list-card__title-icon is-offline">
                  <mat-icon>no_photography</mat-icon>
                </div>
              </ng-template>

              <div class="o-heading--primer u-flush--bottom">
                <h2>{{ camera.name }}</h2>
                <h3>{{ camera.type }}</h3>
              </div>
            </div>
            <div class="o-monitor-view__directions">
              <div class="o-monitor-view__direction">
                <div class="is-label">{{ camera.direction1Alias }}</div>
                <div
                  class="is-arrow"
                  [ngClass]="{
                    'is-in-direction': camerasPositions[camera.cameraPositionId].direction1 === 'direction_1',
                  }"
                >
                  <span class="material-icons"> {{ camera.isPassingTraffic ? 'arrow_back' : 'north' }}</span>
                </div>
              </div>
              <div class="o-monitor-view__direction">
                <div class="is-label">{{ camera.direction2Alias }}</div>
                <div
                  class="is-arrow"
                  [ngClass]="{
                    'is-in-direction': camerasPositions[camera.cameraPositionId].direction1 === 'direction_2',
                  }"
                >
                  <span class="material-icons"> {{ camera.isPassingTraffic ? 'arrow_forward' : 'south' }}</span>
                </div>
              </div>
            </div>
            <div class="o-monitor-view__action">
              <a class="o-button is-small is-action" [routerLink]="['/cameras/details/' + camera.id]">
                View camera
                <span class="material-icons is-icon-right"> arrow_forward </span>
              </a>
            </div>
          </div>
        </ng-container>
        <div class="o-card-grid" *ngIf="!monitorCameras.length">
          <div class="c-no-content">
            <h4 class="is-title">No cameras added.</h4>
            <div class="is-media">
              <img src="/assets/images/no-camera-placeholder.png" alt="No cameras" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
