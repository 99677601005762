import { Site } from 'src/app/api';

export interface SiteStatus extends Site {
  status?: SiteStatus.StatusEnum;
}

export namespace SiteStatus {
  export type StatusEnum = 'online' | 'issue';
  export const StatusEnum = {
    Online: 'online' as StatusEnum,
    Issue: 'issue' as StatusEnum,
  };
}
