<div class="o-card-grid has-border-bottom">
  <div class="o-list-card" *ngIf="issueCameras.length || pausedCameras.length">
    <div class="o-list-card__title">
      <div class="o-list-card__title-text">
        <h2>Camera health</h2>
        <p>{{ cameras.length }} {{ cameras.length | pluraliseWord: 'camera' }} installed</p>
      </div>
    </div>
    <div class="o-list-card-body">
      <div class="o-list-card-overflow u-margin--bottom">
        <div
          class="o-monitor-card__banner-small"
          [ngClass]="{ 'has-error': issueCameras.length, 'has-success': !issueCameras.length }"
        >
          <span class="minion--caps">
            <ng-container *ngIf="issueCameras.length; else noIssue">
              {{ issueCameras.length }} camera {{ issueCameras.length | pluraliseWord: 'issue' }}.
            </ng-container>
            <ng-template #noIssue> No camera issues. </ng-template>
          </span>
        </div>
        <ul class="o-list">
          <ng-container *ngFor="let camera of issueCameras">
            <li
              class="o-list__item is-link"
              [ngClass]="{ 'has-error': camera.urgency === 100 }"
              (click)="viewCameras(camera.id)"
            >
              <div class="o-list__item-icon">
                <mat-icon class="is-icon is-offline">no_photography</mat-icon>
              </div>
              <div class="o-list__item-text">
                <h2 class="is-primary-text">
                  {{ camera.name }}
                </h2>
                <h2 class="is-secondary-text type--capitalize">
                  {{ camera.status }}
                  <ng-container *ngIf="camera.lastSeenAt"> | Last seen {{ camera.lastSeenAt | dateAgo }}</ng-container>
                </h2>
              </div>
              <div class="o-list__item-secondary-icon">
                <mat-icon> chevron_right </mat-icon>
              </div>
            </li>
          </ng-container>
          <ng-container *ngFor="let camera of pausedCameras">
            <li class="o-list__item" (click)="viewCameras(camera.id)">
              <a class="o-list--is-link">
                <div class="o-list__item-icon">
                  <mat-icon class="is-icon">pause</mat-icon>
                </div>
                <div class="o-list__item-text">
                  <h2 class="is-primary-text">{{ camera.name }}</h2>
                </div>
                <div class="o-list__item-secondary-icon">
                  <mat-icon> chevron_right </mat-icon>
                </div>
              </a>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
  <div class="o-list-card">
    <div class="o-list-card__title">
      <div class="o-list-card__title-text">
        <h2>Cameras</h2>
        <h3>{{ cameras.length }} active {{ cameras.length | pluraliseWord: 'camera' }}</h3>
      </div>
    </div>
    <div class="o-list-card__body">
      <div class="o-list-card__overflow">
        <ul class="o-list">
          <li class="o-list__item is-link" *ngIf="inUseCameras.length !== 0" (click)="viewCameras()">
            <div class="o-list__item-icon">
              <mat-icon style="color: var(--color-primary)">photo_camera</mat-icon>
            </div>
            <div class="o-list__item-text">
              <span class="is-primary-text">
                {{ inUseCameras.length }} {{ inUseCameras.length | pluraliseWord: 'camera' }} in use
              </span>
            </div>
            <div class="o-list__item-secondary-icon">
              <mat-icon>chevron_right</mat-icon>
            </div>
          </li>
          <li class="o-list__item is-link" *ngIf="pendingCameras.length !== 0" (click)="viewCameras()">
            <div class="o-list__item-icon">
              <mat-icon style="color: var(--icon-color)">photo_camera</mat-icon>
            </div>
            <div class="o-list__item-text">
              <span class="is-primary-text">
                {{ pendingCameras.length }} {{ pendingCameras.length | pluraliseWord: 'camera' }} pending
              </span>
            </div>
            <div class="o-list__item-secondary-icon">
              <mat-icon>chevron_right</mat-icon>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="o-list-card__action">
      <div class="o-button has-outline" (click)="openCameraSetupPopup()">
        <mat-icon class="is-icon-left"> add_a_photo </mat-icon>
        Register camera
      </div>
    </div>
  </div>

  <div class="c-opening-hours">
    <ng-container *ngIf="site.openingHour; else noOpeningHours">
      <div class="is-heading brevier--bold">Opening hours</div>
      <div class="c-opening-hours__day" *ngFor="let day of daysOfWeek; index as i">
        <div class="is-day brevier u-flush--bottom">{{ day }}</div>
        <div class="is-hours minion--caps">
          {{ site.openingHour['weekly'][i] | formatOpeningHours }}
        </div>
      </div>
    </ng-container>
    <ng-template #noOpeningHours>
      <div class="c-opening-hours__not-set">
        <div class="primer kirk">Opening hours not set</div>

        <img class="is-image" src="/assets/images/client-auth-thumb.png" alt="Building" />

        <button class="o-button" (click)="editSite()">
          <mat-icon class="is-icon-left">more_time</mat-icon>Set opening hours
        </button>
      </div>
    </ng-template>
  </div>
</div>

<div>
  <div class="c-section-title is-title">
    <h2 class="primer kirk u-flush--bottom">Monitors</h2>
  </div>

  <div class="c-monitors-grid" [ngClass]="{ 'has-border-bottom': sites.length }">
    <ng-container *ngIf="hasOccupancyLicence">
      <div class="c-monitors-grid__item">
        <app-monitors-list-display
          [monitorIcon]="'groups'"
          [monitorTitle]="'Occupancy monitors'"
          [imgSrc]="'/assets/images/no-monitor--ocupancy.png'"
          [monitors]="occupancyMonitors"
          [monitorCameras]="occupancyMonitorsCameras"
          (viewMonitors)="viewMonitors('occupancy')"
          (createMonitor)="openNewOccupancyMonitorDialog()"
        ></app-monitors-list-display>
      </div>
    </ng-container>

    <div class="c-monitors-grid__item">
      <app-monitors-list-display
        [monitorIcon]="'transfer_within_a_station'"
        [monitorTitle]="'People count monitors'"
        [imgSrc]="'/assets/images/no-monitor--peoplecount.png'"
        [monitors]="peopleCountMonitors"
        [monitorCameras]="peopleCountMonitorsCameras"
        (viewMonitors)="viewMonitors('people_count')"
        (createMonitor)="openNewPeopleCountMonitorDialog()"
      ></app-monitors-list-display>
    </div>

    <div class="c-monitors-grid__item">
      <app-monitors-list-display
        [monitorIcon]="'fork_left'"
        [monitorTitle]="'Peel off monitors'"
        [imgSrc]="'/assets/images/no-monitor--peeloff.png'"
        [monitors]="peelOffMonitors"
        [monitorCameras]="peelOffMonitorsCameras"
        (viewMonitors)="viewMonitors('peel_off')"
        (createMonitor)="openNewPeelOffMonitorDialog()"
      ></app-monitors-list-display>
    </div>
  </div>

  <div *ngIf="sites.length">
    <div class="c-section-title is-title">
      <h2 class="primer kirk u-flush--bottom">Other sites</h2>
    </div>

    <div class="o-content-panel">
      <ul class="o-content-panel__body c-sites-list o-list">
        <ng-container *ngFor="let site of sites">
          <li class="o-list__item" (click)="viewSite(site.id)">
            <a class="o-list--is-link">
              <div class="o-list__item-icon is-site-name">
                <mat-icon class="is-icon u-primary-color">apartment</mat-icon>
              </div>
              <div class="o-list__item-text">
                <h2 class="is-primary-text">{{ site.name }}</h2>
              </div>
            </a>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>
