import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-comparison-dialog',
  templateUrl: './comparison-dialog.component.html',
  styleUrls: ['./comparison-dialog.component.scss'],
})
export class ComparisonDialogComponent implements OnInit {
  comparisonLevel = 0;
  saveAsDefault = false;
  @Output() comparisonLevelEmitter = new EventEmitter<any>();

  constructor(
    public dialogRef: MatDialogRef<ComparisonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.comparisonLevel = this.data.occupancyComparisonPercentage;
    this.saveAsDefault = this.data.saveAsDefault;
  }

  close(reload?: boolean): void {
    if (reload) {
      this.dialogRef.close('reload');
    } else {
      this.dialogRef.close();
    }
  }

  update() {
    this.comparisonLevelEmitter.emit({ comparisonLevel: this.comparisonLevel, saveAsDefault: this.saveAsDefault });
    this.dialogRef.close();
  }
}
