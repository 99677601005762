<div class="o-form">
  <h2 class="long-primer kirk">Check the details for your new occupancy monitor.</h2>

  <ul class="o-list c-monitor-details">
    <li class="o-list__item">
      <div class="o-list__item-icon">
        <mat-icon>view_compact</mat-icon>
      </div>
      <div class="o-list__item-text">
        <span class="is-secondary-text">Occupancy monitor</span>
        <span class="is-primary-text">{{ monitorDetails['name'] }}</span>
      </div>
    </li>
    <li class="o-list__item">
      <div class="o-list__item-icon">
        <mat-icon>people</mat-icon>
      </div>
      <div class="o-list__item-text">
        <span class="is-secondary-text">Capacity</span>
        <span class="is-primary-text">{{ monitorDetails['capacity'] }}</span>
      </div>
    </li>
    <li class="o-list__item">
      <div class="o-list__item-icon">
        <mat-icon>schedule</mat-icon>
      </div>
      <div class="o-list__item-text">
        <span class="is-secondary-text">Reset time</span>
        <span class="is-primary-text">{{ monitorDetails['dailyResetMin'] }}</span>
      </div>
    </li>
  </ul>

  <div class="c-cameras" [class.is-one-column]="cameras.length === 1">
    <div class="c-cameras__view" *ngFor="let camera of cameras">
      <div class="is-name">{{ camera.name }}</div>
      <div class="c-cameras__view-live" *ngIf="cameraFrame[camera.id]; else notRunningCameraFrame">
        <div class="c-cameras__view-live-wrap">
          <img [src]="cameraFrame[camera.id]" alt="camera view" />
          <ng-container *ngIf="camera.type !== 'Passing traffic'; else passTraffCrossingLine">
            <svg height="100%" width="100%" class="is-count-line">
              <line x1="0%" y1="50%" x2="100%" y2="50%" stroke="rgb(255,0,0)" stroke-width="2" />
            </svg>
          </ng-container>
          <ng-template #passTraffCrossingLine>
            <svg height="100%" width="100%" class="is-count-line">
              <line x1="50%" y1="0%" x2="50%" y2="100%" stroke="rgb(255,0,0)" stroke-width="2" />
            </svg>
          </ng-template>
        </div>
        <ng-container *ngTemplateOutlet="orientationSettingTemplate"></ng-container>
      </div>
      <ng-template #notRunningCameraFrame>
        <div class="c-cameras__view-offline">
          <div class="o-banner-alert c-cameras__view-offline__banner">
            <mat-icon class="is-icon"> warning</mat-icon>
            <div class="is-header">
              <b>Camera view is unavailable</b>
            </div>
          </div>
          <div class="c-cameras__view-offline__body">
            <ng-container *ngTemplateOutlet="orientationSettingTemplate"></ng-container>
          </div>
        </div>
      </ng-template>
      <ng-template #orientationSettingTemplate>
        <div class="c-cameras__directions is-confirmed" [class.is-horizontal]="camera.type === 'Passing traffic'">
          <ng-container *ngIf="camera.type !== 'Passing traffic'; else passTraffDirections">
            <div class="c-cameras__directions__box">
              <div class="is-alias">
                {{ camera.direction1 }}
              </div>
              <div class="is-direction">
                <div class="is-label">
                  {{ camera.reversed ? direction2 : direction1 }}
                </div>
                <mat-icon> arrow_upward </mat-icon>
              </div>
            </div>
            <div class="c-cameras__directions__box">
              <div class="is-alias">
                {{ camera.direction2 }}
              </div>
              <div class="is-direction right-side">
                <div class="is-label">
                  {{ camera.reversed ? direction1 : direction2 }}
                </div>
                <mat-icon> arrow_downward </mat-icon>
              </div>
            </div>
          </ng-container>
          <ng-template #passTraffDirections>
            <div class="c-cameras__directions__box">
              <div class="is-alias">
                {{ camera.direction2 }}
              </div>
              <div class="is-direction">
                <div class="is-label">
                  {{ camera.reversed ? direction1 : direction2 }}
                </div>
                <mat-icon> arrow_back </mat-icon>
              </div>
            </div>
            <div class="c-cameras__directions__box">
              <div class="is-alias">
                {{ camera.direction1 }}
              </div>
              <div class="is-direction right-side">
                <div class="is-label">
                  {{ camera.reversed ? direction2 : direction1 }}
                </div>
                <mat-icon> arrow_forward </mat-icon>
              </div>
            </div>
          </ng-template>
        </div>
      </ng-template>
    </div>
  </div>
</div>
