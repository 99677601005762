<mat-horizontal-stepper class="dialog-stepper" #stepper>
  <mat-step>
    <app-step
      [stepTitle]="stepsTitle()"
      [isBackable]="false"
      [disabledNextStep]="siteForm.invalid"
      (openNextStep)="validateFirstStep(50)"
      (closeStep)="close()"
    >
      <div class="c-modal-content">
        <app-site-setup-side-panel [site]="site"></app-site-setup-side-panel>
        <div class="c-modal-main">
          <form class="o-form is-constrained" [formGroup]="siteForm">
            <div class="is-site-img">
              <img class="is-image" src="/assets/images/client-auth-thumb.png" alt="Building" />
            </div>
            <div class="is-site-img">
              <div class="long-primer kirk" *ngIf="!update()">Create a new site for your organisation</div>
              <div class="long-primer kirk" *ngIf="update()">Update site</div>
            </div>
            <mat-form-field *ngIf="accountService.isSupport">
              <mat-label>Organisation</mat-label>
              <mat-select ngDefaultControl formControlName="organisationId" [disabled]="update()">
                <mat-option *ngFor="let organisation of accountService.organisations" [value]="organisation.id">
                  {{ organisation.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Site name</mat-label>
              <input autocomplete="off" matInput placeholder="Name" formControlName="name" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Site address</mat-label>
              <input autocomplete="off" matInput placeholder="Address" formControlName="address" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Site timezone</mat-label>
              <mat-select ngDefaultControl formControlName="tz">
                <mat-option *ngFor="let timezone of timezones" [value]="timezone">
                  {{ timezone }}
                </mat-option>
              </mat-select>
              <mat-icon matSuffix (click)="getUserTimeZone(); $event.stopPropagation()"> location_on </mat-icon>
            </mat-form-field>
          </form>
        </div>
      </div>
    </app-step>
  </mat-step>
  <mat-step>
    <app-step
      [stepTitle]="stepsTitle()"
      [disabledNextStep]="!validOpeningHours"
      [isBackable]="true"
      (openNextStep)="validateSecondStep(100)"
      (closeStep)="close()"
    >
      <div class="c-modal-content">
        <app-site-setup-side-panel
          [site]="site"
          [setupProgress]="setupProgress"
          [activeStep]="1"
          (openPreviousStep)="goToPreviousStep(0)"
        ></app-site-setup-side-panel>
        <div class="c-modal-main">
          <form class="o-form is-constrained c-open-hours-form" [formGroup]="openingHours">
            <div>
              <div class="is-site-img">
                <img class="is-image" src="/assets/images/client-auth-thumb.png" alt="Building" />
              </div>
              <div class="long-primer kirk">What are the site's main opening hours?</div>
              <div class="primer">
                For improved relevance you can restrict the report to only use traffic data during the site's opening
                hours.
              </div>

              <div class="o-banner-alert is-slim is-primary">
                <mat-icon class="is-icon">live_help</mat-icon>
                <span class="is-message"> If you don't want to set opening hours you can skip this step. </span>
              </div>
            </div>
            <div class="c-days-list">
              <div class="is-buttons-row">
                <button class="clear-all is-small" mat-button (click)="clearOpeningHours()">
                  <mat-icon>delete</mat-icon>Clear all
                </button>
                <button
                  class="skip-next is-small"
                  mat-button
                  [disabled]="!canSkip"
                  (click)="skipOpeningHours = true; clearOpeningHours(); goToNextStep(100)"
                >
                  <mat-icon>skip_next</mat-icon>Skip this step
                </button>
              </div>
              <div class="is-day-row" formGroupName="Monday">
                <label class="kirk is-day">Monday</label>
                <ng-container *ngIf="isOpenAllDay.isSelected('Monday') || isClosedAllDay.isSelected('Monday')">
                  <mat-form-field class="is-open">
                    <mat-label>Open</mat-label>
                    <input matInput [disabled]="true" />
                  </mat-form-field>
                  <mat-form-field class="is-close">
                    <mat-label>Close</mat-label>
                    <input matInput [disabled]="true" />
                  </mat-form-field>
                </ng-container>
                <mat-checkbox
                  *ngIf="isClosedAllDay.isSelected('Monday')"
                  class="is-open-all-day brevier u-flush--bottom"
                  [disabled]="true"
                >
                  Open all day
                </mat-checkbox>
                <mat-checkbox
                  *ngIf="isOpenAllDay.isSelected('Monday')"
                  class="is-close-all-day brevier u-flush--bottom"
                  [disabled]="true"
                >
                  Closed all day
                </mat-checkbox>
                <ng-container *ngIf="!isOpenAllDay.isSelected('Monday') && !isClosedAllDay.isSelected('Monday')">
                  <mat-form-field class="is-open">
                    <mat-label>Open</mat-label>
                    <input matInput [ngxTimepicker]="mondayTimepickerOpen" formControlName="open" readonly />
                    <ngx-material-timepicker #mondayTimepickerOpen [disableAnimation]="true"></ngx-material-timepicker>
                  </mat-form-field>
                  <mat-form-field class="is-close">
                    <mat-label>Close</mat-label>
                    <input matInput [ngxTimepicker]="mondayTimepickerClose" formControlName="close" readonly />
                    <ngx-material-timepicker #mondayTimepickerClose [disableAnimation]="true"></ngx-material-timepicker>
                  </mat-form-field>
                </ng-container>

                <mat-checkbox
                  *ngIf="!isClosedAllDay.isSelected('Monday')"
                  class="is-open-all-day brevier u-flush--bottom"
                  [checked]="isOpenAllDay.isSelected('Monday')"
                  (change)="isOpenAllDay.toggle('Monday')"
                >
                  Open all day
                </mat-checkbox>
                <mat-checkbox
                  *ngIf="!isOpenAllDay.isSelected('Monday')"
                  class="is-close-all-day brevier u-flush--bottom"
                  [checked]="isClosedAllDay.isSelected('Monday')"
                  (change)="isClosedAllDay.toggle('Monday')"
                >
                  Closed all day
                </mat-checkbox>
                <button class="is-copy o-button is-small" mat-button (click)="copyToAllDays()">Copy to all days</button>
              </div>

              <div class="is-day-row" formGroupName="Tuesday">
                <label class="kirk is-day">Tuesday</label>
                <ng-container *ngIf="isOpenAllDay.isSelected('Tuesday') || isClosedAllDay.isSelected('Tuesday')">
                  <mat-form-field class="is-open">
                    <mat-label>Open</mat-label>
                    <input matInput [disabled]="true" />
                  </mat-form-field>
                  <mat-form-field class="is-close">
                    <mat-label>Close</mat-label>
                    <input matInput [disabled]="true" />
                  </mat-form-field>
                </ng-container>
                <mat-checkbox
                  *ngIf="isClosedAllDay.isSelected('Tuesday')"
                  class="is-open-all-day brevier u-flush--bottom"
                  [disabled]="true"
                >
                  Open all day
                </mat-checkbox>
                <mat-checkbox
                  *ngIf="isOpenAllDay.isSelected('Tuesday')"
                  class="is-close-all-day brevier u-flush--bottom"
                  [disabled]="true"
                >
                  Closed all day
                </mat-checkbox>
                <ng-container *ngIf="!isOpenAllDay.isSelected('Tuesday') && !isClosedAllDay.isSelected('Tuesday')">
                  <mat-form-field class="is-open">
                    <mat-label>Open</mat-label>
                    <input matInput [ngxTimepicker]="tuesdayTimepickerOpen" formControlName="open" readonly />
                    <ngx-material-timepicker #tuesdayTimepickerOpen [disableAnimation]="true"></ngx-material-timepicker>
                  </mat-form-field>
                  <mat-form-field class="is-close">
                    <mat-label>Close</mat-label>
                    <input matInput [ngxTimepicker]="tuesdayTimepickerClose" formControlName="close" readonly />
                    <ngx-material-timepicker
                      #tuesdayTimepickerClose
                      [disableAnimation]="true"
                    ></ngx-material-timepicker>
                  </mat-form-field>
                </ng-container>

                <mat-checkbox
                  *ngIf="!isClosedAllDay.isSelected('Tuesday')"
                  class="is-open-all-day brevier u-flush--bottom"
                  [checked]="isOpenAllDay.isSelected('Tuesday')"
                  (change)="isOpenAllDay.toggle('Tuesday')"
                >
                  Open all day
                </mat-checkbox>
                <mat-checkbox
                  *ngIf="!isOpenAllDay.isSelected('Tuesday')"
                  class="is-close-all-day brevier u-flush--bottom"
                  [checked]="isClosedAllDay.isSelected('Tuesday')"
                  (change)="isClosedAllDay.toggle('Tuesday')"
                >
                  Closed all day
                </mat-checkbox>
              </div>

              <div class="is-day-row" formGroupName="Wednesday">
                <label class="kirk is-day">Wednesday</label>
                <ng-container *ngIf="isOpenAllDay.isSelected('Wednesday') || isClosedAllDay.isSelected('Wednesday')">
                  <mat-form-field class="is-open">
                    <mat-label>Open</mat-label>
                    <input matInput [disabled]="true" />
                  </mat-form-field>
                  <mat-form-field class="is-close">
                    <mat-label>Close</mat-label>
                    <input matInput [disabled]="true" />
                  </mat-form-field>
                </ng-container>
                <mat-checkbox
                  *ngIf="isClosedAllDay.isSelected('Wednesday')"
                  class="is-open-all-day brevier u-flush--bottom"
                  [disabled]="true"
                >
                  Open all day
                </mat-checkbox>
                <mat-checkbox
                  *ngIf="isOpenAllDay.isSelected('Wednesday')"
                  class="is-close-all-day brevier u-flush--bottom"
                  [disabled]="true"
                >
                  Closed all day
                </mat-checkbox>
                <ng-container *ngIf="!isOpenAllDay.isSelected('Wednesday') && !isClosedAllDay.isSelected('Wednesday')">
                  <mat-form-field class="is-open">
                    <mat-label>Open</mat-label>
                    <input matInput [ngxTimepicker]="wednesdayTimepickerOpen" formControlName="open" readonly />
                    <ngx-material-timepicker
                      #wednesdayTimepickerOpen
                      [disableAnimation]="true"
                    ></ngx-material-timepicker>
                  </mat-form-field>
                  <mat-form-field class="is-close">
                    <mat-label>Close</mat-label>
                    <input matInput [ngxTimepicker]="wednesdayTimepickerClose" formControlName="close" readonly />
                    <ngx-material-timepicker
                      #wednesdayTimepickerClose
                      [disableAnimation]="true"
                    ></ngx-material-timepicker>
                  </mat-form-field>
                </ng-container>

                <mat-checkbox
                  *ngIf="!isClosedAllDay.isSelected('Wednesday')"
                  class="is-open-all-day brevier u-flush--bottom"
                  [checked]="isOpenAllDay.isSelected('Wednesday')"
                  (change)="isOpenAllDay.toggle('Wednesday')"
                >
                  Open all day
                </mat-checkbox>
                <mat-checkbox
                  *ngIf="!isOpenAllDay.isSelected('Wednesday')"
                  class="is-close-all-day brevier u-flush--bottom"
                  [checked]="isClosedAllDay.isSelected('Wednesday')"
                  (change)="isClosedAllDay.toggle('Wednesday')"
                >
                  Closed all day
                </mat-checkbox>
              </div>

              <div class="is-day-row" formGroupName="Thursday">
                <label class="kirk is-day">Thursday</label>
                <ng-container *ngIf="isOpenAllDay.isSelected('Thursday') || isClosedAllDay.isSelected('Thursday')">
                  <mat-form-field class="is-open">
                    <mat-label>Open</mat-label>
                    <input matInput [disabled]="true" />
                  </mat-form-field>
                  <mat-form-field class="is-close">
                    <mat-label>Close</mat-label>
                    <input matInput [disabled]="true" />
                  </mat-form-field>
                </ng-container>
                <mat-checkbox
                  *ngIf="isClosedAllDay.isSelected('Thursday')"
                  class="is-open-all-day brevier u-flush--bottom"
                  [disabled]="true"
                >
                  Open all day
                </mat-checkbox>
                <mat-checkbox
                  *ngIf="isOpenAllDay.isSelected('Thursday')"
                  class="is-close-all-day brevier u-flush--bottom"
                  [disabled]="true"
                >
                  Closed all day
                </mat-checkbox>
                <ng-container *ngIf="!isOpenAllDay.isSelected('Thursday') && !isClosedAllDay.isSelected('Thursday')">
                  <mat-form-field class="is-open">
                    <mat-label>Open</mat-label>
                    <input matInput [ngxTimepicker]="thursdayTimepickerOpen" formControlName="open" readonly />
                    <ngx-material-timepicker
                      #thursdayTimepickerOpen
                      [disableAnimation]="true"
                    ></ngx-material-timepicker>
                  </mat-form-field>
                  <mat-form-field class="is-close">
                    <mat-label>Close</mat-label>
                    <input matInput [ngxTimepicker]="thursdayTimepickerClose" formControlName="close" readonly />
                    <ngx-material-timepicker
                      #thursdayTimepickerClose
                      [disableAnimation]="true"
                    ></ngx-material-timepicker>
                  </mat-form-field>
                </ng-container>

                <mat-checkbox
                  *ngIf="!isClosedAllDay.isSelected('Thursday')"
                  class="is-open-all-day brevier u-flush--bottom"
                  [checked]="isOpenAllDay.isSelected('Thursday')"
                  (change)="isOpenAllDay.toggle('Thursday')"
                >
                  Open all day
                </mat-checkbox>
                <mat-checkbox
                  *ngIf="!isOpenAllDay.isSelected('Thursday')"
                  class="is-close-all-day brevier u-flush--bottom"
                  [checked]="isClosedAllDay.isSelected('Thursday')"
                  (change)="isClosedAllDay.toggle('Thursday')"
                >
                  Closed all day
                </mat-checkbox>
              </div>

              <div class="is-day-row" formGroupName="Friday">
                <label class="kirk is-day">Friday</label>
                <ng-container *ngIf="isOpenAllDay.isSelected('Friday') || isClosedAllDay.isSelected('Friday')">
                  <mat-form-field class="is-open">
                    <mat-label>Open</mat-label>
                    <input matInput [disabled]="true" />
                  </mat-form-field>
                  <mat-form-field class="is-close">
                    <mat-label>Close</mat-label>
                    <input matInput [disabled]="true" />
                  </mat-form-field>
                </ng-container>
                <mat-checkbox
                  *ngIf="isClosedAllDay.isSelected('Friday')"
                  class="is-open-all-day brevier u-flush--bottom"
                  [disabled]="true"
                >
                  Open all day
                </mat-checkbox>
                <mat-checkbox
                  *ngIf="isOpenAllDay.isSelected('Friday')"
                  class="is-close-all-day brevier u-flush--bottom"
                  [disabled]="true"
                >
                  Closed all day
                </mat-checkbox>
                <ng-container *ngIf="!isOpenAllDay.isSelected('Friday') && !isClosedAllDay.isSelected('Friday')">
                  <mat-form-field class="is-open">
                    <mat-label>Open</mat-label>
                    <input matInput [ngxTimepicker]="fridayTimepickerOpen" formControlName="open" readonly />
                    <ngx-material-timepicker #fridayTimepickerOpen [disableAnimation]="true"></ngx-material-timepicker>
                  </mat-form-field>
                  <mat-form-field class="is-close">
                    <mat-label>Close</mat-label>
                    <input matInput [ngxTimepicker]="fridayTimepickerClose" formControlName="close" readonly />
                    <ngx-material-timepicker #fridayTimepickerClose [disableAnimation]="true"></ngx-material-timepicker>
                  </mat-form-field>
                </ng-container>

                <mat-checkbox
                  *ngIf="!isClosedAllDay.isSelected('Friday')"
                  class="is-open-all-day brevier u-flush--bottom"
                  [checked]="isOpenAllDay.isSelected('Friday')"
                  (change)="isOpenAllDay.toggle('Friday')"
                >
                  Open all day
                </mat-checkbox>
                <mat-checkbox
                  *ngIf="!isOpenAllDay.isSelected('Friday')"
                  class="is-close-all-day brevier u-flush--bottom"
                  [checked]="isClosedAllDay.isSelected('Friday')"
                  (change)="isClosedAllDay.toggle('Friday')"
                >
                  Closed all day
                </mat-checkbox>
              </div>

              <div class="is-day-row" formGroupName="Saturday">
                <label class="kirk is-day">Saturday</label>
                <ng-container *ngIf="isOpenAllDay.isSelected('Saturday') || isClosedAllDay.isSelected('Saturday')">
                  <mat-form-field class="is-open">
                    <mat-label>Open</mat-label>
                    <input matInput [disabled]="true" />
                  </mat-form-field>
                  <mat-form-field class="is-close">
                    <mat-label>Close</mat-label>
                    <input matInput [disabled]="true" />
                  </mat-form-field>
                </ng-container>
                <mat-checkbox
                  *ngIf="isClosedAllDay.isSelected('Saturday')"
                  class="is-open-all-day brevier u-flush--bottom"
                  [disabled]="true"
                >
                  Open all day
                </mat-checkbox>
                <mat-checkbox
                  *ngIf="isOpenAllDay.isSelected('Saturday')"
                  class="is-close-all-day brevier u-flush--bottom"
                  [disabled]="true"
                >
                  Closed all day
                </mat-checkbox>
                <ng-container *ngIf="!isOpenAllDay.isSelected('Saturday') && !isClosedAllDay.isSelected('Saturday')">
                  <mat-form-field class="is-open">
                    <mat-label>Open</mat-label>
                    <input matInput [ngxTimepicker]="saturdayTimepickerOpen" formControlName="open" readonly />
                    <ngx-material-timepicker
                      #saturdayTimepickerOpen
                      [disableAnimation]="true"
                    ></ngx-material-timepicker>
                  </mat-form-field>
                  <mat-form-field class="is-close">
                    <mat-label>Close</mat-label>
                    <input matInput [ngxTimepicker]="saturdayTimepickerClose" formControlName="close" readonly />
                    <ngx-material-timepicker
                      #saturdayTimepickerClose
                      [disableAnimation]="true"
                    ></ngx-material-timepicker>
                  </mat-form-field>
                </ng-container>

                <mat-checkbox
                  *ngIf="!isClosedAllDay.isSelected('Saturday')"
                  class="is-open-all-day brevier u-flush--bottom"
                  [checked]="isOpenAllDay.isSelected('Saturday')"
                  (change)="isOpenAllDay.toggle('Saturday')"
                >
                  Open all day
                </mat-checkbox>
                <mat-checkbox
                  *ngIf="!isOpenAllDay.isSelected('Saturday')"
                  class="is-close-all-day brevier u-flush--bottom"
                  [checked]="isClosedAllDay.isSelected('Saturday')"
                  (change)="isClosedAllDay.toggle('Saturday')"
                >
                  Closed all day
                </mat-checkbox>
              </div>

              <div class="is-day-row" formGroupName="Sunday">
                <label class="kirk is-day">Sunday</label>
                <ng-container *ngIf="isOpenAllDay.isSelected('Sunday') || isClosedAllDay.isSelected('Sunday')">
                  <mat-form-field class="is-open">
                    <mat-label>Open</mat-label>
                    <input matInput [disabled]="true" />
                  </mat-form-field>
                  <mat-form-field class="is-close">
                    <mat-label>Close</mat-label>
                    <input matInput [disabled]="true" />
                  </mat-form-field>
                </ng-container>
                <mat-checkbox
                  *ngIf="isClosedAllDay.isSelected('Sunday')"
                  class="is-open-all-day brevier u-flush--bottom"
                  [disabled]="true"
                >
                  Open all day
                </mat-checkbox>
                <mat-checkbox
                  *ngIf="isOpenAllDay.isSelected('Sunday')"
                  class="is-close-all-day brevier u-flush--bottom"
                  [disabled]="true"
                >
                  Closed all day
                </mat-checkbox>
                <ng-container *ngIf="!isOpenAllDay.isSelected('Sunday') && !isClosedAllDay.isSelected('Sunday')">
                  <mat-form-field class="is-open">
                    <mat-label>Open</mat-label>
                    <input matInput [ngxTimepicker]="sundayTimepickerOpen" formControlName="open" readonly />
                    <ngx-material-timepicker #sundayTimepickerOpen [disableAnimation]="true"></ngx-material-timepicker>
                  </mat-form-field>
                  <mat-form-field class="is-close">
                    <mat-label>Close</mat-label>
                    <input matInput [ngxTimepicker]="sundayTimepickerClose" formControlName="close" readonly />
                    <ngx-material-timepicker #sundayTimepickerClose [disableAnimation]="true"></ngx-material-timepicker>
                  </mat-form-field>
                </ng-container>

                <mat-checkbox
                  *ngIf="!isClosedAllDay.isSelected('Sunday')"
                  class="is-open-all-day brevier u-flush--bottom"
                  [checked]="isOpenAllDay.isSelected('Sunday')"
                  (change)="isOpenAllDay.toggle('Sunday')"
                >
                  Open all day
                </mat-checkbox>
                <mat-checkbox
                  *ngIf="!isOpenAllDay.isSelected('Sunday')"
                  class="is-close-all-day brevier u-flush--bottom"
                  [checked]="isClosedAllDay.isSelected('Sunday')"
                  (change)="isClosedAllDay.toggle('Sunday')"
                >
                  Closed all day
                </mat-checkbox>
              </div>
            </div>
          </form>
        </div>
      </div>
    </app-step>
  </mat-step>
  <mat-step>
    <ng-template matStepContent>
      <app-step
        [stepTitle]="stepsTitle()"
        [nextStepText]="'save'"
        (closeStep)="close()"
        (openPreviousStep)="goToPreviousStep(50)"
        (openNextStep)="save()"
      >
        <div class="c-modal-content">
          <app-site-setup-side-panel
            [site]="site"
            [setupProgress]="setupProgress"
            [activeStep]="2"
            (openPreviousStep)="goToPreviousStep(50)"
          ></app-site-setup-side-panel>
          <div class="c-modal-main">
            <form class="o-form is-constrained" [formGroup]="siteForm">
              <div>
                <h3 class="long-primer kirk u-flush--bottom">Almost done.</h3>
                <p class="u-flush--bottom">Please check and confirm your new site details.</p>
              </div>
              <div class="c-site-details">
                <div class="is-site-details">
                  <h3 *ngIf="accountService.isSupport" class="brevier--caps">
                    {{ accountService.organisationsMap[siteForm.get('organisationId').value].name }}
                  </h3>
                  <p class="long-primer kirk u-flush--bottom">{{ siteForm.get('name').value }}</p>
                  <p>{{ siteForm.get('address').value }}</p>
                  <p class="brevier">{{ siteForm.get('tz').value }}</p>

                  <button class="o-button" mat-button (click)="stepper.selectedIndex = 0">Edit details</button>
                </div>
                <div class="is-opening-hours-details">
                  <div class="primer kirk">Opening hours</div>
                  <div *ngIf="!skipOpeningHours">
                    <div class="c-opening-hours-day" *ngFor="let day of daysOfWeek">
                      <div class="is-day brevier u-flush--bottom">{{ day }}</div>
                      <div class="is-hours minion--caps">
                        {{
                          isClosedAllDay.isSelected(day)
                            ? 'Closed'
                            : isOpenAllDay.isSelected(day)
                              ? 'Open all day'
                              : openingHours.get(day).get('open').value +
                                ' - ' +
                                openingHours.get(day).get('close').value
                        }}
                      </div>
                    </div>
                  </div>
                  <img
                    *ngIf="skipOpeningHours"
                    class="is-image"
                    src="/assets/images/client-auth-thumb.png"
                    alt="Building"
                  />
                  <button class="o-button" mat-button (click)="setupProgress = 50; stepper.selectedIndex = 1">
                    {{ skipOpeningHours ? 'Set' : 'Edit' }} opening hours
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </app-step>
    </ng-template>
  </mat-step>
</mat-horizontal-stepper>
