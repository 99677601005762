<div class="c-container">
  <div class="o-card-list">
    <div class="o-card-list__title">
      <div class="o-heading--long-primer u-flush--bottom">
        <h2>Notification groups</h2>
      </div>
      <div class="is-action o-button has-outline" (click)="createNotificationGroup()">
        <mat-icon class="is-icon-left">contacts</mat-icon>
        CREATE NEW GROUP
      </div>
    </div>
    <div class="u-island">
      <ul class="o-notification-list has-divider">
        <ng-container *ngFor="let notificationGroup of filteredNotificationGroups">
          <li
            class="o-notification-list__item has-icon o-avatar__row"
            (click)="showNotificationGroup(notificationGroup)"
          >
            <div class="is-link">
              <div class="is-icon o-avatar__icon">
                <span>{{ notificationGroup.name.split(' ')[0][0] }}</span>
                <span *ngIf="notificationGroup.name.split(' ')[1]">{{ notificationGroup.name.split(' ')[1][0] }}</span>
              </div>
              <div class="o-notification-list__primary-secondary">
                <div>{{ notificationGroup.name }}</div>
                <div *ngIf="accountService.isSupport">
                  {{ organisationsMap[notificationGroup.organisationId].name }}
                </div>
              </div>
              <div class="o-notification-list__actions">
                <div class="c-icon-count-pair">
                  <mat-icon class="is-icon">recent_actors</mat-icon>
                  <span>
                    <ng-container *ngIf="notificationGroup.emails; else noNotificationGroups">
                      {{ notificationsService.notificationGroupMembers[notificationGroup.id] }}
                    </ng-container>
                    <ng-template #noNotificationGroups> 0 </ng-template>
                  </span>
                </div>
              </div>
            </div>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>
