import { Injectable } from '@angular/core';
import {
  Alert,
  AlertCameraPositionMapping,
  OccupancyMonitor,
  OccupancyMonitorCameraPosition,
  PeelOffMonitor,
  PeelOffMonitorCameraPosition,
  PeopleCountMonitor,
  PeopleCountMonitorCameraPosition,
  Site,
} from 'src/app/api';
import { CameraStatus } from 'src/app/model/cameraStatus';

@Injectable({
  providedIn: 'root',
})
export class SiteDetailsService {
  site: Site;
  sites: Site[];
  allCameras: CameraStatus[] = []; // including decommissioned cameras
  cameras: CameraStatus[] = [];
  camerasMapByPositionId: { [_: string]: CameraStatus } = {};
  inUseCameras: CameraStatus[] = [];
  pendingCameras: CameraStatus[] = [];
  occupancyMonitors: OccupancyMonitor[] = [];
  peopleCountMonitors: PeopleCountMonitor[] = [];
  peelOffMonitors: PeelOffMonitor[] = [];
  peopleCountMonitorsCameras: { [_: string]: CameraStatus[] } = {};
  peelOffMonitorsCameras: { [_: string]: CameraStatus[] } = {};
  occupancyMonitorsCameras: { [_: string]: CameraStatus[] } = {};
  occupancyMonitorCamerasPositions: {
    [_: string]: {
      [_: string]: OccupancyMonitorCameraPosition;
    };
  } = {};
  peopleCountMonitorCamerasPositions: {
    [_: string]: {
      [_: string]: PeopleCountMonitorCameraPosition;
    };
  } = {};
  peelOffMonitorCamerasPositions: {
    [_: string]: {
      [_: string]: PeelOffMonitorCameraPosition;
    };
  } = {};
  monitorOccupancyAlerts: { [_: string]: Alert[] } = {};
  cameraHealth = { offline: new Set<string>(), lagging: new Set<string>(), paused: new Set<string>() };
  offlineAlerts: Alert[] = [];
  summaryAlerts: Alert[] = [];
  offlineAlertCameraPositions: AlertCameraPositionMapping[] = [];
  summaryAlertCameraPositions: AlertCameraPositionMapping[] = [];

  getOccupancyMonitor(id: string): OccupancyMonitor {
    return this.occupancyMonitors.find((om) => om.id === id);
  }

  getPeopleCountMonitor(id: string): PeopleCountMonitor {
    return this.peopleCountMonitors.find((pcm) => pcm.id === id);
  }

  getPeelOffMonitor(id: string): PeelOffMonitor {
    return this.peelOffMonitors.find((pom) => pom.id === id);
  }
}
