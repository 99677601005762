import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject, forkJoin, of, throwError } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import {
  Alert,
  AlertCameraPositionMapping,
  AlertCameraPositionMappingService,
  AlertService,
  Camera,
  CameraService,
  OccupancyMonitor,
  OccupancyMonitorCameraPosition,
  OccupancyMonitorCameraPositionService,
  OccupancyMonitorService,
  PeelOffMonitor,
  PeelOffMonitorCameraPosition,
  PeelOffMonitorCameraPositionService,
  PeelOffMonitorService,
  PeopleCountMonitor,
  PeopleCountMonitorCameraPosition,
  PeopleCountMonitorCameraPositionService,
  PeopleCountMonitorService,
  Site,
  SiteService,
} from 'src/app/api';
import { CameraSetupDialogComponent } from 'src/app/components/cameras/camera-setup-dialog/camera-setup-dialog.component';
import { FilterDialogComponent } from 'src/app/components/general/filter-dialog/filter-dialog.component';
import { SortByComponent } from 'src/app/components/general/sort-by/sort-by.component';
import { CameraStatus } from 'src/app/model/cameraStatus';
import { AccountService } from 'src/app/services/account.service';
import { CamerasService } from 'src/app/services/cameras.service';
import { CamerasSideMenuComponent } from 'src/app/views/cameras/components/cameras-side-menu/cameras-side-menu.component';

@Component({
  selector: 'app-cameras',
  templateUrl: './cameras.component.html',
  styleUrls: ['./cameras.component.scss'],
})
export class CamerasComponent implements OnInit, OnDestroy {
  isLoading$ = new BehaviorSubject(true);

  offlineAlerts: Alert[] = [];
  summaryAlerts: Alert[] = [];
  offlineAlertCameraPositions: AlertCameraPositionMapping[] = [];
  summaryAlertCameraPositions: AlertCameraPositionMapping[] = [];
  sites: Site[] = [];
  filteredSites: Site[] = [];
  sitesMap: { [_: string]: Site } = {};
  selectedSite: Site;
  searchString = '';

  allCameras: CameraStatus[] = []; // including decommissioned cameras
  cameras: CameraStatus[] = [];
  filteredCameras: CameraStatus[] = [];

  sortBy: keyof CameraStatus = 'status';

  hasCameras: boolean;
  showSideMenu: boolean = true;
  showDecommissioned = false;

  sideMenuAllSiteOption: Site = {
    name: 'ALL SITES',
    id: '00000000-0000-0000-0000-000000000000',
  };
  incompletCameras: CameraStatus[] = [];
  issuesCameras: CameraStatus[] = [];
  issuesCamerasMonitors: { [siteId: string]: Set<OccupancyMonitor | PeopleCountMonitor | PeelOffMonitor> } = {};

  private ngUnsubscribe = new Subject();

  constructor(
    public accountService: AccountService,
    private breakpointObserver: BreakpointObserver,
    private siteService: SiteService,
    private cameraService: CameraService,
    private camerasService: CamerasService,
    private alertService: AlertService,
    private alertCameraPositionMappingService: AlertCameraPositionMappingService,
    private occupancyMonitorService: OccupancyMonitorService,
    private peopleCountMonitorService: PeopleCountMonitorService,
    private peelOffMonitorService: PeelOffMonitorService,
    private occupancyMonitorCameraPositionService: OccupancyMonitorCameraPositionService,
    private peopleCountMonitorCameraPositionService: PeopleCountMonitorCameraPositionService,
    private peelOffMonitorCameraPositionService: PeelOffMonitorCameraPositionService,
    private ref: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(min-width: 1365px)'])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (state: BreakpointState) => {
          this.showSideMenu = state.matches;
          this.ref.detectChanges();
        },
      });

    this.getCameras();
  }

  getCameras(): void {
    this.isLoading$.next(true);
    this.hasCameras = false;
    this.ref.detectChanges();
    const cameraState: Camera.StateEnum[] = this.accountService.isSupport
      ? ['running', 'paused', 'decommissioned']
      : ['running', 'paused'];
    forkJoin([
      this.siteService.listSites(undefined, undefined, 'active'),
      this.cameraService.listCameras(false),
      this.cameraService.listCameras(true, undefined, undefined, cameraState),

      this.alertService.listAlerts('offline_alert', undefined, undefined, undefined, undefined, undefined, 'active'),
      this.alertService.listAlerts(
        'camera_health_summary_alert',
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
      ),
      this.alertCameraPositionMappingService.listAlertCameraPositionMappings(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
        'offline_alert',
      ),
      this.alertCameraPositionMappingService.listAlertCameraPositionMappings(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
        'camera_health_summary_alert',
      ),
      this.occupancyMonitorService.listOccupancyMonitors(undefined, undefined, undefined, 'active'),
      this.peopleCountMonitorService.listPeopleCountMonitors(undefined, undefined, undefined, 'active'),
      this.peelOffMonitorService.listPeelOffMonitors(undefined, undefined, undefined, 'active'),
      this.occupancyMonitorCameraPositionService.listOccupancyMonitorCameraPositions(
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
      ),
      this.peopleCountMonitorCameraPositionService.listPeopleCountMonitorCameraPositions(
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
      ),
      this.peelOffMonitorCameraPositionService.listPeelOffMonitorCameraPositions(
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
      ),
    ])
      .pipe(
        finalize(() => {
          this.isLoading$.next(false);
          this.ref.detectChanges();
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: ([
          sites,
          allCameras,
          cameras,
          offlineAlerts,
          summaryAlerts,
          offlineAlertCameraPositions,
          summaryAlertCameraPositions,
          occupancyMonitors,
          peopleCountMonitors,
          peelOffMonitors,
          occupancyMonitorCameraPositions,
          peopleCountMonitorCameraPositions,
          peelOffMonitorCameraPositions,
        ]) => {
          this.allCameras = allCameras;
          this.offlineAlerts = offlineAlerts;
          this.summaryAlerts = summaryAlerts;
          this.offlineAlertCameraPositions = offlineAlertCameraPositions;
          this.summaryAlertCameraPositions = summaryAlertCameraPositions;
          sites = sites.sort((s1, s2) => s1.name.toLowerCase().localeCompare(s2.name.toLowerCase()));

          sites.forEach((s) => (this.sitesMap[s.id] = s));
          this.sites = [this.sideMenuAllSiteOption, ...sites];
          const occMonitorsMap = {};
          const pplMonitorsMap = {};
          const peelMonitorsMap = {};
          occupancyMonitors?.forEach((m) => (occMonitorsMap[m.id] = m));
          peopleCountMonitors?.forEach((m) => (pplMonitorsMap[m.id] = m));
          peelOffMonitors?.forEach((m) => (peelMonitorsMap[m.id] = m));

          this.cameras = cameras.map((c) => this.camerasService.getCameraStatus(c, this.sitesMap[c.siteId]));

          var camerasWithNoOfflineAlerts = cameras.filter((c) => c.state === 'running');
          var camerasWithNoSummaryAlert = cameras.filter((c) => c.state === 'running');

          const offlineAlertMapOfflineAlertCameraPositions: { [_: string]: boolean } = {};
          const summaryAlertMapSummaryAlertCameraPositions: { [_: string]: boolean } = {};

          offlineAlertCameraPositions.forEach((mapping) => {
            offlineAlertMapOfflineAlertCameraPositions[mapping.alertId] = true;
            camerasWithNoOfflineAlerts = camerasWithNoOfflineAlerts.filter(
              (c) => c.cameraPositionId !== mapping.cameraPositionId,
            );
          });
          summaryAlertCameraPositions.forEach((mapping) => {
            summaryAlertMapSummaryAlertCameraPositions[mapping.alertId] = true;
            camerasWithNoSummaryAlert = camerasWithNoSummaryAlert.filter(
              (c) => c.cameraPositionId !== mapping.cameraPositionId,
            );
          });

          offlineAlerts.forEach((alert) => {
            if (!alert.siteId) {
              camerasWithNoOfflineAlerts = camerasWithNoOfflineAlerts.filter(
                (c) => c.organisationId !== alert.organisationId,
              );
            } else if (!offlineAlertMapOfflineAlertCameraPositions[alert.id]) {
              camerasWithNoOfflineAlerts = camerasWithNoOfflineAlerts.filter((c) => c.siteId !== alert.siteId);
            }
          });

          this.summaryAlerts = summaryAlerts;
          summaryAlerts.forEach((alert) => {
            if (!alert.siteId) {
              camerasWithNoSummaryAlert = camerasWithNoSummaryAlert.filter(
                (c) => c.organisationId !== alert.organisationId,
              );
            } else if (!summaryAlertMapSummaryAlertCameraPositions[alert.id]) {
              camerasWithNoSummaryAlert = camerasWithNoSummaryAlert.filter((c) => c.siteId !== alert.siteId);
            }
          });

          this.cameras.forEach((c) => {
            if (
              c.state !== 'decommissioned' &&
              camerasWithNoOfflineAlerts.find((camera) => camera.cameraPositionId === c.cameraPositionId) &&
              camerasWithNoSummaryAlert.find((camera) => camera.cameraPositionId === c.cameraPositionId)
            ) {
              this.incompletCameras.push(c);
            }

            if (c.status === 'offline' || c.status === 'missing' || c.status === 'lagging') {
              this.issuesCameras.push(c);
              occupancyMonitorCameraPositions
                ?.filter((o: OccupancyMonitorCameraPosition) => o.cameraPositionId === c.cameraPositionId)
                .forEach((o) => {
                  this.addIssuesCamerasMonitor(occMonitorsMap[o.occupancyMonitorId]);
                });
              peopleCountMonitorCameraPositions
                ?.filter((p: PeopleCountMonitorCameraPosition) => p.cameraPositionId === c.cameraPositionId)
                .forEach((p) => {
                  this.addIssuesCamerasMonitor(pplMonitorsMap[p.peopleCountMonitorId]);
                });
              peelOffMonitorCameraPositions
                ?.filter((p: PeelOffMonitorCameraPosition) => p.cameraPositionId === c.cameraPositionId)
                .forEach((p) => {
                  this.addIssuesCamerasMonitor(peelMonitorsMap[p.peelOffMonitorId]);
                });
            }
          });

          const selectedSiteFromParams = this.getQueryParam('selectedSite');
          const filterMonitorsFromParams = this.getQueryParam('filterCameras');

          if (selectedSiteFromParams && selectedSiteFromParams != this.sideMenuAllSiteOption.id) {
            this.filterCameras(this.sitesMap[selectedSiteFromParams], filterMonitorsFromParams);
          } else {
            this.filterCameras(this.sideMenuAllSiteOption, filterMonitorsFromParams);
          }
        },
        error: (_) => {
          this.router.navigate(['internal-error']);
        },
      });
  }

  getQueryParam(queryParam: string): string {
    return this.route.snapshot.queryParams[queryParam];
  }

  updateParamsMultiple(customParams) {
    if (!this.route.firstChild)
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: customParams,
        queryParamsHandling: 'merge',
      });
  }

  addIssuesCamerasMonitor(monitor: OccupancyMonitor | PeopleCountMonitor | PeelOffMonitor) {
    if (!monitor) {
      return;
    }

    if (!this.issuesCamerasMonitors[monitor.siteId]) {
      this.issuesCamerasMonitors[monitor.siteId] = new Set([monitor]);
    } else {
      this.issuesCamerasMonitors[monitor.siteId].add(monitor);
    }
  }

  filterCameras(site?: Site, searchString?: string): void {
    if (site) {
      this.selectedSite = site;
    }

    if (searchString !== undefined) {
      this.searchString = searchString.toLowerCase();
    }

    this.updateParamsMultiple({
      filterCameras: searchString,
      selectedSite: this.selectedSite.id,
    });

    this.filteredCameras = this.cameras.filter(
      (c) =>
        (this.showDecommissioned ? true : c.state !== 'decommissioned') &&
        (c.id.includes(this.searchString) ||
          c.name?.toLowerCase().includes(this.searchString) ||
          c.serialNumber?.toLowerCase().includes(this.searchString) ||
          this.accountService.organisationsMap[c.organisationId]?.name.toLowerCase().includes(this.searchString) ||
          this.sitesMap[c.siteId]?.name.toLowerCase().includes(this.searchString)),
    );

    this.filteredSites = [
      this.sideMenuAllSiteOption,
      ...new Set(this.filteredCameras.map((camera) => this.sitesMap[camera.siteId])),
    ];

    this.filteredCameras =
      this.selectedSite.id === this.sideMenuAllSiteOption.id
        ? this.filteredCameras
        : this.filteredCameras.filter((c) => c.siteId === this.selectedSite.id);
    this.hasCameras = this.filteredCameras.length !== 0;
  }

  openSideMenu(): void {
    const dialogRef = this.matDialog.open(CamerasSideMenuComponent, {
      height: '100vh',
      width: '90vw',
      maxWidth: '90vw',
      position: { left: '0' },
      data: {
        incompletCameras: this.incompletCameras,
        issuesCameras: this.issuesCameras,
        issuesCamerasMonitors: this.issuesCamerasMonitors,
        sitesMap: this.sitesMap,
      },
      panelClass: 'mat-dialog-side-menu',
    });

    dialogRef.afterClosed().subscribe({
      next: (response) => {
        if (response?.hasOwnProperty('setup-camera')) {
          this.openCameraSetupPopup();
        }
        if (response?.hasOwnProperty('camera')) {
          this.openCameraSetupPopup(response['camera']);
        }
      },
    });
  }

  openSortBy(): void {
    const dialogRef = this.matDialog.open(SortByComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data: { searchString: this.searchString },
    });

    dialogRef.afterClosed().subscribe({
      next: (sort) => {
        this.sortBy = sort;
        this.ref.detectChanges();
      },
    });
  }

  openFilter(): void {
    const dialogRef = this.matDialog.open(FilterDialogComponent, {
      height: 'fit-content',
      width: '100vw',
      maxWidth: '100vw',
      position: { top: '0' },
      data: { searchString: this.searchString },
    });

    dialogRef.afterClosed().subscribe({
      next: (_) => {
        this.filterCameras(undefined, dialogRef.componentInstance.searchString);
        this.ref.detectChanges();
      },
    });
  }

  openCameraSetupPopup(camera?: CameraStatus): void {
    const dialogRef = this.matDialog.open(CameraSetupDialogComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data: {
        sites: this.sites.slice(1),
        siteId: this.selectedSite !== this.sideMenuAllSiteOption ? this.selectedSite : this.sites.slice(1)[0]?.id,
        camera,
        offlineAlerts: this.offlineAlerts,
        summaryAlerts: this.summaryAlerts,
        offlineAlertCameraPositions: this.offlineAlertCameraPositions,
        summaryAlertCameraPositions: this.summaryAlertCameraPositions,
        cameras: this.allCameras,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          this.getCameras();
        }
      },
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
