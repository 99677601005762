<form class="o-form" [formGroup]="monitorForm">
  <div class="o-form__legend-icon">
    <mat-icon class="is-icon">camera_front</mat-icon>
    <h2 class="is-legend">Camera orientation.</h2>
    <div *ngIf="isEditMode" class="is-action c-is-action">
      <button class="o-button has-outline is-right" (click)="addCameras.emit()">
        <mat-icon class="is-icon-left">add_a_photo</mat-icon>
        ADD CAMERA
      </button>

      <button class="o-button is-delete has-outline is-right" (click)="deleteMonitor.emit()">
        <mat-icon class="is-icon-left">delete</mat-icon>
        Delete CAMERA
      </button>
    </div>
    <p class="is-support-text" *ngIf="!_isEditMode">
      You are connecting {{ cameras.length }} {{ cameras.length | pluraliseWord: 'camera' }} to the monitor. Configure
      how they are oriented in relation to your monitor directions {{ direction1 }} / {{ direction2 }}.
    </p>
    <div class="is-input">
      <div formArrayName="camera" [class.c-edit-view]="isEditMode">
        <div *ngFor="let cameraForm of monitorControls.controls; let i = index">
          <div
            class="auth-overlay white-overlay u-island"
            *ngIf="isLoadingCamera[cameraForm.get('id').value]; else showCamera"
          >
            <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
              <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
            </div>
          </div>
          <ng-template #showCamera>
            <div class="c-camera-orientation" [formGroupName]="i">
              <div class="c-camera-orientation__header" [class.has-space-between]="isEditMode">
                <div class="is-camera">{{ cameraForm.get('name').value }}</div>
                <a
                  *ngIf="!isEditMode; else editSwitchMenu"
                  class="o-button is-small is-switch"
                  [class.is-disabled]="cameraForm.get('confirmed').value"
                  (click)="cameraForm.get('confirmed').invalid && switchMonitorDirection(cameraForm)"
                >
                  <mat-icon class="is-icon">rotate_left</mat-icon>
                  Switch monitor directions
                </a>
                <ng-template #editSwitchMenu>
                  <a class="o-button is-small is-switch">
                    <mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="switchMonitorDirection(cameraForm)">
                        <mat-icon>rotate_left</mat-icon>
                        Switch monitor directions
                      </button>
                      <button mat-menu-item (click)="removeCamera(cameraForm)">
                        <mat-icon>delete</mat-icon>
                        Remove camera from monitor
                      </button>
                    </mat-menu>
                  </a>
                </ng-template>
                <div class="is-confirm" *ngIf="!isEditMode">
                  <div class="c-confirmation-banner" [class.is-confirmed]="cameraForm.get('confirmed').value">
                    <span class="is-message">Confirm camera directions match monitor directions</span>
                    <mat-checkbox formControlName="confirmed" class="is-action"></mat-checkbox>
                  </div>
                  <div
                    *ngIf="cameraForm.touched && cameraForm.invalid"
                    class="o-banner-alert is-error"
                    style="margin-top: 1px"
                  >
                    <mat-icon class="is-icon">warning</mat-icon>
                    <span class="is-message">You must confirm the camera orientation</span>
                  </div>
                </div>
              </div>
              <div
                class="c-camera__live-view"
                *ngIf="isCameraOnline(camerasMap[cameraForm.get('id').value]); else notRunningCameraFrame"
              >
                <div class="c-camera__view-directions">
                  <div
                    class="auth-overlay ultralight-overlay no-padding"
                    *ngIf="isLoadingCameraFrame[cameraForm.get('id').value]; else notLoadingImg"
                  >
                    <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
                      <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
                    </div>
                  </div>
                  <ng-template #notLoadingImg>
                    <div class="c-camera__camera-view">
                      <div class="c-camera__camera-view-wrap">
                        <img [src]="cameraFrame[cameraForm.get('id').value]" alt="camera view" />
                        <ng-container *ngIf="!cameraForm.get('isPassingTraffic').value; else passTraffCrossingLine">
                          <svg height="100%" width="100%" class="is-count-line">
                            <line x1="0%" y1="50%" x2="100%" y2="50%" stroke="rgb(255,0,0)" stroke-width="2" />
                          </svg>
                        </ng-container>
                        <ng-template #passTraffCrossingLine>
                          <svg height="100%" width="100%" class="is-count-line">
                            <line x1="50%" y1="0%" x2="50%" y2="100%" stroke="rgb(255,0,0)" stroke-width="2" />
                          </svg>
                        </ng-template>
                      </div>
                      <div class="c-camera__footer">
                        <a class="o-button is-small is-muted" (click)="getCameraFrame(cameraForm.get('id').value)">
                          <mat-icon class="is-icon-left">refresh</mat-icon>
                          Refresh
                        </a>
                        <div class="c-count-line">Count line</div>
                      </div>
                    </div>
                    <ng-container *ngTemplateOutlet="orientationSettingTemplate"></ng-container>
                  </ng-template>
                </div>
              </div>
              <ng-template #notRunningCameraFrame>
                <div class="c-camera-offline">
                  <div class="o-banner-alert c-camera-offline__banner">
                    <mat-icon class="is-icon"> warning</mat-icon>
                    <div class="is-header">
                      <ng-container *ngIf="cameraForm.get('id').value.state === 'paused'; else cameraNotPausedHeader">
                        <b>Camera is paused</b> - we cannot show a frame view.
                      </ng-container>
                      <ng-template #cameraNotPausedHeader>
                        <ng-container
                          *ngIf="cameraForm.get('id').value.isOnline === false; else cameraUnavailableHeader"
                        >
                          <b>Camera is offline</b> - we cannot show a frame view.
                        </ng-container>
                        <ng-template #cameraUnavailableHeader>
                          <b>Camera is unavailable</b>
                        </ng-template>
                      </ng-template>
                    </div>
                    <a
                      class="o-button is-small c-camera-offline__banner-refresh"
                      [class.rotate]="isLoadingCameraFrame[cameraForm.get('id').value]"
                      (click)="getCamera(cameraForm.get('id').value)"
                    >
                      <mat-icon class="is-icon-left">refresh</mat-icon>
                      Refresh
                    </a>
                  </div>
                  <div class="c-camera-offline__body">
                    <p
                      class="brevier"
                      *ngIf="
                        cameraForm.get('id').value.state !== 'paused' && cameraForm.get('id').value.isOnline !== false
                      "
                    >
                      We are unable to show a camera view. Please try again in a few minutes.
                    </p>
                    <p class="brevier" *ngIf="!_isEditMode">
                      We strongly recommend the camera is online before proceeding with this process.
                    </p>
                    <div class="brevier" *ngIf="!_isEditMode">
                      Camera orientation is critical. Having the camera view visible will help to ensure your directions
                      are correct.
                    </div>
                    <ng-container *ngTemplateOutlet="orientationSettingTemplate"></ng-container>
                  </div>
                </div>
              </ng-template>
              <ng-template #orientationSettingTemplate>
                <div
                  class="c-camera__set-directions"
                  [class.is-confirmed]="isEditMode || cameraForm.get('confirmed').value"
                  [class.is-offline]="!isCameraOnline(camerasMap[cameraForm.get('id').value])"
                  [class.is-horizontal]="cameraForm.get('isPassingTraffic').value"
                  *ngIf="!isLoadingCameraFrame[cameraForm.get('id').value.id]"
                >
                  <ng-container *ngIf="!cameraForm.get('isPassingTraffic').value; else passTraffDirections">
                    <div class="c-camera__set-directions__box">
                      <div class="is-alias">
                        {{ cameraForm.get('direction1').value }}
                      </div>
                      <div class="is-direction">
                        <div class="is-label">
                          {{ cameraForm.get('reversed').value ? direction2 : direction1 }}
                        </div>
                        <mat-icon class="is-icon"> arrow_upward </mat-icon>
                      </div>
                    </div>
                    <div class="c-camera__set-directions__box">
                      <div class="is-alias">
                        {{ cameraForm.get('direction2').value }}
                      </div>
                      <div class="is-direction right-side">
                        <div class="is-label">
                          {{ cameraForm.get('reversed').value ? direction1 : direction2 }}
                        </div>
                        <mat-icon class="is-icon"> arrow_downward </mat-icon>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #passTraffDirections>
                    <div class="c-camera__set-directions__box">
                      <div class="is-alias">
                        {{ cameraForm.get('direction2').value }}
                      </div>
                      <div class="is-direction">
                        <div class="is-label">
                          {{ cameraForm.get('reversed').value ? direction1 : direction2 }}
                        </div>
                        <mat-icon class="is-icon"> arrow_back </mat-icon>
                      </div>
                    </div>
                    <div class="c-camera__set-directions__box">
                      <div class="is-alias">
                        {{ cameraForm.get('direction1').value }}
                      </div>
                      <div class="is-direction right-side">
                        <div class="is-label">
                          {{ cameraForm.get('reversed').value ? direction2 : direction1 }}
                        </div>
                        <mat-icon class="is-icon"> arrow_forward </mat-icon>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </ng-template>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</form>

<div class="o-banner-alert is-warning" *ngIf="!monitorControls.controls.length">
  <mat-icon class="is-icon"> warning </mat-icon>
  <span class="is-message">No cameras defined </span>
</div>
