<div class="o-modal-full-page">
  <div class="o-modal-full-page__header">
    <button (click)="close()" class="is-close">
      <mat-icon>clear</mat-icon>
    </button>
    <h2 class="is-title">{{ editing ? 'Edit' : 'Create a' }} notification group</h2>
    <button
      class="is-action"
      [class.spinner]="saving"
      [disabled]="notificaionGroupForm.invalid || saving"
      (click)="save()"
    >
      Save
    </button>
  </div>

  <div class="o-modal-full-page__body">
    <form class="main-content" [formGroup]="notificaionGroupForm">
      <ng-container *ngIf="!editOnlyUsers">
        <div class="u-padding--bottom" *ngIf="showOrganisationSelect">
          <div class="o-flex u-padding--bottom">
            <mat-icon class="u-grey-color"> shield </mat-icon>
            <div class="o-heading--primer u-flush--bottom">
              <h3>Organisation</h3>
            </div>
          </div>
          <mat-form-field>
            <mat-label>Select an organisation</mat-label>
            <mat-select
              ngDefaultControl
              formControlName="organisationId"
              [disabled]="accountService.organisations.length === 1"
              (selectionChange)="filterNotificationGroupsByOrganisation()"
            >
              <mat-option *ngFor="let organisation of accountService.organisations" [value]="organisation.id">
                {{ organisation.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="u-padding--bottom">
          <div class="o-flex u-padding--bottom">
            <mat-icon class="u-grey-color"> folder_shared </mat-icon>
            <div class="o-heading--primer u-flush--bottom">
              <h3>Notification group name.</h3>
            </div>
          </div>
          <mat-form-field>
            <mat-label>Group name</mat-label>
            <input autocomplete="off" matInput placeholder="Group name" formControlName="name" />
          </mat-form-field>
        </div>
      </ng-container>

      <div>
        <div class="o-flex u-padding--bottom">
          <mat-icon class="u-grey-color"> person </mat-icon>
          <div class="o-heading--primer u-flush--bottom">
            <h3>Add users to notification group.</h3>
          </div>
        </div>
        <app-select-recipients
          #selectRecipientsComponent
          [hasCreateOption]="false"
          [users]="filteredUsers"
          [notificationGroups]="filteredNotificationGroups"
          [selectedRecipients]="selectedRecipients"
        ></app-select-recipients>
      </div>
    </form>
  </div>
</div>
