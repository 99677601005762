<div class="o-step-body">
  <div class="o-form u-padding--inline">
    <div *ngIf="showOrganisationSelect">
      <div class="c-inline-icon-text u-margin--bottom">
        <mat-icon class="u-grey-color"> shield </mat-icon>
        <h3 class="primer kirk u-flush--bottom">Organisation</h3>
      </div>
      <mat-form-field>
        <mat-label>Select an organisation</mat-label>
        <mat-select ngDefaultControl (selectionChange)="filterSitesByOrganisation()" [(ngModel)]="selectedOrganisation">
          <mat-option *ngFor="let organisation of accountService.organisations" [value]="organisation">
            {{ organisation.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div>
      <div class="c-inline-icon-text u-margin--bottom">
        <mat-icon class="u-grey-color">label</mat-icon>
        <div>
          <h2 class="primer kirk u-flush--bottom">{{ resourceType === 'alert' ? 'Alert' : 'Summary' }} name</h2>
          <h3 class="brevier u-flush--bottom type--secondary">
            It's helpful for this to be descriptive if you have lots of
            {{ resourceType === 'alert' ? 'alerts' : 'summaries' }}
          </h3>
        </div>
      </div>
      <mat-form-field>
        <mat-label>{{ resourceType === 'alert' ? 'Alert' : 'Summary' }} name</mat-label>

        <input
          autocomplete="off"
          matInput
          placeholder="{{ resourceType === 'alert' ? 'Alert' : 'Summary' }} name"
          [(ngModel)]="alertName"
        />
      </mat-form-field>
    </div>

    <div>
      <div class="c-inline-icon-text u-margin--bottom">
        <mat-icon class="u-grey-color"> location_on </mat-icon>
        <h3 class="primer kirk u-flush--bottom">Where will this alert be used?</h3>
      </div>

      <mat-radio-group class="o-primary-radio-group is-input" [(ngModel)]="alertMode">
        <div class="c-cameras-options-wrapper">
          <mat-radio-button [value]="'organisaiton'">
            <div class="is-primary-text">All cameras at all sites.</div>
            <div class="is-secondary-text">This will include sites and cameras added in future.</div>
          </mat-radio-button>
          <mat-radio-button [value]="'site'">
            <div class="is-primary-text">Cameras at one site.</div>
            <div class="is-secondary-text">Select any combination of cameras at this site.</div>
          </mat-radio-button>
        </div>
      </mat-radio-group>

      <!-- <div>
        <mat-radio-group formControlName="alertUse">
          <div class="c-cameras-options-wrapper">
            <div
              class="c-cameras-option"
              [class.c-cameras-option-active]="alertMode === organisation"
              (click)="onAlertUseRadioChange(true)"
            >
              <mat-radio-button
                [value]="'isAllCamerasAllSiteInsteadCamerasOneSite'"
                [checked]="isAllCamerasAllSiteInsteadCamerasOneSite"
              ></mat-radio-button>
              <div class="c-camera-option-description">
                <div class="c-camera-option-title">All cameras at all sites.</div>
                <div class="c-camera-option-description">This will include sites and cameras added in future.</div>
              </div>
            </div>

            <div
              class="c-cameras-option"
              [class.c-cameras-option-active]="!isAllCamerasAllSiteInsteadCamerasOneSite"
              (click)="onAlertUseRadioChange(false)"
            >
              <mat-radio-button
                [value]="!isAllCamerasAllSiteInsteadCamerasOneSite"
                [checked]="!isAllCamerasAllSiteInsteadCamerasOneSite"
              ></mat-radio-button>
              <div class="c-camera-option-description">
                <div class="c-camera-option-title">Cameras at one site.</div>
                <div class="c-camera-option-description">Select any combination of cameras at this site.</div>
              </div>
            </div>
          </div>
        </mat-radio-group>
      </div> -->
    </div>

    <div *ngIf="alertMode === 'site'">
      <div class="c-inline-icon-text u-margin--bottom">
        <mat-icon class="u-grey-color">business</mat-icon>
        <h3 class="primer kirk u-flush--bottom">Select the site to use this occupancy alert at</h3>
      </div>

      <ul class="o-list c-sites-list" *ngIf="filteredSites.length; else noSites">
        <mat-radio-group [(ngModel)]="selectedSite">
          <li class="o-list__item" *ngFor="let site of filteredSites">
            <mat-radio-button [value]="site">
              {{ site.name }}
            </mat-radio-button>
          </li>
        </mat-radio-group>
      </ul>
      <ng-template #noSites>
        <app-no-site-to-select (reloadEvent)="closeDialog.emit(true)"></app-no-site-to-select>
      </ng-template>
    </div>
  </div>
</div>
