<mat-horizontal-stepper linear class="dialog-stepper" #stepper>
  <mat-step #generalStep>
    <app-step
      [stepTitle]="'Edit people count monitor'"
      [isBackable]="false"
      [nextStepText]="'Save'"
      [disabledNextStep]="!giveMonitorNameStepComponent?.monitorForm?.valid"
      (openNextStep)="save()"
      (closeStep)="close(false)"
    >
      <div class="o-form-grid u-padding--bottom">
        <div class="o-form-grid__item is-2-col">
          <app-give-monitor-name-step [monitor]="monitor"></app-give-monitor-name-step>
          <app-select-monitor-direction-step [monitor]="monitor"></app-select-monitor-direction-step>
        </div>
      </div>

      <div class="o-form-grid">
        <div class="o-form-grid__item">
          <app-select-camera-direction-step
            #updateCameraDirectionStepComponent
            [direction1]="monitor.direction1Alias"
            [direction2]="monitor.direction2Alias"
            [cameras]="monitorCameras"
            [isEditMode]="true"
            (addCameras)="goToAddCamerasStep()"
            (removeCameraAction)="onRemoveCamera($event)"
          ></app-select-camera-direction-step>
        </div>
      </div>
    </app-step>
  </mat-step>

  <mat-step #selectCamerasStep>
    <app-step
      [stepTitle]="'Add Camera to monitor'"
      [isBackable]="false"
      (closeStep)="backToMainStep()"
      (openNextStep)="goToSelectNewCameraDirectionStep()"
      *ngIf="stepper.selected === selectCamerasStep || stepper.selected === selectNewCameraDirectionStep"
    >
      <app-select-cameras-step #selectCamerasStepComponent [cameras]="unusedCameras"> </app-select-cameras-step>
    </app-step>
  </mat-step>

  <mat-step #selectNewCameraDirectionStep>
    <app-step
      #selectNewCameraDirectionStep
      *ngIf="stepper.selected === selectNewCameraDirectionStep"
      [stepTitle]="'Add Camera to monitor'"
      [nextStepText]="'Add camera'"
      (closeStep)="backToMainStep()"
      (openNextStep)="addNewCameras()"
    >
      <app-select-camera-direction-step
        #selectNewCameraDirectionStepComponent
        [direction1]="selectMonitorDirectionStepComponent.monitorForm.get('direction1Alias').value"
        [direction2]="selectMonitorDirectionStepComponent.monitorForm.get('direction2Alias').value"
        [cameras]="selectCamerasStepComponent?.monitorForm.get('cameras').value"
      >
      </app-select-camera-direction-step>
    </app-step>
  </mat-step>

  <!-- <mat-step #addAlertsStep>
    <app-step
      [stepTitle]="'Add Alert to monitor'"
      [nextStepText]="'Add alert'"
      [isBackable]="false"
      (closeStep)="backToMainStep()"
      (openNextStep)="backToMainStep()"
    >
      TODO
    </app-step>
  </mat-step> -->
</mat-horizontal-stepper>
