import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-limit-reached-information',
  templateUrl: './limit-reached-information.component.html',
  styleUrls: ['./limit-reached-information.component.scss'],
})
export class LimitReachedInformationComponent implements OnInit {
  title: string;
  message: string;
  contactText: string;
  deleteText: string;
  isIncreaseLimitRequestSent = false;

  constructor(
    private dialogRef: MatDialogRef<LimitReachedInformationComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {}

  ngOnInit(): void {
    this.title = this.data.title;
    this.contactText = this.data.contactText;
    this.deleteText = this.data.deleteText;
  }

  close(): void {
    this.dialogRef.close();
  }

  sendIncreaseLimitRequest(): void {
    window.location.href = 'mailto:support@hoxton.ai';
    this.isIncreaseLimitRequestSent = true;
  }
}
