<div class="c-camera-metrics-summary">
  <div class="is-title">
    <div class="primer kirk u-flush--bottom">Camera performance - summary</div>
  </div>
  <div class="is-summary">
    <div class="brevier u-flush--bottom">Video received last 7 days</div>
    <div class="is-summary__row">
      <ng-container *ngIf="camera.status !== 'paused'">
        <div
          [ngStyle]="{ 'width.%': sevenDaysSummary.onTimeCount }"
          *ngIf="sevenDaysSummary.onTimeCount"
          class="o-chart__video-success-bg"
        >
          {{ sevenDaysSummary.onTimeCount > 5 ? sevenDaysSummary.onTimeCount + '%' : '' }}
        </div>
        <div
          [ngStyle]="{ 'width.%': sevenDaysSummary.lateCount }"
          *ngIf="sevenDaysSummary.lateCount"
          class="o-chart__video-delay-bg"
        >
          {{ sevenDaysSummary.lateCount > 5 ? sevenDaysSummary.lateCount + '%' : '' }}
        </div>
        <div
          [ngStyle]="{ 'width.%': sevenDaysSummary.missingCount }"
          *ngIf="sevenDaysSummary.missingCount"
          class="o-chart__video-missing-bg"
        >
          {{ sevenDaysSummary.missingCount > 5 ? sevenDaysSummary.missingCount + '%' : '' }}
        </div>
      </ng-container>
    </div>
  </div>

  <div class="is-summary" *ngIf="oneMonthSummary">
    <div class="brevier u-flush--bottom">Video received last 4 weeks</div>
    <div class="is-summary__row">
      <ng-container *ngIf="camera.status !== 'paused'">
        <div
          [ngStyle]="{ 'width.%': oneMonthSummary.onTimeCount }"
          *ngIf="oneMonthSummary.onTimeCount"
          class="o-chart__video-success-bg"
        >
          {{ oneMonthSummary.onTimeCount > 5 ? oneMonthSummary.onTimeCount + '%' : '' }}
        </div>
        <div
          [ngStyle]="{ 'width.%': oneMonthSummary.lateCount }"
          *ngIf="oneMonthSummary.lateCount"
          class="o-chart__video-delay-bg"
        >
          {{ oneMonthSummary.lateCount > 5 ? oneMonthSummary.lateCount + '%' : '' }}
        </div>
        <div
          [ngStyle]="{ 'width.%': oneMonthSummary.missingCount }"
          *ngIf="oneMonthSummary.missingCount"
          class="o-chart__video-missing-bg"
        >
          {{ oneMonthSummary.missingCount > 5 ? oneMonthSummary.missingCount + '%' : '' }}
        </div>
      </ng-container>
    </div>
  </div>
  <div class="is-legend" *ngIf="camera.status !== 'decommissioned'">
    <div class="is-legend__item on-time"><span class="is-text">Received on time</span></div>
    <div class="is-legend__item late"><span class="is-text">Received late</span></div>
    <div class="is-legend__item missing"><span class="is-text">Not received</span></div>
    <div
      class="is-legend__item no-data"
      *ngIf="
        sevenDaysSummary.onTimeCount + sevenDaysSummary.lateCount + sevenDaysSummary.missingCount < 99.9 ||
        (oneMonthSummary &&
          oneMonthSummary.onTimeCount + oneMonthSummary.lateCount + oneMonthSummary.missingCount < 99.9)
      "
    >
      <span class="is-text">No data</span>
    </div>
    <div class="is-info" (click)="openUserConfirmation()">
      <mat-icon class="is-icon">info</mat-icon>
    </div>
  </div>
</div>
