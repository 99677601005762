<div class="c-side-menu">
  <div class="o-button--icon is-grey u-margin--left" *ngIf="dialogRef">
    <mat-icon (click)="close()">close</mat-icon>
  </div>
  <div>
    <ul class="o-list">
      <li
        class="o-list__item"
        (click)="navigateToPanel(item)"
        *ngFor="let item of listPanles()"
        [ngClass]="{ 'is-notification-group': item === 'notification-groups' }"
      >
        <div class="o-list--is-link">
          <div class="o-list__item-icon">
            <mat-icon class="is-icon" [ngClass]="{ 'is-selected': selected === item }">{{
              menuSpec[item].icon
            }}</mat-icon>
          </div>
          <div class="o-list__item-text">
            <h2 class="is-primary-text">
              {{ menuSpec[item].primary_text }} <span class="is-new-feature" *ngIf="menuSpec[item].is_new">NEW</span>
            </h2>
            <h2 class="is-secondary-text">
              {{ menuSpec[item].secondary_text }}
            </h2>
          </div>
          <div class="o-list__item-secondary-icon" *ngIf="selected === item">
            <mat-icon class="is-selected"> arrow_forward </mat-icon>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
