import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-camera-to-resource-dialog',
  templateUrl: './add-camera-to-resource-dialog.component.html',
  styleUrls: ['./add-camera-to-resource-dialog.component.scss'],
})
export class AddCameraToResourceDialogComponent implements OnInit {
  resources = [];
  filteredResources = [];
  resourceType;
  selection = new SelectionModel<string>(true, []);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddCameraToResourceDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.resourceType = this.data.resourceType;
    this.resources = this.data.resources;

    this.filteredResources = this.data.resources;
  }

  filterAlertRules(searchString: string): void {
    if (!searchString) {
      this.filteredResources = this.resources;
    } else {
      this.filteredResources = this.resources.filter((alertRule) =>
        alertRule.name.toLowerCase().includes(searchString.toLowerCase()),
      );
    }
  }

  onSaveButtonClick(): void {
    this.dialogRef.close(this.selection.selected);
  }
}
