import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gcp-logs',
  templateUrl: './gcp-logs.component.html',
  styleUrls: ['./gcp-logs.component.scss'],
})
export class GcpLogsComponent implements OnInit {
  @Input() fullId = '';
  private logsURL: string;
  private baseURL: string = 'https://console.cloud.google.com/logs/query';
  private query: string;
  private summaryFields: string = 'summaryFields=jsonPayload%252FMESSAGE:false:32:beginning:false';
  private gcpProject: string;
  constructor() {
    this.gcpProject = `project=${environment.gcpProject}`;
  }

  ngOnInit(): void {
    this.query = `query=resource.type%3D"generic_node"%20resource.labels.node_id%3D"${this.fullId}"`;
    this.logsURL = `${this.baseURL};${this.query};${this.summaryFields}?${this.gcpProject}`;
  }

  openGCPLogsView(): void {
    window.open(this.logsURL, '_blank');
  }
}
