<div class="auth-overlay" *ngIf="isLoading$ | async; else notLoading">
  <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
    <p style="font-size: 2rem; font-weight: 500">Loading monitors...</p>
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>
<ng-template #notLoading>
  <nav class="navigation">
    <ul class="o-breadcrumb">
      <li class="o-breadcrumb__item">
        <a routerLink="/infrastructure" class="is-link"> <mat-icon class="is-icon">dashboard</mat-icon></a>
      </li>
      <ng-container *ngIf="activeChildRoute; else noChildRoute">
        <li class="o-breadcrumb__item">
          <a
            [routerLink]="['/monitors']"
            [queryParams]="{
              type: monitorType,
            }"
            class="is-link"
            >{{ activeMonitor }}</a
          >
        </li>
        <li class="o-breadcrumb__item">{{ activeChildRoute }}</li>
      </ng-container>
      <ng-template #noChildRoute>
        <li class="o-breadcrumb__item">{{ activeMonitor }}</li>
      </ng-template>
    </ul>
  </nav>

  <div class="c-monitors-view o-view">
    <router-outlet></router-outlet>
  </div>
</ng-template>
