import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-add-first-monitors',
  templateUrl: './add-first-monitors.component.html',
  styleUrls: ['./add-first-monitors.component.scss'],
})
export class AddFirstMonitorsComponent {
  @Input() title;
  @Input() buttonText;
  @Input() text;
  @Input() imageSrc;
  @Output() buttonClick: EventEmitter<void> = new EventEmitter();
}
