<div class="c-container">
  <div class="is-controls">
    {{ title }}

    <button (click)="buttonClick.emit()" class="o-button is-small is-primary">
      <mat-icon>add</mat-icon>
      {{ buttonText }}
    </button>
  </div>

  <img [src]="imageSrc" alt="" />

  <div class="is-text">
    {{ text }}
    <a href="https://docs.hoxton.ai/user-guide/monitors" target="_blank"> Learn more about monitors. </a>
  </div>
</div>
