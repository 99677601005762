import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Alert, NotificationGroup, User } from 'src/app/api';
import { SelectRecipientsComponent } from 'src/app/components/notifications/select-recipients/select-recipients.component';

@Component({
  selector: 'app-camera-summary-settings',
  templateUrl: './camera-summary-settings.component.html',
  styleUrls: ['./camera-summary-settings.component.scss'],
})
export class CameraSummarySettingsComponent implements OnInit {
  @Input() notificationGroups: NotificationGroup[] = [];
  @Input() users: User[] = [];
  @Input() organisationId: string;
  @Input() summaryAlert: Alert;

  summaryForm: FormGroup;
  durationOptions = [
    { value: 'daily', display: 'Daily' },
    { value: 'weekly', display: 'Weekly' },
  ];
  daysOfTheWeek = [
    { value: 'Mon', display: 'Monday' },
    { value: 'Tue', display: 'Tuesday' },
    { value: 'Wed', display: 'Wednesday' },
    { value: 'Thu', display: 'Thursday' },
    { value: 'Fri', display: 'Friday' },
    { value: 'Sat', display: 'Saturday' },
    { value: 'Sun', display: 'Sunday' },
  ];

  selectedRecipients = [];

  @ViewChild(SelectRecipientsComponent)
  selectRecipientsComponent: SelectRecipientsComponent;

  @Input() set summaryAlertNotificationGroups(summaryAlertNotificationGroups: NotificationGroup[]) {
    summaryAlertNotificationGroups.forEach((summaryAlertNotificationGroup) => {
      this.selectedRecipients.push(summaryAlertNotificationGroup);
    });
  }

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
  ) {
    this.summaryForm = this.formBuilder.group({
      duration: ['', [Validators.required]],
      time: ['', [Validators.required]],
      day: [''],
    });
  }

  ngOnInit(): void {
    if (this.summaryAlert) {
      let [min, hour, dow] = this.summaryAlert.configurationDetails['schedule'].split(' ');
      if (dow === '*') {
        this.summaryForm.controls.duration.patchValue(this.durationOptions[0]);
      } else {
        this.summaryForm.controls.duration.patchValue(this.durationOptions[1]);
        this.summaryForm.controls.day.patchValue(this.daysOfTheWeek.filter((d) => d.value === dow)[0]);
      }
      this.summaryForm.controls.time.patchValue(hour + ':' + min);
      this.summaryAlert.emails?.forEach((email) => {
        const hoxtonUser = this.users.find((u) => u.email === email);
        this.selectedRecipients.push(hoxtonUser ? hoxtonUser : email);
      });
    }
  }

  navigateToNotificationGroups(): void {
    this.router.navigate(['/alerts/notification-groups']).then(() => {
      window.location.reload();
    });
  }
}
