import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { filter, Subject, takeUntil } from 'rxjs';
import { Site } from 'src/app/api';
import { PeopleCountMonitorStatus } from 'src/app/model/monitorStatus';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  selector: 'app-monitor-details-card',
  templateUrl: './monitor-details-card.component.html',
  styleUrls: ['./monitor-details-card.component.scss'],
})
export class MonitorDetailsCardComponent implements OnChanges, OnDestroy {
  @Input() selected = false;
  @Input() peopleCountMonitor: PeopleCountMonitorStatus;
  @Input() isPeopleCountDaily: boolean = true;
  @Input() dataStartDate;
  @Input() site: Site;
  @Input() useOpeningHours = false;

  @Output() changeSelected: EventEmitter<void> = new EventEmitter();
  activeTimeCounts;
  activeDirectionDataTotal: number;
  activeDirectionDataTotalLastWeek: number;
  activeDirectionDataPercentage: number;

  isLoading = true;

  private ngUnsubscribe = new Subject();
  constructor(
    public reportsService: ReportsService,
    private ref: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.isPeopleCountDaily && !changes.dataStartDate) {
      return;
    }

    this.isLoading = true;
    this.ref.detectChanges();
    this.ngUnsubscribe.next(''); // kill existing subscriptions that are still waiting for isLoading

    this.reportsService
      .getPeopleCountMonitorData(this.peopleCountMonitor, this.dataStartDate)
      .then((peopleCountMonitorData) => {
        this.activeTimeCounts = peopleCountMonitorData;
        this.activeTimeCounts.isLoading$
          .pipe(
            filter((isLoading) => !isLoading),
            takeUntil(this.ngUnsubscribe),
          )
          .subscribe((_) => {
            this.updateDirection();
            this.isLoading = false;
            this.ref.detectChanges();
          });
      });
  }

  updateDirection(): void {
    this.activeDirectionDataTotal = 0;
    this.activeDirectionDataTotalLastWeek = 0;
    this.activeTimeCounts['total'].find((result) => {
      this.activeDirectionDataTotal += result.series[0].value;
      this.activeDirectionDataTotalLastWeek += result.series[1].value;
    });

    this.activeDirectionDataPercentage = Math.min(
      Math.trunc(
        ((this.activeDirectionDataTotal - this.activeDirectionDataTotalLastWeek) * 1000) /
          this.activeDirectionDataTotalLastWeek,
      ) / 10,
      100,
    );

    this.ref.detectChanges();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
