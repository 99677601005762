import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AlertService } from './api/alert.service';
import { AlertCameraPositionMappingService } from './api/alertCameraPositionMapping.service';
import { AlertNotificationGroupMappingService } from './api/alertNotificationGroupMapping.service';
import { AlertOccupancyMonitorMappingService } from './api/alertOccupancyMonitorMapping.service';
import { AnnotationService } from './api/annotation.service';
import { CameraService } from './api/camera.service';
import { DwellTimeReportService } from './api/dwellTimeReport.service';
import { NotificationGroupService } from './api/notificationGroup.service';
import { NotificationGroupMappingService } from './api/notificationGroupMapping.service';
import { OAuthClientService } from './api/oAuthClient.service';
import { OccupancyMonitorService } from './api/occupancyMonitor.service';
import { OccupancyMonitorCameraPositionService } from './api/occupancyMonitorCameraPosition.service';
import { OrganisationService } from './api/organisation.service';
import { PeelOffMonitorService } from './api/peelOffMonitor.service';
import { PeelOffMonitorCameraPositionService } from './api/peelOffMonitorCameraPosition.service';
import { PeopleCountMonitorService } from './api/peopleCountMonitor.service';
import { PeopleCountMonitorCameraPositionService } from './api/peopleCountMonitorCameraPosition.service';
import { ReportService } from './api/report.service';
import { SiteService } from './api/site.service';
import { UserService } from './api/user.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [],
})
export class ApiModule {
  public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: ApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575',
      );
    }
  }
}
