import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OccupancyMonitor } from 'src/app/api';
import { GlobalMethods } from 'src/app/global-methods';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-update-occupancy-monitor-details',
  templateUrl: './update-occupancy-monitor-details.component.html',
  styleUrls: ['./update-occupancy-monitor-details.component.scss'],
})
export class UpdateOccupancyMonitorDetailsComponent implements OnInit {
  monitorForm: FormGroup;
  @Input() monitor: OccupancyMonitor;

  constructor(
    private formBuilder: FormBuilder,
    public accountService: AccountService,
  ) {}

  ngOnInit(): void {
    this.monitorForm = this.formBuilder.group({
      name: [this.monitor.name, [Validators.required]],
      capacity: [this.monitor.capacity, [Validators.pattern('^[0-9]*$'), Validators.min(0)]],
      dailyResetMin: [GlobalMethods.minutesToTimeString(this.monitor.dailyResetMin), [Validators.required]],
      reportingStartDate: [this.monitor.reportingStartDate],
      reportingEndDate: [this.monitor.reportingEndDate],
    });
  }
}
