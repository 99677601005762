<div class="auth-overlay" *ngIf="isLoading; else notLoading">
  <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
    <p style="font-size: 2rem; font-weight: 500">Loading...</p>
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>
<ng-template #notLoading>
  <div [ngClass]="{ 'o-content-panel': !summaryAlerts.length }">
    <div class="o-content-panel__head has-no-border u-margin--bottom">
      <h2 class="long-primer kirk u-flush--bottom">Camera summaries</h2>
      <div class="is-action o-button has-outline" (click)="addEditSummaryAlert()">
        <mat-icon class="is-icon-left">note_add</mat-icon>
        add health summary
      </div>
    </div>

    <!-- <div class="o-banner-alert is-warning no-gap u-margin--bottom" *ngIf="camerasWithNoSummaryAlert.length">
      <div class="is-message has-flex">
        {{ getNoCamerasSummariesSetText() }}

        <button class="o-button" (click)="goToNoCamerasSummariesSet()">
          VIEW {{ camerasWithNoSummaryAlert.length | pluraliseWord : 'camera' }}
        </button>
      </div>
    </div> -->

    <ng-container *ngIf="summaryAlerts.length; else noSummaries">
      <div class="o-expansion-wrap">
        <mat-accordion class="o-expansion">
          <mat-expansion-panel
            *ngFor="let summaryAlert of summaryAlerts"
            class="o-expansion__item"
            id="{{ summaryAlert.id }}"
            [(expanded)]="summariesPanel[summaryAlert.id]"
            (afterExpand)="scroll(summaryAlert.id)"
          >
            <mat-expansion-panel-header
              class="o-expansion__head is-online mat-expansion-panel-header-border"
              [collapsedHeight]="'fit-content'"
              [expandedHeight]="'fit-content'"
            >
              <mat-panel-title class="o-expansion__title">
                <mat-icon class="is-icon is-online">notifications_active</mat-icon>
                <div class="is-title-text">
                  <h2 class="c-camera-alert-title">
                    <span>{{ summaryAlert.name }}</span>
                  </h2>
                </div>

                <div class="is-info-bar c-title-bar">
                  <div class="c-title-bar__option">
                    <span class="is-label">Sites:</span>
                    <span class="is-value">{{
                      getCameraSummariesSitesIds(summaryAlert.id).length === sites.length
                        ? 'All'
                        : getCameraSummariesSitesIds(summaryAlert.id).length
                    }}</span>
                  </div>
                  <div class="c-title-bar__option">
                    <span class="is-label">Cameras:</span>
                    <span class="is-value">{{
                      this.summaryCameras[summaryAlert.id].length === cameras.length
                        ? 'All'
                        : this.summaryCameras[summaryAlert.id].length
                    }}</span>
                  </div>
                  <div class="c-title-bar__option">
                    <span class="is-value">
                      {{ getDurationFromSchedule(summaryAlert.configurationDetails['schedule']) }}
                    </span>
                    <ng-container
                      *ngIf="getDurationFromSchedule(summaryAlert.configurationDetails['schedule']) === 'Weekly'"
                    >
                      <span class="is-label">on</span>
                      <span class="is-value">
                        {{ getDayFromSchedule(summaryAlert.configurationDetails['schedule']) }}
                      </span>
                    </ng-container>
                    <span class="is-label">at</span>
                    <span class="is-value">
                      {{ getTimeOfDayFromSchedule(summaryAlert.configurationDetails['schedule']) }}
                    </span>
                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <form class="o-expansion__body">
              <div class="c-alert__email-summary">
                <div class="c-alert__recipients">
                  <mat-icon class="is-icon">contacts</mat-icon>
                  <div class="is-label">Recipients</div>
                  <div class="is-recipients">
                    <span class="is-recipients__item" *ngFor="let email of summaryAlert.emails">{{ email }}</span>
                    <span
                      class="is-recipients__item"
                      *ngFor="let summaryAlertNotificationGroup of summaryAlertNotificationGroupsMap[summaryAlert.id]"
                      >{{ notificationGroupsMap[summaryAlertNotificationGroup.notificationGroupId].name }} ({{
                        notificationGroupsMap[summaryAlertNotificationGroup.notificationGroupId].emails.length
                      }})</span
                    >
                  </div>
                </div>
                <div class="o-button--icon is-menu">
                  <mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon>
                </div>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="addEditSummaryAlert(summaryAlert)">Edit summary</button>
                  <button mat-menu-item (click)="deleteSummaryAlert(summaryAlert)">Delete summary</button>
                </mat-menu>
                <div class="c-alert__setup">
                  <div class="c-alert__setup-item">
                    <div class="is-label">Health summary alerts</div>
                    <div class="is-sent">
                      <span class="is-sent-label">Sent</span>
                      <span class="has-background">
                        {{ getDurationFromSchedule(summaryAlert.configurationDetails['schedule']) }}
                      </span>
                      <ng-container
                        *ngIf="getDurationFromSchedule(summaryAlert.configurationDetails['schedule']) === 'Weekly'"
                      >
                        EVERY
                        <span class="has-background">{{
                          getDayFromSchedule(summaryAlert.configurationDetails['schedule'])
                        }}</span>
                      </ng-container>
                      AT
                      <span class="has-background">
                        {{ getTimeOfDayFromSchedule(summaryAlert.configurationDetails['schedule']) }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="c-alert__sites">
                <mat-icon class="is-icon">apartment</mat-icon>
                <h3 class="is-title">Sites included</h3>
                <div class="is-sites">
                  <ng-container
                    *ngIf="getCameraSummariesSitesIds(summaryAlert.id).length !== sites.length; else allSites"
                  >
                    <ul class="c-alert__sites-list">
                      <li
                        class="c-alert__sites-item"
                        *ngFor="let siteId of getCameraSummariesSitesIds(summaryAlert.id)"
                      >
                        {{ sitesMap[siteId].name }}
                      </li>
                    </ul>
                  </ng-container>

                  <ng-template #allSites>
                    <div class="c-alert__all-sites">All sites</div>
                  </ng-template>
                </div>
              </div>

              <div class="c-alert__cameras">
                <mat-icon class="is-icon">photo_camera</mat-icon>
                <h3 class="is-title">Cameras selected</h3>
                <div class="is-cameras">
                  <ng-container *ngIf="summaryCameras[summaryAlert.id].length !== cameras.length; else allCameras">
                    <div
                      *ngFor="let siteId of getCameraSummariesSitesIds(summaryAlert.id)"
                      class="c-alert__cameras-site"
                    >
                      <div class="is-title">
                        <h3 class="is-site-name">{{ sitesMap[siteId].name }}</h3>
                        <span class="is-camera-count">
                          <ng-container
                            *ngIf="
                              getCamerasForSite(summaryAlert.id, siteId).length !== sitesCameras[siteId].length;
                              else allCamerasForSite
                            "
                          >
                            {{ getCamerasForSite(summaryAlert.id, siteId).length }} of
                            {{ sitesCameras[siteId].length }}
                            {{ sitesCameras[siteId].length | pluraliseWord: 'camera' }}
                          </ng-container>

                          <ng-template #allCamerasForSite><span class="is-all-cameras">all cameras</span></ng-template>
                        </span>
                      </div>
                      <ul
                        *ngIf="getCamerasForSite(summaryAlert.id, siteId).length !== sitesCameras[siteId].length"
                        class="c-alert__cameras-list"
                      >
                        <li
                          class="c-alert__cameras-item"
                          *ngFor="let camera of getCamerasForSite(summaryAlert.id, siteId)"
                        >
                          {{ camera.name }}
                        </li>
                      </ul>
                    </div>
                  </ng-container>

                  <ng-template #allCameras>
                    <span class="info-text">All cameras</span>
                  </ng-template>
                </div>
              </div>
            </form>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </ng-container>

    <ng-template #noSummaries>
      <div class="o-content-panel__body">
        <div class="o-feature-panel has-border is-muted">
          <div class="o-feature-panel__head">
            <span class="material-icons is-icon">notifications_off</span>
            <h3 class="is-label">No camera health summaries created</h3>
          </div>
          <div class="o-feature-panel__body">
            <div class="o-feature-panel__media">
              <img src="/assets/images/no-alerts-placeholder.png" alt="No summaries created" class="is-media" />
            </div>
            <div class="o-feature-panel__message">
              <h3 class="pica">We strongly recommend setting up summaries for all your cameras.</h3>
              <p>
                These will give you an overview of problems affecting your cameras across all your sites and help you
                identify ongoing or recurring issues.
              </p>
              <button class="o-button has-outline is-small is-primary-action" (click)="addEditSummaryAlert()">
                <span class="material-icons is-icon-left">note_add</span>
                add health summary
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</ng-template>
