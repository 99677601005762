<div class="c-site-setup-side-panel">
  <div class="c-site-info">
    <div class="c-text-site">Site</div>
    <div class="c-text-site-name">{{ site?.name }}</div>
  </div>
  <div class="c-progress">
    <div class="c-progress-bar-wrapper">
      <div class="c-progress-bar"></div>
      <div class="c-progress-bar-pointer-wrapper">
        <div class="c-progress-bar-pointer" [style.width.%]="setupProgress">
          <ng-container *ngIf="setupProgress > 0"> {{ setupProgress }} % </ng-container>
        </div>
      </div>
    </div>
  </div>
  <ul class="c-site-steps">
    <li *ngFor="let step of steps" class="c-step">
      <mat-icon
        [ngClass]="{
          'c-step-icon-ready': step.type === StepTypeEnum.ready,
          'c-step-icon-current': step.type === StepTypeEnum.current,
        }"
        class="c-step-icon"
      >
        {{ step.icon }}
      </mat-icon>
      <div class="c-text-step">{{ step.name }}</div>
      <mat-icon *ngIf="step.type === StepTypeEnum.ready" class="c-icon-check">check</mat-icon>
    </li>
  </ul>
</div>
