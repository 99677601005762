import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-sort-by',
  templateUrl: './sort-by.component.html',
  styleUrls: ['./sort-by.component.scss'],
})
export class SortByComponent {
  isSupport: boolean;
  constructor(
    public dialogRef: MatDialogRef<SortByComponent>,
    private accountService: AccountService,
  ) {
    this.isSupport = this.accountService.isSupport;
  }

  close(sort?: string): void {
    this.dialogRef.close(sort);
  }
}
