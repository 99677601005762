<div class="auth-overlay" *ngIf="isLoading; else notLoading">
  <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
    <p style="font-size: 2rem; font-weight: 500">Loading alerts...</p>
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>
<ng-template #notLoading>
  <div class="o-view--2col">
    <nav class="navigation is-breadcrumb">
      <ul class="o-breadcrumb">
        <li class="o-breadcrumb__item">
          <a routerLink="/infrastructure" class="is-link"> <mat-icon class="is-icon">dashboard</mat-icon></a>
        </li>
        <li class="o-breadcrumb__item">Alerts</li>
      </ul>
    </nav>
    <div class="is-title" *ngIf="!showSideMenu">
      <div class="o-flex">
        <div class="o-button is-primary is-small" (click)="openSideMenu()">
          <mat-icon class="is-icon-left">dehaze</mat-icon>
          Alerts menu
        </div>
      </div>
    </div>
    <div class="is-aside" [ngClass]="{ 'has-left-menu': showSideMenu }" *ngIf="showSideMenu">
      <app-notifications-side-menu></app-notifications-side-menu>
    </div>
    <div class="is-main">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-template>
