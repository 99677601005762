<div class="auth-overlay" *ngIf="isLoading; else notLoading">
  <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
    <p style="font-size: 2rem; font-weight: 500">Loading...</p>
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>
<ng-template #notLoading>
  <div [ngClass]="{ 'o-content-panel': !offlineAlerts.length }">
    <div class="o-content-panel__head has-no-border u-margin--bottom">
      <div class="o-heading--long-primer u-flush--bottom">
        <h2>Camera alerts</h2>
      </div>
      <div class="is-action o-button has-outline" (click)="addEditCameraAlertsOffline()">
        <mat-icon class="is-icon-left">add_alert</mat-icon>
        add camera alert
      </div>
    </div>

    <!-- <div class="o-banner-alert is-warning no-gap u-margin--bottom" *ngIf="camerasWithNoOfflineAlerts.length">
      <span class="is-message has-flex">
        {{ getNoCamerasAlertsSetText() }}
      </span>
      <button class="o-button" (click)="goToNoCamerasAlertsSet()">
        VIEW {{ camerasWithNoOfflineAlerts.length | pluraliseWord : 'camera' }}
      </button>
    </div> -->

    <ng-container *ngIf="offlineAlerts.length; else noRules">
      <div class="o-expansion-wrap">
        <mat-accordion class="o-expansion">
          <mat-expansion-panel
            *ngFor="let offlineAlert of offlineAlerts"
            class="o-expansion__item"
            id="{{ offlineAlert.id }}"
            [(expanded)]="alertsPanel[offlineAlert.id]"
            (afterExpand)="scroll(offlineAlert.id)"
          >
            <mat-expansion-panel-header
              class="o-expansion__head is-online mat-expansion-panel-header-border"
              [collapsedHeight]="'fit-content'"
              [expandedHeight]="'fit-content'"
            >
              <mat-panel-title class="o-expansion__title">
                <mat-icon class="is-icon is-online">notifications_active</mat-icon>
                <div class="is-title-text">
                  <h2 class="c-camera-alert-title">
                    <span>{{ offlineAlert.name }}</span>
                  </h2>
                </div>

                <div class="is-info-bar c-title-bar">
                  <div class="c-title-bar__option">
                    <span class="is-label">Sites:</span>
                    <span class="is-value">{{
                      getCameraAlertsSitesIds(offlineAlert.id).length === sites.length
                        ? 'All'
                        : getCameraAlertsSitesIds(offlineAlert.id).length
                    }}</span>
                  </div>
                  <div class="c-title-bar__option">
                    <span class="is-label">Cameras:</span>
                    <span class="is-value">{{
                      this.alertCameras[offlineAlert.id].length === cameras.length
                        ? 'All'
                        : this.alertCameras[offlineAlert.id].length
                    }}</span>
                  </div>
                  <div class="c-title-bar__option">
                    <span class="is-label">Alerts:</span>
                    <span class="is-value">
                      after {{ offlineAlert.configurationDetails['duration'] | alertDuration }}
                    </span>
                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <form class="o-expansion__body">
              <div class="c-alert__email-summary">
                <div class="c-alert__recipients">
                  <mat-icon class="is-icon">contacts</mat-icon>
                  <div class="is-label">Recipients</div>
                  <div class="is-recipients">
                    <span class="is-recipients__item" *ngFor="let email of offlineAlert.emails">{{ email }}</span>
                    <span
                      class="is-recipients__item"
                      *ngFor="let offlineAlertNotificationGroup of offlineAlertNotificationGroupsMap[offlineAlert.id]"
                      >{{ notificationGroupsMap[offlineAlertNotificationGroup.notificationGroupId].name }} ({{
                        notificationGroupsMap[offlineAlertNotificationGroup.notificationGroupId].emails.length
                      }})</span
                    >
                  </div>
                </div>
                <div class="o-button--icon is-menu">
                  <mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon>
                </div>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="addEditCameraAlertsOffline(offlineAlert)">Edit alert</button>
                  <button mat-menu-item (click)="deleteOfflineAlert(offlineAlert)">Delete alert</button>
                </mat-menu>
                <div class="c-alert__setup">
                  <div class="c-alert__setup-item">
                    <div class="is-label">Real time alerts</div>
                    <div class="is-sent">
                      <span class="is-sent-label">Sent</span>
                      <span class="is-sent-duration">
                        After <b>{{ offlineAlert.configurationDetails['duration'] }}</b></span
                      >
                    </div>
                  </div>
                  <div class="c-alert__updates">
                    <ng-container *ngIf="offlineAlert.configurationDetails['notifyAlertRecovery']; else noRecovery">
                      <mat-icon class="is-icon is-positive">check_circle</mat-icon>
                      <span class="is-label">Send update when camera is back online</span>
                    </ng-container>
                    <ng-template #noRecovery>
                      <mat-icon class="is-icon">cancel</mat-icon>
                      <span class="is-label">Don't send update when camera is back online</span>
                    </ng-template>
                  </div>
                </div>
              </div>
              <div class="c-alert__sites">
                <mat-icon class="is-icon">apartment</mat-icon>
                <h3 class="is-title">Sites included</h3>
                <div class="is-sites">
                  <ng-container *ngIf="getCameraAlertsSitesIds(offlineAlert.id).length !== sites.length; else allSites">
                    <ul class="c-alert__sites-list">
                      <li class="c-alert__sites-item" *ngFor="let siteId of getCameraAlertsSitesIds(offlineAlert.id)">
                        {{ sitesMap[siteId].name }}
                      </li>
                    </ul>
                  </ng-container>

                  <ng-template #allSites>
                    <div class="c-alert__all-sites">All sites</div>
                  </ng-template>
                </div>
              </div>

              <div class="c-alert__cameras">
                <mat-icon class="is-icon">photo_camera</mat-icon>
                <h3 class="is-title">Cameras selected</h3>
                <div class="is-cameras">
                  <ng-container *ngIf="alertCameras[offlineAlert.id].length !== cameras.length; else allCameras">
                    <div *ngFor="let siteId of getCameraAlertsSitesIds(offlineAlert.id)" class="c-alert__cameras-site">
                      <div class="is-title">
                        <h3 class="is-site-name">{{ sitesMap[siteId].name }}</h3>
                        <span class="is-camera-count">
                          <ng-container
                            *ngIf="
                              getCamerasForSite(offlineAlert.id, siteId).length !== sitesCameras[siteId].length;
                              else allCamerasForSite
                            "
                          >
                            {{ getCamerasForSite(offlineAlert.id, siteId).length }} of
                            {{ sitesCameras[siteId].length }}
                            {{ sitesCameras[siteId].length | pluraliseWord: 'camera' }}
                          </ng-container>

                          <ng-template #allCamerasForSite><span class="is-all-cameras">all cameras</span></ng-template>
                        </span>
                      </div>
                      <ul
                        *ngIf="getCamerasForSite(offlineAlert.id, siteId).length !== sitesCameras[siteId].length"
                        class="c-alert__cameras-list"
                      >
                        <li
                          class="c-alert__cameras-item"
                          *ngFor="let camera of getCamerasForSite(offlineAlert.id, siteId)"
                        >
                          {{ camera.name }}
                        </li>
                      </ul>
                    </div>
                  </ng-container>

                  <ng-template #allCameras>
                    <span class="info-text">All cameras</span>
                  </ng-template>
                </div>
              </div>
            </form>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </ng-container>

    <ng-template #noRules>
      <div class="o-content-panel__body">
        <div class="o-feature-panel has-border is-muted">
          <div class="o-feature-panel__head">
            <span class="material-icons is-icon">notifications_off</span>
            <h3 class="is-label">No camera alerts created</h3>
          </div>
          <div class="o-feature-panel__body">
            <div class="o-feature-panel__media">
              <img src="/assets/images/no-alerts-placeholder.png" alt="No alerts created" class="is-media" />
            </div>
            <div class="o-feature-panel__message">
              <h3 class="pica">We strongly recommend setting up alerts for all your cameras.</h3>
              <p>
                These will alert you whenever cameras are offline or losing video data and ensure you can resolve the
                issue as quickly as possible and avoid losing traffic data.
              </p>
              <button class="o-button has-outline is-small is-primary-action" (click)="addEditCameraAlertsOffline()">
                <span class="material-icons is-icon-left">add_alert</span>
                Create alert
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</ng-template>
