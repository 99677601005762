<div class="c-metrics-card" [ngStyle]="{ 'background-color': backgroundColor }">
  <ng-container *ngIf="!isLoadingMetrics; else loading">
    <div class="c-metrics-card__title">
      <div class="minion--caps">{{ titel }}</div>
      <div class="minion">{{ titelDate }}</div>
    </div>
    <div class="brevier--caps">Overall camera health: {{ videoUploaded < 90 ? 'POOR' : 'GOOD' }}</div>
    <div class="c-metrics-card__video-uploaded" *ngIf="videoUploaded > 99">
      <span class="is-percentage">{{ videoUploaded | number: '1.0-0' }}%</span> video uploaded.
    </div>
    <a class="c-metrics-card__video-uploaded is-poor" [routerLink]="['/cameras']" *ngIf="videoUploaded <= 99">
      <span class="is-percentage">{{ videoUploaded | number: '1.0-0' }}</span>
      <span class="is-pc-symbol">% </span>
      <span class="is-pc-label">video uploaded.</span>
    </a>

    <!-- <div>{{ peopleCounted | number: '1.0-1' }} people counted.</div> -->
  </ng-container>

  <ng-template #loading>
    <div class="auth-overlay no-background">
      <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
        <div class="dot-spin"></div>
      </div>
    </div>
  </ng-template>
</div>
