<form class="o-form" [formGroup]="monitorForm">
  <div class="o-form__legend-icon">
    <mat-icon class="is-icon">import_export</mat-icon>
    <h2 class="is-legend">Monitor directions.</h2>
    <h3 class="is-support-text">What are the footflow directions for this monitor?</h3>
    <div class="is-input">
      <p class="brevier">
        Footflow directions might be <b>East/West</b>, <b>Into gallery/Out of gallery</b>,
        <b>Toward NE corner/Away from NE corner</b> - or any description relevant to your site or space.
      </p>
      <div class="c-inputs">
        <mat-form-field class="is-input" appearance="outline">
          <mat-label>Direction 1</mat-label>
          <input autocomplete="off" matInput formControlName="direction1Alias" />
        </mat-form-field>
        <mat-form-field class="is-input" appearance="outline">
          <mat-label>Direction 2</mat-label>
          <input autocomplete="off" matInput formControlName="direction2Alias" />
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
