import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SnackbarDeleteComponent } from 'src/app/components/general/snackbar-delete/snackbar-delete.component';
import { CustomSnackbarComponent } from 'src/app/components/notifications/custom-snackbar/custom-snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class NotifyService {
  constructor(private snackBar: MatSnackBar) {}

  public success(message: string, duration: number = 5, showButton: boolean = true) {
    this.notify(message, 'snack-bar-success', duration, showButton);
  }

  public warning(message: string, duration: number = 5, showButton: boolean = true) {
    this.notify(message, 'snack-bar-warning', duration, showButton);
  }

  public error(error: any, duration: number = 7, showButton: boolean = true) {
    let message: string;
    if (typeof error === 'string') {
      message = error;
    } else if (typeof error === 'object' && error.error && error.error.message) {
      message = error.error.message;
    } else {
      message = 'An error has occurred!';
    }

    if (error.name && error.name !== 'HttpErrorResponse') {
      console.log(error);
    }

    this.notify(message, 'snack-bar-error', duration, showButton);
  }

  public delete(message: string): Observable<any> {
    return this.snackBar
      .openFromComponent(SnackbarDeleteComponent, {
        data: { message },
        verticalPosition: 'bottom',
        horizontalPosition: 'left',
      })
      .afterDismissed()
      .pipe(switchMap((dismiss) => of(dismiss.dismissedByAction)));
  }

  public offline(): MatSnackBarRef<any> {
    return this.snackBar.openFromComponent(CustomSnackbarComponent, {
      duration: 6000,
      data: {
        html: '<h4>Camera offline.</h4><p>Follow steps to connect.</p>',
        class: 'c-camera-offline',
        icon: 'wifi_off',
        buttonText: 'retry',
      },
    });
  }

  public online(): void {
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      duration: 3000,
      data: {
        html: '<h4>Camera online.</h4><p>Ready for next step.</p>',
        class: 'c-camera-online',
        icon: 'wifi',
      },
    });
  }

  private notify(message: string, className: string, duration: number, showButton: boolean): void {
    this.snackBar.open(message, showButton ? 'X' : undefined, {
      duration: duration * 1000,
      panelClass: className,
      verticalPosition: 'bottom',
      horizontalPosition: 'left',
    });
  }
}
