import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import * as moment from 'moment';
import { Subject, Observable, forkJoin, finalize, takeUntil } from 'rxjs';
import { ReportService, Site, ReportFilter, PeelOffMonitorCameraPosition } from 'src/app/api';
import { UserConfirmationComponent } from 'src/app/components/general/user-confirmation/user-confirmation.component';
import { GlobalMethods } from 'src/app/global-methods';
import { PeelOffMonitorStatus } from 'src/app/model/monitorStatus';
import { DownloadFileService } from 'src/app/services/download-file.service';
import { NotifyService } from 'src/app/services/notify.service';
import { ReportsService } from 'src/app/services/reports.service';
import { AccountService } from 'src/app/services/account.service';

declare var gtag: Function;

@Component({
  selector: 'app-download-peel-off-data',
  templateUrl: './download-peel-off-data.component.html',
  styleUrls: ['./download-peel-off-data.component.scss'],
})
export class DownloadPeelOffDataComponent implements OnInit, OnDestroy {
  @Input() selectedIds;
  saving = false;

  peelOffMonitors: PeelOffMonitorStatus[] = [];
  peelOffMonitorCameraPositionsMap: { [_: string]: PeelOffMonitorCameraPosition[] } = {};
  peelOffMonitorsMap: { [_: string]: PeelOffMonitorStatus } = {};

  sitesMap: { [_: string]: Site } = {};

  downloading: boolean;
  downloadButtonText: string;
  ngUnsubscribe = new Subject();
  useOpeningHours = false;

  tableDataSource = new MatTableDataSource<any>();
  tableHeaderNames = ['select', 'monitorName', 'siteName', 'cameras', 'connectivity'];
  selectedTimeGrain: ReportFilter.TimeGrainEnum = '1h';
  timeGrains = Object.values(ReportFilter.TimeGrainEnum);
  currentDateTimeMidnight = new Date(new Date().setHours(0, 0, 0, 0));
  range = new FormGroup({
    from: new FormControl(this.currentDateTimeMidnight),
    to: new FormControl(this.currentDateTimeMidnight),
  });

  selection = new SelectionModel<string>(true, []);
  noMonitorSelectedError = false;
  @ViewChild('tablePaginator') set paginator(pager: MatPaginator) {
    if (pager) {
      this.tableDataSource.paginator = pager;
    }
  }

  constructor(
    public accountService: AccountService,
    private df: DownloadFileService,
    private reportsService: ReportsService,
    private notifyService: NotifyService,
    private reportService: ReportService,
    private ref: ChangeDetectorRef,
    private matDialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.peelOffMonitors = this.reportsService.peelOffMonitors;
    this.peelOffMonitorCameraPositionsMap = this.reportsService.peelOffMonitorCameraPositionsMap;
    this.peelOffMonitorsMap = this.reportsService.peelOffMonitorsMap;
    this.sitesMap = this.reportsService.sitesMap;

    const dataSource: { [_: string]: string }[] = [];
    this.peelOffMonitors.forEach((peelOffMonitor) => {
      dataSource.push({
        id: peelOffMonitor.id,
        monitorName: peelOffMonitor.name,
        siteName: this.sitesMap[peelOffMonitor.siteId].name,
        cameras: `${this.peelOffMonitorCameraPositionsMap[peelOffMonitor.id].length} ${GlobalMethods.pluraliseWord(
          this.peelOffMonitorCameraPositionsMap[peelOffMonitor.id].length,
          'camera',
        )}`,
        connectivity:
          this.peelOffMonitorCameraPositionsMap[peelOffMonitor.id].length === 0
            ? 'No cameras linked'
            : peelOffMonitor.status === 'online'
              ? 'Online'
              : 'Camera issues',
        disabled: this.peelOffMonitorCameraPositionsMap[peelOffMonitor.id].length === 0 ? 'true' : 'false',
      });
    });

    this.tableDataSource.data = dataSource;
    this.setDownloading(false);

    if (this.selectedIds) {
      this.selectedIds.forEach((id) => this.selection.toggle(id));
    }
  }

  runSearch(ss: string): void {
    this.tableDataSource.filter = ss.trim().toLowerCase();
  }

  downloadCSV(toolTip?: MatTooltip) {
    if (this.selection.selected.length === 0) {
      this.noMonitorSelectedError = true;
      toolTip.disabled = false;
      toolTip.show();
      return;
    }
    if (this.downloading) {
      return;
    }

    this.noMonitorSelectedError = false;

    const roundedEndDate = new Date(this.range.value.to);
    roundedEndDate.setDate(roundedEndDate.getDate() + 1); // round date up so that the upper date is included in returned data
    const isoStartTime = this.range.value.from.toISOString();
    const isoEndTime = roundedEndDate.toISOString();

    gtag('event', 'file_download', {
      file_name: 'peel_off_report',
      csv_start_time: isoStartTime,
      csv_end_time: isoEndTime,
      time_grain: this.selectedTimeGrain,
      monitors: this.selection.selected,
    });

    this.setDownloading(true);
    const tzList = [];
    const tzRequests = {};
    const requests: Observable<any>[] = [];

    this.selection.selected.forEach((monitorId) => {
      const siteTz = this.sitesMap[this.peelOffMonitorsMap[monitorId].siteId].tz;

      if (siteTz in tzRequests) {
        tzRequests[siteTz].push(monitorId);
      } else {
        tzRequests[siteTz] = [monitorId];
        tzList.push(siteTz);
      }
    });

    tzList.forEach((tz) => {
      requests.push(
        this.reportService.createPeelOffReport({
          peelOffMonitorIds: tzRequests[tz],
          startDate: moment(this.range.value.from).format('YYYY-MM-DD'),
          endDate: moment(roundedEndDate).format('YYYY-MM-DD'),
          timeGrain: this.selectedTimeGrain === '1d' ? '24h' : this.selectedTimeGrain,
          isOpen: this.useOpeningHours,
        }),
      );
    });

    forkJoin(requests)
      .pipe(
        finalize(() => {
          this.setDownloading(false);
          this.ref.detectChanges();
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (reports) => {
          var reportForDownload = [];

          reports.forEach((report, index) => {
            report.forEach((row) => {
              reportForDownload.push({
                ...row,
                time: GlobalMethods.changeTimezone(row['time'], tzList[index]),
              });
            });
          });

          if (reportForDownload.length === 0) {
            this.notifyService.warning('No data available for the selected moniotrs.');
            return;
          }

          let data = reportForDownload.map(
            ({ time: time, id: id, name: name, base: passing, peelOff: entrance, rate: peel_off_rate }) => ({
              time,
              id,
              name,
              passing,
              entrance,
              peel_off_rate,
            }),
          );
          let header = new Set<string>([]);
          data.forEach((r) => Object.keys(r).forEach((k) => header.add(k)));

          this.df.downloadDataAsCSVFile(data, `MonitorsData.csv`, [...header]);
        },
        error: (error) => {
          this.notifyService.error(error);
        },
      });
  }

  openUserConfirmation(): void {
    const messages = `You can use the site opening hours to filter the monitors data.

    This allows you to refine the data, offering a deeper understanding
    of peel off based on the operational hours of your sites.`;

    this.matDialog.open(UserConfirmationComponent, {
      data: { message: messages, isDelete: true },
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableDataSource.data.filter((row) => row.disabled !== 'true').length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.tableDataSource.data.forEach((row) => {
          if (row.disabled !== 'true') {
            this.selection.select(row.id);
          }
        });
  }

  setDownloading(state: boolean) {
    this.downloading = state;
    this.setDownloadButtonText();
  }

  setDownloadButtonText() {
    this.downloadButtonText = this.downloading ? 'Downloading...' : 'Download csv';
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
