<mat-horizontal-stepper class="dialog-stepper" #stepper>
  <mat-step>
    <app-step
      [stepTitle]="'Camera setup'"
      [processingStep]="processingStep"
      [isBackable]="false"
      [isStepButtonBold]="false"
      [isStepButtonBordered]="false"
      (closeStep)="close()"
      (openNextStep)="goToNextStep(15)"
    >
      <div class="c-modal-content">
        <app-camera-setup-side-panel [hideAlerts]="!this.cameras.length"></app-camera-setup-side-panel>
        <div class="c-modal-main">
          <div class="c-step-header">
            <div class="c-text-title-wrapper">
              <div class="c-text-step brevier--caps">Step 1</div>
              <div class="kirk">Claim the camera.</div>
            </div>
            <a href="https://docs.hoxton.ai/setup-guide" target="_blank">
              <button class="o-button is-muted is-contained">
                <span class="material-icons is-icon-left">live_help</span>
                HELP
              </button>
            </a>
          </div>
          <div class="c-page-banner is-positive">
            <p>We'll take you through the process of registering your new camera.</p>
            <p>You can exit the setup process at any point and your progress will be saved.</p>
          </div>

          <div class="c-icon-lead-section">
            <div class="is-icon">
              <mat-icon class="">tag</mat-icon>
            </div>
            <div class="c-enter-serial x-number-wrapper">
              <div>
                <p>Start by entering the camera serial number.</p>
                <mat-form-field>
                  <mat-label>Serial number</mat-label>
                  <input autocomplete="off" [(ngModel)]="cameraSN" matInput placeholder="Serial number" />
                </mat-form-field>
              </div>
              <div class="c-serial-number-tip-wrapper">
                <img class="c-img-camera-serial" src="assets/images/camera-serial.png" alt="Camera serial" />

                <div class="c-text-serial-number-found">
                  The serial number will be found on the back of your camera.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-step>
  </mat-step>

  <mat-step>
    <ng-template matStepContent>
      <app-step
        [stepTitle]="'Camera setup'"
        [processingStep]="processingStep"
        [isBackable]="false"
        [isStepButtonBold]="false"
        [isStepButtonBordered]="false"
        [disabledNextStep]="!camera.name || !camera.siteId"
        (closeStep)="close()"
        (openNextStep)="createCamera()"
      >
        <div class="c-modal-content">
          <app-camera-setup-side-panel
            [setupProgress]="setupProgress"
            [hideAlerts]="!this.cameras.length"
            (openPreviousStep)="goToPreviousStep(0)"
          ></app-camera-setup-side-panel>
          <div class="c-modal-main">
            <div class="c-step-header c-step-two-header">
              <div class="c-text-title-wrapper">
                <div class="c-text-step brevier--caps">Step 2</div>
                <div class="kirk">Finish claiming the camera.</div>
              </div>
              <a href="https://docs.hoxton.ai/setup-guide" target="_blank">
                <button class="o-button is-muted is-contained">
                  <span class="material-icons is-icon-left">live_help</span>
                  HELP
                </button>
              </a>
            </div>

            <div class="c-page-banner has-icon">
              <mat-icon class="c-icon-gold">tag</mat-icon>
              <div class="primer u-flush--bottom type--monospace">{{ cameraSN }}</div>
            </div>

            <div class="c-icon-lead-section">
              <div class="is-icon">
                <mat-icon>apartment</mat-icon>
              </div>
              <div class="is-content-constrained">
                <p>Where will be the camera used.</p>
                <mat-form-field class="c-dropdown-site" *ngIf="accountService.isSupport">
                  <mat-label>Organisation name</mat-label>
                  <mat-select (selectionChange)="filterSitesByOrganisation($event)">
                    <mat-option *ngFor="let organisation of accountService.organisations" [value]="organisation.id">{{
                      organisation.name
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="c-dropdown-site">
                  <mat-label>Site name</mat-label>
                  <mat-select [(ngModel)]="camera.siteId">
                    <mat-option *ngFor="let site of filteredSites" [value]="site.id">{{ site.name }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="xc-camera-name-container c-icon-lead-section">
              <div class="is-icon">
                <mat-icon>label</mat-icon>
              </div>
              <div class="is-content-constrained">
                <p>Give this camera a name.</p>

                <mat-form-field class="c-input-camera-name">
                  <mat-label>Camera name</mat-label>
                  <input autocomplete="off" matInput [(ngModel)]="camera.name" />
                </mat-form-field>

                <div class="c-text-help-wrapper">
                  <mat-icon class="c-icon-help">live_help</mat-icon>
                  <div class="c-text-help brevier">
                    It's recommended you name the camera something descriptive that can be understood by everyone, eg:
                    Main entrance, or Gallery A doorway.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-step>
    </ng-template>
  </mat-step>

  <mat-step #powerTheCameraStep>
    <ng-template matStepContent>
      <app-step
        [stepTitle]="'Camera setup'"
        [nextStepText]="'Next'"
        [processingStep]="processingStep"
        [isBackable]="false"
        [isStepButtonBold]="false"
        [isStepButtonBordered]="false"
        (openNextStep)="goToNextStep(25)"
        (closeStep)="close()"
      >
        <div class="c-modal-content">
          <app-camera-setup-side-panel
            [camera]="camera"
            [setupProgress]="setupProgress"
            [activeStep]="1"
            [isBackable]="false"
            [hideAlerts]="!this.cameras.length"
            (openPreviousStep)="goToPreviousStep(15)"
          ></app-camera-setup-side-panel>
          <div class="c-modal-main">
            <div class="c-step-header c-step-header-power-camera">
              <div class="c-text-title-wrapper">
                <div class="c-text-step brevier--caps">Step 3</div>
                <div class="kirk">Power the camera.</div>
              </div>
              <a href="https://docs.hoxton.ai/setup-guide" target="_blank">
                <button class="o-button is-muted is-contained">
                  <span class="material-icons is-icon-left">live_help</span>
                  HELP
                </button>
              </a>
            </div>
            <div class="c-main-content">
              <h3 class="pica">Power your camera.</h3>
              <ul class="c-list-steps">
                <li class="c-list-steps__item">
                  <div class="is-number">1</div>
                  <div class="is-description">Connect the plug to mains power.</div>
                </li>
                <li class="c-list-steps__item">
                  <div class="is-number">2</div>
                  <div class="is-description">
                    <div>Connect one end of the ethernet cable to the socket <i>Data & power out/POE</i>.</div>
                  </div>
                </li>
                <li class="c-list-steps__item">
                  <div class="is-number">3</div>
                  <div class="is-description">Plug the other end of the cable into the camera.</div>
                </li>
              </ul>
              <div>
                <p class="c-text-hotspot-mode">
                  When powered the camera will be in <b>hotspot mode</b> with both LEDs flashing simultaneously at
                  regular intervals. The camera is ready to be connected to the internet.
                </p>

                <a
                  class="c-text-led-statuses"
                  href="https://docs.hoxton.ai/knowledge/camera-troubleshooting#ledstatus"
                  target="_blank"
                >
                  See all LED statuses.
                </a>
              </div>
            </div>
          </div>
        </div>
      </app-step>
    </ng-template>
  </mat-step>

  <mat-step>
    <ng-template matStepContent>
      <app-step
        [stepTitle]="'Camera setup'"
        [nextStepText]="'next'"
        [processingStep]="processingStep"
        [isBackable]="false"
        [isStepButtonBold]="false"
        [isStepButtonBordered]="false"
        (openNextStep)="checkCameraIsOnline()"
        (closeStep)="close()"
      >
        <div class="c-modal-content">
          <app-camera-setup-side-panel
            [camera]="camera"
            [setupProgress]="setupProgress"
            [activeStep]="2"
            [hideAlerts]="!this.cameras.length"
            (openPreviousStep)="goToPreviousStep(20)"
          ></app-camera-setup-side-panel>
          <div class="c-modal-main">
            <div class="c-step-header c-step-header-custom">
              <div class="c-text-title-wrapper">
                <div class="c-text-step brevier--caps">Step 4</div>
                <div class="kirk">Connect to internet.</div>
              </div>
              <a href="https://docs.hoxton.ai/setup-guide" target="_blank">
                <button class="o-button is-muted is-contained">
                  <span class="material-icons is-icon-left">live_help</span>
                  HELP
                </button>
              </a>
            </div>
            <div class="c-tabs">
              <div
                class="c-tab-item"
                [class.c-tab-item-active]="showWifiTabInsteadEthernetTab"
                (click)="showWifiTabInsteadEthernetTab = true"
              >
                Wifi
              </div>
              <div
                class="c-tab-item"
                [class.c-tab-item-active]="!showWifiTabInsteadEthernetTab"
                (click)="showWifiTabInsteadEthernetTab = false"
              >
                Ethernet
              </div>
            </div>
            <div *ngIf="showWifiTabInsteadEthernetTab" class="c-tab c-tab-wifi">
              <div class="pica">Connect to the desired wifi network</div>
              <ul class="c-list-steps">
                <li class="c-list-steps__item">
                  <div class="is-number">1</div>
                  <div class="is-description">
                    <div>Use a computer to connect to the wifi access point named <i>Hoxton Direct hotspot.</i></div>
                  </div>
                </li>
                <li class="c-list-steps__item">
                  <div class="is-number">2</div>
                  <div class="is-description">After a few moments a HoxtonAi popup should appear.</div>
                </li>
                <li class="c-list-steps__item">
                  <div class="is-number">3</div>
                  <div class="is-description">
                    Use the dropdown menu to select the wifi network the camera will ultimately sit on when installed.
                  </div>
                </li>
                <li class="c-list-steps__item">
                  <div class="is-number">4</div>
                  <div class="is-description">Enter the wifi password.</div>
                </li>
                <li class="c-list-steps__item">
                  <div class="is-number">5</div>
                  <div class="is-description">
                    <div>
                      After clicking <b>Connect</b> button, you will be disconnected from the Hoxton Direct hotspot and
                      be reconnected to the wifi network your computer was on before.
                    </div>
                  </div>
                </li>
                <li class="c-list-steps__item">
                  <div class="is-number">6</div>
                  <div class="is-description">
                    <div>
                      Wait up to one minute, during which the camera will indicate a successful wifi connection. For
                      <b>overhead</b> cameras this will be a solid red and green LED. <b>Horizontal</b> cameras will
                      show a solid green and blue LED.
                    </div>
                  </div>
                </li>
              </ul>
              <div class="c-text-warning">
                <mat-icon>error</mat-icon>
                <p class="u-flush--bottom">
                  If the above steps do not work, please <i>power cycle</i> the camera by removing power for 10 seconds,
                  then retry the steps above. If still no progress see the Troubleshooting guide.
                </p>
              </div>
            </div>

            <div *ngIf="!showWifiTabInsteadEthernetTab" class="c-tab c-tab-ethernet">
              <div class="pica">This page will explain the steps to set up a HoxtonAi camera with ethernet.</div>
              <p>
                Setting up a HoxtonAi camera using ethernet is extremely straight forward and should be plug and play in
                most scenarios.
              </p>
              <p class="c-text-more-information">Please see the network requirements page for more information.</p>

              <ul class="c-list-steps c-list-steps-ethernet">
                <li class="c-list-steps__item">
                  <div class="is-number">1</div>
                  <div class="is-description">
                    <div>
                      <p>Connect the Power Over Ethernet plug to your mains power.</p>
                      <div class="brevier type--secondary" style="display: flex; align-items: center; gap: var(--unit)">
                        <mat-icon>error</mat-icon>
                        <p class="brevier u-flush--bottom type--secondary">
                          If you have access to active PoE points, you may simply connect your camera(s) directly, by
                          using the supplied ethernet cable.
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="c-list-steps__item">
                  <div class="is-number">2</div>
                  <div class="is-description">
                    <div>
                      <p>
                        Connect one end of the provided ethernet cable to the socket marked <em>Data and Power Out</em>.
                      </p>
                      <p>
                        Using a second ethernet cable, connect one end to the <em>Data in</em> port on the adapter, and
                        the other to a data point that is connected to the network you wish to use.
                      </p>
                      <div class="c-flex-media has-outline">
                        <img
                          src="https://f.hubspotusercontent40.net/hubfs/7815191/support-site-assets/ethernet-connection.png"
                          alt="Ethernet connection ports"
                          width="180"
                        />
                        <p>
                          On some supplied PoE plugs, the ports "Data &amp; power out" and "Data in" may be labelled
                          "POE" and "LAN" respectively.
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="c-list-steps__item">
                  <div class="is-number">3</div>
                  <div class="is-description">
                    <div>
                      <p>Plug the other end of the cable into the camera.</p>

                      <div class="c-flex-media has-outline">
                        <img
                          src="https://f.hubspotusercontent40.net/hubfs/7815191/support-site-assets/sensor-ethernet.png"
                          width="200"
                          alt="Ethernet connection ports"
                        />
                        <p>
                          Check the LEDs on the side of the camera to confirm it is powered. Overhead cameras will show
                          a red LED. Horizontal cameras will show a green LED.
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="c-list-steps__item">
                  <div class="is-number">4</div>
                  <div class="is-description">
                    <p>Wait up to a minute for the camera to connect to your network.</p>
                    <p>This can be confirmed by the LEDs on the side being continuously lit.</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </app-step>
    </ng-template>
  </mat-step>

  <mat-step #checkCameraViewStep>
    <ng-template matStepContent>
      <app-step
        [stepTitle]="'Camera setup'"
        [nextStepText]="'Next'"
        [processingStep]="processingStep"
        [isBackable]="false"
        [isStepButtonBold]="false"
        [isStepButtonBordered]="false"
        [disabledNextStep]="cameraFrame === ''"
        (openNextStep)="goToNextStep(60)"
        (closeStep)="close()"
      >
        <div class="c-modal-content">
          <app-camera-setup-side-panel
            [camera]="camera"
            [setupProgress]="setupProgress"
            [activeStep]="3"
            [hideAlerts]="!this.cameras.length"
            (openPreviousStep)="goToPreviousStep(25)"
          ></app-camera-setup-side-panel>
          <div class="c-modal-main">
            <div class="c-step-header c-step-header-check-camera-view">
              <div class="c-text-title-wrapper">
                <div class="c-text-step brevier--caps">Step 5</div>
                <div class="kirk">Check the camera view</div>
              </div>
              <a href="https://docs.hoxton.ai/setup-guide" target="_blank">
                <button class="o-button is-muted is-contained">
                  <span class="material-icons is-icon-left">live_help</span>
                  HELP
                </button>
              </a>
            </div>
            <div class="c-main-content c-main-content-check-camera-view">
              <p class="brevier">
                This step is to check the camera is functioning correctly. <br />
                We recommend waiting for the camera to return a view <b>before</b> mounting in the final position.
              </p>

              <ng-container *ngIf="!isGettingView">
                <ng-container *ngIf="cameraFrame === ''">
                  <ng-container *ngIf="camera.isOnline">
                    <div class="c-page-banner is-error has-icon">
                      <mat-icon>broken_image</mat-icon>
                      <div class="c-alert-title">Camera view unavailable</div>
                    </div>
                    <div class="c-alert c-alert-online">
                      <div class="c-icon-label">
                        <mat-icon class="is-icon">wifi</mat-icon>
                        <div class="is-label">Camera online</div>
                      </div>
                      <div class="c-alert-content">
                        You are likely to experience a delay of up-to 15 minutes returning the camera view for the first
                        time. This is normal and due to software updates being made.
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!camera.isOnline">
                    <div class="c-page-banner is-error has-icon">
                      <mat-icon>broken_image</mat-icon>
                      <div class="c-alert-title">Camera view unavailable</div>
                    </div>
                    <div class="c-page-banner is-warning">
                      <div class="c-icon-label">
                        <mat-icon class="is-icon">wifi_off</mat-icon>
                        <div class="is-label">Camera offline</div>
                      </div>
                      <div class="c-alert-content">
                        <div class="c-text-camera-offline">
                          The camera is offline. A camera view will not be available until it is connected to the
                          internet.
                        </div>
                        <div class="c-help-menu">
                          <mat-icon class="is-icon">live_help</mat-icon>
                          <a href="https://docs.hoxton.ai/setup-guide/wifi-connect" target="_blank" class="is-link">
                            Connecting with wifi
                          </a>
                          &nbsp;
                          <a href="https://docs.hoxton.ai/setup-guide/ethernet-connect" target="_blank" class="is-link">
                            Connecting with ethernet
                          </a>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="cameraFrame !== ''">
                  <div class="xc-alert xc-alert-ready c-page-banner is-positive has-icon">
                    <mat-icon class="u-primary-color">check</mat-icon>
                    <div>Camera view ready</div>
                  </div>
                  <div class="xc-alert xc-alert-working-correctly c-page-banner">
                    <p>Your camera looks to be working correctly and is sending video for processing.</p>
                    <p>You can continue to the next step and mount your camera in it's final position.</p>
                  </div>
                </ng-container>
              </ng-container>

              <div class="c-live-camera-title">
                <b>Live camera view</b>
                <div class="c-text-refresh" (click)="getCameraFrame()">
                  <mat-icon class="c-icon-refresh" [class.rotate]="isGettingView">refresh</mat-icon>
                  REFRESH
                </div>
              </div>

              <div class="c-live-camera" [ngClass]="{ 'no-view': cameraFrame === '' && !isGettingView }">
                <div class="dot-flashing" *ngIf="isGettingView; else cameraFrameView"></div>
                <ng-template #cameraFrameView>
                  <ng-container *ngIf="cameraFrame !== ''; else noFrame">
                    <img [src]="cameraFrame" alt="camera view" />
                  </ng-container>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </app-step>
    </ng-template>
  </mat-step>

  <mat-step>
    <ng-template matStepContent>
      <app-step
        [stepTitle]="'Camera setup'"
        [nextStepText]="'Next'"
        [processingStep]="processingStep"
        [isBackable]="false"
        [isStepButtonBold]="false"
        [isStepButtonBordered]="false"
        (openNextStep)="goToNextStep(70)"
        (closeStep)="close()"
      >
        <div class="c-modal-content">
          <app-camera-setup-side-panel
            [camera]="camera"
            [setupProgress]="setupProgress"
            [activeStep]="4"
            [hideAlerts]="!this.cameras.length"
            (openPreviousStep)="goToPreviousStep(45)"
          ></app-camera-setup-side-panel>
          <div class="c-modal-main">
            <div class="c-step-header">
              <div class="c-text-title-wrapper">
                <div class="c-text-step brevier--caps">Step 6</div>
                <div class="kirk">Mount the camera</div>
              </div>
              <a href="https://docs.hoxton.ai/setup-guide" target="_blank">
                <button class="o-button is-muted is-contained">
                  <span class="material-icons is-icon-left">live_help</span>
                  HELP
                </button>
              </a>
            </div>
            <div class="c-main-content">
              <div class="xc-alert-requirements c-page-banner is-warning">
                <p>
                  Please read the requirements below before mounting your camera. Failure to do so might effect the
                  accuracy of your data.
                </p>
              </div>

              <div class="c-tabs c-tabs-mount-camera">
                <div
                  class="c-tab-item"
                  [class.c-tab-item-active]="showOverheadInsteadHorizontalTab"
                  (click)="showOverheadInsteadHorizontalTab = true"
                >
                  Overhead
                </div>
                <div
                  class="c-tab-item"
                  [class.c-tab-item-active]="!showOverheadInsteadHorizontalTab"
                  (click)="showOverheadInsteadHorizontalTab = false"
                >
                  Horizontal
                </div>
              </div>
              <div *ngIf="showOverheadInsteadHorizontalTab" class="c-tab">
                <img
                  src="/assets/images/new-camera-journey/mount.png"
                  class="c-image-mount-camera"
                  alt="Mount camera"
                />
                <div class="c-horizontal-rule-small"></div>
                <h3 class="c-title-camera-position">Camera position</h3>
                <p class="c-text-good-lighting">
                  Ensure there is good lighting. You must be able to see clear distinction between head and background
                </p>
                <p class="c-text-doorways-clear">
                  Doorways should also be clear of any obstacles that inhibit traffic flow. They should also be clear of
                  people loitering of queuning
                </p>

                <ul class="c-images-list">
                  <li class="c-list-item-camera-position c-images-list__item">
                    <div class="c-image-camera-position-wrapper">
                      <img
                        src="https://f.hubspotusercontent40.net/hubfs/7815191/support-site-assets/frame-good-lighting1-1.jpeg"
                        class="c-image-camera-position-correct"
                        alt="Doorway"
                      />
                      <div class="c-icon-status-wrapper c-icon-status-wrapper-correct"><mat-icon>check</mat-icon></div>
                    </div>
                    <div class="c-text-camera-position-description">The person's head is clearly visible</div>
                  </li>

                  <li class="c-list-item-camera-position c-images-list__item">
                    <div class="c-image-camera-position-wrapper">
                      <img
                        src="https://f.hubspotusercontent40.net/hubfs/7815191/support-site-assets/frame-good-lighting2.jpeg"
                        class="c-image-camera-position-correct"
                        alt="Doorway"
                      />
                      <div class="c-icon-status-wrapper c-icon-status-wrapper-correct"><mat-icon>check</mat-icon></div>
                    </div>
                    <div class="c-text-camera-position-description">The person's head is clearly visible</div>
                  </li>
                  <li class="c-list-item-camera-position c-images-list__item">
                    <div class="c-image-camera-position-wrapper">
                      <img
                        src="https://f.hubspotusercontent40.net/hubfs/7815191/support-site-assets/frame-poor-lighting1.jpeg"
                        class="c-image-camera-position-wrong"
                        alt="Doorway"
                      />
                      <div class="c-icon-status-wrapper c-icon-status-wrapper-wrong"><mat-icon>close</mat-icon></div>
                    </div>
                    <div class="c-text-camera-position-description">The person's cannot be seen clearly</div>
                  </li>
                  <li class="c-list-item-camera-position c-images-list__item">
                    <div class="c-image-camera-position-wrapper">
                      <img
                        src="https://f.hubspotusercontent40.net/hubfs/7815191/support-site-assets/frame-poor-lighting2.jpeg"
                        class="c-image-camera-position-wrong"
                        alt="Doorway"
                      />
                      <div class="c-icon-status-wrapper c-icon-status-wrapper-wrong"><mat-icon>close</mat-icon></div>
                    </div>
                    <div class="c-text-camera-position-description">The person's cannot be seen clearly</div>
                  </li>
                </ul>
              </div>

              <div *ngIf="!showOverheadInsteadHorizontalTab" class="c-tab">
                <h3 class="c-title-camera-position-horizontal">Camera position</h3>
                <p class="kirk">Please note the following requirements when mounting your horizontal camera.</p>

                <ul class="c-list-steps">
                  <li class="c-list-steps__item">
                    <div class="is-number">1</div>
                    <div class="is-description">
                      The bottom of the camera camera must be mounted 40cm above the detection area.
                    </div>
                  </li>
                  <li class="c-list-steps__item">
                    <div class="is-number">2</div>
                    <div class="is-description">
                      The maximum detectable distance from the camera is 4m, provided the camera is mounted at the
                      correct height.
                    </div>
                  </li>
                  <li class="c-list-steps__item">
                    <div class="is-number">3</div>
                    <div class="is-description">The camera's view of foot traffic must not be obscured.</div>
                  </li>
                  <li class="c-list-steps__item">
                    <div class="is-number">4</div>
                    <div class="is-description">The camera must be level to the detection area.</div>
                  </li>
                </ul>

                <ul class="c-images-list">
                  <li class="c-list-item-camera-position c-images-list__item">
                    <div class="c-image-camera-position-wrapper">
                      <img
                        src="/assets/images/new-camera-journey/camera-position-1.png"
                        class="c-image-camera-position-correct"
                        alt="Correct camera position"
                      />
                      <div class="c-icon-status-wrapper c-icon-status-wrapper-correct">
                        <mat-icon>check</mat-icon>
                      </div>
                    </div>

                    <div class="c-text-camera-position-description">A clear view of the person's feet.</div>
                  </li>
                  <li class="c-list-item-camera-position c-images-list__item">
                    <div class="c-image-camera-position-wrapper">
                      <img
                        src="/assets/images/new-camera-journey/camera-position-2.png"
                        class="c-image-camera-position-correct"
                        alt="Correct camera position"
                      />
                      <div class="c-icon-status-wrapper c-icon-status-wrapper-correct">
                        <mat-icon>check</mat-icon>
                      </div>
                    </div>
                    <div class="c-text-camera-position-description">The camera is level with the street outside.</div>
                  </li>
                  <li class="c-list-item-camera-position c-images-list__item">
                    <div class="c-image-camera-position-wrapper">
                      <img
                        src="/assets/images/new-camera-journey/camera-position-3.png"
                        class="c-image-camera-position-wrong"
                        alt="Correct camera position"
                      />
                      <div class="c-icon-status-wrapper c-icon-status-wrapper-wrong"><mat-icon>close</mat-icon></div>
                    </div>
                    <div class="c-text-camera-position-description">The area to be detected is too far away.</div>
                  </li>
                  <li class="c-list-item-camera-position c-images-list__item">
                    <div class="c-image-camera-position-wrapper">
                      <img
                        src="/assets/images/new-camera-journey/camera-position-4.png"
                        class="c-image-camera-position-wrong"
                        alt="Correct camera position"
                      />
                      <div class="c-icon-status-wrapper c-icon-status-wrapper-wrong"><mat-icon>close</mat-icon></div>
                    </div>

                    <div class="c-text-camera-position-description">
                      The ledge is blocking the camera view of peoples feet.
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </app-step>
    </ng-template>
  </mat-step>

  <mat-step>
    <ng-template matStepContent>
      <app-step
        [stepTitle]="'Camera setup'"
        [nextStepText]="'Next'"
        [processingStep]="processingStep"
        [isBackable]="false"
        [isStepButtonBold]="false"
        [isStepButtonBordered]="false"
        [disabledNextStep]="
          camera.direction1Alias === '' ||
          camera.direction2Alias === '' ||
          camera.direction1Alias === 'Direction 1' ||
          camera.direction2Alias === 'Direction 2'
        "
        (openNextStep)="updateCamera()"
        (closeStep)="close()"
      >
        <div class="c-modal-content">
          <app-camera-setup-side-panel
            [camera]="camera"
            [setupProgress]="setupProgress"
            [activeStep]="5"
            [hideAlerts]="!this.cameras.length"
            (openPreviousStep)="goToPreviousStep(60)"
          ></app-camera-setup-side-panel>
          <div class="c-modal-main">
            <div class="c-step-header">
              <div class="c-text-title-wrapper">
                <div class="c-text-step brevier--caps">Step 7</div>
                <div class="kirk">Name the camera directions</div>
              </div>
              <a href="https://docs.hoxton.ai/setup-guide" target="_blank">
                <button class="o-button is-muted is-contained">
                  <span class="material-icons is-icon-left">live_help</span>
                  HELP
                </button>
              </a>
            </div>
            <div class="c-main-content" *ngIf="cameraFrame === ''">
              <div class="c-alert c-alert-offline" *ngIf="camera.isOnline">
                <div class="c-icon-label">
                  <mat-icon>broken_image</mat-icon>
                  <div>Camera view unavailable</div>
                </div>
                <div class="c-alert-content">
                  <p>The camera is online but we're unable to get a camera view. Please try again in a minute.</p>
                </div>
                <div class="o-button is-small" (click)="getCameraFrame()">
                  <mat-icon class="is-icon-left" [class.rotate]="isGettingView">refresh</mat-icon>
                  refresh
                </div>
              </div>

              <div class="c-page-banner is-warning" *ngIf="!camera.isOnline">
                <div class="c-icon-label u-margin--bottom">
                  <mat-icon>wifi_off</mat-icon>
                  <div>Camera offline</div>
                </div>
                <p>The camera is offline. A camera view will not be available until it is connected to the internet.</p>
                <div class="c-help-menu u-margin--top">
                  <mat-icon class="is-icon">live_help</mat-icon>
                  <a href="https://docs.hoxton.ai/setup-guide/wifi-connect" target="_blank" class="is-link"
                    >Connecting with wifi</a
                  >
                  <a href="https://docs.hoxton.ai/setup-guide/ethernet-connect" target="_blank" class="is-link">
                    Connecting with ethernet</a
                  >
                </div>
              </div>
            </div>

            <div
              class="c-camera-directions-wrapper c-icon-lead-section"
              [class.c-camera-directions-wrapper-horizontal]="isPasstraffCamera"
              [class.c-camera-directions-wrapper-vertical]="!isPasstraffCamera"
            >
              <div class="is-icon">
                <mat-icon class="xc-icon-signpost c-icon-gold">signpost</mat-icon>
              </div>
              <div class="c-camera-directions-image-wrapper" [ngClass]="{ 'no-view': cameraFrame === '' }">
                <div class="c-directions-on-image" *ngIf="cameraFrame !== ''">
                  <mat-icon class="c-camera-direction-icon">
                    {{ isPasstraffCamera ? 'arrow_back' : 'arrow_upward' }}
                  </mat-icon>
                  <mat-icon class="c-camera-direction-icon">
                    {{ isPasstraffCamera ? 'arrow_forward' : 'arrow_downward' }}
                  </mat-icon>
                </div>
                <div class="dot-flashing" *ngIf="isGettingView; else cameraFrameView"></div>
                <ng-template #cameraFrameView>
                  <ng-container *ngIf="cameraFrame !== ''; else noFrame">
                    <img [src]="cameraFrame" alt="camera view" />
                  </ng-container>
                </ng-template>
              </div>

              <div>
                <div class="c-camera-directions-title">Camera directions</div>
                <div class="c-camera-directions-list">
                  <div class="c-camera-direction">
                    <mat-icon class="c-camera-direction-icon">
                      {{ isPasstraffCamera ? 'arrow_back' : 'arrow_upward' }}
                    </mat-icon>
                    <mat-form-field>
                      <input
                        *ngIf="isPasstraffCamera"
                        autocomplete="off"
                        matInput
                        [(ngModel)]="camera.direction2Alias"
                        placeholder="Direction 1"
                      />
                      <input
                        *ngIf="!isPasstraffCamera"
                        autocomplete="off"
                        matInput
                        [(ngModel)]="camera.direction1Alias"
                        placeholder="Direction 1"
                      />
                    </mat-form-field>
                  </div>

                  <div class="c-camera-direction">
                    <mat-icon class="c-camera-direction-icon">
                      {{ isPasstraffCamera ? 'arrow_forward' : 'arrow_downward' }}
                    </mat-icon>
                    <mat-form-field>
                      <input
                        *ngIf="isPasstraffCamera"
                        autocomplete="off"
                        matInput
                        [(ngModel)]="camera.direction1Alias"
                        placeholder="Direction 2"
                      />
                      <input
                        *ngIf="!isPasstraffCamera"
                        autocomplete="off"
                        matInput
                        [(ngModel)]="camera.direction2Alias"
                        placeholder="Direction 2"
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-step>
    </ng-template>
  </mat-step>

  <mat-step #scheduleAuditStep>
    <ng-template matStepContent>
      <app-step
        [stepTitle]="'Camera setup'"
        [nextStepText]="'Next'"
        [processingStep]="processingStep"
        [isBackable]="false"
        [isStepButtonBold]="false"
        [isStepButtonBordered]="false"
        [disabledNextStep]="!readyForAudit"
        (openNextStep)="cameraReadyForAudit()"
        (closeStep)="close()"
      >
        <div class="c-modal-content">
          <app-camera-setup-side-panel
            [camera]="camera"
            [setupProgress]="setupProgress"
            [activeStep]="6"
            [isBackable]="false"
            [hideAlerts]="!this.cameras.length"
          ></app-camera-setup-side-panel>
          <div class="c-modal-main">
            <div class="c-step-header">
              <div class="c-text-title-wrapper">
                <div class="c-text-step brevier--caps">Step 8</div>
                <div class="kirk">Schedule installation audit.</div>
              </div>
              <a href="https://docs.hoxton.ai/setup-guide" target="_blank">
                <button class="o-button is-muted is-contained">
                  <span class="material-icons is-icon-left">live_help</span>
                  HELP
                </button>
              </a>
            </div>
            <div class="c-main-content">
              <div class="c-page-banner is-warning">
                <p>
                  We will audit the camera installation. You will be notified of any issues that will affect its
                  accuracy, such as incorrect placement or poor lighting.
                </p>
              </div>

              <div class="c-icon-lead-section">
                <div class="is-icon">
                  <mat-icon class="c-icon-gold">safety_check</mat-icon>
                </div>
                <div class="is-content-constrained">
                  <p>Is the camera ready to be audited?</p>
                  <ul class="c-checklist u-margin--bottom">
                    <li class="c-checklist__item">Space is open to the public</li>
                    <li class="c-checklist__item">Lighting conditions not expected to change</li>
                    <li class="c-checklist__item">No planned layout changes within camera view</li>
                  </ul>

                  <p class="brevier">
                    If you are not ready for the camera to be audited you can exit the setup now. Your progress will be
                    saved. You can complete the next steps when the camera is ready for audit.
                  </p>

                  <div class="c-slot-ready-audit">
                    <mat-radio-button
                      [checked]="readyForAudit"
                      (click)="readyForAudit = !readyForAudit; $event.stopPropagation()"
                    >
                      <div class="c-text-ready-audit" (click)="$event.stopPropagation()">
                        <div class="c-title-ready-audit">Ready for audit</div>
                        <div class="c-subtitle-ready-audit">Notify the team the camera is ready.</div>
                      </div>
                    </mat-radio-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-step>
    </ng-template>
  </mat-step>

  <mat-step>
    <ng-template matStepContent>
      <app-step
        [stepTitle]="'Camera setup'"
        [nextStepText]="'Next'"
        [processingStep]="processingStep"
        [isBackable]="false"
        [isStepButtonBold]="false"
        [isStepButtonBordered]="false"
        (openNextStep)="addAlerts()"
        (closeStep)="close()"
      >
        <div class="c-modal-content">
          <app-camera-setup-side-panel
            [camera]="camera"
            [setupProgress]="setupProgress"
            [activeStep]="7"
            (openPreviousStep)="goToPreviousStep(80)"
          ></app-camera-setup-side-panel>

          <div class="c-modal-main c-modal-main">
            <div class="c-step-header c-step-header-add-camera-alert">
              <div class="c-text-title-wrapper">
                <div class="c-text-step c-text-step-mobile brevier--caps">Step 9</div>
                <div class="kirk">This camera will be added to the following alerts.</div>
                <div class="brevier type--secondary">You can add more camera alerts if required.</div>
              </div>
            </div>
            <div class="c-main-content c-main-content-camera-alerts">
              <div class="c-camera-alerts">
                <mat-icon class="c-icon-tag-rounded c-icon-safety-check">add_alert</mat-icon>

                <div class="c-title-camera-alerts">Camera alerts.</div>
                <div class="c-subtitle-camera-alerts">
                  {{ offlineAlerts.length }} camera {{ offlineAlerts.length | pluraliseWord: 'alert' }} available
                </div>
                <div class="c-checkboxes-cameras-alerts">
                  <mat-checkbox
                    *ngFor="let alert of offlineAlertsToAdd"
                    (click)="$event.stopPropagation()"
                    (change)="offlineAlertsToAddSelection.toggle(alert.id)"
                    [checked]="offlineAlertsToAddSelection.isSelected(alert.id)"
                    class="c-checkbox-camera-alert"
                  >
                    {{ alert.name }}
                  </mat-checkbox>
                  <mat-checkbox
                    *ngFor="let alert of offlineAlertsExisting"
                    [checked]="true"
                    class="c-checkbox-camera-alert"
                    [disabled]="true"
                  >
                    {{ alert.name }}
                  </mat-checkbox>
                </div>
                <button class="o-button has-outline c-button-add-alert" (click)="selectAlerts()">
                  <span class="material-icons is-icon-left"> add </span>
                  <span class="c-button-text-add-alert"> add another camera alert </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </app-step>
    </ng-template>
  </mat-step>

  <mat-step>
    <ng-template matStepContent>
      <app-step
        [stepTitle]="'Camera setup'"
        [nextStepText]="'Next'"
        [processingStep]="processingStep"
        [isBackable]="false"
        [isStepButtonBold]="false"
        [isStepButtonBordered]="false"
        (openNextStep)="addSummaries()"
        (closeStep)="close()"
      >
        <div class="c-modal-content">
          <app-camera-setup-side-panel
            [camera]="camera"
            [setupProgress]="setupProgress"
            [activeStep]="8"
            (openPreviousStep)="goToPreviousStep(85)"
          ></app-camera-setup-side-panel>
          <div class="c-modal-main">
            <div class="c-step-header">
              <div class="c-text-title-wrapper">
                <div class="c-text-step c-text-step-mobile brevier--caps">Step 10</div>
                <div class="kirk">This camera will be added to the following health summaries.</div>
                <div class="brevier type--secondary">You can add more health summaries if required.</div>
              </div>
            </div>
            <div class="c-main-content c-card-blue">
              <div class="c-camera-alerts">
                <mat-icon class="c-icon-tag-rounded c-icon-safety-check">emergency</mat-icon>

                <div class="c-title-camera-alerts">Camera health summaries.</div>
                <div class="c-subtitle-camera-alerts">
                  {{ summaryAlerts.length }} health {{ summaryAlerts.length | pluraliseWord: 'summary' }} available
                </div>

                <div class="c-checkboxes-cameras-alerts">
                  <mat-checkbox
                    *ngFor="let summary of summaryAlertsToAdd"
                    (click)="$event.stopPropagation()"
                    (change)="summaryAlertsToAddSelection.toggle(summary.id)"
                    [checked]="summaryAlertsToAddSelection.isSelected(summary.id)"
                    class="c-checkbox-camera-alert"
                  >
                    {{ summary.name }}
                  </mat-checkbox>
                  <mat-checkbox
                    *ngFor="let summary of summaryAlertsExisting"
                    [checked]="true"
                    class="c-checkbox-camera-alert"
                    [disabled]="true"
                  >
                    {{ summary.name }}
                  </mat-checkbox>
                </div>
                <button class="o-button has-outline c-button-add-alert" (click)="selectSummaries()">
                  <span class="material-icons is-icon-left"> add </span>
                  <span class="c-button-text-add-alert"> add another health summary </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </app-step>
    </ng-template>
  </mat-step>

  <mat-step>
    <ng-template matStepContent>
      <app-step
        [stepTitle]="'Camera setup'"
        [nextStepText]="'Finish'"
        [isBackable]="false"
        [isStepButtonBold]="false"
        [isStepButtonBordered]="false"
        [processingStep]="processingStep"
        (openNextStep)="finalizeJourney()"
        (closeStep)="finalizeJourney()"
      >
        <div class="c-modal-content">
          <div class="c-modal-main">
            <div class="c-notification-setup-complete">
              <mat-icon>check</mat-icon>
              <div>
                Setup compete for camera: <b>{{ camera.name }}</b
                >.
              </div>
            </div>
            <div class="c-main-content">
              <div
                class="c-camera-directions-wrapper"
                [class.c-camera-directions-wrapper-horizontal]="isPasstraffCamera"
                [class.c-camera-directions-wrapper-vertical]="!isPasstraffCamera"
              >
                <mat-icon class="c-icon-signpost">check</mat-icon>
                <div class="c-camera-directions-image-wrapper" [ngClass]="{ 'no-view': cameraFrame === '' }">
                  <div class="c-directions-on-image">
                    <div class="c-button-direction">
                      <mat-icon class="c-camera-direction-icon">
                        {{ isPasstraffCamera ? 'arrow_back' : 'arrow_upward' }}
                      </mat-icon>
                      <div class="c-camera-direction-name">
                        {{ isPasstraffCamera ? camera.direction2Alias : camera.direction1Alias }}
                      </div>
                    </div>
                    <div class="c-button-direction">
                      <mat-icon class="c-camera-direction-icon">
                        {{ isPasstraffCamera ? 'arrow_forward' : 'arrow_downward' }}
                      </mat-icon>
                      <div class="c-camera-direction-name">
                        {{ isPasstraffCamera ? camera.direction1Alias : camera.direction2Alias }}
                      </div>
                    </div>
                  </div>

                  <ng-container *ngIf="cameraFrame !== ''; else noFrame">
                    <img [src]="cameraFrame" alt="camera view" />
                  </ng-container>
                </div>

                <div class="c-camera-directions-main">
                  <div>
                    <div class="c-list-status-title">
                      <mat-icon>no_photography</mat-icon>
                      CAMERA ALERTS
                    </div>
                    <ul class="c-list-status">
                      <li
                        class="c-list-status-item"
                        *ngIf="!offlineAlertsToAddSelection.selected.length; else cameraAlerts"
                      >
                        <span class="c-slash-error">/</span> None selected
                      </li>
                      <ng-template #cameraAlerts>
                        <li class="c-list-status-item" *ngFor="let alert of getCameraAlerts()">
                          <span class="c-slash-success">/</span> {{ alert.name }}
                        </li>
                      </ng-template>
                    </ul>
                  </div>

                  <div>
                    <div class="c-list-status-title">
                      <mat-icon>emergency</mat-icon>
                      HEALTH SUMMARIES
                    </div>
                    <ul class="c-list-status">
                      <li
                        class="c-list-status-item"
                        *ngIf="!summaryAlertsToAddSelection.selected.length; else cameraSummaries"
                      >
                        <span class="c-slash-error">/</span> None selected
                      </li>
                      <ng-template #cameraSummaries>
                        <li class="c-list-status-item" *ngFor="let summary of getCameraSummaries()">
                          <span class="c-slash-success">/</span> {{ summary.name }}
                        </li>
                      </ng-template>
                    </ul>
                  </div>

                  <div class="c-text-audit-ready">
                    <mat-icon class="c-icon-flaky">flaky</mat-icon>
                    <div>Audit: <b>Camera ready for audit</b></div>
                  </div>

                  <button class="c-button-setup-completed o-button has-outline" (click)="navigateToMonitorsPage()">
                    <span class="material-icons is-icon-left"> view_quilt </span>
                    CREATE MONITOR
                  </button>

                  <div *ngIf="cameras.length">
                    <mat-checkbox
                      (change)="createMonitorForCamera = !createMonitorForCamera"
                      [checked]="createMonitorForCamera"
                    >
                      Create a single camera people count monitor
                    </mat-checkbox>
                    <a class="c-list-status" href="https://docs.hoxton.ai/user-guide/monitors" target="_blank"
                      >Learn more about monitors</a
                    >
                  </div>

                  <button class="c-button-setup-completed o-button has-outline" (click)="close(true)">
                    <span class="material-icons is-icon-left"> add_a_photo </span>
                    Setup another camera
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-step>
    </ng-template>
  </mat-step>
  <mat-step #setupConfirmationStep>
    <ng-template matStepContent>
      <app-step [stepTitle]="'New camera added'" [isBackable]="false" [hasNextStep]="false" (closeStep)="close()">
        <div class="c-final-step-first-camera">
          <div class="is-grid-item">
            <div class="paragon u-margin--bottom">Nice, you've got your first camera online.</div>
            <div class="primer kirk u-margin--top u-margin--bottom">
              We've created a people count monitor for you using this camera.
            </div>
            <div class="u-margin--bottom">
              You will find all the report data for the camera in this monitor.
              <a href="https://docs.hoxton.ai/user-guide/monitors" target="_blank"
                >Learn more about how monitors work.</a
              >
            </div>
            <button (click)="navigateToMonitorsPage()" class="o-button u-margin--top">
              View monitor
              <mat-icon class="is-icon-right">arrow_forward</mat-icon>
            </button>
          </div>
          <div class="is-grid-item">
            <img
              [src]="
                isPasstraffCamera
                  ? 'assets/images/horizontal-setup-complete.png'
                  : 'assets/images/overhead-setup-complete.png'
              "
              alt="Camera"
            />
          </div>
          <div class="u-grey-bg is-grid-item">
            <mat-icon class="u-primary-color">live_help</mat-icon>
            <div class="primer kirk u-margin--bottom">
              It's really important that your camera stays connected to power and internet.
            </div>
            <div>
              Losing power means the camera isn't running, or losing internet means the video isn't being sent to us for
              processing. Either way you are losing count data and reducing the accuracy in your reports.
            </div>
          </div>
          <div class="u-grey-bg is-grid-item">
            <mat-icon class="u-primary-color u-hide">live_help</mat-icon>
            <div class="primer kirk u-margin--bottom">To help with this we have created an email alert for you.</div>
            <div class="u-margin--bottom">
              You will be notified whenever a camera has been offline for more than 4 hours. We'll also send you a
              weekly health summary to tell you how all your cameras have been performing over the last 7 days.
            </div>
            <div>
              You can <a routerLink="/alerts">manage frequency and recipients</a> for both of these in the control room.
            </div>
          </div>
        </div>
      </app-step>
    </ng-template>
  </mat-step>
</mat-horizontal-stepper>

<ng-template #noFrame>
  <mat-icon class="is-icon">no_photography</mat-icon>
</ng-template>
