import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SiteStatus } from 'src/app/model/siteStatus';
import { AddEditSiteComponent } from '../add-edit-site/add-edit-site.component';

@Component({
  selector: 'app-sites-opening-hours',
  templateUrl: './sites-opening-hours.component.html',
  styleUrls: ['./sites-opening-hours.component.scss'],
})
export class SitesOpeningHoursComponent {
  sites: SiteStatus[];

  daysOfWeek: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  constructor(
    private matDialog: MatDialog,
    public dialogRef: MatDialogRef<AddEditSiteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data.sites) {
      this.sites = data.sites;
    }
  }

  editSite(site): void {
    const dialogRef = this.matDialog.open(AddEditSiteComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data: { site },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          window.location.reload();
        }
      },
    });
  }
}
