export interface SortData<T> {
  keyToSort: keyof T;
  direction: 'ascending' | 'descending';
  thenBy?: SortData<T>;
}

const isString = (value: any) => typeof value === 'string' || value instanceof String;
const isStringOrEmpty = (value: any) => !value || isString(value);

export const compare = <T>(objectA: T, objectB: T, sortData: SortData<T>): number => {
  let valueA = objectA[sortData.keyToSort];
  let valueB = objectB[sortData.keyToSort];
  if (valueA === valueB) {
    return sortData.thenBy ? compare(objectA, objectB, sortData.thenBy) : 0;
  }

  if (isStringOrEmpty(valueA) && isStringOrEmpty(valueB)) {
    return sortData.direction === 'ascending'
      ? (valueA ?? '-')
          .toString()
          .toLowerCase()
          .localeCompare((valueB ?? '-').toString().toLowerCase())
      : (valueB ?? '-')
          .toString()
          .toLowerCase()
          .localeCompare((valueA ?? '-').toString().toLowerCase());
  }

  if (valueA > valueB) {
    return sortData.direction === 'ascending' ? 1 : -1;
  } else {
    return sortData.direction === 'ascending' ? -1 : 1;
  }
};

export const sortArrayOfObjects = <T>(data: T[], sortData: SortData<T>): T[] => {
  return data.slice().sort((objectA: T, objectB: T) => compare(objectA, objectB, sortData));
};
