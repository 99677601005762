import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-new-camera-summaries',
  templateUrl: './new-camera-summaries.component.html',
  styleUrls: ['./new-camera-summaries.component.scss'],
})
export class NewCameraSummariesComponent {
  hasLink;
  constructor(
    public dialogRef: MatDialogRef<NewCameraSummariesComponent>,
    private cookieService: CookieService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
  ) {
    this.hasLink = data?.hasLink;
  }

  createSummary(): void {
    this.cookieService.set('_new_camera_summary_feature', 'true');
    this.router.navigate(['alerts/camera-summaries']).then(() => this.dialogRef.close());
  }

  close(): void {
    this.cookieService.set('_new_camera_summary_feature', 'true');
    this.dialogRef.close();
  }
}
