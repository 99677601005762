<div class="c-alert-information">
  <div class="c-alert-information__title">
    <h3 class="long-primer kirk u-flush--bottom u-white-color">
      {{ offlineCamerasWithIssues }} {{ offlineCamerasWithIssues | pluraliseWord: 'camera' }}
      {{ offlineCamerasWithIssues | isAre }}
      {{ issueType === 'alert' ? 'offline' : 'having issues' }} and not
      {{ issueType === 'alert' ? 'collecting traffic data.' : 'included in a health summary.' }}
    </h3>
    <div class="brevier u-flush--bottom u-white-color" *ngIf="issueType === 'alert'">
      You might be unaware of this issue because they do not have offline alerts set.
    </div>
  </div>
  <div class="c-alert-information__main">
    <div>
      You have {{ allCamerasQuantity }} {{ allCamerasQuantity | pluraliseWord: 'camera' }} across
      {{ allSitesQuantity }} {{ allSitesQuantity | pluraliseWord: 'site' }}.
    </div>
    <div class="primer kirk">
      {{ camerasPronedToIssues }} {{ camerasPronedToIssues | pluraliseWord: 'camera' }}

      {{
        issueType === 'alert'
          ? 'do not have camera alerts set up.'
          : (camerasPronedToIssues | isAre) +
            ' not
      included in a health summary.'
      }}
    </div>
    <div class="brevier">
      <ng-container *ngIf="issueType === 'alert'; else summaryRecommendation">
        We strongly recommend setting alerts for all your cameras so you can get them back online as quickly as possible
        and maintain the accuracy of your traffic data.
      </ng-container>
      <ng-template #summaryRecommendation>
        Add these cameras to a daily or weekly health summary to keep up-to-date with any downtime issues across your
        cameras fleet.
      </ng-template>
    </div>

    <button class="o-button is-primary" (click)="addCameraAlert()">
      <ng-container *ngIf="issueType === 'alert'; else summaryButton">
        <mat-icon class="is-icon-left">add_alert</mat-icon>
        ADD CAMERA ALERTS
      </ng-container>
      <ng-template #summaryButton>
        <mat-icon class="is-icon-left">emergency</mat-icon>
        Manage health summaries
      </ng-template>
    </button>

    <div class="c-pausing">
      <div class="c-pausing__body">
        <mat-icon class="is-icon">live_help</mat-icon>
        <p class="u-flush--bottom">
          If you do not want to receive {{ issueType === 'alert' ? 'offline alerts' : 'health summaries' }} because the
          camera is not currently in use, we recommend
          <b>pausing the camera.</b>
          <br />
          <a (click)="nextStep.emit()">FIND OUT MORE</a>.
        </p>
      </div>
    </div>
  </div>
</div>
