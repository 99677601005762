import { Pipe, PipeTransform } from '@angular/core';
import { GlobalMethods } from 'src/app/global-methods';

@Pipe({
  name: 'dateAgo',
})
export class DateAgoPipe implements PipeTransform {
  transform(value: any): unknown {
    return GlobalMethods.dateAgo(value);
  }
}
