<div class="o-modal-full-page has-secondary-header">
  <div class="o-modal-full-page__header">
    <button (click)="onClose()" class="is-close">
      <mat-icon>clear</mat-icon>
    </button>
    <h2 class="is-title">{{ stepTitle }}</h2>
  </div>
  <div class="o-modal-full-page__actions">
    <div style="display: flex">
      <ng-container *ngIf="isBackable">
        <button
          class="o-button is-opaque"
          mat-button
          *ngIf="!openPreviousStep.observed"
          [disabled]="!isBackable"
          matStepperPrevious
        >
          {{ previousStepText }}
        </button>
        <button
          class="o-button is-opaque"
          mat-button
          *ngIf="openPreviousStep.observed"
          [disabled]="!isBackable"
          (click)="goToPreviousStep()"
        >
          {{ previousStepText }}
        </button>
      </ng-container>
      <ng-container *ngIf="hasNextStep">
        <button
          class="o-button is-right-button is-opaque"
          mat-button
          [class.spinner]="processingStep"
          [class.c-step-button-bold]="isStepButtonBold"
          [class.c-step-button-bordered]="isStepButtonBordered"
          [disabled]="processingStep || disabledNextStep"
          *ngIf="!openNextStep.observed"
          matStepperNext
        >
          {{ nextStepText }}
        </button>
        <button
          class="o-button is-right-button is-opaque"
          mat-button
          [class.spinner]="processingStep"
          [disabled]="processingStep || disabledNextStep"
          [class.c-step-button-bold]="isStepButtonBold"
          [class.c-step-button-bordered]="isStepButtonBordered"
          *ngIf="openNextStep.observed"
          (click)="goToNextStep()"
        >
          {{ nextStepText }}
        </button>
      </ng-container>
    </div>
  </div>
  <div class="o-modal-full-page__body">
    <ng-content></ng-content>
  </div>
</div>
