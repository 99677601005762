import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { monitorNames, TMonitor } from 'src/app/model/monitor';
import { CameraStatus } from 'src/app/model/cameraStatus';
import { CamerasService } from 'src/app/services/cameras.service';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-monitor-card',
  templateUrl: './monitor-card.component.html',
  styleUrls: ['./monitor-card.component.scss'],
})
export class MonitorCardComponent implements OnInit {
  @Input() monitor;
  @Input() monitorProperties = {};
  @Input() monitorDetails = {};
  @Input() cameras = [];
  @Input() showIcon = false;

  @Input() siteName: string;
  @Input() monitorType: TMonitor;
  @Input() isDisabled = false;
  @Input() isIncomplete = false;

  @Output() editMonitor: EventEmitter<void> = new EventEmitter();
  @Output() deleteMonitor: EventEmitter<void> = new EventEmitter();
  @Output() showMonitorDetails: EventEmitter<void> = new EventEmitter();

  offlineCameras = 0;
  onlineCameras = 0;
  poorConnectionCameras = 0;
  activeCameras = 0;

  occupancyConfiguration = null;
  monitorNames = monitorNames;

  issues = 0;
  urgentIssues = 0;

  constructor(
    public accountService: AccountService,
    private camerasService: CamerasService,
  ) {}

  ngOnInit(): void {
    this.cameras.forEach((camera: CameraStatus) => {
      if (this.camerasService.hasIssue(camera)) {
        this.issues += 1;
        if (camera.urgency === 100) {
          this.urgentIssues += 1;
        }
      }
      if (camera.state === 'running') {
        this.activeCameras += 1;
      }
    });
  }

  hasCapacity(): boolean {
    return 'capacity' in this.monitor;
  }

  getMonitorDetails(): string[] {
    return Object.keys(this.monitorDetails);
  }

  getSecondaryText(): string {
    let prefix: string;
    switch (this.monitorType) {
      case 'occupancy':
        prefix = 'Occupancy';
        break;
      case 'people-count':
        prefix = 'People count';
        break;
      case 'peel-off':
        prefix = 'Peel off';
    }
    return `${prefix} data for this monitor will be unavailable or inaccurate until the issue is resolved.`;
  }
}
