<form class="o-form u-padding--inline" [formGroup]="summaryForm">
  <div class="o-form__legend">
    <h2 class="is-legend">Configure camera health summary</h2>
  </div>
  <div>
    <h3 class="primer">How often do you want to receive this email summary?</h3>

    <mat-form-field class="u-margin--bottom">
      <mat-label>Frequency</mat-label>
      <mat-select ngDefaultControl formControlName="duration" #select>
        <mat-select-trigger>
          {{ select.value?.display }}
        </mat-select-trigger>
        <mat-option *ngFor="let duration of durationOptions" [value]="duration">
          <mat-icon>schedule</mat-icon> {{ duration.display }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="c-inline-form-fields">
      <mat-form-field *ngIf="summaryForm.get('duration').value?.value === 'weekly'">
        <button mat-button matPrefix>
          <mat-icon>calendar_today</mat-icon>
        </button>
        <mat-select placeholder="Day" ngDefaultControl formControlName="day" #select>
          <mat-option *ngFor="let day of daysOfTheWeek" [value]="day">
            {{ day.display }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="summaryForm.get('duration').value">
        <button mat-button matPrefix>
          <mat-icon>av_timer</mat-icon>
        </button>
        <input matInput [ngxTimepicker]="toggleTimepicker" readonly placeholder="Pick time" formControlName="time" />
        <ngx-material-timepicker #toggleTimepicker [disableAnimation]="true"></ngx-material-timepicker>
      </mat-form-field>
    </div>
  </div>

  <div>
    <app-select-recipients
      [users]="users"
      [notificationGroups]="notificationGroups"
      [selectedRecipients]="selectedRecipients"
    ></app-select-recipients>
  </div>
</form>
