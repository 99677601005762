<div class="o-modal-full-page has-no-header o-dialog-page">
  <mat-tab-group
    class="c-tab-groups"
    [selectedIndex]="selectedTabIndex"
    (selectedTabChange)="navigateToTab(monitorsTypeValue[$event.index])"
  >
    <ng-container *ngFor="let item of reportsService.reportDownloadMenuItems">
      <mat-tab label="item">
        <ng-template mat-tab-label>
          <mat-icon style="margin-right: 4px">{{ reportsService.reportDownloadMenuSpec[item].icon }}</mat-icon>
          {{ reportsService.reportDownloadMenuSpec[item].primary_text }}
        </ng-template>

        <div
          class="o-modal-full-page__body c-modal-downloads-body"
          *ngIf="!accountService.isSupport || selectedTabIndex === monitorsType[item]"
        >
          <ng-container [ngSwitch]="item">
            <ng-container *ngSwitchCase="'people_count'">
              <app-download-people-count-data
                [selectedIds]="selectedIds"
                class="c-modal-downloads-body"
              ></app-download-people-count-data>
            </ng-container>
            <ng-container *ngSwitchCase="'occupancy'">
              <app-download-occupancy-data class="c-modal-downloads-body"></app-download-occupancy-data>
            </ng-container>
            <ng-container *ngSwitchCase="'peel_off'">
              <app-download-peel-off-data
                [selectedIds]="selectedIds"
                class="c-modal-downloads-body"
              ></app-download-peel-off-data>
            </ng-container>
            <ng-container *ngSwitchCase="'dwell_time'">
              <app-download-dwell-time-data class="c-modal-downloads-body"></app-download-dwell-time-data>
            </ng-container>
          </ng-container>
        </div>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
</div>
