import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AccountService } from 'src/app/services/account.service';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  selector: 'app-download-report-dialog',
  templateUrl: './download-report-dialog.component.html',
  styleUrls: ['./download-report-dialog.component.scss'],
})
export class DownloadReportDialogComponent implements OnInit, OnDestroy {
  selectedIds;
  selectedTabIndex;

  monitorsType = {};
  monitorsTypeValue = {};

  private ngUnsubscribe = new Subject();

  constructor(
    public reportsService: ReportsService,
    public accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router,
    public ref: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.reportsService.reportDownloadMenuItems.forEach((menu, index) => {
      this.monitorsType[menu] = index;
      this.monitorsTypeValue[index] = menu;
    });

    this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe((queryParams) => {
      const tab = queryParams['tab'];
      if (this.monitorsType[tab] != undefined) {
        this.selectedTabIndex = this.monitorsType[tab];
      } else {
        this.selectedTabIndex = 0;
      }
      const idsParam = queryParams['ids'];
      this.selectedIds = idsParam ? decodeURIComponent(idsParam).split(',') : undefined;
      this.ref.detectChanges();
    });
  }

  navigateToTab(tab): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { tab },
      queryParamsHandling: 'merge',
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
