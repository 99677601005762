<div class="c-site-filter__sites">
  <div class="c-site-filter__sites-text">Filter by site</div>
  <ul class="o-chip__list is-list">
    <ng-container *ngFor="let site of sites">
      <li class="o-chip__item" (click)="filterSite(site)" [ngClass]="{ 'item-selected': filteredSites.includes(site) }">
        {{ site.name }}
      </li>
    </ng-container>
  </ul>
  <div class="c-site-filter__clear">
    <button
      class="c-clear-filter"
      (click)="clearFilter()"
      [ngClass]="{ 'all-item-selected': filteredSites.length !== 0 }"
    >
      <mat-icon class="is-icon">clear</mat-icon>
      Clear filters
    </button>
  </div>
</div>
