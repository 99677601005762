import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { finalize, mergeMap, Observable, Subject, switchMap, takeUntil } from 'rxjs';
import {
  CameraService,
  PeelOffMonitor,
  PeelOffMonitorCameraPosition,
  PeelOffMonitorCameraPositionService,
  PeelOffMonitorService,
  Site,
} from 'src/app/api';
import { CameraStatus } from 'src/app/model/cameraStatus';
import { NotifyService } from 'src/app/services/notify.service';
import { UserConfirmationComponent } from 'src/app/components/general/user-confirmation/user-confirmation.component';
import { AccountService } from 'src/app/services/account.service';
import * as moment from 'moment';
import { MatSelectChange } from '@angular/material/select';

type stepsEnum =
  | 'namePeelOffMonitor'
  | 'selectCamerasForpassingCount'
  | 'selectDirectionForpassingCount'
  | 'selectCamerasForEntranceCount'
  | 'selectDirectionForEntranceCount'
  | 'setupComplete';

const steps: { [key in stepsEnum]: number } = {
  namePeelOffMonitor: 0,
  selectCamerasForpassingCount: 1,
  selectDirectionForpassingCount: 2,
  selectCamerasForEntranceCount: 3,
  selectDirectionForEntranceCount: 4,
  setupComplete: 5,
};

@Component({
  selector: 'app-add-edit-peel-off-monitor',
  templateUrl: './add-edit-peel-off-monitor.component.html',
  styleUrls: ['./add-edit-peel-off-monitor.component.scss'],
})
export class AddEditPeelOffMonitorComponent implements OnInit, OnDestroy, AfterViewInit {
  stepsTitle: string;
  isLoading: boolean;
  steps = steps;

  editing = false;
  sites: Site[];
  filteredSites: Site[];
  cameras: CameraStatus[];
  entranceCountCameras: CameraStatus[];
  entranceCountCamerasForActiveSite: CameraStatus[] = [];
  passingCountCameras: CameraStatus[];
  passingCountCamerasForActiveSite: CameraStatus[] = [];
  peelOffMonitor: PeelOffMonitor;
  selectedCamerasDirections: { [id: string]: { direction1: boolean; direction2: boolean } } = {};
  cameraFrame = {};
  camerasPositionsMap: {
    [_: string]: PeelOffMonitorCameraPosition;
  } = {};
  isRedirect = false;

  peelOffMonitorForm: FormGroup;
  passingCountCamerasFormErrors = false;
  entranceCountCamerasFormErrors = false;

  selectedPassingCountCameras = new SelectionModel<CameraStatus>(true, []);
  selectedEntranceCountCameras = new SelectionModel<CameraStatus>(true, []);

  @ViewChild('stepper') stepper: MatStepper;

  ngUnsubscribe = new Subject();

  constructor(
    private dialogRef: MatDialogRef<AddEditPeelOffMonitorComponent>,
    private ref: ChangeDetectorRef,
    private router: Router,
    private matDialog: MatDialog,
    private formBuilder: FormBuilder,
    private cameraService: CameraService,
    private peelOffMonitorCameraPositionService: PeelOffMonitorCameraPositionService,
    private peelOffMonitorService: PeelOffMonitorService,
    private notifyService: NotifyService,
    public accountService: AccountService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.initializeData();
    this.setupSelectionChangeSubscriptions();
    this.prepareFormGroups();

    this.dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape') {
        this.close();
      }
    });
  }

  ngAfterViewInit(): void {
    this.setupStepperSubscriptions();
  }

  private initializeData(): void {
    this.cameras = this.data.cameras;
    this.sites = this.data.sites;
    this.stepsTitle = this.data.stepsTitle;
    this.passingCountCameras = this.sortCameras([...this.cameras]);
    this.entranceCountCameras = this.sortCameras([...this.cameras]);
    this.isRedirect = this.data.isRedirect;
    if (this.data.peelOffMonitor) {
      this.peelOffMonitor = this.data.peelOffMonitor;
      this.camerasPositionsMap = { ...this.data.camerasPositionsMap };
      this.editing = true;
    }
    this.filterByOrganisation();
  }

  filterByOrganisation(matSelectChange?: MatSelectChange) {
    this.filteredSites = matSelectChange
      ? this.sites.filter((s) => s.organisationId === matSelectChange.value)
      : this.sites;
  }

  private sortCameras(cameras: CameraStatus[]): CameraStatus[] {
    return cameras.sort((c1, c2) => c1.name.localeCompare(c2.name));
  }

  private setupSelectionChangeSubscriptions(): void {
    this.selectedPassingCountCameras.changed.subscribe((change) => this.handlePassingCameraChange(change));
    this.selectedEntranceCountCameras.changed.subscribe((change) => this.handleEntranceCameraChange(change));
  }

  private handlePassingCameraChange(change: SelectionChange<CameraStatus>): void {
    this.passingCountCamerasFormErrors = this.selectedPassingCountCameras.selected.length === 0;
    if (change.added.length) {
      const addedCamera = change.added[0];
      this.entranceCountCameras = this.entranceCountCameras.filter((camera) => camera.id !== addedCamera.id);
    } else if (change.removed.length) {
      const removedCamera = change.removed[0];
      this.entranceCountCameras.push(removedCamera);
      this.entranceCountCameras = this.sortCameras(this.entranceCountCameras);
    }
  }

  private handleEntranceCameraChange(change: SelectionChange<CameraStatus>): void {
    this.entranceCountCamerasFormErrors = this.selectedEntranceCountCameras.selected.length === 0;
    if (change.added.length) {
      const addedCamera = change.added[0];
      this.passingCountCameras = this.passingCountCameras.filter((camera) => camera.id !== addedCamera.id);
    } else if (change.removed.length) {
      const removedCamera = change.removed[0];
      this.passingCountCameras.push(removedCamera);
      this.passingCountCameras = this.sortCameras(this.passingCountCameras);
    }
  }

  private setupStepperSubscriptions(): void {
    this.stepper.selectionChange.subscribe((stepper) => {
      if (stepper.selectedIndex === this.steps['selectCamerasForpassingCount']) {
        this.preparePassingCountSetupStep();
      }

      if (stepper.selectedIndex === this.steps['selectDirectionForpassingCount']) {
        this.prepareSelectDirectionStep(this.selectedPassingCountCameras, true);
      }

      if (stepper.selectedIndex === this.steps['selectCamerasForEntranceCount']) {
        this.prepareEntranceCountSetupStep();
      }

      if (stepper.selectedIndex === this.steps['selectDirectionForEntranceCount']) {
        this.prepareSelectDirectionStep(this.selectedEntranceCountCameras);
      }
    });
  }

  private prepareFormGroups(): void {
    var monitorName = '';
    var site = undefined;
    var reportingEndDate = undefined;
    var reportingStartDate = undefined;

    if (this.peelOffMonitor) {
      monitorName = this.peelOffMonitor.name;
      site = this.sites.find((s) => s.id === this.peelOffMonitor.siteId);
      reportingEndDate = this.peelOffMonitor.reportingEndDate;
      reportingStartDate = this.peelOffMonitor.reportingStartDate;
    }

    this.peelOffMonitorForm = this.formBuilder.group({
      monitorName: [monitorName, Validators.required],
      site: [site, Validators.required],
      reportingEndDate: [reportingEndDate],
      reportingStartDate: [reportingStartDate],
    });

    this.passingCountCameras.forEach((camera) => {
      if (this.camerasPositionsMap[camera.cameraPositionId]?.countType === 'base') {
        this.selectedPassingCountCameras.toggle(camera);
      }
    });

    this.entranceCountCameras.forEach((camera) => {
      if (this.camerasPositionsMap[camera.cameraPositionId]?.countType === 'peel_off') {
        this.selectedEntranceCountCameras.toggle(camera);
      }
    });
  }

  atLeastOneCheckboxCheckedValidator(): ValidatorFn {
    return (control: FormArray): { [key: string]: any } | null => {
      const checkboxes = control.controls;
      const checkedCheckboxes = checkboxes.filter((checkbox) => checkbox.value === true);

      if (checkedCheckboxes.length > 0) {
        return null;
      } else {
        return { atLeastOneCheckboxChecked: true };
      }
    };
  }

  toggleEntranceCountCamera(entranceCountCamera: any): void {
    this.selectedEntranceCountCameras.toggle(entranceCountCamera);
    this.ref.detectChanges();
  }

  togglePassingCountCamera(passingCountCamera: any): void {
    this.selectedPassingCountCameras.toggle(passingCountCamera);
    this.ref.detectChanges();
  }

  close(reload?: boolean): void {
    if (reload) {
      this.dialogRef.close('reload');
    } else {
      if (this.peelOffMonitorForm.touched) {
        const message = `You have unsaved changes that will be lost if you exit the journey now.
    
      Are you sure you want to close this screen?`;
        const dialogRef = this.matDialog.open(UserConfirmationComponent, {
          data: { message, closeText: 'stay on page', buttonText: 'leave page' },
        });
        dialogRef.afterClosed().subscribe({
          next: (result) => {
            if (result === 'confirm') {
              if (this.isRedirect) {
                history.back();
              } else {
                this.dialogRef.close();
              }
            }
          },
        });
      } else {
        this.dialogRef.close();
      }
    }
  }

  setUpSelectCamerasDirections(selectedCameras, isPassingCount?): void {
    const allSelectedCountCameras = [...selectedCameras.selected];

    const oldSelectedCamerasDirections = { ...this.selectedCamerasDirections };
    this.selectedCamerasDirections = {};
    allSelectedCountCameras.forEach((selectedCountCamera: CameraStatus) => {
      var direction1 = false;
      var direction2 = false;
      if (this.camerasPositionsMap[selectedCountCamera.cameraPositionId]) {
        direction1 = this.camerasPositionsMap[selectedCountCamera.cameraPositionId].countDirection === 'direction_1';
        direction2 = this.camerasPositionsMap[selectedCountCamera.cameraPositionId].countDirection === 'direction_2';
      } else {
        direction1 = true;
        direction2 = !!isPassingCount;
      }
      this.selectedCamerasDirections[selectedCountCamera.id] = {
        direction1,
        direction2,
      };
    });
    this.selectedCamerasDirections = { ...this.selectedCamerasDirections, ...oldSelectedCamerasDirections };
  }

  setUpCameraFrames(selectedCameras): void {
    const allSelectedCountCameras = [...selectedCameras.selected];

    const frameRequests = [];
    allSelectedCountCameras.forEach((selectedCountCamera) => {
      const req = this.cameraService.createFrameRequest({ cameraId: selectedCountCamera.id });
      frameRequests.push(req);
    });

    allSelectedCountCameras.forEach((selectedCountCamera) => {
      this.cameraService
        .createFrameRequest({ cameraId: selectedCountCamera.id })
        .pipe(
          takeUntil(this.ngUnsubscribe),
          finalize(() => {
            this.ref.detectChanges();
          }),
        )
        .subscribe({
          next: (frame) => {
            this.cameraFrame[selectedCountCamera.id] = 'data:image/jpeg;base64,' + frame.image;
          },
          error: (error) => {
            this.notifyService.error(error);
          },
        });
    });
  }

  prepareSelectDirectionStep(selectedCameras, isPassingCount?): void {
    this.setUpSelectCamerasDirections(selectedCameras, isPassingCount);
    this.setUpCameraFrames(selectedCameras);
  }

  goToNextStep(): void {
    if (this.stepper.selectedIndex === this.steps['namePeelOffMonitor']) {
      this.updatePeelOffMonitorData();
    }

    if (this.stepper.selectedIndex === this.steps['selectCamerasForpassingCount']) {
      if (this.selectedPassingCountCameras.selected.length === 0) {
        return;
      }
    }

    if (this.stepper.selectedIndex === this.steps['selectDirectionForpassingCount']) {
      if (
        !Object.values(this.selectedCamerasDirections).every((selectedCameraDirections) => {
          return selectedCameraDirections.direction1 || selectedCameraDirections.direction2;
        })
      ) {
        return;
      }
    }

    if (this.stepper.selectedIndex === this.steps['selectCamerasForEntranceCount']) {
      if (this.selectedEntranceCountCameras.selected.length === 0) {
        return;
      }
    }

    if (this.stepper.selectedIndex === this.steps['selectDirectionForEntranceCount']) {
      if (
        !Object.values(this.selectedCamerasDirections).every((selectedCameraDirections) => {
          return selectedCameraDirections.direction1 || selectedCameraDirections.direction2;
        })
      ) {
        return;
      }
    }

    this.stepper.next();
  }

  isValidDirectionsStep(): boolean {
    return !Object.values(this.selectedCamerasDirections).every((selectedCameraDirections) => {
      return selectedCameraDirections.direction1 || selectedCameraDirections.direction2;
    });
  }

  getPeelOffMonitorCameraPositions(): Observable<any> {
    return this.peelOffMonitorService.putPeelOffMonitor(this.peelOffMonitor).pipe(
      takeUntil(this.ngUnsubscribe),
      switchMap((peelOffMonitor: PeelOffMonitor) => {
        if (peelOffMonitor) {
          this.peelOffMonitor = peelOffMonitor;
        }

        const requests: Observable<PeelOffMonitorCameraPosition>[] = [];

        requests.push(...this.getEntranceCountCameraRequests());
        requests.push(...this.getPassingCountCameraRequests());
        requests.push(...this.getDeletedCameraPositionRequests());

        return requests;
      }),
      mergeMap((data) => data),
    );
  }

  updatePeelOffMonitorData(): void {
    const { monitorName, site, reportingEndDate, reportingStartDate } = this.peelOffMonitorForm.value;

    this.peelOffMonitor = {
      ...this.peelOffMonitor,
      name: monitorName,
      siteId: site.id,
      reportingEndDate: reportingEndDate ? moment(reportingEndDate).format('YYYY-MM-DD') : undefined,
      reportingStartDate: reportingStartDate ? moment(reportingStartDate).format('YYYY-MM-DD') : undefined,
    };
  }

  getEntranceCountCameraRequests(): Observable<PeelOffMonitorCameraPosition>[] {
    return this.selectedEntranceCountCameras.selected.map((selectedCountCamera: CameraStatus) => {
      const countDirection = this.getCameraCountDirection(selectedCountCamera);

      const peelOffMonitorCameraPosition: PeelOffMonitorCameraPosition = {
        id: this.camerasPositionsMap[selectedCountCamera.cameraPositionId]?.id,
        cameraPositionId: selectedCountCamera.cameraPositionId,
        countDirection,
        countType: 'peel_off',
        peelOffMonitorId: this.peelOffMonitor.id,
      };

      delete this.camerasPositionsMap[selectedCountCamera.cameraPositionId];

      return this.peelOffMonitorCameraPositionService.putPeelOffMonitorCameraPosition(peelOffMonitorCameraPosition);
    });
  }

  getPassingCountCameraRequests(): Observable<PeelOffMonitorCameraPosition>[] {
    return this.selectedPassingCountCameras.selected.map((selectedCountCamera: CameraStatus) => {
      const countDirection = this.getCameraCountDirection(selectedCountCamera);

      const peelOffMonitorCameraPosition: PeelOffMonitorCameraPosition = {
        id: this.camerasPositionsMap[selectedCountCamera.cameraPositionId]?.id,
        cameraPositionId: selectedCountCamera.cameraPositionId,
        countDirection: countDirection,
        countType: 'base',
        peelOffMonitorId: this.peelOffMonitor.id,
      };

      delete this.camerasPositionsMap[selectedCountCamera.cameraPositionId];

      return this.peelOffMonitorCameraPositionService.putPeelOffMonitorCameraPosition(peelOffMonitorCameraPosition);
    });
  }

  getDeletedCameraPositionRequests(): Observable<PeelOffMonitorCameraPosition>[] {
    const deletedRequests: Observable<PeelOffMonitorCameraPosition>[] = [];

    Object.values(this.camerasPositionsMap).forEach((peelOffMonitorCameraPosition) => {
      deletedRequests.push(
        this.peelOffMonitorCameraPositionService.deletePeelOffMonitorCameraPosition(peelOffMonitorCameraPosition.id),
      );
    });

    return deletedRequests;
  }

  getCameraCountDirection(selectedCountCamera: CameraStatus): PeelOffMonitorCameraPosition.CountDirectionEnum {
    const activeCameraDirections = this.selectedCamerasDirections[selectedCountCamera.id];

    return activeCameraDirections.direction1 && activeCameraDirections.direction2
      ? PeelOffMonitorCameraPosition.CountDirectionEnum.Both
      : activeCameraDirections.direction1
        ? PeelOffMonitorCameraPosition.CountDirectionEnum._1
        : PeelOffMonitorCameraPosition.CountDirectionEnum._2;
  }

  onFinishDialog(): void {
    this.getPeelOffMonitorCameraPositions().subscribe({
      next: () => {
        this.close(true);
      },
      error: (error) => {
        this.notifyService.error(error);
      },
    });
  }

  preparePassingCountSetupStep(): void {
    this.passingCountCamerasForActiveSite = this.passingCountCameras.filter(
      (camera) => camera.siteId === this.peelOffMonitorForm.value['site'].id,
    );
  }

  onPassingDirectionButtonClick(countCameraId: string, direction: 'direction1' | 'direction2'): void {
    this.selectedCamerasDirections[countCameraId][direction] =
      !this.selectedCamerasDirections[countCameraId][direction];
  }

  onEntranceDirectionButtonClick(countCameraId: string, direction: 'direction1' | 'direction2'): void {
    switch (direction) {
      case 'direction1':
        this.selectedCamerasDirections[countCameraId]['direction1'] = true;
        this.selectedCamerasDirections[countCameraId]['direction2'] = false;
        break;
      case 'direction2':
        this.selectedCamerasDirections[countCameraId]['direction1'] = false;
        this.selectedCamerasDirections[countCameraId]['direction2'] = true;
        break;
    }
  }

  prepareEntranceCountSetupStep(): void {
    this.entranceCountCamerasForActiveSite = this.entranceCountCameras.filter(
      (camera) => camera.siteId === this.peelOffMonitorForm.value['site'].id,
    );
  }

  goToStep(step: number): void {
    this.stepper.selectedIndex = step;
    this.ref.detectChanges();
  }

  viewMonitor(): void {
    this.getPeelOffMonitorCameraPositions().subscribe({
      next: () => {
        this.close(true);
        this.router.navigate([`monitors/peel-off-monitor/${this.peelOffMonitor.id}`]);
      },
      error: (error) => {
        this.notifyService.error(error);
      },
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
