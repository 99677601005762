import { CdkStep } from '@angular/cdk/stepper';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import * as moment from 'moment';
import { Observable, Subject, finalize, forkJoin, switchMap, takeUntil } from 'rxjs';
import {
  PeopleCountMonitor,
  PeopleCountMonitorCameraPosition,
  PeopleCountMonitorCameraPositionService,
  PeopleCountMonitorService,
} from 'src/app/api';
import { GiveMonitorNameStepComponent } from 'src/app/components/steps-for-stepper/give-monitor-name-step/give-monitor-name-step.component';
import { SelectCameraDirectionStepComponent } from 'src/app/components/steps-for-stepper/select-camera-direction-step/select-camera-direction-step.component';
import { SelectCamerasStepComponent } from 'src/app/components/steps-for-stepper/select-cameras-step/select-cameras-step.component';
import { SelectMonitorDirectionStepComponent } from 'src/app/components/steps-for-stepper/select-monitor-direction-step/select-monitor-direction-step.component';
import { CameraStatus } from 'src/app/model/cameraStatus';
import { NotifyService } from 'src/app/services/notify.service';
import { UserConfirmationComponent } from '../../general/user-confirmation/user-confirmation.component';

@Component({
  selector: 'app-edit-people-count-monitor',
  templateUrl: './edit-people-count-monitor.component.html',
  styleUrls: ['./edit-people-count-monitor.component.scss'],
})
export class EditPeopleCountMonitorComponent implements OnInit, OnDestroy {
  monitorCameras: CameraStatus[];
  unusedCameras: CameraStatus[];
  cameraPositions = {};
  cameraPositionsToRemove = [];
  monitor: PeopleCountMonitor;
  cameras: CameraStatus[] = [];
  saving = false;
  isRedirect = false;

  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('generalStep') generalStep: CdkStep;
  @ViewChild('addAlertsStep') addAlertsStep: CdkStep;
  @ViewChild('selectNewCameraDirectionStep') selectNewCameraDirectionStep: CdkStep;

  @ViewChild(GiveMonitorNameStepComponent) giveMonitorNameStepComponent: GiveMonitorNameStepComponent;
  @ViewChild(SelectCamerasStepComponent) selectCamerasStepComponent: SelectCamerasStepComponent;
  @ViewChild(SelectMonitorDirectionStepComponent)
  selectMonitorDirectionStepComponent: SelectMonitorDirectionStepComponent;
  @ViewChild('updateCameraDirectionStepComponent')
  updateCameraDirectionStepComponent: SelectCameraDirectionStepComponent;
  @ViewChild('selectNewCameraDirectionStepComponent')
  selectNewCameraDirectionStepComponent: SelectCameraDirectionStepComponent;

  private ngUnsubscribe = new Subject();

  constructor(
    public dialogRef: MatDialogRef<EditPeopleCountMonitorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private peopleCountMonitorService: PeopleCountMonitorService,
    private peopleCountMonitorCameraPositionService: PeopleCountMonitorCameraPositionService,
    private notifyService: NotifyService,
    private matDialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.monitor = this.data.monitor;
    this.cameras = this.data.cameras;
    this.cameras = this.data.cameras;
    this.monitorCameras = this.data.monitorCameras;
    this.cameraPositions = this.data.cameraPositions;
    this.isRedirect = this.data.isRedirect;
    this.monitorCameras.forEach((camera) => {
      camera['reversed'] =
        this.data.cameraPositions[camera.cameraPositionId].direction1 === 'direction_1' ? false : true;
    });
    this.unusedCameras = this.excludeCameras(this.cameras, this.monitorCameras);
  }

  goToSelectNewCameraDirectionStep(): void {
    this.selectCamerasStepComponent.monitorForm.markAllAsTouched();
    if (this.selectCamerasStepComponent.monitorForm.valid) {
      this.stepper.selected = this.selectNewCameraDirectionStep;
    }
  }

  addNewCameras(): void {
    this.selectNewCameraDirectionStepComponent.monitorForm.markAsTouched();
    if (this.selectNewCameraDirectionStepComponent.monitorForm.valid) {
      var newCameras = this.unusedCameras.filter((existedCamera) =>
        this.selectNewCameraDirectionStepComponent.monitorForm
          .get('camera')
          .value.map((newCamera) => newCamera.id)
          .includes(existedCamera.id),
      );

      this.cameras = [...this.cameras, ...newCameras];
      this.unusedCameras = this.excludeCameras(this.unusedCameras, newCameras);

      this.stepper.selected = this.generalStep;
    }

    const newCamerasSettings = this.selectNewCameraDirectionStepComponent.monitorForm.get('camera').value;

    forkJoin(
      newCamerasSettings.map((camera) =>
        this.peopleCountMonitorCameraPositionService.putPeopleCountMonitorCameraPosition({
          peopleCountMonitorId: this.monitor.id,
          cameraPositionId: camera.cameraPositionId,
          direction1: camera.reversed
            ? PeopleCountMonitorCameraPosition.Direction1Enum._2
            : PeopleCountMonitorCameraPosition.Direction1Enum._1,
        }),
      ),
    )
      .pipe(
        finalize(() => {
          this.close(true);
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (_) => {
          this.notifyService.success('Monitor accessibility successfully updated!');
          this.stepper.next();
        },
        error: (error) => {
          this.notifyService.error(error);
        },
      });
  }

  save(): void {
    this.saving = true;
    const requests: Observable<any>[] = [];
    const camerasSettings = this.updateCameraDirectionStepComponent.monitorForm.get('camera').value;

    // update monitor info
    if (
      this.giveMonitorNameStepComponent.monitorForm.value['name'] !== this.monitor.name ||
      this.giveMonitorNameStepComponent.monitorForm.value['reportingStartDate'] !== this.monitor.reportingStartDate ||
      this.giveMonitorNameStepComponent.monitorForm.value['reportingEndDate'] !== this.monitor.reportingEndDate ||
      this.selectMonitorDirectionStepComponent.monitorForm.value['direction1Alias'] !== this.monitor.direction1Alias ||
      this.selectMonitorDirectionStepComponent.monitorForm.value['direction2Alias'] !== this.monitor.direction2Alias
    ) {
      requests.push(
        this.peopleCountMonitorService.putPeopleCountMonitor({
          ...this.giveMonitorNameStepComponent.monitorForm.value,
          ...this.selectMonitorDirectionStepComponent.monitorForm.value,
          reportingEndDate: this.giveMonitorNameStepComponent.monitorForm.value['reportingEndDate']
            ? moment(this.giveMonitorNameStepComponent.monitorForm.value['reportingEndDate']).format('YYYY-MM-DD')
            : undefined,
          reportingStartDate: this.giveMonitorNameStepComponent.monitorForm.value['reportingStartDate']
            ? moment(this.giveMonitorNameStepComponent.monitorForm.value['reportingStartDate']).format('YYYY-MM-DD')
            : undefined,
          id: this.monitor.id,
          siteId: this.monitor.siteId,
        }),
      );
    }

    // delete camera positions
    this.cameraPositionsToRemove.forEach((cameraPositionId) => {
      requests.push(
        this.peopleCountMonitorCameraPositionService.deletePeopleCountMonitorCameraPosition(
          this.cameraPositions[cameraPositionId].id,
        ),
      );
    });

    // update cameraPosition direction1
    camerasSettings.forEach((camerasSetting) => {
      if (
        (camerasSetting.reversed &&
          this.cameraPositions[camerasSetting.cameraPositionId].direction1 ===
            PeopleCountMonitorCameraPosition.Direction1Enum._1) ||
        (!camerasSetting.reversed &&
          this.cameraPositions[camerasSetting.cameraPositionId].direction1 ===
            PeopleCountMonitorCameraPosition.Direction1Enum._2)
      ) {
        requests.push(
          this.peopleCountMonitorCameraPositionService.putPeopleCountMonitorCameraPosition({
            id: this.cameraPositions[camerasSetting.cameraPositionId].id,
            peopleCountMonitorId: this.monitor.id,
            cameraPositionId: camerasSetting.cameraPositionId,
            direction1: camerasSetting.reversed
              ? PeopleCountMonitorCameraPosition.Direction1Enum._2
              : PeopleCountMonitorCameraPosition.Direction1Enum._1, //chane prop name
          }),
        );
      }
    });

    forkJoin(requests)
      .pipe(
        finalize(() => {
          this.saving = false;
          this.close(true);
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (_) => {
          this.notifyService.success('Monitor accessibility successfully updated!');
        },
        error: (error) => {
          this.notifyService.error(error);
        },
      });
  }

  excludeCameras(existedActiveCameras: CameraStatus[], camerasToExclude: CameraStatus[]): CameraStatus[] {
    return existedActiveCameras.filter(
      (existedCamera) => !camerasToExclude.map((cameraToExclude) => cameraToExclude.id).includes(existedCamera.id),
    );
  }

  backToMainStep(): void {
    this.stepper.selected = this.generalStep;
  }

  goToAddCamerasStep(): void {
    this.stepper.next();
  }

  goToAddAlertsStep(): void {
    this.stepper.linear = false;
    this.stepper.selected = this.addAlertsStep;
    this.stepper.linear = true;
  }

  onRemoveCamera(cameraPositionId: string): void {
    this.cameraPositionsToRemove.push(cameraPositionId);
  }

  deletePeopleCountMonitor(peopleCountMonitor: PeopleCountMonitor): void {
    const message = `You are about to delete ${peopleCountMonitor.name}.

    Are you sure you want to continue?`;

    const dialogRef = this.matDialog.open(UserConfirmationComponent, {
      data: { message, buttonText: 'Delete' },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'confirm') {
          forkJoin([
            this.peopleCountMonitorCameraPositionService.listPeopleCountMonitorCameraPositions(
              undefined,
              undefined,
              undefined,
              peopleCountMonitor.id,
              'active',
            ),
          ])
            .pipe(
              switchMap(([peopleCountMonitorCameraPositions]) => {
                const requests: Observable<any>[] = [];
                peopleCountMonitorCameraPositions.forEach((peopleCountMonitorCameraPosition) => {
                  requests.push(
                    this.peopleCountMonitorCameraPositionService.deletePeopleCountMonitorCameraPosition(
                      peopleCountMonitorCameraPosition.id,
                    ),
                  );
                });
                if (!requests.length) {
                  return this.peopleCountMonitorService.deletePeopleCountMonitor(peopleCountMonitor.id);
                }
                return forkJoin(requests).pipe(
                  switchMap((_) => this.peopleCountMonitorService.deletePeopleCountMonitor(peopleCountMonitor.id)),
                );
              }),
              takeUntil(this.ngUnsubscribe),
            )
            .subscribe({
              next: (_) => {
                window.location.reload();
              },
              error: (error) => {
                this.notifyService.error(error);
              },
            });
        }
      },
    });
  }

  close(reload?: boolean): void {
    if (this.isRedirect) {
      history.back();
    } else if (reload) {
      this.dialogRef.close('reload');
    } else {
      this.dialogRef.close();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
