import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { SitesComponent } from './views/sites/sites.component';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { SiteDetailsComponent } from './views/site-details/site-details.component';
import { InternalErrorComponent } from './views/internal-error/internal-error.component';
import { UsersComponent } from './views/users/users.component';
import { NotificationsComponent } from './views/notifications/notifications.component';
import { CamerasComponent } from './views/cameras/cameras.component';
import { HomeComponent } from './views/home/home.component';
import { OrganisationsComponent } from './views/organisations/organisations.component';
import { SiteInfoComponent } from './views/site-details/routes/site-info/site-info.component';
import { SiteCamerasComponent } from './views/site-details/routes/site-cameras/site-cameras.component';
import { OccupancyMonitorDetailsComponent } from './views/monitors/routes/occupancy-monitor-details/occupancy-monitor-details.component';
import { PeopleCountMonitorDetailsComponent } from './views/monitors/routes/people-count-monitor-details/people-count-monitor-details.component';
import { OccupancyLicenceGuard } from './guards/occupancy-licence.guard';
import { NoCameraAlertsComponent } from './views/notifications/routes/no-camera-alerts/no-camera-alerts.component';
import { NotificationGroupsComponent } from './views/notifications/routes/notification-groups/notification-groups.component';
import { MonitorAlertsComponent } from './views/notifications/routes/monitor-alerts/monitor-alerts.component';
import { NotificationGroupComponent } from './views/notifications/routes/notification-group/notification-group.component';
import { MonitorsComponent } from './views/monitors/monitors.component';
import { NoAccessComponent } from 'src/app/views/no-access/no-access.component';
import { CameraAlertsOfflineComponent } from './views/notifications/routes/camera-alerts-offline/camera-alerts-offline.component';
import { NoCameraSummariesComponent } from './views/notifications/routes/no-camera-summaries/no-camera-summaries.component';
import { CameraSummariesComponent } from './views/notifications/routes/camera-summaries/camera-summaries.component';
import { SupportGuard } from './guards/support.guard';
import { RealTimeDataComponent } from './views/real-time-data/real-time-data.component';
import { ReportingComponent } from './views/reporting/reporting.component';
import { CustomerEngagementComponent } from './views/customer-engagement/customer-engagement.component';
import { HomeGuard } from './guards/home.guard';
import { PeopleCountAllMonitorsDialogComponent } from './views/reporting/components/people-count-all-monitors-dialog/people-count-all-monitors-dialog.component';
import { DownloadReportDialogComponent } from './views/reporting/components/download-report-dialog/download-report-dialog.component';
import { DataExploreDialogComponent } from './views/reporting/components/data-explore-dialog/data-explore-dialog.component';
import { PeelOffMonitorDetailsComponent } from './views/monitors/routes/peel-off-monitor-details/peel-off-monitor-details.component';
import { CapacityManagementDialogComponent } from './views/reporting/components/capacity-management-dialog/capacity-management-dialog.component';
import { CameraDetailsComponent } from './components/cameras/camera-details/camera-details.component';
import { MonitorsInfoComponent } from './views/monitors/routes/monitors-info/monitors-info.component';
import { DwellTimeReportReviewComponent } from './views/reporting/components/dwell-time-report-review/dwell-time-report-review.component';
import { SettingsComponent } from './views/settings/settings.component';
import { NotSupportGuard } from './guards/not-support.guard';
import { AdminGuard } from 'src/app/guards/admin-guard.service';
import { OAuthClientModalComponent } from './views/settings/components/oauth-client-modal/oauth-client-modal.component';
import { hasAccessGuard } from './guards/has-access.guard';

const routes: Routes = [
  {
    path: 'sites/:siteId/occupancy-monitor/:id',
    redirectTo: 'monitors/occupancy-monitor/:id',
  },
  {
    path: 'sites/:siteId/occupancy-monitor/:id/:edit',
    redirectTo: 'monitors/occupancy-monitor/:id/:edit',
  },
  {
    path: 'sites/:siteId/people-count-monitor/:id',
    redirectTo: 'monitors/people-count-monitor/:id',
  },
  {
    path: 'sites/:siteId/people-count-monitor/:id/:edit',
    redirectTo: 'monitors/people-count-monitor/:id/:edit',
  },
  {
    path: 'sites/:siteId/peel-off-monitor/:id',
    redirectTo: 'monitors/peel-off-monitor/:id',
  },
  {
    path: 'sites/:siteId/peel-off-monitor/:id/:edit',
    redirectTo: 'monitors/peel-off-monitor/:id/:edit',
  },
  {
    path: 'alerts',
    component: NotificationsComponent,
    canActivate: [AuthGuard, hasAccessGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'camera-alerts-offline',
      },
      {
        path: 'monitor-alerts',
        component: MonitorAlertsComponent,
        canActivate: [OccupancyLicenceGuard],
      },
      {
        path: 'camera-alerts-offline',
        component: CameraAlertsOfflineComponent,
      },
      {
        path: 'camera-summaries',
        component: CameraSummariesComponent,
      },
      {
        path: 'no-camera-alerts',
        component: NoCameraAlertsComponent,
        canActivate: [SupportGuard],
      },
      {
        path: 'no-camera-summaries',
        component: NoCameraSummariesComponent,
        canActivate: [SupportGuard],
      },
      {
        path: 'notification-groups',
        component: NotificationGroupsComponent,
      },
      {
        path: 'notification-groups/:id',
        component: NotificationGroupComponent,
      },
    ],
  },
  {
    path: 'cameras',
    component: CamerasComponent,
    canActivate: [AuthGuard, hasAccessGuard],
    children: [
      {
        path: 'details/:id',
        component: CameraDetailsComponent,
      },
    ],
  },
  {
    path: 'monitors',
    component: MonitorsComponent,
    canActivate: [AuthGuard, hasAccessGuard],
    children: [
      {
        path: '',
        component: MonitorsInfoComponent,
      },
      {
        path: 'occupancy-monitor/:id',
        component: OccupancyMonitorDetailsComponent,
      },

      {
        path: 'occupancy-monitor/:id/:edit',
        component: OccupancyMonitorDetailsComponent,
      },
      {
        path: 'people-count-monitor/:id',
        component: PeopleCountMonitorDetailsComponent,
      },
      {
        path: 'people-count-monitor/:id/:edit',
        component: PeopleCountMonitorDetailsComponent,
      },
      {
        path: 'peel-off-monitor/:id',
        component: PeelOffMonitorDetailsComponent,
      },
      {
        path: 'peel-off-monitor/:id/:edit',
        component: PeelOffMonitorDetailsComponent,
      },
    ],
  },
  {
    path: 'monitors/:siteId',
    component: MonitorsComponent,
    canActivate: [AuthGuard, hasAccessGuard],
    children: [
      {
        path: '',
        component: MonitorsInfoComponent,
      },
    ],
  },
  {
    path: 'organisations',
    component: OrganisationsComponent,
    canActivate: [AuthGuard, hasAccessGuard, SupportGuard],
  },
  {
    path: 'sites',
    component: SitesComponent,
    canActivate: [AuthGuard, hasAccessGuard],
  },
  {
    path: 'sites/:id',
    component: SiteDetailsComponent,
    canActivate: [AuthGuard, hasAccessGuard],
    children: [
      {
        path: '',
        component: SiteInfoComponent,
      },
      {
        path: 'cameras',
        component: SiteCamerasComponent,
      },
      {
        path: 'cameras/:id',
        component: SiteCamerasComponent,
      },
      {
        path: 'cameras/details/:id',
        component: CameraDetailsComponent,
      },
      {
        path: 'cameras/:id/details/:id',
        component: CameraDetailsComponent,
      },
    ],
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AuthGuard, hasAccessGuard, SupportGuard],
  },
  {
    path: 'customer-engagement',
    component: CustomerEngagementComponent,
    canActivate: [AuthGuard, hasAccessGuard, SupportGuard],
  },
  {
    path: 'internal-error',
    component: InternalErrorComponent,
    canActivate: [AuthGuard],
  },
  { path: 'not-found', component: NotFoundComponent, canActivate: [AuthGuard] },
  { path: 'no-access', component: NoAccessComponent, canActivate: [AuthGuard] },
  { path: '', component: HomeComponent, canActivate: [HomeGuard] },
  {
    path: 'infrastructure',
    component: HomeComponent,
    canActivate: [AuthGuard, hasAccessGuard],
    children: [
      {
        path: 'oauth-client',
        component: OAuthClientModalComponent,
      },
    ],
  },
  {
    path: 'settings/oauth-client',
    component: OAuthClientModalComponent,
    canActivate: [AuthGuard, hasAccessGuard, NotSupportGuard],
  },
  {
    path: 'settings/oauth-client/:id',
    component: OAuthClientModalComponent,
    canActivate: [AuthGuard, hasAccessGuard, NotSupportGuard],
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard, hasAccessGuard, AdminGuard],
  },
  {
    path: 'real-time-occupancy',
    component: RealTimeDataComponent,
    canActivate: [AuthGuard, hasAccessGuard, OccupancyLicenceGuard],
  },
  {
    path: 'reporting',
    component: ReportingComponent,
    canActivate: [AuthGuard, hasAccessGuard],
    children: [
      {
        path: 'people-count',
        component: PeopleCountAllMonitorsDialogComponent,
      },
      {
        path: 'capacity-management/:id',
        component: CapacityManagementDialogComponent,
      },
      {
        path: 'dwell-time-reports/:id',
        component: DwellTimeReportReviewComponent,
        canActivate: [SupportGuard],
      },
      {
        path: 'report-csv-download',
        component: DownloadReportDialogComponent,
      },
      {
        path: 'data-explorer',
        component: DataExploreDialogComponent,
      },
    ],
  },
  { path: '**', redirectTo: 'not-found', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
