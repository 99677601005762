import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeToString',
})
export class TimeToStringPipe implements PipeTransform {
  transform(time: number): string {
    const m = Math.trunc((time / 60000) % 60);
    const h = Math.trunc(time / 60000 / 60);
    return `${h ? h + 'h' : ''} ${m}m`;
  }
}
