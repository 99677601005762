import { Injectable, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  finalize,
  forkJoin,
  of,
  takeUntil,
  takeWhile,
  throwError,
  timer,
} from 'rxjs';
import {
  Camera,
  DwellTimeRequestResponse,
  OccupancyMonitor,
  OccupancyMonitorCameraPosition,
  PeelOffMonitorCameraPosition,
  PeopleCountMonitorCameraPosition,
  ReportService,
  Site,
} from 'src/app/api';
import { GlobalMethods } from 'src/app/global-methods';
import { CameraStatus } from 'src/app/model/cameraStatus';
import { OccupancyMonitorStatus, PeelOffMonitorStatus, PeopleCountMonitorStatus } from 'src/app/model/monitorStatus';

@Injectable({
  providedIn: 'root',
})
export class ReportsService implements OnDestroy {
  dataExplorerMenuSpec: any = {
    'current-occupancy': {
      icon: 'pending_actions',
      primary_text: 'Real-time occupancy',
      secondary_text: 'Most recent occupancy data',
    },
    'time-series': {
      icon: 'history',
      primary_text: 'Time series',
      secondary_text: 'Time series data',
    },
    'time-comparison': {
      icon: 'more_time',
      primary_text: 'Time comparison',
      secondary_text: 'Compare between time ranges',
    },
    seasonality: {
      icon: 'event',
      primary_text: 'Seasonality',
      secondary_text: 'Seasonality plot',
    },
  };

  reportDownloadMenuSpec: any = {
    people_count: {
      icon: 'transfer_within_a_station',
      primary_text: 'People count',
      secondary_text: 'Download report as CSV',
    },
    occupancy: {
      icon: 'groups',
      primary_text: 'Occupancy',
      secondary_text: 'Download report as CSV',
    },
    peel_off: {
      icon: 'fork_left',
      primary_text: 'Peel off',
      secondary_text: 'Download report as CSV',
    },
    dwell_time: {
      icon: 'timer',
      primary_text: 'Dwell time',
      secondary_text: 'Download report as CSV',
    },
  };

  hasDataExplorer: boolean = false;
  isLoadingRealTimeData: boolean = false;
  dataExplorerMenuItems: string[] = [];
  reportDownloadMenuItems: string[] = [];
  cameras: CameraStatus[] = [];
  sitesMap: { [_: string]: Site } = {};
  camerasMapByCameraPositionId: { [_: string]: CameraStatus } = {};
  peopleCountMonitors: PeopleCountMonitorStatus[] = [];
  peopleCountMonitorCameraPositionsMap: { [_: string]: PeopleCountMonitorCameraPosition[] } = {};
  peopleCountMonitorsMap: { [_: string]: PeopleCountMonitorStatus } = {};
  occupancyMonitors: OccupancyMonitorStatus[] = [];
  occupancyMonitorCameraPositionsMap: { [_: string]: OccupancyMonitorCameraPosition[] } = {};
  occupancyMonitorsMap: { [_: string]: OccupancyMonitorStatus } = {};
  peelOffMonitors: PeelOffMonitorStatus[] = [];
  peelOffMonitorCameraPositionsMap: { [_: string]: PeelOffMonitorCameraPosition[] } = {};
  peelOffMonitorsMap: { [_: string]: PeelOffMonitorStatus } = {};
  realTimeLastUpdate: Date = undefined;
  hasRealTimeDataUpdateAvailable: boolean = false;
  autoUpdateRealTimeData: boolean = false;
  isPeopleCountDaily = true;

  dwellTimeRequestResponse: DwellTimeRequestResponse[] = [];

  useOpeningHoursPeopleCountMonitors = false;
  useOpeningHoursCapacityManagementMonitors = false;

  // the list that is getting displayed
  peopleCountMonitorData: {
    [_: string]: {
      daily: {
        date: Date;
        direction_1: any[];
        direction_2: any[];
        total: any[];
        isLoading$: BehaviorSubject<boolean>;
      };
      weekly: {
        date: Date;
        direction_1: any[];
        direction_2: any[];
        total: any[];
        isLoading$: BehaviorSubject<boolean>;
      };
      dailyOpeningHours: {
        date: Date;
        direction_1: any[];
        direction_2: any[];
        total: any[];
        isLoading$: BehaviorSubject<boolean>;
      };
      weeklyOpeningHours: {
        date: Date;
        direction_1: any[];
        direction_2: any[];
        total: any[];
        isLoading$: BehaviorSubject<boolean>;
      };
    };
  } = {};
  private _peopleCountMonitorDataMutex: boolean = false;
  peopleCountMonitorDataDailyTime: Date;
  peopleCountMonitorDataWeeklyTime: Date;
  private _peopleCountMonitorDataTzRequests = {};
  isLoadingPeopleCountOrderData = new BehaviorSubject(true);
  private _peopleCountOrderDataNgUnsubscribe = new Subject();

  occupancyMonitorRealTimeData: {
    [_: string]: { isLoading$: BehaviorSubject<boolean>; data: any[]; currentOccupancy: number; lastUpdate: Date };
  } = {};
  private _occupancyMonitorRealTimeDataMutex: boolean = false;
  private _occupancyMonitorRealTimeDataTzRequests = {};

  occupancyMonitorRealTimeUpdateData: {
    [_: string]: { data: any[]; currentOccupancy: number; lastUpdate: Date };
  } = {};
  private _occupancyMonitorRealTimeUpdateDataMutex: boolean = false;
  private _occupancyMonitorRealTimeUpdateDataTzRequests = {};

  private _capacityManagementData: {
    [_: string]: {
      [key in 'true' | 'false']: { isLoading$: BehaviorSubject<boolean>; data: any[]; lastUpdate: Date };
    };
  } = {};
  private _capacityManagementDataMutex: boolean = false;
  private _capacityManagementDataTzRequests = {};

  private ngUnsubscribe = new Subject();

  constructor(private reportService: ReportService) {}

  async getPeopleCountMonitorData(
    peopleCountMonitor: Camera | PeopleCountMonitorStatus,
    dataStartDate: Date,
  ): Promise<any> {
    await new Promise<void>((resolve) => {
      const interval = setInterval(() => {
        if (!this._peopleCountMonitorDataMutex) {
          clearInterval(interval);
          resolve();
        }
      }, 10);
    });
    this._peopleCountMonitorDataMutex = true;

    let key = this.isPeopleCountDaily
      ? this.useOpeningHoursPeopleCountMonitors
        ? 'dailyOpeningHours'
        : 'daily'
      : this.useOpeningHoursPeopleCountMonitors
        ? 'weeklyOpeningHours'
        : 'weekly';
    if (
      this.peopleCountMonitorData[peopleCountMonitor.id] &&
      this.peopleCountMonitorData[peopleCountMonitor.id][key] &&
      this.peopleCountMonitorData[peopleCountMonitor.id][key].date !== undefined &&
      this.peopleCountMonitorData[peopleCountMonitor.id][key].date.getTime() === dataStartDate.getTime()
    ) {
      this._peopleCountMonitorDataMutex = false;
      return this.peopleCountMonitorData[peopleCountMonitor.id][key];
    } else if (
      this.peopleCountMonitorData[peopleCountMonitor.id] &&
      this.peopleCountMonitorData[peopleCountMonitor.id][key]
    ) {
      this.peopleCountMonitorData[peopleCountMonitor.id][key].date = dataStartDate;
      this.peopleCountMonitorData[peopleCountMonitor.id][key].isLoading$.next(true);
    } else if (
      this.peopleCountMonitorData[peopleCountMonitor.id] &&
      !this.peopleCountMonitorData[peopleCountMonitor.id][key]
    ) {
      this.peopleCountMonitorData[peopleCountMonitor.id][key] = {
        date: dataStartDate,
        direction_1: undefined,
        direction_2: undefined,
        total: undefined,
        isLoading$: new BehaviorSubject<boolean>(true),
      };
    } else if (!this.peopleCountMonitorData[peopleCountMonitor.id]) {
      this.peopleCountMonitorData[peopleCountMonitor.id] = {
        daily: undefined,
        weekly: undefined,
        dailyOpeningHours: undefined,
        weeklyOpeningHours: undefined,
      };
      this.peopleCountMonitorData[peopleCountMonitor.id][key] = {
        date: dataStartDate,
        direction_1: undefined,
        direction_2: undefined,
        total: undefined,
        isLoading$: new BehaviorSubject<boolean>(true),
      };
    }

    const siteTz = this.sitesMap[peopleCountMonitor.siteId].tz;
    if (siteTz in this._peopleCountMonitorDataTzRequests) {
      this._peopleCountMonitorDataTzRequests[siteTz].push(peopleCountMonitor.id);
    } else {
      this._peopleCountMonitorDataTzRequests[siteTz] = [peopleCountMonitor.id];
    }
    if (this._peopleCountMonitorDataTzRequests[siteTz].length === 1) {
      timer(1000)
        .pipe(takeWhile(() => this._peopleCountMonitorDataTzRequests[siteTz].length < 3))
        .subscribe(async () => {
          await new Promise<void>((resolve) => {
            const interval = setInterval(() => {
              if (!this._peopleCountMonitorDataMutex) {
                clearInterval(interval);
                resolve();
              }
            }, 10);

            this._peopleCountMonitorDataMutex = true;
            if (this._peopleCountMonitorDataTzRequests[siteTz].length > 0) {
              if (key.startsWith('daily')) {
                this.getPeoplecountMonitorDailyDataCall(siteTz, dataStartDate, key);
              } else {
                this.getPeoplecountMonitorWeeklyDataCall(siteTz, dataStartDate, key);
              }
              this._peopleCountMonitorDataTzRequests[siteTz] = [];
            }
            this._peopleCountMonitorDataMutex = false;
          });
        });
    } else if (this._peopleCountMonitorDataTzRequests[siteTz].length >= 3) {
      if (key.startsWith('daily')) {
        this.getPeoplecountMonitorDailyDataCall(siteTz, dataStartDate, key);
      } else {
        this.getPeoplecountMonitorWeeklyDataCall(siteTz, dataStartDate, key);
      }
      this._peopleCountMonitorDataTzRequests[siteTz] = [];
    }

    this._peopleCountMonitorDataMutex = false;

    return this.peopleCountMonitorData[peopleCountMonitor.id][key];
  }

  getPeopleCountMonitorOrderData(): void {
    this._peopleCountOrderDataNgUnsubscribe.next(''); // kill existing subscriptions
    this.isLoadingPeopleCountOrderData.next(true);
    const peopleCountOrderData = {};
    this.peopleCountMonitors.forEach((m) => (peopleCountOrderData[m.id] = 0));

    const requests: Observable<any>[] = [];
    for (let i = 0; i < this.peopleCountMonitors.length; i += 100) {
      const peopleCountMonitorsChunk = this.peopleCountMonitors.slice(i, i + 100);
      const tzList = [];
      const tzRequests = {};
      peopleCountMonitorsChunk.forEach((peopleCountMonitor) => {
        const siteTz = this.sitesMap[peopleCountMonitor.siteId].tz;

        if (siteTz in tzRequests) {
          tzRequests[siteTz].push(peopleCountMonitor.id);
        } else {
          tzRequests[siteTz] = [peopleCountMonitor.id];
          tzList.push(siteTz);
        }
      });

      tzList.forEach((tz) => {
        requests.push(
          this.reportService.createPeopleCountReport({
            peopleCountMonitorIds: tzRequests[tz],
            startDate: moment(
              this.isPeopleCountDaily ? this.peopleCountMonitorDataDailyTime : this.peopleCountMonitorDataWeeklyTime,
            ).format('YYYY-MM-DD'),
            endDate: moment(
              this.isPeopleCountDaily ? this.peopleCountMonitorDataDailyTime : this.peopleCountMonitorDataWeeklyTime,
            )
              .add(1, 'days')
              .format('YYYY-MM-DD'),
            timeGrain: '24h',
          }),
        );
      });
    }

    if (requests.length) {
      forkJoin(requests)
        .pipe(takeUntil(this._peopleCountOrderDataNgUnsubscribe))
        .subscribe({
          next: (reports) => {
            reports.forEach((report) => {
              report.forEach((row) => {
                peopleCountOrderData[row.peopleCountMonitorId] += row.direction1Count + row.direction2Count;
              });
            });
            this.peopleCountMonitors = this.peopleCountMonitors.sort((m1, m2) => {
              if (peopleCountOrderData[m1.id] < peopleCountOrderData[m2.id]) {
                return 1;
              } else {
                return -1;
              }
            });

            const uniqueSiteMonitors = [];
            const theRest = [];

            let i = 0;
            while (i < this.peopleCountMonitors.length) {
              if (
                !uniqueSiteMonitors.find((m) => m.siteId === this.peopleCountMonitors[i].siteId) &&
                uniqueSiteMonitors.length < 3
              ) {
                uniqueSiteMonitors.push(this.peopleCountMonitors[i]);
              } else {
                theRest.push(this.peopleCountMonitors[i]);
              }
              i += 1;
            }

            this.peopleCountMonitors = [
              ...uniqueSiteMonitors,
              ...theRest.sort((m1, m2) => {
                if (m1.state === m2.state) {
                  if (m1.name.toLowerCase() > m2.name.toLowerCase()) {
                    return 1;
                  } else {
                    return -1;
                  }
                } else {
                  if (m1.state === 'paused' || !m1.isOnline) {
                    return 1;
                  } else if (m2.state === 'paused' || !m2.isOnline) {
                    return -1;
                  } else {
                    return 0;
                  }
                }
              }),
            ];

            this.isLoadingPeopleCountOrderData.next(false);
          },
          error: (error) => {
            this.isLoadingPeopleCountOrderData.next(false);
            console.log(error);
          },
        });
    } else {
      this.isLoadingPeopleCountOrderData.next(false);
    }
  }

  private getPeoplecountMonitorDailyDataCall(siteTz: string, dataStartDate: Date, key: string): void {
    const currentWeekDay = moment(moment(dataStartDate).format('YYYY-MM-DD'));
    const previousWeekDay = moment(dataStartDate).subtract(7, 'days');
    const peopleCountMonitorIds = [...this._peopleCountMonitorDataTzRequests[siteTz]];

    let requests;
    requests = [
      this.reportService.createPeopleCountReport({
        peopleCountMonitorIds: peopleCountMonitorIds,
        startDate: currentWeekDay.format('YYYY-MM-DD'),
        endDate: currentWeekDay.clone().add(1, 'days').format('YYYY-MM-DD'),
        timeGrain: '1h',
        isOpen: this.useOpeningHoursPeopleCountMonitors,
      }),
      this.reportService.createPeopleCountReport({
        peopleCountMonitorIds: peopleCountMonitorIds,
        startDate: previousWeekDay.format('YYYY-MM-DD'),
        endDate: previousWeekDay.clone().add(1, 'days').format('YYYY-MM-DD'),
        timeGrain: '1h',
        isOpen: this.useOpeningHoursPeopleCountMonitors,
      }),
    ];
    forkJoin(requests)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (reports: any[]) => {
          peopleCountMonitorIds.forEach((monitorId) => {
            this.peopleCountMonitorData[monitorId][key].direction_1 = [];
            this.peopleCountMonitorData[monitorId][key].direction_2 = [];
            this.peopleCountMonitorData[monitorId][key].total = [];
            for (let i = 0; i < 24; i++) {
              const dailyData = {
                name: currentWeekDay.clone().add(i, 'hours').toISOString(),
                series: [
                  {
                    name: 1,
                    value: 0,
                  },
                  {
                    name: 2,
                    value: 0,
                  },
                ],
              };
              this.peopleCountMonitorData[monitorId][key].direction_1.push(JSON.parse(JSON.stringify(dailyData)));
              this.peopleCountMonitorData[monitorId][key].direction_2.push(JSON.parse(JSON.stringify(dailyData)));
              this.peopleCountMonitorData[monitorId][key].total.push(JSON.parse(JSON.stringify(dailyData)));
            }
          });
          reports.forEach((report, index) => {
            report.forEach((row) => {
              let time = row.time.split('.')[0];
              if (index % 2 === 0) {
                // currentWeekDay
                this.peopleCountMonitorData[row.peopleCountMonitorId][key].direction_1[
                  moment(time).hours()
                ].series[0].value = row.direction1Count;
                this.peopleCountMonitorData[row.peopleCountMonitorId][key].direction_2[
                  moment(time).hours()
                ].series[0].value = row.direction2Count;
                this.peopleCountMonitorData[row.peopleCountMonitorId][key].total[moment(time).hours()].series[0].value =
                  row.direction1Count + row.direction2Count;
              } else {
                // previousWeekDay
                this.peopleCountMonitorData[row.peopleCountMonitorId][key].direction_1[
                  moment(time).hours()
                ].series[1].value = row.direction1Count;
                this.peopleCountMonitorData[row.peopleCountMonitorId][key].direction_2[
                  moment(time).hours()
                ].series[1].value = row.direction2Count;
                this.peopleCountMonitorData[row.peopleCountMonitorId][key].total[moment(time).hours()].series[1].value =
                  row.direction1Count + row.direction2Count;
              }
            });
          });
          peopleCountMonitorIds.forEach((peopleCountMonitorId: string) => {
            this.peopleCountMonitorData[peopleCountMonitorId][key].isLoading$.next(false);
          });
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  private getPeoplecountMonitorWeeklyDataCall(siteTz: string, dataStartDate: Date, key: string): void {
    const currentWeekStart = moment(dataStartDate);
    const lastWeekStart = moment(dataStartDate).subtract(7, 'days');
    const peopleCountMonitorIds = [...this._peopleCountMonitorDataTzRequests[siteTz]];
    let requests;
    requests = [
      this.reportService.createPeopleCountReport({
        peopleCountMonitorIds: peopleCountMonitorIds,
        startDate: currentWeekStart.format('YYYY-MM-DD'),
        endDate: currentWeekStart.clone().add(7, 'days').format('YYYY-MM-DD'),
        timeGrain: '24h',
        isOpen: this.useOpeningHoursPeopleCountMonitors,
      }),
      this.reportService.createPeopleCountReport({
        peopleCountMonitorIds: peopleCountMonitorIds,
        startDate: lastWeekStart.format('YYYY-MM-DD'),
        endDate: lastWeekStart.clone().add(7, 'days').format('YYYY-MM-DD'),
        timeGrain: '24h',
        isOpen: this.useOpeningHoursPeopleCountMonitors,
      }),
    ];

    forkJoin(requests)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (reports: any[]) => {
          peopleCountMonitorIds.forEach((monitorId) => {
            this.peopleCountMonitorData[monitorId][key].direction_1 = [];
            this.peopleCountMonitorData[monitorId][key].direction_2 = [];
            this.peopleCountMonitorData[monitorId][key].total = [];
            for (let i = 0; i < 7; i++) {
              const weeklyData = {
                name: moment(dataStartDate).clone().add(i, 'days').toISOString(),
                series: [
                  {
                    name: 1,
                    value: 0,
                  },
                  {
                    name: 2,
                    value: 0,
                  },
                ],
              };
              this.peopleCountMonitorData[monitorId][key].direction_1.push(JSON.parse(JSON.stringify(weeklyData)));
              this.peopleCountMonitorData[monitorId][key].direction_2.push(JSON.parse(JSON.stringify(weeklyData)));
              this.peopleCountMonitorData[monitorId][key].total.push(JSON.parse(JSON.stringify(weeklyData)));
            }
          });
          reports.forEach((report, index) => {
            report.forEach((row) => {
              let time = row.time.split('.')[0];
              const dayIndex = (14 + moment(time).days() - moment(dataStartDate).days()) % 7;
              if (index % 2 === 0) {
                // currentWeekStart
                this.peopleCountMonitorData[row.peopleCountMonitorId][key].direction_1[dayIndex].series[0].value =
                  row.direction1Count;
                this.peopleCountMonitorData[row.peopleCountMonitorId][key].direction_2[dayIndex].series[0].value =
                  row.direction2Count;
                this.peopleCountMonitorData[row.peopleCountMonitorId][key].total[dayIndex].series[0].value =
                  row.direction1Count + row.direction2Count;
              } else {
                // lastWeekStart
                this.peopleCountMonitorData[row.peopleCountMonitorId][key].direction_1[dayIndex].series[1].value =
                  row.direction1Count;
                this.peopleCountMonitorData[row.peopleCountMonitorId][key].direction_2[dayIndex].series[1].value =
                  row.direction2Count;
                this.peopleCountMonitorData[row.peopleCountMonitorId][key].total[dayIndex].series[1].value =
                  row.direction1Count + row.direction2Count;
              }
            });
          });

          peopleCountMonitorIds.forEach((peopleCountMonitorId: string) => {
            this.peopleCountMonitorData[peopleCountMonitorId][key].isLoading$.next(false);
          });
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  async getOccupancyMonitorRealTimeData(occupancyMonitor: OccupancyMonitor): Promise<any> {
    await new Promise<void>((resolve) => {
      const interval = setInterval(() => {
        if (!this._occupancyMonitorRealTimeDataMutex) {
          clearInterval(interval);
          resolve();
        }
      }, 10);
    });
    this._occupancyMonitorRealTimeDataMutex = true;

    if (!this.occupancyMonitorRealTimeData[occupancyMonitor.id]) {
      this.occupancyMonitorRealTimeData[occupancyMonitor.id] = {
        isLoading$: new BehaviorSubject<boolean>(true),
        data: [],
        lastUpdate: undefined,
        currentOccupancy: undefined,
      };
    } else {
      this._occupancyMonitorRealTimeDataMutex = false;
      return this.occupancyMonitorRealTimeData[occupancyMonitor.id];
    }

    const siteTz = this.sitesMap[occupancyMonitor.siteId].tz;
    if (siteTz in this._occupancyMonitorRealTimeDataTzRequests) {
      this._occupancyMonitorRealTimeDataTzRequests[siteTz].push(occupancyMonitor.id);
    } else {
      this._occupancyMonitorRealTimeDataTzRequests[siteTz] = [occupancyMonitor.id];
    }

    if (this._occupancyMonitorRealTimeDataTzRequests[siteTz].length === 1) {
      timer(1000)
        .pipe(takeWhile(() => this._occupancyMonitorRealTimeDataTzRequests[siteTz].length < 3))
        .subscribe(async () => {
          await new Promise<void>((resolve) => {
            const interval = setInterval(() => {
              if (!this._occupancyMonitorRealTimeDataMutex) {
                clearInterval(interval);
                resolve();
              }
            }, 10);

            this._occupancyMonitorRealTimeDataMutex = true;
            if (this._occupancyMonitorRealTimeDataTzRequests[siteTz].length > 0) {
              this.getOccupancyMonitorRealTimeDataCall(siteTz);
            }
            this._occupancyMonitorRealTimeDataMutex = false;
          });
        });
    } else if (this._occupancyMonitorRealTimeDataTzRequests[siteTz].length >= 3) {
      this.getOccupancyMonitorRealTimeDataCall(siteTz);
    }

    this._occupancyMonitorRealTimeDataMutex = false;

    return this.occupancyMonitorRealTimeData[occupancyMonitor.id];
  }

  private getOccupancyMonitorRealTimeDataCall(siteTz: string): void {
    const occupancyMonitorIds = [...this._occupancyMonitorRealTimeDataTzRequests[siteTz]];
    this._occupancyMonitorRealTimeDataTzRequests[siteTz] = [];

    this.reportService
      .createOccupancyReport({
        occupancyMonitorIds: occupancyMonitorIds,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(1, 'day').format('YYYY-MM-DD'),
        metrics: ['occupancy_max'],
        timeGrain: '1m',
      })
      .pipe(
        finalize(() => {
          occupancyMonitorIds.forEach((occupancyMonitorId: string) => {
            var hasLastValue = false;
            for (let i = this.occupancyMonitorRealTimeData[occupancyMonitorId].data.length - 1; i >= 0; i--) {
              if (this.occupancyMonitorRealTimeData[occupancyMonitorId].data[i].occupancy === undefined) {
                if (!hasLastValue) {
                  this.occupancyMonitorRealTimeData[occupancyMonitorId].data.pop();
                } else {
                  this.occupancyMonitorRealTimeData[occupancyMonitorId].data[i].occupancy = 0;
                }
              } else {
                if (!hasLastValue) {
                  hasLastValue = true;
                  this.occupancyMonitorRealTimeData[occupancyMonitorId].currentOccupancy =
                    this.occupancyMonitorRealTimeData[occupancyMonitorId].data[i].occupancy;
                  this.occupancyMonitorRealTimeData[occupancyMonitorId].lastUpdate =
                    this.occupancyMonitorRealTimeData[occupancyMonitorId].data[i].time;
                }
              }
            }
            this.occupancyMonitorRealTimeData[occupancyMonitorId].isLoading$.next(false);
          });
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (report) => {
          report.forEach((row) => {
            this.occupancyMonitorRealTimeData[row.occupancyMonitorId].data.push({
              time: moment(row['time'].split('.')[0]),
              occupancy: row['occupancyMax'],
            });
          });
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  public async updateRealTimeDataWithLatest(): Promise<void> {
    await new Promise<void>((resolve) => {
      const interval = setInterval(() => {
        if (!this._occupancyMonitorRealTimeUpdateDataMutex && !this._occupancyMonitorRealTimeDataMutex) {
          clearInterval(interval);
          resolve();
        }
      }, 10);
    });
    this._occupancyMonitorRealTimeDataMutex = true;
    this._occupancyMonitorRealTimeUpdateDataMutex = true;

    Object.keys(this.occupancyMonitorRealTimeUpdateData).forEach((occupancyMonitorId: string) => {
      this.occupancyMonitorRealTimeData[occupancyMonitorId].currentOccupancy =
        this.occupancyMonitorRealTimeUpdateData[occupancyMonitorId].currentOccupancy;
      this.occupancyMonitorRealTimeData[occupancyMonitorId].data =
        this.occupancyMonitorRealTimeUpdateData[occupancyMonitorId].data;
      this.occupancyMonitorRealTimeData[occupancyMonitorId].lastUpdate =
        this.occupancyMonitorRealTimeUpdateData[occupancyMonitorId].lastUpdate;
      delete this.occupancyMonitorRealTimeUpdateData[occupancyMonitorId];
      this.occupancyMonitorRealTimeData[occupancyMonitorId].isLoading$.next(true);
      this.occupancyMonitorRealTimeData[occupancyMonitorId].isLoading$.next(false);
    });
    this.hasRealTimeDataUpdateAvailable = false;
    this._occupancyMonitorRealTimeDataMutex = false;
    this._occupancyMonitorRealTimeUpdateDataMutex = false;
  }

  async updateOccupancyMonitorRealTimeData(occupancyMonitor: OccupancyMonitor) {
    await new Promise<void>((resolve) => {
      const interval = setInterval(() => {
        if (!this._occupancyMonitorRealTimeUpdateDataMutex) {
          clearInterval(interval);
          resolve();
        }
      }, 10);
    });

    this._occupancyMonitorRealTimeUpdateDataMutex = true;

    const siteTz = this.sitesMap[occupancyMonitor.siteId].tz;
    if (siteTz in this._occupancyMonitorRealTimeUpdateDataTzRequests) {
      this._occupancyMonitorRealTimeUpdateDataTzRequests[siteTz].push(occupancyMonitor.id);
    } else {
      this._occupancyMonitorRealTimeUpdateDataTzRequests[siteTz] = [occupancyMonitor.id];
    }

    if (this._occupancyMonitorRealTimeUpdateDataTzRequests[siteTz].length === 1) {
      timer(2000)
        .pipe(takeWhile(() => this._occupancyMonitorRealTimeUpdateDataTzRequests[siteTz].length < 5))
        .subscribe(async () => {
          await new Promise<void>((resolve) => {
            const interval = setInterval(() => {
              if (!this._occupancyMonitorRealTimeUpdateDataMutex) {
                clearInterval(interval);
                resolve();
              }
            }, 10);

            this._occupancyMonitorRealTimeUpdateDataMutex = true;
            if (this._occupancyMonitorRealTimeUpdateDataTzRequests[siteTz].length > 0) {
              this.updateOccupancyMonitorRealTimeDataCall(siteTz);
            }
            this._occupancyMonitorRealTimeUpdateDataMutex = false;
          });
        });
    } else if (this._occupancyMonitorRealTimeUpdateDataTzRequests[siteTz].length >= 5) {
      this.updateOccupancyMonitorRealTimeDataCall(siteTz);
    }

    this._occupancyMonitorRealTimeUpdateDataMutex = false;
  }

  private updateOccupancyMonitorRealTimeDataCall(siteTz: string): void {
    const occupancyMonitorIds = [...this._occupancyMonitorRealTimeUpdateDataTzRequests[siteTz]];
    this._occupancyMonitorRealTimeUpdateDataTzRequests[siteTz] = [];

    this.reportService
      .createOccupancyReport({
        occupancyMonitorIds: occupancyMonitorIds,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(1, 'day').format('YYYY-MM-DD'),
        metrics: ['occupancy_max'],
        timeGrain: '1m',
      })
      .pipe(
        finalize(() => {
          if (this.autoUpdateRealTimeData) {
            this.updateRealTimeDataWithLatest();
          } else {
            this.hasRealTimeDataUpdateAvailable = true;
          }
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: async (report) => {
          await new Promise<void>((resolve) => {
            const interval = setInterval(() => {
              if (!this._occupancyMonitorRealTimeUpdateDataMutex) {
                clearInterval(interval);
                resolve();
              }
            }, 10);
          });

          this._occupancyMonitorRealTimeUpdateDataMutex = true;
          occupancyMonitorIds.forEach((occupancyMonitorId: string) => {
            this.occupancyMonitorRealTimeUpdateData[occupancyMonitorId] = {
              data: [],
              lastUpdate: undefined,
              currentOccupancy: undefined,
            };
          });
          report.forEach((row) => {
            this.occupancyMonitorRealTimeUpdateData[row.occupancyMonitorId].data.push({
              time: moment(row['time'].split('.')[0]),
              occupancy: row['occupancyMax'],
            });
          });

          occupancyMonitorIds.forEach((occupancyMonitorId: string) => {
            var hasLastValue = false;
            for (let i = this.occupancyMonitorRealTimeUpdateData[occupancyMonitorId].data.length - 1; i >= 0; i--) {
              if (this.occupancyMonitorRealTimeUpdateData[occupancyMonitorId].data[i].occupancy === undefined) {
                if (!hasLastValue) {
                  this.occupancyMonitorRealTimeUpdateData[occupancyMonitorId].data.pop();
                } else {
                  this.occupancyMonitorRealTimeUpdateData[occupancyMonitorId].data[i].occupancy = 0;
                }
              } else {
                if (!hasLastValue) {
                  hasLastValue = true;
                  this.occupancyMonitorRealTimeUpdateData[occupancyMonitorId].currentOccupancy =
                    this.occupancyMonitorRealTimeUpdateData[occupancyMonitorId].data[i].occupancy;
                  this.occupancyMonitorRealTimeUpdateData[occupancyMonitorId].lastUpdate =
                    this.occupancyMonitorRealTimeUpdateData[occupancyMonitorId].data[i].time;
                }
              }
            }
          });

          this._occupancyMonitorRealTimeUpdateDataMutex = false;
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  async getCapacityManagementData(occupancyMonitor: OccupancyMonitor): Promise<any> {
    await new Promise<void>((resolve) => {
      const interval = setInterval(() => {
        if (!this._capacityManagementDataMutex) {
          clearInterval(interval);
          resolve();
        }
      }, 10);
    });
    this._capacityManagementDataMutex = true;

    if (!this._capacityManagementData[occupancyMonitor.id]) {
      this._capacityManagementData[occupancyMonitor.id] = { true: undefined, false: undefined };
    }

    const isOpen = String(this.useOpeningHoursCapacityManagementMonitors);
    if (!this._capacityManagementData[occupancyMonitor.id][isOpen]) {
      this._capacityManagementData[occupancyMonitor.id][isOpen] = {
        isLoading$: new BehaviorSubject<boolean>(true),
        data: [],
        lastUpdate: new Date(),
      };
    } else {
      this._capacityManagementData[occupancyMonitor.id][isOpen].isLoading$.next(true);
      this._capacityManagementData[occupancyMonitor.id][isOpen].data = [];
      this._capacityManagementData[occupancyMonitor.id][isOpen].lastUpdate = new Date();
    }

    const siteTz = this.sitesMap[occupancyMonitor.siteId].tz;
    if (siteTz in this._capacityManagementDataTzRequests) {
      this._capacityManagementDataTzRequests[siteTz].push(occupancyMonitor.id);
    } else {
      this._capacityManagementDataTzRequests[siteTz] = [occupancyMonitor.id];
    }

    if (this._capacityManagementDataTzRequests[siteTz].length === 1) {
      timer(1000)
        .pipe(takeWhile(() => this._capacityManagementDataTzRequests[siteTz].length < 5))
        .subscribe(async () => {
          await new Promise<void>((resolve) => {
            const interval = setInterval(() => {
              if (!this._capacityManagementDataMutex) {
                clearInterval(interval);
                resolve();
              }
            }, 10);

            this._capacityManagementDataMutex = true;
            if (this._capacityManagementDataTzRequests[siteTz].length > 0) {
              this.getCapacityManagementDataCall(siteTz, this.useOpeningHoursCapacityManagementMonitors);
            }
            this._capacityManagementDataMutex = false;
          });
        });
    } else if (this._capacityManagementDataTzRequests[siteTz].length >= 5) {
      this.getCapacityManagementDataCall(siteTz, this.useOpeningHoursCapacityManagementMonitors);
    }

    this._capacityManagementDataMutex = false;

    return this._capacityManagementData[occupancyMonitor.id];
  }

  private getCapacityManagementDataCall(siteTz: string, isOpen: boolean): void {
    const occupancyMonitorIds = [...this._capacityManagementDataTzRequests[siteTz]];
    this._capacityManagementDataTzRequests[siteTz] = [];

    this.reportService
      .createOccupancyReport({
        occupancyMonitorIds: occupancyMonitorIds,
        startDate: moment(new Date().setHours(0, 0, 0, 0) - 1123200000).format('YYYY-MM-DD'),
        endDate: moment().add(1, 'day').format('YYYY-MM-DD'),
        metrics: ['occupancy_max'],
        timeGrain: '24h',
        isOpen,
      })
      .pipe(
        finalize(() => {
          occupancyMonitorIds.forEach((occupancyMonitorId: string) => {
            this._capacityManagementData[occupancyMonitorId][String(isOpen)].isLoading$.next(false);
          });
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (report) => {
          report.forEach((row) => {
            this._capacityManagementData[row.occupancyMonitorId][String(isOpen)].data.push({
              time: new Date(
                GlobalMethods.changeTimezone(
                  row['time'],
                  this.sitesMap[this.occupancyMonitorsMap[row.occupancyMonitorId].siteId].tz,
                ),
              ).getTime(),
              occupancyMax: row['occupancyMax'],
            });
          });
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  ngOnDestroy() {
    this._peopleCountOrderDataNgUnsubscribe.next('');
    this._peopleCountOrderDataNgUnsubscribe.complete();
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
