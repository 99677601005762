import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { forkJoin, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import {
  Camera,
  CameraService,
  NotificationGroup,
  NotificationGroupMappingService,
  NotificationGroupService,
  Organisation,
  Site,
  SiteService,
  UserService,
} from 'src/app/api';
import { NotificationsSideMenuComponent } from 'src/app/views/notifications/components/notifications-side-menu/notifications-side-menu.component';
import { AccountService } from 'src/app/services/account.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { GlobalMethods } from 'src/app/global-methods';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  isLoading = true;

  organisation: Organisation = null;
  selectedMenu = '';
  notificationGroup: NotificationGroup;
  showSideMenu = false;

  isSupport: boolean;
  hasOccupancyLicence: boolean = true;

  private ngUnsubscribe = new Subject();

  constructor(
    private notificationsService: NotificationsService,
    private accountService: AccountService,
    private cameraService: CameraService,
    private breakpointObserver: BreakpointObserver,
    private matDialog: MatDialog,
    private ref: ChangeDetectorRef,
    private router: Router,
    private notificationGroupService: NotificationGroupService,
    private notificationGroupMappingService: NotificationGroupMappingService,
    private siteService: SiteService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.isSupport = this.accountService.isSupport;
    this.organisation = this.accountService.organisation;
    this.breakpointObserver
      .observe(['(min-width: 1365px)'])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (state: BreakpointState) => {
          this.showSideMenu = state.matches;
          this.ref.detectChanges();
        },
      });

    this.getData();
  }

  getData(): void {
    this.isLoading = true;
    forkJoin([
      this.notificationGroupService.listNotificationGroups(),
      this.notificationGroupMappingService.listNotificationGroupNotificationGroups(),
      this.siteService.listSites(undefined, undefined, 'active'),
      this.cameraService.listCameras(false, undefined, undefined, ['running', 'paused']),
      this.userService.listUsers(),
    ])
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.ref.detectChanges();
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: ([notificationGroups, notificationGroupNotificationGroups, sites, cameras, users]) => {
          this.notificationsService.notificationGroups = notificationGroups
            .filter((notificationGroup) => notificationGroup.state === 'active')
            .sort((e1: NotificationGroup, e2: NotificationGroup) => {
              if (e1.name.toLowerCase() > e2.name.toLowerCase()) {
                return 1;
              } else {
                return -1;
              }
            });
          notificationGroups.forEach((notificationGroup) => {
            this.notificationsService.notificationGroupNotificationGroupMap[notificationGroup.id] = [];
          });
          this.notificationsService.notificationGroupNotificationGroups = notificationGroupNotificationGroups.filter(
            (notificationGroupNotificationGroup) => notificationGroupNotificationGroup.state !== 'deleted',
          );

          this.notificationsService.notificationGroupNotificationGroups.forEach(
            (notificationGroupNotificationGroup) => {
              this.notificationsService.notificationGroupNotificationGroupMap[
                notificationGroupNotificationGroup.parentId
              ]?.push(notificationGroupNotificationGroup);
            },
          );

          this.notificationsService.notificationGroupsMap = {};
          this.notificationsService.notificationGroups.forEach((notificationGroup) => {
            this.notificationsService.notificationGroupsMap[notificationGroup.id] = notificationGroup;
          });
          this.notificationsService.notificationGroups.forEach((notificationGroup) => {
            this.notificationsService.notificationGroupMembers[notificationGroup.id] =
              notificationGroup.emails.length + this.getNotificationGroupMembers(notificationGroup);
          });

          this.notificationsService.sites = sites.sort((e1: Site, e2: Site) => {
            if (e1.name.toLowerCase() > e2.name.toLowerCase()) {
              return 1;
            } else {
              return -1;
            }
          });
          this.notificationsService.sitesMap = {};
          this.notificationsService.sites.forEach((site) => {
            this.notificationsService.sitesMap[site.id] = site;
          });

          this.notificationsService.cameras = cameras.sort((c1: Camera, c2: Camera) => {
            if (
              this.notificationsService.sitesMap[c1.siteId].name.toLowerCase() ===
              this.notificationsService.sitesMap[c2.siteId].name.toLowerCase()
            ) {
              if (c1.name.toLowerCase() > c2.name.toLowerCase()) {
                return 1;
              } else {
                return -1;
              }
            } else {
              if (
                this.notificationsService.sitesMap[c1.siteId].name.toLowerCase() >
                this.notificationsService.sitesMap[c2.siteId].name.toLowerCase()
              ) {
                return 1;
              } else {
                return -1;
              }
            }
          });
          this.notificationsService.camerasMap = {};
          this.notificationsService.cameras.forEach((camera) => {
            this.notificationsService.camerasMap[camera.id] = camera;
          });

          this.notificationsService.users = users;
        },
        error: (_) => {
          this.router.navigate(['internal-error']);
        },
      });
  }

  openSideMenu(): void {
    this.matDialog.open(NotificationsSideMenuComponent, {
      height: '100vh',
      width: '90vw',
      maxWidth: '90vw',
      position: { left: '0' },
      panelClass: 'mat-dialog-side-menu',
    });
  }

  getNotificationGroupMembers(notificationGroup: NotificationGroup): number {
    let nestedNGIds = []; // All NestedGroupIds that are in the passed NG
    let nextFloorNGIds = [];
    let currentFloorNGIds = [notificationGroup.id];

    do {
      nextFloorNGIds = GlobalMethods.flatten(
        currentFloorNGIds.map((currentFloorNGId) =>
          this.notificationsService.notificationGroupNotificationGroupMap[currentFloorNGId].map(
            (notificationGroupNotificationGroup) => notificationGroupNotificationGroup.childId,
          ),
        ),
      ).filter((id) => !nestedNGIds.includes(id));

      if (currentFloorNGIds.length === 0) {
        return 0;
      }

      nestedNGIds = GlobalMethods.unique([...nestedNGIds, ...nextFloorNGIds]);
      currentFloorNGIds = nextFloorNGIds;
    } while (nextFloorNGIds.length > 0);

    if (nestedNGIds.length === 0) {
      return 0;
    }

    const membersLength = nestedNGIds
      .map((nestedNGId) => this.notificationsService.notificationGroupsMap[nestedNGId])
      .map((group) => (group ? group.emails.length : 0))
      .reduce((acc, curr) => acc + curr);

    return membersLength;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
