<div
  class="o-monitor-card"
  [ngClass]="{
    'is-online': site.status === 'online',
    'is-offline': site.status === 'issue',
  }"
>
  <div class="o-monitor-card__title">
    <mat-icon class="o-monitor-card__title-icon">apartment</mat-icon>
    <div class="o-monitor-card__title-text c-title-text">
      <app-copy-link
        [text]="site.name"
        [partRoute]="'/' + site.id"
        [tooltipMessage]="'Copy link to site'"
      ></app-copy-link>
      <h3 class="c-secondary-title-text">
        <div>{{ site.address }}</div>
        <div *ngIf="accountService.isSupport">{{ accountService.organisationsMap[site.organisationId].name }}</div>
      </h3>
    </div>
    <a class="o-monitor-card__title-actions">
      <mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="editSite.emit()">
          <mat-icon>edit</mat-icon>
          Edit site details
        </button>
        <button mat-menu-item (click)="deleteSite.emit()" *ngIf="accountService.isSupport">
          <mat-icon>delete</mat-icon>
          Delete site
        </button>
      </mat-menu>
    </a>
  </div>
  <div class="o-monitor-card__body">
    <div class="o-monitor-card__banner-small">
      <!-- <span class="is-primary-text">All cameras offline.</span> -->
      <span class="minion--caps"> {{ cameras.length }} {{ cameras.length | pluraliseWord: 'camera' }} installed </span>
    </div>
    <ul class="o-list">
      <li class="o-list__item is-link" [ngClass]="{ 'has-error': urgentIssues }" (click)="viewSiteCameras()">
        <div class="o-list__item-icon">
          <mat-icon [ngClass]="{ 'is-online': site.status === 'online', 'is-offline': site.status === 'issue' }"
            >photo_camera</mat-icon
          >
        </div>
        <div class="o-list__item-text">
          <ng-container *ngIf="site.status === 'issue'; else noIssue">
            <ng-container *ngIf="urgentIssues"
              >{{ urgentIssues }} urgent camera {{ urgentIssues | pluraliseWord: 'issue' }}</ng-container
            >
            <ng-container *ngIf="!urgentIssues">
              {{ issues }} camera {{ issues | pluraliseWord: 'issue' }}</ng-container
            >
          </ng-container>
          <ng-template #noIssue> No camera issues </ng-template>
        </div>

        <div class="o-list__item-secondary-icon">
          <mat-icon> chevron_right </mat-icon>
        </div>
      </li>
      <li class="o-list__item is-link" (click)="viewSiteMonitors('occupancy')" *ngIf="occupancyMonitors.length">
        <div class="o-list__item-icon">
          <mat-icon>space_dashboard</mat-icon>
        </div>
        <div class="o-list__item-text">
          {{ occupancyMonitors.length }} occupancy {{ occupancyMonitors.length | pluraliseWord: 'monitor' }}
        </div>
        <div class="o-list__item-secondary-icon">
          <mat-icon> chevron_right </mat-icon>
        </div>
      </li>
      <li class="o-list__item is-link" (click)="viewSiteMonitors('people_count')" *ngIf="peopleCountMonitors.length">
        <div class="o-list__item-icon">
          <mat-icon>transfer_within_a_station</mat-icon>
        </div>
        <div class="o-list__item-text">
          {{ peopleCountMonitors.length }} people count {{ peopleCountMonitors.length | pluraliseWord: 'monitor' }}
        </div>
        <div class="o-list__item-secondary-icon">
          <mat-icon> chevron_right </mat-icon>
        </div>
      </li>
      <li class="o-list__item is-link" (click)="viewSiteMonitors('peel_off')" *ngIf="peelOffMonitors.length">
        <div class="o-list__item-icon">
          <mat-icon>fork_left</mat-icon>
        </div>
        <div class="o-list__item-text">
          {{ peelOffMonitors.length }} peel off {{ peelOffMonitors.length | pluraliseWord: 'monitor' }}
        </div>
        <div class="o-list__item-secondary-icon">
          <mat-icon> chevron_right </mat-icon>
        </div>
      </li>

      <li
        class="o-list__item is-link"
        (click)="viewSiteMonitors()"
        *ngIf="!occupancyMonitors.length && !peopleCountMonitors.length && !peelOffMonitors.length"
      >
        <div class="o-list__item-icon">
          <mat-icon>space_dashboard</mat-icon>
        </div>
        <div class="o-list__item-text">No monitors set up</div>
        <div class="o-list__item-secondary-icon">
          <mat-icon> chevron_right </mat-icon>
        </div>
      </li>
    </ul>
  </div>
  <div class="o-monitor-card__action">
    <a [routerLink]="['/sites', site.id]" class="o-button">
      View site
      <mat-icon class="is-icon-right">arrow_forward</mat-icon>
    </a>
  </div>
</div>
