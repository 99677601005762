<section class="o-expansion-section">
  <div class="o-expansion-section__header" (click)="collapseMonitorsSection = !collapseMonitorsSection">
    <mat-icon class="is-icon">view_quilt</mat-icon>
    <div class="is-title">Monitors</div>
    <mat-icon class="is-expansion-icon">
      {{ collapseMonitorsSection ? 'expand_more' : 'expand_less' }}
    </mat-icon>
  </div>

  <div class="o-expansion-section__body c-add-monitor" [ngClass]="{ 'is-hidden': collapseMonitorsSection }">
    <div class="c-add-monitor__content">
      <div class="o-banner-alert is-error">
        <span class="is-message">You must setup at least one site and one camera to create a monitor. </span>
      </div>
      <div class="c-content-sections">
        <div class="c-content-sections__item is-intro">
          <div class="primer kirk u-flush--bottom">Monitors.</div>
          <p class="brevier">
            Groups of one or more cameras, collecting data for real-world objects-of-interest such as an entrance, a
            room or a building.
          </p>

          <p class="brevier">
            There is no limit to how many cameras you can use in a monitor and a camera can be used in multiple
            monitors.
          </p>

          <a class="o-button is-muted" href="https://docs.hoxton.ai/user-guide/monitors">
            <span class="material-icons is-icon-left"> live_help </span>
            learn more
          </a>
        </div>

        <div class="is-image">
          <img
            class="c-content-sections__image"
            src="/assets/images/new-homepage/monitor-setup.png"
            alt="Monitor setup"
            loading="lazy"
          />
        </div>

        <div class="c-content-sections__item is-selection">
          <div class="primer kirk">Create a people count monitor.</div>
          <div class="brevier">
            Count people coming through or passing your site, or a particular space on your site.
          </div>
          <button
            mat-button
            class="o-button is-primary"
            (click)="addPeopleCountMonitor.emit()"
            [disabled]="!canAddMonitor"
          >
            <span class="material-icons is-icon-left"> transfer_within_a_station </span>
            ADD PEOPLE COUNT MONITOR
          </button>

          <ng-container *ngIf="accountService.occupancyLicence">
            <div class="primer kirk">Create an occupancy monitor.</div>
            <div class="brevier">
              All people count data and real-time occupancy counts. See how many people are in your site, or in a
              particular space in your site.
            </div>
            <button
              mat-button
              class="o-button is-primary"
              (click)="addOccupancyMonitor.emit()"
              [disabled]="!canAddMonitor"
            >
              <span class="material-icons is-icon-left"> groups </span>
              ADD OCCUPANCY MONITOR
            </button>
          </ng-container>

          <ng-container>
            <div class="primer kirk">Create a peel off monitor.</div>
            <div class="brevier">Text to be added</div>
            <button
              mat-button
              class="o-button is-primary"
              (click)="addPeelOffMonitor.emit()"
              [disabled]="!canAddMonitor"
            >
              <span class="material-icons is-icon-left"> fork_left </span>
              ADD PEEL OFF COUNT MONITOR
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</section>
