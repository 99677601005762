<div class="c-page-container u-padding--bottom">
  <div class="c-sort-bar">
    <span class="c-sort-bar__description">Order by</span>
    <div
      class="c-sort-bar__option"
      [ngClass]="{ 'is-selected': keyToSort === 'status' }"
      (click)="onSortClick('status')"
    >
      <span class="is-label">Status</span>
    </div>
    <div
      class="c-sort-bar__option"
      [ngClass]="{ 'is-selected': keyToSort === 'siteName' }"
      (click)="onSortClick('siteName')"
    >
      <span class="is-label">Site</span>
    </div>
    <div
      class="c-sort-bar__option"
      [ngClass]="{ 'is-selected': keyToSort === 'lastSeenAt' }"
      (click)="onSortClick('lastSeenAt')"
    >
      <span class="is-label">Last seen</span>
    </div>
    <div
      class="c-sort-bar__option"
      [ngClass]="{ 'is-selected': keyToSort === 'serialNumber' }"
      (click)="onSortClick('serialNumber')"
    >
      <span class="is-label">Serial number</span>
    </div>
    <div
      class="c-sort-bar__option"
      [ngClass]="{ 'is-selected': keyToSort === 'videoBacklog' }"
      (click)="onSortClick('videoBacklog')"
    >
      <span class="is-label">Video backlog</span>
    </div>

    <ng-container *ngIf="isSupport">
      <div
        class="c-sort-bar__option"
        [ngClass]="{ 'is-selected': keyToSort === 'organisationId' }"
        (click)="onSortClick('organisationId')"
      >
        <span class="is-label">Organisation</span>
      </div>
    </ng-container>
  </div>

  <div #camerasList class="c-camera-list" (scroll)="onPageScroll()">
    <app-camera-card
      *ngFor="let camera of activeCameras"
      [camera]="camera"
      [keyToSort]="keyToSort"
      (cardClick)="navigateToCamera(camera)"
    ></app-camera-card>
  </div>
</div>
