<div class="c-cameras-information">
  <div class="long-primer kirk">
    {{ cameras.length }} {{ cameras.length | pluraliseWord: 'Camera' }}
    {{ issueType === 'alert' ? 'offline' : 'having issues' }} and not
    {{ issueType === 'alert' ? 'with no alerts set:' : 'and not included in a health summary:' }}
  </div>
  <ul class="o-list c-cameras-information__list u-margin--bottom">
    <div class="o-list__item" *ngFor="let camera of cameras">
      <div class="o-list__item-icon">
        <mat-icon class="u-red-color">no_photography</mat-icon>
      </div>
      <div class="o-list__item-text">
        <div class="is-secondary-text">{{ camera.siteName | uppercase }}</div>
        <div class="is-primary-text">{{ camera.name }}</div>
      </div>
      <div class="o-list__item-secondary-icon-text minion u-flush--bottom u-grey-color c-capitalize">
        {{ camera.status }}
      </div>
    </div>
    <div class="o-list__item" *ngIf="onlineCamerasWithoutAlertsQuantity">
      <div class="o-list__item-icon">
        <mat-icon class="u-grey-color">camera_alt</mat-icon>
      </div>
      <div class="o-list__item-text brevier u-flush--bottom">
        + {{ onlineCamerasWithoutAlertsQuantity }} other
        {{ onlineCamerasWithoutAlertsQuantity | pluraliseWord: 'camera' }} , currently online.
      </div>
    </div>
  </ul>

  <div class="u-margin--bottom">
    <button class="o-button is-primary" (click)="addCameraAlert()">
      <ng-container *ngIf="issueType === 'alert'; else summaryButton">
        <mat-icon class="is-icon-left">add_alert</mat-icon>
        ADD CAMERA ALERTS
      </ng-container>
      <ng-template #summaryButton>
        <mat-icon class="is-icon-left">emergency</mat-icon>
        Manage health summaries
      </ng-template>
    </button>
  </div>

  <div class="c-alerts-user-guide u-island">
    <ng-container *ngIf="issueType === 'alert'; else summaryUserGuide">
      <p class="brevier u-flush--bottom">
        Our user guide has addition support for setting up and managing camera alerts.
      </p>
      <a href="https://docs.hoxton.ai/user-guide/alerts" target="_blank" class="o-button is-small"
        >CAMERA ALERTS USER GUIDE <mat-icon class="is-icon-right">arrow_forward</mat-icon></a
      >
    </ng-container>
    <ng-template #summaryUserGuide>
      <p class="brevier u-flush--bottom">
        Our user guide has addition support for setting up and managing camera health summaries.
      </p>
      <a href="https://docs.hoxton.ai/user-guide/health-summaries" target="_blank" class="o-button is-small"
        >HEALTH SUMMARIES USER GUIDE <mat-icon class="is-icon-right">arrow_forward</mat-icon></a
      >
    </ng-template>
  </div>
</div>
