import { Component, Input, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Site } from 'src/app/api';
import { AddEditSiteComponent } from 'src/app/components/sites/add-edit-site/add-edit-site.component';

@Component({
  selector: 'app-opening-hours-tab',
  templateUrl: './opening-hours-tab.component.html',
  styleUrl: './opening-hours-tab.component.scss',
})
export class OpeningHoursTabComponent implements OnChanges {
  @Input() sites: Site[] = [];
  filteredSites: Site[] = [];
  mustContainString = '';

  daysOfWeek: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  constructor(private matDialog: MatDialog) {}

  ngOnChanges(): void {
    if (this.sites) {
      this.filterSites();
    }
  }

  runSearch(ss: string) {
    this.mustContainString = ss.toLowerCase();
    this.filterSites();
  }

  filterSites(): void {
    this.filteredSites = this.sites;

    if (this.mustContainString) {
      this.filteredSites = this.filteredSites.filter(
        (site) =>
          site.name.toLowerCase().includes(this.mustContainString) ||
          site.id.toLowerCase().includes(this.mustContainString),
      );
    }

    this.filteredSites = this.filteredSites.sort((s1: Site, s2: Site) => {
      if (s1.name.toLowerCase() > s2.name.toLowerCase()) {
        return 1;
      } else {
        return -1;
      }
    });
  }

  editSite(site): void {
    ``;
    const dialogRef = this.matDialog.open(AddEditSiteComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data: { site },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          window.location.reload();
        }
      },
    });
  }
}
