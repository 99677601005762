<div class="c-onboard-stepper">
  <mat-horizontal-stepper linear class="dialog-stepper" #stepper>
    <mat-step>
      <div class="o-modal-full-page is-not-full-height">
        <div class="o-modal-full-page__header">
          <h2 class="is-title c-onboard-stepper__title">
            <span class="is-new-label">New feature</span>
            <span class="is-feature-name">Camera health summaries</span>
          </h2>
        </div>
        <div class="c-onboard-stepper__body">
          <div class="is-media">
            <img src="assets/images/health-summary-1.png" alt="Camera alerts" />
          </div>
          <div class="is-description">
            <h3 class="long-primer kirk">
              A daily or weekly health summary will keep you up-to-date with any downtime issues across your camera
              fleet.
            </h3>
            <p>Summaries are a quick overview of any cameras that have been offline and missing count data.</p>
            <p>
              They identify and troubleshoot recurring issues - helping avoid ongoing inaccuracies in your people count
              or occupancy reporting.
            </p>
          </div>
        </div>
        <div class="o-modal-full-page__footer c-onboard-stepper__button">
          <div class="o-button" (click)="stepper.next()">next</div>
        </div>
      </div> </mat-step
    ><mat-step>
      <div class="o-modal-full-page is-not-full-height">
        <div class="o-modal-full-page__header">
          <h2 class="is-title c-onboard-stepper__title">
            <span class="is-new-label">New feature</span>
            <span class="is-feature-name">Offline camera alerts</span>
          </h2>
        </div>
        <div class="c-onboard-stepper__body">
          <div class="is-media">
            <img src="assets/images/health-summary-2.png" alt="Camera alerts" />
          </div>
          <div class="is-description">
            <p>Configure summaries for cameras by site, across multiple sites or any other grouping you require.</p>
            <p>Summaries can be sent daily or weekly on the day or time of day best suited to you.</p>
            <p>
              In combination with real-time camera alerts, they’re a powerful tool to ensure you’re getting the most out
              of your camera installations.
            </p>

            <p>
              Find out more on our <a href="https://docs.hoxton.ai/user-guide/alerts" target="_blank">support page</a>.
            </p>
          </div>
        </div>
        <div class="o-modal-full-page__footer">
          <div class="o-button c-onboard-stepper__button" (click)="close()">close</div>
          <div class="o-button c-onboard-stepper__button is-right" (click)="createSummary()" *ngIf="hasLink">
            create summary
          </div>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
