import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { OAuthClient } from 'src/app/api';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-oauth-client-tab',
  templateUrl: './oauth-client-tab.component.html',
  styleUrl: './oauth-client-tab.component.scss',
})
export class OAuthClientTabComponent implements OnInit {
  mustContainString = '';
  filteredOAuthClients: OAuthClient[] = [];
  constructor(
    public accountService: AccountService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.filterOAuthClients();
  }

  runSearch(ss: string) {
    this.mustContainString = ss.toLowerCase();
    this.filterOAuthClients();
  }

  filterOAuthClients(): void {
    this.filteredOAuthClients = this.accountService.oAuthClients;

    if (this.mustContainString) {
      this.filteredOAuthClients = this.filteredOAuthClients.filter(
        (oAuthClient) =>
          oAuthClient.name.toLowerCase().includes(this.mustContainString) ||
          oAuthClient.clientId.toLowerCase().includes(this.mustContainString),
      );
    }

    this.filteredOAuthClients = this.filteredOAuthClients.sort((o1: OAuthClient, o2: OAuthClient) => {
      if (o1.name.toLowerCase() > o2.name.toLowerCase()) {
        return 1;
      } else {
        return -1;
      }
    });
  }

  addEditOAuthClient(oAuthClient?: OAuthClient) {
    var redirectURL = 'settings/oauth-client';
    if (oAuthClient) {
      redirectURL += `/${oAuthClient.clientId}`;
    }
    this.router.navigate([redirectURL]);
  }
}
