import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Alert, OccupancyMonitor, PeelOffMonitor, PeopleCountMonitor } from 'src/app/api';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-decommission-confirmation',
  templateUrl: './decommission-confirmation.component.html',
  styleUrls: ['./decommission-confirmation.component.scss'],
})
export class DecommissionConfirmationComponent implements OnInit {
  cameraName = '  ';
  deleteLinkedResources = false;

  occupancyMonitors: OccupancyMonitor[] = [];
  peopleCountMonitors: PeopleCountMonitor[] = [];
  peelOffMonitors: PeelOffMonitor[] = [];
  alerts: Alert[] = [];

  constructor(
    public accountService: AccountService,
    public dialogRef: MatDialogRef<DecommissionConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.cameraName = this.data.cameraName;
    this.occupancyMonitors = this.data.occupancyMonitors;
    this.peopleCountMonitors = this.data.peopleCountMonitors;
    this.peelOffMonitors = this.data.peelOffMonitors;
    this.alerts = this.data.alerts;
  }

  toggleDeleteLinkedResources(): void {
    this.deleteLinkedResources = !this.deleteLinkedResources;
  }

  decommission(): void {
    this.dialogRef.close({ removeLinks: this.deleteLinkedResources });
  }

  close(): void {
    this.dialogRef.close();
  }
}
