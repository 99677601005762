import { CdkStep } from '@angular/cdk/stepper';
import { Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { finalize, forkJoin, Subject, takeUntil, switchMap, Observable } from 'rxjs';
import {
  Alert,
  AlertCameraPositionMapping,
  AlertCameraPositionMappingService,
  AlertNotificationGroupMapping,
  AlertNotificationGroupMappingService,
  AlertService,
  Camera,
  NotificationGroup,
  NotificationGroupMappingService,
  NotificationGroupService,
  Site,
  User,
} from 'src/app/api';
import { GlobalMethods } from 'src/app/global-methods';
import { NotificationsService } from 'src/app/services/notifications.service';
import { NotifyService } from 'src/app/services/notify.service';
import { CameraAlertAndSummaryCamerasComponent } from 'src/app/components/notifications/steps-for-stepper/camera-alert-and-summary-cameras/camera-alert-and-summary-cameras.component';
import { CameraAlertAndSummaryNameAndSitesComponent } from 'src/app/components/notifications/steps-for-stepper/camera-alert-and-summary-name-and-sites/camera-alert-and-summary-name-and-sites.component';
import { CameraSummarySettingsComponent } from 'src/app/components/notifications/steps-for-stepper/camera-summary-settings/camera-summary-settings.component';

@Component({
  selector: 'app-add-edit-camera-summary',
  templateUrl: './add-edit-camera-summary.component.html',
  styleUrls: ['./add-edit-camera-summary.component.scss'],
})
export class AddEditCameraSummaryComponent implements OnDestroy {
  stepsTitle = 'Camera health summary';
  sites: Site[];
  filteredCameras: Camera[] = [];
  cameras: Camera[];
  camerasMap: { [_: string]: Camera } = {};
  summaryAlert: Alert;
  summaryAlertCameraPositions: AlertCameraPositionMapping[] = [];
  saving = false;
  editing = false;
  notificationGroupName: string;
  notificationGroups: NotificationGroup[] = [];
  users: User[] = [];
  filteredNotificationGroups: NotificationGroup[] = [];
  filteredUsers: User[] = [];
  organisationId;
  summaryAlertNotificationGroups: NotificationGroup[] = [];
  summaryAlertNotificationGroupsMapping: AlertNotificationGroupMapping[];

  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('cameraSummarySettings') cameraSummarySettings: CdkStep;
  @ViewChild(CameraAlertAndSummaryNameAndSitesComponent)
  cameraAlertAndSummaryNameAndSitesComponent: CameraAlertAndSummaryNameAndSitesComponent;
  @ViewChild(CameraAlertAndSummaryCamerasComponent)
  cameraAlertAndSummaryCamerasComponent: CameraAlertAndSummaryCamerasComponent;
  @ViewChild(CameraSummarySettingsComponent)
  cameraSummarySettingsComponent: CameraSummarySettingsComponent;

  private ngUnsubscribe = new Subject();
  constructor(
    private notifyService: NotifyService,
    private notificationsService: NotificationsService,
    private notificationGroupService: NotificationGroupService,
    private alertNotificationGroupMappingService: AlertNotificationGroupMappingService,
    private notificationGroupMappingService: NotificationGroupMappingService,
    private alertService: AlertService,
    private alertCameraPositionMappingService: AlertCameraPositionMappingService,
    public dialogRef: MatDialogRef<AddEditCameraSummaryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.sites = this.data.sites;
    this.cameras = this.data.cameras;
    this.camerasMap = this.data.camerasMap;
    this.notificationGroups = this.data.notificationGroups;
    this.users = this.data.users;

    if (this.data.summaryAlert) {
      this.summaryAlert = this.data.summaryAlert;
      this.summaryAlertCameraPositions = this.data.summaryAlertCameraPositions;
      this.summaryAlertNotificationGroupsMapping = this.data.summaryAlertNotificationGroupsMapping;
      this.summaryAlertNotificationGroupsMapping.forEach(
        (alertNotificationGroupMapping: AlertNotificationGroupMapping) =>
          this.summaryAlertNotificationGroups.push(
            this.notificationsService.notificationGroupsMap[alertNotificationGroupMapping.notificationGroupId],
          ),
      );
      this.editing = true;
    }
  }

  goTosecondStep(): void {
    this.organisationId = this.cameraAlertAndSummaryNameAndSitesComponent.selectedOrganisation.id;
    this.filteredUsers = this.users.filter((user) => user.organisationId === this.organisationId);
    this.filteredNotificationGroups = this.notificationGroups.filter(
      (notificationGroup) => notificationGroup.organisationId === this.organisationId,
    );

    if (this.cameraAlertAndSummaryNameAndSitesComponent.alertMode === 'site') {
      this.filteredCameras = this.cameras.filter(
        (camera: Camera) => this.cameraAlertAndSummaryNameAndSitesComponent.selectedSite.id === camera.siteId,
      );

      this.stepper.next();
    } else {
      this.stepper.selectedIndex = 2;
    }
  }

  setCameraHealthSummaryConfiguration(): void {
    let day =
      this.cameraSummarySettingsComponent.summaryForm.get('duration').value.value === 'daily'
        ? '*'
        : this.cameraSummarySettingsComponent.summaryForm.get('day').value.value;
    let time = this.cameraSummarySettingsComponent.summaryForm.get('time').value;
    let parts = time.match(/(\d+):(\d+) (\w+)/);
    let hour = /am/i.test(parts[3])
      ? parseInt(parts[1], 10) === 12
        ? 0
        : parseInt(parts[1], 10)
      : parseInt(parts[1], 10) === 12
        ? 12
        : parseInt(parts[1], 10) + 12;
    let minute = parseInt(parts[2], 10);
    this.summaryAlert = {
      ...this.summaryAlert,
      alertType: 'camera_health_summary_alert',
      organisationId: this.cameraAlertAndSummaryNameAndSitesComponent.selectedOrganisation.id,
      name: this.cameraAlertAndSummaryNameAndSitesComponent.alertName,
      ...this.cameraSummarySettingsComponent.summaryForm.value,
      configurationDetails: {
        schedule: minute + ' ' + hour + ' ' + day,
      },
    };

    if (this.cameraAlertAndSummaryNameAndSitesComponent.alertMode === 'site') {
      this.summaryAlert.siteId = this.cameraAlertAndSummaryNameAndSitesComponent.selectedSite.id;
    }

    this.notificationGroupName = undefined;
    if (
      this.cameraSummarySettingsComponent.selectRecipientsComponent.selectedRecipients.length === 1 &&
      this.cameraSummarySettingsComponent.selectRecipientsComponent.selectedRecipients[0].hasOwnProperty('emails')
    ) {
      this.notificationGroupName =
        this.cameraSummarySettingsComponent.selectRecipientsComponent.selectedRecipients[0].name;
    } else if (
      this.cameraSummarySettingsComponent.selectRecipientsComponent.createNotificationCheck &&
      this.cameraSummarySettingsComponent.selectRecipientsComponent.selectedRecipients.length !== 1
    ) {
      this.notificationGroupName =
        this.cameraSummarySettingsComponent.selectRecipientsComponent.createNotificationName.value;
    }

    this.stepper.next();
  }

  private getRecipientsEmails(recipients: any[]): string[] {
    const emails = [];
    recipients.forEach((recipient) => {
      if (typeof recipient === 'string') {
        emails.push(recipient);
      } else if (recipient.hasOwnProperty('firstName')) {
        emails.push(recipient.email);
      }
    });
    return emails;
  }

  private getRecipientsNotificationGroupsIDs(recipients: any[]): string[] {
    const notificationGroupsIDs = [];
    recipients.forEach((recipient) => {
      if (recipient.hasOwnProperty('emails')) {
        notificationGroupsIDs.push(recipient.id);
      }
    });
    return notificationGroupsIDs;
  }

  addSummaryAlert(): void {
    this.saving = true;
    const cameraHealthSummaryConfiguration = {
      ...this.summaryAlert,
    };
    delete cameraHealthSummaryConfiguration['createdAt'];
    const requests: Observable<any>[] = [];

    let emails;

    if (
      this.cameraSummarySettingsComponent.selectRecipientsComponent.selectedRecipients.length === 1 &&
      this.cameraSummarySettingsComponent.selectRecipientsComponent.selectedRecipients[0].hasOwnProperty('emails')
    ) {
      requests.push(this.alertService.putAlert({ ...cameraHealthSummaryConfiguration, emails: undefined }));
      // notificationGroup = this.cameraSummarySettingsComponent.selectRecipientsComponent.selectedRecipients[0];
    } else if (
      this.cameraSummarySettingsComponent.selectRecipientsComponent.createNotificationCheck &&
      this.cameraSummarySettingsComponent.selectRecipientsComponent.selectedRecipients.length !== 1
    ) {
      requests.push(this.alertService.putAlert(cameraHealthSummaryConfiguration));
      requests.push(
        this.notificationGroupService.putNotificationGroup({
          emails: this.getRecipientsEmails(
            this.cameraSummarySettingsComponent.selectRecipientsComponent.selectedRecipients,
          ),
          name: this.cameraSummarySettingsComponent.selectRecipientsComponent.createNotificationName.value,
          organisationId: this.organisationId,
        }),
      );
    } else {
      emails = this.getRecipientsEmails(
        this.cameraSummarySettingsComponent.selectRecipientsComponent.selectedRecipients,
      );
      if (emails?.length !== 0) {
        cameraHealthSummaryConfiguration.emails = emails;
      }
      requests.push(this.alertService.putAlert(cameraHealthSummaryConfiguration));
    }

    if (this.editing) {
      this.summaryAlertNotificationGroupsMapping.forEach((cameraHealthSummaryConfigurationNotificationGroupLink) => {
        requests.push(
          this.alertNotificationGroupMappingService.deleteAlertNotificationGroupMapping(
            cameraHealthSummaryConfigurationNotificationGroupLink.id,
          ),
        );
      });
    }

    forkJoin(requests)
      .pipe(
        switchMap((response) => {
          const requests: Observable<any>[] = [];
          if (
            this.cameraSummarySettingsComponent.selectRecipientsComponent.createNotificationCheck &&
            this.cameraSummarySettingsComponent.selectRecipientsComponent.selectedRecipients.length !== 1
          ) {
            requests.push(
              this.alertNotificationGroupMappingService.putAlertNotificationGroupMapping({
                alertId: cameraHealthSummaryConfiguration.id ? cameraHealthSummaryConfiguration.id : response[0].id,
                notificationGroupId: response[1].id,
              }),
            );
            this.getRecipientsNotificationGroupsIDs(
              this.cameraSummarySettingsComponent.selectRecipientsComponent.selectedRecipients,
            ).forEach((notificationGroupId) => {
              requests.push(
                this.notificationGroupMappingService.putNotificationGroupNotificationGroup({
                  organisationId: this.organisationId,
                  parentId: response[1].id,
                  childId: notificationGroupId,
                }),
              );
            });
          } else {
            this.getRecipientsNotificationGroupsIDs(
              this.cameraSummarySettingsComponent.selectRecipientsComponent.selectedRecipients,
            ).forEach((notificationGroupId) => {
              requests.push(
                this.alertNotificationGroupMappingService.putAlertNotificationGroupMapping({
                  alertId: cameraHealthSummaryConfiguration.id ? cameraHealthSummaryConfiguration.id : response[0].id,
                  notificationGroupId: notificationGroupId,
                }),
              );
            });
          }
          if (this.editing && this.cameraAlertAndSummaryNameAndSitesComponent.alertMode === 'site') {
            // type cameraHealthSummaryConfigurationCameraPosition
            const linksToDelete = [...this.summaryAlertCameraPositions];
            // type Camera
            const linksToAdd = [...this.cameraAlertAndSummaryCamerasComponent.selection.selected];

            linksToDelete.forEach(
              (cameraHealthSummaryConfigurationCameraPosition: AlertCameraPositionMapping, indexDelete) => {
                const indexAdd = linksToAdd.findIndex(
                  (camera: Camera) =>
                    camera?.cameraPositionId === cameraHealthSummaryConfigurationCameraPosition.cameraPositionId,
                );

                if (indexAdd != -1) {
                  delete linksToDelete[indexDelete];
                  delete linksToAdd[indexAdd];
                }
              },
            );

            linksToDelete.forEach((cameraHealthSummaryConfigurationCameraPosition: AlertCameraPositionMapping) => {
              requests.push(
                this.alertCameraPositionMappingService.deleteAlertCameraPositionMapping(
                  cameraHealthSummaryConfigurationCameraPosition.id,
                ),
              );
            });
            // add monitor links
            linksToAdd.forEach((camera: Camera) => {
              requests.push(
                this.alertCameraPositionMappingService.putAlertCameraPositionMapping({
                  cameraPositionId: camera.cameraPositionId,
                  alertId: this.summaryAlert.id,
                }),
              );
            });
          } else {
            if (
              this.cameraAlertAndSummaryNameAndSitesComponent.alertMode === 'site' &&
              !this.cameraAlertAndSummaryCamerasComponent.isAllSelected()
            ) {
              this.cameraAlertAndSummaryCamerasComponent.selection.selected.forEach((camera) =>
                requests.push(
                  this.alertCameraPositionMappingService.putAlertCameraPositionMapping({
                    cameraPositionId: camera.cameraPositionId,
                    alertId: response[0].id,
                  }),
                ),
              );
            }
          }
          return forkJoin(requests);
        }),
        finalize(() => {
          // this.saving = false;
          // if (this.editing) {
          this.close(true);
          // }
          // this.stepper.next();
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (_) => {
          // if (this.editing) {
          this.close(true);
          // }
          // this.stepper.next();
        },
        error: (error) => {
          this.notifyService.error(error);
        },
      });
  }

  close(reload?: boolean): void {
    if (reload) {
      this.dialogRef.close('reload');
    } else {
      this.dialogRef.close();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
