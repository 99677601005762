<div class="o-dialog">
  <mat-dialog-content *ngIf="title" class="o-dialog__title c-user-title" [innerHTML]="title"></mat-dialog-content>
  <mat-dialog-content *ngIf="message" class="o-dialog__body c-user-message" [innerHTML]="message"></mat-dialog-content>
  <div class="o-dialog__actions">
    <button mat-button class="is-destructive is-uppercase" (click)="close()">{{ closeText }}</button>
    <button
      *ngIf="buttonText"
      mat-button
      class="is-constructive is-uppercase"
      [ngClass]="{ 'is-delete': isDelete, 'is-neutral': !isDelete }"
      (click)="confirm()"
    >
      {{ buttonText }}
    </button>
  </div>
</div>
