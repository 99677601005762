import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Organisation, Site } from 'src/app/api';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-monitor-alert-type',
  templateUrl: './monitor-alert-type.component.html',
  styleUrls: ['./monitor-alert-type.component.scss'],
})
export class MonitorAlertTypeComponent implements OnInit {
  @Input() sites: Site[] = [];
  @Output() reloadEvent: EventEmitter<void> = new EventEmitter();
  alertName = '';
  filteredSites: Site[];
  selectedOrganisation: Organisation;
  selectedSite: Site;
  showOrganisationSelect: boolean;
  alertMode: 'site' | 'organisaiton' = 'organisaiton';

  constructor(public accountService: AccountService) {}

  ngOnInit(): void {
    this.filteredSites = this.sites;
    if (this.accountService.isSupport) {
      this.showOrganisationSelect = this.accountService.isSupport;
    } else {
      this.selectedOrganisation = this.accountService.organisation;
    }
  }

  filterSitesByOrganisation() {
    this.filteredSites = this.sites.filter((site) => site.organisationId === this.selectedOrganisation.id);
  }
}
