import { ChangeDetectorRef, Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, takeUntil } from 'rxjs';
import { OAuthClient, OAuthClientService } from 'src/app/api';
import { AccountService } from 'src/app/services/account.service';
import { NotifyService } from 'src/app/services/notify.service';
import { MatDialog } from '@angular/material/dialog';
import { OauthClientFlowHelpComponent } from 'src/app/views/settings/components/oauth-client-flow-help/oauth-client-flow-help.component';
import { UserConfirmationComponent } from 'src/app/components/general/user-confirmation/user-confirmation.component';

@Component({
  selector: 'app-oauth-client-modal',
  templateUrl: './oauth-client-modal.component.html',
  styleUrls: ['./oauth-client-modal.component.scss'],
})
export class OAuthClientModalComponent implements OnDestroy, OnInit {
  stepsTitle = 'Authorisation';
  isClientSecretAsPassword = true;
  oAuthClient: OAuthClient = { callbacks: [], grantTypes: [], name: '' };
  callbacks = '';
  editMode = false;
  tooltipMessage = 'Copy to clipboard';
  revealSecretTooltipMessage = 'Reveal Secret';
  rotateSecretTooltipMessage = 'Rotate Secret';

  isURLListValid = true;
  isGrantTypesValid = true;

  private ngUnsubscribe = new Subject<void>();

  dialog = inject(MatDialog);

  @ViewChild('tooltipClient') tooltipClient: MatTooltip;
  @ViewChild('tooltipSecret') tooltipSecret: MatTooltip;

  constructor(
    private oAuthClientService: OAuthClientService,
    public accountService: AccountService,
    private ref: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private notifyService: NotifyService,

    private matDialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (params) => {
        this.editMode = !!params.id;
        if (this.editMode) {
          this.oAuthClient = this.accountService.oAuthClients.find((client) => client.clientId === params.id) || {
            callbacks: [],
            grantTypes: [],
            name: '',
          };
          this.callbacks = this.oAuthClient.callbacks?.join(', ') || '';
        }
        this.validateInputs();
      },
    });
  }

  get canEnableRefreshToken(): boolean {
    const grantTypes = this.oAuthClient.grantTypes;
    return grantTypes.includes('authorization_code') || grantTypes.includes('password');
  }

  switchGrantType(grantType): void {
    const grantTypes = this.oAuthClient.grantTypes;

    if (grantTypes.includes(grantType)) {
      this.oAuthClient.grantTypes = grantTypes.filter((gt) => gt !== grantType);
    } else {
      this.oAuthClient.grantTypes.push(grantType);
    }

    if (grantType !== 'refresh_token' && !this.canEnableRefreshToken) {
      this.oAuthClient.grantTypes = this.oAuthClient.grantTypes.filter((gt) => gt !== 'refresh_token');
    }

    this.validateInputs();
  }

  save(): void {
    const callbacks = this.callbacks.trim() ? this.callbacks.split(',').map((url) => url.trim()) : [];
    this.oAuthClientService
      .putOAuthClient({
        ...this.oAuthClient,
        callbacks,
        organisationId: this.accountService.organisation.id,
      })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () =>
          this.router.navigate(['settings'], { queryParams: { tab: 'oauth_client' } }).then(() => {
            window.location.reload();
          }),
        error: (error) => this.notifyService.error(error),
      });
  }

  delete(): void {
    const title = `<em>Are you sure you want to delete the oauth client,</em> <b>${this.oAuthClient.name}</b><em>?</em>`;
    const message = `This action cannot be undone. All your application using this oauth client will stop working. 
    <br>Click the <em>DELETE</em> to proceed, or <em>CANCEL</em> to ignore.`;

    const dialogRef = this.matDialog.open(UserConfirmationComponent, {
      data: { title, message, buttonText: 'delete', closeText: 'cancel', isDelete: true },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'confirm') {
          this.oAuthClientService.deleteOAuthClient(this.oAuthClient.clientId).subscribe({
            next: (_) => {
              this.router.navigate(['settings'], { queryParams: { tab: 'oauth_client' } }).then(() => {
                window.location.reload();
              });
            },
            error: (error) => {
              this.notifyService.error(error);
            },
          });
        }
      },
    });
  }

  rotateClientSecret(): void {
    const title = `<em>Are you sure you want to rotate the secret for oauth client,</em> <b>${this.oAuthClient.name}</b><em>?</em>`;
    const message = `This action cannot be undone. All your applications using this oauth client need to be updated with the new secret; otherwise, they will stop working. 
    <br>Click the <em>CONFIRM</em> to proceed, or <em>CANCEL</em> to ignore.`;

    const dialogRef = this.matDialog.open(UserConfirmationComponent, {
      data: { title, message, buttonText: 'confirm', closeText: 'cancel' },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'confirm') {
          this.oAuthClientService.rotateClientSecret(this.oAuthClient.clientId).subscribe({
            next: (_) => {
              window.location.reload();
            },
            error: (error) => {
              this.notifyService.error(error);
            },
          });
        }
      },
    });
  }

  togglePassword(tooltip: MatTooltip): void {
    this.revealSecretTooltipMessage = this.isClientSecretAsPassword ? 'Hide Secret' : 'Reveal Secret';
    this.isClientSecretAsPassword = !this.isClientSecretAsPassword;
  }

  copyLink(tooltip: MatTooltip): void {
    this.tooltipMessage = 'Link copied!';
    tooltip.show();
    this.ref.detectChanges();
    setTimeout(() => {
      this.tooltipMessage = 'Copy to clipboard';
      this.ref.detectChanges();
    }, 1000);
  }

  validateInputs(): void {
    this.isURLListValid = this.validateURLList();
    this.isGrantTypesValid = this.validateGrantTypes();
  }

  showFlowDiagram(diagram: string): void {
    this.dialog.open(OauthClientFlowHelpComponent, {
      data: { image: `/assets/images/${diagram}.png` },
    });
  }
  private validateURLList(): boolean {
    return !this.oAuthClient.grantTypes.includes('authorization_code') || !!this.callbacks.trim();
  }

  private validateGrantTypes(): boolean {
    const grantTypes = this.oAuthClient.grantTypes;
    return grantTypes.length > 0 && !(grantTypes.includes('refresh_token') && grantTypes.length === 1);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
