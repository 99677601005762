<div class="c-infrastructure-aside-mobile">
  <button class="c-button-show-menu" (click)="openDialog(infrastructureAsideTemplate)">
    <mat-icon>dehaze</mat-icon>
    <div>MENU</div>
  </button>
</div>

<div class="c-infrastructure-aside-desktop">
  <ng-container *ngTemplateOutlet="infrastructureAsideTemplate"></ng-container>
</div>

<ng-template #infrastructureAsideTemplate>
  <aside class="c-infrastructure-aside">
    <div class="c-icon-mobile-close-wrapper">
      <mat-icon class="c-icon-mobile-close" (click)="closeDialog()">close</mat-icon>
    </div>
    <section class="c-aside-seciton" *ngIf="sites.length">
      <div class="c-aside-section-title-wrapper">
        <div>
          <div class="c-aside-section-title">Sites</div>
          <div class="paragon">{{ sites.length }}</div>
        </div>

        <a class="o-button is-small" [routerLink]="['/sites']">
          View all sites
          <span class="material-icons is-icon-right"> arrow_forward </span>
        </a>
      </div>

      <button class="o-button is-primary is-small" (click)="addSite.emit()">
        <span class="material-icons is-icon-left"> business </span>
        Add a site
      </button>
    </section>

    <section class="c-aside-seciton" *ngIf="cameras.length">
      <div class="c-aside-section-title-wrapper">
        <div>
          <div class="c-aside-section-title">Cameras</div>
          <div class="paragon">{{ cameras.length }}</div>
        </div>

        <a class="o-button is-small" [routerLink]="['/cameras']">
          View all cameras
          <span class="material-icons is-icon-right"> arrow_forward </span>
        </a>
      </div>

      <div class="c-alert c-alert-success" *ngIf="!issueCameras.length">
        <div class="alert-description">
          <mat-icon>camera_alt</mat-icon>
          <div><b>0</b> cameras with issues</div>
        </div>
      </div>

      <div class="c-alert c-alert-warning" *ngIf="issueCameras.length">
        <div class="alert-description">
          <mat-icon>warning</mat-icon>
          <div>
            <b>{{ issueCameras.length }}</b> {{ issueCameras.length | pluraliseWord: 'camera' }} with issues
          </div>
        </div>
        <div class="c-text-across-sites">
          Across {{ getCamerasSites(issueCameras) }} {{ getCamerasSites(issueCameras) | pluraliseWord: 'site' }}
        </div>
      </div>

      <div class="c-alert c-alert-paused" *ngIf="pausedCameras">
        <div class="alert-description">
          <mat-icon>pause_circle_filled</mat-icon>
          <div>
            <b>{{ pausedCameras }}</b> {{ pausedCameras | pluraliseWord: 'camera' }} paused
          </div>
        </div>
      </div>

      <!-- <div class="c-alert c-alert-ready">
        <div class="alert-description">
          <mat-icon>power_settings_new</mat-icon>
          <div><b>3</b> cameras paused</div>
        </div>
        <button class="o-button">SETUP</button>
      </div> -->

      <div class="unregistered-cameras u-margin--bottom" *ngIf="incompletCameras.length">
        <div class="brevier--caps">being setup</div>
        <ul class="unregistered-cameras__list o-list">
          <li class="c-setup-camera-item" *ngFor="let camera of incompletCameras" (click)="continueSetup.emit(camera)">
            <div class="c-setup-camera-item__title">
              <div class="is-primary-text">{{ camera.name }}</div>
              <div class="is-secondary-text">{{ sitesMap[camera.siteId].name }}</div>
            </div>
            <div class="c-setup-camera-item__progress">
              <span class="minion--caps u-flush--bottom type--secondary">SETUP:</span>
              {{ completPercentage[camera.id] }}% complete
              <div class="is-progress-bar">
                <div class="is-completed" [ngStyle]="{ 'width.%': completPercentage[camera.id] }"></div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <button class="o-button is-primary is-small" (click)="addCamera.emit()">
        <span class="material-icons is-icon-left"> add_a_photo </span>
        Setup new camera
      </button>
      <!-- <button class="o-button c-button-order-new-camera is-primary is-small">
        <span class="material-icons is-icon"> add_a_photo </span>
        ORDER NEW CAMERA
      </button> -->
    </section>

    <section class="c-aside-seciton" *ngIf="cameras.length">
      <div class="c-service-wrapper c-service-health-summary">
        <div class="c-health-summaries-wrapper">
          <mat-icon class="material-icons c-icon-service"> no_photography </mat-icon>
          <a [routerLink]="['/alerts/camera-alerts-offline']" class="c-text-service">{{
            offlineAlerts.length ? 'Camera alerts' : 'Create your first camera alert'
          }}</a>
        </div>
        <div class="c-alert-small-warning" *ngIf="offlineAlerts.length && camerasWithNoOfflineAlerts.length">
          {{ camerasWithNoOfflineAlerts.length }} {{ camerasWithNoOfflineAlerts.length | pluraliseWord: 'camera' }} not
          included
        </div>
      </div>

      <div class="c-service-wrapper c-service-health-summary" *ngIf="accountService.isSupport">
        <div class="c-health-summaries-wrapper">
          <mat-icon class="material-icons c-icon-service"> emergency </mat-icon>
          <a [routerLink]="['/alerts/camera-summaries']" class="c-text-service">{{
            summaryAlerts.length ? 'Health summaries' : 'Create your first camera health summary'
          }}</a>
        </div>
        <div class="c-alert-small-warning" *ngIf="summaryAlerts.length && camerasWithNoSummaryAlert.length">
          {{ camerasWithNoSummaryAlert.length }}
          {{ camerasWithNoSummaryAlert.length | pluraliseWord: 'camera' }} not included
        </div>
      </div>
    </section>

    <section
      class="c-aside-seciton"
      *ngIf="
        (accountService.occupancyLicence && occupancyMonitors.length) ||
        peopleCountMonitors.length ||
        peelOffMonitors.length
      "
    >
      <div class="c-aside-section-title-wrapper">
        <div>
          <div class="c-aside-section-title">Monitors</div>
          <div class="paragon">
            {{ occupancyMonitors.length + peopleCountMonitors.length + peelOffMonitors.length }}
          </div>
        </div>

        <a class="o-button is-small" [routerLink]="['/monitors']">
          VIEW ALL MONITORS
          <span class="material-icons is-icon-right"> arrow_forward </span>
        </a>
      </div>

      <div class="c-monitor-card">
        <mat-icon>transfer_within_a_station</mat-icon>
        <div class="c-monitor-card-content">
          <div>
            <b> People count monitors | {{ peopleCountMonitors.length }} </b>
          </div>
          <button class="o-button is-small u-hard--left" (click)="addPeopleCountMonitor.emit()">
            ADD PEOPLE COUNT MONITOR
          </button>
        </div>
      </div>
      <div class="c-monitor-card">
        <mat-icon>fork_left</mat-icon>
        <div class="c-monitor-card-content">
          <div>
            <b> Peel off monitors | {{ peelOffMonitors.length }} </b>
          </div>
          <button class="o-button is-small u-hard--left" (click)="addPeelOffMonitor.emit()">
            ADD PEEL OFF COUNT MONITOR
          </button>
        </div>
      </div>

      <div class="c-monitor-card" *ngIf="accountService.occupancyLicence">
        <mat-icon>groups</mat-icon>
        <div class="c-monitor-card-content">
          <div>
            <b> Occupancy monitors | {{ occupancyMonitors.length }} </b>
          </div>
          <button class="o-button is-small u-hard--left" (click)="addOccupancyMonitor.emit()">
            ADD OCCUPANCY MONITOR
          </button>
        </div>
      </div>

      <!-- <div class="c-monitor-card">
        <mat-icon>transfer_within_a_station</mat-icon>
        <div class="c-monitor-card-content">
          <div><b> People count monitors | 4 </b></div>
          <button class="o-button is-small c-button-add-monitor">ADD PEOPLE COUNT MONITOR</button>
        </div>
      </div> -->

      <div class="c-monitor-card c-monitor-card-transparent" *ngIf="accountService.occupancyLicence">
        <mat-icon class="c-icon-reduce-capacity">reduce_capacity</mat-icon>
        <div class="c-monitor-card-content">
          <a [routerLink]="['/alerts/monitor-alerts']" class="c-text-people-count-monitors">
            {{ occupancyAlerts.length ? 'Occupancy alerts' : 'Create your first occupancy alert' }}
          </a>
          <div class="c-alert-small-warning" *ngIf="occupancyAlerts.length">
            {{ noOccupancyAlertMonitors.length }} occupancy
            {{ noOccupancyAlertMonitors.length | pluraliseWord: 'monitor' }} not covered
          </div>
        </div>
      </div>
    </section>
  </aside>
</ng-template>
