import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { forkJoin, switchMap, Observable, finalize, takeUntil, Subject } from 'rxjs';
import {
  AlertOccupancyMonitorMappingService,
  OccupancyMonitor,
  OccupancyMonitorCameraPositionService,
  OccupancyMonitorService,
  PeelOffMonitorCameraPositionService,
  PeelOffMonitorService,
  PeopleCountMonitor,
  PeopleCountMonitorCameraPositionService,
  PeopleCountMonitorService,
} from 'src/app/api';
import { UserConfirmationComponent } from 'src/app/components/general/user-confirmation/user-confirmation.component';
import { AddEditPeelOffMonitorComponent } from 'src/app/components/monitors/add-edit-peel-off-monitor/add-edit-peel-off-monitor.component';
import { AddOccupancyMonitorComponent } from 'src/app/components/monitors/add-occupancy-monitor/add-occupancy-monitor.component';
import { AddPeopleCountMonitorComponent } from 'src/app/components/monitors/add-people-count-monitor/add-people-count-monitor.component';
import { compare } from 'src/app/functions/sort';
import { GlobalMethods } from 'src/app/global-methods';
import { CameraStatus } from 'src/app/model/cameraStatus';
import {
  OccupancyMonitorStatus,
  MonitorStatus,
  PeopleCountMonitorStatus,
  PeelOffMonitorStatus,
} from 'src/app/model/monitorStatus';
import { AccountService } from 'src/app/services/account.service';
import { MonitorsService } from 'src/app/services/monitors.service';
import { NotifyService } from 'src/app/services/notify.service';

const noPeopleCountMonitorText = `If you want to know the total count of people who have come into your site, or a particular space on your site, you can use a people count monitor.

The counting is bi-directional so you can report on individual or combined traffic directions.`;
const noOccupancyMonitorText = `If you want to know how many people are in your site, or in a particular space in your site - you will want to set up an occupancy monitor.
  
You can set a capacity for the monitor (this is typically the maximum number of people the space can hold) and create an occupancy alert to tell team members when a space is approaching, or has reached, its capacity.`;
const noPeelOffMonitorText = `A peel off monitor is a perfect tool for stores who want a deeper understanding of their sales traffic.

Peel off monitors count both traffic passing and customers entering your store. This allows you to understand footfall and relative peel-off conversion rates between stores as well as measuring effectiveness of marketing campaigns and window displays.`;

@Component({
  selector: 'app-monitors-info',
  templateUrl: './monitors-info.component.html',
  styleUrl: './monitors-info.component.scss',
})
export class MonitorsInfoComponent implements OnInit, OnDestroy {
  noPeopleCountMonitorText = noPeopleCountMonitorText;
  noOccupancyMonitorText = noOccupancyMonitorText;
  noPeelOffMonitorText = noPeelOffMonitorText;
  organisationName: string;
  disabledOccupancyMonitorCard: { [_: string]: boolean } = {};
  disabledPeopleCountMonitorCard: { [_: string]: boolean } = {};
  disabledPeelOffMonitorCard: { [_: string]: boolean } = {};
  occupancyMonitorsWithIssues = 0;
  peopleCountMonitorsWithIssues = 0;
  peelOffMonitorsWithIssues = 0;
  keyToSort: keyof PeelOffMonitorStatus = 'name';

  private ngUnsubscribe = new Subject();

  constructor(
    public monitorsService: MonitorsService,
    private occupancyMonitorService: OccupancyMonitorService,
    private peopleCountMonitorService: PeopleCountMonitorService,
    private peelOffMonitorService: PeelOffMonitorService,
    public accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog,
    private notifyService: NotifyService,
    private alertOccupancyMonitorMappingService: AlertOccupancyMonitorMappingService,
    private ref: ChangeDetectorRef,
    private occupancyMonitorCameraPositionService: OccupancyMonitorCameraPositionService,
    private peopleCountMonitorCameraPositionService: PeopleCountMonitorCameraPositionService,
    private peelOffMonitorCameraPositionService: PeelOffMonitorCameraPositionService,
  ) {}
  ngOnInit(): void {
    const { organisation, isSupport } = this.accountService;
    if (!isSupport) this.organisationName = organisation?.name;

    this.monitorsService.occupancyMonitors.forEach((om) => {
      if (om.status === 'issue') {
        this.occupancyMonitorsWithIssues += 1;
      }
    });

    this.monitorsService.peopleCountMonitors.forEach((pcm) => {
      if (pcm.status === 'issue') {
        this.peopleCountMonitorsWithIssues += 1;
      }
    });

    this.monitorsService.peelOffMonitors.forEach((pom) => {
      if (pom.status === 'issue') {
        this.peelOffMonitorsWithIssues += 1;
      }
    });

    const filterMonitorsFromParams = this.getQueryParam('filterMonitors') ? this.getQueryParam('filterMonitors') : '';

    switch (this.monitorsService.selectedTabIndex) {
      case this.monitorsService.monitorsType['people_count']: {
        this.monitorsService.mustContainPeopleCountString = filterMonitorsFromParams;
        break;
      }
      case this.monitorsService.monitorsType['peel_off']: {
        this.monitorsService.mustContainPeelOffString = filterMonitorsFromParams;
        break;
      }
      case this.monitorsService.monitorsType['occupancy']: {
        this.monitorsService.mustContainOccupancyString = filterMonitorsFromParams;
        break;
      }
    }
    this.filterPeopleCountMonitors(this.monitorsService.mustContainPeopleCountString);
    this.filterOccupancyMonitors(this.monitorsService.mustContainOccupancyString);
    this.filterPeelOffMonitors(this.monitorsService.mustContainPeelOffString);
  }

  getCamerasWithIssue(cameras: CameraStatus[]): number {
    return cameras.filter((c) => c.status === 'lagging' || c.status === 'missing' || c.status === 'offline').length;
  }

  groupIssueMonitorsBySite(): any[] {
    const monitorSites: { [_: string]: any[] } = {};

    this.monitorsService.occupancyMonitors.forEach((om) => {
      if (om.status === 'issue') {
        if (monitorSites[om.siteId]) {
          monitorSites[om.siteId].push(om);
        } else {
          monitorSites[om.siteId] = [om];
        }
      }
    });
    this.monitorsService.peopleCountMonitors.forEach((pcm) => {
      if (pcm.status === 'issue') {
        if (monitorSites[pcm.siteId]) {
          monitorSites[pcm.siteId].push(pcm);
        } else {
          monitorSites[pcm.siteId] = [pcm];
        }
      }
    });
    this.monitorsService.peelOffMonitors.forEach((pom) => {
      if (pom.status === 'issue') {
        if (monitorSites[pom.siteId]) {
          monitorSites[pom.siteId].push(pom);
        } else {
          monitorSites[pom.siteId] = [pom];
        }
      }
    });
    return Object.values(monitorSites);
  }

  goToMonitorPage(
    monitor: OccupancyMonitorStatus | PeopleCountMonitorStatus | PeelOffMonitorStatus,
    edit?: boolean,
  ): void {
    let path;
    if (monitor.hasOwnProperty('capacity')) {
      path = `monitors/occupancy-monitor/${monitor.id}`;
    } else if (monitor.hasOwnProperty('direction1Alias')) {
      path = `monitors/people-count-monitor/${monitor.id}`;
    } else {
      path = `monitors/peel-off-monitor/${monitor.id}`;
    }
    if (edit) {
      path += '/edit';
    }
    this.router.navigate([path]);
  }

  openNewOccupancyMonitorDialog(): void {
    const dialogRef = this.matDialog.open(AddOccupancyMonitorComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data: {
        cameras: this.monitorsService.cameras.filter(
          (c) => c.serialNumber && GlobalMethods.isOverheadCamera(c.serialNumber),
        ),
        sites: this.monitorsService.sites,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          window.location.reload();
        }
      },
    });
  }

  openNewPeopleCountMonitorDialog(): void {
    const dialogRef = this.matDialog.open(AddPeopleCountMonitorComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data: {
        cameras: this.monitorsService.cameras,
        sites: this.monitorsService.sites,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          window.location.reload();
        }
      },
    });
  }

  openNewPeelOffMonitorDialog() {
    const dialogRef = this.matDialog.open(AddEditPeelOffMonitorComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      disableClose: true,
      data: {
        cameras: this.monitorsService.cameras,
        sites: this.monitorsService.sites,
        stepsTitle: 'Create Peel Off Monitor',
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          window.location.reload();
        }
      },
    });
  }

  getQueryParam(queryParam: string): string {
    const filterQueryParam: string = this.route.snapshot.queryParams[queryParam];

    return filterQueryParam;
  }

  updateParams(filterMonitors: string, customParams?: Params) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { filterMonitors, ...customParams },
      queryParamsHandling: 'merge',
    });
  }

  onTabChanged(type) {
    switch (type) {
      case 'people_count': {
        this.updateParams(this.monitorsService.mustContainPeopleCountString, { type });
        break;
      }
      case 'peel_off': {
        this.updateParams(this.monitorsService.mustContainPeelOffString, { type });
        break;
      }
      case 'occupancy': {
        this.updateParams(this.monitorsService.mustContainOccupancyString, { type });
        break;
      }
    }
  }

  sortOccupancyMonitors(): void {
    switch (this.keyToSort) {
      case 'status': {
        this.monitorsService.filteredOccupancyMonitors = this.monitorsService.filteredOccupancyMonitors.sort(
          (m1: OccupancyMonitorStatus, m2: OccupancyMonitorStatus) => {
            if (m1.status === m2.status) {
              return compare(m1, m2, {
                keyToSort: 'name',
                direction: 'ascending',
              });
            } else {
              return (
                Object.values(MonitorStatus.StatusEnum).indexOf(m2.status) -
                Object.values(MonitorStatus.StatusEnum).indexOf(m1.status)
              );
            }
          },
        );
        break;
      }
      default: {
        this.monitorsService.filteredOccupancyMonitors = this.monitorsService.filteredOccupancyMonitors.sort(
          (m1: OccupancyMonitorStatus, m2: OccupancyMonitorStatus) => {
            return compare(m1, m2, {
              keyToSort: this.keyToSort,
              direction: 'ascending',
              thenBy: { keyToSort: 'name', direction: 'ascending' },
            });
          },
        );
      }
    }
  }

  sortPeopleCountMonitors(): void {
    switch (this.keyToSort) {
      case 'status': {
        this.monitorsService.filteredPeopleCountMonitors = this.monitorsService.filteredPeopleCountMonitors.sort(
          (m1: PeopleCountMonitorStatus, m2: PeopleCountMonitorStatus) => {
            if (m1.status === m2.status) {
              return compare(m1, m2, {
                keyToSort: 'name',
                direction: 'ascending',
              });
            } else {
              return (
                Object.values(MonitorStatus.StatusEnum).indexOf(m2.status) -
                Object.values(MonitorStatus.StatusEnum).indexOf(m1.status)
              );
            }
          },
        );
        break;
      }
      default: {
        this.monitorsService.filteredPeopleCountMonitors = this.monitorsService.filteredPeopleCountMonitors.sort(
          (m1: PeopleCountMonitorStatus, m2: PeopleCountMonitorStatus) => {
            return compare(m1, m2, {
              keyToSort: this.keyToSort,
              direction: 'ascending',
              thenBy: { keyToSort: 'name', direction: 'ascending' },
            });
          },
        );
      }
    }
  }

  sortPeelOffMonitors(): void {
    switch (this.keyToSort) {
      case 'status': {
        this.monitorsService.filteredPeelOffMonitors = this.monitorsService.filteredPeelOffMonitors.sort(
          (m1: PeelOffMonitorStatus, m2: PeelOffMonitorStatus) => {
            if (m1.status === m2.status) {
              return compare(m1, m2, {
                keyToSort: 'name',
                direction: 'ascending',
              });
            } else {
              return (
                Object.values(MonitorStatus.StatusEnum).indexOf(m2.status) -
                Object.values(MonitorStatus.StatusEnum).indexOf(m1.status)
              );
            }
          },
        );
        break;
      }
      default: {
        this.monitorsService.filteredPeelOffMonitors = this.monitorsService.filteredPeelOffMonitors.sort(
          (m1: PeelOffMonitorStatus, m2: PeelOffMonitorStatus) => {
            return compare(m1, m2, {
              keyToSort: this.keyToSort,
              direction: 'ascending',
              thenBy: { keyToSort: 'name', direction: 'ascending' },
            });
          },
        );
      }
    }
  }

  filterPeopleCountMonitors(ss: string): void {
    this.updateParams(ss);

    this.monitorsService.mustContainPeopleCountString = ss.toLowerCase();
    this.monitorsService.filteredPeopleCountMonitors = this.monitorsService.peopleCountMonitors.filter(
      (monitor: PeopleCountMonitorStatus) => {
        return (
          monitor.name.toLowerCase().includes(this.monitorsService.mustContainPeopleCountString) ||
          monitor.id.toLowerCase().includes(this.monitorsService.mustContainPeopleCountString) ||
          this.accountService.organisationsMap[monitor.organisationId]?.name
            .toLowerCase()
            .includes(this.monitorsService.mustContainPeopleCountString) ||
          monitor.siteName.toLowerCase().includes(this.monitorsService.mustContainPeopleCountString)
        );
      },
    );
    this.sortPeopleCountMonitors();
  }

  filterOccupancyMonitors(ss: string): void {
    this.updateParams(ss);

    this.monitorsService.mustContainOccupancyString = ss.toLowerCase();
    this.monitorsService.filteredOccupancyMonitors = this.monitorsService.occupancyMonitors.filter(
      (monitor: OccupancyMonitorStatus) =>
        monitor.name?.toLowerCase().includes(this.monitorsService.mustContainOccupancyString) ||
        monitor.id.toLowerCase().includes(this.monitorsService.mustContainOccupancyString) ||
        this.accountService.organisationsMap[monitor.organisationId].name
          .toLowerCase()
          .includes(this.monitorsService.mustContainOccupancyString) ||
        monitor.siteName.toLowerCase().includes(this.monitorsService.mustContainOccupancyString),
    );

    this.sortOccupancyMonitors();
  }

  filterPeelOffMonitors(ss: string): void {
    this.updateParams(ss);

    this.monitorsService.mustContainPeelOffString = ss.toLowerCase();
    this.monitorsService.filteredPeelOffMonitors = this.monitorsService.peelOffMonitors.filter(
      (monitor: PeelOffMonitorStatus) => {
        return (
          monitor.name.toLowerCase().includes(this.monitorsService.mustContainPeelOffString) ||
          monitor.id.toLowerCase().includes(this.monitorsService.mustContainPeelOffString) ||
          this.accountService.organisationsMap[monitor.organisationId]?.name
            .toLowerCase()
            .includes(this.monitorsService.mustContainPeelOffString) ||
          monitor.siteName.toLowerCase().includes(this.monitorsService.mustContainPeelOffString)
        );
      },
    );
    this.sortPeelOffMonitors();
  }

  deleteOccupancyMonitor(occupancyMonitor: OccupancyMonitor): void {
    const message = `You are about to delete ${occupancyMonitor.name}.

    Are you sure you want to continue?`;

    const dialogRef = this.matDialog.open(UserConfirmationComponent, {
      data: { message, buttonText: 'Delete' },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'confirm') {
          this.disabledOccupancyMonitorCard[occupancyMonitor.id] = true;
          this.ref.detectChanges();
          forkJoin([
            this.occupancyMonitorCameraPositionService.listOccupancyMonitorCameraPositions(
              undefined,
              undefined,
              undefined,
              occupancyMonitor.id,
              'active',
            ),
            this.alertOccupancyMonitorMappingService.listAlertOccupancyMonitorMappings(
              occupancyMonitor.id,
              undefined,
              undefined,
              undefined,
              'active',
            ),
          ])
            .pipe(
              switchMap(([occupancyMonitorCameraPositions, alertOccupancyMonitorMappings]) => {
                const requests: Observable<any>[] = [];
                occupancyMonitorCameraPositions.forEach((occupancyMonitorCameraPosition) => {
                  requests.push(
                    this.occupancyMonitorCameraPositionService.deleteOccupancyMonitorCameraPosition(
                      occupancyMonitorCameraPosition.id,
                    ),
                  );
                });
                alertOccupancyMonitorMappings.forEach((alertOccupancyMonitorMapping) => {
                  requests.push(
                    this.alertOccupancyMonitorMappingService.deleteAlertOccupancyMonitorMapping(
                      alertOccupancyMonitorMapping.id,
                    ),
                  );
                });
                if (!requests.length) {
                  return this.occupancyMonitorService.deleteOccupancyMonitor(occupancyMonitor.id);
                }
                return forkJoin(requests).pipe(
                  switchMap((_) => this.occupancyMonitorService.deleteOccupancyMonitor(occupancyMonitor.id)),
                );
              }),
              finalize(() => {
                delete this.disabledOccupancyMonitorCard[occupancyMonitor.id];
                this.ref.detectChanges();
              }),
              takeUntil(this.ngUnsubscribe),
            )
            .subscribe({
              next: (_) => {
                window.location.reload();
              },
              error: (error) => {
                this.notifyService.error(error);
              },
            });
        }
      },
    });
  }

  deletePeopleCountMonitor(peopleCountMonitor: PeopleCountMonitor): void {
    const message = `You are about to delete ${peopleCountMonitor.name}.

    Are you sure you want to continue?`;

    const dialogRef = this.matDialog.open(UserConfirmationComponent, {
      data: { message, buttonText: 'Delete' },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'confirm') {
          this.disabledPeopleCountMonitorCard[peopleCountMonitor.id] = true;
          this.ref.detectChanges();
          forkJoin([
            this.peopleCountMonitorCameraPositionService.listPeopleCountMonitorCameraPositions(
              undefined,
              undefined,
              undefined,
              peopleCountMonitor.id,
              'active',
            ),
          ])
            .pipe(
              switchMap(([peopleCountMonitorCameraPositions]) => {
                const requests: Observable<any>[] = [];
                peopleCountMonitorCameraPositions.forEach((peopleCountMonitorCameraPosition) => {
                  requests.push(
                    this.peopleCountMonitorCameraPositionService.deletePeopleCountMonitorCameraPosition(
                      peopleCountMonitorCameraPosition.id,
                    ),
                  );
                });
                if (!requests.length) {
                  return this.peopleCountMonitorService.deletePeopleCountMonitor(peopleCountMonitor.id);
                }
                return forkJoin(requests).pipe(
                  switchMap((_) => this.peopleCountMonitorService.deletePeopleCountMonitor(peopleCountMonitor.id)),
                );
              }),
              finalize(() => {
                delete this.disabledPeopleCountMonitorCard[peopleCountMonitor.id];
                this.ref.detectChanges();
              }),
              takeUntil(this.ngUnsubscribe),
            )
            .subscribe({
              next: (_) => {
                window.location.reload();
              },
              error: (error) => {
                this.notifyService.error(error);
              },
            });
        }
      },
    });
  }

  deletePeelOfftMonitor(peelOffMonitor) {
    const message = `You are about to delete ${peelOffMonitor.name}.

    Are you sure you want to continue?`;

    const dialogRef = this.matDialog.open(UserConfirmationComponent, {
      data: { message, buttonText: 'Delete' },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'confirm') {
          this.disabledPeelOffMonitorCard[peelOffMonitor.id] = true;
          this.ref.detectChanges();
          forkJoin([
            this.peelOffMonitorCameraPositionService.listPeelOffMonitorCameraPositions(
              undefined,
              undefined,
              undefined,
              peelOffMonitor.id,
              'active',
            ),
          ])
            .pipe(
              switchMap(([peelOffMonitorCameraPositions]) => {
                const requests: Observable<any>[] = [];
                peelOffMonitorCameraPositions.forEach((peelOffMonitorCameraPosition) => {
                  requests.push(
                    this.peelOffMonitorCameraPositionService.deletePeelOffMonitorCameraPosition(
                      peelOffMonitorCameraPosition.id,
                    ),
                  );
                });
                if (!requests.length) {
                  return this.peelOffMonitorService.deletePeelOffMonitor(peelOffMonitor.id);
                }
                return forkJoin(requests).pipe(
                  switchMap((_) => this.peelOffMonitorService.deletePeelOffMonitor(peelOffMonitor.id)),
                );
              }),
              finalize(() => {
                delete this.disabledPeelOffMonitorCard[peelOffMonitor.id];
                this.ref.detectChanges();
              }),
              takeUntil(this.ngUnsubscribe),
            )
            .subscribe({
              next: (_) => {
                window.location.reload();
              },
              error: (error) => {
                this.notifyService.error(error);
              },
            });
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
