import { Component, Input } from '@angular/core';
import { Alert } from 'src/app/api';
import { CameraStatus } from 'src/app/model/cameraStatus';
import { OccupancyMonitorStatus, PeelOffMonitorStatus, PeopleCountMonitorStatus } from 'src/app/model/monitorStatus';
import { SiteStatus } from 'src/app/model/siteStatus';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
})
export class ExpansionPanelComponent {
  @Input() camera: CameraStatus;
  @Input() site: SiteStatus;
  @Input() siteCameraIssues: CameraStatus[] = [];
  @Input() cameraOfflineAlerts: Alert[] = [];
  @Input() offlineAlerts: Alert[] = [];
  @Input() cameraSummaryAlerts: Alert[] = [];
  @Input() summaryAlerts: Alert[] = [];
  @Input() occupancyMonitors: OccupancyMonitorStatus[] = [];
  @Input() peopleCountMonitors: PeopleCountMonitorStatus[] = [];
  @Input() peelOffMonitors: PeelOffMonitorStatus[] = [];
  @Input() sevenDaysSummary;

  isExpandedCameraIssue = false;

  constructor(public accountService: AccountService) {}

  expandCameraIssue(): void {
    this.isExpandedCameraIssue = !this.isExpandedCameraIssue;
  }
}
