import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject, finalize, forkJoin, of, switchMap, takeUntil } from 'rxjs';
import {
  Alert,
  AlertNotificationGroupMapping,
  AlertNotificationGroupMappingService,
  AlertOccupancyMonitorMapping,
  AlertOccupancyMonitorMappingService,
  AlertService,
  NotificationGroup,
  NotificationGroupMappingService,
  NotificationGroupService,
  OccupancyMonitor,
  Site,
  User,
} from 'src/app/api';
import { OccupancyAlertDetailsComponent } from 'src/app/components/notifications/steps-for-stepper/occupancy-alert-details/occupancy-alert-details.component';
import { OccupancyAlertSelectMonitorComponent } from 'src/app/components/notifications/steps-for-stepper/occupancy-alert-select-monitor/occupancy-alert-select-monitor.component';
import { NotificationsService } from 'src/app/services/notifications.service';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'app-edit-occupancy-alert',
  templateUrl: './edit-occupancy-alert.component.html',
  styleUrls: ['./edit-occupancy-alert.component.scss'],
})
export class EditOccupancyAlertComponent implements OnDestroy, AfterViewInit {
  saving = false;
  occupancyAlert: Alert;
  occupancyAlertRuleMonitors: OccupancyMonitor[];
  alertOccupancyMonitorMappings: AlertOccupancyMonitorMapping[];
  occupancyMonitors: OccupancyMonitor[];
  occupancyAlertRuleNotificationGroups: NotificationGroup[] = [];
  alertNotificationGroupMappings: AlertNotificationGroupMapping[];
  notificationGroups: NotificationGroup[] = [];
  users: User[] = [];
  site: Site;

  @ViewChild(OccupancyAlertDetailsComponent)
  occupancyAlertDetailsComponent: OccupancyAlertDetailsComponent;

  @ViewChild(OccupancyAlertSelectMonitorComponent)
  occupancyAlertSelectMonitorComponent: OccupancyAlertSelectMonitorComponent;

  private ngUnsubscribe = new Subject();
  constructor(
    private notificationsService: NotificationsService,
    public dialogRef: MatDialogRef<EditOccupancyAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notifyService: NotifyService,
    private notificationGroupService: NotificationGroupService,
    private alertService: AlertService,
    private alertOccupancyMonitorMappingService: AlertOccupancyMonitorMappingService,
    private alertNotificationGroupMappingService: AlertNotificationGroupMappingService,

    private notificationGroupMappingService: NotificationGroupMappingService,
    private ref: ChangeDetectorRef,
  ) {
    this.occupancyAlert = this.data.occupancyAlert;
    this.occupancyAlertRuleMonitors = this.data.occupancyAlertRuleMonitors;
    this.alertOccupancyMonitorMappings = this.data.alertOccupancyMonitorMappings;
    this.occupancyMonitors = this.data.occupancyMonitors;
    this.alertNotificationGroupMappings = this.data.alertNotificationGroupMappings;
    this.site = this.data.site;
    this.alertNotificationGroupMappings.forEach((occupancyAlertRuleNotificationGroupsLink) =>
      this.occupancyAlertRuleNotificationGroups.push(
        this.notificationsService.notificationGroupsMap[occupancyAlertRuleNotificationGroupsLink.notificationGroupId],
      ),
    );
    this.notificationGroups = notificationsService.notificationGroups.filter(
      (notificationGroup) => notificationGroup.organisationId === this.occupancyAlert.organisationId,
    );
    this.users = this.notificationsService.users.filter(
      (user) => user.organisationId === this.occupancyAlert.organisationId,
    );
  }
  ngAfterViewInit(): void {
    this.occupancyAlertRuleMonitors.forEach((monitor) =>
      this.occupancyAlertSelectMonitorComponent?.selection.select(monitor),
    );
  }

  private getRecipientsEmails(recipients: any[]): string[] {
    const emails = [];
    recipients.forEach((recipient) => {
      if (typeof recipient === 'string') {
        emails.push(recipient);
      } else if (recipient.hasOwnProperty('firstName')) {
        emails.push(recipient.email);
      }
    });
    return emails;
  }

  private getRecipientsNotificationGroupsIDs(recipients: any[]): string[] {
    const notificationGroupsIDs = [];
    recipients.forEach((recipient) => {
      if (recipient.hasOwnProperty('emails')) {
        notificationGroupsIDs.push(recipient.id);
      }
    });
    return notificationGroupsIDs;
  }

  save(): void {
    this.occupancyAlertDetailsComponent.errors.warningThreshold = '';
    this.occupancyAlertDetailsComponent.errors.warningRecoveryThreshold = '';
    this.occupancyAlertDetailsComponent.errors.alertRecoveryThreshold = '';

    if (this.isValidForm()) {
      if (
        this.occupancyAlertDetailsComponent.alertForm.get('alertThreshold').value <=
        this.occupancyAlertDetailsComponent.alertForm.get('alertRecoveryThreshold').value
      ) {
        this.occupancyAlertDetailsComponent.errors.alertRecoveryThreshold =
          'Recovery threshold should be less than alert threshold';
        this.ref.detectChanges();
        this.notifyService.warning('Recovery threshold should be less than alert threshold');
        return;
      }
      if (
        this.occupancyAlertDetailsComponent.alertForm.get('alertRecoveryThreshold').value <=
        this.occupancyAlertDetailsComponent.alertForm.get('warningThreshold').value
      ) {
        this.occupancyAlertDetailsComponent.errors.warningThreshold =
          'Warning threshold should be less than alert recovery threshold';
        this.ref.detectChanges();
        this.notifyService.warning('Warning threshold should be less than alert recovery threshold');
        return;
      }
      if (
        this.occupancyAlertDetailsComponent.alertForm.controls.issueWarning.value &&
        this.occupancyAlertDetailsComponent.alertForm.controls.notifyWarningRecovery.value &&
        this.occupancyAlertDetailsComponent.alertForm.get('warningThreshold').value <=
          this.occupancyAlertDetailsComponent.alertForm.get('warningRecoveryThreshold').value
      ) {
        this.occupancyAlertDetailsComponent.errors.warningRecoveryThreshold =
          'Recovery threshold should be less than warning threshold';
        this.ref.detectChanges();
        this.notifyService.warning('Recovery threshold should be less than warning threshold');
        return;
      }
      this.saving = true;
      const requests: Observable<any>[] = [];

      let emails;

      if (
        this.occupancyAlertDetailsComponent.selectRecipientsComponent.selectedRecipients.length === 1 &&
        this.occupancyAlertDetailsComponent.selectRecipientsComponent.selectedRecipients[0].hasOwnProperty('emails')
      ) {
        // emails = [];
        // notificationGroup = this.occupancyAlertDetailsComponent.selectRecipientsComponent.selectedRecipients[0];
      } else if (
        this.occupancyAlertDetailsComponent.selectRecipientsComponent.createNotificationCheck &&
        this.occupancyAlertDetailsComponent.selectRecipientsComponent.selectedRecipients.length !== 1
      ) {
        // emails = [];
        requests.push(
          this.notificationGroupService.putNotificationGroup({
            emails: this.getRecipientsEmails(
              this.occupancyAlertDetailsComponent.selectRecipientsComponent.selectedRecipients,
            ),
            name: this.occupancyAlertDetailsComponent.selectRecipientsComponent.createNotificationName.value,
            organisationId: this.occupancyAlert.organisationId,
          }),
        );
      } else {
        emails = this.getRecipientsEmails(
          this.occupancyAlertDetailsComponent.selectRecipientsComponent.selectedRecipients,
        );
      }

      this.alertNotificationGroupMappings.forEach((alertNotificationGroupMapping) => {
        requests.push(
          this.alertNotificationGroupMappingService.deleteAlertNotificationGroupMapping(
            alertNotificationGroupMapping.id,
          ),
        );
      });

      // update alert info
      this.occupancyAlert = {
        alertType: this.occupancyAlert.alertType,
        id: this.occupancyAlert.id,
        siteId: this.occupancyAlert.siteId,
        organisationId: this.occupancyAlert.organisationId,
        name: this.occupancyAlertDetailsComponent.alertForm.get('name').value,
        emails,
        configurationDetails: {
          alertThreshold: this.occupancyAlertDetailsComponent.alertForm.get('alertThreshold').value / 100,
          warningThreshold: this.occupancyAlertDetailsComponent.alertForm.get('warningThreshold').value / 100,
          alertRecoveryThreshold:
            this.occupancyAlertDetailsComponent.alertForm.get('alertRecoveryThreshold').value / 100,
          warningRecoveryThreshold:
            this.occupancyAlertDetailsComponent.alertForm.get('warningRecoveryThreshold').value / 100,
          notifyAlertRecovery: this.occupancyAlertDetailsComponent.alertForm.get('notifyAlertRecovery').value,
          notifyWarningRecovery: this.occupancyAlertDetailsComponent.alertForm.get('notifyWarningRecovery').value,
        },
      };

      if (!emails?.length) {
        delete this.occupancyAlert.emails;
      }

      if (!this.occupancyAlert.configurationDetails['issueWarning']) {
        delete this.occupancyAlert.configurationDetails['warningThreshold'];
        delete this.occupancyAlert.configurationDetails['warningRecoveryThreshold'];
        delete this.occupancyAlert.configurationDetails['notifyWarningRecovery'];
      }

      requests.push(this.alertService.putAlert(this.occupancyAlert));

      if (this.occupancyAlertSelectMonitorComponent) {
        const linksToDelete = [...this.alertOccupancyMonitorMappings];
        const monitorsToAdd = [...this.occupancyAlertSelectMonitorComponent.selection.selected];

        linksToDelete.forEach((occupancyMonitorOccupancyAlertRule: AlertOccupancyMonitorMapping, indexDelete) => {
          const indexAdd = monitorsToAdd.findIndex(
            (m) => m?.id === occupancyMonitorOccupancyAlertRule.occupancyMonitorId,
          );
          if (indexAdd != -1) {
            delete linksToDelete[indexDelete];
            delete monitorsToAdd[indexAdd];
          }
        });

        // delete monitor links
        linksToDelete.forEach((occupancyMonitorOccupancyAlertRule) => {
          requests.push(
            this.alertOccupancyMonitorMappingService.deleteAlertOccupancyMonitorMapping(
              occupancyMonitorOccupancyAlertRule.id,
            ),
          );
        });

        // add monitor links
        monitorsToAdd.forEach((monitor) => {
          requests.push(
            this.alertOccupancyMonitorMappingService.putAlertOccupancyMonitorMapping({
              alertId: this.occupancyAlert.id,
              occupancyMonitorId: monitor.id,
            }),
          );
        });
      }

      if (requests.length) {
        forkJoin(requests)
          .pipe(
            switchMap((response) => {
              const requests: Observable<any>[] = [];
              if (
                this.occupancyAlertDetailsComponent.selectRecipientsComponent.createNotificationCheck &&
                this.occupancyAlertDetailsComponent.selectRecipientsComponent.selectedRecipients.length !== 1
              ) {
                requests.push(
                  this.alertNotificationGroupMappingService.putAlertNotificationGroupMapping({
                    alertId: this.occupancyAlert.id,
                    notificationGroupId: response[0].id,
                  }),
                );
                this.getRecipientsNotificationGroupsIDs(
                  this.occupancyAlertDetailsComponent.selectRecipientsComponent.selectedRecipients,
                ).forEach((notificationGroupId) => {
                  requests.push(
                    this.notificationGroupMappingService.putNotificationGroupNotificationGroup({
                      organisationId: this.occupancyAlert.organisationId,
                      parentId: response[0].id,
                      childId: notificationGroupId,
                    }),
                  );
                });
              } else {
                this.getRecipientsNotificationGroupsIDs(
                  this.occupancyAlertDetailsComponent.selectRecipientsComponent.selectedRecipients,
                ).forEach((notificationGroupId) => {
                  requests.push(
                    this.alertNotificationGroupMappingService.putAlertNotificationGroupMapping({
                      alertId: this.occupancyAlert.id,
                      notificationGroupId: notificationGroupId,
                    }),
                  );
                });
              }

              if (!requests.length) {
                return of([]);
              }

              return forkJoin(requests);
            }),
            finalize(() => {
              this.saving = false;
            }),
            takeUntil(this.ngUnsubscribe),
          )
          .subscribe({
            next: (_) => {
              this.close(true);
            },
            error: (error) => {
              this.notifyService.error(error);
            },
          });
      } else {
        this.close(false);
      }
    }
  }

  isValidForm(): boolean {
    return (
      this.occupancyAlertDetailsComponent.alertForm.valid &&
      (!this.occupancyAlertSelectMonitorComponent ||
        this.occupancyAlertSelectMonitorComponent.selection.selected.length > 0)
    );
  }

  getSitesNumber(): number {
    const siteIds = new Set();
    this.occupancyAlertRuleMonitors.forEach((om) => siteIds.add(om.siteId));
    return siteIds.size;
  }

  close(reload?: boolean): void {
    if (reload) {
      this.dialogRef.close('reload');
    } else {
      this.dialogRef.close();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
