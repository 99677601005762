import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { finalize, forkJoin, Observable, Subject, takeUntil } from 'rxjs';
import { DwellTimeReportService, DwellTimeRequestResponse, Organisation } from 'src/app/api';
import { UserConfirmationComponent } from 'src/app/components/general/user-confirmation/user-confirmation.component';
import { AccountService } from 'src/app/services/account.service';
import { DownloadFileService } from 'src/app/services/download-file.service';
import { NotifyService } from 'src/app/services/notify.service';
import { ReportsService } from 'src/app/services/reports.service';
import { AddDwellTimeReportComponent } from '../add-dwell-time-report/add-dwell-time-report.component';
// @ts-ignore
import moment from 'moment-timezone';

@Component({
  selector: 'app-download-dwell-time-data',
  templateUrl: './download-dwell-time-data.component.html',
  styleUrl: './download-dwell-time-data.component.scss',
})
export class DownloadDwellTimeDataComponent implements OnInit, OnDestroy {
  saving = false;

  dwellTimeRequestResponse: DwellTimeRequestResponse[] = [];
  organisationsMap: { [_: string]: Organisation } = {};
  selectedOrganisationId;
  creating: boolean;
  downloading: boolean;
  downloadButtonText: string;
  ngUnsubscribe = new Subject();

  tableDataSource = new MatTableDataSource<any>();
  tableHeaderNames = [
    'select',
    'createdAt',
    'preview',
    'startDate',
    'endDate',
    'status',
    'monitors',
    'organisationName',
  ];
  currentDateTimeMidnight = new Date(new Date().setHours(0, 0, 0, 0));
  range = new FormGroup({
    from: new FormControl(this.currentDateTimeMidnight),
    to: new FormControl(this.currentDateTimeMidnight),
  });

  selection = new SelectionModel<string>(true, []);
  selectedMonitors = new SelectionModel<string>(true, []);
  noOrganisationSelectedError = false;
  @ViewChild('tablePaginator') set paginator(pager: MatPaginator) {
    if (pager) {
      this.tableDataSource.paginator = pager;
    }
  }

  constructor(
    public accountService: AccountService,
    private df: DownloadFileService,
    private notifyService: NotifyService,
    private dwellTimeReportService: DwellTimeReportService,
    private ref: ChangeDetectorRef,
    private matDialog: MatDialog,
    public reportsService: ReportsService,
  ) {}

  ngOnInit(): void {
    if (!this.accountService.isSupport) {
      this.tableHeaderNames.pop();
    }
    this.dwellTimeRequestResponse = this.reportsService.dwellTimeRequestResponse;
    this.organisationsMap = this.accountService.organisationsMap;

    const dataSource: { [_: string]: string }[] = [];

    this.dwellTimeRequestResponse.forEach((dwellTime) => {
      dataSource.push({
        createdAt: moment(dwellTime.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        dwellTimeReportId: dwellTime.dwellTimeReportId,
        startDate: dwellTime.startDate,
        endDate: dwellTime.endDate,
        status: dwellTime.status,
        monitors: dwellTime.occupancyMonitorIds
          ? dwellTime.occupancyMonitorIds.map((id) => this.reportsService.occupancyMonitorsMap[id]?.name).join(',')
          : 'All monitors',
        organisationName: this.organisationsMap[dwellTime.organisationId]?.name,
        organisationId: this.organisationsMap[dwellTime.organisationId]?.id,
      });
    });

    this.tableDataSource.data = dataSource.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
    // this.tableDataSource.data = dataSource;
    this.setDownloading(false);
  }

  openNewDwellTimeReportDialog(): void {
    const dialogRef = this.matDialog.open(AddDwellTimeReportComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          window.location.reload();
        }
      },
    });
  }

  runSearch(ss: string): void {
    this.tableDataSource.filter = ss.trim().toLowerCase();
  }

  downloadCSV(toolTip?: MatTooltip) {
    if (this.selection.selected.length === 0) {
      this.noOrganisationSelectedError = true;
      toolTip.disabled = false;
      toolTip.show();
      return;
    }
    if (this.downloading) {
      return;
    }

    this.noOrganisationSelectedError = false;

    const roundedEndDate = new Date(this.range.value.to);
    roundedEndDate.setDate(roundedEndDate.getDate() + 1);

    const requests: Observable<any>[] = [];
    this.setDownloading(true);

    this.selection.selected.forEach((row: any) => {
      requests.push(this.dwellTimeReportService.getDwellTimeReport(row.dwellTimeReportId));
    });

    forkJoin(requests)
      .pipe(
        finalize(() => {
          this.setDownloading(false);
          this.ref.detectChanges();
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (reports) => {
          console.log(reports);
          var reportForDownload = [];

          reports.forEach((report) => {
            report.reportData.forEach((row) => {
              reportForDownload.push({
                ...row,
              });
            });
          });

          if (reportForDownload.length === 0) {
            this.notifyService.warning('No data available for the selected reports.');
            return;
          }

          let header = new Set<string>([]);
          reportForDownload.forEach((r) => Object.keys(r).forEach((k) => header.add(k)));

          this.df.downloadDataAsCSVFile(reportForDownload, `DwellTimeReportData.csv`, [...header]);
        },
        error: (error) => {
          this.notifyService.error(error);
        },
      });
  }

  openUserConfirmation(): void {
    const messages = `You can use the site opening hours to filter the monitors data.

    This allows you to refine the data, offering a deeper understanding
    of people count based on the operational hours of your sites.`;

    this.matDialog.open(UserConfirmationComponent, {
      data: { message: messages, isDelete: true },
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableDataSource.data.filter((r) => r.status === 'completed').length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.tableDataSource.data.forEach((row) => {
          if (row.status === 'completed') {
            this.selection.select(row);
          }
        });
  }

  setDownloading(state: boolean) {
    this.downloading = state;
    this.setDownloadButtonText();
  }

  setDownloadButtonText() {
    this.downloadButtonText = this.downloading ? 'Downloading...' : 'Download csv';
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
