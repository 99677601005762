<div class="u-island">
  <form class="o-form">
    <div class="o-heading--pica u-flush--bottom">
      <h1>{{ occupancyAlert.name }} occupancy alert summary.</h1>
    </div>
    <div>
      <h3 class="primer kirk u-flush--bottom">Thresholds</h3>
      <div class="c-alert">
        <div class="c-alert__threshold is-alert">
          <span class="is-text">
            <div class="is-primary-text">Alert threshold</div>
            <div class="is-secondary-text" *ngIf="occupancyAlert.configurationDetails['alertRecoveryThreshold']">
              Recovery threshold:
              {{ occupancyAlert.configurationDetails['alertRecoveryThreshold'] * 100 | number: '1.0-0' }}%
            </div>
          </span>
          <span class="is-count">
            {{ occupancyAlert.configurationDetails['alertThreshold'] * 100 | number: '1.0-0'
            }}<span class="is-pc">%</span>
          </span>
        </div>
        <div class="c-alert__threshold is-warning">
          <span class="is-text">
            <div class="is-primary-text">Warning threshold</div>
            <div class="is-secondary-text" *ngIf="occupancyAlert.configurationDetails['warningRecoveryThreshold']">
              Recovery threshold:
              {{ occupancyAlert.configurationDetails['warningRecoveryThreshold'] * 100 | number: '1.0-0' }}%
            </div>
          </span>
          <span class="is-count">
            <ng-container *ngIf="occupancyAlert.configurationDetails['warningThreshold']; else noWarning">
              {{ occupancyAlert.configurationDetails['warningThreshold'] * 100 | number: '1.0-0' }}
              <span>%</span>
            </ng-container>
            <ng-template #noWarning>
              <span class="is-off">Off</span>
            </ng-template>
          </span>
        </div>
      </div>
    </div>

    <ul class="o-list">
      <li class="o-list__item u-border--bottom">
        <div class="o-list__item-icon">
          <mat-icon>contacts</mat-icon>
        </div>
        <div class="o-list__item-text">
          <ng-container *ngIf="notificationGroupName; else recipientsList">
            <span class="is-secondary-text">Notification group</span>
            <span class="is-primary-text">{{ notificationGroupName }}</span>
          </ng-container>
          <ng-template #recipientsList>
            <span class="is-secondary-text">Recipients ({{ selectedRecipients.length }})</span>
            <span class="is-primary-text c-recipients">
              <span class="c-recipients__item" *ngFor="let recipient of selectedRecipients">{{
                getRecipientName(recipient)
              }}</span>
            </span>
          </ng-template>
        </div>
      </li>
    </ul>

    <!-- <div>
      <h3 class="primer kirk u-flush--bottom">
        {{ monitors.length }} occupancy {{ monitors.length | pluraliseWord : 'monitor' }} selected
      </h3>
      <ul class="o-list">
        <li class="o-list__item" *ngFor="let monitor of monitors">
          <div class="o-list__item-icon">
            <mat-icon class="is-icon is-online">done</mat-icon>
          </div>
          <div class="o-list__item-text">
            <span class="is-primary-text">{{ monitor.name }}</span>
          </div>
        </li>
      </ul>
    </div> -->

    <div class="c-alert-summary__item">
      <div class="is-icon">
        <mat-icon class="is-icon">apartment</mat-icon>
      </div>
      <div class="is-label">Sites included</div>
      <ng-container *ngIf="!allSites; else allSitesIncluded">
        <div class="is-detail is-selection">
          <ul class="c-selection-list">
            <li class="c-selection-list__item">
              {{ site.name }}
            </li>
          </ul>
        </div>
      </ng-container>
      <ng-template #allSitesIncluded>
        <div class="is-all is-detail">All sites</div>
      </ng-template>
    </div>

    <div class="c-alert-summary__item">
      <div class="is-icon">
        <mat-icon class="is-icon">view_quilt</mat-icon>
      </div>
      <div class="is-label">Monitors selected</div>
      <ng-container *ngIf="!allSites && !allMonitors; else allMonitorsIncluded">
        <div class="is-detail">
          <div class="c-camera__item">
            <div class="c-alert-detail__title">
              {{ site.name }}
              <span class="info-text">
                {{ selectedMonitors.length }} of {{ monitors.length }} {{ monitors.length | pluraliseWord: 'monitor' }}.
              </span>
            </div>
            <ul class="o-list c-alert-detail__list">
              <li *ngFor="let monitor of selectedMonitors">{{ monitor.name }}</li>
            </ul>
          </div>
        </div>
      </ng-container>
      <ng-template #allMonitorsIncluded>
        <div class="is-all is-detail">All monitors</div>
      </ng-template>
    </div>
  </form>
</div>
