import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss'],
})
export class StepComponent {
  @Input() nextStepText: string = 'CONTINUE';
  @Input() previousStepText: string = 'BACK';
  @Input() stepTitle: string;
  @Input() isBackable: boolean = true;
  @Input() hasNextStep: boolean = true;
  @Input() processingStep: boolean;
  @Input() disabledNextStep: boolean;
  @Input() isStepButtonBold = false;
  @Input() isStepButtonBordered = true;

  @Output() closeStep: EventEmitter<void> = new EventEmitter();
  @Output() openNextStep: EventEmitter<void> = new EventEmitter();
  @Output() openPreviousStep: EventEmitter<void> = new EventEmitter();

  constructor() {}

  onClose(): void {
    this.closeStep.emit();
  }

  goToNextStep(): void {
    this.openNextStep.emit();
  }

  goToPreviousStep(): void {
    this.openPreviousStep.emit();
  }
}
