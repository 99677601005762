import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, filter, takeUntil } from 'rxjs';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-notifications-side-menu',
  templateUrl: './notifications-side-menu.component.html',
  styleUrls: ['./notifications-side-menu.component.scss'],
})
export class NotificationsSideMenuComponent implements OnInit, OnDestroy {
  public dialogRef = null;

  isSupport: boolean;

  ngUnsubscribe = new Subject();

  menuSpec: any = {
    // 'camera-alerts': {
    //   icon: 'no_photography',
    //   primary_text: 'Camera poor connectivity alerts',
    //   secondary_text: 'Poor connectivity issues',
    // },
    'camera-alerts-offline': {
      icon: 'no_photography',
      primary_text: 'Camera alerts',
      secondary_text: 'Offline camera notifications',
    },
    'camera-summaries': {
      icon: 'emergency',
      primary_text: 'Camera health summaries',
      secondary_text: 'Daily or weekly system updates',
    },
    'monitor-alerts': {
      icon: 'notifications_active',
      primary_text: `Monitor alerts`,
      // secondary_text: 'Occupancy & people count',
      secondary_text: 'Create and manage occupancy alerts',
    },
    'notification-groups': {
      icon: 'contacts',
      primary_text: 'Notification groups',
      secondary_text: 'Manage notification groups',
    },
  };

  selected = '';

  constructor(
    private router: Router,
    private injector: Injector,
    private accountService: AccountService,
  ) {
    this.isSupport = this.accountService.isSupport;
    this.dialogRef = this.injector.get(MatDialogRef, null);
    this.selected = this.router.url.split('/')[2];
  }

  ngOnInit(): void {
    if (!this.accountService.occupancyLicence) {
      delete this.menuSpec['monitor-alerts'];
    }

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (event: NavigationEnd) => {
          this.selected = event.url.split('/')[2];
        },
      });
  }

  listPanles(): string[] {
    return Object.keys(this.menuSpec);
  }

  navigateToPanel(panel: string): void {
    const routerLink = 'alerts/' + panel;
    this.router.navigate([routerLink]).then(() => this.close());
  }

  close() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
