<mat-horizontal-stepper class="dialog-stepper" #stepper>
  <mat-step>
    <app-step [stepTitle]="stepsTitle" [isBackable]="false" (closeStep)="close()" [hasNextStep]="false">
      <div class="o-modal-full-page__body c-modal-full-page__body">
        <div class="c-body__left-col-wrapper">
          <div class="c-body__left-col-content">
            <app-alert-information
              [camerasOffline]="getCamerasWithIssues()"
              [allCamerasQuantity]="cameras.length"
              [allSitesQuantity]="getCamerasSites()"
              [camerasPronedToIssues]="camerasPronedToIssues.length"
              [offlineCamerasWithIssues]="offlineCamerasWithIssues.length"
              [issueType]="issueType"
              (nextStep)="goToSecondStep()"
              (closeDialog)="close()"
            ></app-alert-information>
          </div>
        </div>
        <div class="c-body__right-col-wrapper">
          <div class="c-body__right-col-content">
            <app-cameras-information
              [onlineCamerasWithoutAlertsQuantity]="camerasPronedToIssues.length - offlineCamerasWithIssues.length"
              [cameras]="offlineCamerasWithIssues"
              [issueType]="issueType"
              (closeDialog)="close()"
            ></app-cameras-information>
          </div>
        </div>
      </div> </app-step
  ></mat-step>
  <mat-step>
    <app-step [stepTitle]="stepsTitle" [isBackable]="false" (closeStep)="close()" [hasNextStep]="false">
      <div class="o-modal-full-page__body c-modal-full-page__body">
        <div class="c-body__left-col-wrapper">
          <div class="c-body__left-col-content">
            <div class="c-pause-desc">
              <p>
                Sometimes you do not need a camera to be active for a period of time. In this situation it's recommended
                that you pause the camera.
              </p>
              <ul class="o-list">
                <li class="o-list__item">
                  <div class="o-list__item-icon">
                    <mat-icon class="c-icon-arrow">arrow_forward</mat-icon>
                  </div>
                  <div class="o-list__item-text">Stop receiving offline alerts for the camera</div>
                </li>
                <li class="o-list__item">
                  <div class="o-list__item-icon">
                    <mat-icon class="c-icon-arrow">arrow_forward</mat-icon>
                  </div>
                  <div class="o-list__item-text">Improved historical reporting for monitors using the camera</div>
                </li>
                <li class="o-list__item">
                  <div class="o-list__item-icon">
                    <mat-icon class="c-icon-arrow">arrow_forward</mat-icon>
                  </div>
                  <div class="o-list__item-text">A paused camera can be re-activated at any time</div>
                </li>
                <li class="o-list__item">
                  <div class="o-list__item-icon">
                    <mat-icon class="c-icon-arrow">arrow_forward</mat-icon>
                  </div>
                  <div class="o-list__item-text">All historical camera data is saved</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="c-body__right-col-wrapper">
          <div class="c-body__right-col-content">
            <div class="c-paused-camera-list">
              <app-paused-camera-info [cameras]="cameras"></app-paused-camera-info>
            </div>
          </div>
        </div>
      </div>
    </app-step>
  </mat-step>
</mat-horizontal-stepper>
