<div class="o-card has-no-border">
  <div class="c-card__body">
    <div class="is-media">
      <img src="/assets/images/site-museum.png" class="is-grayscale" alt="Building" />
    </div>

    <h2 class="o-card__body-text-message">You have not been given access to any sites.</h2>
  </div>

  <div class="o-card__action has-no-border">
    <a [href]="adminEmail" class="o-button">
      Contact your admin for help
      <mat-icon class="is-icon-right">arrow_forward</mat-icon>
    </a>
  </div>
</div>
