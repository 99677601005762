<div class="c-container">
  <div class="c-actions">
    <app-filter placeholder="Filter users" (search)="runSearch($event)" class="o-search__input"></app-filter>

    <div class="o-toggle__row">
      <div class="is-legend">
        <mat-icon>people_alt</mat-icon>
        Roles
      </div>
      <div class="[ search-check is-check ] o-toggle__item">
        <input type="checkbox" id="admin-check" [(ngModel)]="showAdmin" (change)="filterUsers()" name="showAdmin" />
        <label for="admin-check">Admin</label>
      </div>
      <div class="[ search-check is-check ] o-toggle__item">
        <input type="checkbox" id="members-check" [(ngModel)]="showMember" (change)="filterUsers()" name="showMember" />
        <label for="members-check">Members</label>
      </div>
      <button class="is-button o-button has-outline" (click)="openAddEditUserDialog()">
        <mat-icon class="is-icon-left">person_add</mat-icon>
        ADD USER
      </button>
    </div>
  </div>
  <div class="c-container-cards" *ngIf="users">
    <div class="o-user-card is-active c-user-card" *ngFor="let user of filteredUsers">
      <div class="o-user-card__title">
        <div class="is-avatar">
          <mat-icon>person</mat-icon>
        </div>
        <div class="is-title-role">
          <h2>
            {{ user.name }}
          </h2>
          <h3>
            {{ formatUserRoles(user) }}
          </h3>
        </div>
        <div class="o-user-card__actions">
          <mat-icon
            class="is-action"
            (click)="openAddEditUserDialog(user)"
            matTooltip="Edit user"
            [matTooltipPosition]="'above'"
            >edit
          </mat-icon>
        </div>
      </div>
      <div class="o-user-card__body">
        <a>{{ user.email }}</a>
      </div>
    </div>
  </div>
</div>
