<mat-horizontal-stepper class="dialog-stepper" #stepper>
  <mat-step>
    <app-step
      [stepTitle]="stepsTitle"
      [isBackable]="false"
      (closeStep)="close()"
      [disabledNextStep]="
        !cameraAlertAndSummaryNameAndSitesComponent?.selectedOrganisation ||
        cameraAlertAndSummaryNameAndSitesComponent?.alertName === '' ||
        (cameraAlertAndSummaryNameAndSitesComponent?.alertMode === 'site' &&
          !cameraAlertAndSummaryNameAndSitesComponent?.selectedSite)
      "
      (openNextStep)="goTosecondStep()"
    >
      <app-camera-alert-and-summary-name-and-sites
        [sites]="sites"
        [alertOrSummary]="summaryAlert"
        [resourceType]="'summary'"
        (closeDialog)="close(true)"
      >
      </app-camera-alert-and-summary-name-and-sites>
    </app-step>
  </mat-step>

  <mat-step>
    <ng-template matStepContent>
      <app-step
        [stepTitle]="stepsTitle"
        (closeStep)="close()"
        [disabledNextStep]="!cameraAlertAndSummaryCamerasComponent?.selection?.selected.length"
      >
        <app-camera-alert-and-summary-cameras
          [cameras]="filteredCameras"
          [resourceType]="'summary'"
          [selectedCameras]="summaryAlertCameraPositions"
        >
        </app-camera-alert-and-summary-cameras>
      </app-step>
    </ng-template>
  </mat-step>
  <mat-step #cameraSummarySettings>
    <ng-template matStepContent>
      <app-step
        [stepTitle]="stepsTitle"
        [isBackable]="false"
        (closeStep)="close()"
        [disabledNextStep]="!cameraSummarySettingsComponent.summaryForm.valid"
        (openNextStep)="setCameraHealthSummaryConfiguration()"
      >
        <app-camera-summary-settings
          [users]="filteredUsers"
          [notificationGroups]="filteredNotificationGroups"
          [organisationId]="organisationId"
          [summaryAlert]="summaryAlert"
          [summaryAlertNotificationGroups]="summaryAlertNotificationGroups"
          #cameraSummarySettingsComponent
        >
        </app-camera-summary-settings>
      </app-step>
    </ng-template>
  </mat-step>
  <mat-step>
    <ng-template matStepContent>
      <app-step
        [stepTitle]="stepsTitle"
        (closeStep)="close()"
        [nextStepText]="'SAVE'"
        [processingStep]="saving"
        (openNextStep)="addSummaryAlert()"
      >
        <app-camera-alert-and-summary-details-review
          [resourceType]="'summary'"
          [site]="cameraAlertAndSummaryNameAndSitesComponent.selectedSite"
          [allSites]="cameraAlertAndSummaryNameAndSitesComponent.alertMode === 'organisaiton'"
          [selectedCameras]="cameraAlertAndSummaryCamerasComponent?.selection.selected"
          [cameras]="filteredCameras"
          [allCameras]="cameraAlertAndSummaryCamerasComponent?.isAllSelected()"
          [alertOrSummary]="summaryAlert"
          [notificationGroupName]="notificationGroupName"
          [selectedRecipients]="cameraSummarySettingsComponent.selectRecipientsComponent.selectedRecipients"
        ></app-camera-alert-and-summary-details-review>
      </app-step>
    </ng-template>
  </mat-step>
  <!-- <mat-step>
    <app-step [stepTitle]="stepsTitle" (closeStep)="close()" [hasNextStep]="false" [isBackable]="false">
      <app-camera-alert-and-summary-add-the-other-resource
        [resourceType]="'summary'"
        (closeDialog)="close(true)"
      ></app-camera-alert-and-summary-add-the-other-resource>
    </app-step>
  </mat-step> -->
</mat-horizontal-stepper>
