<app-step
  [stepTitle]="'Decommission camera'"
  [isBackable]="false"
  [nextStepText]="'Decommission'"
  (openNextStep)="decommission()"
  (closeStep)="close()"
>
  <div class="o-banner-alert is-error u-margin--bottom">
    <span class="is-message">
      <h2 class="u-margin--bottom primer kirk">You are about to decommission camera: {{ cameraName }}</h2>
      <h3>All people counting and occupancy monitoring will stop.</h3>
      <h3>You will be sent information about returning the camera to HoxtonAi.</h3>
    </span>
  </div>
  <ng-container *ngIf="occupancyMonitors.length || peopleCountMonitors.length || peelOffMonitors.length">
    <h2 class="u-margin--top primer kirk">We recommend removing the camera from the following resources.</h2>
    <ul class="o-list resoureces-to-remove">
      <li class="o-list__item" (click)="toggleDeleteLinkedResources()">
        <div class="o-list__item-icon">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            [checked]="deleteLinkedResources"
            (change)="toggleDeleteLinkedResources()"
          ></mat-checkbox>
        </div>
        <div class="o-list__item-text">
          <h2 class="is-primary-text">Remove camera from all resources</h2>
          <h2 class="is-secondary-text"></h2>
        </div>
      </li>
      <li class="o-list__item" *ngFor="let occupancyMonitor of occupancyMonitors">
        <div class="o-list__item-icon">
          <mat-icon>groups</mat-icon>
        </div>
        <div class="o-list__item-text">
          <h2 class="is-secondary-text">Occupancy monitor</h2>
          <h2 class="is-primary-text">{{ occupancyMonitor.name }}</h2>
        </div>
      </li>
      <li class="o-list__item" *ngFor="let peopleCountMonitor of peopleCountMonitors">
        <div class="o-list__item-icon">
          <mat-icon>transfer_within_a_station</mat-icon>
        </div>
        <div class="o-list__item-text">
          <h2 class="is-secondary-text">People count monitor</h2>
          <h2 class="is-primary-text">{{ peopleCountMonitor.name }}</h2>
        </div>
      </li>
      <ng-container>
        <li class="o-list__item" *ngFor="let peelOffMonitor of peelOffMonitors">
          <div class="o-list__item-icon">
            <mat-icon>fork_left</mat-icon>
          </div>
          <div class="o-list__item-text">
            <h2 class="is-secondary-text">Peel off monitor</h2>
            <h2 class="is-primary-text">{{ peelOffMonitor.name }}</h2>
          </div>
        </li>
      </ng-container>
      <li class="o-list__item" *ngFor="let alert of alerts">
        <div class="o-list__item-icon">
          <mat-icon>{{ alert.alertType === 'offline_alert' ? 'no_photography' : 'emergency' }}</mat-icon>
        </div>
        <div class="o-list__item-text">
          <h2 class="is-secondary-text">
            {{ alert.alertType === 'offline_alert' ? 'Camera alert' : 'Camera health summary' }}
          </h2>
          <h2 class="is-primary-text">{{ alert.name }}</h2>
        </div>
      </li>
    </ul>
  </ng-container>
</app-step>
