<div class="o-router-full-page-wrapper" *ngIf="(hasActiveChildRoute$ | async) && (isLoading$ | async) === false">
  <nav class="navigation is-breadcrumb">
    <ul class="o-breadcrumb">
      <li class="o-breadcrumb__item">
        <a routerLink="/infrastructure" class="is-link"> <mat-icon class="is-icon">dashboard</mat-icon></a>
      </li>
      <li class="o-breadcrumb__item">
        <a routerLink="/reporting" class="is-link"> Reporting </a>
      </li>
      <li class="o-breadcrumb__item">{{ activeChildRoute }}</li>
    </ul>
  </nav>
  <div class="c-router-outlet">
    <router-outlet></router-outlet>
  </div>
</div>

<div class="is-main o-view-2" *ngIf="(hasActiveChildRoute$ | async) === false">
  <div class="auth-overlay" *ngIf="isLoading$ | async; else notLoading">
    <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
      <p style="font-size: 2rem; font-weight: 500">Loading...</p>
      <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>
  </div>

  <ng-template #notLoading>
    <app-navigation></app-navigation>

    <div class="c-reports__container">
      <div class="c-data-explorer">
        <div class="c-data-explorer__title-wrapper">
          <div class="c-data-explorer__title">Data explorer</div>
          <div class="c-data-explorer__subtitle">Advanced analysis of your people count and occupancy data.</div>
          <i class="c-image-analysis"></i>
          <div class="is-img">
            <svg
              width="205"
              viewBox="0 0 205 131"
              height="131"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <defs>
                <path id="a" d="M0 0h205v131H0z" />
              </defs>
              <g transform="translate(0 .572)" fill="none" fill-rule="evenodd">
                <mask id="b" fill="#fff">
                  <use xlink:href="#a" />
                </mask>
                <path
                  stroke="#FDC00B"
                  stroke-width="3"
                  mask="url(#b)"
                  d="m-2.518 101.101 16.957 24.835L45.82 92.272l29.38 19.03 28.793-38.267L133.08 44.18l30.262-40.233 29.38 11.697L208 48.989"
                />
                <path
                  stroke="#CCDA3A"
                  stroke-width="3"
                  mask="url(#b)"
                  d="M-8 117.92H7.74l30.715-38.473 30.715-6.412 33.171-22.443 27.336-27.252 30.101 14.428 32.25 6.412L208 36.165"
                />
                <circle stroke="#FFF" fill="#CCDA3A" cx="6" cy="117.558" r="3.5" />
                <circle stroke="#FFF" fill="#FDC00B" cx="46" cy="92.558" r="3.5" />
                <circle stroke="#FFF" fill="#FDC00B" cx="14" cy="125.558" r="3.5" />
                <circle stroke="#FFF" fill="#FDC00B" cx="75" cy="111.558" r="3.5" />
                <circle stroke="#FFF" fill="#FDC00B" cx="101" cy="74.558" r="3.5" />
                <circle stroke="#FFF" fill="#FDC00B" cx="132" cy="44.558" r="3.5" />
                <circle stroke="#FFF" fill="#FDC00B" cx="163" cy="4.558" r="3.5" />
                <circle stroke="#FFF" fill="#FDC00B" cx="192" cy="14.558" r="3.5" />
                <circle stroke="#FFF" fill="#CCDA3A" cx="35" cy="80.558" r="3.5" />
                <circle stroke="#FFF" fill="#CCDA3A" cx="66" cy="73.558" r="3.5" />
                <circle stroke="#FFF" fill="#CCDA3A" cx="101" cy="48.558" r="3.5" />
                <circle stroke="#FFF" fill="#CCDA3A" cx="190" cy="44.558" r="3.5" />
                <circle stroke="#FFF" fill="#CCDA3A" cx="130" cy="23.558" r="3.5" />
                <circle stroke="#FFF" fill="#CCDA3A" cx="158" cy="37.558" r="3.5" />
                <g>
                  <path
                    d="M33.222 31.558h65.556c1.227 0 2.222.995 2.222 2.222v32.778a2.222 2.222 0 0 1-2.222 2.222H68.343l-2.969 2.778-3.61-2.778H33.222A2.222 2.222 0 0 1 31 66.558V33.78c0-1.227.995-2.222 2.222-2.222Z"
                    fill="#FFF"
                  />
                  <path
                    stroke="#737E8C"
                    stroke-width="2.4"
                    stroke-linecap="round"
                    d="M40.24 40.169h50.4M40.24 47.947h32.48M40.24 55.725H82.8"
                  />
                </g>
              </g>
            </svg>
          </div>
        </div>
        <div
          class="c-data-explorer__description"
          [ngClass]="{ 'large-gap': accountService.dataExplorerLicence && !reportsService.hasDataExplorer }"
        >
          <ng-container *ngIf="!accountService.dataExplorerLicence; else hasDataExplorerLicence">
            <div class="long-primer--bold u-flush--bottom">Data explorer is not enabled for your organisation.</div>
            <div>Contact the sales team about Data explorer and be able to:</div>
            <ul class="o-list">
              <li class="o-list__item">
                <div class="o-list__item-icon">
                  <mat-icon class="is-icon is-online">done</mat-icon>
                </div>
                <div>
                  <h2>Compare time periods</h2>
                </div>
              </li>
              <li class="o-list__item">
                <div class="o-list__item-icon">
                  <mat-icon class="is-icon is-online">done</mat-icon>
                </div>
                <div>
                  <h2>Compare multiple sites and monitors</h2>
                </div>
              </li>
              <li class="o-list__item">
                <div class="o-list__item-icon">
                  <mat-icon class="is-icon is-online">done</mat-icon>
                </div>
                <div>
                  <h2>View traffic by hour, day, month or season</h2>
                </div>
              </li>
            </ul>
            <a class="o-button is-primary" href="mailto:support@hoxton.ai" target="_blank">
              <mat-icon class="is-icon-left">mail</mat-icon>
              Contact sales team
            </a>
          </ng-container>

          <ng-template #hasDataExplorerLicence>
            <ul class="o-list" *ngIf="reportsService.hasDataExplorer; else noDataExplorerAccess">
              <a
                class="o-list__item"
                *ngFor="let item of reportsService.dataExplorerMenuItems"
                [routerLink]="['/reporting', 'data-explorer']"
                [queryParams]="{
                  tab: item,
                }"
              >
                <div class="o-list--is-link">
                  <div class="o-list__item-icon">
                    <mat-icon class="is-icon">{{ reportsService.dataExplorerMenuSpec[item].icon }}</mat-icon>
                  </div>
                  <div class="o-list__item-text">
                    <h2 class="is-primary-text">{{ reportsService.dataExplorerMenuSpec[item].primary_text }}</h2>
                    <h2 class="is-secondary-text">{{ reportsService.dataExplorerMenuSpec[item].secondary_text }}</h2>
                  </div>
                  <div class="o-list__item-secondary-icon">
                    <mat-icon> chevron_right</mat-icon>
                  </div>
                </div>
              </a>
            </ul>
            <ng-template #noDataExplorerAccess>
              <div class="long-primer--bold u-flush--bottom">You do not have access to Data explorer.</div>
              <div>Speak to your Admin about getting access.</div>
              <div>Or contact the sales team.</div>
              <a class="o-button is-primary" href="mailto:support@hoxton.ai" target="_blank">
                <mat-icon class="is-icon-left">mail</mat-icon>
                contact sales team
              </a>
            </ng-template>
          </ng-template>
        </div>
      </div>
      <div class="c-report-downloads">
        <div class="c-report-downloads__title-wrapper">
          <div class="c-report-downloads__title">Report downloads</div>
          <div class="c-report-downloads__subtitle">Download your data as a CSV file.</div>
          <i class="c-image-analysis"></i>
        </div>
        <div class="c-report-downloads__description">
          <ul class="o-list">
            <a
              class="o-list__item"
              *ngFor="let item of reportsService.reportDownloadMenuItems"
              [routerLink]="['/reporting', 'report-csv-download']"
              [queryParams]="{
                tab: item,
              }"
            >
              <div class="o-list--is-link">
                <div class="o-list__item-icon">
                  <mat-icon class="is-icon">{{ reportsService.reportDownloadMenuSpec[item].icon }}</mat-icon>
                </div>
                <div class="o-list__item-text">
                  <h2 class="is-primary-text">{{ reportsService.reportDownloadMenuSpec[item].primary_text }}</h2>
                  <h2 class="is-secondary-text">{{ reportsService.reportDownloadMenuSpec[item].secondary_text }}</h2>
                </div>
                <div class="o-list__item-secondary-icon">
                  <mat-icon>chevron_right</mat-icon>
                </div>
              </div>
            </a>
          </ul>
        </div>
      </div>
    </div>

    <div class="c-people-count-monitors-count">
      <div class="long-primer kirk">People count</div>
      <div>
        <div class="c-people-count-plots-header" *ngIf="reportsService.peopleCountMonitors.length">
          <div class="c-plots-header-description">
            <div class="primer kirk u-flush--bottom">People count</div>
            <div class="c-text-people-count-date">
              {{
                (reportsService.isPeopleCountDaily
                  ? reportsService.peopleCountMonitorDataDailyTime
                  : reportsService.peopleCountMonitorDataWeeklyTime
                ) | date: 'EE dd MMM y'
              }}
              <ng-container *ngIf="!reportsService.isPeopleCountDaily">
                -
                {{ reportsService.peopleCountMonitorDataWeeklyTime.getTime() + 6.048e8 | date: 'EE dd MMM y' }}
              </ng-container>
            </div>
          </div>
          <div class="c-plots-controls">
            <div class="c-plots-legends">
              <div class="c-plots-legend">Selected week</div>
              <div class="c-plots-legend">Previous week</div>
            </div>
            <div class="c-plots-time-options o-button-flush-group">
              <button
                (click)="dailyTimePicker.open()"
                class="o-button is-small"
                [ngClass]="reportsService.isPeopleCountDaily ? 'is-primary' : 'is-muted is-contained'"
              >
                <span class="material-icons is-icon-left" *ngIf="reportsService.isPeopleCountDaily"> event </span>
                <div class="o-button__date-label">
                  Daily
                  <span *ngIf="reportsService.isPeopleCountDaily">
                    {{ reportsService.peopleCountMonitorDataDailyTime | date: 'dd/MM/yy' }}</span
                  >
                </div>
              </button>
              <input
                class="c-hide"
                matInput
                [(ngModel)]="reportsService.peopleCountMonitorDataDailyTime"
                (dateChange)="toggleTimingPeopleCount(true)"
                [matDatepicker]="dailyTimePicker"
              />
              <mat-datepicker #dailyTimePicker></mat-datepicker>
              <button
                (click)="weeklyTimePicker.open()"
                class="o-button is-small"
                [ngClass]="!reportsService.isPeopleCountDaily ? 'is-primary' : 'is-muted is-contained'"
              >
                <span class="material-icons is-icon-left" *ngIf="!reportsService.isPeopleCountDaily"> event </span>
                <div class="o-button__date-label">
                  Weekly
                  <span class="c-text-date" *ngIf="!reportsService.isPeopleCountDaily">{{
                    reportsService.peopleCountMonitorDataWeeklyTime | date: 'dd/MM/yy'
                  }}</span>
                </div>
              </button>
              <mat-date-range-input class="c-hide" [rangePicker]="weeklyTimePicker">
                <input
                  matStartDate
                  [(ngModel)]="reportsService.peopleCountMonitorDataWeeklyTime"
                  (dateChange)="updatePeopleCountMonitorDataWeeklyTime(); weeklyTimePicker.close()"
                />
                <input
                  matEndDate
                  [(ngModel)]="peopleCountMonitorDataWeeklyTimeEndDate"
                  (dateChange)="updatePeopleCountMonitorDataWeeklyTime(); weeklyTimePicker.close()"
                />
              </mat-date-range-input>
              <mat-date-range-picker #weeklyTimePicker [restoreFocus]="true"></mat-date-range-picker>

              <mat-checkbox [(ngModel)]="reportsService.useOpeningHoursPeopleCountMonitors"
                >Filter by opening hours</mat-checkbox
              >
            </div>
          </div>
        </div>
        <div *ngIf="reportsService.isLoadingPeopleCountOrderData | async; else notLoadingPeopleCount">
          <div
            class="c-people-count-monitors-count"
            fxFill
            fxLayoutAlign="center center"
            fxLayout="column"
            fxLayoutGap="1.5625rem"
          >
            <div class="dot-flashing"></div>
          </div>
        </div>
        <ng-template #notLoadingPeopleCount>
          <div
            class="c-people-count-plots-content"
            *ngIf="reportsService.peopleCountMonitors.length; else noPeopleCountMonitors"
          >
            <app-people-count-monitor-card
              *ngFor="let peopleCountMonitor of reportsService.peopleCountMonitors.slice(0, 3)"
              [site]="reportsService.sitesMap[peopleCountMonitor.siteId]"
              [dataStartDate]="
                reportsService.isPeopleCountDaily
                  ? reportsService.peopleCountMonitorDataDailyTime
                  : reportsService.peopleCountMonitorDataWeeklyTime
              "
              [useOpeningHours]="reportsService.useOpeningHoursPeopleCountMonitors"
              [peopleCountMonitor]="peopleCountMonitor"
            ></app-people-count-monitor-card>
          </div>
          <ng-template #noPeopleCountMonitors>
            <!-- <div class="o-media">
                        <img src="assets/images/people-count.png" alt="people-count" />
                      </div> -->
            <div class="o-banner-message">
              <div class="o-banner-message__media">
                <img src="/assets/images/people-count-placeholder.png" alt="" class="is-image" />
              </div>
              <div class="o-banner-message__text">
                <h4 class="pica u-flush--bottom">You don't have any people count monitors.</h4>
                <p class="primer">Setup a camera to start.</p>
                <button mat-button disabled *ngIf="!hasSites; else addCamera">
                  <mat-icon>add_a_photo</mat-icon>
                  Add camera
                </button>
                <ng-template #addCamera>
                  <a [routerLink]="['/cameras']" class="o-button is-primary">
                    <mat-icon class="mat-icon is-icon-left material-icons" aria-hidden="true" data-mat-icon-type="font"
                      >add_a_photo
                    </mat-icon>
                    Add camera
                  </a>
                </ng-template>
              </div>
            </div>
          </ng-template>
        </ng-template>
        <a
          [routerLink]="['/reporting', 'people-count']"
          class="o-button u-hard--left u-hard--right c-ppl-count-view-monitors"
          *ngIf="reportsService.peopleCountMonitors.length"
        >
          VIEW ALL MONITORS
          <mat-icon class="is-icon-right">arrow_forward</mat-icon>
        </a>
      </div>
    </div>

    <ng-container *ngIf="accountService.occupancyLicence">
      <div class="c-capacity-management">
        <div class="c-capacity-management__cover">
          <div class="is-title">Capacity management</div>
          <img src="assets/images/ticket.png" alt="" class="is-image" />
        </div>
        <div class="c-capacity-management__monitors">
          <div class="primer kirk">Occupancy monitors</div>
          <app-filter
            (search)="capacityManagementSearchString = $event; filterCapacityManagementMonitors()"
            class="o-search__input u-margin--bottom"
            placeholder="Search sites and monitor names"
          ></app-filter>
          <ul #capacityManagementList class="o-list c-monitors-list" (scroll)="onCapacityManagementScroll()">
            <li
              *ngFor="let occupancyMonitor of filteredCapacityManagementMonitors"
              (click)="selectedCapacityManagementMonitor = occupancyMonitor"
              class="o-list__item c-monitors-list__item"
              [class.is-active]="occupancyMonitor.id === selectedCapacityManagementMonitor?.id"
            >
              <div class="o-list__item-icon">
                <span class="material-icons">groups</span>
              </div>
              <div class="o-list__item-text">
                <span class="minion--caps is-secondary-text">{{
                  reportsService.sitesMap[occupancyMonitor.siteId].name
                }}</span>
                <span class="c-monitors-list__name">{{ occupancyMonitor.name }}</span>
                <span class="c-monitors-list__occupancy"
                  >Safe occupancy {{ occupancyMonitor.capacity ? occupancyMonitor.capacity : 'not set' }}</span
                >
              </div>
              <mat-icon
                *ngIf="occupancyMonitor.id === selectedCapacityManagementMonitor?.id"
                class="c-monitors-list__icon is-icon"
                >arrow_forward
              </mat-icon>
            </li>
          </ul>
        </div>

        <div
          [ngClass]="{
            'c-occupancy-distribution-wrapper':
              !selectedCapacityManagementMonitor || !capacityManagementData[selectedCapacityManagementMonitor?.id],
          }"
        >
          <ng-container
            *ngIf="
              selectedCapacityManagementMonitor &&
                capacityManagementData[selectedCapacityManagementMonitor.id] &&
                capacityManagementData[selectedCapacityManagementMonitor.id][
                  this.reportsService.useOpeningHoursCapacityManagementMonitors | booleanToString
                ];
              else noSelectedCapacityManagementMonitor
            "
          >
            <app-capacity-management-brief
              [occupancyMonitor]="selectedCapacityManagementMonitor"
              [capacityManagementData]="
                capacityManagementData[selectedCapacityManagementMonitor.id][
                  this.reportsService.useOpeningHoursCapacityManagementMonitors | booleanToString
                ]
              "
              (filterByOpeningHours)="filterByOpeningHours()"
            ></app-capacity-management-brief>
          </ng-container>
          <ng-template #noSelectedCapacityManagementMonitor>
            <img src="assets/images/chart-placeholder.png" alt="no-data" />
          </ng-template>
        </div>
      </div>
    </ng-container>
  </ng-template>
</div>
