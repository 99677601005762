import { Component, HostListener, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss'],
})
export class FilterDialogComponent {
  placeholder: string = 'Filter';
  searchString: string = '';

  @HostListener('document:keydown.enter', ['$event'])
  close(): void {
    this.dialogRef.close();
  }

  constructor(
    public dialogRef: MatDialogRef<FilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (this.data?.hasOwnProperty('placeholder')) {
      this.placeholder = this.data.placeholder;
    }
    if (this.data?.hasOwnProperty('searchString')) {
      this.searchString = this.data.searchString;
    }
  }

  clearSearch(): void {
    this.searchString = '';
  }
}
