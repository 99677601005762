<app-step
  [stepTitle]="title"
  [nextStepText]="'Save'"
  [isBackable]="false"
  [isStepButtonBold]="true"
  [isStepButtonBordered]="false"
  (openNextStep)="save()"
  (closeStep)="close()"
>
  <div class="c-modal-content">
    <div class="c-modal-main-centered">
      <ng-container *ngIf="offlineOrSummary.length; else noResources">
        <div class="c-title-select-alerts">
          Select {{ resourceType === 'alert' ? 'alerts' : 'summaries' }} to add your camera to.
        </div>
        <div>
          <!-- <mat-checkbox
            (click)="$event.stopPropagation()"
            [checked]="AllCameraAlerts"
            (change)="changeAllCamerasAlert()"
            class="c-checkbox-all-alerts"
          >
            <span class="c-text-all-cameras-alert"> All cameras alert </span>
          </mat-checkbox> -->
          <div class="c-checkboxes-cameras-alerts">
            <mat-checkbox
              *ngFor="let resource of offlineOrSummary"
              (click)="$event.stopPropagation()"
              (change)="selection.toggle(resource.id)"
              [checked]="selection.isSelected(resource.id)"
              class="c-checkbox-camera-alert"
            >
              {{ resource.name }}
            </mat-checkbox>
          </div>
        </div>
      </ng-container>
      <ng-template #noResources>
        <div class="c-text-no-alerts">No {{ resourceType === 'alert' ? 'alerts' : 'summaries' }} exist.</div>
        <button class="o-button is-primary c-button-create-alert" (click)="navigateToResourcePage()">
          <span class="material-icons is-icon"> add_alert </span>
          Create {{ resourceType === 'alert' ? 'alert' : 'summary' }}
        </button>
        <p class="c-text-leave-setup">You will leave this setup journey but your progress will be saved.</p>
        <p class="c-text-camera-available">
          This camera will be available to add to your new alert when you have created it.
        </p>
      </ng-template>
    </div>
  </div>
</app-step>
