import { ChangeDetectorRef, Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Observable, Subject, finalize, forkJoin, of, switchMap, takeUntil } from 'rxjs';
import {
  Alert,
  AlertNotificationGroupMappingService,
  AlertOccupancyMonitorMappingService,
  AlertService,
  NotificationGroup,
  NotificationGroupMappingService,
  NotificationGroupService,
  OccupancyMonitor,
  PeopleCountMonitor,
  Site,
  User,
} from 'src/app/api';
import { MonitorAlertTypeComponent } from 'src/app/components/notifications/steps-for-stepper/monitor-alert-type/monitor-alert-type.component';
import { OccupancyAlertDetailsComponent } from 'src/app/components/notifications/steps-for-stepper/occupancy-alert-details/occupancy-alert-details.component';
import { OccupancyAlertSelectMonitorComponent } from 'src/app/components/notifications/steps-for-stepper/occupancy-alert-select-monitor/occupancy-alert-select-monitor.component';
import { NotificationsService } from 'src/app/services/notifications.service';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'app-add-monitor-alert',
  templateUrl: './add-monitor-alert.component.html',
  styleUrls: ['./add-monitor-alert.component.scss'],
})
export class AddMonitorAlertComponent implements OnDestroy {
  stepsTitle = 'Create monitor alert';
  occupancyMonitors: OccupancyMonitor[] = [];
  filteredOccupancyMonitors: OccupancyMonitor[] = [];
  peopleCountMonitors: PeopleCountMonitor[] = [];
  occupancyAlert: Alert;
  isLoading = false;
  notificationGroupName: string;
  notificationGroups: NotificationGroup[] = [];
  filteredNotificationGroups: NotificationGroup[] = [];
  users: User[] = [];
  filteredUsers: User[] = [];
  sites: Site[] = [];

  private ngUnsubscribe = new Subject();

  @ViewChild('stepper') stepper: MatStepper;

  @ViewChild(MonitorAlertTypeComponent)
  monitorAlertTypeComponent: MonitorAlertTypeComponent;
  @ViewChild(OccupancyAlertSelectMonitorComponent)
  occupancyAlertSelectMonitorComponent: OccupancyAlertSelectMonitorComponent;
  @ViewChild(OccupancyAlertDetailsComponent)
  occupancyAlertDetailsComponent: OccupancyAlertDetailsComponent;

  constructor(
    public dialogRef: MatDialogRef<AddMonitorAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notificationsService: NotificationsService,
    private notificationGroupService: NotificationGroupService,
    private notificationGroupMappingService: NotificationGroupMappingService,
    private alertService: AlertService,
    private alertNotificationGroupMappingService: AlertNotificationGroupMappingService,
    private alertOccupancyMonitorMappingService: AlertOccupancyMonitorMappingService,
    private notifyService: NotifyService,
    private ref: ChangeDetectorRef,
  ) {
    this.occupancyMonitors = this.data.occupancyMonitors;
    this.peopleCountMonitors = this.data.peopleCountMonitors;
    this.notificationGroups = notificationsService.notificationGroups;
    this.users = this.notificationsService.users;
    this.sites = this.notificationsService.sites;
  }

  validateAlertType(): void {
    this.filteredUsers = this.users.filter(
      (u) => u.organisationId === this.monitorAlertTypeComponent.selectedOrganisation.id,
    );
    this.filteredNotificationGroups = this.notificationGroups.filter(
      (n) => n.organisationId === this.monitorAlertTypeComponent.selectedOrganisation.id,
    );

    if (this.monitorAlertTypeComponent.alertMode === 'site') {
      this.filteredOccupancyMonitors = this.occupancyMonitors.filter(
        (occupancyMonitor: OccupancyMonitor) =>
          this.monitorAlertTypeComponent.selectedSite.id === occupancyMonitor.siteId,
      );
      this.stepper.next();
    } else {
      this.stepper.selectedIndex = 2;
    }
  }

  goToFirstStep(): void {
    if (this.monitorAlertTypeComponent.alertMode === 'site') {
      this.stepper.selectedIndex = 1;
    } else {
      this.stepper.selectedIndex = 0;
    }
  }

  saveOccupancyAlert(): void {
    this.occupancyAlertDetailsComponent.errors.warningThreshold = '';
    this.occupancyAlertDetailsComponent.errors.warningRecoveryThreshold = '';
    this.occupancyAlertDetailsComponent.errors.alertRecoveryThreshold = '';

    if (
      this.occupancyAlertDetailsComponent.alertForm.get('alertThreshold').value <=
      this.occupancyAlertDetailsComponent.alertForm.get('alertRecoveryThreshold').value
    ) {
      this.occupancyAlertDetailsComponent.errors.alertRecoveryThreshold =
        'Recovery threshold should be less than alert threshold';
      this.ref.detectChanges();
      this.notifyService.warning('Recovery threshold should be less than alert threshold');
      return;
    }
    if (
      this.occupancyAlertDetailsComponent.alertForm.get('alertRecoveryThreshold').value <=
      this.occupancyAlertDetailsComponent.alertForm.get('warningThreshold').value
    ) {
      this.occupancyAlertDetailsComponent.errors.warningThreshold =
        'Warning threshold should be less than alert recovery threshold';
      this.ref.detectChanges();
      this.notifyService.warning('Warning threshold should be less than alert recovery threshold');
      return;
    }
    if (
      this.occupancyAlertDetailsComponent.alertForm.controls.issueWarning.value &&
      this.occupancyAlertDetailsComponent.alertForm.controls.notifyWarningRecovery.value &&
      this.occupancyAlertDetailsComponent.alertForm.get('warningThreshold').value <=
        this.occupancyAlertDetailsComponent.alertForm.get('warningRecoveryThreshold').value
    ) {
      this.occupancyAlertDetailsComponent.errors.warningRecoveryThreshold =
        'Recovery threshold should be less than warning threshold';
      this.ref.detectChanges();
      this.notifyService.warning('Recovery threshold should be less than warning threshold');
      return;
    }

    this.occupancyAlert = {
      alertType: 'occupancy_alert',
      name: this.monitorAlertTypeComponent.alertName,
      organisationId: this.monitorAlertTypeComponent.selectedOrganisation.id,
      configurationDetails: {
        alertThreshold: this.occupancyAlertDetailsComponent.alertForm.get('alertThreshold').value / 100,
        warningThreshold: this.occupancyAlertDetailsComponent.alertForm.get('warningThreshold').value / 100,
        alertRecoveryThreshold: this.occupancyAlertDetailsComponent.alertForm.get('alertRecoveryThreshold').value / 100,
        warningRecoveryThreshold:
          this.occupancyAlertDetailsComponent.alertForm.get('warningRecoveryThreshold').value / 100,
        notifyAlertRecovery: this.occupancyAlertDetailsComponent.alertForm.get('notifyAlertRecovery').value,
        notifyWarningRecovery: this.occupancyAlertDetailsComponent.alertForm.get('notifyWarningRecovery').value,
      },
    };

    if (this.monitorAlertTypeComponent.alertMode === 'site') {
      this.occupancyAlert.siteId = this.monitorAlertTypeComponent.selectedSite.id;
    }

    if (!this.occupancyAlert.configurationDetails['issueWarning']) {
      delete this.occupancyAlert.configurationDetails['warningThreshold'];
      delete this.occupancyAlert.configurationDetails['warningRecoveryThreshold'];
      delete this.occupancyAlert.configurationDetails['notifyWarningRecovery'];
    }

    this.occupancyAlertDetailsComponent.errors.warningThreshold = '';
    this.occupancyAlertDetailsComponent.errors.warningRecoveryThreshold = '';
    this.occupancyAlertDetailsComponent.errors.alertRecoveryThreshold = '';

    this.notificationGroupName = undefined;
    if (
      this.occupancyAlertDetailsComponent.selectRecipientsComponent.selectedRecipients.length === 1 &&
      this.occupancyAlertDetailsComponent.selectRecipientsComponent.selectedRecipients[0].hasOwnProperty('emails')
    ) {
      this.notificationGroupName =
        this.occupancyAlertDetailsComponent.selectRecipientsComponent.selectedRecipients[0].name;
    } else if (
      this.occupancyAlertDetailsComponent.selectRecipientsComponent.createNotificationCheck &&
      this.occupancyAlertDetailsComponent.selectRecipientsComponent.selectedRecipients.length !== 1
    ) {
      this.notificationGroupName =
        this.occupancyAlertDetailsComponent.selectRecipientsComponent.createNotificationName.value;
    }

    this.stepper.next();
  }

  private getRecipientsEmails(recipients: any[]): string[] {
    const emails = [];
    recipients.forEach((recipient) => {
      if (typeof recipient === 'string') {
        emails.push(recipient);
      } else if (recipient.hasOwnProperty('firstName')) {
        emails.push(recipient.email);
      }
    });
    return emails;
  }

  private getRecipientsNotificationGroupsIDs(recipients: any[]): string[] {
    const notificationGroupsIDs = [];
    recipients.forEach((recipient) => {
      if (recipient.hasOwnProperty('emails')) {
        notificationGroupsIDs.push(recipient.id);
      }
    });
    return notificationGroupsIDs;
  }

  createOccupancyAlert(): void {
    this.isLoading = true;
    const requests: Observable<any>[] = [];
    if (
      this.occupancyAlertDetailsComponent.selectRecipientsComponent.selectedRecipients.length === 1 &&
      this.occupancyAlertDetailsComponent.selectRecipientsComponent.selectedRecipients[0].hasOwnProperty('emails')
    ) {
      // notificationGroup = this.occupancyAlertDetailsComponent.selectRecipientsComponent.selectedRecipients[0];
      requests.push(this.alertService.putAlert(this.occupancyAlert));
    } else if (
      this.occupancyAlertDetailsComponent.selectRecipientsComponent.createNotificationCheck &&
      this.occupancyAlertDetailsComponent.selectRecipientsComponent.selectedRecipients.length !== 1
    ) {
      requests.push(this.alertService.putAlert(this.occupancyAlert));
      requests.push(
        this.notificationGroupService.putNotificationGroup({
          emails: this.getRecipientsEmails(
            this.occupancyAlertDetailsComponent.selectRecipientsComponent.selectedRecipients,
          ),
          name: this.occupancyAlertDetailsComponent.selectRecipientsComponent.createNotificationName.value,
          organisationId: this.occupancyAlert.organisationId,
        }),
      );
    } else {
      requests.push(
        this.alertService.putAlert({
          ...this.occupancyAlert,
          emails: this.getRecipientsEmails(
            this.occupancyAlertDetailsComponent.selectRecipientsComponent.selectedRecipients,
          ),
        }),
      );
    }

    forkJoin(requests)
      .pipe(
        switchMap((response) => {
          const requests: Observable<any>[] = [];

          if (this.occupancyAlertSelectMonitorComponent && !this.occupancyAlertSelectMonitorComponent.isAllSelected()) {
            this.occupancyAlertSelectMonitorComponent.selection.selected.forEach((m: OccupancyMonitor) => {
              requests.push(
                this.alertOccupancyMonitorMappingService.putAlertOccupancyMonitorMapping({
                  alertId: response[0].id,
                  occupancyMonitorId: m.id,
                }),
              );
            });
          }

          if (
            this.occupancyAlertDetailsComponent.selectRecipientsComponent.createNotificationCheck &&
            this.occupancyAlertDetailsComponent.selectRecipientsComponent.selectedRecipients.length !== 1
          ) {
            requests.push(
              this.alertNotificationGroupMappingService.putAlertNotificationGroupMapping({
                alertId: response[0].id,
                notificationGroupId: response[1].id,
              }),
            );
            this.getRecipientsNotificationGroupsIDs(
              this.occupancyAlertDetailsComponent.selectRecipientsComponent.selectedRecipients,
            ).forEach((notificationGroupId) => {
              requests.push(
                this.notificationGroupMappingService.putNotificationGroupNotificationGroup({
                  organisationId: this.occupancyAlert.organisationId,
                  parentId: response[1].id,
                  childId: notificationGroupId,
                }),
              );
            });
          } else {
            this.getRecipientsNotificationGroupsIDs(
              this.occupancyAlertDetailsComponent.selectRecipientsComponent.selectedRecipients,
            ).forEach((notificationGroupId) => {
              requests.push(
                this.alertNotificationGroupMappingService.putAlertNotificationGroupMapping({
                  alertId: response[0].id,
                  notificationGroupId: notificationGroupId,
                }),
              );
            });
          }

          if (!requests.length) {
            return of([]);
          }

          return forkJoin(requests);
        }),
        finalize(() => {
          this.isLoading = false;
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (_) => {
          this.close(true);
        },
        error: (error) => {
          this.notifyService.error(error);
        },
      });
  }

  close(reload?: boolean): void {
    if (reload) {
      this.dialogRef.close('reload');
    } else {
      this.dialogRef.close();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
