import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-add-first-camera',
  templateUrl: './add-first-camera.component.html',
  styleUrls: ['./add-first-camera.component.scss'],
})
export class AddFirstCameraComponent {
  collapseCamerasSection = false;

  @Input() canAddCamera = true;
  @Output() addCamera: EventEmitter<void> = new EventEmitter();
  constructor() {}
}
