import { Pipe, PipeTransform } from '@angular/core';
import { GlobalMethods } from 'src/app/global-methods';

@Pipe({
  name: 'timeGrainDisplayValue',
})
export class TimeGrainDisplayValuePipe implements PipeTransform {
  allowedTimeGrains = { s: ' second', m: ' minute', h: ' hour', d: ' day' };

  transform(timeGrain: string): unknown {
    let [v, u] = timeGrain.match(/[0-9]+|[smhd]/g);
    if (!this.allowedTimeGrains.hasOwnProperty(u)) return '';
    let vInt = parseInt(v);
    return `${vInt} ${GlobalMethods.pluraliseWord(vInt, this.allowedTimeGrains[u])}`;
  }
}
