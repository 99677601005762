import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Site } from 'src/app/api';

@Component({
  selector: 'app-site-filter',
  templateUrl: './site-filter.component.html',
  styleUrls: ['./site-filter.component.scss'],
})
export class SiteFilterComponent implements OnInit {
  filteredSites: Site[] = [];
  filterSitesIdsFromParams: string[];

  @Input() sites: Site[];
  @Output() filtered: EventEmitter<Site[]> = new EventEmitter();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.sites.sort((a, b) => a.name.localeCompare(b.name));

    const sitesMap = {};
    this.sites.forEach((site) => (sitesMap[site.id] = site));

    this.filterSitesIdsFromParams = this.getSitesIdsFromParamsAsArray();
    if (Boolean(this.filterSitesIdsFromParams.length)) {
      this.filterSitesIdsFromParams.forEach((filterSiteId) => {
        this.filterSite(sitesMap[filterSiteId]);
      });
    }
  }

  getSitesIdsFromParamsAsArray(): string[] {
    const filterSitesIdsFromParams: string | string[] = this.activatedRoute.snapshot.queryParams['filterSitesIds'];

    if (filterSitesIdsFromParams === undefined) {
      return [];
    } else {
      return Array.isArray(filterSitesIdsFromParams) ? [...filterSitesIdsFromParams] : [filterSitesIdsFromParams];
    }
  }

  clearFilter(): void {
    this.filteredSites = [];
    this.filtered.emit(this.filteredSites);
    this.updateParams({});
  }

  filterSite(site: Site): void {
    this.toggleFilteredSite(site);
    this.filtered.emit(this.filteredSites);
    this.updateParams();
  }

  toggleFilteredSite(site: Site) {
    const index = this.filteredSites.indexOf(site);
    if (index !== -1) {
      this.filteredSites.splice(index, 1);
    } else {
      this.filteredSites.push(site);
    }
  }

  updateParams(customParams?: Params) {
    const sitesIds: string[] = this.filteredSites.map((site) => site.id);
    const queryParams: Params = { filterSitesIds: sitesIds };

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams ? queryParams : customParams,
      queryParamsHandling: 'merge',
    });
  }
}
