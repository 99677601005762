<mat-horizontal-stepper class="dialog-stepper" #stepper>
  <mat-step>
    <app-step
      [stepTitle]="stepsTitle"
      [isBackable]="false"
      [disabledNextStep]="
        !monitorAlertTypeComponent?.selectedOrganisation ||
        monitorAlertTypeComponent?.alertName === '' ||
        (monitorAlertTypeComponent?.alertMode === 'site' && !monitorAlertTypeComponent.selectedSite)
      "
      (closeStep)="close()"
      (openNextStep)="validateAlertType()"
    >
      <app-monitor-alert-type
        [sites]="sites"
        (reloadEvent)="close(true)"
        #monitorAlertTypeComponent
      ></app-monitor-alert-type>
    </app-step>
  </mat-step>
  <mat-step>
    <ng-template matStepContent>
      <app-step
        [stepTitle]="stepsTitle"
        (closeStep)="close()"
        [disabledNextStep]="!occupancyAlertSelectMonitorComponent?.selection?.selected.length"
      >
        <app-occupancy-alert-select-monitor
          #occupancyAlertSelectMonitorComponent
          [monitors]="filteredOccupancyMonitors"
          class="o-step-body"
        >
        </app-occupancy-alert-select-monitor>
      </app-step>
    </ng-template>
  </mat-step>
  <mat-step>
    <ng-template matStepContent>
      <app-step
        [stepTitle]="stepsTitle"
        [processingStep]="isLoading"
        [disabledNextStep]="
          !occupancyAlertDetailsComponent.alertForm.valid ||
          !occupancyAlertDetailsComponent.selectRecipientsComponent.selectedRecipients.length ||
          (occupancyAlertDetailsComponent.selectRecipientsComponent.createNotificationCheck &&
            !occupancyAlertDetailsComponent.selectRecipientsComponent.createNotificationName.valid)
        "
        (closeStep)="close()"
        (openNextStep)="saveOccupancyAlert()"
        (openPreviousStep)="goToFirstStep()"
      >
        <app-occupancy-alert-details
          [organisationId]="monitorAlertTypeComponent.selectedOrganisation.id"
          [users]="users"
          [notificationGroups]="notificationGroups"
          #occupancyAlertDetailsComponent
        >
        </app-occupancy-alert-details>
      </app-step>
    </ng-template>
  </mat-step>

  <mat-step>
    <ng-template matStepContent>
      <app-step
        [stepTitle]="stepsTitle"
        [isBackable]="false"
        [processingStep]="isLoading"
        [nextStepText]="'FINISH'"
        (closeStep)="close()"
        (openNextStep)="createOccupancyAlert()"
      >
        <app-occupancy-alert-summray
          #occupancyAlertSummrayComponent
          [site]="monitorAlertTypeComponent.selectedSite"
          [allSites]="monitorAlertTypeComponent.alertMode === 'organisaiton'"
          [selectedMonitors]="occupancyAlertSelectMonitorComponent?.selection.selected"
          [monitors]="filteredOccupancyMonitors"
          [allMonitors]="occupancyAlertSelectMonitorComponent?.isAllSelected()"
          [occupancyAlert]="occupancyAlert"
          [notificationGroupName]="notificationGroupName"
          [selectedRecipients]="occupancyAlertDetailsComponent.selectRecipientsComponent.selectedRecipients"
        >
        </app-occupancy-alert-summray>
      </app-step>
    </ng-template>
  </mat-step>
</mat-horizontal-stepper>
