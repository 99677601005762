import { Pipe, PipeTransform } from '@angular/core';
import { GlobalMethods } from 'src/app/global-methods';

@Pipe({
  name: 'pluraliseWord',
})
export class PluraliseWordPipe implements PipeTransform {
  transform(count: number, word: string): unknown {
    if (word === 'summary') {
      return count === 1 ? word : 'summaries';
    }

    return GlobalMethods.pluraliseWord(count, word);
  }
}
