import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CameraStatus } from 'src/app/model/cameraStatus';
import { SiteStatus } from 'src/app/model/siteStatus';
import { CamerasService } from 'src/app/services/cameras.service';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-site-card',
  templateUrl: './site-card.component.html',
  styleUrls: ['./site-card.component.scss'],
})
export class SiteCardComponent implements OnInit {
  @Input() site: SiteStatus;
  @Input() cameras = [];
  @Input() alerts = [];
  @Input() occupancyMonitors = [];
  @Input() peopleCountMonitors = [];
  @Input() peelOffMonitors = [];

  @Output() editSite: EventEmitter<void> = new EventEmitter();
  @Output() deleteSite: EventEmitter<void> = new EventEmitter();

  issues = 0;
  urgentIssues = 0;

  constructor(
    private router: Router,
    private camerasService: CamerasService,
    public accountService: AccountService,
  ) {}

  ngOnInit(): void {
    this.cameras.forEach((camera: CameraStatus) => {
      if (this.camerasService.hasIssue(camera)) {
        this.issues += 1;
        if (camera.urgency === 100) {
          this.urgentIssues += 1;
        }
      }
    });
  }

  viewSiteCameras(): void {
    const routerLink = 'sites' + `/${this.site.id}` + '/cameras';
    this.router.navigate([routerLink]);
  }

  viewSiteMonitors(type = null): void {
    this.router.navigate([`monitors/${this.site.id}`], { queryParams: { type } });
  }
}
