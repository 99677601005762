import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { GlobalMethods } from 'src/app/global-methods';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-new-feature',
  templateUrl: './new-feature.component.html',
  styleUrls: ['./new-feature.component.scss'],
})
export class NewFeatureComponent {
  constructor(
    public dialogRef: MatDialogRef<NewFeatureComponent>,
    private cookieService: CookieService,
  ) {}

  dontShowAgain(): void {
    GlobalMethods.dontShowNewFeatureDialog(this.cookieService);
    this.dialogRef.close();
  }
}
