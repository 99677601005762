<div class="auth-overlay" *ngIf="isLoading; else notLoading">
  <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
    <p style="font-size: 2rem; font-weight: 500">Loading...</p>
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>
<ng-template #notLoading>
  <a class="o-button u-margin--bottom" (click)="navigateToNotificationGroups()">
    <mat-icon class="is-icon-left">arrow_back</mat-icon>
    Back
  </a>

  <div class="c-container">
    <div class="c-email-list__title">
      <h2 class="long-primer--bold u-flush">{{ notificationGroup.name }} notification group</h2>
      <a class="is-icon">
        <mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="editList()">
            <mat-icon>edit</mat-icon>
            Edit list
          </button>
          <button mat-menu-item (click)="deleteList()" [disabled]="deleting">
            <mat-icon>delete</mat-icon>
            Delete list
          </button>
        </mat-menu>
      </a>
    </div>
    <div class="c-list-details">
      <div class="c-list-details__recipients">
        <div class="c-list-details__recipients-title">
          <div class="o-heading--long-primer xc-heading">
            <h2>Recipients</h2>
            <p>
              These users are receiving alerts going to <b>{{ notificationGroup.name }}</b>
            </p>
          </div>
          <div class="o-button has-outline" (click)="addEmailToNotificationGroup()">
            <mat-icon class="is-icon-left">person_add</mat-icon>
            ADD RECIPIENTS
          </div>
        </div>
        <div class="c-list-details__recipients-delete">
          <div class="is-delete-button" (click)="deleteSelected()">
            <mat-icon class="is-icon">delete</mat-icon>
            Delete selected
          </div>
        </div>
        <div class="c-list-scroll">
          <ul class="o-long-checklist">
            <li *ngFor="let email of notificationGroup.emails" class="o-long-checklist__item has-hover">
              <mat-checkbox
                class="c-user-select"
                (change)="emailsSelection.toggle(email)"
                [checked]="emailsSelection.isSelected(email)"
              >
                <div class="c-recipient-label">
                  <ng-container *ngIf="(userEmailToUserMap[email] | keyvalue)?.length">
                    <div class="c-recipient-label__name">
                      {{ userEmailToUserMap[email].name }}
                    </div>
                    <div class="c-recipient-label__role minion--caps">{{ userEmailToUserMap[email].roles[0] }}</div>
                    <div class="c-recipient-label__email">{{ email }}</div>
                  </ng-container>
                  <ng-container *ngIf="!(userEmailToUserMap[email] | keyvalue)?.length">
                    <div class="c-recipient-label__name">{{ email }}</div>
                    <div class="c-recipient-label__role minion--caps is-contact">Contact</div>
                  </ng-container>
                </div>
              </mat-checkbox>
            </li>

            <li *ngFor="let notificationGroup of nestedNotificationGroups" class="o-long-checklist__item has-hover">
              <mat-checkbox
                class="c-user-select"
                (change)="notificationGroupsSelection.toggle(notificationGroup.id)"
                [checked]="notificationGroupsSelection.isSelected(notificationGroup.id)"
              >
                <div class="c-recipient-label">
                  <div class="c-recipient-label__name">
                    {{ notificationGroup.name }}
                  </div>
                  <div class="c-recipient-label__role minion--caps is-group">
                    Notification group
                    <span
                      (click)="openEditNotificationGroupDialog(notificationGroup)"
                      class="c-recipient-label__members"
                    >
                      {{ notificationGroup.members }}
                      {{ notificationGroup.members ? 'members' : 'member' }}
                    </span>
                  </div>
                </div>
              </mat-checkbox>
            </li>
          </ul>
        </div>
      </div>
      <div class="c-list-alerts">
        <div class="c-list-alerts__header">
          <h3 class="primer kirk u-flush--bottom">Alerts</h3>
          <p class="brevier u-flush--bottom type--secondary">Notifications received by members of this group</p>
        </div>
        <div class="c-list-alerts_content">
          <div *ngFor="let alertNotifiaction of alertNotifiactions" class="c-list-alerts__item">
            <mat-icon class="is-icon">
              <ng-container *ngIf="alertNotifiaction.type === 'occupancy_alert'"> people </ng-container>
              <ng-container *ngIf="alertNotifiaction.type === 'camera_health_summary_alert'"> emergency </ng-container>
              <ng-container *ngIf="alertNotifiaction.type === 'offline_alert'"> no_photography </ng-container>
            </mat-icon>
            <div class="c-alerts__item-description">
              <div class="primer u-flush--bottom">{{ alertNotifiaction.title }}</div>
              <div class="brevier type--secondary u-flush--bottom">
                {{ notificationGroups[alertNotifiaction.type] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
