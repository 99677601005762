import { Pipe, PipeTransform } from '@angular/core';
import { GlobalMethods } from 'src/app/global-methods';

@Pipe({
  name: 'minutesToTimeString',
})
export class MinutesToTimeStringPipe implements PipeTransform {
  transform(dayMinutes: number): any {
    return GlobalMethods.minutesToTimeString(dayMinutes);
  }
}
