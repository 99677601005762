<div class="c-current-camera-issues">
  <div class="c-current-camera-issues__header" (click)="expandCameraIssue()">
    <div class="c-current-camera-issues__title">
      <div class="c-title-site-content">
        <div class="brevier--caps u-flush--bottom">{{ site.name }}</div>
        <a class="brevier u-flush--bottom" *ngIf="siteCameraIssues.length - 1" [routerLink]="['/sites', site.id]">
          {{ siteCameraIssues.length - 1 }} other {{ siteCameraIssues.length - 1 | pluraliseWord: 'camera' }} at this
          site
          {{ siteCameraIssues.length - 1 === 1 ? 'has an' : 'have' }}
          {{ siteCameraIssues.length - 1 | pluraliseWord: 'issue' }}
        </a>
      </div>
      <div class="c-alert-warning c-alert-no-camera-alert" *ngIf="!offlineAlerts?.length">
        <mat-icon>notification_important</mat-icon>
        <div class="c-text-no-camera-alert">no camera alert</div>
      </div>
    </div>
    <div class="c-current-camera-issues__info">
      <mat-icon class="has-error is-icon" *ngIf="camera.status !== 'online'">no_photography</mat-icon>
      <mat-icon class="is-online is-icon" *ngIf="camera.status === 'online'">photo_camera</mat-icon>
      <a class="is-name" [routerLink]="['/sites', site.id, 'cameras', camera.id]">{{ camera.name }}</a>
      <div class="c-alert-warning c-alert-missing-video is-status" *ngIf="!sevenDaysSummary">
        {{ camera.status }}
      </div>
      <div class="is-last-seen brevier u-flush--bottom type--secondary">
        LAST SEEN: {{ camera.lastSeenAt | dateAgo }}
      </div>
    </div>
    <div class="c-current-camera-issues__toggle">
      <mat-icon>
        {{ isExpandedCameraIssue ? 'expand_less' : 'expand_more' }}
      </mat-icon>
    </div>
  </div>

  <div class="c-current-camera-issues-content" *ngIf="isExpandedCameraIssue">
    <app-camera-performance-summary
      *ngIf="sevenDaysSummary"
      [sevenDaysSummary]="sevenDaysSummary"
      [camera]="camera"
    ></app-camera-performance-summary>

    <ng-container *ngIf="occupancyMonitors?.length || peopleCountMonitors?.length || peelOffMonitors?.length">
      <div class="c-header-monitors-impacted-by-issues">
        <mat-icon class="c-icon-view-quilt">view_quilt</mat-icon>
        <div>
          <div class="c-text-monitors-impacted-by-issue">
            {{ occupancyMonitors?.length + peopleCountMonitors?.length + peelOffMonitors?.length }}
            {{
              occupancyMonitors?.length + peopleCountMonitors?.length + peelOffMonitors?.length
                | pluraliseWord: 'Monitor'
            }}
            impacted by this issue.
          </div>
          <div class="c-text-data-unavailable">
            {{
              camera.status !== 'online'
                ? 'Data for these monitors will be unavailable or inaccurate until the issues are resolved.'
                : 'Data for these monitors is unavailable or inaccurate for the time of the issue.'
            }}
          </div>
        </div>
      </div>
      <div class="c-monitor-issues">
        <div class="c-monitor-issue c-monitor-issues__item" *ngFor="let occupancyMonitor of occupancyMonitors">
          <mat-icon class="c-icon-monitor">groups</mat-icon>
          <div>
            <div class="primer kirk u-flush--bottom">{{ occupancyMonitor.name }}</div>
            <div class="minion--caps type--secondary">Occupancy monitor</div>
          </div>
          <!-- <div class="c-monitor-issue-content">Occupancy unknown for last 2 hours.</div> -->
        </div>
        <div class="c-monitor-issue c-monitor-issues__item" *ngFor="let peopleCountMonitor of peopleCountMonitors">
          <mat-icon class="c-icon-monitor">transfer_within_a_station</mat-icon>
          <div>
            <div class="primer kirk u-flush--bottom">{{ peopleCountMonitor.name }}</div>
            <div class="minion--caps type--secondary">PEOPLE COUNT MONITOR</div>
          </div>
          <!-- <div class="c-monitor-issue-content">Occupancy unknown for last 2 hours.</div> -->
        </div>
        <ng-container>
          <div class="c-monitor-issue c-monitor-issues__item" *ngFor="let peelOffMonitor of peelOffMonitors">
            <mat-icon class="c-icon-monitor">fork_left</mat-icon>
            <div>
              <div class="primer kirk u-flush--bottom">{{ peelOffMonitor.name }}</div>
              <div class="minion--caps type--secondary">PEEL OFF MONITOR</div>
            </div>
            <!-- <div class="c-monitor-issue-content">Occupancy unknown for last 2 hours.</div> -->
          </div>
        </ng-container>
        <!-- <div class="c-monitor-issue">
            <div class="c-monitor-issue-header">
              <mat-icon class="c-icon-monitor">transfer_within_a_station</mat-icon>
              <div>
                <div class="c-text-site-name">Dinosaur feet</div>
                <div class="c-text-monitor-name">PEOPLE COUNT MONITOR</div>
              </div>
            </div>
            <div class="c-monitor-issue-content">No people count for last 2 hours.</div>
          </div> -->
      </div>
    </ng-container>
    <div class="c-services">
      <div class="c-services__item">
        <div class="c-services-title">Camera alerts.</div>

        <ng-container *ngIf="!cameraOfflineAlerts?.length; else cameraAlerts">
          <div class="c-alert-no-camera-alert-wrapper">
            <div class="c-alert-warning c-alert-no-camera-alert">
              <mat-icon>notification_important</mat-icon>
              <div class="c-text-no-camera-alert">no camera alert</div>
            </div>
          </div>
          <div class="c-services-content">
            <div class="c-service-available-wrapper">
              <div class="c-text-recommendation">We strongly recommend adding an alert for all cameras.</div>
              <div class="c-service-available">
                <div class="c-service-available-count">
                  <mat-icon>notifications</mat-icon>
                  <div class="c-text-avaiable-count">
                    <b>{{ offlineAlerts.length }}</b> camera
                    {{ offlineAlerts.length | pluraliseWord: 'alert' }} available
                  </div>
                </div>
                <a class="o-button is-small" [routerLink]="['/alerts/camera-alerts-offline']">add alerts</a>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #cameraAlerts>
          <div class="c-services-content">
            <div class="c-service" *ngFor="let alert of cameraOfflineAlerts">
              <mat-icon class="c-icon-check">check</mat-icon>
              <div>{{ alert.name }}</div>
            </div>
          </div>
        </ng-template>
      </div>

      <div class="c-services-wrapper">
        <div class="c-services-title">Health summaries.</div>

        <ng-container *ngIf="!cameraSummaryAlerts?.length; else cameraSummaries">
          <div class="c-alert-no-camera-alert-wrapper">
            <div class="c-alert-warning c-alert-no-camera-alert">
              <mat-icon>emergency</mat-icon>
              no health summary
            </div>
          </div>
          <div class="c-services-content">
            <div class="c-service-available-wrapper">
              <div class="c-text-recommendation">We strongly recommend adding an alert for all cameras.</div>
              <div class="c-service-available">
                <div class="c-service-available-count">
                  <mat-icon>emergency</mat-icon>
                  <div class="c-text-avaiable-count">
                    <b> {{ summaryAlerts.length }} </b>
                    health {{ summaryAlerts.length | pluraliseWord: 'summary' }} available
                  </div>
                </div>
                <a class="o-button is-small" [routerLink]="['/alerts/camera-summaries']">add summary</a>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #cameraSummaries>
          <div class="c-services-content">
            <div class="c-service" *ngFor="let alert of cameraSummaryAlerts">
              <mat-icon class="c-icon-check">check</mat-icon>
              <div>{{ alert.name }}</div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>

    <div class="c-button-manage-alerts-wrapper">
      <a class="o-button has-outline" [routerLink]="['/alerts/camera-alerts-offline']">
        <span class="material-icons is-icon-left"> notifications </span>
        Manage alerts
      </a>
    </div>
  </div>
</div>
