import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  /**
   * Store the given value in local storage under the key
   *
   * @param key Key to store the value under
   * @param value Value to store
   */
  set(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  /**
   * Get the value for the key from local storage
   *
   * @param key Key to retrieve
   * @returns The value
   */
  get(key: string): string {
    return localStorage.getItem(key);
  }

  /**
   * Delete the key
   *
   * @param key Key to delete
   */
  delete(key: string): void {
    localStorage.removeItem(key);
  }

  /**
   * Clear all keys
   */
  clear() {
    localStorage.clear();
  }
}
