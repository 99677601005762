<form class="c-recipients-form">
  <mat-form-field appearance="fill">
    <mat-label>Recipients</mat-label>
    <input
      matInput
      [formControl]="searchRecipientsControl"
      [matAutocomplete]="auto"
      type="text"
      (keydown.enter)="addRecipients(searchRecipientsControl.value)"
    />
    <mat-error *ngIf="searchRecipientsControl.invalid">Invalid Email</mat-error>

    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let user of $filteredUsers | async" (click)="addRecipients(user)" class="o-avatar__row">
        <li class="o-avatar__list-item">
          <div class="o-avatar__icon">
            <span>{{ user.name.split(' ')[0][0] }}</span>
            <span *ngIf="user.name.split(' ')[1]">{{ user.name.split(' ')[1][0] }}</span>
          </div>
          <div class="o-avatar__description">
            <div>{{ user.name }}</div>
            <div>{{ user.email }}</div>
          </div>
        </li>
      </mat-option>
      <mat-option
        *ngFor="let notificationGroup of $filteredNotificationGroups | async"
        (click)="addRecipients(notificationGroup)"
        class="o-avatar__row"
      >
        <li class="o-avatar__list-item">
          <div class="o-avatar__icon">
            <span>{{ notificationGroup.name.split(' ')[0][0] }}</span>
            <span *ngIf="notificationGroup.name.split(' ')[1]">{{ notificationGroup.name.split(' ')[1][0] }}</span>
          </div>
          <div class="o-avatar__description">
            <div>{{ notificationGroup.name }}</div>
            <div>
              Notification group({{ notificationGroupMembers[notificationGroup.id] }}
              {{ notificationGroupMembers[notificationGroup.id] | pluraliseWord: 'member' }})
            </div>
          </div>
        </li>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>

<ng-container *ngIf="selectedRecipients.length > 1 && hasCreateOption">
  <div class="c-checkbox-create-notification-wrapper">
    <mat-checkbox (change)="createNotificationCheck = !createNotificationCheck" class="">
      Create a notification group with these recipients
    </mat-checkbox>
  </div>
  <div *ngIf="createNotificationCheck" class="c-notification-group-name-wrapper">
    <div class="c-text-give-group-name">
      All of these recipients will be added to a new notification group. <br />
      <b>Give this group a name.</b>
    </div>
    <mat-form-field class="is-input c-emails-card__add-email">
      <input autocomplete="off" matInput placeholder="Notification group name" [formControl]="createNotificationName" />
    </mat-form-field>
  </div>
</ng-container>

<ul class="o-avatar__list">
  <li class="o-avatar__list-item o-avatar__row" *ngFor="let recipient of selectedRecipients">
    <ng-container [ngSwitch]="getRecipientType(recipient)">
      <ng-container *ngSwitchCase="'email'">
        <div class="o-avatar__icon">
          <span>{{ recipient[0] }}</span>
        </div>
        <div class="o-avatar__description">
          <div>{{ recipient }}</div>
          <div>Does not have control room access</div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'user'">
        <div class="o-avatar__icon">
          <span>{{ recipient.name.split(' ')[0][0] }}</span>
          <span *ngIf="recipient.name.split(' ')[1]">{{ recipient.name.split(' ')[1][0] }}</span>
        </div>
        <div class="o-avatar__description">
          <div>{{ recipient.name }}</div>
          <div>{{ recipient.email }}</div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'group'">
        <div class="o-avatar__icon">
          <span>{{ recipient.name.split(' ')[0][0] }}</span>
          <span *ngIf="recipient.name.split(' ')[1]">{{ recipient.name.split(' ')[1][0] }}</span>
        </div>
        <div class="o-avatar__description">
          <div>{{ recipient.name }}</div>
          <div>
            Notification group({{ notificationGroupMembers[recipient.id] }}
            {{ notificationGroupMembers[recipient.id] | pluraliseWord: 'member' }})
          </div>
        </div>
      </ng-container>
    </ng-container>
    <div class="is-remove">
      <mat-icon (click)="deleteSelectedRecipient(recipient)" class="is-remove-icon">close</mat-icon>
    </div>
  </li>
</ul>
