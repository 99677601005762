import { ApplicationRef, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { filter, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UpdateService {
  constructor(
    private appRef: ApplicationRef,
    private updates: SwUpdate,
    private snackBar: MatSnackBar,
  ) {
    const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable === true));
    const everyHour$ = interval(60 * 60 * 1000);
    const everyHourOnceAppIsStable$ = concat(appIsStable$, everyHour$);

    if (updates.isEnabled) {
      everyHourOnceAppIsStable$.subscribe({ next: () => this.updates.checkForUpdate() });
    }
  }

  checkForUpdates() {
    if (this.updates.isEnabled) {
      this.updates.versionUpdates
        .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
        .subscribe({
          next: (_) => {
            const snackBarRef = this.snackBar.open('New version available!', 'Reload', {
              panelClass: 'snack-bar-neutral',
              verticalPosition: 'bottom',
              horizontalPosition: 'start',
            });
            snackBarRef.onAction().subscribe({
              next: () => {
                this.updates.activateUpdate().then(() => document.location.reload());
              },
            });
          },
        });
    }
  }
}
