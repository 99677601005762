<form class="o-form" [formGroup]="monitorForm">
  <div class="o-banner-alert is-error u-flush" *ngIf="monitorForm?.touched && !monitorMode">
    <mat-icon class="is-icon">warning</mat-icon>
    <span class="is-message">Please select how many cameras this monitor will use.</span>
  </div>

  <h1 class="long-primer kirk u-flush--bottom">How many cameras will be used in this monitor?</h1>
  <mat-accordion class="c-accordion" hideToggle="true">
    <mat-expansion-panel #singlePanel disabled class="c-flat-panel is-contained">
      <mat-expansion-panel-header [class.selected-header]="this.monitorMode === 'single'" class="c-flat-panel__header">
        <mat-panel-description>
          <mat-radio-button
            (change)="onModeChange($event, singlePanel)"
            name="camerasOption"
            value="single"
            class="c-accordion__radio"
          >
            One camera
          </mat-radio-button>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div class="c-camera-list" *ngIf="this.monitorMode === 'single'">
          <div class="c-camera-list__head o-heading--primer is-all-black u-flush--bottom">
            <h3>Select the camera.</h3>
            <p>A single-camera people count monitor will be created for you.</p>
          </div>
          <div
            class="c-camera-list__overflow"
            [class.is-invalid]="monitorForm?.touched && monitorForm?.get('cameraId')?.invalid"
          >
            <div
              *ngIf="monitorForm?.touched && monitorForm?.get('cameraId')?.invalid"
              class="o-banner-alert is-error u-flush"
            >
              <span class="is-message">Please select a camera</span>
            </div>

            <mat-radio-group formControlName="cameraId">
              <ul class="o-list">
                <li class="o-list__item" *ngFor="let camera of cameras">
                  <mat-radio-button value="{{ camera.id }}" name="single-camera" class="o-list--is-link">
                    {{ camera.name }}<br />
                  </mat-radio-button>
                </li>
              </ul>
            </mat-radio-group>
          </div>
        </div>
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel class="c-flat-panel is-contained has-no-body" #multiPanel disabled>
      <mat-expansion-panel-header [class.selected-header]="this.monitorMode === 'multi'" class="c-flat-panel__header">
        <mat-panel-description>
          <mat-radio-button
            (change)="onModeChange($event, multiPanel)"
            name="camerasOption"
            value="multi"
            class="c-accordion__radio"
          >
            More than one camera</mat-radio-button
          >
        </mat-panel-description>
      </mat-expansion-panel-header>
    </mat-expansion-panel>
  </mat-accordion>
</form>
