<div class="container">
  <div class="o-banner-message not-found">
    <div class="o-banner-message__text">
      <h1 class="is-title is-paragon">Sorry, we're experiencing a technical issue and can't display this page.</h1>
      <p class="is-text">Our engineering team will resolve this as soon as possible. Please try again soon.</p>
      <p class="is-text">
        If you're still having difficulties, contact our
        <a href="mailto:support@hoxton.ai">customer service team</a>.
      </p>
    </div>

    <div class="o-banner-message__media not-found__media">
      <img src="/assets/images/404-graphic.png" alt="" class="is-image" />
    </div>
  </div>
</div>
