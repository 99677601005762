<div class="c-navigation">
  <div class="c-navigation__logo">
    <mat-icon>dashboard</mat-icon>
    {{ accountService.organisation ? accountService.organisation.name : 'Hoxton Inc' }}
  </div>
  <div class="c-navigation__desktop">
    <div class="is-text">Select dashboard view</div>
    <a
      class="is-navigation"
      [ngClass]="{ 'is-selected': currentRoute === 'real-time-occupancy' }"
      routerLink="/real-time-occupancy"
      *ngIf="accountService.occupancyLicence"
    >
      <mat-icon>stream</mat-icon>
      REAL TIME OCCUPANCY
      <mat-icon *ngIf="defaultNavigation === 'real-time-occupancy'" class="is-pin-icon">push_pin</mat-icon>
    </a>
    <a
      class="is-navigation"
      [ngClass]="{ 'is-selected': currentRoute === 'infrastructure' }"
      routerLink="/infrastructure"
    >
      <mat-icon>widgets</mat-icon>
      INFRASTRUCTURE
      <mat-icon *ngIf="defaultNavigation === 'infrastructure'" class="is-pin-icon">push_pin</mat-icon>
    </a>
    <a class="is-navigation" [ngClass]="{ 'is-selected': currentRoute === 'reporting' }" routerLink="/reporting">
      <mat-icon>query_stats</mat-icon>
      REPORTING
      <mat-icon *ngIf="defaultNavigation === 'reporting'" class="is-pin-icon">push_pin</mat-icon>
    </a>
    <a
      class="is-navigation"
      [ngClass]="{ 'is-selected': currentRoute === 'settings' }"
      routerLink="/settings"
      *ngIf="accountService.isAdmin"
    >
      <mat-icon>settings</mat-icon>
      SETTINGS
      <mat-icon *ngIf="defaultNavigation === 'reporting'" class="is-pin-icon">push_pin</mat-icon>
    </a>

    <button class="is-select-default o-button is-small">
      <mat-icon class="is-icon-left is-pin-icon">push_pin</mat-icon>
      Set default tab
      <mat-select [(ngModel)]="defaultNavigation" (selectionChange)="setDefaultNavigation($event)">
        <mat-option [value]="'real-time-occupancy'" *ngIf="accountService.occupancyLicence">
          <mat-icon>stream</mat-icon>
          REAL TIME OCCUPANCY
        </mat-option>
        <mat-option [value]="'infrastructure'">
          <mat-icon>widgets</mat-icon>
          INFRASTRUCTURE
        </mat-option>
        <mat-option [value]="'reporting'">
          <mat-icon>query_stats</mat-icon>
          REPORTING
        </mat-option>
        <mat-option [value]="'settings'" *ngIf="accountService.isAdmin">
          <mat-icon>settings</mat-icon>
          SETTINGS
        </mat-option>
      </mat-select>
    </button>
  </div>
  <div class="c-navigation__mobile">
    <div class="o-button u-hard--left" (click)="selectNavigation()">
      <mat-icon class="c-icon-menu-open is-icon-left">menu_open</mat-icon>
      Select dashboard view
    </div>
    <div class="is-current-navigation">
      <mat-icon class="is-icon">{{ routeMap[currentRoute].icon }}</mat-icon>
      {{ routeMap[currentRoute].name }}
    </div>
  </div>
</div>

<ng-template #mobileNavigation>
  <div class="o-modal-full-page">
    <div (click)="closeMobileNavigation()" class="c-mobile-navigation-close">
      <mat-icon>clear</mat-icon>
    </div>
    <ul class="o-list">
      <li class="o-list__item" *ngIf="accountService.isSupport">
        <a
          routerLink="/real-time-occupancy"
          (click)="closeMobileNavigation()"
          class="o-list--is-link"
          [ngClass]="{ 'c-selected-navigation': currentRoute === 'real-time-occupancy' }"
        >
          <mat-icon class="o-list__item-icon is-icon"> stream </mat-icon>
          <div class="o-list__item-text is-text">REAL TIME OCCUPANCY</div>
          <mat-icon class="o-list__item-secondary-icon is-secondary-icon" *ngIf="currentRoute !== 'real-time-occupancy'"
            >chevron_right
          </mat-icon>
        </a>
      </li>
      <li class="o-list__item">
        <a
          routerLink="/infrastructure"
          (click)="closeMobileNavigation()"
          class="o-list--is-link"
          [ngClass]="{ 'c-selected-navigation': currentRoute === 'infrastructure' }"
        >
          <mat-icon class="o-list__item-icon is-icon"> widgets </mat-icon>
          <div class="o-list__item-text is-text">INFRASTRUCTURE</div>
          <mat-icon class="o-list__item-secondary-icon is-secondary-icon" *ngIf="currentRoute !== 'infrastructure'"
            >chevron_right
          </mat-icon>
        </a>
      </li>
      <li class="o-list__item">
        <a
          routerLink="/reporting"
          (click)="closeMobileNavigation()"
          class="o-list--is-link"
          [ngClass]="{ 'c-selected-navigation': currentRoute === 'reporting' }"
        >
          <mat-icon class="o-list__item-icon is-icon"> query_stats </mat-icon>
          <div class="o-list__item-text is-text">REPORTING</div>
          <mat-icon class="o-list__item-secondary-icon is-secondary-icon" *ngIf="currentRoute !== 'reporting'"
            >chevron_right
          </mat-icon>
        </a>
      </li>
      <li class="o-list__item" *ngIf="accountService.isAdmin">
        <a
          routerLink="/settings"
          (click)="closeMobileNavigation()"
          class="o-list--is-link"
          [ngClass]="{ 'c-selected-navigation': currentRoute === 'settings' }"
        >
          <mat-icon class="o-list__item-icon is-icon"> settings </mat-icon>
          <div class="o-list__item-text is-text">SETTINGS</div>
          <mat-icon class="o-list__item-secondary-icon is-secondary-icon" *ngIf="currentRoute !== 'settings'"
            >chevron_right
          </mat-icon>
        </a>
      </li>
    </ul>
  </div>
</ng-template>
