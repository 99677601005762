<form class="o-form" [formGroup]="monitorForm">
  <div></div>
  <div class="o-form__legend-icon">
    <mat-form-field class="is-input" appearance="outline">
      <mat-label>Monitor name</mat-label>
      <input autocomplete="off" matInput placeholder="Name" formControlName="name" />
    </mat-form-field>
  </div>

  <div class="o-form__legend-icon">
    <mat-form-field class="is-input" appearance="outline">
      <mat-label>Capacity</mat-label>
      <input type="number" autocomplete="off" matInput placeholder="Capacity" formControlName="capacity" />
    </mat-form-field>
  </div>

  <div class="o-form__legend-icon">
    <mat-form-field class="is-input" appearance="outline">
      <mat-label>Daily Occupancy Reset</mat-label>
      <input
        matInput
        [ngxTimepicker]="toggleTimepicker"
        readonly
        placeholder="Reset time"
        formControlName="dailyResetMin"
      />
      <ngx-material-timepicker #toggleTimepicker [disableAnimation]="true"></ngx-material-timepicker>
      <ngx-material-timepicker-toggle matSuffix [for]="toggleTimepicker"></ngx-material-timepicker-toggle>
    </mat-form-field>
  </div>

  <ng-container *ngIf="accountService.isSupport">
    <div class="o-form__legend-icon">
      <mat-form-field class="is-input">
        <mat-label>Reporting start date</mat-label>
        <input matInput [matDatepicker]="pickerFrom" formControlName="reportingStartDate" readonly />
        <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="o-form__legend-icon">
      <mat-form-field class="is-input">
        <mat-label>Reporting end date</mat-label>
        <input matInput [matDatepicker]="pickerTo" formControlName="reportingEndDate" readonly />
        <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
        <mat-datepicker #pickerTo></mat-datepicker>
      </mat-form-field>
    </div>
  </ng-container>
</form>
