import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'occupancyReportMetricsDisplayValue',
})
export class OccupancyReportMetricsDisplayValuePipe implements PipeTransform {
  allowedMetrics = {
    occupancy_avg: 'Avg. Occupancy',
    occupancy_max: 'Max. Occupancy',
    in_count_sum: 'Sum In',
    out_count_sum: 'Sum Out',
  };
  transform(metric: string): unknown {
    return this.allowedMetrics.hasOwnProperty(metric) ? this.allowedMetrics[metric] : '';
  }
}
