import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-alert-information',
  templateUrl: './alert-information.component.html',
  styleUrls: ['./alert-information.component.scss'],
})
export class AlertInformationComponent {
  @Input() camerasOffline: number;
  @Input() allCamerasQuantity: number;
  @Input() allSitesQuantity: number;
  @Input() camerasPronedToIssues: number;
  @Input() offlineCamerasWithIssues: number;
  @Input() issueType: 'alert' | 'summary';

  @Output() nextStep: EventEmitter<void> = new EventEmitter();
  @Output() closeDialog: EventEmitter<void> = new EventEmitter();
  constructor(private router: Router) {}

  addCameraAlert(): void {
    if (this.issueType === 'alert') {
      this.router.navigate(['alerts/no-camera-alerts']).then(() => this.closeDialog.emit());
    } else {
      this.router.navigate(['alerts/no-camera-summaries']).then(() => this.closeDialog.emit());
    }
  }
}
