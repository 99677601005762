<div class="auth-overlay" *ngIf="isLoading; else notLoading">
  <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
    <p style="font-size: 2rem; font-weight: 500">Loading...</p>
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>
<ng-template #notLoading>
  <div class="o-content-panel is-full-height">
    <div class="o-content-panel__head">
      <div class="o-heading--long-primer u-flush--bottom">
        <h2>Monitor alerts</h2>
      </div>
      <div class="is-action o-button has-outline" (click)="openNewAlertDialog()">
        <mat-icon class="is-icon-left">add_alert</mat-icon>
        Create monitor alert
      </div>
    </div>
    <div class="o-content-panel__body">
      <ul class="o-notification-list">
        <ng-container *ngFor="let occupancyAlert of occupancyAlerts">
          <li class="o-notification-list__item has-icon" (click)="openEditOccupancyAlertDialog(occupancyAlert)">
            <div class="is-icon">
              <span class="c-alert-tag">OA</span>
            </div>
            <div class="o-notification-list__primary-secondary">
              <div class="c-alert-name">{{ occupancyAlert.name }}</div>
              <div>
                {{ occupancyAlertMonitors[occupancyAlert.id].length }}
                {{ occupancyAlertMonitors[occupancyAlert.id].length | pluraliseWord: 'monitor' }}
                <ng-container *ngIf="occupancyAlertMonitors[occupancyAlert.id].length">
                  -
                  <span>
                    <ng-container *ngFor="let monitor of occupancyAlertMonitors[occupancyAlert.id].slice(0, -1)">
                      {{ monitor.name }},
                    </ng-container>
                    {{
                      occupancyAlertMonitors[occupancyAlert.id][occupancyAlertMonitors[occupancyAlert.id].length - 1]
                        .name
                    }}
                  </span>
                </ng-container>
              </div>
            </div>
            <div class="o-notification-list__actions">
              <div class="is-notification-values">
                <ul class="c-threshold-list">
                  <li class="c-threshold-list__item is-alert">
                    {{ occupancyAlert.configurationDetails['alertThreshold'] * 100 | number: '1.0-0' }}%
                  </li>
                  <li class="c-threshold-list__item is-warning">
                    {{
                      occupancyAlert.configurationDetails['warningThreshold']
                        ? (occupancyAlert.configurationDetails['warningThreshold'] * 100 | number: '1.0-0') + '%'
                        : 'OFF'
                    }}
                  </li>
                </ul>
              </div>
              <div class="is-action" (click)="deleteOccupancyAlert(occupancyAlert); $event.stopPropagation()">
                <mat-icon class="is-delete">delete</mat-icon>
              </div>
            </div>
          </li>
        </ng-container>
      </ul>
      <div class="o-feature-panel has-border is-muted" *ngIf="!occupancyAlerts.length">
        <div class="o-feature-panel__head">
          <span class="material-icons is-icon">notifications_off</span>
          <h3 class="is-label">No monitor alerts</h3>
        </div>
        <div class="o-feature-panel__body">
          <div class="o-feature-panel__media">
            <img src="/assets/images/no-alerts-placeholder.png" alt="No alerts created" class="is-media" />
          </div>
          <div class="o-feature-panel__message">
            <h3 class="pica">You haven't created any monitor alerts yet.</h3>
            <p>Monitor alerts will notify you when spaces reach specified occupancy or footfall thresholds.</p>
            <button class="o-button has-outline is-small is-primary-action" (click)="openNewAlertDialog()">
              <span class="material-icons is-icon-left">add_alert</span>
              Create monitor alert
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
