import { Pipe, PipeTransform } from '@angular/core';
import { GlobalMethods } from 'src/app/global-methods';

@Pipe({
  name: 'formatOpeningHours',
})
export class FormatOpeningHoursPipe implements PipeTransform {
  transform(openingHour: number[]): string {
    return openingHour[0] === -1
      ? 'Open all day'
      : openingHour[0] === openingHour[1]
        ? 'Closed'
        : GlobalMethods.minutesToTimeString(openingHour[0]) + ' - ' + GlobalMethods.minutesToTimeString(openingHour[1]);
  }
}
