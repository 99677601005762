import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UserService } from 'src/app/api';

@Component({
  selector: 'app-no-sites-card',
  templateUrl: './no-sites-card.component.html',
  styleUrls: ['./no-sites-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoSitesCardComponent implements OnInit {
  @Input() adminEmail: string;

  constructor(
    public userService: UserService,
    public ref: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (!this.adminEmail) {
      this.userService.listUsers().subscribe((users) => {
        const adminUsers = users.filter((u) => u.roles.includes('admin'));
        this.adminEmail = adminUsers.length ? `mailto:${adminUsers[0].email}` : '';
        this.ref.detectChanges();
      });
    }
  }
}
