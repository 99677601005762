<div class="o-full-page-wrapper" xmlns="http://www.w3.org/1999/html" xmlns:div="http://www.w3.org/1999/html">
  <nav class="navigation is-breadcrumb">
    <ul class="o-breadcrumb">
      <li class="o-breadcrumb__item">
        <a routerLink="/infrastructure" class="is-link">
          <mat-icon class="is-icon">dashboard</mat-icon>
        </a>
      </li>
      <li class="o-breadcrumb__item">
        <a [routerLink]="['/settings']" [queryParams]="{ tab: 'oauth_client' }" class="is-link">Settings</a>
      </li>
      <li class="o-breadcrumb__item">OAuth Client</li>
    </ul>
  </nav>

  <div class="o-full-page-wrapper__body">
    <div class="c-modal-full-page">
      <div class="c-text-section-title">Basic information</div>

      <div *ngIf="!oAuthClient" class="c-no-client-container">
        <img class="c-img-client-auth" src="/assets/images/client-auth-thumb.png" alt="" />
        <button
          mat-button
          class="o-button is-primary c-button-create-client"
          (click)="save()"
          [disabled]="!accountService.canCreateOAuth"
        >
          create client
        </button>
        <div class="c-text-generate-client-id">
          This will generate your
          <span class="c-badge">Client ID</span> and <span class="c-badge">Client secret</span>.
        </div>
      </div>

      <div *ngIf="oAuthClient">
        <div class="c-input-wrapper c-input-wrapper-first">
          <mat-form-field class="user-form-field" appearance="outline">
            <mat-label>Client Name</mat-label>
            <input [(ngModel)]="oAuthClient.name" matInput type="text" autocomplete="off" />
          </mat-form-field>
        </div>

        <ng-container *ngIf="editMode">
          <div class="c-input-wrapper">
            <mat-form-field class="user-form-field" appearance="outline">
              <mat-label>Client ID</mat-label>
              <input [(ngModel)]="oAuthClient.clientId" matInput type="text" autocomplete="off" disabled />
            </mat-form-field>
            <mat-icon
              (click)="copyLink(tooltipClient)"
              [cdkCopyToClipboard]="oAuthClient.clientId"
              class="c-input-icon c-icon-content-copy"
              #tooltipClient="matTooltip"
              [matTooltip]="tooltipMessage"
              [matTooltipPosition]="'above'"
            >
              content_copy
            </mat-icon>
          </div>

          <div class="c-input-wrapper">
            <mat-form-field class="user-form-field" appearance="outline">
              <mat-label>Client secret</mat-label>
              <input
                [value]="oAuthClient.clientSecret"
                matInput
                type="text"
                autocomplete="off"
                [attr.type]="isClientSecretAsPassword ? 'password' : 'text'"
                disabled
                class="is-secret"
              />
            </mat-form-field>
            <mat-icon
              (click)="copyLink(tooltipSecret)"
              [cdkCopyToClipboard]="oAuthClient.clientSecret"
              class="c-input-icon c-icon-content-copy"
              #tooltipSecret="matTooltip"
              [matTooltip]="tooltipMessage"
              [matTooltipPosition]="'above'"
            >
              content_copy
            </mat-icon>
            <mat-icon
              (click)="rotateClientSecret()"
              class="c-input-icon c-icon-rotate-secret"
              [matTooltip]="rotateSecretTooltipMessage"
              [matTooltipPosition]="'above'"
              >cached</mat-icon
            >
            <mat-icon
              (click)="togglePassword(revealSecretTooltip)"
              class="c-input-icon c-icon-remove-red-eyes"
              #revealSecretTooltip="matTooltip"
              [matTooltip]="revealSecretTooltipMessage"
              [matTooltipPosition]="'above'"
              >visibility_off</mat-icon
            >
          </div>
        </ng-container>

        <div class="c-grant-types-wrapper">
          <div class="c-text-section-title c-text-section-flex">
            Grant types
            <div class="c-info-wrapper">
              (API docs
              <a href="https://hoxtonai.redoc.ly/#section/Authentication" target="_blank">
                <mat-icon class="is-icon-small">open_in_new</mat-icon> </a
              >)
            </div>
          </div>
          <div class="c-controls-grant-types">
            <div class="c-controls-grant-wrapper">
              <mat-checkbox
                (change)="switchGrantType('authorization_code')"
                [checked]="oAuthClient.grantTypes.includes('authorization_code')"
              >
                Authorisation code
              </mat-checkbox>
              <div (click)="showFlowDiagram('authorization-code-flow-diagram')">
                <mat-icon class="is-icon-small">info</mat-icon>
              </div>
            </div>
            <div class="c-controls-grant-wrapper">
              <mat-checkbox
                (change)="switchGrantType('password')"
                [checked]="oAuthClient.grantTypes.includes('password')"
              >
                Resource Owner Password
              </mat-checkbox>
              <div (click)="showFlowDiagram('resource-owner-password-flow-diagram')">
                <mat-icon class="is-icon-small">info</mat-icon>
              </div>
            </div>
            <div class="c-info-text">
              Do not select <b>Resource Owner Password</b> grant if you are sharing this client with a 3rd party.
            </div>
            <mat-checkbox
              (change)="switchGrantType('refresh_token')"
              [checked]="oAuthClient.grantTypes.includes('refresh_token')"
              [disabled]="!canEnableRefreshToken"
            >
              Refresh Token
            </mat-checkbox>
          </div>
        </div>
        <div *ngIf="oAuthClient.grantTypes.includes('authorization_code')">
          <b class="c-text-section-title">Allowed callback URLs</b> (required)
        </div>

        <textarea
          rows="4"
          cols="50"
          class="is-text-area"
          [(ngModel)]="callbacks"
          (input)="validateInputs()"
          [disabled]="!accountService.canUpdateOAuth"
          *ngIf="oAuthClient.grantTypes.includes('authorization_code')"
        ></textarea>
        <div class="c-text-urls-description" *ngIf="oAuthClient.grantTypes.includes('authorization_code')">
          After the user authenticates we will only call back to any of these URLs. You can specify multiple valid URLs
          by comma-separating them (typically to handle different environments like QA or testing). Make sure to specify
          the protocol (<span class="c-badge">https://</span>) otherwise the callback may fail in some cases. With the
          exception of custom URI schemes for native clients, all callbacks should use protocol
          <span class="c-badge">https://</span>.
        </div>

        <div class="c-actions">
          <button
            *ngIf="accountService.canUpdateOAuth"
            mat-button
            class="o-button is-primary c-button-create-client"
            (click)="save()"
            [disabled]="!isURLListValid || !isGrantTypesValid || !oAuthClient.name"
          >
            save
          </button>
          <button
            *ngIf="accountService.canUpdateOAuth && editMode"
            mat-button
            class="o-button is-error"
            (click)="delete()"
            [disabled]="!isURLListValid || !isGrantTypesValid || !oAuthClient.name"
          >
            delete
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
