<div class="o-modal-full-page has-no-header o-dialog-page">
  <div class="o-modal-full-page__body c-modal-full-page__body">
    <div class="c-content-wrapper">
      <div class="c-controls">
        <mat-form-field>
          <mat-select
            [(value)]="selectedMonitorId"
            class="c-monitor-select"
            (selectionChange)="changeMonitor()"
            [disabled]="isLoading"
          >
            <mat-select-trigger>
              <div class="c-monitor-option-trigger-name">{{ occupancyMonitorsMap[selectedMonitorId].name }}</div>
              <div class="c-monitor-option-trigger-site">
                {{ sitesMap[occupancyMonitorsMap[selectedMonitorId].siteId].name }}
              </div>
            </mat-select-trigger>

            <app-filter
              (search)="filterBySiteOrMonitorName($event)"
              class="c-monitor-option-search"
              placeholder="Filter by site or monitor name"
            ></app-filter>

            <mat-option
              *ngFor="let occupancyMonitor of filteredMonitorOptions"
              [value]="occupancyMonitor.id"
              class="c-monitor-option"
            >
              <div class="c-monitor-option__name">{{ occupancyMonitor.name }}</div>
              <div class="c-monitor-option__site">
                {{ sitesMap[occupancyMonitorsMap[occupancyMonitor.id].siteId].name }}
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label class="c-title-datepicker">Day</mat-label>
          <input
            (dateChange)="updateMonitorData()"
            matInput
            [matDatepicker]="pickerFrom"
            [max]="calendarMaxDay"
            [(ngModel)]="selectedDate"
            [disabled]="isLoading"
            readonly
          />
          <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
          <mat-datepicker #pickerFrom></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="c-stats">
        <div class="c-average-occupancy-content loading-container" [ngClass]="{ 'loading-pie-chart': isLoading }">
          <div class="dot-spin" *ngIf="isLoading; else averageOccupancyPie"></div>
          <ng-template #averageOccupancyPie>
            <ng-container *ngIf="occupancyMonitorsMap[selectedMonitorId].capacity !== undefined; else noCapacity">
              <div class="is-avgChart" id="avgChart" #avgChart></div>
            </ng-container>
            <ng-template #noCapacity>
              <mat-icon class="u-orange-color"> warning </mat-icon>
              Capacity not set
            </ng-template>
          </ng-template>
        </div>

        <div class="c-people-stats-wrapper">
          <div class="c-people-stats">
            <mat-icon class="c-people-stats__icon">directions_walk</mat-icon>
            <div class="c-people-stats__text">Total people in</div>
            <div class="c-people-stats__value">{{ totalPeopleIn !== undefined ? totalPeopleIn : '-' }}</div>
          </div>
          <div class="c-people-stats">
            <mat-icon class="c-people-stats__icon">directions_walk</mat-icon>
            <div class="c-people-stats__text">Avg people in/hr</div>
            <div class="c-people-stats__value">{{ avgPeopleIn !== undefined ? avgPeopleIn : '-' }}</div>
          </div>
        </div>
      </div>

      <div
        class="c-occupancy-comparison"
        [ngClass]="isLoading ? 'loading-container' : 'c-occupancy-comparison-' + occupancyComparisionOption"
      >
        <div class="dot-spin" *ngIf="isLoading; else OccupancyComparisonLevel"></div>
        <ng-template #OccupancyComparisonLevel>
          <div class="c-occupancy-comparison-first-row">
            <div>
              <div class="c-text-occupancy-comparison-level">Occupancy comparison level</div>
              <div class="c-comparison-level">
                <div class="pica--bold">
                  {{
                    occupancyComparisionOption === occupancyComparisionOptions.notApplicable
                      ? 'N/A'
                      : occupancyComparisonPercentage + '%'
                  }}
                </div>
                <mat-icon (click)="openComparisonLevelDialog()" class="c-icon-create">create</mat-icon>
              </div>
            </div>
            <div
              *ngIf="occupancyComparisionOption !== occupancyComparisionOptions.notApplicable"
              (click)="openTimePickDialog()"
              class="c-time"
            >
              <mat-icon class="c-time-icon"> access_time </mat-icon>
              {{ startTime }} - {{ endTime }}
            </div>
          </div>
          <div class="c-occupancy-comparison-second-row">
            <ng-container *ngIf="occupancyComparisionOption !== occupancyComparisionOptions.notApplicable">
              <div>
                <ng-container *ngIf="occupancyComparisionOption === occupancyComparisionOptions.timeAbove">
                  <div class="c-text-time-position">
                    Time
                    <b> above </b>
                  </div>
                  <div class="long-primer--bold u-flush--bottom">
                    {{
                      timeAbove >= 60
                        ? (timeAbove / 60 - (timeAbove % 60) / 60 | number: '1.0-1') +
                          'h ' +
                          (timeAbove % 60 | number: '1.0-1') +
                          'm'
                        : (timeAbove % 60 | number: '1.0-1') + 'm'
                    }}
                    <span class="c-time-position-separator">|</span>
                    {{ (timeAbove * 100) / (timeAbove + timeBelow) | number: '1.0-1' }}%
                  </div>
                </ng-container>
                <ng-container *ngIf="occupancyComparisionOption === occupancyComparisionOptions.timeBelow">
                  <div class="c-text-time-position">
                    Time
                    <b> below </b>
                  </div>
                  <div class="long-primer--bold u-flush--bottom">
                    {{
                      timeBelow >= 60
                        ? (timeBelow / 60 - (timeBelow % 60) / 60 | number: '1.0-1') +
                          'h ' +
                          (timeBelow % 60 | number: '1.0-1') +
                          'm'
                        : (timeBelow % 60 | number: '1.0-1') + 'm'
                    }}
                    <span class="c-time-position-separator">|</span>
                    {{ (timeBelow * 100) / (timeAbove + timeBelow) | number: '1.0-1' }}%
                  </div>
                </ng-container>
              </div>
              <div class="c-time-buttons">
                <button
                  (click)="occupancyComparisionOption = occupancyComparisionOptions.timeAbove"
                  class="c-button-time-position c-button-time-position-above"
                >
                  Time above level
                </button>
                <button
                  (click)="occupancyComparisionOption = occupancyComparisionOptions.timeBelow"
                  class="c-button-time-position c-button-time-position-below"
                >
                  Time below level
                </button>
              </div>
            </ng-container>
            <div
              *ngIf="occupancyComparisionOption === occupancyComparisionOptions.notApplicable"
              class="c-set-capacity"
            >
              <span class="c-text-set-max-capacity"> You must set a max capacity to view this comparison. </span>
              <a> Set capacity for Cafe. </a>
            </div>
          </div>
        </ng-template>
      </div>
    </div>

    <div class="loading-container">
      <div class="dot-flashing" *ngIf="isLoading; else occupancyChart"></div>
      <ng-template #occupancyChart>
        <div class="o-banner-message" *ngIf="chartData[selectedRollingOption] === undefined; else chartDataTemplate">
          <div class="o-banner-message__text">
            <h1 class="is-title is-paragon">No data available.</h1>
            <p class="is-text">
              There is no data for the selected monitor. Please select a different monitor or a weider time interval.
            </p>
          </div>
          <div class="o-banner-message__media">
            <img src="/assets/images/capacity-management-api-fail.png" alt="api-fails" />
          </div>
        </div>
        <ng-template #chartDataTemplate>
          <div class="c-chart-top-bar">
            <div class="c-chart-legend">
              <div class="c-chart-legend__item" *ngFor="let chartLegendItem of chartLegend">
                <div class="c-chart-legend__dot" [ngStyle]="{ 'background-color': chartLegendItem.dotColor }"></div>
                <div>{{ chartLegendItem.title }}</div>
              </div>
            </div>
            <div class="c-hourly-occupnacy">Hourly Occupancy (avg)</div>
            <div>
              <div class="c-button-rolling">
                Interval:
                <mat-select
                  [(value)]="selectedRollingOption"
                  (selectionChange)="updateMonitorData(false)"
                  class="c-rolling-select"
                  [disabled]="isLoading"
                >
                  <mat-option
                    *ngFor="let rollingOption of rollingOptions"
                    [value]="rollingOption"
                    class="c-rolling-select__option"
                  >
                    {{ rollingOption }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
          </div>
          <div class="c-line-chart-wrapper" #chartArea></div>
        </ng-template>
      </ng-template>
    </div>
  </div>
</div>
