<mat-horizontal-stepper class="dialog-stepper" #stepper>
  <mat-step>
    <app-step
      [stepTitle]="stepsTitle"
      [isBackable]="false"
      (closeStep)="close()"
      [disabledNextStep]="
        !cameraAlertAndSummaryNameAndSitesComponent?.selectedOrganisation ||
        cameraAlertAndSummaryNameAndSitesComponent?.alertName === '' ||
        (cameraAlertAndSummaryNameAndSitesComponent?.alertMode === 'site' &&
          !cameraAlertAndSummaryNameAndSitesComponent?.selectedSite)
      "
      (openNextStep)="goTosecondStep()"
    >
      <app-camera-alert-and-summary-name-and-sites
        [sites]="sites"
        [alertOrSummary]="offlineAlert"
        [resourceType]="'alert'"
        (closeDialog)="close(true)"
      >
      </app-camera-alert-and-summary-name-and-sites>
    </app-step>
  </mat-step>

  <mat-step>
    <ng-template matStepContent>
      <app-step
        [stepTitle]="stepsTitle"
        (closeStep)="close()"
        [disabledNextStep]="!cameraAlertAndSummaryCamerasComponent?.selection?.selected.length"
      >
        <app-camera-alert-and-summary-cameras
          [cameras]="filteredCameras"
          [resourceType]="'alert'"
          [selectedCameras]="offlineAlertCameraPositions"
        >
        </app-camera-alert-and-summary-cameras>
      </app-step>
    </ng-template>
  </mat-step>
  <mat-step #cameraAlertSettings>
    <ng-template matStepContent>
      <app-step
        [stepTitle]="stepsTitle"
        [isBackable]="false"
        (closeStep)="close()"
        [disabledNextStep]="!cameraAlertSettingsComponent?.alertForm?.valid"
        (openNextStep)="setofflineAlert()"
      >
        <app-camera-alert-settings
          [users]="filteredUsers"
          [notificationGroups]="filteredNotificationGroups"
          [organisationId]="organisationId"
          [offlineAlert]="offlineAlert"
          [offlineAlertNotificationGroups]="offlineAlertNotificationGroups"
          #cameraAlertSettingsComponent
        >
        </app-camera-alert-settings>
      </app-step>
    </ng-template>
  </mat-step>
  <mat-step>
    <ng-template matStepContent>
      <app-step
        [stepTitle]="stepsTitle"
        (closeStep)="close()"
        [nextStepText]="'SAVE'"
        [processingStep]="saving"
        (openNextStep)="addCameraAlert()"
      >
        <app-camera-alert-and-summary-details-review
          [resourceType]="'alert'"
          [site]="cameraAlertAndSummaryNameAndSitesComponent.selectedSite"
          [allSites]="cameraAlertAndSummaryNameAndSitesComponent.alertMode === 'organisaiton'"
          [selectedCameras]="cameraAlertAndSummaryCamerasComponent?.selection.selected"
          [cameras]="filteredCameras"
          [allCameras]="cameraAlertAndSummaryCamerasComponent?.isAllSelected()"
          [alertOrSummary]="offlineAlert"
          [notificationGroupName]="notificationGroupName"
          [selectedRecipients]="cameraAlertSettingsComponent.selectRecipientsComponent.selectedRecipients"
        ></app-camera-alert-and-summary-details-review>
      </app-step>
    </ng-template>
  </mat-step>
  <!-- <mat-step>
    <app-step [stepTitle]="stepsTitle" (closeStep)="close()" [hasNextStep]="false" [isBackable]="false">
      <app-camera-alert-and-summary-add-the-other-resource
        [resourceType]="'alert'"
        (closeDialog)="close(true)"
      ></app-camera-alert-and-summary-add-the-other-resource>
    </app-step>
  </mat-step> -->
</mat-horizontal-stepper>
