<div class="o-list-card">
  <div class="o-list-card__title">
    <div class="o-list-card__title-icon">
      <mat-icon>{{ monitorIcon }}</mat-icon>
    </div>
    <h2 class="primer kirk u-flush--bottom">{{ monitorTitle }}</h2>
  </div>
  <div class="o-list-card-body">
    <ng-container *ngIf="monitors.length === 0; else hasMonitors">
      <div class="o-media">
        <div>
          <p class="primer kirk u-flush--bottom">No monitors have been created.</p>
          <a (click)="createMonitor.emit()" class="brevier u-flush--bottom">Create a new monitor.</a>
        </div>
        <img [src]="imgSrc" class="is-media u-margin--right" height="200" alt="" />
      </div>
    </ng-container>

    <ng-template #hasMonitors>
      <div class="o-list-card__overflow">
        <div
          class="c-title-row"
          [ngClass]="{
            'no-issues': !allMonitorsHaveIssue,
            'has-issues': allMonitorsHaveIssue,
          }"
        >
          <span class="is-total brevier kirk u-flush--bottom"
            >{{ monitors.length }} {{ monitors.length | pluraliseWord: 'monitor' }}</span
          >
          <span class="is-issues">
            <ng-container *ngIf="monitorsWithIssue; else online">
              <ng-container *ngIf="!allMonitorsHaveIssue">
                {{ monitorsWithIssue }}
                {{ monitorsWithIssue | pluraliseWord: 'monitor' }} with camera issues
              </ng-container>
              <ng-container *ngIf="allMonitorsHaveIssue"> All monitors have camera issues </ng-container>
            </ng-container>
            <ng-template #online>No camera issues</ng-template>
          </span>

          <button class="o-button has-outline is-action" (click)="viewMonitors.emit()">
            <mat-icon class="is-icon-left">view_quilt</mat-icon>
            view monitors
          </button>
        </div>
        <ul class="o-list">
          <ng-container *ngFor="let monitor of monitors">
            <li class="o-list__item">
              <div class="o-list__item-icon">
                <mat-icon
                  class="is-icon"
                  [ngClass]="{
                    'u-primary-color': monitorStatus[monitor.id] === 'online',
                    'u-red-color': monitorStatus[monitor.id] === 'issue',
                  }"
                  >{{ monitorIcon }}</mat-icon
                >
              </div>
              <div class="o-list__item-text">
                <div class="c-monitor-item">
                  <span class="is-name">{{ monitor.name }}</span>
                  <span class="is-camera-count"
                    >{{ monitorCameras[monitor.id].length }}
                    {{ monitorCameras[monitor.id].length | pluraliseWord: 'camera' }}</span
                  >
                </div>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </ng-template>
  </div>

  <div class="o-list-card__footer">
    <div class="o-list-card__footer-secondary-text">
      <button class="o-button has-outline" (click)="createMonitor.emit()">
        <mat-icon class="is-icon-left">add</mat-icon>
        add monitor
      </button>
    </div>
  </div>
</div>
