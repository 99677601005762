<div class="c-options">
  <div class="c-options__dates">
    <div class="c-options__title">
      <mat-icon class="is-icon">calendar_today</mat-icon>
      <h2 class="is-text">Date range</h2>
    </div>
    <div class="c-options__dates-field">
      <mat-form-field [formGroup]="range" class="is-date-input">
        <mat-label>From start of</mat-label>
        <input matInput [matDatepicker]="pickerFrom" formControlName="from" readonly />
        <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
      </mat-form-field>
      <mat-form-field [formGroup]="range" class="is-date-input">
        <mat-label>To end of</mat-label>
        <input matInput [matDatepicker]="pickerTo" [min]="range.value.from" formControlName="to" readonly />
        <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
        <mat-datepicker #pickerTo></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="c-options__metrics">
    <div class="c-options__title">
      <mat-icon class="is-icon">library_add_check</mat-icon>
      <h2 class="is-text">Metrics to include</h2>
    </div>
    <div class="c-options__metrics-options">
      <ng-container *ngFor="let metric of metrics">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="metricsSelection.toggle(metric)"
          [checked]="metricsSelection.isSelected(metric)"
          >{{ metric | occupancyReportMetricsDisplayValue }}</mat-checkbox
        >
      </ng-container>
    </div>
  </div>

  <div class="c-options__grain">
    <div class="c-options__title">
      <mat-icon class="is-icon">clear_all</mat-icon>
      <h2 class="is-text">Granularity</h2>
    </div>
    <mat-form-field>
      <mat-label>Time grain</mat-label>
      <mat-select ngDefaultControl [(ngModel)]="selectedTimeGrain" name="selectedTimeGrain">
        <mat-option *ngFor="let timeGrain of timeGrains" value="{{ timeGrain }}">
          {{ timeGrain | timeGrainDisplayValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="c-options__search">
    <div class="c-search__field">
      <app-filter placeholder="Filter monitors" (search)="runSearch($event)" class="o-search__input"></app-filter>
    </div>
  </div>
  <div class="c-options__pagination">
    <mat-paginator #tablePaginator [pageSizeOptions]="[20, 50, 100]" class="c-pagination"></mat-paginator>
  </div>
</div>

<div class="c-camera-list">
  <table mat-table [dataSource]="tableDataSource" class="c-table" [ngClass]="{ 'has-error': noMonitorSelectedError }">
    <tr mat-header-row *matHeaderRowDef="tableHeaderNames" class="c-table__header"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: tableHeaderNames"
      class="c-table__row"
      [ngClass]="{ 'is-disabled': row.disabled === 'true' }"
    ></tr>

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef (click)="masterToggle()">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="masterToggle()"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
        >
        </mat-checkbox>
      </th>
      <td
        mat-cell
        *matCellDef="let row"
        (click)="row.disabled === 'true' ? $event.stopPropagation() : selection.toggle(row.id)"
        class="check-column"
      >
        <mat-checkbox [checked]="selection.isSelected(row.id)" [disabled]="row.disabled === 'true'"> </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="monitorName">
      <th mat-header-cell *matHeaderCellDef class="c-table__title">Monitors</th>

      <td mat-cell *matCellDef="let row">
        {{ row.monitorName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="siteName">
      <th mat-header-cell *matHeaderCellDef class="c-table__title">Site</th>

      <td mat-cell *matCellDef="let row">
        {{ row.siteName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="cameras">
      <th mat-header-cell *matHeaderCellDef class="c-table__title">Cameras</th>

      <td mat-cell *matCellDef="let row">
        {{ row.cameras }}
      </td>
    </ng-container>
    <ng-container matColumnDef="connectivity">
      <th mat-header-cell *matHeaderCellDef class="c-table__title">Status</th>

      <td mat-cell *matCellDef="let row">
        <div class="c-table-td-content">
          <mat-icon
            [ngStyle]="
              (row.connectivity === 'Camera issues' && { color: 'var(--offline-color)' }) ||
              (row.connectivity === 'Online' && { color: 'var(--online-color)' }) ||
              (row.disabled === 'true' && { color: 'var(--disabled-color)' })
            "
            >view_compact</mat-icon
          >
          {{ row.connectivity }}
        </div>
      </td>
    </ng-container>
  </table>
</div>

<div class="c-options__download" [class.valid]="!payloadToBig && downloadBannerMsg" [class.invalid]="payloadToBig">
  <div class="loading" *ngIf="downloading">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="36"></mat-progress-spinner>
  </div>
  <p class="text">
    {{ downloadBannerMsg }}
  </p>
  <div class="c-use-opening-hours">
    <mat-checkbox [(ngModel)]="useOpeningHours">Filter by opening hours</mat-checkbox>
    <div class="is-info" (click)="openUserConfirmation()">
      <mat-icon class="is-icon">info</mat-icon>
    </div>
  </div>

  <div
    #tooltip="matTooltip"
    matTooltip="You must select at least one monitor"
    [matTooltipDisabled]="!noMonitorSelectedError"
  >
    <button class="o-button is-primary is-small" (click)="downloadCSV(tooltip)" [disabled]="payloadToBig">
      <mat-icon class="is-icon-left">file_download</mat-icon>
      {{ downloadButtonText }}
    </button>
  </div>
</div>
