<div class="o-dialog c-dialog">
  <ng-container *ngIf="!isIncreaseLimitRequestSent">
    <div class="o-dialog__title c-title">
      {{ title }}
    </div>
    <div class="o-dialog__body">
      <div class="c-contact-text">{{ contactText }}</div>
      <button (click)="sendIncreaseLimitRequest()" class="c-email-button">
        <mat-icon class="c-panel-entry-icon">email</mat-icon>
        <div class="c-email-text">EMAIL SUPPORT</div>
      </button>
      <div class="c-delete-text">{{ deleteText }}</div>
    </div>
  </ng-container>
  <ng-container *ngIf="isIncreaseLimitRequestSent">
    <div class="o-dialog__title c-request-title">Your request has been sent to Hoxton support.</div>
    <div class="o-dialog__body">
      <div class="c-contact-text">
        One of the team will be in touch soon to confirm the number of new seats required and pricing.
      </div>
    </div>
  </ng-container>
  <div class="o-dialog__actions">
    <button mat-button class="c-close-button" (click)="close()">CLOSE</button>
  </div>
</div>
