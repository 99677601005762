import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';

Sentry.init({
  dsn: 'https://9604ad82ce7755fba57ce3fd857e1a8e@o34873.ingest.us.sentry.io/4508326013960192',
  environment: environment.envName,
  integrations: [],
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
