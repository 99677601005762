<section class="c-no-site-permission">
  <div class="c-no-site-permission-header">
    <mat-icon>business</mat-icon>
    <h3 class="c-titile-no-site">No site permissions</h3>
  </div>
  <div class="c-no-site-permission-content">
    <div class="c-no-site-permission-description">
      <p class="c-text-not-connected">You’ve not been connected to a site.</p>
      <p>You must be connected to at least one site to use the control room.</p>
      <p>Please contact your admin to update your profile.</p>
    </div>
    <img src="/assets/images/new-homepage/no-sites.png" alt="No sites" class="c-img-no-site-permission" />
  </div>
</section>
