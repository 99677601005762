import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import { OAuthClientLink, UserService } from 'src/app/api';
import { Organisation, OrganisationService } from 'src/app/api';
import { NotifyService } from 'src/app/services/notify.service';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'app-add-edit-organistaion',
  templateUrl: './add-edit-organistaion.component.html',
  styleUrls: ['./add-edit-organistaion.component.scss'],
})
export class AddEditOrganistaionComponent implements OnInit, OnDestroy {
  saving = false;
  deleting = false;

  organisationForm: FormGroup;
  adminForm: FormGroup;
  processingRequest = false;
  organisation: Organisation;

  licenses = [
    { description: 'People count', value: 'people_count' },
    { description: 'Occupancy', value: 'occupancy_monitor' },
    { description: 'Data explorer', value: 'data_explorer' },
    { description: 'Real time', value: 'real_time' },
  ];
  states = [
    { description: 'Enabled', value: 'active' },
    { description: 'Disabled', value: 'disabled' },
  ];
  processingGroups = [
    { description: '24x7', value: 0 },
    { description: '6 AM - 11 PM', value: 1 },
    { description: 'Site open hours ± 1 hour', value: 2 },
  ];
  updateOrganisation = false;
  private ngUnsubscribe = new Subject();
  oAuthClientLinks: OAuthClientLink[] = [];

  constructor(
    public dialogRef: MatDialogRef<AddEditOrganistaionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private organisationService: OrganisationService,
    private notifyService: NotifyService,
    private userService: UserService,
    private ref: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    if (this.data.hasOwnProperty('organisation')) {
      this.updateOrganisation = true;
      this.organisation = this.data.organisation;
      this.organisationForm = this.fb.group({
        id: [this.organisation.id, [Validators.required]],
        name: [this.organisation.name, [Validators.required]],
        licence: this.fb.array(this.organisation.licence),
        maxAdminSeats: [this.organisation.maxAdminSeats],
        maxUserSeats: [this.organisation.maxUserSeats],
        state: [this.organisation.state, [Validators.required]],
        processingGroup: [this.organisation.processingGroup],
      });
    } else {
      this.organisationForm = this.fb.group({
        name: ['', [Validators.required]],
        licence: new FormArray([new FormControl('people_count')]),
        maxUserSeats: [],
        maxAdminSeats: [],
        state: [Organisation.StateEnum.Active],
        processingGroup: [1], // since the default value should be 6AM to 11PM
      });
      this.adminForm = this.fb.group({
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        organisationId: [''],
        roles: [['admin']],
      });
    }

    this.oAuthClientLinks = this.data.oAuthClientIds;
  }

  save(): void {
    this.saving = true;
    this.ref.detectChanges();

    if (!this.updateOrganisation) {
      this.organisationService
        .putOrganisation({ ...this.organisationForm.value })
        .pipe(
          switchMap((organisation: Organisation) => {
            this.adminForm.get('organisationId').setValue(organisation.id);
            return this.userService.putUser(this.adminForm.value);
          }),
          finalize(() => {
            this.saving = false;
            this.ref.detectChanges();
          }),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe({
          next: (_) => {
            this.close(true);
          },
          error: (error) => {
            this.notifyService.error(error);
          },
        });
    } else {
      this.organisationService
        .putOrganisation({ ...this.organisationForm.value })
        .pipe(
          finalize(() => {
            this.saving = false;
            this.ref.detectChanges();
          }),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe({
          next: (_) => {
            this.close(true);
          },
          error: (error) => {
            this.notifyService.error(error);
          },
        });
    }
  }

  close(reload?: boolean): void {
    if (reload) {
      this.dialogRef.close('reload');
    } else {
      this.dialogRef.close();
    }
  }

  onCheckChangeStatus(event: MatRadioChange) {}
  onCheckChangeLicence(event: MatCheckboxChange) {
    const formArray: FormArray = this.organisationForm.get('licence') as FormArray;

    if (event.checked) {
      formArray.push(new FormControl(event.source.value));
    } else {
      let i: number = 0;
      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value == event.source.value) {
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
