import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

type stepTypeEnum = 'ready' | 'current' | 'unready';

@Component({
  selector: 'app-monitor-setup-side-panel',
  templateUrl: './monitor-setup-side-panel.component.html',
  styleUrl: './monitor-setup-side-panel.component.scss',
})
export class MonitorSetupSidePanelComponent implements OnInit {
  @Input() activeStep = 0;
  @Input() monitor: any;
  @Input() monitorType: string;

  setupProgress = 0;
  steps: any[];
  StepTypeEnum = {
    ready: 'ready' as stepTypeEnum,
    current: 'current' as stepTypeEnum,
    unready: 'unready' as stepTypeEnum,
  };
  occupancyMonitorSteps = [
    {
      name: 'Select site',
      icon: 'pin_drop',
      type: this.StepTypeEnum.current,
    },
    {
      name: 'Monitor details',
      icon: 'tag',
      type: this.StepTypeEnum.current,
    },
    {
      name: 'Select camera(s)',
      icon: 'videocam',
      type: this.StepTypeEnum.unready,
    },
    {
      name: 'Select camera orientation',
      icon: 'flip_camera_android',
      type: this.StepTypeEnum.unready,
    },
    {
      name: 'Review',
      icon: 'checklist',
      type: this.StepTypeEnum.unready,
    },
  ];
  peopleCountMonitorSteps = [
    {
      name: 'Select site',
      icon: 'pin_drop',
      type: this.StepTypeEnum.current,
    },
    {
      name: 'No of cameras',
      icon: 'add_box',
      type: this.StepTypeEnum.current,
    },
    {
      name: 'Monitor details',
      icon: 'tag',
      type: this.StepTypeEnum.current,
    },
    {
      name: 'Select camera(s)',
      icon: 'videocam',
      type: this.StepTypeEnum.unready,
    },
    {
      name: 'Monitor direction',
      icon: 'swap_horiz',
      type: this.StepTypeEnum.current,
    },
    {
      name: 'Select camera orientation',
      icon: 'flip_camera_android',
      type: this.StepTypeEnum.unready,
    },
    {
      name: 'Review',
      icon: 'checklist',
      type: this.StepTypeEnum.unready,
    },
  ];
  peelOffMonitorSteps = [
    {
      name: 'Monitor details',
      icon: 'tag',
      type: this.StepTypeEnum.current,
    },
    {
      name: 'Select passing camera(s)',
      icon: 'videocam',
      type: this.StepTypeEnum.unready,
    },
    {
      name: 'Select passing direction(s)',
      icon: 'swap_horiz',
      type: this.StepTypeEnum.unready,
    },
    {
      name: 'Select entrance camera(s)',
      icon: 'videocam',
      type: this.StepTypeEnum.unready,
    },
    {
      name: 'Select entrance direction',
      icon: 'swap_horiz',
      type: this.StepTypeEnum.unready,
    },
    {
      name: 'Review',
      icon: 'checklist',
      type: this.StepTypeEnum.unready,
    },
  ];

  @Output() openPreviousStep: EventEmitter<void> = new EventEmitter();

  ngOnInit(): void {
    switch (this.monitorType) {
      case 'peel-off': {
        this.steps = this.peelOffMonitorSteps;
        break;
      }
      case 'people-count': {
        this.steps = this.peopleCountMonitorSteps;
        break;
      }
      case 'occupancy': {
        this.steps = this.occupancyMonitorSteps;
        break;
      }
    }
    this.steps = this.steps.map((step, index) => {
      let type;
      if (index < this.activeStep) {
        type = this.StepTypeEnum.ready;
      }
      if (index === this.activeStep) {
        type = this.StepTypeEnum.current;
      }
      if (index > this.activeStep) {
        type = this.StepTypeEnum.unready;
      }
      return { ...step, type };
    });
    this.setupProgress = Math.round((this.activeStep * 100) / (this.steps.length - 1));
  }
}
