import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CameraService } from 'src/app/api';
import { CameraStatus } from 'src/app/model/cameraStatus';
import { NotifyService } from 'src/app/services/notify.service';
import { UserConfirmationComponent } from 'src/app/components/general/user-confirmation/user-confirmation.component';

@Component({
  selector: 'app-paused-camera-info',
  templateUrl: './paused-camera-info.component.html',
  styleUrls: ['./paused-camera-info.component.scss'],
})
export class PausedCameraInfoComponent {
  @Input() cameras: CameraStatus[] = [];

  constructor(
    private matDialog: MatDialog,
    private cameraService: CameraService,
    private notifyService: NotifyService,
    private ref: ChangeDetectorRef,
  ) {}

  putCameraState(camera: CameraStatus): void {
    const cameraStateName = camera.state === 'paused' ? 'restore' : 'pause';
    const message = `You are about to ${cameraStateName} this camera.

    All people counting and occupaccy monitoring will be ${cameraStateName}d.`;

    const dialogRef = this.matDialog.open(UserConfirmationComponent, {
      data: { message, buttonText: cameraStateName },
    });
    const newState = camera.state === 'paused' ? 'running' : 'paused';
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'confirm') {
          this.cameraService
            .putCameraState(camera.id, newState)
            .pipe()
            .subscribe({
              next: (_) => {
                camera.state = newState;
                this.ref.detectChanges();
              },
              error: (error) => {
                this.notifyService.error(error);
              },
            });
        }
      },
    });
  }
}
