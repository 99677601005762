import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxMaterialTimepickerComponent } from 'ngx-material-timepicker';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-add-occupancy-monitor-details',
  templateUrl: './add-occupancy-monitor-details.component.html',
  styleUrls: ['./add-occupancy-monitor-details.component.scss'],
})
export class AddOccupancyMonitorDetailsComponent implements OnInit {
  monitorForm: FormGroup;
  @ViewChild('toggleTimepicker') timepicker: NgxMaterialTimepickerComponent;

  constructor(
    private formBuilder: FormBuilder,
    public accountService: AccountService,
  ) {}

  ngOnInit(): void {
    this.monitorForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      capacity: [undefined, [Validators.pattern('^[0-9]*$'), Validators.min(0)]],
      dailyResetMin: ['', [Validators.required]],
      reportingStartDate: [undefined],
      reportingEndDate: [undefined],
    });
  }

  @HostListener('keydown', ['$event'])
  onInput(e: any) {
    if (e.key === 'Tab' && e.srcElement.id === 'capacityInput') {
      this.timepicker.open();
    }
  }
}
