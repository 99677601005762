<div class="container">
  <div class="o-banner-message not-found">
    <div class="o-banner-message__text">
      <h1 class="is-title is-paragon">Sorry, we can't find the page you requested.</h1>
      <div class="is-primary-action">
        <a routerLink="/" class="o-button has-border">Return to your dashboard</a>
      </div>
      <p class="is-text">
        If you're still having difficulties, contact our
        <a href="mailto:support@hoxton.ai">customer service team</a>.
      </p>
    </div>

    <div class="o-banner-message__media">
      <img src="/assets/images/404-graphic.png" alt="" />
    </div>
  </div>
</div>
