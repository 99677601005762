<div
  class="o-monitor-card"
  [ngClass]="{
    'is-online': !issues,
    'is-offline': issues,
    'is-disabled': disabled,
  }"
>
  <div class="o-monitor-card__title">
    <mat-icon class="o-monitor-card__title-icon">shield</mat-icon>
    <div class="o-monitor-card__title-text">
      <app-copy-link
        [text]="organisation.name"
        [partRoute]="'/' + organisation.id"
        [tooltipMessage]="'Copy link to organisation'"
      ></app-copy-link>
      <h3 class="c-secondary-title-text">
        <span *ngIf="disabled" class="is-org-disabled"> (DISABLED) </span>
        {{ formatLicenses(organisation.licence) }}
      </h3>
    </div>
    <mat-icon class="o-monitor-card__title-actions" (click)="edit.emit(true)" *ngIf="canEdit">edit</mat-icon>
  </div>
  <div class="c-organisation-card__body">
    <ul class="o-list">
      <a class="o-list__item" [routerLink]="['/sites', { orgId: organisation.id }]">
        <div class="o-list--is-link">
          <div class="o-list__item-icon">
            <mat-icon class="is-icon"> apartment </mat-icon>
          </div>
        </div>
        <div class="o-list__item-text">
          <h2 class="is-primary-text">{{ sites.length }} sites</h2>
        </div>
        <div class="o-list__item-secondary-icon">
          <mat-icon>chevron_right</mat-icon>
        </div>
      </a>
      <a
        class="o-list__item"
        [ngClass]="{ 'has-error': urgentIssues }"
        [routerLink]="['/cameras', { orgId: organisation.id }]"
      >
        <div class="o-list--is-link">
          <div class="o-list__item-icon">
            <mat-icon [ngClass]="{ 'is-online': !issues, 'is-offline': issues }">photo_camera</mat-icon>
          </div>
        </div>
        <div class="o-list__item-text">
          <h2 class="is-primary-text">
            <ng-container *ngIf="issues; else noIssue">
              <ng-container *ngIf="urgentIssues"
                >{{ urgentIssues }} urgent camera {{ urgentIssues | pluraliseWord: 'issue' }}</ng-container
              >
              <ng-container *ngIf="!urgentIssues">
                {{ issues }} camera {{ issues | pluraliseWord: 'issue' }}</ng-container
              >
            </ng-container>
            <ng-template #noIssue> No camera issues </ng-template>
          </h2>
        </div>
        <div class="o-list__item-secondary-icon">
          <mat-icon>chevron_right</mat-icon>
        </div>
      </a>
      <a class="o-list__item" [routerLink]="['/users', { orgId: organisation.id }]">
        <div class="o-list--is-link">
          <div class="o-list__item-icon">
            <mat-icon class="is-icon"> supervised_user_circle </mat-icon>
          </div>
        </div>
        <div class="o-list__item-text">
          <h2 class="is-primary-text">{{ users.length }} users</h2>
          <h2 class="is-secondary-text">{{ adminUsers }} admins, {{ memberUsers }} members</h2>
        </div>
        <div class="o-list__item-secondary-icon">
          <mat-icon>chevron_right</mat-icon>
        </div>
      </a>
      <li class="o-list__item">
        <div class="o-list--is-link">
          <div class="o-list__item-icon">
            <mat-icon class="is-icon"> schedule_send </mat-icon>
          </div>
        </div>
        <div class="o-list__item-text">
          <h2 class="is-primary-text">
            {{ processingGroup[organisation.processingGroup] }}
          </h2>
        </div>
      </li>
      <li *ngIf="adminUsersSeatsAvailable" class="o-list__item">
        <div class="o-list--is-link">
          <div class="o-list__item-icon">
            <mat-icon class="is-icon"> recent_actors </mat-icon>
          </div>
        </div>
        <div class="o-list__item-text">
          <h2 class="is-primary-text">
            {{ memberUsersSeatsAvailable }} user {{ memberUsersSeatsAvailable | pluraliseWord: 'seat' }}
          </h2>
          <h2 *ngIf="memberUsersSeatsAvailable > adminUsers + memberUsers" class="is-secondary-text">
            {{ adminUsersSeatsAvailable }} admin limit | {{ memberUsersSeatsAvailable - (adminUsers + memberUsers) }}
            {{ memberUsersSeatsAvailable - (adminUsers + memberUsers) | pluraliseWord: 'seat' }} available
          </h2>
          <h2 *ngIf="memberUsersSeatsAvailable <= adminUsers + memberUsers" class="is-secondary-text">
            {{ adminUsers }} admin limit | All seats allocated
          </h2>
        </div>
      </li>
    </ul>
  </div>
  <!--  <div class="o-monitor-card__action">-->
  <!--    <a [routerLink]="['/users', { orgId: organisation.id }]" class="o-button">-->
  <!--      View users-->
  <!--      <mat-icon class="is-icon-right">arrow_forward</mat-icon>-->
  <!--    </a>-->
  <!--    <a [routerLink]="['/sites', { orgId: organisation.id }]" class="o-button">-->
  <!--      View sites-->
  <!--      <mat-icon class="is-icon-right">arrow_forward</mat-icon>-->
  <!--    </a>-->
  <!--  </div>-->
</div>
