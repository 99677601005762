export const environment = {
  apiUrl: 'https://stage.hoxton.cloud/api/v0',
  auth0Audience: 'https://api.stage.hoxton.cloud',
  auth0ClientId: 'T7rjlhWk8gbbQrJuV4qMv35A4TWeWK2C',
  auth0Domain: 'auth.hoxton.ai',
  gtagId: 'G-ZRRSW4BHMZ',
  reportingUrl: 'https://reporting.hoxton.ai',
  cookieDomain: 'app-stage.hoxton.ai',
  production: true,
  gcpProject: 'ruling-perch-3813',
  envName: 'stage',
  hubspot: {
    visitorIdentificationUrl: 'https://hsproxy.ew.r.appspot.com/conversations/v3/visitor-identification/tokens/create',
  },
};
