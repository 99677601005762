import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatRadioChange } from '@angular/material/radio';
import { Camera } from 'src/app/api';

@Component({
  selector: 'app-choose-monitor-mode-step',
  templateUrl: './choose-monitor-mode-step.component.html',
  styleUrls: ['./choose-monitor-mode-step.component.scss'],
})
export class ChooseMonitorModeStepComponent {
  @Input() cameras: Camera[] = [];
  monitorForm: FormGroup;
  monitorMode: 'single' | 'multi' | null = null;

  constructor(private formBuilder: FormBuilder) {
    this.monitorForm = this.formBuilder.group({
      cameraId: ['', [Validators.required]],
    });
  }

  onModeChange(radio: MatRadioChange, panel: MatExpansionPanel) {
    this.monitorMode = radio.value;
    if (this.monitorMode === 'single') {
      this.monitorForm = this.formBuilder.group({
        cameraId: ['', [Validators.required]],
      });
    } else {
      this.monitorForm = this.formBuilder.group({});
    }

    panel.open();
  }
}
