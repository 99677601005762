import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Alert } from 'src/app/api';

@Component({
  selector: 'app-select-alerts-and-sumarries',
  templateUrl: './select-alerts-and-sumarries.component.html',
  styleUrls: ['./select-alerts-and-sumarries.component.scss'],
})
export class SelectAlertsAndSumarriesComponent implements OnInit {
  offlineOrSummary: Alert[];
  title: string;
  resourceType = 'alert';

  selection = new SelectionModel<string>(true, []);
  constructor(
    public dialogRef: MatDialogRef<SelectAlertsAndSumarriesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.offlineOrSummary = this.data.offlineOrSummary;
    this.title = this.data.title;
    this.resourceType = this.data.resourceType;
  }

  save(): void {
    this.dialogRef.close(this.selection.selected);
  }

  navigateToResourcePage(): void {
    this.router
      .navigate([this.resourceType === 'alert' ? '/alerts/camera-alerts-offline' : '/alerts/camera-summaries'])
      .then(() => {
        window.location.reload();
      });
  }

  close(): void {
    this.dialogRef.close();
  }
}
