<ng-container *ngIf="accountService.canReadOAuth; else noOAuthAccess">
  <div class="c-container">
    <div class="c-actions">
      <app-filter placeholder="Filter OAuth Clients" (search)="runSearch($event)" class="o-search__input"></app-filter>
      <button
        *ngIf="accountService.oAuthClients.length !== 0"
        class="is-button o-button has-outline"
        (click)="addEditOAuthClient()"
      >
        <mat-icon class="is-icon-left">fingerprint</mat-icon>
        ADD client
      </button>
    </div>
    <div *ngIf="accountService.oAuthClients.length === 0" class="c-no-client-container">
      <img class="c-img-client-auth" src="/assets/images/client-auth-thumb.png" alt="" />
      <b> No client exists. </b>
      <button
        mat-button
        class="o-button is-primary c-button-create-client"
        (click)="addEditOAuthClient()"
        [disabled]="!accountService.canCreateOAuth"
      >
        create client
      </button>
      <div class="c-text-generate-client-id">
        This will generate your
        <span class="c-badge">Client ID</span>
        and
        <span class="c-badge">Client secret</span>.
      </div>
    </div>

    <div class="c-container-cards">
      <ng-container *ngFor="let oAuthClient of filteredOAuthClients">
        <app-oauth-client-card
          [oAuthClient]="oAuthClient"
          (addEditOAuthClient)="addEditOAuthClient(oAuthClient)"
        ></app-oauth-client-card>
      </ng-container>
    </div>
  </div>
</ng-container>
<ng-template #noOAuthAccess>
  <div class="brevier u-margin--top">You don't have OAuth access. Contact your admin for help.</div>
</ng-template>
