import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isAre',
})
export class IsArePipe implements PipeTransform {
  transform(count: number): string {
    return count === 1 ? 'is' : 'are';
  }
}
