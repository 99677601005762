<div class="o-modal-full-page has-secondary-header c-monitor-general-info-dialog">
  <div class="o-modal-full-page__header">
    <button (click)="close()" class="is-close">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <h2 class="is-title">Real time capacity</h2>
  </div>
  <div class="o-modal-full-page__actions">
    <button class="o-button" mat-button (click)="close()">
      <mat-icon class="is-icon-left"> west </mat-icon>
      back
    </button>
  </div>
  <app-real-time-data-card
    [site]="site"
    [occupancyMonitor]="occupancyMonitor"
    [disableShowingPopup]="true"
    [showChartForMobile]="true"
  ></app-real-time-data-card>
</div>
