import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Alert, NotificationGroup, User } from 'src/app/api';
import { SelectRecipientsComponent } from 'src/app/components/notifications/select-recipients/select-recipients.component';

@Component({
  selector: 'app-camera-alert-settings',
  templateUrl: './camera-alert-settings.component.html',
  styleUrls: ['./camera-alert-settings.component.scss'],
})
export class CameraAlertSettingsComponent implements OnInit {
  @Input() notificationGroups: NotificationGroup[] = [];
  @Input() users: User[] = [];
  @Input() organisationId: string;
  @Input() offlineAlert: Alert;

  alertForm: FormGroup;

  selectedRecipients = [];
  @ViewChild(SelectRecipientsComponent)
  selectRecipientsComponent: SelectRecipientsComponent;

  @Input() set offlineAlertNotificationGroups(offlineAlertNotificationGroups: NotificationGroup[]) {
    offlineAlertNotificationGroups.forEach((offlineAlertNotificationGroup) => {
      this.selectedRecipients.push(offlineAlertNotificationGroup);
    });
  }

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
  ) {
    this.alertForm = this.formBuilder.group({
      duration: ['', [Validators.required]],
      notifyAlertRecovery: [true, [Validators.required]],
    });
  }

  ngOnInit(): void {
    if (this.offlineAlert) {
      this.alertForm.controls.duration.patchValue(this.offlineAlert.configurationDetails['duration']);
      this.offlineAlert.emails?.forEach((email) => {
        const hoxtonUser = this.users.find((u) => u.email === email);
        this.selectedRecipients.push(hoxtonUser ? hoxtonUser : email);
      });
    }
  }

  navigateToNotificationGroups(): void {
    this.router.navigate(['/alerts/notification-groups']).then(() => {
      window.location.reload();
    });
  }
}
