import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-balena-summary',
  templateUrl: './balena-summary.component.html',
  styleUrls: ['./balena-summary.component.scss'],
})
export class BalenaSummaryComponent implements OnInit {
  @Input() fullId = '';
  private summaryURL: string;
  constructor() {}

  ngOnInit(): void {
    this.summaryURL = `https://dashboard.balena-cloud.com/devices/${this.fullId.replace(/-/g, '')}/summary`;
  }

  openBalenaSummaryView(): void {
    window.open(this.summaryURL, '_blank');
  }
}
